/* eslint-disable import/prefer-default-export */
/* eslint-disable react/prop-types */
import React from 'react';
import { PropTypes } from 'prop-types';
import { useLocalizedFromContext } from 'data/Localization';

export const Crop48 = ({ title, fill, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="117.824"
    height="161.744"
    viewBox="0 0 117.824 161.744"
    {...props}
  >
    <title>{useLocalizedFromContext(title)}</title>
    <g id="Component_1_1" data-name="Component 1 – 1" transform="translate(2.501 3.501)">
      <path
        id="Path_625"
        data-name="Path 625"
        d="M1708.514,2273.51c-9.709,0-26.044-15.929-43.469-36.718s-17.6-50.74,1.58-64.456,41.889-11.892,41.889-11.892"
        transform="translate(-1652.103 -2117.767)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_626"
        data-name="Path 626"
        d="M1708.514,2273.51c9.709,0,26.044-15.929,43.469-36.718s17.6-50.74-1.58-64.456-41.889-11.892-41.889-11.892"
        transform="translate(-1652.103 -2117.767)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_627"
        data-name="Path 627"
        d="M1704.036,2160.364s-25.785-1.621-27.959-10.941,1.553-18.639,1.553-18.639,4.66,12.183,15.844,14.79S1704.036,2160.364,1704.036,2160.364Z"
        transform="translate(-1652.103 -2117.767)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_628"
        data-name="Path 628"
        d="M1712.992,2160.364s25.785-1.621,27.959-10.941-1.553-18.639-1.553-18.639-4.66,12.183-15.844,14.79S1712.992,2160.364,1712.992,2160.364Z"
        transform="translate(-1652.103 -2117.767)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_629"
        data-name="Path 629"
        d="M1708.514,2117.767s-11.878,8.963-10.314,19.489c1.308,8.8,7.682,23.113,7.682,23.113"
        transform="translate(-1652.103 -2117.767)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_630"
        data-name="Path 630"
        d="M1708.514,2117.767s11.878,8.963,10.314,19.489c-1.308,8.8-7.682,23.113-7.682,23.113"
        transform="translate(-1652.103 -2117.767)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <g id="Group_198" data-name="Group 198" transform="translate(-1652.103 -2117.767)">
        <ellipse
          id="Ellipse_60"
          data-name="Ellipse 60"
          cx="6.867"
          cy="3.63"
          rx="6.867"
          ry="3.63"
          transform="translate(1732.089 2230.536) rotate(-55.322)"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
        <ellipse
          id="Ellipse_61"
          data-name="Ellipse 61"
          cx="3.984"
          cy="6.867"
          rx="3.984"
          ry="6.867"
          transform="translate(1742.998 2193.823)"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
        <ellipse
          id="Ellipse_62"
          data-name="Ellipse 62"
          cx="3.615"
          cy="6.867"
          rx="3.615"
          ry="6.867"
          transform="matrix(0.739, -0.673, 0.673, 0.739, 1726.345, 2175.083)"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
        <ellipse
          id="Ellipse_63"
          data-name="Ellipse 63"
          cx="4.667"
          cy="6.867"
          rx="4.667"
          ry="6.867"
          transform="translate(1714.302 2212.544)"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
        <ellipse
          id="Ellipse_64"
          data-name="Ellipse 64"
          cx="4.667"
          cy="6.867"
          rx="4.667"
          ry="6.867"
          transform="translate(1714.562 2240.113)"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
        <ellipse
          id="Ellipse_65"
          data-name="Ellipse 65"
          cx="4.667"
          cy="6.867"
          rx="4.667"
          ry="6.867"
          transform="translate(1718.97 2189.342)"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
        <ellipse
          id="Ellipse_66"
          data-name="Ellipse 66"
          cx="4.667"
          cy="6.867"
          rx="4.667"
          ry="6.867"
          transform="translate(1704.161 2171.39)"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
      </g>
      <g id="Group_199" data-name="Group 199" transform="translate(-1652.103 -2117.767)">
        <ellipse
          id="Ellipse_67"
          data-name="Ellipse 67"
          cx="3.63"
          cy="6.867"
          rx="3.63"
          ry="6.867"
          transform="translate(1671.41 2223.374) rotate(-34.678)"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
        <ellipse
          id="Ellipse_68"
          data-name="Ellipse 68"
          cx="3.984"
          cy="6.867"
          rx="3.984"
          ry="6.867"
          transform="translate(1666.316 2193.823)"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
        <ellipse
          id="Ellipse_69"
          data-name="Ellipse 69"
          cx="6.867"
          cy="3.615"
          rx="6.867"
          ry="3.615"
          transform="matrix(0.673, -0.739, 0.739, 0.673, 1676.347, 2180.372)"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
        <ellipse
          id="Ellipse_70"
          data-name="Ellipse 70"
          cx="4.667"
          cy="6.867"
          rx="4.667"
          ry="6.867"
          transform="translate(1693.646 2212.544)"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
        <ellipse
          id="Ellipse_71"
          data-name="Ellipse 71"
          cx="4.667"
          cy="6.867"
          rx="4.667"
          ry="6.867"
          transform="translate(1693.386 2240.113)"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
        <ellipse
          id="Ellipse_72"
          data-name="Ellipse 72"
          cx="4.667"
          cy="6.867"
          rx="4.667"
          ry="6.867"
          transform="translate(1688.979 2189.342)"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
      </g>
    </g>
  </svg>
);

Crop48.defaultProps = {
  title: 'strawberry',
};

Crop48.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
};
