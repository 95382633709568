/* eslint-disable react/prop-types */
import React from 'react';

const LPIcon1 = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="66.262"
    height="102.168"
    viewBox="0 0 66.262 102.168"
    {...props}
  >
    <g id="Component_1_1" transform="translate(0.25 0.252)">
      <g id="Group_1082" transform="translate(4.883)">
        <g id="Group_1081" transform="translate(0 0)">
          <path
            id="Path_1156"
            d="M131.808,11.839a9.649,9.649,0,0,0-1.158-3.667,9.064,9.064,0,0,0-2.632-2.948c-2.3-1.649-5.623-2.509-10.028-2.027-5.264.579-10.773-2.535-11.4-2.9a1.553,1.553,0,0,0-2.465,1.412c.921,9.045,3.071,15.168,5.764,19.046a13.028,13.028,0,0,0,6.413,5.176,9.86,9.86,0,0,0,6.9-.228,11.764,11.764,0,0,0,1.869-.93q-.175-.307-.368-.605a10.119,10.119,0,0,0-1.562-1.842,8.549,8.549,0,0,1-1.053.509,6.809,6.809,0,0,1-4.764.167A10.011,10.011,0,0,1,112.481,19c-2.141-3.079-3.9-7.843-4.878-14.747A22.1,22.1,0,0,0,118.289,6.26c3.571-.395,6.141.228,7.9,1.456a5.982,5.982,0,0,1,1.755,1.948,6.545,6.545,0,0,1,.781,2.492,8.515,8.515,0,0,1-.114,2.509,18.58,18.58,0,0,1,2.553,2.439A12.194,12.194,0,0,0,131.808,11.839Z"
            transform="translate(-104.112 0)"
            fill="#849daa"
            stroke="#849daa"
            strokeWidth="0.5"
          />
        </g>
      </g>
      <g id="Group_1084" transform="translate(13.436 8.505)">
        <g id="Group_1083" transform="translate(0)">
          <path
            id="Path_1157"
            d="M176.7,46.122a1.553,1.553,0,0,0-2.166.359l-.036.052a11.9,11.9,0,0,1-4.465,3.457c-2.632,1.456-5.4,2.956-7.159,6.729-2.1-7.711-6.483-10.133-10.729-12.449-2.36-1.29-4.658-2.553-6.22-4.843a1.553,1.553,0,0,0-2.562,1.755c1.983,2.9,4.6,4.343,7.29,5.816,4.457,2.43,9.141,5,10.124,16.133H162.6c.456,0,1.614,0,1.755.044.939-7.018,4.123-8.773,7.159-10.4,2.053-1.158,4.036-2.246,5.553-4.483A1.553,1.553,0,0,0,176.7,46.122Z"
            transform="translate(-143.112 -38.778)"
            fill="#849daa"
            stroke="#849daa"
            strokeWidth="0.5"
          />
        </g>
      </g>
      <g id="Group_1086" transform="translate(0 35.903)">
        <g id="Group_1085">
          <path
            id="Path_1158"
            d="M114.753,163.7a32.881,32.881,0,1,0,32.859,32.9A32.881,32.881,0,0,0,114.753,163.7Zm16.09,7.869a29.829,29.829,0,0,1,12.045,15.4,44.1,44.1,0,0,0-6.343-4.474C132.255,179.86,129.079,177.895,130.843,171.569ZM91.084,214.706a29.82,29.82,0,0,1,2.553-39.171c1.43,3.93,4.237,6.343,6.808,8.545,2.342,2.009,4.439,3.816,4.483,6.334,0,1.816-1.088,3.193-2.334,4.72a15.845,15.845,0,0,0-3.676,6.632,19.787,19.787,0,0,0-.158,4.825c.1,2.588.219,5.6-.6,7.483C97.427,215.829,95.532,216.531,91.084,214.706Zm23.626,11.651v-.009a30.092,30.092,0,0,1-3.632-.219,4.729,4.729,0,0,1,1.632-5.816c1.369-1,5.141.123,9.115,1.307,2.053.614,4.15,1.237,6.141,1.623A29.646,29.646,0,0,1,114.709,226.357Zm17.809-5.922c-2.483.43-6.238-.693-9.843-1.755-4.729-1.412-9.22-2.746-11.835-.833a7.378,7.378,0,0,0-3.15,7.676,29.689,29.689,0,0,1-12.651-6.6,5.562,5.562,0,0,0,5.992-3.623c1.079-2.509.947-5.9.877-8.817a18.841,18.841,0,0,1,.07-3.992,13.326,13.326,0,0,1,3.062-5.378c1.623-1.983,3.079-3.772,3.027-6.72-.07-3.93-2.632-6.141-5.562-8.65-2.509-2.158-5.264-4.544-6.273-8.466a29.735,29.735,0,0,1,31.837-3.3c-2.974,9.115,1.184,11.677,6.817,15.168,3.027,1.869,6.562,4.036,9.352,7.948a29.858,29.858,0,0,1-11.721,27.337Z"
            transform="translate(-81.85 -163.7)"
            fill="#849daa"
            stroke="#849daa"
            strokeWidth="0.5"
          />
        </g>
      </g>
      <g id="Group_1088" transform="translate(32.666 9.325)">
        <g id="Group_1087" transform="translate(0)">
          <path
            id="Path_1159"
            d="M250.733,42.527a1.551,1.551,0,0,0-.942.207s-4.29,2.553-8.282,2.114c-3.36-.368-5.922.29-7.7,1.57a7.117,7.117,0,0,0-2.079,2.307,7.553,7.553,0,0,0-.877,2.869,9.064,9.064,0,0,0,.132,2.921,15.909,15.909,0,0,1,2.948-2.492v-.088a4.449,4.449,0,0,1,.526-1.693,4.037,4.037,0,0,1,1.184-1.3c1.184-.877,3.018-1.281,5.553-1a16.23,16.23,0,0,0,7.457-1.246c-.728,4.667-1.965,7.9-3.448,10.045a7.018,7.018,0,0,1-3.386,2.825,4.553,4.553,0,0,1-2.544.114,14.317,14.317,0,0,0-2.149,2.5l.333.14a7.7,7.7,0,0,0,5.4.175,10.036,10.036,0,0,0,4.939-3.992c2.027-2.921,3.641-7.518,4.334-14.282A1.553,1.553,0,0,0,250.733,42.527Z"
            transform="translate(-230.788 -42.519)"
            fill="#849daa"
            stroke="#849daa"
            strokeWidth="0.5"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default LPIcon1;
