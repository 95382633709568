/* eslint-disable react/prop-types */
import React from 'react';

const CreateNewPlanIcon = ({ color, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="62.999"
    height="53"
    viewBox="0 0 62.999 53"
    {...props}
  >
    <g
      id="Vector_Smart_Object2_copy_4"
      dataname="Vector Smart Object2 copy 4"
      transform="translate(-1814.636 -1287.587)"
    >
      <g id="Group_29" dataname="Group 29">
        <path
          id="Path_328"
          dataname="Path 328"
          d="M1875.873,1324.739l-3.059.723,1.158,4.192-10.863,6.6-.954-8.271-3.073.726.906,7.859-12.152-4.081v-1.116l-3.122.739v.3l-12.152,4.017.173-1.487-3.2.759-.087.759-.871-.533-4.355,1.031,5.9,3.583.067.04,15.682-5.226a1.512,1.512,0,0,0,.192-.117c.022-.012.042-.027.063-.041.076.051.165.1.258.146l15.632,5.214.075.025,15.549-9.439Z"
          fill="#b95700"
        />
        <path
          id="Path_329"
          dataname="Path 329"
          d="M1834.279,1321.629l-3.23.764-1.52,13.312,3.205-.758Z"
          fill="#eb6909"
        />
        <path
          id="Path_330"
          dataname="Path 330"
          d="M1872.528,1312.578l-3.075.728,3.361,12.157,3.06-.724Z"
          fill="#eb6909"
        />
        <path
          id="Path_331"
          dataname="Path 331"
          d="M1860.7,1315.377l-3.073.727,1.453,12.609,3.073-.727Z"
          fill="#eb6909"
        />
        <path
          id="Path_332"
          dataname="Path 332"
          d="M1818.3,1329.655l1.255-4.542-3.488.825-1.4,5.083-.035.126,9.58,5.816,4.355-1.031Z"
          fill="#eb6909"
        />
        <path
          id="Path_333"
          dataname="Path 333"
          d="M1847.836,1327.7a1.561,1.561,0,1,0-3.122,0v4.412l3.122-.739Z"
          fill="#eb6909"
        />
        <path
          id="Path_334"
          dataname="Path 334"
          d="M1869.263,1300.708l-3.1,1.88,1.152,2.992,2.135,7.726,3.075-.728Z"
          fill="#5f9501"
        />
        <path
          id="Path_335"
          dataname="Path 335"
          d="M1834.368,1312.35v.065h-1.561a1.539,1.539,0,0,0-.751,1.163l-1.006,8.816,3.229-.765.9-7.737A1.6,1.6,0,0,0,1834.368,1312.35Z"
          fill="#5f9501"
        />
        <path
          id="Path_336"
          dataname="Path 336"
          d="M1823.386,1311.254l-3.507.83-3.81,13.855,3.488-.825Z"
          fill="#5f9501"
        />
        <path
          id="Path_337"
          dataname="Path 337"
          d="M1860.5,1313.583a1.551,1.551,0,0,0-.551-1.054,1.588,1.588,0,0,0-1.164-.366,1.524,1.524,0,0,0-1.044.555,1.6,1.6,0,0,0-.362,1.181l.254,2.206,3.073-.726Z"
          fill="#5f9501"
        />
        <path
          id="Path_338"
          dataname="Path 338"
          d="M1827.5,1304.921a1.59,1.59,0,0,0-.666-1.9l-3.634-2.2-.191-.115-3.129,11.379,3.507-.829,1.568-5.675.264.159a1.564,1.564,0,0,0,2.28-.818Z"
          fill="#79b61d"
        />
      </g>
      <path
        id="Path_339"
        dataname="Path 339"
        d="M1843.463,1307.444v11.349a2.868,2.868,0,0,0,.061.586l5.562-1.316v-10.618h11.251a2.822,2.822,0,0,0,2.812-2.836c0-.063-.014-.124-.018-.187a2.84,2.84,0,0,0-1.369-2.249l-22.277,5.271Z"
        fill="#5f9501"
      />
      <path
        id="Path_340"
        dataname="Path 340"
        d="M1846.275,1321.629a2.822,2.822,0,0,0,2.812-2.836v-.73l-5.562,1.316A2.816,2.816,0,0,0,1846.275,1321.629Z"
        fill="#eb6909"
      />
      <path
        id="Path_341"
        dataname="Path 341"
        d="M1849.086,1290.423a2.812,2.812,0,1,0-5.623,0v3.1l5.623-1.332Z"
        fill="#c8d200"
      />
      <path
        id="Path_342"
        dataname="Path 342"
        d="M1849.086,1301.772v-9.579l-5.623,1.332v8.248h-11.251a2.822,2.822,0,0,0-2.812,2.836c0,.063.014.124.018.187a2.814,2.814,0,0,0,2.794,2.649h7.273l22.277-5.271a2.754,2.754,0,0,0-1.425-.4Z"
        fill="#79b61d"
      />
      <ellipse
        id="Ellipse_1"
        dataname="Ellipse 1"
        cx="1.589"
        cy="1.603"
        rx="1.589"
        ry="1.603"
        transform="translate(1865.281 1302.418)"
        fill="#5f9501"
      />
    </g>
  </svg>
);

export default CreateNewPlanIcon;
