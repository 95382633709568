import React from 'react';
import { localized } from 'data/Localization';
import NutriguideLogo from 'components/Icons/NutriguideLogo';
import {
  useGetLocalizedLinkForLATWebsite,
  addCopyRightToText,
  websiteLat,
} from 'Utils/GlobalUtils';
import { localeBehaviour } from 'data/Behaviour';
import useUser from 'customHooks/globalHooks/useUser';

const Footer = () => {
  const { locale } = useUser();

  return (
    <footer className="footer">
      <div className="footer__wrapper">
        <NutriguideLogo tagline={false} className="footer__logo" />
        <a
          target="_blank"
          rel="noopener noreferrer"
          id="link_footer_cookies"
          className="footer__link"
          href={localized(locale, 'impressum_url')}
        >
          {localized(locale, 'impressum')}
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          id="terms_conditions_pdf"
          className="footer__link"
          href={localeBehaviour(locale, 'terms_conditions_pdf')}
        >
          {localized(locale, 'terms_conditions')}
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          id="privacy_notice_pdf"
          className="footer__link"
          href={localeBehaviour(locale, 'privacy_notice_pdf')}
        >
          {localized(locale, 'privacy_notice')}
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          id="link_footer_lat_website"
          className="footer__link"
          href={`${useGetLocalizedLinkForLATWebsite(websiteLat)}`}
        >
          {localized(locale, 'lat_website')}
        </a>
        <p id="link_footer_copyright" className="footer__copyright">
          {addCopyRightToText(localized(locale, 'pdf_copyright'))}
        </p>
      </div>
    </footer>
  );
};

export default Footer;
