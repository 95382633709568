/* eslint-disable react/prop-types */
import React from 'react';

const EnglishFrance = (props) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 48.017 46.638"
    space="preserve"
    {...props}
  >
    <g dataname="Group 1">
      <path
        fill="none"
        d="M34.644 3.023l.018.007q-.817-.361-1.662-.662l.04.016q.816.291 1.604.639z"
        dataname="Path 1"
        transform="translate(-.983 -2.362)"
      />
      <path
        fill="none"
        d="M5.808 10.56c-.088.117-.173.237-.259.356.086-.118.181-.229.269-.346z"
        dataname="Path 2"
        transform="translate(-.983 -2.362)"
      />
      <path
        fill="none"
        d="M20.153 48.51c-.076-.016-.15-.036-.226-.052.073.016.15.037.226.052z"
        dataname="Path 3"
        transform="translate(-.983 -2.362)"
      />
      <path
        fill="none"
        d="M11.37 5.249c.09-.062.179-.127.27-.188.12-.08.234-.168.355-.247-.49.316-.97.645-1.435.995.264-.195.54-.373.81-.56z"
        dataname="Path 4"
        transform="translate(-.983 -2.362)"
      />
      <path
        fill="none"
        d="M12.876 4.29q.675-.4 1.375-.747c.181-.091.361-.181.544-.267.145-.068.284-.146.43-.211A24.061 24.061 0 0012.1 4.747c.121-.077.249-.143.372-.218s.269-.16.404-.239z"
        dataname="Path 5"
        transform="translate(-.983 -2.362)"
      />
      <path
        fill="none"
        d="M15.635 47.1l-.047-.021z"
        dataname="Path 6"
        transform="translate(-.983 -2.362)"
      />
      <path
        fill="none"
        d="M16.727 47.536l-.064-.026z"
        dataname="Path 7"
        transform="translate(-.983 -2.362)"
      />
      <path
        fill="none"
        d="M17.847 47.915c-.047-.014-.092-.031-.139-.046l.139.046z"
        dataname="Path 8"
        transform="translate(-.983 -2.362)"
      />
      <path
        fill="none"
        d="M18.989 48.241c-.061-.016-.119-.035-.179-.051.06.016.118.035.179.051z"
        dataname="Path 9"
        transform="translate(-.983 -2.362)"
      />
      <path
        fill="none"
        d="M48.19 18.81c.016.06.035.118.051.179-.016-.061-.035-.119-.051-.179z"
        dataname="Path 10"
        transform="translate(-.983 -2.362)"
      />
      <path
        fill="none"
        d="M48.454 19.912c.018.081.039.16.056.241-.017-.081-.038-.16-.056-.241z"
        dataname="Path 11"
        transform="translate(-.983 -2.362)"
      />
      <path fill="none" d="M2.833 15.743z" dataname="Path 12" transform="translate(-.983 -2.362)" />
      <path
        fill="none"
        d="M5.668 39.219c-.032-.043-.066-.082-.1-.125.082.113.161.227.245.338l.005-.005a13.514 13.514 0 00-.15-.208z"
        dataname="Path 13"
        transform="translate(-.983 -2.362)"
      />
      <path
        fill="none"
        d="M3.665 13.971a23.949 23.949 0 011.75-2.871 23.949 23.949 0 00-1.75 2.871z"
        dataname="Path 14"
        transform="translate(-.983 -2.362)"
      />
      <path
        fill="none"
        d="M4.771 37.908a25.57 25.57 0 01-.58-.956c-.13-.227-.26-.454-.383-.685-.042-.079-.089-.154-.13-.233A23.99 23.99 0 005.4 38.851c-.044-.062-.082-.127-.126-.19-.174-.247-.34-.499-.503-.753z"
        dataname="Path 15"
        transform="translate(-.983 -2.362)"
      />
      <path
        fill="none"
        d="M3.151 34.929c-.1-.225-.215-.444-.31-.672.21.5.44.993.683 1.477-.038-.076-.07-.155-.107-.23-.092-.19-.178-.383-.266-.575z"
        dataname="Path 16"
        transform="translate(-.983 -2.362)"
      />
      <path
        fill="#d80027"
        d="M48.69 28.84A24.07 24.07 0 0049 25q0-.623-.031-1.237c-.007-.124-.02-.247-.029-.371a23.93 23.93 0 00-.064-.85c-.014-.131-.035-.26-.05-.39-.032-.272-.063-.544-.1-.813-.009-.06-.022-.119-.032-.179h-16.46l.96-.96L43.58 9.814q-.444-.543-.919-1.06L42 9.415 31.214 20.2l-.96.96-.454.454-.506.506-.454.454-.757.757-.2.2-.476.476-.678.679-2.037 2.037-.679.678-.476.476-.2.2-.757.757-.454.454-.506.506-.454.454-.96.96L9.415 42l-.661.662q.516.474 1.06.919L20.2 33.194l.96-.96V48.69c.06.01.119.023.179.032.269.041.541.072.813.1.13.015.259.036.39.05.282.028.566.046.85.064.124.009.247.022.371.029Q24.378 49 25 49a24.07 24.07 0 003.84-.31V32.234l.96.96 10.387 10.388q.931-.762 1.782-1.61.849-.849 1.61-1.782L33.194 29.8l-.96-.96H48.69z"
        dataname="Path 17"
        transform="translate(-.983 -2.362)"
      />
      <path
        fill="#d80027"
        d="M41.233 7.353c-.116-.106-.224-.219-.341-.323l-.163-.142c-.181-.157-.372-.3-.558-.454-.248-.2-.491-.415-.748-.609a24.339 24.339 0 00-1.426-.993l-.149-.1a24.013 24.013 0 00-3.07-1.653l-.021-.01-.1-.043-.018-.007q-.789-.348-1.606-.639L33 2.368l-.015-.005V15.6l7.6-7.6z"
        dataname="Path 18"
        transform="translate(-.983 -2.362)"
      />
      <path
        fill="#0052b4"
        d="M10.573 44.179a23.984 23.984 0 005.015 2.9l.047.021c.338.144.682.279 1.028.407l.064.026c.324.118.652.228.981.333.047.015.092.032.139.046.317.1.639.189.963.275.06.016.118.035.179.051.309.08.623.149.938.217.076.016.15.036.226.052l.047.009V34.552l-9.627 9.627z"
        dataname="Path 19"
        transform="translate(-.983 -2.362)"
      />
      <path
        fill="#0052b4"
        d="M48.518 20.2l-.009-.047c-.017-.081-.038-.16-.056-.241a23.755 23.755 0 00-.213-.923c-.016-.061-.035-.119-.051-.179a21.735 21.735 0 00-.275-.963c-.014-.047-.031-.092-.046-.139-.1-.329-.215-.657-.333-.981l-.026-.064c-.128-.346-.263-.69-.407-1.028l-.021-.047a24.01 24.01 0 00-2.9-5.015L34.552 20.2z"
        dataname="Path 20"
        transform="translate(-.983 -2.362)"
      />
      <path
        fill="#0052b4"
        d="M29.8 48.519a23.889 23.889 0 009.627-4.34L29.8 34.552z"
        dataname="Path 21"
        transform="translate(-.983 -2.362)"
      />
      <path
        fill="#0052b4"
        d="M44.179 39.427a23.889 23.889 0 004.34-9.627H34.552z"
        dataname="Path 22"
        transform="translate(-.983 -2.362)"
      />
      <path
        fill="#0052b4"
        d="M39.425 5.824a23.896 23.896 0 00-.002.001z"
        dataname="Path 23"
        transform="translate(-.983 -2.362)"
      />
      <path
        fill="#0052b4"
        d="M16.983 30.98V2.362c-.125.045-.245.1-.37.147q-.513.192-1.015.4c-.1.042-.2.076-.3.12l-.072.032c-.146.065-.285.143-.43.211-.183.086-.363.176-.544.267q-.7.351-1.375.747c-.135.079-.272.157-.405.239s-.251.141-.372.218l-.1.067c-.121.079-.235.167-.355.247-.091.061-.18.126-.27.188-.27.187-.546.365-.807.562-.234.176-.453.368-.679.552l-.07.057-.163.132c-.147.123-.3.237-.446.364L9.1 7c-.317.279-.618.575-.919.871-.11.108-.227.208-.335.318l-.069.072c-.048.049-.09.1-.138.152-.121.127-.236.261-.355.391l-.105.116c-.178.2-.364.387-.535.59l-.059.067c-.061.073-.115.152-.175.226-.2.249-.4.506-.6.763-.088.117-.183.228-.269.346l-.134.189c-.122.172-.231.353-.349.528l-.008.012a24.235 24.235 0 00-1.393 2.326l-.136.266c-.248.493-.482.994-.7 1.506-.019.044-.035.089-.053.133-.2.477-.375.963-.541 1.456-.032.1-.067.193-.1.29q-.251.779-.45 1.578l-.069.3c-.049.209-.085.424-.128.635l-.012.061c-.053.262-.114.521-.158.786-.01.058-.022.116-.031.174 0 .02-.008.04-.011.06a23.73 23.73 0 00-.2 1.66c-.011.126-.019.253-.029.38-.011.157-.01.318-.019.476v.1c-.011.246-.025.491-.029.739 0 .139-.017.275-.017.415s.015.276.017.415c0 .238.017.473.028.708 0 .057 0 .113.007.17.008.145.007.293.017.437a23.214 23.214 0 00.23 2.044c0 .025.009.05.014.075.008.053.019.1.028.157.036.219.088.433.131.651l.03.152.041.186c.034.161.06.325.1.485.025.107.048.215.074.322.06.24.136.474.2.711l.023.082c.074.257.139.519.221.773.033.1.069.2.1.305.045.133.1.262.147.395q.085.239.176.475c.068.176.126.356.2.53.026.064.049.129.076.193.1.228.208.447.31.672.088.192.174.385.266.575.037.075.069.154.107.23.05.1.1.2.154.3.041.079.088.154.13.233.124.231.253.459.383.685q.279.486.58.956c.163.254.328.506.5.753.044.063.082.128.126.19.058.081.115.163.174.243.031.043.065.082.1.125.051.069.1.139.153.208h.005c.194.258.39.514.594.763.031.038.059.078.089.115s.049.064.075.1.055.063.082.094c.057.067.119.129.177.2.164.189.334.371.5.555l.661-.66 8.983-8.985z"
        dataname="Path 24"
        transform="translate(-.983 -2.362)"
      />
    </g>
  </svg>
);

export default EnglishFrance;
