/* eslint-disable react/prop-types */
import React from 'react';

const LPIcon3 = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="89.522"
    height="80.994"
    viewBox="0 0 89.522 80.994"
    {...props}
  >
    <g id="Product_icon" transform="translate(2 2)">
      <rect
        id="Rectangle_658"
        width="17.104"
        height="17.104"
        rx="8.552"
        transform="translate(51.313 59.89)"
        strokeWidth="4"
        stroke="#849daa"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <rect
        id="Rectangle_659"
        width="17.104"
        height="17.104"
        rx="8.552"
        transform="translate(51.313 30.263)"
        strokeWidth="4"
        stroke="#849daa"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <rect
        id="Rectangle_660"
        width="17.104"
        height="17.104"
        rx="8.552"
        transform="translate(34.209 30.263)"
        strokeWidth="4"
        stroke="#849daa"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <rect
        id="Rectangle_661"
        width="17.104"
        height="17.104"
        rx="8.552"
        transform="translate(34.209)"
        strokeWidth="4"
        stroke="#849daa"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <rect
        id="Rectangle_662"
        width="17.104"
        height="17.104"
        rx="8.552"
        transform="translate(68.417 59.89)"
        strokeWidth="4"
        stroke="#849daa"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <rect
        id="Rectangle_663"
        width="17.104"
        height="17.104"
        rx="8.552"
        transform="translate(59.865 45.113)"
        strokeWidth="4"
        stroke="#849daa"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <rect
        id="Rectangle_664"
        width="17.104"
        height="17.104"
        rx="8.552"
        transform="translate(34.209 59.89)"
        strokeWidth="4"
        stroke="#849daa"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <rect
        id="Rectangle_665"
        width="17.104"
        height="17.104"
        rx="8.552"
        transform="translate(17.104 59.89)"
        strokeWidth="4"
        stroke="#849daa"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <rect
        id="Rectangle_666"
        width="17.104"
        height="17.104"
        rx="8.552"
        transform="translate(42.761 45.113)"
        strokeWidth="4"
        stroke="#849daa"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <rect
        id="Rectangle_667"
        width="17.104"
        height="17.104"
        rx="8.552"
        transform="translate(25.656 45.113)"
        strokeWidth="4"
        stroke="#849daa"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <rect
        id="Rectangle_668"
        width="17.104"
        height="17.104"
        rx="8.552"
        transform="translate(42.761 14.85)"
        strokeWidth="4"
        stroke="#849daa"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <rect
        id="Rectangle_669"
        width="17.104"
        height="17.104"
        rx="8.552"
        transform="translate(25.656 14.85)"
        strokeWidth="4"
        stroke="#849daa"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <rect
        id="Rectangle_670"
        width="17.104"
        height="17.104"
        rx="8.552"
        transform="translate(17.104 30.263)"
        strokeWidth="4"
        stroke="#849daa"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <rect
        id="Rectangle_671"
        width="17.104"
        height="17.104"
        rx="8.552"
        transform="translate(0 59.89)"
        strokeWidth="4"
        stroke="#849daa"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <rect
        id="Rectangle_672"
        width="17.104"
        height="17.104"
        rx="8.552"
        transform="translate(8.552 45.113)"
        strokeWidth="4"
        stroke="#849daa"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </g>
  </svg>
);

export default LPIcon3;
