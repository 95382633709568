/* eslint-disable import/prefer-default-export */
/* eslint-disable react/prop-types */
import React from 'react';
import { PropTypes } from 'prop-types';
import { useLocalizedFromContext } from 'data/Localization';

export const Crop13 = ({ title, fill, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="134.095"
    height="163.628"
    viewBox="0 0 134.095 163.628"
    {...props}
  >
    <title>{useLocalizedFromContext(title)}</title>
    <g id="Component_1_1" dataname="Component 1 – 1" transform="translate(2.5 2.5)">
      <g id="Group_1018" dataname="Group 1018" transform="translate(-1009.192 -6439.274)">
        <path
          id="Path_4807"
          dataname="Path 4807"
          d="M1058.665,6585.44s2.919-71.606-29.957-95.656c0,0,13.052,6.312,13.052,95.656Z"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
        <path
          id="Path_4808"
          dataname="Path 4808"
          d="M1039.838,6535.593s-15.73-22.867-23.836-23.867c0,0,12.706,17.041,12.706,73.714h12.418"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
        <path
          id="Path_4809"
          dataname="Path 4809"
          d="M1127.754,6585.44H1015.632s1.15-25.448-6.44-43.338c0,0,18.69,11.018,18.69,21.345"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
        <path
          id="Path_4810"
          dataname="Path 4810"
          d="M1088.814,6585.44s-2.919-71.606,29.958-95.656c0,0-13.053,6.312-13.053,95.656Z"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
        <path
          id="Path_4811"
          dataname="Path 4811"
          d="M1107.641,6535.593s15.731-22.867,23.837-23.867c0,0-12.706,17.041-12.706,73.714h-12.418"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
        <path
          id="Path_4812"
          dataname="Path 4812"
          d="M1019.725,6585.44h112.122s-1.149-25.448,6.44-43.338c0,0-18.689,11.018-18.689,21.345"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
        <rect
          id="Rectangle_169"
          dataname="Rectangle 169"
          width="116.208"
          height="12.463"
          transform="translate(1015.633 6585.44)"
          strokeWidth="5"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="none"
        />
      </g>
      <g id="Group_1020" dataname="Group 1020" transform="translate(-1009.192 -6439.274)">
        <line
          id="Line_411"
          dataname="Line 411"
          y2="136.031"
          transform="translate(1074.053 6461.074)"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="5"
        />
        <g id="Group_1019" dataname="Group 1019">
          <path
            id="Path_4813"
            dataname="Path 4813"
            d="M1073.812,6463.435s4.505-5.98,4.417-12.393-4.654-11.768-4.654-11.768-4.41,5.986-4.329,11.891c.089,6.414,4.566,12.27,4.566,12.27"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
        <path
          id="Path_4814"
          dataname="Path 4814"
          d="M1073.735,6465.563s.2-4.522-5.484-8.651-6.3-17.107-6.3-17.107-4.042,15.756.427,23.032,7.3,3.871,11.356,9.331Z"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5 "
        />
        <path
          id="Path_4815"
          dataname="Path 4815"
          d="M1073.737,6465.563s-.2-4.522,5.485-8.651,6.3-17.107,6.3-17.107,4.042,15.756-.427,23.032-7.3,3.871-11.357,9.331Z"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
        <path
          id="Path_4816"
          dataname="Path 4816"
          d="M1073.735,6489.193s.2-4.522-5.484-8.652-6.3-17.106-6.3-17.106-4.042,15.756.427,23.031,7.3,3.872,11.356,9.332Z"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
        <path
          id="Path_4817"
          dataname="Path 4817"
          d="M1073.737,6489.193s-.2-4.522,5.485-8.652,6.3-17.106,6.3-17.106,4.042,15.756-.427,23.031-7.3,3.872-11.357,9.332Z"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
        <path
          id="Path_4818"
          dataname="Path 4818"
          d="M1073.735,6511.223s.2-4.522-5.484-8.652-6.3-17.106-6.3-17.106-4.042,15.756.427,23.031,7.3,3.872,11.356,9.332Z"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
        <path
          id="Path_4819"
          dataname="Path 4819"
          d="M1073.737,6511.223s-.2-4.522,5.485-8.652,6.3-17.106,6.3-17.106,4.042,15.756-.427,23.031-7.3,3.872-11.357,9.332Z"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
        <path
          id="Path_4820"
          dataname="Path 4820"
          d="M1073.735,6533.253s.2-4.522-5.484-8.652-6.3-17.107-6.3-17.107-4.042,15.757.427,23.032,7.3,3.872,11.356,9.332Z"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
        <path
          id="Path_4821"
          dataname="Path 4821"
          d="M1073.737,6533.253s-.2-4.522,5.485-8.652,6.3-17.107,6.3-17.107,4.042,15.757-.427,23.032-7.3,3.872-11.357,9.332Z"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
        <path
          id="Path_4822"
          dataname="Path 4822"
          d="M1073.735,6555.756s.2-4.523-5.484-8.652-6.3-17.107-6.3-17.107-4.042,15.757.427,23.032,7.3,3.872,11.356,9.332Z"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
        <path
          id="Path_4823"
          dataname="Path 4823"
          d="M1073.737,6555.756s-.2-4.523,5.485-8.652,6.3-17.107,6.3-17.107,4.042,15.757-.427,23.032-7.3,3.872-11.357,9.332Z"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
      </g>
    </g>
  </svg>
);

Crop13.defaultProps = {
  title: 'grasses_type_rye',
};

Crop13.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
};
