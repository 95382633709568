/* eslint-disable react/prop-types */
import React from 'react';

const LPIcon2 = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="74.837"
    height="117.969"
    viewBox="0 0 74.837 117.969"
    {...props}
  >
    <g id="Component_2_1" transform="translate(1.5 1.5)">
      <g id="Group_1093" transform="translate(2.738 73.934)">
        <line
          id="Line_127"
          x2="12.604"
          transform="translate(56.495 6.81)"
          fill="none"
          stroke="#849daa"
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="3"
        />
        <line
          id="Line_128"
          x2="36.688"
          transform="translate(0 6.81)"
          fill="none"
          stroke="#849daa"
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="3"
        />
        <g id="Group_1092" transform="translate(36.689 0)">
          <path
            id="Path_1163"
            d="M324.44,5931.014c0,5.592-10.126,20.5-10.126,20.5s-10.126-14.906-10.126-20.5a10.126,10.126,0,1,1,20.253,0Z"
            transform="translate(-304.187 -5920.887)"
            fill="none"
            stroke="#849daa"
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="3"
          />
          <path
            id="Path_1164"
            d="M318.127,5966.061c0,3.039-.213,2.914-1.655,4.355s.956,3.776,2.613,5.433"
            transform="translate(-308.001 -5935.436)"
            fill="none"
            stroke="#849daa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="3"
          />
          <line
            id="Line_129"
            x2="2.489"
            transform="translate(9.958 33.058)"
            fill="none"
            stroke="#849daa"
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="3"
          />
          <line
            id="Line_130"
            x2="2.489"
            transform="translate(5.54 36.555)"
            fill="none"
            stroke="#849daa"
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="3"
          />
          <line
            id="Line_131"
            x2="4.65"
            transform="translate(15.602 22.419)"
            fill="none"
            stroke="#849daa"
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="3"
          />
          <line
            id="Line_132"
            x2="4.65"
            transform="translate(2.719 26.831)"
            fill="none"
            stroke="#849daa"
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="3"
          />
        </g>
      </g>
      <g id="Group_1094" transform="translate(41.957 44.954)">
        <path
          id="Path_1165"
          d="M318.763,5916.335c-.077.339-2-7.205-2.88-14.421"
          transform="translate(-310.484 -5885.797)"
          fill="none"
          stroke="#849daa"
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="3"
        />
        <path
          id="Path_1166"
          d="M320.267,5919.406a21.551,21.551,0,0,0,3.866-8"
          transform="translate(-311.896 -5888.853)"
          fill="none"
          stroke="#849daa"
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="3"
        />
        <path
          id="Path_1167"
          d="M312.242,5880.433s-3.632,6.064-4.21,10.823c-.475,3.908.586,5.943,2.488,6.971a23.042,23.042,0,0,0,3.731,1.638s1.849-1.641,3.085-2.875c1.028-1.026,2.245-2.906.5-7.51A35.681,35.681,0,0,0,312.242,5880.433Z"
          transform="translate(-307.919 -5878.878)"
          fill="none"
          stroke="#849daa"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3"
        />
        <path
          id="Path_1168"
          d="M331.187,5878.14s4.8,6.057,4.594,12.007-9.812,10.543-9.812,10.543,1.238-7.57,1.551-10.7C327.8,5887.168,327.742,5880.959,331.187,5878.14Z"
          transform="translate(-313.733 -5878.14)"
          fill="none"
          stroke="#849daa"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3"
        />
        <path
          id="Path_1169"
          d="M330.879,5899.545s-.864,2.377-4.91,8.039"
          transform="translate(-313.733 -5885.034)"
          fill="none"
          stroke="#849daa"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3"
        />
      </g>
      <path
        id="Path_1170"
        d="M279.431,5907.945s-.315-26.907-.315-48.278"
        transform="translate(-256.686 -5827.236)"
        fill="none"
        stroke="#849daa"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      />
      <path
        id="Path_1171"
        d="M268.46,5913.12c-2.607.266-4.248.533-9.491-1.555-5.019-2-8.024-7.047-12.939-7.343,0,0,14.186-2.487,22.43,6.988"
        transform="translate(-246.03 -5841.491)"
        fill="none"
        stroke="#849daa"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      />
      <path
        id="Path_1172"
        d="M279.46,5895.185c7.385,4.993,10.914-2.256,13.547-13.415a114.439,114.439,0,0,1-13.8,10.551"
        transform="translate(-256.715 -5834.355)"
        fill="none"
        stroke="#849daa"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      />
      <path
        id="Path_1173"
        d="M271.761,5875.224c-9.324,6.706-13.593-4.9-15.483-12.789,0,0,9.3,10.85,15.545,11.25"
        transform="translate(-249.331 -5828.128)"
        fill="none"
        stroke="#849daa"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      />
      <g id="Group_1095" transform="translate(10.651)">
        <path
          id="Path_1174"
          d="M273.041,5826.91v-9.1a7.994,7.994,0,0,1,1.95-5.107v0a3.027,3.027,0,0,1,4.256,0h0a8,8,0,0,1,1.96,5.119v8.9"
          transform="translate(-265.38 -5811.83)"
          fill="none"
          stroke="#849daa"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3"
        />
        <path
          id="Path_1175"
          d="M278.216,5847.6s-6.756-19.513-16.475-14.3c0,0,4.435,3.629,5.282,8.408s1.139,9.183,6.021,9.183"
          transform="translate(-261.741 -5818.459)"
          fill="none"
          stroke="#849daa"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3"
        />
        <path
          id="Path_1176"
          d="M279.144,5837.759c2.485-3.186,6.577-6.8,11.224-4.408,0,0-4.436,3.63-5.282,8.408s-1.139,9.183-6.021,9.183"
          transform="translate(-267.32 -5818.511)"
          fill="none"
          stroke="#849daa"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3"
        />
      </g>
      <path
        id="Path_1177"
        d="M316.726,5940.938a16.951,16.951,0,0,0,11.305-1.392"
        transform="translate(-268.799 -5852.962)"
        fill="none"
        stroke="#849daa"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      />
    </g>
  </svg>
);

export default LPIcon2;
