import { STATUS_GETTING } from 'Redux/actions/types';
import { store } from 'Redux/createStore';
import {
  NG4SupportedCountries,
  PostalCodeAndCityNotObligatoryAtXMLUploadCounties,
  NG4SupportedCountriesForLP,
} from 'constants/index.js';
import LocalizationService from 'services/LocalizationService';

class UserUtils {
  convertFrontendToBackend = (input) => {
    const output = {
      acceptCommunication: input.acceptCommunication,
      termsAcceptedId: input.termsAcceptedId,
      role: input.role,
      id_token: input.id_token,
      country: input.country,
      languageCode: input.lang,
    };

    if (input.role === 'farmer' || this.isNG4()) {
      output.firstName = input.firstName;
      output.lastName = input.lastName;
      output.role = 'farmer';
    }

    if (input.role === 'reseller') {
      output.organisation = input.organisation;
      output.userName = input.userName;
    }

    return output;
  };

  convertBackendToFrontend = (input) => {
    const output = {
      userName: input.userName,
      firstName: input.firstName,
      lastName: input.lastName,
      country: input.country,
      lang: input.languageCode,
      locale: LocalizationService.getLocaleFromLangAndCountry(input.languageCode, input.country),
      email: input.email,
      acceptCommunication: input.acceptCommunication,
      role: input.role,
      termsCompliant: input.termsCompliant,
      termsAcceptedId: input.termsAcceptedId,
      organisation: input.organisation,
      usedMobileApp: input.usedMobileApp,
    };
    return output;
  };

  isOnboarded = (user) => {
    if (!user) {
      return false;
    }
    if (user.status === STATUS_GETTING) {
      return true;
    }
    if (!user.role) {
      return false;
    }
    if (user.role === 'reseller' && (!user.userName || !user.organisation)) {
      return false;
    }
    if (user.role === 'farmer' && (!user.firstName || !user.lastName)) {
      return false;
    }
    if (user.isOnboarding) {
      return false;
    }
    return true;
  };

  isLoggedIn = (user) => user.id_token != null;

  isInDatabase = (user) => user.IN_DATABASE === true;

  shouldRenewTerms = (user) => {
    if (user.IN_DATABASE && user.termsCompliant !== undefined && !user.termsCompliant) {
      return true;
    }
    return false;
  };

  isNG4 = (state) => {
    if (state) {
      return NG4SupportedCountries.includes(state.country);
    }
    return NG4SupportedCountries.includes(store.getState().user.country);
  };

  // Checks if NG4 version should be presented for Landing page
  isNG4AtLP = (state) => {
    if (state) {
      return NG4SupportedCountriesForLP.includes(state.country);
    }
    return NG4SupportedCountriesForLP.includes(store.getState().user.country);
  };

  isAT = () => store.getState().user.country === 'AT';

  isPostalCodeAndCityNotObligatoryAtPlotImport = () =>
    PostalCodeAndCityNotObligatoryAtXMLUploadCounties.includes(store.getState().user.country);

  getLocale = () => {
    const { lang, country } = store.getState().user;
    return LocalizationService.createLocaleFromLangAndCountry(lang, country);
  };
}
export default new UserUtils();
