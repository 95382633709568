import {
  UPDATE_MY_APPLICATIONS_SEARCH_TERMS_NG4,
  RESET_PLANS,
  DELETE_PLAN_FOR_PLOT_ID_NG4,
  MERGE_NG4_PLAN,
  RESET_PLAN_STATUS,
} from './types';

export const updateSearchStringNG4 = (update, lang, role) => ({
  type: UPDATE_MY_APPLICATIONS_SEARCH_TERMS_NG4,
  payload: { update, lang, role },
});

export const resetPlans = () => ({
  type: RESET_PLANS,
  payload: null,
});

export const resetPlanStatus = () => ({
  type: RESET_PLAN_STATUS,
});

export const deletePlansForPlotIdNG4 = (plotId, lang, role) => ({
  type: DELETE_PLAN_FOR_PLOT_ID_NG4,
  payload: { plotId, lang, role },
});

export const mergePlansNG4 = (updatedPlans, lang, role) => ({
  type: MERGE_NG4_PLAN,
  payload: {
    plans: updatedPlans,
    lang,
    role,
  },
});
