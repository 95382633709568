/* eslint-disable import/prefer-default-export */
/* eslint-disable react/prop-types */
import React from 'react';
import { PropTypes } from 'prop-types';
import { useLocalizedFromContext } from 'data/Localization';

export const Crop19 = ({ title, fill, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="176.578"
    height="117.631"
    viewBox="0 0 176.578 117.631"
    {...props}
  >
    <title>{useLocalizedFromContext(title)}</title>
    <g id="Component_2_1" data-name="Component 2 – 1" transform="translate(2.5 2.5)">
      <line
        id="Line_412"
        data-name="Line 412"
        x2="2.426"
        y2="5.526"
        transform="translate(39.805 92.375)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <line
        id="Line_413"
        data-name="Line 413"
        y1="7.838"
        x2="3.748"
        transform="translate(75.393 99.737)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <line
        id="Line_414"
        data-name="Line 414"
        x2="8.461"
        y2="0.332"
        transform="translate(54.31 94.059)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <line
        id="Line_415"
        data-name="Line 415"
        x1="10.767"
        y2="2.665"
        transform="translate(67.404 80.874)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <line
        id="Line_416"
        data-name="Line 416"
        x1="1.664"
        y2="1.323"
        transform="translate(59.372 106.252)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <line
        id="Line_417"
        data-name="Line 417"
        x1="1.664"
        y2="1.323"
        transform="translate(53.14 80.403)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <line
        id="Line_418"
        data-name="Line 418"
        x1="1.664"
        y2="1.323"
        transform="translate(45.71 106.252)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <line
        id="Line_419"
        data-name="Line 419"
        x1="1.664"
        y2="1.323"
        transform="translate(82.509 91.189)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <line
        id="Line_420"
        data-name="Line 420"
        x2="10.974"
        y2="2.938"
        transform="translate(64.291 56.928)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <line
        id="Line_421"
        data-name="Line 421"
        x1="8.183"
        y2="0.135"
        transform="translate(46.99 64.646)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <line
        id="Line_422"
        data-name="Line 422"
        x1="1.972"
        y2="0.747"
        transform="translate(65.378 70.028)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <line
        id="Line_423"
        data-name="Line 423"
        x1="6.183"
        y2="0.135"
        transform="translate(48.242 44.04)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <line
        id="Line_424"
        data-name="Line 424"
        x1="1.972"
        y2="0.747"
        transform="translate(71.615 45.324)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <line
        id="Line_425"
        data-name="Line 425"
        x1="1.972"
        y2="0.747"
        transform="translate(89.771 63.538)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <line
        id="Line_426"
        data-name="Line 426"
        x1="0.832"
        y2="0.662"
        transform="translate(30.358 106.914)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <line
        id="Line_427"
        data-name="Line 427"
        x1="1.664"
        y2="1.323"
        transform="translate(40.401 78.787)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <line
        id="Line_428"
        data-name="Line 428"
        x1="1.664"
        y2="1.323"
        transform="translate(32.705 70.418)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <line
        id="Line_429"
        data-name="Line 429"
        x1="0.832"
        y2="0.662"
        transform="translate(29.993 86.385)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <line
        id="Line_430"
        data-name="Line 430"
        x2="2.426"
        y2="5.526"
        transform="translate(20.197 91.531)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <line
        id="Line_431"
        data-name="Line 431"
        x1="0.832"
        y2="0.662"
        transform="translate(12.735 106.914)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <line
        id="Line_432"
        data-name="Line 432"
        x1="2.426"
        y2="5.526"
        transform="translate(125.513 86.229)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <line
        id="Line_433"
        data-name="Line 433"
        x1="5.461"
        y2="0.332"
        transform="translate(90.269 105.107)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <line
        id="Line_434"
        data-name="Line 434"
        x1="8.461"
        y2="0.332"
        transform="translate(104.98 94.059)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <line
        id="Line_435"
        data-name="Line 435"
        x2="7.767"
        y2="2.665"
        transform="translate(98.558 79.85)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <line
        id="Line_436"
        data-name="Line 436"
        x2="1.664"
        y2="1.323"
        transform="translate(106.715 106.252)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <line
        id="Line_437"
        data-name="Line 437"
        x2="1.664"
        y2="1.323"
        transform="translate(114.924 80.403)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <line
        id="Line_438"
        data-name="Line 438"
        x2="1.664"
        y2="1.323"
        transform="translate(122.353 106.252)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <line
        id="Line_439"
        data-name="Line 439"
        x2="1.664"
        y2="1.323"
        transform="translate(93.524 91.189)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <line
        id="Line_440"
        data-name="Line 440"
        x2="6.384"
        y2="5.742"
        transform="translate(84.948 73.704)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <line
        id="Line_441"
        data-name="Line 441"
        x1="7.975"
        y2="2.938"
        transform="translate(98.59 49.125)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <line
        id="Line_442"
        data-name="Line 442"
        x2="8.183"
        y2="0.135"
        transform="translate(116.136 68.744)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <line
        id="Line_443"
        data-name="Line 443"
        x2="1.972"
        y2="0.747"
        transform="translate(103.401 65.931)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <line
        id="Line_444"
        data-name="Line 444"
        x2="1.972"
        y2="0.747"
        transform="translate(84.985 52.269)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <line
        id="Line_445"
        data-name="Line 445"
        x1="1.972"
        y2="0.747"
        transform="translate(88.141 41.795)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <line
        id="Line_446"
        data-name="Line 446"
        x2="1.972"
        y2="0.747"
        transform="translate(74.912 36.932)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <line
        id="Line_447"
        data-name="Line 447"
        x1="7.975"
        y2="2.938"
        transform="translate(101.818 36.209)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <line
        id="Line_448"
        data-name="Line 448"
        x2="0.832"
        y2="0.662"
        transform="translate(134.568 103.167)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <line
        id="Line_449"
        data-name="Line 449"
        x2="1.664"
        y2="1.323"
        transform="translate(135.897 70.065)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <line
        id="Line_450"
        data-name="Line 450"
        x2="1.664"
        y2="1.323"
        transform="translate(114.924 57.437)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <line
        id="Line_451"
        data-name="Line 451"
        x2="8.183"
        y2="0.135"
        transform="translate(113.563 45.778)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <line
        id="Line_452"
        data-name="Line 452"
        x2="1.664"
        y2="1.323"
        transform="translate(127.663 55.82)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <line
        id="Line_453"
        data-name="Line 453"
        x2="0.832"
        y2="0.662"
        transform="translate(138.903 87.409)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <line
        id="Line_454"
        data-name="Line 454"
        x1="2.426"
        y2="5.526"
        transform="translate(148.798 92.059)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <line
        id="Line_455"
        data-name="Line 455"
        x2="1.664"
        y2="1.323"
        transform="translate(146.631 106.252)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <line
        id="Line_456"
        data-name="Line 456"
        x2="0.832"
        y2="0.662"
        transform="translate(158.846 104.9)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <line
        id="Line_457"
        data-name="Line 457"
        x2="0.832"
        y2="0.662"
        transform="translate(162.188 93.24)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <line
        id="Line_458"
        data-name="Line 458"
        x1="1.972"
        y2="0.747"
        transform="translate(51.442 53.143)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <g id="Component_1_1" data-name="Component 1 – 1">
        <path
          id="Path_4824"
          data-name="Path 4824"
          d="M2579.272,5876.671c38.06,0,67.492,56.649,79.894,84.365,1.324,2.959,5.387,1.989,5.4-1.312v-.136c0-40.032-36.528-109.36-85.289-109.36s-86.289,69.328-86.289,109.36v.136c.008,3.3,4.071,4.271,5.4,1.312C2510.78,5933.32,2541.212,5876.671,2579.272,5876.671Z"
          transform="translate(-2492.983 -5850.228)"
          fill="#fff"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
      </g>
    </g>
  </svg>
);

Crop19.defaultProps = {
  title: 'corn_sillage',
};

Crop19.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
};
