/* eslint-disable import/prefer-default-export */
/* eslint-disable react/prop-types */
import React from 'react';
import { PropTypes } from 'prop-types';
import { useLocalizedFromContext } from 'data/Localization';

export const Crop15 = ({ title, fill, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="93.669"
    height="83.126"
    viewBox="0 0 93.669 83.126"
    {...props}
  >
    <title>{useLocalizedFromContext(title)}</title>
    <g id="Component_2_1" dataname="Component 2 – 1" transform="translate(2.506 2.587)">
      <path
        id="Path_4716"
        dataname="Path 4716"
        d="M2611.856,5303.655c9.665-2.558,13.642-9.557,13.642-18.121,0-10.1-2.924-23.594-15.353-22.169-4.344.5-10.945,5.587-10.945,14.572"
        transform="translate(-2536.834 -5245.371)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_4717"
        dataname="Path 4717"
        d="M2563.435,5304.753c.628-5.027,6.6-12.617,17.013-12.512s10.107.99,13.986-2.225,18.409-6.324,17.369,8.756-11.4,21.664-22.859,24.032C2577.222,5325.226,2561.637,5319.139,2563.435,5304.753Z"
        transform="translate(-2536.834 -5245.371)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_4718"
        dataname="Path 4718"
        d="M2609.156,5263.261a24.259,24.259,0,0,0-4.333-9.1c-4.01-5.1-11.3-11.183-21.137-7.834s-6.071,15.074-3.978,20.308"
        transform="translate(-2536.834 -5245.371)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_4719"
        dataname="Path 4719"
        d="M2561.3,5265.046a13.924,13.924,0,0,0-3.816-1.252c-13.638-2.551-19.938,5.526-20.566,15.784s2.581,16.629,5.242,20.011c4.009,5.1,11.3,11.183,21.136,7.833"
        transform="translate(-2536.834 -5245.371)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_4720"
        dataname="Path 4720"
        d="M2602.879,5286.626a19.423,19.423,0,0,0,.119-2.41s-.145-5.347-8.4-6.045-3.476-8.791-18.535-10.466-21.72,8.233-16.363,19.815a22.518,22.518,0,0,0,8.067,9.512"
        transform="translate(-2536.834 -5245.371)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_4721"
        dataname="Path 4721"
        d="M2563.292,5269.912s-4.15-6.526-.7-13.439c3.071-6.153,10.478-6.877,15.626-5.534"
        transform="translate(-2536.834 -5245.371)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_4722"
        dataname="Path 4722"
        d="M2587.114,5301.552s5.658,1.572,12.086-3.536"
        transform="translate(-2536.834 -5245.371)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_4723"
        dataname="Path 4723"
        d="M2577.222,5292.47s-6.947-4.811-8.147-10.194"
        transform="translate(-2536.834 -5245.371)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_4724"
        dataname="Path 4724"
        d="M2588,5254.911a9.628,9.628,0,0,1,8.9,4.255"
        transform="translate(-2536.834 -5245.371)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_4725"
        dataname="Path 4725"
        d="M2605.719,5277.665s4.65,3.595,3.437,11.878"
        transform="translate(-2536.834 -5245.371)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
    </g>
  </svg>
);

Crop15.defaultProps = {
  title: 'faba_beans',
};

Crop15.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
};
