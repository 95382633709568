/* eslint-disable react/prop-types */
import React from 'react';

const FieldInfoIcon = (props) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 128 128"
      enableBackground="new 0 0 128 128"
      xmlSpace="preserve"
      {...props}
    >
      <g>
        <defs>
          <path
            id="SVGID_85_FieldInfoIcon"
            d="M78.4,103.2c4.9-2,9.4-5,13.3-8.9c11.8-11.8,15.4-29.5,9.1-45c-0.1-0.4-0.4-0.7-0.8-0.8
            c-0.4-0.2-0.8-0.2-1.1,0l-3.1,1.3c-5.9-13.1-18.8-21.5-33.3-21.5c-10.1,0-19.2,4.1-25.8,10.7c0,0,0,0,0,0s0,0,0,0
            C30,45.6,25.9,54.8,25.9,64.8c0,14.8,9.1,28.3,22.7,33.8l-1.2,3.4c-0.1,0.4-0.1,0.8,0.1,1.1c0.2,0.4,0.5,0.6,0.9,0.8
            c4.5,1.6,9.3,2.4,14,2.4c1.1,0,2.3-0.1,3.4-0.2c0,0,0.1,0,0.1,0c0.1,0,0.2,0,0.3,0c4.1-0.4,8.2-1.4,12-2.9
            C78.1,103.3,78.3,103.2,78.4,103.2z M66.3,103.2L54.5,91.4c0,0,0,0-0.1,0l2.8-7.7l17.7,17.7C72.1,102.2,69.2,102.9,66.3,103.2z
            M58.4,80.5l2.8-7.7l23.5,23.5c-2.1,1.5-4.4,2.8-6.7,3.8L58.4,80.5z M89.6,92.1c-0.8,0.8-1.7,1.6-2.5,2.3L62.3,69.6l1.3-3.6l4-1.6
            l24.7,24.7C91.4,90.2,90.5,91.2,89.6,92.1z M78.1,60.2l19.7,19.7c-1,2.4-2.2,4.6-3.7,6.8L70.6,63.2L78.1,60.2z M88.6,55.9
            L100.7,68c-0.2,3-0.9,5.9-1.8,8.7L81.1,58.9L88.6,55.9z M100.8,63.9l-9.2-9.2l3.9-1.6c0,0,0,0,0,0c0,0,0,0,0,0l3.1-1.2
            C100,55.8,100.7,59.8,100.8,63.9z M92.9,50.9l-15,6.1c0,0,0,0,0,0l-10.3,4.2c-0.1,0-0.2,0.1-0.4,0.1l-4.3,1.8l-23-23
            c6-5.5,13.9-8.8,22.6-8.8C75.7,31.3,87.5,38.9,92.9,50.9z M28.9,64.8c0-8.7,3.3-16.7,8.8-22.6l23,23L49.6,95.8
            C37.2,90.7,28.9,78.4,28.9,64.8z M50.7,101.6l1.2-3.3c0,0,0,0,0,0c0,0,0,0,0,0l1.4-3.9l8.9,8.9C58.4,103.4,54.5,102.8,50.7,101.6z"
          />
        </defs>
        <clipPath id="SVGID_2_FieldInfoIcon">
          <use xlinkHref="#SVGID_85_FieldInfoIcon" overflow="visible" />
        </clipPath>
        <g clipPath="url(#SVGID_2_FieldInfoIcon)">
          <rect
            x="-12"
            y="108.5"
            transform="matrix(0.9736 -0.2284 0.2284 0.9736 -26.3369 20.839)"
            fill="#B95700"
            width="177.8"
            height="31.5"
          />
          <rect
            x="-19.2"
            y="77.8"
            transform="matrix(0.9736 -0.2284 0.2284 0.9736 -19.5204 18.3844)"
            fill="#EB6909"
            width="177.8"
            height="31.5"
          />
          <rect
            x="-26.4"
            y="47.1"
            transform="matrix(0.9736 -0.2284 0.2284 0.9736 -12.7039 15.9297)"
            fill="#5F9501"
            width="177.8"
            height="31.5"
          />
          <rect
            x="-33.6"
            y="16.4"
            transform="matrix(0.9736 -0.2284 0.2284 0.9736 -5.8875 13.4751)"
            fill="#79B61D"
            width="177.8"
            height="31.5"
          />
          <rect
            x="-40.8"
            y="-14.3"
            transform="matrix(0.9736 -0.2284 0.2284 0.9736 0.929 11.0204)"
            fill="#C8D200"
            width="177.8"
            height="31.5"
          />
        </g>
      </g>
    </svg>
  );
};

export default FieldInfoIcon;
