class StraightFertilizerService {
  static getApplicationDate(straightFertilizers, fertilizerName) {
    const { applicationTimeline } = straightFertilizers;

    const index = applicationTimeline.findIndex(({ components }) => {
      const allComponents = components.map(({ shortName }) => shortName);
      return allComponents.includes(fertilizerName);
    });

    return applicationTimeline[index].date;
  }

  static getStraighFertilizerWithUpdatedDate(straightFertilizers, fertilizerName, date) {
    const { applicationTimeline } = straightFertilizers;

    const index = applicationTimeline.findIndex(({ components }) => {
      const allComponents = components.map(({ shortName }) => shortName);
      return allComponents.includes(fertilizerName);
    });

    const adjustedApplicationTimeline = [...applicationTimeline];
    adjustedApplicationTimeline[index].date = date;

    return {
      ...straightFertilizers,
      applicationTimeline: adjustedApplicationTimeline,
    };
  }
}

export default StraightFertilizerService;
