/* eslint-disable import/prefer-default-export */
/* eslint-disable react/prop-types */
import React from 'react';
import { PropTypes } from 'prop-types';
import { useLocalizedFromContext } from 'data/Localization';

export const GradientCrop35 = ({ title, ...props }) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 70 70"
    enableBackground="new 0 0 70 70"
    {...props}
  >
    <title>{useLocalizedFromContext(title)}</title>
    <g>
      <clipPath id="SVGID_2_">
        <path
          id="SVGID_69_"
          d="M56.5,46.5c-0.1-0.2-2.9-4.9-9.9-4.3c-3.2,0.3-5.8,1.7-7.8,3.5V42c0.4-0.7,1.5-2.8,3.7-4.3
          c4.2-3,7.2-5.9,6.8-16.4c0-0.2,0-0.3,0-0.3c-0.1-0.8-0.8-1.4-1.6-1.3c-0.8,0.1-1.4,0.7-1.4,1.6c0,0.1,0,0.1,0,0.2
          c0,0.6-0.3,3.6-4.7,7.2c-1.8,1.5-2.8,2.3-3.5,2.9c-0.1,0.1-0.1,0.1-0.2,0.2c-0.2-2.6-0.5-6.4-0.7-9.7c0.7-0.6,2.4-1.6,5.6-1.2
          c0.8,0.1,1.6-0.5,1.7-1.3c0.1-0.8-0.5-1.6-1.3-1.7c-1.7-0.2-3.2-0.1-4.3,0.2c0.8-1,1.8-1.9,3-2.5c0.8-0.4,1.1-1.2,0.7-2
          c-0.3-0.8-1.2-1.1-2-0.7c-1.5,0.7-2.6,1.7-3.6,2.8V6.8c0-0.8-0.7-1.5-1.5-1.5S34,6,34,6.8v8.7c-1-1.1-2.1-2.1-3.6-2.8
          c-0.8-0.3-1.6,0-2,0.7c-0.4,0.8,0,1.6,0.7,2c1.2,0.6,2.2,1.5,3,2.5c-1.2-0.3-2.6-0.4-4.3-0.2c-0.8,0.1-1.4,0.9-1.3,1.7
          c0.1,0.8,0.8,1.4,1.7,1.3c3.1-0.4,4.8,0.5,5.5,1.1l-0.8,14.3c-0.2-0.3-0.5-0.5-0.7-0.8c-2.8-2.9-11.4-10.1-17.9-8.1
          c-0.6,0.2-1,0.7-1.1,1.2c-0.1,0.6,0.2,1.2,0.7,1.5c0.1,0.1,8.5,5.2,11.5,9.2l1,1.3c1.5,1.9,2.5,3.2,3.3,4.8c0.3,0.6,0.7,1,1.3,1.4
          l0,2.1c-2-0.6-3.7-0.8-3.9-0.8c-6.7-0.9-8.1,1.9-8.4,2.6c-1.3,3.5-0.1,7.4,0,7.6c0.2,0.5,0.5,0.8,1,1c0.1,0,0.3,0.1,0.4,0.1
          c0.3,0,0.7-0.1,0.9-0.3c0.4-0.3,3.3-2.7,4.5-5.2c6.2,2.9,6.6,6.7,6.6,6.9c0,0.4,0.1,0.7,0.4,0.9c0.6,0.7,1.7,1.1,2.9,1.1
          c0.9,0,1.8-0.2,2.6-0.7c0.5-0.4,1.4-1.1,1.7-2.6c0,0,0,0,0-0.1c0.6-1.8,2.7-7,4.6-8.8c3.3-3.2,5.5-3.1,10.4-2
          c0.6,0.1,1.2-0.1,1.5-0.6C56.8,47.6,56.8,47,56.5,46.5z M35.9,36c0,0,0.1,0.1,0.1,0.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.2,0
          c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0.1,0,0.3,0,0.4-0.1c0,0,0,0,0.1,0c0.2-0.1,0.3-0.2,0.4-0.3c0,0,0,0,0,0c1-0.9,1.3-1.2,2.3-2
          c0.7-0.6,1.7-1.4,3.6-2.9c0.9-0.7,1.6-1.4,2.2-2.1c-0.9,3.5-2.8,4.9-5,6.4c-2.5,1.8-3.9,4-4.5,5.1c-0.2-0.2-0.4-0.5-0.6-0.7
          L35.9,36z M28.8,38.7l-1-1.3c-1.7-2.2-4.8-4.7-7.4-6.6c3.8,1.5,7.7,4.7,9.6,6.7c1.8,1.9,4.3,5.2,5.5,6.8c-0.4,0.2-0.8,0.4-1.2,0.4
          c-0.4,0-0.7,0-1-0.1c0,0-0.1,0-0.1,0c-0.3-0.1-0.5-0.3-0.6-0.5C31.5,42.1,30.4,40.8,28.8,38.7z M34.1,47.7c0.1,0,0.2,0,0.3,0
          c0.5,0,1-0.1,1.5-0.3V49c-0.4,0.5-0.7,1-0.9,1.5c-0.3-0.2-0.5-0.3-0.8-0.5L34.1,47.7z M21.2,54.9c-0.1-1,0-2.1,0.4-3.2
          c0,0,0,0,0,0c0.1-0.2,0.7-0.6,2.1-0.8c-0.2,0.4-0.4,0.8-0.6,1.3C22.8,53,22,54,21.2,54.9z M36.6,59.4c-0.4,0.3-1.1,0.4-1.4,0.3
          c-0.4-1.7-1.9-5.6-7.9-8.5c0.1-0.1,0.1-0.1,0.2-0.2c1.7,0.2,7.2,1.2,9,5.3C36.7,57.1,37.3,58.8,36.6,59.4z M42.4,48.5
          c-1.4,1.4-2.7,3.8-3.7,5.9c-0.4-0.7-0.9-1.4-1.4-1.9c0.3-0.6,0.8-1.3,1.3-2.1c0,0,0,0,0-0.1c1.7-2.4,4.6-4.8,8.3-5.2
          c0.6-0.1,1.1-0.1,1.6,0C46.5,45.4,44.5,46.4,42.4,48.5z"
        />
      </clipPath>
      <g clipPath="url(#SVGID_2_)">
        <rect
          x="4.5"
          y="49.2"
          transform="matrix(0.9736 -0.2284 0.2284 0.9736 -12.47 10.8225)"
          fill="#B95700"
          width="72.1"
          height="20.2"
        />
        <rect
          x="1"
          y="39.7"
          transform="matrix(0.9736 -0.2284 0.2284 0.9736 -9.2199 9.6522)"
          fill="#EB6909"
          width="72.1"
          height="9.9"
        />
        <rect
          x="-1.2"
          y="30.1"
          transform="matrix(0.9736 -0.2284 0.2284 0.9736 -7.0821 8.8823)"
          fill="#5F9501"
          width="72.1"
          height="9.9"
        />
        <rect
          x="-3.5"
          y="20.5"
          transform="matrix(0.9736 -0.2284 0.2284 0.9736 -4.9442 8.1125)"
          fill="#79B61D"
          width="72.1"
          height="9.9"
        />
        <rect
          x="-6.9"
          y="0.7"
          transform="matrix(0.9736 -0.2284 0.2284 0.9736 -1.6929 6.9416)"
          fill="#C8D200"
          width="72.1"
          height="20.2"
        />
      </g>
    </g>
  </svg>
);

GradientCrop35.defaultProps = {
  title: 'maissilage',
};

GradientCrop35.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
};
