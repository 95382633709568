/* eslint-disable import/prefer-default-export */
/* eslint-disable react/prop-types */
import React from 'react';
import { PropTypes } from 'prop-types';
import { useLocalizedFromContext } from 'data/Localization';

export const Crop23 = ({ title, fill, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="142.112"
    height="164.306"
    viewBox="0 0 142.112 164.306"
    {...props}
  >
    <title>{useLocalizedFromContext(title)}</title>
    <g id="Component_8_1" dataname="Component 8 – 1" transform="translate(2.299 2.5)">
      <g id="Group_101" dataname="Group 101" transform="translate(-1158.026 -7057.482)">
        <path
          id="Path_392"
          dataname="Path 392"
          d="M1226.865,7172.345c-.084-.13-.128-.2-.128-.2s-.121.192-.345.537A2.8,2.8,0,0,1,1226.865,7172.345Z"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
        <path
          id="Path_393"
          dataname="Path 393"
          d="M1226.736,7172.144s-.12.193-.343.537a4.5,4.5,0,0,0-1.256,1.882c-2.5,3.664-7.538,10.619-12.324,14.566-2.774,2.287-9.42,7.439-10.362,7.253s3.66-12.467,6.594-15.26a35.475,35.475,0,0,1,10.989-7.327C1224.073,7172.86,1226.736,7172.144,1226.736,7172.144Z"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
        <path
          id="Path_394"
          dataname="Path 394"
          d="M1226.736,7172.144s-21.793,8.071-41.241,5.445c-11.493-1.551-18.84-4.945-19.547-5.887s11.959-4.882,19.512-6.7c5.657-1.361,14.858.048,23.14,1.962,9.668,2.977,17.625,5.049,18.113,5.174l.009,0,.007,0Z"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
        <path
          id="Path_395"
          dataname="Path 395"
          d="M1226.734,7172.142l0,0-.005,0-.012-.009c-.052-.038-.186-.139-.4-.3-.036-.029-.071-.057-.111-.085-.273-.212-.648-.5-1.112-.876-.037-.031-.077-.061-.117-.094a.082.082,0,0,1-.024-.019c-.04-.031-.082-.064-.122-.1q-.326-.261-.7-.565c-.038-.031-.078-.064-.118-.095-.436-.36-.919-.76-1.442-1.2l-.294-.25c-.139-.117-.28-.24-.426-.365-.061-.054-.125-.108-.186-.16q-.809-.7-1.71-1.5l-.3-.271q-.586-.527-1.208-1.1c-.073-.068-.148-.137-.224-.205-1.276-1.175-2.644-2.48-4.072-3.9q-.459-.461-.925-.933c-1.545-1.568-3.146-3.266-4.757-5.082-.179-.2-.358-.4-.537-.61-.358-.408-.718-.822-1.076-1.244-.179-.209-.358-.419-.537-.633s-.358-.426-.537-.643l-.412-.5c-1.291-1.573-2.57-3.215-3.816-4.917l-.007-.01c-5.591-7.145-42.7-26.2-43.372-25.641-1.413,1.177,7.355,23.235,15.543,31.086,9.563,9.17,21.3,10.862,34.88,15.044,9.668,2.977,17.625,5.049,18.113,5.174v0l.009.005.007,0,.007,0Zm-.148-.05c.04.012.073.024.1.033C1226.656,7172.116,1226.626,7172.107,1226.586,7172.092Z"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
        <path
          id="Path_396"
          dataname="Path 396"
          d="M1226.722,7172.14l-.01,0v0Z"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
        <path
          id="Path_397"
          dataname="Path 397"
          d="M1226.722,7172.14l-.01,0v0Z"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
        <path
          id="Path_398"
          dataname="Path 398"
          d="M1226.732,7172.14l-.005,0-.012-.009c-.052-.038-.186-.139-.4-.3-.036-.029-.071-.057-.111-.085-.273-.212-.648-.5-1.112-.876-.037-.031-.077-.061-.117-.094a.082.082,0,0,1-.024-.019c-.04-.031-.082-.064-.122-.1q-.326-.261-.7-.565c-.038-.031-.078-.064-.118-.095-.436-.36-.919-.76-1.442-1.2l-.294-.25c-.139-.117-.28-.24-.426-.365-.061-.054-.125-.108-.186-.16q-.809-.7-1.71-1.5l-.3-.271q-.586-.527-1.208-1.1c-.073-.068-.148-.137-.224-.205-1.276-1.175-2.644-2.48-4.072-3.9q-.459-.461-.925-.933c-1.545-1.568-3.146-3.266-4.757-5.082-.179-.2-.358-.4-.537-.61-.358-.408-.718-.822-1.076-1.244-.179-.209-.358-.419-.537-.633s-.358-.426-.537-.643l-.412-.5c-1.291-1.573-2.57-3.215-3.816-4.917l-.007-.01c-5.755-7.873-10.769-17.057-12.757-26.819-3.316-16.287-1.177-42.626,0-42.626.532,0,21.648,39.207,24.728,48.629.563,1.724,1.378,4.43,2.331,7.689C1218.03,7157.635,1226.555,7171.845,1226.732,7172.14Z"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
        <path
          id="Path_399"
          dataname="Path 399"
          d="M1226.734,7172.14v0l0,0Z"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
        <path
          id="Path_400"
          dataname="Path 400"
          d="M1239.235,7127.94c-2.231,16.349-9.244,40.368-12.494,44.2v0l-.005,0,0,0v0h0c-.177-.295-8.7-14.505-10.881-38.793a127.706,127.706,0,0,1-.447-15.183c.695-23.588,11.373-60.682,12.7-60.682s11.309,50.911,11.8,60.367A57.436,57.436,0,0,1,1239.235,7127.94Z"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
        <path
          id="Path_401"
          dataname="Path 401"
          d="M1225.138,7174.563c.552-.811.98-1.46,1.254-1.881A4.5,4.5,0,0,0,1225.138,7174.563Z"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
        <path
          id="Path_402"
          dataname="Path 402"
          d="M1227.09,7172.238a2.037,2.037,0,0,0-.226.106c-.073-.113-.116-.181-.125-.2C1226.753,7172.149,1226.873,7172.182,1227.09,7172.238Z"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
        <path
          id="Path_403"
          dataname="Path 403"
          d="M1251.022,7196.382c-.942.186-7.588-4.966-10.363-7.253-4.985-4.112-10.246-11.486-12.623-15.006-.572-.851-.979-1.477-1.172-1.779a2.037,2.037,0,0,1,.226-.106c.242.064.61.158,1.081.278,1.172.3,3,.754,5.268,1.279a35.454,35.454,0,0,1,10.989,7.327C1247.364,7183.915,1251.964,7196.194,1251.022,7196.382Z"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
        <path
          id="Path_404"
          dataname="Path 404"
          d="M1287.525,7171.7c-.707.942-9.475,6.42-21.072,6.412-17.268-.01-37.11-5.3-38.282-5.6a.854.854,0,0,0-.292-.273.165.165,0,0,0-.033-.021.777.777,0,0,0-.566-.045c-.35-.03-.544-.033-.544-.033l.005,0h0l.007,0,.009,0c.488-.125,8.448-2.2,18.115-5.174,8.28-1.914,17.481-3.323,23.138-1.962C1275.566,7166.82,1288.231,7170.76,1287.525,7171.7Z"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
        <path
          id="Path_405"
          dataname="Path 405"
          d="M1227.879,7172.243c-.233-.03-.434-.052-.6-.066a.777.777,0,0,1,.566.045A.165.165,0,0,1,1227.879,7172.243Z"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
        <path
          id="Path_406"
          dataname="Path 406"
          d="M1227.28,7172.177a1.527,1.527,0,0,0-.19.061c-.217-.056-.337-.089-.351-.091l0,0S1226.93,7172.147,1227.28,7172.177Z"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
        <path
          id="Path_407"
          dataname="Path 407"
          d="M1228.171,7172.516c-.471-.12-.839-.214-1.081-.278a1.527,1.527,0,0,1,.19-.061c.165.014.366.036.6.066A.854.854,0,0,1,1228.171,7172.516Z"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
        <path
          id="Path_408"
          dataname="Path 408"
          d="M1295.3,7120.833c-.671-.561-37.779,18.5-43.37,25.641l-.007.01c-1.707,2.336-3.481,4.557-5.261,6.641-.149.177-.3.351-.45.525-1.272,1.477-2.546,2.883-3.8,4.213-.174.186-.348.37-.523.554a142.388,142.388,0,0,1-10.7,10.126l-.278.238c-.528.445-1.015.853-1.458,1.215-.04.031-.08.064-.118.095-.247.2-.478.391-.7.565-.04.035-.082.068-.122.1a.082.082,0,0,1-.024.019l-.117.094c-.464.372-.839.664-1.112.876-.04.031-.075.059-.111.085-.209.16-.344.261-.395.3l-.012.009-.005,0v0h0l.007,0,.009-.005v0c.488-.125,8.448-2.2,18.115-5.174,13.581-4.182,26.2-7.487,34.878-15.044C1289.289,7143.615,1296.709,7122.01,1295.3,7120.833Zm-68.505,51.292c.023-.009.056-.021.1-.033C1226.847,7172.107,1226.817,7172.116,1226.791,7172.125Z"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
        <path
          id="Path_409"
          dataname="Path 409"
          d="M1226.76,7172.135v0l-.009,0Z"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
        <path
          id="Path_410"
          dataname="Path 410"
          d="M1226.76,7172.135v0l-.009,0Z"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
        <path
          id="Path_411"
          dataname="Path 411"
          d="M1264.681,7119.655c-1.988,9.762-7,18.946-12.755,26.819l-.007.01c-1.707,2.336-3.481,4.557-5.261,6.641-.149.177-.3.351-.45.525-1.272,1.477-2.546,2.883-3.8,4.213-.174.186-.348.37-.523.554a142.388,142.388,0,0,1-10.7,10.126l-.278.238c-.528.445-1.015.853-1.458,1.215-.04.031-.08.064-.118.095-.247.2-.478.391-.7.565-.04.035-.082.068-.122.1a.082.082,0,0,1-.024.019l-.117.094c-.464.372-.839.664-1.112.876-.04.028-.075.056-.111.085-.209.16-.344.261-.395.3l-.012.009-.005,0c3.25-3.832,10.263-27.851,12.494-44.2.271-.878.511-1.646.718-2.282,3.08-9.422,24.2-48.629,24.728-48.629C1265.858,7077.029,1268,7103.368,1264.681,7119.655Z"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
      </g>
      <line
        id="Line_32"
        dataname="Line 32"
        y2="40.989"
        transform="translate(68.715 118.317)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
    </g>
  </svg>
);

Crop23.defaultProps = {
  title: 'hemp',
};

Crop23.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
};
