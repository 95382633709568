import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import { Dialog, LinearProgress } from '@material-ui/core';
import { STATUS_GETTING, STATUS_PUT_FAILED } from 'Redux/actions/types';
import { getTerms, updateUser, putUser } from 'Redux/actions';
import NG4TermsAndConditions from 'containers/GetStarted//TermsAndConditions/NG4TermsAndConditions';
import WebserviceFailedDialog from 'components/Molecules/WebserviceFailedDialog';
import userUtils from 'Utils/UserUtils';

class TermsAndConditionsPopOver extends Component {
  static propTypes = {
    user: PropTypes.object,
    getTerms: PropTypes.func,
    terms: PropTypes.func,
    putUser: PropTypes.func,
  };

  componentDidMount() {
    const { getTerms, terms, user } = this.props;
    if (terms.data === null && terms.status !== STATUS_GETTING) {
      getTerms({ locale: user.locale });
    }
  }

  renewTerms = (input) => {
    const { user, putUser } = this.props;
    const userDTO = {
      ...userUtils.convertFrontendToBackend({
        ...user,
        termsAcceptedId: input.terms.data.versionId,
      }),
      ...{ termsCompliant: true },
    };
    putUser(userDTO, user.id_token);
  };

  render() {
    const { terms, user } = this.props;
    const { lang, locale } = user;
    let popOverContent;
    let linearLoading;
    if (terms.status === STATUS_GETTING) {
      linearLoading = <LinearProgress className="content--loading-screen" color="primary" />;
    } else {
      popOverContent = (
        <NG4TermsAndConditions
          lang={lang}
          terms={terms}
          termsAccepted={false}
          acceptCommunicationEnabled={false}
          updateUser={(value) => updateUser(value)}
          nextStep={(output) => this.renewTerms(output)}
          nextLabel="confirm"
          isUpdate
        />
      );
    }

    if (user.status === STATUS_PUT_FAILED) {
      const userDTO = {
        ...userUtils.convertFrontendToBackend({ ...user, termsAcceptedId: terms.data.versionId }),
        ...{ termsCompliant: true },
      };
      return (
        <WebserviceFailedDialog
          locale={locale}
          errorCode={user.errorCode}
          onCancel={() => this.putUser(userDTO, user.id_token)}
        />
      );
    }

    return (
      <>
        <Dialog
          open
          anchorEl={document.documentElement}
          classes={{
            paper: 'dialog__terms',
          }}
        >
          {linearLoading}
          {popOverContent}
        </Dialog>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { terms, user } = state;
  return { terms, user };
};

export default withRouter(
  connect(mapStateToProps, { getTerms, putUser })(TermsAndConditionsPopOver),
);
