/* eslint-disable import/prefer-default-export */
/* eslint-disable react/prop-types */
import React from 'react';
import { PropTypes } from 'prop-types';
import { useLocalizedFromContext } from 'data/Localization';

export const GradientCrop42 = ({ title, ...props }) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 70 70"
    enableBackground="new 0 0 70 70"
    {...props}
  >
    <title>{useLocalizedFromContext(title)}</title>
    <g>
      <g>
        <clipPath id="SVGID_2_">
          <path
            id="SVGID_1_"
            d="M53.8,45.1L53.8,45.1L53.8,45.1c-0.1-2.3-1.9-4-4-4c-2.2,0-3.9,1.8-3.9,3.9v0.1h-0.1c-1.6,0-3,1-3.6,2.4
            h-5.6V39v-3.4v-1c1.4-0.6,2.5-2,2.5-3.7c0-0.5-0.1-0.9-0.3-1.5c0.4,0.1,0.8,0.2,1.3,0.2c1,0,2-0.4,2.8-1.2l0.1-0.1l0.1,0.1
            c0.8,0.8,1.8,1.2,2.8,1.2s2-0.4,2.8-1.2c1.5-1.5,1.5-4.1,0-5.6l-0.1-0.1l0.1-0.1c1.5-1.5,1.5-4.1,0-5.6s-4.1-1.5-5.6,0L43,17.1
            L42.9,17c-0.2-0.2-0.5-0.4-0.8-0.6c0.7-0.7,1.2-1.7,1.2-2.8c0-2.2-1.8-4-4-4h-0.1V9.5c0-2.2-1.8-4-4-4s-4,1.8-4,4v0.1h-0.1
            c-2.2,0-4,1.8-4,4c0,1.1,0.5,2.1,1.2,2.8c-0.3,0.2-0.6,0.4-0.8,0.6l-0.1,0.1L27.3,17c-1.5-1.5-4.1-1.5-5.6,0s-1.5,4.1,0,5.6
            l0.1,0.1l-0.1,0.1c-1.5,1.5-1.5,4.1,0,5.6c0.8,0.8,1.8,1.2,2.8,1.2s2-0.4,2.8-1.2l0.1-0.1l0.1,0.1c0.8,0.8,1.8,1.2,2.8,1.2
            c0.4,0,0.8-0.1,1.3-0.2c-0.2,0.5-0.3,1-0.3,1.5c0,1.7,1,3.1,2.5,3.7v1v1.7H28c-0.6-1.4-2-2.4-3.6-2.4h-0.1v-0.1
            c0-2.2-1.8-3.9-3.9-3.9c-2.2,0-3.9,1.8-3.9,3.9v0.1h-0.1c-2.2,0-3.9,1.8-3.9,3.9c0,2.2,1.8,3.9,3.9,3.9h0.1v0.1
            c0,2.2,1.8,3.9,3.9,3.9c2.2,0,3.9-1.8,3.9-3.9v-0.1h0.1c1.6,0,3-1,3.6-2.4h5.6v21.2c0,0.8,0.7,1.5,1.5,1.5s1.5-0.7,1.5-1.5v-11
            h5.6c0.6,1.4,2,2.4,3.6,2.4h0.1V53c0,2.2,1.8,3.9,3.9,3.9c2.2,0,3.9-1.8,3.9-3.9v-0.1h0.1c2.2,0,3.9-1.8,3.9-3.9
            C57.8,46.9,56,45.1,53.8,45.1z M34.1,30.9c0-0.3,0.4-1,1-1.7c0.5,0.7,0.9,1.4,1,1.7c0,0.5-0.4,0.9-1,0.9S34.1,31.4,34.1,30.9z
            M35.1,16c0.5,0.7,0.9,1.4,1,1.7c0,0.5-0.4,0.9-1,0.9c-0.5,0-1-0.4-1-0.9C34.1,17.5,34.5,16.8,35.1,16z M35.1,21.7
            c0.5,0,1,0.4,1,0.9c0,0.3-0.4,1-1,1.7c-0.5-0.7-0.9-1.4-1-1.7C34.1,22.1,34.5,21.7,35.1,21.7z M39.2,20.5c-0.4-0.4-0.4-1,0-1.4
            s1-0.4,1.3,0c0.2,0.2,0.4,1,0.5,1.9C40.2,20.9,39.5,20.7,39.2,20.5z M30.9,20.5c-0.2,0.2-1,0.4-1.9,0.5c0.1-0.9,0.3-1.7,0.5-1.9
            c0.4-0.4,1-0.4,1.4,0C31.3,19.6,31.3,20.2,30.9,20.5z M40.6,26.3c-0.4,0.4-1,0.4-1.4,0s-0.4-1,0-1.3c0.2-0.2,1-0.4,1.9-0.5
            C41,25.3,40.8,26.1,40.6,26.3z M46.4,26.3c-0.4,0.4-1,0.4-1.3,0c-0.2-0.2-0.4-1-0.5-1.9c0.9,0.1,1.7,0.3,1.9,0.5
            C46.8,25.4,46.8,26,46.4,26.3z M45,19.2c0.4-0.4,1-0.4,1.4,0s0.4,1,0,1.3c-0.2,0.2-1,0.4-1.9,0.5C44.7,20.2,44.9,19.4,45,19.2z
            M39.2,12.7c0.5,0,0.9,0.4,0.9,1c0,0.5-0.4,1-0.9,1c-0.3,0-1-0.4-1.7-1C38.2,13.1,38.9,12.7,39.2,12.7z M35.1,8.6
            c0.5,0,1,0.4,1,0.9c0,0.3-0.4,1-1,1.7c-0.5-0.7-0.9-1.4-1-1.7C34.1,9,34.5,8.6,35.1,8.6z M31,12.7c0.3,0,1,0.4,1.7,1
            c-0.7,0.5-1.4,0.9-1.7,1c-0.5,0-0.9-0.4-0.9-1C30,13.1,30.4,12.7,31,12.7z M23.7,19.2c0.4-0.4,1-0.4,1.3,0c0.2,0.2,0.4,1,0.5,1.9
            c-0.9-0.1-1.7-0.3-1.9-0.5C23.4,20.2,23.4,19.6,23.7,19.2z M25.1,26.3c-0.4,0.4-1,0.4-1.4,0s-0.4-1,0-1.3c0.2-0.2,1-0.4,1.9-0.5
            C25.5,25.3,25.3,26.1,25.1,26.3z M30.9,26.3c-0.4,0.4-1,0.4-1.3,0c-0.2-0.2-0.4-1-0.5-1.9c0.9,0.1,1.7,0.3,1.9,0.5
            C31.3,25.4,31.3,26,30.9,26.3z M20.3,33.8c0.5,0,0.9,0.4,0.9,0.9c0,0.3-0.4,0.9-0.9,1.7c-0.5-0.7-0.9-1.4-0.9-1.7
            C19.4,34.2,19.8,33.8,20.3,33.8z M16.3,39.7c-0.5,0-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9c0.3,0,0.9,0.4,1.7,0.9
            C17.2,39.3,16.6,39.7,16.3,39.7z M20.3,43.7c-0.5,0-0.9-0.4-0.9-0.9c0-0.3,0.4-0.9,0.9-1.7c0.5,0.7,0.9,1.4,0.9,1.7
            C21.2,43.3,20.8,43.7,20.3,43.7z M24.4,39.7c-0.3,0-0.9-0.4-1.7-0.9c0.7-0.5,1.4-0.9,1.7-0.9c0.5,0,0.9,0.4,0.9,0.9
            S24.9,39.7,24.4,39.7z M49.8,44.1c0.5,0,0.9,0.4,0.9,0.9c0,0.3-0.4,0.9-0.9,1.7c-0.5-0.7-0.9-1.4-0.9-1.7
            C48.9,44.5,49.3,44.1,49.8,44.1z M45.8,50c-0.5,0-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9c0.3,0,0.9,0.4,1.7,0.9
            C46.7,49.5,46.1,49.9,45.8,50z M49.8,54c-0.5,0-0.9-0.4-0.9-0.9c0-0.3,0.4-0.9,0.9-1.7c0.5,0.7,0.9,1.4,0.9,1.7
            C50.7,53.6,50.3,54,49.8,54z M53.9,50c-0.3,0-0.9-0.4-1.7-0.9c0.7-0.5,1.4-0.9,1.7-0.9c0.5,0,0.9,0.4,0.9,0.9
            C54.8,49.5,54.4,50,53.9,50z"
          />
        </clipPath>
        <g clipPath="url(#SVGID_2_)">
          <rect
            x="4.5"
            y="49.2"
            transform="matrix(0.9736 -0.2284 0.2284 0.9736 -12.4708 10.8294)"
            fill="#B95700"
            width="72.1"
            height="20.2"
          />
          <rect
            x="1"
            y="39.7"
            transform="matrix(0.9736 -0.2284 0.2284 0.9736 -9.221 9.6424)"
            fill="#EB6909"
            width="72.1"
            height="9.9"
          />
          <rect
            x="-1.2"
            y="30.1"
            transform="matrix(0.9736 -0.2284 0.2284 0.9736 -7.0833 8.8866)"
            fill="#5F9501"
            width="72.1"
            height="9.9"
          />
          <rect
            x="-3.5"
            y="20.5"
            transform="matrix(0.9736 -0.2284 0.2284 0.9736 -4.9534 8.1091)"
            fill="#79B61D"
            width="72.1"
            height="9.9"
          />
          <rect
            x="-6.9"
            y="0.7"
            transform="matrix(0.9736 -0.2284 0.2284 0.9736 -1.6958 6.944)"
            fill="#C8D200"
            width="72.1"
            height="20.2"
          />
        </g>
      </g>
    </g>
  </svg>
);

GradientCrop42.defaultProps = {
  title: 'canolaoilrapeseed',
};

GradientCrop42.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
};
