/* eslint-disable import/prefer-default-export */
/* eslint-disable react/prop-types */
import React from 'react';

export const CornCards = (props) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 128 128"
      enableBackground="new 0 0 128 128"
      xmlSpace="preserve"
      {...props}
    >
      <g>
        <defs>
          <path
            id="SVGID_19_CornCards"
            d="M123.1,37.6h-20.2l1.7-2.7c0.2-0.3,0.3-0.7,0.2-1.1s-0.3-0.7-0.7-0.9L52.3,0.2c-0.7-0.4-1.6-0.2-2.1,0.5
            l-4.1,6.5c0,0,0,0,0,0c0,0,0,0,0,0L3.8,74.7c-0.2,0.3-0.3,0.7-0.2,1.1s0.3,0.7,0.7,0.9l51.9,32.5c0.2,0.2,0.5,0.2,0.8,0.2
            c0.1,0,0.2,0,0.3,0c0.4-0.1,0.7-0.3,0.9-0.7l2.2-3.5v21.1c0,0.8,0.7,1.5,1.5,1.5h61.2c0.8,0,1.5-0.7,1.5-1.5V39.1
            C124.6,38.3,124,37.6,123.1,37.6z M56.5,106L7.1,75l40.7-64.9l1.5,1c0.2,0.2,0.5,0.2,0.8,0.2c0.5,0,1-0.2,1.3-0.7
            C51.9,9.9,51.7,9,51,8.5l-1.5-1l2.5-4l49.3,30.9l-1.9,3.1h-2.1l-2.4-1.5c-0.7-0.4-1.6-0.2-2.1,0.5c-0.2,0.3-0.3,0.7-0.2,1H79.2
            L52.6,20.9c-0.7-0.4-1.6-0.2-2.1,0.5c-0.4,0.7-0.2,1.6,0.5,2.1l22.6,14.2H61.9c-0.8,0-1.4,0.6-1.5,1.3
            c-4.5,1.1-10.2,6.8-14.7,12.5c-1.1-1.9-2.1-2.8-2.2-3c-0.3-0.3-0.8-0.5-1.2-0.4c-0.5,0-0.9,0.3-1.1,0.7C31.1,64.5,27.7,76,31.2,83
            c1.9,3.7,5.1,4.8,6.1,4.9l0.1,0c0.3,0,0.5,0,0.8,0c1.3,0,2.4-0.3,3.5-0.8c0,0,0,0,0,0c4.4-1.5,11-5.5,18.7-15.7v28.3L56.5,106z
            M36.3,84.3c-0.6-3.6,2-10.4,3-12.9c0.3-0.8,0-1.6-0.8-2c-0.8-0.3-1.6,0-2,0.8c-0.3,0.7-2.4,5.7-3.1,10.2c-1.2-3.8-1-12,9.2-28.4
            c1.9,2.8,5.3,9.5,3.5,19.9c-2.4,13.4-6.9,13.1-8.4,12.9l-0.1,0C37.6,84.9,37,84.8,36.3,84.3z M60.4,55.9c-0.7,1.6-1.5,3.3-2.4,4.9
            c-2.4,0.1-5.3,0.5-8.6,1.7c-0.4-3.3-1.2-6.1-2.2-8.2C52.7,47,57.5,43.1,60.4,42V55.9z M49.1,72.5c0.4-2.4,0.6-4.7,0.6-6.7
            c4.4-1.9,8.3-2,10.7-1.8v2.3c-5.2,7.7-9.9,12.3-13.7,15C47.7,78.9,48.5,76,49.1,72.5z M63.4,124.9V66.6c0,0,0,0,0,0V56.2
            c0,0,0,0,0,0v-7.9h1.8c0.8,0,1.5-0.7,1.5-1.5c0-0.8-0.7-1.5-1.5-1.5h-1.8v-4.7h58.2v4.7h-1.8c-0.8,0-1.5,0.7-1.5,1.5
            c0,0.8,0.7,1.5,1.5,1.5h1.8v76.6H63.4z M94,46.8c0-0.8,0.7-1.5,1.5-1.5h6.1c0.8,0,1.5,0.7,1.5,1.5c0,0.8-0.7,1.5-1.5,1.5h-6.1
            C94.7,48.3,94,47.6,94,46.8z M115.3,46.8c0,0.8-0.7,1.5-1.5,1.5h-6.1c-0.8,0-1.5-0.7-1.5-1.5c0-0.8,0.7-1.5,1.5-1.5h6.1
            C114.6,45.3,115.3,46,115.3,46.8z M81.9,46.8c0-0.8,0.7-1.5,1.5-1.5h6.1c0.8,0,1.5,0.7,1.5,1.5c0,0.8-0.7,1.5-1.5,1.5h-6.1
            C82.6,48.3,81.9,47.6,81.9,46.8z M77.3,48.3h-6.1c-0.8,0-1.5-0.7-1.5-1.5c0-0.8,0.7-1.5,1.5-1.5h6.1c0.8,0,1.5,0.7,1.5,1.5
            C78.8,47.6,78.2,48.3,77.3,48.3z M113.4,56.4c0,0.8-0.7,1.5-1.5,1.5H73.1c-0.8,0-1.5-0.7-1.5-1.5c0-0.8,0.7-1.5,1.5-1.5h38.8
            C112.7,54.9,113.4,55.6,113.4,56.4z M106.3,83.4c-0.4-0.3-0.8-0.4-1.3-0.3c-0.1,0-1.4,0.3-3.2,1.2c-0.9-9.6-3.8-19.9-9.1-19.9
            c-5.4,0-8.2,10.4-9.2,20c-1.9-1-3.3-1.3-3.4-1.3c-0.4-0.1-0.9,0-1.3,0.3c-0.4,0.3-0.6,0.7-0.6,1.2c-0.2,18.7,3.1,30.3,9.7,34.4
            c1.8,1.1,3.5,1.4,4.9,1.4c1.4,0,2.4-0.3,2.9-0.6l0.1,0c1.4-0.7,2.5-1.7,3.2-2.9c0,0,0,0,0,0c3.7-4.5,8-13.7,7.8-32.3
            C106.9,84.1,106.7,83.6,106.3,83.4z M92.7,67.4c2,0,5.3,6.4,6.3,18.5c-2,1.3-4.3,3.3-6.3,6c-2.1-2.6-4.3-4.5-6.2-5.8
            C87.4,73.9,90.7,67.4,92.7,67.4z M94.5,117.1l-0.1,0.1c0,0-0.5,0.2-1.4,0.2c-2.4-2.7-3.9-9.9-4.3-12.5c-0.1-0.8-0.9-1.4-1.7-1.3
            c-0.8,0.1-1.4,0.9-1.3,1.7c0.1,0.8,0.9,6.1,2.8,10.4c-3.1-2.6-7.2-9.7-7.3-29c3.1,1.4,9.6,5.2,13.5,15
            C100,114.3,95.9,116.4,94.5,117.1z M100.1,109.1c-0.4-2.5-1.2-5.4-2.5-8.6c-0.9-2.3-2-4.3-3.1-6c3.2-4.7,7.1-6.9,9.4-7.8
            C103.8,97.2,102.2,104.4,100.1,109.1z M80.2,28.7c0.4-0.7,1.4-0.9,2.1-0.5l6.3,3.9c0.7,0.4,0.9,1.4,0.5,2.1
            c-0.3,0.5-0.8,0.7-1.3,0.7c-0.3,0-0.5-0.1-0.8-0.2l-6.3-3.9C80,30.3,79.8,29.4,80.2,28.7z M55.2,12.9c0.4-0.7,1.4-0.9,2.1-0.5
            l6.3,3.9c0.7,0.4,0.9,1.4,0.5,2.1c-0.3,0.5-0.8,0.7-1.3,0.7c-0.3,0-0.5-0.1-0.8-0.2L55.6,15C54.9,14.6,54.7,13.6,55.2,12.9z
            M67.7,20.8c0.4-0.7,1.4-0.9,2.1-0.5l6.3,3.9c0.7,0.4,0.9,1.4,0.5,2.1c-0.3,0.5-0.8,0.7-1.3,0.7c-0.3,0-0.5-0.1-0.8-0.2l-6.3-3.9
            C67.5,22.4,67.3,21.5,67.7,20.8z"
          />
        </defs>
        <clipPath id="SVGID_2_CornCards">
          <use xlinkHref="#SVGID_19_CornCards" overflow="visible" />
        </clipPath>
        <g clipPath="url(#SVGID_2_CornCards)">
          <rect
            x="-5.5"
            y="89.9"
            transform="matrix(0.9736 -0.2284 0.2284 0.9736 -24.4455 20.0059)"
            fill="#5F9501"
            width="159.5"
            height="51.4"
          />
          <rect
            x="-17.3"
            y="39.9"
            transform="matrix(0.9736 -0.2284 0.2284 0.9736 -13.3258 16.0017)"
            fill="#79B61D"
            width="159.5"
            height="51.4"
          />
          <rect
            x="-29"
            y="-10.2"
            transform="matrix(0.9736 -0.2284 0.2284 0.9736 -2.21 12.0009)"
            fill="#C8D200"
            width="159.5"
            height="51.4"
          />
        </g>
      </g>
    </svg>
  );
};
