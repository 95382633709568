/* eslint-disable import/prefer-default-export */
import reducers from 'Redux/Reducers';
import thunk from 'redux-thunk';
import { createStore, applyMiddleware, compose } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web and AsyncStorage for react-native
import { pushDataLayer } from 'Utils/DataLayerUtils';
import { setLastUsed } from 'Redux/actions';
import { POST_PLAN_SUCCESS, PUT_PLAN_SUCCESS, STATUS_DEFAULT } from 'Redux/actions/types';
import LocalizationService from 'services/LocalizationService';

// eslint-disable-next-line no-underscore-dangle
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['user', 'plan', 'myPlots', 'lastUsed'], // only these will be persisted
  stateReconciler: (inboundState, state) => {
    const returnedState = {
      ...state,
      ...inboundState,
      myPlots: {
        ...inboundState.myPlots,
        status: STATUS_DEFAULT,
      },
    };
    // Needed to set the locale for the logged in and persisted users
    if (!inboundState.user.locale) {
      const locale = LocalizationService.getLocaleFromLangAndCountry(
        inboundState.user.lang,
        inboundState.user.country,
      );
      returnedState.user.locale = locale;
    }
    return returnedState;
  },
};

const persistedReducer = persistReducer(persistConfig, reducers);

const analytics = () => (next) => (action) => {
  if (action.meta && action.meta.analytics) {
    pushDataLayer(action.meta.analytics, action.payload);
  }
  return next(action);
};

const saveLastUsed = () => (next) => (action) => {
  if (
    (action.type === POST_PLAN_SUCCESS || action.type === PUT_PLAN_SUCCESS) &&
    action.payload.json &&
    action.payload.json.plot
  ) {
    const {
      id: plotId,
      name: plotName,
      farm: { id: farmId, name: farmName },
    } = action.payload.json.plot;
    setLastUsed({
      plotId,
      plotName,
      farmId,
      farmName,
    });
  }
  return next(action);
};

export const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(...[thunk, analytics, saveLastUsed])),
);
