import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

class ScrollToTop extends Component {
  static propTypes = {
    location: PropTypes.object,
    children: PropTypes.object,
    elementRef: PropTypes.object,
  };

  componentDidUpdate(prevProps) {
    const { location, elementRef } = this.props;
    if (location !== prevProps.location && elementRef) {
      elementRef.current.scrollIntoView({ block: 'start' });
    }
  }

  render() {
    const { children } = this.props;
    return children;
  }
}

export default withRouter(ScrollToTop);
