/* eslint-disable react/prop-types */
import React from 'react';

const AvatarSmall = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 19.40899" {...props}>
    <title>Small avatar</title>
    <path
      d="M18.98368,18.45378a9.75944,9.75944,0,0,0-4.04438-6.07673l-.02033-.02033c-.28452-.18291-.52841-.34551-.752-.46744l-1.07716-.60971.77231-.81294A6.29217,6.29217,0,0,0,15.6303,6.40192,6.16311,6.16311,0,0,0,9.51291,0h-.061A6.15412,6.15412,0,0,0,3.33455,6.13772,6.085,6.085,0,0,0,5.02141,10.365l.83326.89424L4.77753,11.869a7.6458,7.6458,0,0,0-.7723.48776l-.02033.02033A9.56214,9.56214,0,0,0,.02181,18.39282a.80242.80242,0,0,0,.79262.97552.81667.81667,0,0,0,.76939-.58277l-.00094.00529a8.05967,8.05967,0,0,1,15.81538-.01256l.00019.00067a.8093.8093,0,0,0,1.58523-.32519ZM9.49259,10.64955a4.51184,4.51184,0,1,1,4.51182-4.51183A4.51184,4.51184,0,0,1,9.49259,10.64955Z"
      fill="#71bf44"
    />
  </svg>
);

export default AvatarSmall;
