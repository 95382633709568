import { useEffect } from 'react';
import { STATUS_GET_FAILED } from 'Redux/actions/types';
import { localized } from 'data/Localization';
import ErrorMessageHelper from 'helpers/ErrorMessageHelper';

const getFailedDialogueProperties = (locale, errorCode, setDialogue, webservice) => ({
  id: 'dialogue-retry-loading-plots',
  text1: localized(locale, 'alert_failed_webservice_1'),
  text2: ErrorMessageHelper.getErrorFromCode(errorCode, locale),
  buttons: [
    {
      id: 'dialogue-cancel-fail-dialog-button',
      handler: () => {
        setDialogue(null);
      },
      color: 'primary',
      title: localized(locale, 'cancel'),
    },
    {
      id: 'dialogue-retry-button',
      handler: () => {
        setDialogue(null);
        webservice();
      },
      color: 'primary',
      title: localized(locale, 'retry'),
    },
  ],
});

const useGetWsLogic = ({
  locale,
  shouldCallWebservice,
  webservice,
  status,
  setDialogue,
  errorCode,
}) => {
  useEffect(() => {
    if (shouldCallWebservice()) {
      webservice();
    }
  }, [shouldCallWebservice, webservice]);

  useEffect(() => {
    switch (status) {
      case STATUS_GET_FAILED:
        setDialogue(getFailedDialogueProperties(locale, errorCode, setDialogue, webservice));
        break;
      default:
        break;
    }
  }, [errorCode, locale, setDialogue, status, webservice]);
};

export default useGetWsLogic;
