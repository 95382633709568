import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { localized } from 'data/Localization';
import NutriguideLogo from 'components/Icons/NutriguideLogo';
import ErrorMessageHelper from 'helpers/ErrorMessageHelper';

const WebserviceFailedDialog = ({ locale, onCancel, errorCode }) => (
  <Dialog open>
    <DialogTitle id="alert_dialog_title">
      <NutriguideLogo className="logo__img img-center" />
    </DialogTitle>
    <DialogContent classes={{ root: 'dialog--content' }}>
      <DialogContentText id="alert_dialog_description">
        <p className="text-align--center margin-top--zero">
          {localized(locale, 'alert_failed_webservice_1')}
          <br />
          {ErrorMessageHelper.getErrorFromCode(errorCode, locale)}
        </p>
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button id="getStarted_validation" disabled={false} color="primary" onClick={onCancel}>
        {localized(locale, 'retry')}
      </Button>
    </DialogActions>
  </Dialog>
);
WebserviceFailedDialog.propTypes = {
  locale: PropTypes.string,
  onCancel: PropTypes.func,
  errorCode: PropTypes.number,
};

export default WebserviceFailedDialog;
