import { UPDATE_PLAN, RESET_PLAN } from './types';

export const updatePlan = (update) => ({
  type: UPDATE_PLAN,
  payload: update,
});

export const resetPlan = (country) => ({
  type: RESET_PLAN,
  payload: { country },
});
