/* eslint-disable react/prop-types */
import React from 'react';
import { PropTypes } from 'prop-types';

const Product = ({ color, ...props }) => {
  Product.propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
  };

  let fillColor = color || '#71bf44';

  if (!color) {
    fillColor = '#79B61D';
  }
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 42 42"
      enableBackground="new 0 0 42 42"
      {...props}
    >
      <g>
        <path
          fill={fillColor}
          d="M34.5,12.9L32,7V1.5C32,0.7,31.3,0,30.5,0h-19C10.7,0,10,0.7,10,1.5V7l-2.5,5.9c-0.1,0.2-0.1,0.4-0.1,0.6
          v27C7.3,41.3,8,42,8.8,42h24.3c0.8,0,1.5-0.7,1.5-1.5v-27C34.7,13.3,34.6,13.1,34.5,12.9z M29,3v2.8H13V3H29z M31.7,39H10.3V13.8
          l2.1-5h17.1l2.1,5V39z"
        />
        <path
          fill={fillColor}
          d="M27.4,30h-4.9v-4.5c1.2-0.2,2.9-0.7,4.3-2c2.4-2.4,1.8-5.7,1.8-5.8c-0.1-0.7-0.7-1.2-1.4-1.2
          c-0.1,0-3.5-0.2-5.8,2.1c-0.1,0.1-0.3,0.3-0.4,0.4c-0.1-0.1-0.2-0.3-0.4-0.4c-2.3-2.3-5.7-2.1-5.8-2.1c-0.7,0-1.3,0.6-1.4,1.2
          c0,0.1-0.6,3.4,1.8,5.8c1.3,1.4,3,1.9,4.3,2V30h-4.9c-0.8,0-1.5,0.7-1.5,1.5s0.7,1.5,1.5,1.5h12.7c0.8,0,1.5-0.7,1.5-1.5
          S28.2,30,27.4,30z M23.5,20.6c0.6-0.6,1.4-0.9,2-1.1c-0.1,0.6-0.4,1.2-0.9,1.8c-0.6,0.6-1.4,0.9-2,1.1C22.7,21.8,23,21.2,23.5,20.6
          z M17.4,21.3c-0.5-0.5-0.8-1.2-0.9-1.8c0.7,0.2,1.4,0.5,2,1.1c0.5,0.5,0.8,1.2,0.9,1.8C18.7,22.2,18,21.9,17.4,21.3z"
        />
      </g>
    </svg>
  );
};

export default Product;
