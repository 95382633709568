/* eslint-disable import/prefer-default-export */
/* eslint-disable react/prop-types */
import React from 'react';
import { PropTypes } from 'prop-types';
import { useLocalizedFromContext } from 'data/Localization';

export const Crop45 = ({ title, fill, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="165.852"
    height="160.685"
    viewBox="0 0 165.852 160.685"
    {...props}
  >
    <title>{useLocalizedFromContext(title)}</title>
    <g id="Component_7_1" data-name="Component 7 – 1" transform="translate(2.5 2.5)">
      <path
        id="Path_743"
        data-name="Path 743"
        d="M285.261,6596.431s4.562-84.069-46.818-108.119c0,0,19.407,18.775,19.407,108.119Z"
        transform="translate(-218.586 -6440.746)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_744"
        data-name="Path 744"
        d="M262.234,6507.086s.047-34.232-4.62-52.9c0,0,27.1,13.61,31.766,57.948"
        transform="translate(-218.586 -6440.746)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_745"
        data-name="Path 745"
        d="M281.9,6555.158s.693-75.074,56.366-114.412c0,0-26.336,59.34-26.336,121.014v34.671h-26.67"
        transform="translate(-218.586 -6440.746)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_746"
        data-name="Path 746"
        d="M312.2,6550.756s6.4-58.671,54.87-80.006c0,0-28.8,62.007-28.8,125.681H311.931"
        transform="translate(-218.586 -6440.746)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_747"
        data-name="Path 747"
        d="M253.644,6533.59s-22.389-22.336-35.058-23.336c0,0,19.857,29.5,19.857,86.177H257.85"
        transform="translate(-218.586 -6440.746)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_748"
        data-name="Path 748"
        d="M340.575,6564.834s9.36-31.744,38.863-31.244c0,0-21.5,12.835-21.5,62.841H338.267"
        transform="translate(-218.586 -6440.746)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
    </g>
  </svg>
);

Crop45.defaultProps = {
  title: 'grass',
};

Crop45.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
};
