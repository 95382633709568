/* eslint-disable import/prefer-default-export */
/* eslint-disable react/prop-types */
import React from 'react';
import { PropTypes } from 'prop-types';
import { useLocalizedFromContext } from 'data/Localization';

export const Crop22 = ({ title, fill, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="79.699"
    height="154.27"
    viewBox="0 0 79.699 154.27"
    {...props}
  >
    <title>{useLocalizedFromContext(title)}</title>
    <g id="Component_7_1" dataname="Component 7 – 1" transform="translate(2.546 2.602)">
      <path
        id="Path_509"
        dataname="Path 509"
        d="M1966.09,3969.192s-6-11.995-12.3-7.568-30.521,39.822-29.213,91.132c1.041,40.844,2.374,56.857,2.374,56.857"
        transform="translate(-1907.334 -3960.643)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_510"
        dataname="Path 510"
        d="M1935.279,4051.882h0c-2.574,10.473,5.815,28.424,15.508,33.152h0C1953.362,4074.561,1944.972,4056.61,1935.279,4051.882Z"
        transform="translate(-1907.334 -3960.643)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_511"
        dataname="Path 511"
        d="M1965.947,3968.15h0c-2.574,10.472,5.816,28.424,15.509,33.152h0C1984.03,3990.829,1975.64,3972.878,1965.947,3968.15Z"
        transform="translate(-1907.334 -3960.643)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_512"
        dataname="Path 512"
        d="M1950.167,3987.7h0c-5.189,9.455-1.713,28.963,6.433,36.031h0C1961.788,4014.274,1958.313,3994.765,1950.167,3987.7Z"
        transform="translate(-1907.334 -3960.643)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_513"
        dataname="Path 513"
        d="M1939.011,4007.426h0c-6.763,8.4-6.753,28.217.031,36.6h0C1945.8,4035.626,1945.794,4015.81,1939.011,4007.426Z"
        transform="translate(-1907.334 -3960.643)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_514"
        dataname="Path 514"
        d="M1914.828,4001.528h0c-7.708,7.543-10.041,27.221-4.3,36.348h0C1918.24,4030.333,1920.573,4010.655,1914.828,4001.528Z"
        transform="translate(-1907.334 -3960.643)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <line
        id="Line_40"
        dataname="Line 40"
        x1="8.232"
        y1="9.019"
        transform="translate(34.349 17.769)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <line
        id="Line_41"
        dataname="Line 41"
        x2="9.665"
        y2="12.893"
        transform="translate(17.602 77.473)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_515"
        dataname="Path 515"
        d="M1929.106,4011.336l-3.616-17.761-9.761,7.152"
        transform="translate(-1907.334 -3960.643)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <line
        id="Line_42"
        dataname="Line 42"
        y1="24.255"
        x2="0.298"
        transform="translate(31.677 22.529)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
    </g>
  </svg>
);

Crop22.defaultProps = {
  title: 'oats',
};

Crop22.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
};
