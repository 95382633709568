import React from 'react';

const Envelope = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="60.371"
    height="45.986"
    viewBox="0 0 60.371 45.986"
    {...props}
  >
    <path
      id="Path_1162"
      dataname="Path 1162"
      d="M60.371,66.306A5.315,5.315,0,0,0,55.065,61H5.306A5.315,5.315,0,0,0,0,66.306V101.68a5.315,5.315,0,0,0,5.306,5.306H55.065a5.315,5.315,0,0,0,5.306-5.306Zm-56.834.716,17.036,16.89L3.537,100.947ZM30.3,88.572,6.056,64.537H54.332ZM23.085,86.4l5.973,5.922a1.769,1.769,0,0,0,2.5-.005l5.824-5.824,16.954,16.954H6.039ZM39.88,83.993,56.834,67.039v33.909Z"
      transform="translate(0 -61)"
      fill="#849daa"
    />
  </svg>
);

export default Envelope;
