import {
  GET_ORGANIC_FERTILIZER_LIST_LOADING,
  GET_ORGANIC_FERTILIZER_LIST_SUCCESS,
  GET_ORGANIC_FERTILIZER_LIST_FAILED,
  POST_ORGANIC_FERTILIZER_LOADING,
  POST_ORGANIC_FERTILIZER_SUCCESS,
  POST_ORGANIC_FERTILIZER_FAILED,
  PUT_ORGANIC_FERTILIZER_LOADING,
  PUT_ORGANIC_FERTILIZER_SUCCESS,
  PUT_ORGANIC_FERTILIZER_FAILED,
  DELETE_ORGANIC_FERTILIZER_LOADING,
  DELETE_ORGANIC_FERTILIZER_SUCCESS,
  DELETE_ORGANIC_FERTILIZER_FAILED,
  STATUS_DEFAULT,
  STATUS_GETTING,
  STATUS_GET_SUCCESS,
  STATUS_GET_FAILED,
  STATUS_POST_FAILED,
  STATUS_PUT_FAILED,
  STATUS_DELETE_FAILED,
  STATUS_POST_SUCCESS,
  STATUS_PUT_SUCCESS,
  STATUS_DELETE_SUCCESS,
} from 'Redux/actions/types';
import OrganicFertilizerService from 'services/OrganicFertilizerService';

const initialState = {
  status: STATUS_DEFAULT,
  statusCode: null,
  errorCode: null,
  data: null,
};

export default (state = initialState, action) => {
  const { payload, type } = action;

  switch (type) {
    case GET_ORGANIC_FERTILIZER_LIST_LOADING:
      return {
        status: STATUS_GETTING,
        errorCode: null,
      };

    case GET_ORGANIC_FERTILIZER_LIST_SUCCESS:
      return {
        data: payload,
        status: STATUS_GET_SUCCESS,
        errorCode: null,
      };

    case GET_ORGANIC_FERTILIZER_LIST_FAILED:
      return {
        status: STATUS_GET_FAILED,
        statusCode: payload.statusCode,
        errorCode: payload.errorCode,
      };

    case POST_ORGANIC_FERTILIZER_LOADING:
      return {
        ...state,
        status: STATUS_GETTING,
      };

    case POST_ORGANIC_FERTILIZER_SUCCESS:
      return {
        status: STATUS_POST_SUCCESS,
        data: [...state.data, payload],
      };

    case POST_ORGANIC_FERTILIZER_FAILED:
      return {
        ...state,
        status: STATUS_POST_FAILED,
        statusCode: payload.statusCode,
        errorCode: payload.errorCode,
      };

    case PUT_ORGANIC_FERTILIZER_LOADING:
      return {
        ...state,
        status: STATUS_GETTING,
      };

    case PUT_ORGANIC_FERTILIZER_SUCCESS:
      return {
        ...state,
        status: STATUS_PUT_SUCCESS,
        data: OrganicFertilizerService.getMergedOrganicFertilizerList(payload, state.data),
      };

    case PUT_ORGANIC_FERTILIZER_FAILED:
      return {
        ...state,
        status: STATUS_PUT_FAILED,
        statusCode: payload.statusCode,
        errorCode: payload.errorCode,
      };

    case DELETE_ORGANIC_FERTILIZER_LOADING:
      return {
        ...state,
        status: STATUS_GETTING,
      };

    case DELETE_ORGANIC_FERTILIZER_SUCCESS:
      return {
        ...state,
        status: STATUS_DELETE_SUCCESS,
        data: OrganicFertilizerService.getOrganicFertilizerListWithout(payload, state.data),
      };

    case DELETE_ORGANIC_FERTILIZER_FAILED:
      return {
        ...state,
        status: STATUS_DELETE_FAILED,
        statusCode: payload.statusCode,
        errorCode: payload.errorCode,
      };

    default:
      return state;
  }
};
