import {
  SET_CROP_ROTATION,
  SET_CURRENT_CROP_ROTATION_PLAN,
  UPDATE_CROP_ROTATIONS_PLAN,
  UPDATE_CROP_ROTATIONS_PLAN_NG4,
  UPDATE_CROP_ROTATION,
  RESET_CROP_ROTATION,
  SET_CROP_ROTATION_WAS_EDITED,
  RESET_MY_CROP_ROTATION_STATUS,
} from './types';

export const setCropRotation = (cropRotation) => ({
  type: SET_CROP_ROTATION,
  payload: cropRotation,
});

export const setCurrentPlan = (plan) => ({
  type: SET_CURRENT_CROP_ROTATION_PLAN,
  payload: plan,
});

export const updateCropRotationPlan = (planId, date, key) => ({
  type: UPDATE_CROP_ROTATIONS_PLAN,
  payload: {
    planId,
    date,
    key,
  },
});

export const updateCropRotationPlanNG4 = (planId, date, key) => ({
  type: UPDATE_CROP_ROTATIONS_PLAN_NG4,
  payload: {
    planId,
    date,
    key,
  },
});

export const updateCropRotation = (value, key) => ({
  type: UPDATE_CROP_ROTATION,
  payload: {
    value,
    key,
  },
});

export const resetCropRotation = () => ({
  type: RESET_CROP_ROTATION,
});

export const setCropRotationEditedState = (wasEdited) => ({
  type: SET_CROP_ROTATION_WAS_EDITED,
  payload: wasEdited,
});

export const resetCropRotationStatus = () => ({
  type: RESET_MY_CROP_ROTATION_STATUS,
});
