/* eslint-disable import/prefer-default-export */
import { RESET_MY_PLOTS, SET_MY_PLOTS_STATUS, MERGE_PLOT, REMOVE_TEMP_PLOTS } from './types';

export const resetMyPlots = () => ({
  type: RESET_MY_PLOTS,
});

export const mergePlot = (plot) => ({
  type: MERGE_PLOT,
  payload: plot,
});

export const removeTemporaryPlots = () => ({
  type: REMOVE_TEMP_PLOTS,
});

export const setMyPlotsStatus = (status) => ({
  type: SET_MY_PLOTS_STATUS,
  payload: status,
});
