import {
  UPDATE_PLAN,
  RESET_PLAN,
  CREATE_USER_SUCCESS,
  GET_USER_FAILED,
  POST_PLAN_LOADING,
  POST_PLAN_SUCCESS,
  POST_PLAN_FAILED,
  PUT_PLAN_LOADING,
  PUT_PLAN_SUCCESS,
  PUT_PLAN_FAILED,
  STATUS_POSTING,
  STATUS_POST_SUCCESS,
  STATUS_POST_FAILED,
  STATUS_PUTTING,
  STATUS_PUT_SUCCESS,
  STATUS_PUT_FAILED,
  GET_SOIL_COMPOSITION_SUCCESS,
  POST_PLAN_BULK_LOADING,
  POST_PLAN_BULK_SUCCESS,
} from 'Redux/actions/types';
import { getInitialPlanState } from 'Utils/PlanUtils';

const initialState = {};

const getPlanId = (state, payload) => {
  if (payload.json) {
    if (payload.json.paramsOut) {
      return payload.json.paramsOut.id;
    }

    // Required for NG4 response handling
    return payload.json.id;
  }
  return state.id;
};

export default (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case UPDATE_PLAN:
      return { ...state, ...payload };

    case GET_SOIL_COMPOSITION_SUCCESS:
      return { ...payload, ...state };

    case CREATE_USER_SUCCESS:
      return { ...state, ...{ shouldPost: true } };

    case GET_USER_FAILED:
      return { ...state, ...{ shouldPost: false } };

    case RESET_PLAN:
      // Checking NG version requires the country all the time
      return getInitialPlanState({ state, country: payload.country });

    case POST_PLAN_LOADING:
      return {
        ...state,
        status: STATUS_POSTING,
        statusCode: null,
        errorCode: null,
      };

    case POST_PLAN_SUCCESS:
      return {
        ...state,
        status: STATUS_POST_SUCCESS,
        statusCode: payload.statusCode,
        errorCode: null,
        id: getPlanId(state, payload),
      };

    case POST_PLAN_FAILED:
      return {
        ...state,
        status: STATUS_POST_FAILED,
        statusCode: payload.statusCode,
        errorCode: payload.errorCode,
      };

    case PUT_PLAN_LOADING:
      return {
        ...state,
        status: STATUS_PUTTING,
        statusCode: null,
        errorCode: null,
      };

    case PUT_PLAN_SUCCESS:
      return {
        ...state,
        status: STATUS_PUT_SUCCESS,
        statusCode: payload.statusCode,
        errorCode: null,
        id: getPlanId(state, payload),
      };

    case PUT_PLAN_FAILED:
      return {
        ...state,
        status: STATUS_PUT_FAILED,
        statusCode: payload.statusCode,
        errorCode: payload.errorCode,
      };

    case POST_PLAN_BULK_LOADING:
      return {
        ...state,
        status: POST_PLAN_BULK_LOADING,
      };
    case POST_PLAN_BULK_SUCCESS:
      return {
        ...state,
        status: POST_PLAN_BULK_SUCCESS,
      };

    default:
      return state;
  }
};
