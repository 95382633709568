/* eslint-disable react/prop-types */
import React from 'react';

const GradientSoil = (props) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120.5 117.3">
    <title>Soil</title>
    <path
      d="M98.8,45.9a26,26,0,0,1,4-7.3,38.7,38.7,0,0,1,3.9-4.8c-6.9,1-10.2,4.9-11.1,6.2s-.3.3-.3.4a1.8,1.8,0,0,1-1.7.9h-.1a1.7,1.7,0,0,1-1.4-.7.5.5,0,0,1-.2-.4h0A19.3,19.3,0,0,0,76.6,28.7a47.5,47.5,0,0,1,8.1,13.4,37.3,37.3,0,0,1,1.5,3.8Z"
      fill="none"
    />
    <polygon points="71.2 54.5 59.9 54.5 54.4 63.2 60.8 68.6 71.4 63.1 71.2 54.5" fill="none" />
    <path
      d="M20.8,40.5A1.1,1.1,0,0,0,22,41.7a1.2,1.2,0,0,0,0-2.4A1.1,1.1,0,0,0,20.8,40.5Z"
      fill="none"
    />
    <polygon
      points="97.4 76.6 89.8 96.3 92.9 100.7 101.8 113.4 116.6 113.4 116.6 96 116.6 92.4 97.4 76.6"
      fill="none"
    />
    <path
      d="M34.5,40.5a1.1,1.1,0,0,0,1.1,1.2,1.2,1.2,0,0,0,0-2.4A1.1,1.1,0,0,0,34.5,40.5Z"
      fill="none"
    />
    <path d="M49.3,39.3a1.2,1.2,0,1,0,1.2,1.2A1.1,1.1,0,0,0,49.3,39.3Z" fill="none" />
    <path
      d="M105.1,16.9c0-1.1-.7-1.2-1.2-1.2s-1.1.1-1.1,1.2a1,1,0,0,0,1.1,1.1C104.4,18,105.1,17.9,105.1,16.9Z"
      fill="none"
    />
    <polygon points="80 62.1 94.5 64.8 108.6 54.5 79.8 54.5 80 62.1" fill="none" />
    <path d="M42.5,27.5a1.2,1.2,0,0,0,0,2.4,1.2,1.2,0,1,0,0-2.4Z" fill="none" />
    <path d="M28.8,27.5A1.2,1.2,0,1,0,30,28.7,1.1,1.1,0,0,0,28.8,27.5Z" fill="none" />
    <path
      d="M49.3,15.7a1.1,1.1,0,0,0-1.2,1.2,1.2,1.2,0,0,0,2.4,0A1.1,1.1,0,0,0,49.3,15.7Z"
      fill="none"
    />
    <polygon
      points="17.4 79.9 23.8 67.2 17.2 55.2 16.8 54.5 3.9 54.5 3.9 57.8 3.9 71.3 17.4 79.9"
      fill="none"
    />
    <path
      d="M63,18a1,1,0,0,0,1.1-1.1,1.3,1.3,0,0,0-.2-.8,1,1,0,0,0-.9-.4,1.1,1.1,0,0,0-1.2.8v.4C61.8,17.9,62.5,18,63,18Z"
      fill="none"
    />
    <polygon points="11.8 86.6 11.6 86.4 3.9 81.5 3.9 87.9 3.9 93.1 11.8 86.6" fill="none" />
    <path
      d="M8.3,18c.5,0,1.2-.1,1.2-1.1s-.7-1.2-1.2-1.2a1.1,1.1,0,0,0-1.2,1.2A1,1,0,0,0,8.3,18Z"
      fill="none"
    />
    <path
      d="M41.3,5c0,1.1.7,1.2,1.2,1.2A1.1,1.1,0,0,0,43.7,5a1.1,1.1,0,0,0-1.2-1.2A1.1,1.1,0,0,0,41.3,5Z"
      fill="none"
    />
    <path
      d="M83.5,3.8A1.1,1.1,0,0,0,82.3,5c0,1.1.7,1.2,1.2,1.2s1.1-.1,1.1-1.2A1.1,1.1,0,0,0,83.5,3.8Z"
      fill="none"
    />
    <path
      d="M15.1,3.8A1.1,1.1,0,0,0,14,5c0,1.1.7,1.2,1.1,1.2s1.2-.1,1.2-1.2A1.1,1.1,0,0,0,15.1,3.8Z"
      fill="none"
    />
    <path
      d="M22,18a1,1,0,0,0,1.2-1.1A1.1,1.1,0,0,0,22,15.7c-.5,0-1.2.1-1.2,1.2S21.5,18,22,18Z"
      fill="none"
    />
    <polygon
      points="91.3 113.4 83.6 102.5 82.6 101 55.8 99.1 53.8 108.3 52.6 113.4 91.3 113.4"
      fill="none"
    />
    <polygon
      points="58.5 90.6 82.1 92.3 89.7 72.7 85.8 72 76.5 70.2 65.4 75.9 63.7 76.8 58.5 90.6"
      fill="none"
    />
    <polygon
      points="20.1 90.8 3.9 104.4 3.9 113.4 27.7 113.4 43.8 113.4 44.5 110.1 47.3 97.7 20.1 90.8"
      fill="none"
    />
    <polygon points="46.1 60.1 49.7 54.5 26.6 54.5 31 62.4 46.1 60.1" fill="none" />
    <polygon points="116.6 81.2 116.6 66 116.6 59.3 104.3 68.4 102.6 69.6 116.6 81.2" fill="none" />
    <polygon
      points="47.4 68.7 31.5 71 25.3 83.2 26.5 83.5 49.8 89.4 54 78.2 55.1 75.1 47.4 68.7"
      fill="none"
    />
    <path
      d="M47.3,115.7l1.4-6.4-4.2.8-.7,3.3H27.7L7.6,117.3H45.4A2,2,0,0,0,47.3,115.7Z"
      fill="#b95700"
    />
    <path
      d="M91.3,113.4H52.6l1.2-5.1-4.2.8-1.3,5.8a1.8,1.8,0,0,0,.4,1.6,1.8,1.8,0,0,0,1.5.8H95.1a1.8,1.8,0,0,0,1.7-1.1,2,2,0,0,0-.1-2l-8.9-12.6-4.2.9Z"
      fill="#b95700"
    />
    <path
      d="M100.8,117.3h17.7a2,2,0,0,0,2-2v-20l-3.9.7v17.4H101.8l-8.9-12.7-4.2.8,10.5,14.9A1.9,1.9,0,0,0,100.8,117.3Z"
      fill="#b95700"
    />
    <path
      d="M53.8,108.3l2-9.2L82.6,101l1,1.5,4.2-.9L85.3,98a1.9,1.9,0,0,0-1.5-.8L54.4,95.1a1.8,1.8,0,0,0-2,1.5l-2.8,12.5Z"
      fill="#eb6909"
    />
    <path
      d="M89.8,96.3l7.6-19.7,19.2,15.8V96l3.9-.7V91.5a2.1,2.1,0,0,0-.7-1.5l-22-18.2h0a2.1,2.1,0,0,0-1.7-.4,2.4,2.4,0,0,0-1.4,1.2L85.8,95.8a1.9,1.9,0,0,0,.2,1.9l2.7,3.8,4.2-.8Z"
      fill="#eb6909"
    />
    <path
      d="M3.9,113.4v-9L20.1,90.8l27.2,6.9-2.8,12.4,4.2-.8,2.9-12.6a2,2,0,0,0-1.5-2.4l-30-7.5a1.8,1.8,0,0,0-1.7.4L.7,102a2,2,0,0,0-.7,1.5v11.8a2,2,0,0,0,2,2H7.6l20.1-3.9Z"
      fill="#eb6909"
    />
    <path
      d="M116.6,81.2l-14-11.6,1.7-1.2-6.9,1.3a2.1,2.1,0,0,0,.7,1.3l19.2,15.9a1.8,1.8,0,0,0,1.2.5l.9-.2a1.9,1.9,0,0,0,1.1-1.8V65.2l-3.9.8Z"
      fill="#eb6909"
    />
    <path
      d="M22,86.4l28.5,7.2H51a1.9,1.9,0,0,0,1.8-1.3l5.7-15.1-4.5.9L49.8,89.4,26.5,83.5l-5.9,1.2a1.3,1.3,0,0,0,.1.6A1.8,1.8,0,0,0,22,86.4Z"
      fill="#eb6909"
    />
    <path
      d="M11.8,86.6,3.9,93.1V87.9L0,88.7v8.6a1.9,1.9,0,0,0,1.1,1.8h0l.9.2a1.8,1.8,0,0,0,1.2-.5l13.1-11a1.7,1.7,0,0,0,.7-1.6,1.9,1.9,0,0,0-.2-.8l-5.2,1Z"
      fill="#eb6909"
    />
    <path
      d="M89.7,72.7,82.1,92.3,58.5,90.6l5.2-13.8,1.7-.9-6,1.2L53.9,91.7a2,2,0,0,0,.2,1.7,1.6,1.6,0,0,0,1.5.9l27.6,2h.2a2.1,2.1,0,0,0,1.8-1.3l9-23.2a2.2,2.2,0,0,0-.1-1.6L85.8,72Z"
      fill="#eb6909"
    />
    <path
      d="M116.4,51.9a1.8,1.8,0,0,0-1.8-1.3H77.8a2.1,2.1,0,0,0-2,2l.4,11.2a1.8,1.8,0,0,0,1.6,1.8l16.8,3.2H95a2.1,2.1,0,0,0,1.1-.4l19.6-14.4A1.9,1.9,0,0,0,116.4,51.9ZM94.5,64.8,80,62.1l-.2-7.6h28.8Z"
      fill="#5f9501"
    />
    <path
      d="M119.4,53.7a1.8,1.8,0,0,0-2,.2l-19.2,14a2.1,2.1,0,0,0-.8,1.6v.2l6.9-1.3,12.3-9.1V66l3.9-.8V55.5A2,2,0,0,0,119.4,53.7Z"
      fill="#5f9501"
    />
    <path
      d="M23.8,67.2,17.4,79.9,3.9,71.3V57.8L0,58.6V72.5a1.8,1.8,0,0,0,1.3,1.8h.1l15.7,9.9a2.1,2.1,0,0,0,1,.4h.6a2.3,2.3,0,0,0,1.2-1L27.8,68a1.9,1.9,0,0,0-.1-1.8L21.2,54.4l-4,.8Z"
      fill="#5f9501"
    />
    <path
      d="M25.3,83.2,31.5,71l15.9-2.3,7.7,6.4L54,78.2l4.5-.9.8-2.1a2,2,0,0,0-.6-2.2l-9.5-7.9a1.7,1.7,0,0,0-1.5-.4L29.9,67.3a2.1,2.1,0,0,0-1.4,1L20.7,83.6a2.1,2.1,0,0,0-.1,1.1l5.9-1.2Z"
      fill="#5f9501"
    />
    <path
      d="M92.7,69.3,76.6,66.2a1.9,1.9,0,0,0-1.3.2L61.2,73.7a2.2,2.2,0,0,0-.9,1l-.9,2.4,6-1.2,11.1-5.7L85.8,72l8.3-1.7H94A1.5,1.5,0,0,0,92.7,69.3Z"
      fill="#5f9501"
    />
    <path
      d="M29.9,66.5h.3l17.4-2.5a2,2,0,0,0,1.3-.9l6-9.4a1.9,1.9,0,0,0-1.6-3H41L22,54.3l6.2,11.2A2.1,2.1,0,0,0,29.9,66.5Zm19.8-12-3.6,5.6L31,62.4l-4.4-7.9Z"
      fill="#5f9501"
    />
    <path
      d="M74.3,66a1.9,1.9,0,0,0,1.1-1.8l-.3-11.7a2,2,0,0,0-2-1.9H58.8a1.8,1.8,0,0,0-1.6.9L50.1,62.6a2,2,0,0,0,.4,2.5l8.8,7.3a2.1,2.1,0,0,0,1.3.5l.9-.2ZM54.4,63.2l5.5-8.7H71.2l.2,8.6L60.8,68.6Z"
      fill="#5f9501"
    />
    <path
      d="M98.8,45.9H86.2a37.3,37.3,0,0,0-1.5-3.8l-3.9.7a40.2,40.2,0,0,1,2.1,5.7h0a2,2,0,0,0,1.9,1.3h15.5a1.9,1.9,0,0,0,1.9-1.5c.9-3.9,3.9-8,6.5-10.9l-5.9,1.2A26,26,0,0,0,98.8,45.9Z"
      fill="#5f9501"
    />
    <path
      d="M3.9,87.9V81.5l7.7,4.9,5.2-1a2.3,2.3,0,0,0-.7-.7L3,76.3a1.8,1.8,0,0,0-2,0A1.9,1.9,0,0,0,0,78V88.7Z"
      fill="#5f9501"
    />
    <path
      d="M93.5,41.3h.1a1.8,1.8,0,0,0,1.7-.9c0-.1.1-.2.3-.4l-3.5.6A1.7,1.7,0,0,0,93.5,41.3Z"
      fill="#5f9501"
    />
    <path
      d="M3.9,57.8V54.5H16.8l.4.7,4-.8-1.6-2.8a1.9,1.9,0,0,0-1.7-1H2a2,2,0,0,0-2,1.9v6.1Z"
      fill="#79b61d"
    />
    <path d="M49.3,22a5,5,0,0,0,5-4.1L45,19.7A5.1,5.1,0,0,0,49.3,22Z" fill="#79b61d" />
    <path
      d="M31.5,24.4a5.2,5.2,0,0,0-5.4,0,4.8,4.8,0,0,0-2.4,4.3,5.1,5.1,0,1,0,10.2,0A4.6,4.6,0,0,0,31.5,24.4Zm-2.7,5.5A1.2,1.2,0,1,1,30,28.7,1.1,1.1,0,0,1,28.8,29.9Z"
      fill="#79b61d"
    />
    <path
      d="M16.9,40.5A4.9,4.9,0,0,0,22,45.6a5.1,5.1,0,1,0,0-10.1A4.8,4.8,0,0,0,16.9,40.5Zm6.3,0A1.1,1.1,0,0,1,22,41.7a1.2,1.2,0,1,1,0-2.4A1.1,1.1,0,0,1,23.2,40.5Z"
      fill="#79b61d"
    />
    <path d="M23.3,50.6a2.1,2.1,0,0,0-1.7,1,1.8,1.8,0,0,0,0,1.9l.4.8,19-3.7Z" fill="#79b61d" />
    <path
      d="M30.5,40.5a4.9,4.9,0,0,0,5.1,5.1,5.1,5.1,0,1,0,0-10.1A4.9,4.9,0,0,0,30.5,40.5Zm6.3,0a1.1,1.1,0,0,1-1.2,1.2,1.2,1.2,0,0,1,0-2.4A1.1,1.1,0,0,1,36.8,40.5Z"
      fill="#79b61d"
    />
    <path
      d="M111.7,29.8c-9.6-.6-15.2,3.4-17.7,6C86.1,22.9,71.4,24.7,71.3,24.7a1.9,1.9,0,0,0-1.6,1.4,2,2,0,0,0,.6,2.1c4.9,3.8,8.2,9.4,10.5,14.6l3.9-.7a47.5,47.5,0,0,0-8.1-13.4A19.3,19.3,0,0,1,91.9,40.2h0a.5.5,0,0,0,.2.4l3.5-.6c.9-1.3,4.2-5.2,11.1-6.2a38.7,38.7,0,0,0-3.9,4.8l5.9-1.2a47.3,47.3,0,0,1,4.2-4.2,2.1,2.1,0,0,0,.6-2.1A1.9,1.9,0,0,0,111.7,29.8Z"
      fill="#79b61d"
    />
    <path
      d="M49.3,35.5a4.8,4.8,0,0,0-5.1,5,5.1,5.1,0,1,0,5.1-5Zm0,6.2a1.2,1.2,0,1,1,1.2-1.2A1.1,1.1,0,0,1,49.3,41.7Z"
      fill="#79b61d"
    />
    <path
      d="M42.5,23.7A5.3,5.3,0,0,0,39,24.9a4.6,4.6,0,0,0-1.6,3.8,4.9,4.9,0,0,0,5.1,5.1,5,5,0,0,0,5.1-5.1A4.8,4.8,0,0,0,42.5,23.7Zm0,6.2a1.2,1.2,0,0,1,0-2.4,1.2,1.2,0,1,1,0,2.4Z"
      fill="#79b61d"
    />
    <path
      d="M109,16.9a5.1,5.1,0,1,0-10.1,0,5.1,5.1,0,1,0,10.1,0Zm-6.2,0c0-1.1.7-1.2,1.1-1.2s1.2.1,1.2,1.2-.7,1.1-1.2,1.1A1,1,0,0,1,102.8,16.9Z"
      fill="#79b61d"
    />
    <path
      d="M64.1,16.9A1,1,0,0,1,63,18c-.5,0-1.2-.1-1.2-1.1v-.4l-3.9.7a5.1,5.1,0,0,0,10.2-.3,4.1,4.1,0,0,0-.3-1.6l-3.9.8A1.3,1.3,0,0,1,64.1,16.9Z"
      fill="#79b61d"
    />
    <path
      d="M22,22a5,5,0,0,0,5.1-5.1A4.9,4.9,0,0,0,22,11.8a4.9,4.9,0,0,0-5.1,5.1A4.9,4.9,0,0,0,22,22Zm0-6.3a1.1,1.1,0,0,1,1.2,1.2A1,1,0,0,1,22,18c-.5,0-1.2-.1-1.2-1.1S21.5,15.7,22,15.7Z"
      fill="#c8d200"
    />
    <path
      d="M83.5,0a4.8,4.8,0,0,0-5.1,5,4.9,4.9,0,0,0,5.1,5.1A4.9,4.9,0,0,0,88.6,5,4.9,4.9,0,0,0,83.5,0Zm0,6.2c-.5,0-1.2-.1-1.2-1.2a1.1,1.1,0,0,1,1.2-1.2A1.1,1.1,0,0,1,84.6,5C84.6,6.1,83.9,6.2,83.5,6.2Z"
      fill="#c8d200"
    />
    <path
      d="M39,1.2A4.6,4.6,0,0,0,37.4,5a4.9,4.9,0,0,0,5.1,5.1A4.9,4.9,0,0,0,47.6,5a4.9,4.9,0,0,0-5.1-5A5.3,5.3,0,0,0,39,1.2ZM43.7,5a1.1,1.1,0,0,1-1.2,1.2c-.5,0-1.2-.1-1.2-1.2a1.1,1.1,0,0,1,1.2-1.2A1.1,1.1,0,0,1,43.7,5Z"
      fill="#c8d200"
    />
    <path
      d="M54.4,16.9a5.1,5.1,0,0,0-10.2,0,4.9,4.9,0,0,0,.8,2.8l9.3-1.8A3.4,3.4,0,0,0,54.4,16.9ZM49.3,18a1,1,0,0,1-1.2-1.1,1.2,1.2,0,0,1,2.4,0A1,1,0,0,1,49.3,18Z"
      fill="#c8d200"
    />
    <path
      d="M8.3,22a5,5,0,0,0,5.1-5.1,4.9,4.9,0,0,0-5.1-5.1,4.9,4.9,0,0,0-5.1,5.1A5,5,0,0,0,8.3,22Zm0-6.3c.5,0,1.2.1,1.2,1.2S8.8,18,8.3,18a1,1,0,0,1-1.2-1.1A1.1,1.1,0,0,1,8.3,15.7Z"
      fill="#c8d200"
    />
    <path
      d="M20.2,5a4.8,4.8,0,0,0-5.1-5A4.9,4.9,0,0,0,10,5a4.9,4.9,0,0,0,5.1,5.1A4.9,4.9,0,0,0,20.2,5ZM15.1,6.2C14.7,6.2,14,6.1,14,5a1.1,1.1,0,0,1,1.1-1.2A1.1,1.1,0,0,1,16.3,5C16.3,6.1,15.6,6.2,15.1,6.2Z"
      fill="#c8d200"
    />
    <path
      d="M65.7,12.5a5.9,5.9,0,0,0-5.5,0,4.9,4.9,0,0,0-2.3,4.4v.3l3.9-.7a1.1,1.1,0,0,1,1.2-.8,1,1,0,0,1,.9.4l3.9-.8A4.5,4.5,0,0,0,65.7,12.5Z"
      fill="#c8d200"
    />
  </svg>
);

export default GradientSoil;
