/* eslint-disable react/prop-types */
import React from 'react';
import UserUtils from 'Utils/UserUtils';

const ColouredFarmer = ({ fill, ...props }) => {
  if (UserUtils.isNG4AtLP()) {
    return (
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 128 128"
        enableBackground="new 0 0 128 128"
        xmlSpace="preserve"
        {...props}
      >
        <g>
          <defs>
            <path
              id="SVGID_53_ColouredFarmer"
              d="M60.4,32.9c0-0.7,0.5-1.2,1.2-1.2h4.7c0.7,0,1.2,0.5,1.2,1.2v0c0,0.7-0.5,1.2-1.2,1.2h-4.7
              C61,34.1,60.4,33.5,60.4,32.9z M53,89.8h-3.9c-0.7,0-1.2,0.5-1.2,1.2v0c0,0.7,0.5,1.2,1.2,1.2H53c0.7,0,1.2-0.5,1.2-1.2
              S53.7,89.8,53,89.8z M79,89.8h-3.9c-0.7,0-1.2,0.5-1.2,1.2c0,0.7,0.5,1.2,1.2,1.2H79c0.7,0,1.2-0.5,1.2-1.2
              C80.2,90.3,79.6,89.8,79,89.8z M93.7,96.8c-0.3,0.7-0.8,1.1-1.5,1.4l-0.6,0.2c-2.8,0.9-13.3,3.7-28.8,3.7
              c-8.6,0-16.3-1.4-21.3-2.7c0,0-0.1,0-0.1,0c-3-0.7-5-1.4-5.8-1.7c-0.7-0.2-1.3-0.8-1.5-1.4c-1.8-4.5-2.6-9.3-2.3-14.2
              c0-0.7,0.1-1.4,0.3-2c0.3-1.2,0.7-2.4,1.2-3.5c0.8-2.1,1.9-4.3,3.6-5.9c2.2-1.9,5.2-3.4,14-5c0,0,0,0,0,0c0.3-0.1,0.6-0.1,0.9-0.2
              c2.2-0.3,2.3-0.5,2.5-1.8c-3.7-3.5-6.3-8.4-7.7-14.8c0,0,0,0,0,0l-0.1-0.4c-0.2-1-0.3-2.1-0.3-3.5v-7c0-6.8,5.2-12,12.1-12h11.2
              c7,0,12.7,5.4,12.7,12v6.6c0,0.8-0.1,1.4-0.2,1.9c0,0.1-0.1,0.3-0.1,0.4c-0.1,0.3-0.3,1.2-0.3,1.2c-1.3,6.6-4,11.9-7.8,15.7
              c0.1,1.1,0.3,1.3,2.4,1.7c0.4,0.1,0.7,0.1,1.1,0.2c0,0,0,0,0.1,0c8.6,1.6,11.6,3.1,13.8,5c2.6,2.3,4,5.8,4.5,7.7
              C97.1,83.7,96.4,90.4,93.7,96.8z M81.5,69.6c-0.6-0.1-1.3-0.3-2-0.5v14.6h2V69.6z M46.5,83.8h2V69.1c-0.7,0.2-1.4,0.3-2,0.4V83.8z
              M51.5,83.8h25.1V69.2l-11.8,8.4c-0.3,0.2-0.6,0.3-0.9,0.3c-0.3,0-0.6-0.1-0.9-0.3l-11.5-8.3V83.8z M51.1,46.3
              c0.9,0,3.3-0.5,5.3-1c2.6-0.6,5.2-1.1,6.8-1.2c0.5,0,1.6,0,1.6,0c1.7,0,4.4,0.6,7.1,1.2c2,0.4,4.2,0.9,5,0.9c0.9,0,1.7-1,2.1-1.5
              c0-0.1,0-0.1,0-0.2v-1.3c-2.9-0.7-11.2-2.3-15-2.4c-3.1-0.1-10.4,1.4-15,2.5v1.4C49.6,45.5,50.4,46.3,51.1,46.3z M49,38v2.2
              c3.7-0.9,11.4-2.5,15.1-2.4c3.7,0.1,11.2,1.5,14.9,2.3V38c0-5-4.4-9-9.7-9H58.1C52.9,29,49,32.8,49,38z M64.2,65.8
              c0.2,0.1,0.5,0.1,0.6,0c8.4-2.9,11.9-11.1,13.3-16.7c-0.4,0.1-0.8,0.2-1.2,0.2c-1.2,0-3.3-0.5-5.7-1c-2.4-0.5-5.1-1.1-6.5-1.1
              c0,0-1.1,0-1.5,0c-1.3,0-3.9,0.6-6.2,1.1c-2.6,0.6-4.8,1-6,1c-0.6,0-1.1-0.1-1.6-0.3C51.7,58.2,56.5,63.7,64.2,65.8z M56.7,65.7
              c-0.3,0.8-1,1.5-2.2,2.1l9.4,6.8l9.4-6.7c-1.2-0.5-1.8-1.1-2.2-1.9c-1.6,1.2-3.4,2.1-5.3,2.8c-0.4,0.1-0.8,0.2-1.3,0.2
              c-0.4,0-0.8-0.1-1.2-0.2C60.9,68,58.7,67,56.7,65.7z M36.7,94.9c0.6,0.2,1.8,0.6,3.5,1.1v-8.4c0-2.1,1.3-3.5,3.3-3.8V70.4
              c-2.7,0.9-3.9,1.7-4.8,2.5c-1.3,1.2-2.1,3-2.8,4.8c-0.4,1-0.8,2-1,3.1c-0.1,0.4-0.2,0.9-0.2,1.5C34.4,86.6,35.1,90.9,36.7,94.9z
              M62.7,99c9.6,0,17.1-1.1,22.1-2.1v-9.4c0-0.6-0.2-0.8-0.8-0.8H44c-0.6,0-0.8,0.2-0.8,0.8v9.2C48,97.8,54.9,99,62.7,99z
              M92.7,79.2c-0.5-1.6-1.5-4.5-3.6-6.3c-0.9-0.8-2-1.6-4.6-2.4v13.3c1.9,0.2,3.3,1.7,3.3,3.7v8.7c1.3-0.3,2.3-0.6,2.9-0.8l0.4-0.1
              C93.4,89.7,94,83.8,92.7,79.2z"
            />
          </defs>
          <clipPath id="SVGID_2_ColouredFarmer">
            <use xlinkHref="#SVGID_53_ColouredFarmer" overflow="visible" />
          </clipPath>
          <g clipPath="url(#SVGID_2_ColouredFarmer)">
            <rect
              x="-12"
              y="108.5"
              transform="matrix(0.9736 -0.2284 0.2284 0.9736 -26.3369 20.839)"
              fill="#B95700"
              width="177.8"
              height="31.5"
            />
            <rect
              x="-19.2"
              y="77.8"
              transform="matrix(0.9736 -0.2284 0.2284 0.9736 -19.5204 18.3844)"
              fill="#EB6909"
              width="177.8"
              height="31.5"
            />
            <rect
              x="-26.4"
              y="47.1"
              transform="matrix(0.9736 -0.2284 0.2284 0.9736 -12.7039 15.9297)"
              fill="#5F9501"
              width="177.8"
              height="31.5"
            />
            <rect
              x="-33.6"
              y="16.4"
              transform="matrix(0.9736 -0.2284 0.2284 0.9736 -5.8875 13.4751)"
              fill="#79B61D"
              width="177.8"
              height="31.5"
            />
            <rect
              x="-40.8"
              y="-14.3"
              transform="matrix(0.9736 -0.2284 0.2284 0.9736 0.929 11.0204)"
              fill="#C8D200"
              width="177.8"
              height="31.5"
            />
          </g>
        </g>
      </svg>
    );
  }

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 53.98796 64.22113" {...props}>
      <title>Farmer avatar</title>
      <path
        d="M44.67193,38.4884V50.8335h2.07581v9.13055l3.9422-1.03656.0003-.00006A29.06687,29.06687,0,0,0,51.83184,48.092c-.07452-.75409-.17254-1.49951-.31182-2.23724C51.3128,44.195,50.067,40.0459,44.67193,38.4884Z"
        fill="none"
      />
      <path
        d="M32.63318,38.21252l2.70111-1.90094a6.07794,6.07794,0,0,1-2.90558-3.11236,8.626,8.626,0,0,1-5.395,1.97107,9.313,9.313,0,0,1-5.60138-2.0755A5.68083,5.68083,0,0,1,18.52948,36.207L24.11865,40.21l2.81323,2.01489Z"
        fill="none"
      />
      <path
        d="M13.54724,50.73468V37.35034c-.72766.20679-1.35065.31146-2.07581.51831v12.866Z"
        fill="none"
      />
      <path
        d="M38.44659,50.73218V37.03668a.76745.76745,0,0,0-.37006-.10119c-.01452-.00025-.03186-.00354-.04614-.00354l-.03045.02154L27.03375,44.71411l-5.49127-3.89972L15.932,36.83a.37953.37953,0,0,1-.31152.10467V50.73218Z"
        fill="none"
      />
      <path
        d="M9.39618,58.04742v2.33289a87.77121,87.77121,0,0,0,35.27575,0V52.80737H9.39618Zm26.97467-3.1632h4.149V56.96h-4.149Zm-22.82611,0h4.149V56.96h-4.149Z"
        fill="none"
      />
      <path
        d="M9.39618,50.734V38.38629A9.36026,9.36026,0,0,0,2.68,45.23956c-.04175.17176-.10022.33905-.1319.51319l.00031.00006A28.77775,28.77775,0,0,0,3.37817,58.828l1.26874.33362,2.67346.703V50.734Z"
        fill="none"
      />
      <path
        d="M40.5227,37.45367V50.73285h2.07581V37.972A15.5274,15.5274,0,0,0,40.5227,37.45367Z"
        fill="none"
      />
      <path
        d="M14.58123,13.79749c.10212,1.14135.309,1.76434.7251,2.0758.623.51831,2.492,0,4.3584-.51831a40.975,40.975,0,0,1,5.81122-1.1413h3.11236a23.12225,23.12225,0,0,1,5.81116,1.1413c1.869.51831,3.6333,1.03662,4.3584.51831h.00031c.41363-.20679.623-.93445.62048-2.0758a35.226,35.226,0,0,0-16.97925-1.95105,35.83941,35.83941,0,0,0-6.77832,1.59014C15.274,13.554,14.92651,13.66919,14.58123,13.79749Z"
        fill="none"
      />
      <path
        d="M27.03375,1.97076c-5.91589,0-9.23511.51831-10.89472,3.11243H16.139c-.93194,1.34808-1.45288,3.52857-1.6571,6.53632A36.69464,36.69464,0,0,1,31.43676,9.72626a37.85433,37.85433,0,0,1,7.94452,1.89325,22.64388,22.64388,0,0,0-.38134-3.66761,8.27978,8.27978,0,0,0-1.07148-2.86871C36.26886,2.48907,32.94708,1.97076,27.03375,1.97076Zm3.11218,4.15149H23.92114V4.04645h6.22479Z"
        fill="none"
      />
      <path
        d="M33.77679,17.43079a20.84276,20.84276,0,0,0-5.2904-1.1413H25.58081a36.18669,36.18669,0,0,0-5.29035,1.1413,15.9304,15.9304,0,0,1-4.56518.72515h-.00019a23.83668,23.83668,0,0,0,3.193,8.89069c2.07684,3.4278,4.95068,6.048,8.11541,6.048,4.3935,0,8.28638-5.1034,10.18024-10.34021A20.70489,20.70489,0,0,0,38.342,18.15594,10.71053,10.71053,0,0,1,33.77679,17.43079Z"
        fill="none"
      />
      <polygon
        points="17.694 56.96 17.694 56.101 14.031 56.96 17.694 56.96"
        fill={fill || '#b95700'}
      />
      <path
        d="M27.03491,64.22113a90.49265,90.49265,0,0,0,25.42035-3.63074,37.481,37.481,0,0,0,1.42383-12.97863l-2.04725.48028a29.06687,29.06687,0,0,1-1.1416,10.83539l-.0003.00006-3.9422,1.03656V50.8335H44.67193V49.77173l-2.07342.48645v.47467H40.57513l-8.84277,2.07452H44.67193v7.57294a87.77121,87.77121,0,0,1-35.27575,0V58.04742l-2.07581.487v1.3302l-2.67346-.703-3.13361.73517c.124.43567.20648.6936.20648.6936A89.25914,89.25914,0,0,0,27.03491,64.22113Z"
        fill={fill || '#b95700'}
      />
      <rect x="36.37085" y="54.88422" width="4.14905" height="2.07581" fill={fill || '#b95700'} />
      <path
        d="M38.44659,37.03668v13.6955H15.62048V42.20374l-2.07324.48632v8.04462H11.47143V43.17706l-2.07525.48688V50.734H7.32037v7.80041l2.07581-.487V52.80737H31.73236l8.84277-2.07452H40.5227V37.45367a15.5274,15.5274,0,0,1,2.07581.51831v12.2862l2.07342-.48645V38.4884C50.067,40.0459,51.3128,44.195,51.52,45.8548c.13928.73773.2373,1.48315.31182,2.23724l2.04725-.48028c-.06434-.72248-.15039-1.4477-.28254-2.16993l-.00122.00067a11.3338,11.3338,0,0,0-7.88409-8.81878s-1.24622-.371-2.93268-.79132l-4.702,1.10309A.76745.76745,0,0,1,38.44659,37.03668Z"
        fill={fill || '#eb6909'}
      />
      <path
        d="M3.37817,58.828A28.77775,28.77775,0,0,1,2.5484,45.75281l-.00031-.00006c.03168-.17414.09015-.34143.1319-.51319L.35479,45.785C-.7143,51.2619.91717,57.80176,1.5133,59.89679l3.13361-.73517Z"
        fill={fill || '#eb6909'}
      />
      <polygon
        points="17.694 56.101 17.694 54.884 13.545 54.884 13.545 56.96 14.031 56.96 17.694 56.101"
        fill={fill || '#eb6909'}
      />
      <polygon
        points="38 36.953 32.633 38.213 26.932 42.225 24.119 40.21 21.542 40.814 27.034 44.714 38 36.953"
        fill={fill || '#eb6909'}
      />
      <path
        d="M9.39618,38.38629v5.27765l2.07525-.48688V37.86865c.72516-.20685,1.34815-.31152,2.07581-.51831v5.33972l2.07324-.48632v-5.269A.37953.37953,0,0,0,15.932,36.83l5.61048,3.98437L24.11865,40.21,18.52948,36.207a5.68083,5.68083,0,0,0,2.90289-3.11218,9.313,9.313,0,0,0,5.60138,2.0755,8.626,8.626,0,0,0,5.395-1.97107,6.07794,6.07794,0,0,0,2.90558,3.11236l-2.70111,1.90094,5.36676-1.259.03045-.02154c.01428,0,.03162.00329.04614.00354l4.702-1.10309a46.91091,46.91091,0,0,0-5.46979-1.07514,3.74641,3.74641,0,0,1-3.11236-3.00775,23.33549,23.33549,0,0,0,5.41-9.55633l-2.39264.56128C35.31988,27.99127,31.427,33.09467,27.0335,33.09467c-3.16473,0-6.03857-2.62024-8.11541-6.048l-2.176.5105a20.93316,20.93316,0,0,0,2.9317,4.08765,3.746,3.746,0,0,1-3.2171,3.2171,70.05306,70.05306,0,0,0-8.19586,1.86645A11.55979,11.55979,0,0,0,.37677,45.65167c-.00891.04389-.01337.08936-.022.13336L2.68,45.23956A9.36026,9.36026,0,0,1,9.39618,38.38629Z"
        fill={fill || '#5f9501'}
      />
      <path
        d="M15.72509,18.15594h.00019a15.9304,15.9304,0,0,0,4.56518-.72515,36.18669,36.18669,0,0,1,5.29035-1.1413h2.90558a20.84276,20.84276,0,0,1,5.2904,1.1413,10.71053,10.71053,0,0,0,4.56519.72515,20.70489,20.70489,0,0,1-1.12824,4.59852l2.39264-.56128a21.34243,21.34243,0,0,0,.9195-5.07386,6.112,6.112,0,0,0,1.03662-4.04687,24.62311,24.62311,0,0,0-.54578-5.59369l-2.01678.47314a22.64388,22.64388,0,0,1,.38134,3.66761,37.85433,37.85433,0,0,0-7.94452-1.89325l-9.03735,2.12018a35.226,35.226,0,0,1,16.97925,1.95105c.00256,1.14135-.20685,1.869-.62048,2.0758h-.00031c-.7251.51831-2.48938,0-4.3584-.51831a23.12225,23.12225,0,0,0-5.81116-1.1413H25.476a40.975,40.975,0,0,0-5.81122,1.1413c-1.86639.51831-3.73535,1.03662-4.3584.51831-.41614-.31146-.623-.93445-.7251-2.0758.34528-.1283.69275-.24353,1.03986-.36091l-3.059.71766a5.17674,5.17674,0,0,0,.99164,3.07013,24.41934,24.41934,0,0,0,3.18841,10.33276l2.176-.5105A23.83668,23.83668,0,0,1,15.72509,18.15594Z"
        fill={fill || '#79b61d'}
      />
      <path
        d="M22.39941,11.84644l9.03735-2.12018a36.69464,36.69464,0,0,0-16.95489,1.89325c.20422-3.00775.72516-5.18824,1.6571-6.53632H16.139c1.65961-2.59412,4.97883-3.11243,10.89472-3.11243,5.91333,0,9.23511.51831,10.89471,3.11243A8.27978,8.27978,0,0,1,38.99994,7.9519l2.01678-.47314A10.81229,10.81229,0,0,0,39.696,4.14905C37.30877.31152,32.53686,0,27.03979,0,21.5401,0,16.87256.31152,14.38354,4.04694c-1.34808,1.97107-1.86645,4.87664-1.86645,9.13055,0,.34979.02246.66327.045.97675l3.059-.71766A35.83941,35.83941,0,0,1,22.39941,11.84644Z"
        fill={fill || '#c8d200'}
      />
      <rect x="23.92114" y="4.04645" width="6.22479" height="2.07581" fill={fill || '#c8d200'} />
    </svg>
  );
};

export default ColouredFarmer;
