/* eslint-disable react/prop-types */
import React from 'react';

const Plot = (props) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 128 128"
      enableBackground="new 0 0 128 128"
      xmlSpace="preserve"
      {...props}
    >
      <g>
        <defs>
          <path
            id="SVGID_41_Plot"
            d="M80.1,33.8c-0.1-0.4-0.4-0.8-0.7-1L59.8,22.1c-0.7-0.4-1.5-0.2-1.9,0.4l-37.8,51c-0.2,0.3-0.3,0.8-0.3,1.2
            c0.1,0.4,0.3,0.8,0.7,1L42,89.1c0.2,0.2,0.5,0.2,0.8,0.2c0.5,0,1-0.2,1.2-0.7L79.9,35C80.2,34.7,80.2,34.2,80.1,33.8z M42.3,85.7
            L23.5,74l36-48.6l17,9.3L42.3,85.7z M107.4,48.3L84.1,35.5c-0.7-0.4-1.5-0.2-2,0.5L62.4,65.4c-0.4,0.6-0.3,1.3,0.1,1.8l21.1,23.3
            c0.3,0.3,0.7,0.5,1.1,0.5c0.1,0,0.1,0,0.2,0c0.5-0.1,0.9-0.3,1.1-0.8l22-40C108.4,49.6,108.1,48.7,107.4,48.3z M84.4,87L65.6,66.1
            l18.3-27.4l20.8,11.4L84.4,87z M61.7,69.9c-0.3-0.3-0.8-0.5-1.2-0.5c-0.5,0-0.9,0.3-1.1,0.7L46.1,89.8c-0.2,0.3-0.3,0.8-0.2,1.2
            c0.1,0.4,0.3,0.7,0.7,1L74,109c0.2,0.1,0.5,0.2,0.8,0.2c0.1,0,0.3,0,0.4,0c0.4-0.1,0.7-0.4,0.9-0.7l7.2-13.1
            c0.3-0.6,0.2-1.3-0.2-1.7L61.7,69.9z M74.3,105.6L49.5,90.2l11.3-16.8l19.4,21.5L74.3,105.6z"
          />
        </defs>
        <clipPath id="SVGID_2_Plot">
          <use xlinkHref="#SVGID_41_Plot" overflow="visible" />
        </clipPath>
        <g clipPath="url(#SVGID_2_Plot)">
          <rect
            x="-12.3"
            y="108.5"
            transform="matrix(0.9736 -0.2284 0.2284 0.9736 -26.3448 20.7702)"
            fill="#B95700"
            width="177.8"
            height="31.5"
          />
          <rect
            x="-19.5"
            y="77.8"
            transform="matrix(0.9736 -0.2284 0.2284 0.9736 -19.5284 18.3155)"
            fill="#EB6909"
            width="177.8"
            height="31.5"
          />
          <rect
            x="-26.7"
            y="47.1"
            transform="matrix(0.9736 -0.2284 0.2284 0.9736 -12.7119 15.8609)"
            fill="#5F9501"
            width="177.8"
            height="31.5"
          />
          <rect
            x="-33.9"
            y="16.4"
            transform="matrix(0.9736 -0.2284 0.2284 0.9736 -5.8954 13.4062)"
            fill="#79B61D"
            width="177.8"
            height="31.5"
          />
          <rect
            x="-41.1"
            y="-14.3"
            transform="matrix(0.9736 -0.2284 0.2284 0.9736 0.921 10.9515)"
            fill="#C8D200"
            width="177.8"
            height="31.5"
          />
        </g>
      </g>
    </svg>
  );
};

export default Plot;
