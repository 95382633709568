/* eslint-disable import/prefer-default-export */
/* eslint-disable react/prop-types */
import React from 'react';
import { PropTypes } from 'prop-types';
import { useLocalizedFromContext } from 'data/Localization';

export const Crop35 = ({ title, fill, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="122.22"
    height="176.328"
    viewBox="0 0 122.22 176.328"
    {...props}
  >
    <title>{useLocalizedFromContext(title)}</title>
    <g id="Component_3_1" data-name="Component 3 – 1" transform="translate(2.5 2.5)">
      <g id="Component_2_1" data-name="Component 2 – 1">
        <g id="Group_398" data-name="Group 398" transform="translate(-1844.385 -5819.592)">
          <path
            id="Path_1353"
            data-name="Path 1353"
            d="M1872.942,5954.065a18.647,18.647,0,0,0-7.923,10.064c-2.57,7.281-13.491,16.061-13.491,16.061s-3.64-11.35,0-20.986C1851.528,5959.2,1853.56,5951.418,1872.942,5954.065Z"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
          <path
            id="Path_1354"
            data-name="Path 1354"
            d="M1872.942,5954.065s25.911,1.713,33.834,19.7-9.019,19.315-12.659,15.246c0,0-.6-17.133-27.9-27.626"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
          <path
            id="Path_1355"
            data-name="Path 1355"
            d="M1908.935,5983.5s7.646-21.723,15.676-29.433c12.332-11.841,21.544-10.87,36.994-7.438,0,0-7.183-12.89-26.55-11.149s-31.684,19.092-35.6,28.667"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
          <path
            id="Path_1356"
            data-name="Path 1356"
            d="M1905.389,5937.509s-11.822-16.345-19.6-24.511c-9.43-9.9-24.192-22.522-41.408-17.3,0,0,19.874,8.407,30.175,21.714,6.784,8.766,11.452,15.306,14.834,21.762S1902.248,5945.161,1905.389,5937.509Z"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
          <line
            id="Line_152"
            data-name="Line 152"
            y1="15.949"
            x2="0.121"
            transform="translate(1894.117 5943.712)"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
          <path
            id="Path_1357"
            data-name="Path 1357"
            d="M1905.389,5953.818v-24.695a29.525,29.525,0,0,1,11.521-17.25c12.113-8.477,22.844-14.984,21.539-47.136,0,0,2.827,15.04-14.99,29.742-13.712,11.316-18.381,9.79-20.824,15.231"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
          <line
            id="Line_153"
            data-name="Line 153"
            y1="74.607"
            x2="4.012"
            transform="translate(1895.494 5849.842)"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
          <path
            id="Path_1358"
            data-name="Path 1358"
            d="M1903.14,5907.469s-3.808-46.242-3.633-57.627"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
        <line
          id="Line_371"
          data-name="Line 371"
          y2="30.25"
          transform="translate(55.122)"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
      </g>
      <path
        id="Path_2037"
        data-name="Path 2037"
        d="M1917.554,5842.679c-12.165,5.667-18.047,20.5-18.047,20.5"
        transform="translate(-1844.385 -5819.592)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_2038"
        data-name="Path 2038"
        d="M1923.108,5858.929c-17.719-2.208-23.6,7.5-23.6,7.5"
        transform="translate(-1844.385 -5819.592)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_2039"
        data-name="Path 2039"
        d="M1881.46,5842.679c12.164,5.667,18.047,20.5,18.047,20.5"
        transform="translate(-1844.385 -5819.592)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_2040"
        data-name="Path 2040"
        d="M1875.906,5858.929c17.718-2.208,23.6,7.5,23.6,7.5"
        transform="translate(-1844.385 -5819.592)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
    </g>
  </svg>
);

Crop35.defaultProps = {
  title: 'maissilage',
};

Crop35.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
};
