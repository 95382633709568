/* eslint-disable import/prefer-default-export */
/* eslint-disable react/prop-types */
import React from 'react';

export const GradientCrop = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36.9 96" {...props}>
    <title>Crop</title>
    <path d="M16.3,38.1c.8-4.5-3.4-8.9-9.3-9.7C7.8,34.5,11.6,38.5,16.3,38.1Z" fill="none" />
    <path d="M16.4,55.1c.5-4.7-3.2-8.6-9.4-9.7C6.8,50.5,12,55.9,16.4,55.1Z" fill="none" />
    <path d="M29.9,28.4c-5.4.9-9.6,4.2-9.4,9.6C25.3,38.6,29.1,34.3,29.9,28.4Z" fill="none" />
    <path d="M30,45.4c-5.8.7-10.4,5.5-9.4,9.8C25.3,55.5,29,51.6,30,45.4Z" fill="none" />
    <path
      d="M18.5,22.9a8.5,8.5,0,0,0,2.6-4.8,13,13,0,0,0-2.6-8.9C16.1,12.3,15.2,16,16,19A7.2,7.2,0,0,0,18.5,22.9Z"
      fill="none"
    />
    <path
      d="M8,66.9c1.7,3.3,5.1,5.9,8.4,5.4a8.4,8.4,0,0,0-2.6-6.6,9.9,9.9,0,0,0-6.8-3A10.4,10.4,0,0,0,8,66.9Z"
      fill="none"
    />
    <path d="M29.9,62.6c-5.9,1-9.7,4.7-9.4,9.8C25.3,72.7,29.9,68,29.9,62.6Z" fill="none" />
    <path
      d="M16.8,94.7A1.6,1.6,0,0,0,18.5,96a1.9,1.9,0,0,0,1.9-1.2,3,3,0,0,0,.2-1.3v-6l-4,.8v5A3.2,3.2,0,0,0,16.8,94.7Z"
      fill="#b95700"
    />
    <path
      d="M20.6,77.5v-1h.6a11.4,11.4,0,0,0,8.9-4.3,16.8,16.8,0,0,0,3.7-8,10.2,10.2,0,0,1,.6-2.5L18.5,64.8h-.1l-4.6.9a8.4,8.4,0,0,1,2.6,6.6c-3.3.5-6.7-2.1-8.4-5.4l-3.8.7a11.7,11.7,0,0,0,.5,1.1,11.8,11.8,0,0,0,8.7,7.5l3.2.4V88.3l4-.8Zm9.3-14.9c0,5.4-4.6,10.1-9.4,9.8C20.2,67.3,24,63.6,29.9,62.6Z"
      fill="#eb6909"
    />
    <path
      d="M7,62.7a9.9,9.9,0,0,1,6.8,3l4.6-.9c-2.6-3.6-6.3-5.4-10.6-6.2a6.5,6.5,0,0,1-4.3-2.4,1.9,1.9,0,0,0-2.8-.1A2,2,0,0,0,.6,59l1.1,1.1a3.8,3.8,0,0,1,1.1,2.3,50.1,50.1,0,0,0,1.4,5.2L8,66.9A10.4,10.4,0,0,1,7,62.7Z"
      fill="#5f9501"
    />
    <path
      d="M36.4,58.9a1.9,1.9,0,0,0-.2-2.8,1.8,1.8,0,0,0-2.7.1,8,8,0,0,0-1.3,1.3,2.7,2.7,0,0,1-1.7.8,18.6,18.6,0,0,0-10,4.4c-.7.6-1.3,1.4-2,2.1l15.9-3.1A6.6,6.6,0,0,1,36.4,58.9Z"
      fill="#5f9501"
    />
    <path
      d="M5.2,52.6c2.2,4,5.4,6.4,10.1,6.7q3.1.2,6.3,0a11.7,11.7,0,0,0,9.1-5.1,15.9,15.9,0,0,0,3.1-7.3c.3-2,.8-3.9,2.6-5.2l.3-.4a2,2,0,0,0-.3-2.2,1.7,1.7,0,0,0-1-.7l-1.6.3-.5.4a6.1,6.1,0,0,1-4.2,2.3c-4.2.8-8,2.6-10.6,6.4a15.1,15.1,0,0,0-6-5l-9.8,2c0,.1.1.2.1.3A20,20,0,0,0,5.2,52.6ZM30,45.4c-1,6.2-4.7,10.1-9.4,9.8C19.6,50.9,24.2,46.1,30,45.4ZM16.4,55.1c-4.4.8-9.6-4.6-9.4-9.7C13.2,46.5,16.9,50.4,16.4,55.1Z"
      fill="#5f9501"
    />
    <path d="M16,42.3h3.6a12.2,12.2,0,0,0,8.6-2.4L15.7,42.2Z" fill="#5f9501" />
    <path
      d="M18.5,22.9A7.2,7.2,0,0,1,16,19l-3.9.8A14.3,14.3,0,0,0,17,27.2c.9.6,1.8.8,2.5.2a12.9,12.9,0,0,0,5.7-10.1h-.1l-4,.8A8.5,8.5,0,0,1,18.5,22.9Z"
      fill="#79b61d"
    />
    <path
      d="M1.7,25.9a3.3,3.3,0,0,1,1.1,2.2,41.1,41.1,0,0,0,2.1,6.8,11.8,11.8,0,0,0,10.8,7.3l12.5-2.4a15.5,15.5,0,0,0,2.5-2.6A18.2,18.2,0,0,0,34,28.6a4.3,4.3,0,0,1,1.6-3.1c1.1-.8,1.3-1.9,1.3-3.3V15l-3.9.7v5.6a2.8,2.8,0,0,1-2.5,2.8c-4.8.7-9,2.4-12,6.5a28.9,28.9,0,0,0-2.4-2.5A19.5,19.5,0,0,0,6.3,24,2.5,2.5,0,0,1,4,21.5H4l-4,.8A4.2,4.2,0,0,0,1.7,25.9Zm28.2,2.5c-.8,5.9-4.6,10.2-9.4,9.6C20.3,32.6,24.5,29.3,29.9,28.4ZM16.3,38.1c-4.7.4-8.5-3.6-9.3-9.7C12.9,29.2,17.1,33.6,16.3,38.1Z"
      fill="#79b61d"
    />
    <path d="M34.4,38.4l-.6.3,1.6-.3A1.7,1.7,0,0,0,34.4,38.4Z" fill="#79b61d" />
    <path
      d="M8.1,41.5A7.8,7.8,0,0,1,3.4,39a1.8,1.8,0,0,0-2.7-.2,2.1,2.1,0,0,0-.2,2.9L1.7,43a2.7,2.7,0,0,1,1,1.8l9.8-2A18.9,18.9,0,0,0,8.1,41.5Z"
      fill="#79b61d"
    />
    <path
      d="M20.4,3.4V2.1A1.9,1.9,0,0,0,18.5,0a1.9,1.9,0,0,0-2,2,6.1,6.1,0,0,0,0,1.9,3,3,0,0,1-.6,1.8,21.3,21.3,0,0,0-3.4,6.5,13.3,13.3,0,0,0-.4,7.6L16,19c-.8-3,.1-6.7,2.5-9.8a13,13,0,0,1,2.6,8.9l4-.8A17.5,17.5,0,0,0,21.3,6.1,3.7,3.7,0,0,1,20.4,3.4Z"
      fill="#c8d200"
    />
    <path
      d="M36.9,2.7a5.7,5.7,0,0,0-.1-1.3A1.8,1.8,0,0,0,35,0a1.9,1.9,0,0,0-1.9,1.2A2.5,2.5,0,0,0,33,2.4V15.7l3.9-.7Z"
      fill="#c8d200"
    />
    <path
      d="M4,6.2V2.1A2,2,0,0,0,1.8,0,2,2,0,0,0,0,2.2C0,8.8.1,15.3,0,21.9v.3l4-.8Z"
      fill="#c8d200"
    />
  </svg>
);

export default GradientCrop;
