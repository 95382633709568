/* eslint-disable react/prop-types */
import React from 'react';

const Calendar = (props) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 42 42"
      enableBackground="new 0 0 42 42"
      {...props}
    >
      <path
        fill="#79B61D"
        d="M34.3,3.3H32V1.5C32,0.7,31.3,0,30.5,0S29,0.7,29,1.5v1.8H12.7V1.5C12.7,0.7,12,0,11.2,0S9.7,0.7,9.7,1.5
        v1.8h-2C3.5,3.3,0,6.7,0,10.9v23.5C0,38.6,3.5,42,7.7,42h26.7c4.2,0,7.6-3.4,7.6-7.6V10.9C42,6.7,38.5,3.3,34.3,3.3z M7.7,6.3h2V9
        c0,0.8,0.7,1.5,1.5,1.5s1.5-0.7,1.5-1.5V6.3H29V9c0,0.8,0.7,1.5,1.5,1.5S32,9.8,32,9V6.3h2.4c2.5,0,4.6,2.1,4.6,4.6v2H3v-2
        C3,8.4,5.1,6.3,7.7,6.3z M34.3,39H7.7C5.1,39,3,37,3,34.4V15.9H39v18.5C39,37,36.9,39,34.3,39z"
      />
    </svg>
  );
};

export default Calendar;
