/* eslint-disable import/prefer-default-export */
/* eslint-disable react/prop-types */
import React from 'react';
import { PropTypes } from 'prop-types';
import { useLocalizedFromContext } from 'data/Localization';

export const GradientCrop46 = ({ title, ...props }) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 70 70"
    enableBackground="new 0 0 70 70"
    {...props}
  >
    <title>{useLocalizedFromContext(title)}</title>
    <g>
      <clipPath id="SVGID_2_">
        <path
          id="SVGID_75_"
          d="M56.8,36.4c-0.4-0.6-1.1-0.9-1.7-0.7c-3.1,1-5.2,2.9-6.8,4.8c0.4-10.6,2.4-15.5,2.4-15.6
          c0.2-0.6,0.1-1.3-0.4-1.7c-0.5-0.4-1.2-0.5-1.8-0.1c-1.5,1-2.8,2.2-3.8,3.5c1.3-5.4,2.6-7.2,2.6-7.3c0.4-0.5,0.4-1.3,0-1.9
          c-0.4-0.5-1.2-0.7-1.8-0.4c-2.6,1.3-4.7,3.4-6.2,5.8c1.3-7.7,2.8-12.9,2.8-13c0.2-0.7-0.1-1.4-0.7-1.7c-0.6-0.3-1.3-0.2-1.8,0.2
          c-3.6,3.7-6.2,8.7-7.9,13.9c-1.8-7.8-5.2-9.9-5.7-10.2c-0.5-0.3-1.1-0.3-1.6,0.1s-0.7,0.9-0.6,1.5c0.5,3.1,0.5,8.5,0.4,12.6
          c-1-1.5-2.3-2.8-3.7-3.9c-0.5-0.4-1.3-0.4-1.8,0c-0.5,0.4-0.7,1.2-0.4,1.8c0,0.1,2,4.5,2.7,17c-2.1-2.1-4.3-3.2-4.5-3.3
          c-0.6-0.3-1.2-0.2-1.7,0.3c-0.4,0.4-0.6,1.1-0.3,1.7c2.1,5,1.8,12.2,1.8,12.3c0,0,0,0,0,0c0,0,0,0,0,0v4.7c0,0.8,0.7,1.5,1.5,1.5
          h34.7c0.8,0,1.5-0.7,1.5-1.5v-4.6c0,0,0-0.1,0-0.1c0-10.9,2.7-14,2.7-14C57.1,37.8,57.2,37,56.8,36.4z M52.2,40.8
          c-0.7,2.2-1.2,5.4-1.3,9.9h-1.5h-0.9c-0.1-1.4-0.1-2.8-0.1-4.1C48.5,45.8,49.7,43,52.2,40.8z M46.5,29.1
          c-0.8,4.3-1.6,11.4-1.1,21.6h-2.9c0-6.1,0.4-11.1,0.8-15.1C43.5,34.8,44.5,31.9,46.5,29.1z M42.4,23.5c-0.8,2.7-1.6,6.4-2.1,11.5
          c0,0.1,0,0.1,0,0.2c-0.5,4.2-0.8,9.3-0.8,15.5h-2.4v-5.1c0-1.6,0-3.3,0.1-4.9C37.4,37.2,38.3,28.9,42.4,23.5z M34.2,39.9
          c-0.1,1.3-0.1,2.2-0.1,2.3c0,0,0,0,0,0c0,1.1-0.1,2.2-0.1,3.3v5.1h-3.2c-0.1-5.1,0.1-22.5,6.5-34.3C36.2,22.2,34.7,30.8,34.2,39.9
          z M27.1,18.3c1.1,2.3,2.2,5.8,2.6,11.3c0,0,0,0.1,0,0.1c-0.5,2.6-0.9,5.1-1.2,7.5c-0.4-1.9-1-3.8-1.7-5.6
          C27,30.2,27.3,23.7,27.1,18.3z M23.9,32.4C23.9,32.4,24,32.4,23.9,32.4c2.7,6.8,3,15,3,18.3h-2.8c-0.1-10.1-0.8-16.9-1.6-21.2
          C23.1,30.4,23.5,31.3,23.9,32.4z M21.1,47.7c0,0.9,0,1.9,0,2.9h-2c0-1.7-0.1-4.7-0.8-7.7C19.6,44.1,20.8,45.8,21.1,47.7z
          M50.8,55.4H19.1v-1.7h11.4h5.1h5h0.3h6h2.4h1.5V55.4z"
        />
      </clipPath>
      <g clipPath="url(#SVGID_2_)">
        <rect
          x="5"
          y="49.4"
          transform="matrix(0.9736 -0.2284 0.2284 0.9736 -12.498 10.9561)"
          fill="#B95700"
          width="72.1"
          height="20.2"
        />
        <rect
          x="1.6"
          y="39.9"
          transform="matrix(0.9736 -0.2284 0.2284 0.9736 -9.2479 9.7857)"
          fill="#EB6909"
          width="72.1"
          height="9.9"
        />
        <rect
          x="-0.7"
          y="30.3"
          transform="matrix(0.9736 -0.2284 0.2284 0.9736 -7.1101 9.0159)"
          fill="#5F9501"
          width="72.1"
          height="9.9"
        />
        <rect
          x="-2.9"
          y="20.7"
          transform="matrix(0.9736 -0.2284 0.2284 0.9736 -4.9722 8.246)"
          fill="#79B61D"
          width="72.1"
          height="9.9"
        />
        <rect
          x="-6.4"
          y="0.9"
          transform="matrix(0.9736 -0.2284 0.2284 0.9736 -1.7209 7.0752)"
          fill="#C8D200"
          width="72.1"
          height="20.2"
        />
      </g>
    </g>
  </svg>
);

GradientCrop46.defaultProps = {
  title: 'foddergrass',
};

GradientCrop46.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
};
