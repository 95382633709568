/* eslint-disable react/prop-types */
import React from 'react';

const Gear = ({ disabled, ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 54 54" {...props}>
    <path
      d="M51.2 21h-5.1c-.8 0-1.5-.4-1.8-1.2s-.2-1.5.4-2.1l3.6-3.6c.5-.5.8-1.2.8-2 0-.7-.3-1.4-.8-2l-4.6-4.6c-1-1-2.9-1.1-3.9 0l-3.6 3.6c-.5.5-1 .6-1.4.6-.5 0-1-.2-1.4-.5-.1-.2-.4-.7-.4-1.4v-5C33 1.2 31.8 0 30.2 0h-6.4C22.2 0 21 1.2 21 2.8v5.1c0 1.2-1 1.9-2 1.9-.4 0-.9-.1-1.4-.6L14 5.6c-1.1-1.1-2.9-1.1-3.9 0l-4.6 4.6c-.5.5-.8 1.2-.8 2 0 .7.3 1.4.8 2l3.6 3.6c.6.6.7 1.4.4 2.1S8.6 21 7.8 21h-5C1.2 21 0 22.2 0 23.8v6.4C0 31.8 1.2 33 2.8 33h5.1c.8 0 1.5.4 1.8 1.2s.2 1.5-.4 2.1l-3.6 3.6c-.5.5-.8 1.2-.8 2 0 .7.3 1.4.8 2l4.6 4.6c1.1 1.1 2.9 1.1 3.9 0l3.6-3.6c.5-.5 1-.6 1.4-.6 1 0 2 .7 2 1.9v5.1c0 1.5 1.2 2.8 2.8 2.8h6.4c1.5 0 2.8-1.2 2.8-2.8v-5.1c0-1.2 1-1.9 2-1.9.4 0 .9.1 1.4.6l3.6 3.6c1.1 1.1 2.9 1 3.9 0l4.6-4.6c.5-.5.8-1.2.8-2 0-.7-.3-1.4-.8-2l-3.6-3.6c-.6-.6-.7-1.4-.4-2.1s1-1.2 1.8-1.2h5.1c1.5 0 2.8-1.2 2.8-2.8v-6.4c-.4-1.6-1.6-2.8-3.2-2.8zM34 27c0 3.9-3.1 7-7 7s-7-3.1-7-7 3.1-7 7-7 7 3.1 7 7z"
      fill={disabled ? 'rgba(131, 156, 169, .5)' : '#839ca9'}
    />
  </svg>
);

export default Gear;
