/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';

const Printer = ({ stroke = '#839ca9', ...props }) => {
  Printer.propTypes = {
    stroke: PropTypes.string,
  };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 26.25 26.68622"
      {...props}
      height="15"
      width="15"
    >
      <title>Printer icon</title>
      <polyline
        points="6.444 9.145 6.444 0.875 19.806 0.875 19.806 9.145"
        fill="none"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.75"
      />
      <rect
        x="6.44427"
        y="18.61061"
        width="13.36146"
        height="7.20061"
        fill="none"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.75"
      />
      <path
        d="M6.44427,22.50671H.875V11.11122a1.966,1.966,0,0,1,1.966-1.954H23.409a1.966,1.966,0,0,1,1.966,1.954V22.50671H19.80573"
        fill="none"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.75"
      />
      <line
        x1="4.7711"
        y1="13.59707"
        x2="7"
        y2="13.59707"
        fill="none"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.75"
      />
    </svg>
  );
};

export default Printer;
