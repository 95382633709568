import React, { useCallback, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { Route, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { countryBehaviour } from 'data/Behaviour';
import { getUserToCheckNZLoggedInState } from 'Redux/actions';

const listOfPathsWhereStepRouteShouldNotRender = ['/welcome'];

const NZStepRoute = ({
  user,
  component,
  terrazoFields: { data: terrazoFieldsData },
  location: { pathname },
  getUserToCheckNZLoggedInState,
  ...props
}) => {
  const handleGetUser = useCallback(() => {
    if (
      document.visibilityState === 'visible' &&
      terrazoFieldsData &&
      terrazoFieldsData.length > 0
    ) {
      getUserToCheckNZLoggedInState(user.id_token);
    }
  }, [getUserToCheckNZLoggedInState, terrazoFieldsData, user.id_token]);

  useEffect(() => {
    document.addEventListener('visibilitychange', handleGetUser);
    return () => {
      document.removeEventListener('visibilitychange', handleGetUser);
    };
  }, [handleGetUser]);

  useEffect(() => {
    if (
      !countryBehaviour(user.country, 'application_map_functionality') ||
      !terrazoFieldsData ||
      (terrazoFieldsData && terrazoFieldsData.length === 0) ||
      !user.id_token ||
      user.usedMobileApp === true
    ) {
      return;
    }
    getUserToCheckNZLoggedInState(user.id_token);
  }, [
    user.usedMobileApp,
    getUserToCheckNZLoggedInState,
    user.id_token,
    user.country,
    terrazoFieldsData,
  ]);

  if (user.usedMobileApp === true) {
    return null;
  }
  if (
    !countryBehaviour(user.country, 'application_map_functionality') ||
    listOfPathsWhereStepRouteShouldNotRender.includes(pathname)
  ) {
    return null;
  }
  return <Route component={component} {...props} />;
};

NZStepRoute.propTypes = {
  component: PropTypes.any,
  user: PropTypes.object,
  location: PropTypes.object,
  getUserToCheckNZLoggedInState: PropTypes.func,
  terrazoFields: PropTypes.object,
};

export default withRouter(
  connect(
    ({ user, terrazoFields }) => ({
      user,
      terrazoFields,
    }),
    {
      getUserToCheckNZLoggedInState,
    },
  )(NZStepRoute),
);
