/* eslint-disable import/prefer-default-export */
/* eslint-disable react/prop-types */
import React from 'react';
import { PropTypes } from 'prop-types';
import { useLocalizedFromContext } from 'data/Localization';

export const Crop24 = ({ title, fill, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="148.372"
    height="158.119"
    viewBox="0 0 148.372 158.119"
    {...props}
  >
    <title>{useLocalizedFromContext(title)}</title>
    <g id="Component_9_1" dataname="Component 9 – 1" transform="translate(2.5 2.5)">
      <path
        id="Path_1421"
        dataname="Path 1421"
        d="M2595.906,7202.294s-3.3-87.549-58.312-108.119c0,0,24.457,18.237,33.849,108.119Z"
        transform="translate(-2531.924 -7049.175)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <g id="Group_482" dataname="Group 482" transform="translate(-2531.924 -7049.175)">
        <g id="Group_479" dataname="Group 479">
          <path
            id="Path_1422"
            dataname="Path 1422"
            d="M2600.564,7056.773a7.6,7.6,0,0,0-15.2,0s-.34,6.835,8.532,15.418c3.2,3.092,6.665,6.544,6.665,6.544"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
          <path
            id="Path_1423"
            dataname="Path 1423"
            d="M2600.564,7077.693v-20.92a7.6,7.6,0,0,1,15.2,0s.339,6.835-8.532,15.418c-3.2,3.092-6.665,6.544-6.665,6.544"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
        <g id="Group_480" dataname="Group 480">
          <path
            id="Path_1424"
            dataname="Path 1424"
            d="M2621.348,7086.148a7.6,7.6,0,0,1-4.93,14.375s-6.575-1.9-11.815-13.073c-1.889-4.027-4.029-8.428-4.029-8.428"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
          <path
            id="Path_1425"
            dataname="Path 1425"
            d="M2601.56,7079.36l19.788,6.788a7.6,7.6,0,0,0,4.931-14.375s-6.354-2.538-17.351,3.068c-3.963,2.021-8.354,4.181-8.354,4.181"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
        <g id="Group_481" dataname="Group 481">
          <path
            id="Path_1426"
            dataname="Path 1426"
            d="M2579.79,7086.148a7.6,7.6,0,0,0,4.931,14.375s6.575-1.9,11.815-13.073c1.888-4.027,4.028-8.428,4.028-8.428"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
          <path
            id="Path_1427"
            dataname="Path 1427"
            d="M2599.578,7079.36l-19.788,6.788a7.6,7.6,0,0,1-4.931-14.375s6.355-2.538,17.352,3.068c3.962,2.021,8.353,4.181,8.353,4.181"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
        <line
          id="Line_319"
          dataname="Line 319"
          y2="54.623"
          transform="translate(2600.564 7079.243)"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
      </g>
      <path
        id="Path_1428"
        dataname="Path 1428"
        d="M2571.443,7202.294H2549.8s-1.449-48.09-17.876-69.273c0,0,28.175,15.277,34.621,34.636"
        transform="translate(-2531.924 -7049.175)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_1429"
        dataname="Path 1429"
        d="M2635.778,7202.294h21.643s1.449-48.09,17.875-69.273c0,0-28.174,15.277-34.62,34.636"
        transform="translate(-2531.924 -7049.175)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_1430"
        dataname="Path 1430"
        d="M2583.745,7145.637s1.871-22.587-1.208-34.314c0,0,15.939,7.052,17.045,28.13"
        transform="translate(-2531.924 -7049.175)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_1431"
        dataname="Path 1431"
        d="M2590.159,7165.756s8.572-46.294,43.222-58.881c0,0-21.59,54.9-16.651,95.419h-20.824"
        transform="translate(-2531.924 -7049.175)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_1432"
        dataname="Path 1432"
        d="M2627.968,7122.606s17.655-19.52,33.261-25.242c0,0-24.591,46.861-24.591,104.93H2616.73"
        transform="translate(-2531.924 -7049.175)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
    </g>
  </svg>
);

Crop24.defaultProps = {
  title: 'clover_grass_min60',
};

Crop24.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
};
