/* eslint-disable import/prefer-default-export */
/* eslint-disable react/prop-types */
import React from 'react';

export const CropLp3 = ({ title, fill, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17.39"
    height="66.97"
    viewBox="0 0 17.39 66.97"
    {...props}
  >
    <g id="Group_1622" dataname="Group 1622" transform="translate(1.387 1.961)">
      <path
        id="Path_2637"
        dataname="Path 2637"
        d="M304.712,4061.515a3.339,3.339,0,0,1-4.722-4.721c1.3-1.3,6.331-1.609,6.331-1.609S306.015,4060.212,304.712,4061.515Z"
        transform="translate(-291.704 -4010.756)"
        fill="none"
        stroke="#839ca9"
        strokeMiterlimit="10"
        strokeWidth="2.6"
      />
      <path
        id="Path_2638"
        dataname="Path 2638"
        d="M291.948,4050.876a22.032,22.032,0,0,1,2.989-4.317,22.025,22.025,0,0,1,2.99,4.317"
        transform="translate(-287.629 -4005.779)"
        fill="none"
        stroke="#839ca9"
        strokeMiterlimit="10"
        strokeWidth="2.6"
      />
      <path
        id="Path_2639"
        dataname="Path 2639"
        d="M283.35,4061.515a3.339,3.339,0,0,0,4.722-4.721c-1.3-1.3-6.331-1.609-6.331-1.609S282.047,4060.212,283.35,4061.515Z"
        transform="translate(-281.741 -4010.756)"
        fill="none"
        stroke="#839ca9"
        strokeMiterlimit="10"
        strokeWidth="2.6"
      />
      <path
        id="Path_2640"
        dataname="Path 2640"
        d="M299.2,4042.343a3.323,3.323,0,0,1,.9-1.64c1.3-1.3,6.331-1.609,6.331-1.609s-.305,5.027-1.609,6.33a3.325,3.325,0,0,1-1.37.828"
        transform="translate(-291.811 -4001.473)"
        fill="none"
        stroke="#839ca9"
        strokeMiterlimit="10"
        strokeWidth="2.6"
      />
      <path
        id="Path_2641"
        dataname="Path 2641"
        d="M291.948,4034.784a22.052,22.052,0,0,1,2.989-4.317,22.045,22.045,0,0,1,2.99,4.317"
        transform="translate(-287.629 -3996.497)"
        fill="none"
        stroke="#839ca9"
        strokeMiterlimit="10"
        strokeWidth="2.6"
      />
      <path
        id="Path_2642"
        dataname="Path 2642"
        d="M288.985,4042.411a3.322,3.322,0,0,0-.914-1.708c-1.3-1.3-6.331-1.609-6.331-1.609s.306,5.027,1.609,6.33a3.324,3.324,0,0,0,1.782.928"
        transform="translate(-281.741 -4001.473)"
        fill="none"
        stroke="#839ca9"
        strokeMiterlimit="10"
        strokeWidth="2.6"
      />
      <path
        id="Path_2643"
        dataname="Path 2643"
        d="M299.2,4026.251a3.32,3.32,0,0,1,.9-1.639c1.3-1.3,6.331-1.61,6.331-1.61s-.305,5.027-1.609,6.331a3.322,3.322,0,0,1-1.37.828"
        transform="translate(-291.811 -3992.19)"
        fill="none"
        stroke="#839ca9"
        strokeMiterlimit="10"
        strokeWidth="2.6"
      />
      <path
        id="Path_2644"
        dataname="Path 2644"
        d="M291.948,4018.694a22.053,22.053,0,0,1,2.989-4.318,22.038,22.038,0,0,1,2.99,4.318"
        transform="translate(-287.629 -3987.214)"
        fill="none"
        stroke="#839ca9"
        strokeMiterlimit="10"
        strokeWidth="2.6"
      />
      <path
        id="Path_2645"
        dataname="Path 2645"
        d="M288.985,4026.319a3.32,3.32,0,0,0-.914-1.708c-1.3-1.3-6.331-1.61-6.331-1.61s.306,5.027,1.609,6.331a3.323,3.323,0,0,0,1.782.927"
        transform="translate(-281.741 -3992.19)"
        fill="none"
        stroke="#839ca9"
        strokeMiterlimit="10"
        strokeWidth="2.6"
      />
      <path
        id="Path_2646"
        dataname="Path 2646"
        d="M299.2,4010.16a3.321,3.321,0,0,1,.9-1.64c1.3-1.3,6.331-1.609,6.331-1.609s-.305,5.026-1.609,6.33a3.323,3.323,0,0,1-1.37.828"
        transform="translate(-291.811 -3982.908)"
        fill="none"
        stroke="#839ca9"
        strokeMiterlimit="10"
        strokeWidth="2.6"
      />
      <path
        id="Path_2647"
        dataname="Path 2647"
        d="M291.948,4002.6a22.028,22.028,0,0,1,2.989-4.318,22.025,22.025,0,0,1,2.99,4.317"
        transform="translate(-287.629 -3977.933)"
        fill="none"
        stroke="#839ca9"
        strokeMiterlimit="10"
        strokeWidth="2.6"
      />
      <path
        id="Path_2648"
        dataname="Path 2648"
        d="M288.985,4010.228a3.324,3.324,0,0,0-.914-1.708c-1.3-1.3-6.331-1.609-6.331-1.609s.306,5.026,1.609,6.33a3.319,3.319,0,0,0,1.782.928"
        transform="translate(-281.741 -3982.908)"
        fill="none"
        stroke="#839ca9"
        strokeMiterlimit="10"
        strokeWidth="2.6"
      />
      <path
        id="Path_2649"
        dataname="Path 2649"
        d="M299.2,3994.069a3.324,3.324,0,0,1,.9-1.64c1.3-1.3,6.331-1.609,6.331-1.609s-.305,5.026-1.609,6.33a3.323,3.323,0,0,1-1.37.829"
        transform="translate(-291.811 -3973.626)"
        fill="none"
        stroke="#839ca9"
        strokeMiterlimit="10"
        strokeWidth="2.6"
      />
      <path
        id="Path_2650"
        dataname="Path 2650"
        d="M291.948,3986.511a22.04,22.04,0,0,1,2.989-4.317,22.033,22.033,0,0,1,2.99,4.317"
        transform="translate(-287.629 -3968.65)"
        fill="none"
        stroke="#839ca9"
        strokeMiterlimit="10"
        strokeWidth="2.6"
      />
      <path
        id="Path_2651"
        dataname="Path 2651"
        d="M288.985,3994.137a3.325,3.325,0,0,0-.914-1.708c-1.3-1.3-6.331-1.609-6.331-1.609s.306,5.026,1.609,6.33a3.316,3.316,0,0,0,1.782.927"
        transform="translate(-281.741 -3973.626)"
        fill="none"
        stroke="#839ca9"
        strokeMiterlimit="10"
        strokeWidth="2.6"
      />
      <path
        id="Path_2652"
        dataname="Path 2652"
        d="M299.2,3978.066a3.322,3.322,0,0,1,.9-1.64c1.3-1.3,6.331-1.609,6.331-1.609s-.305,5.027-1.609,6.33a3.318,3.318,0,0,1-1.37.828"
        transform="translate(-291.811 -3964.395)"
        fill="none"
        stroke="#839ca9"
        strokeMiterlimit="10"
        strokeWidth="2.6"
      />
      <path
        id="Path_2653"
        dataname="Path 2653"
        d="M291.948,3970.508a22.045,22.045,0,0,1,2.989-4.318,22.038,22.038,0,0,1,2.99,4.318"
        transform="translate(-287.629 -3959.419)"
        fill="none"
        stroke="#839ca9"
        strokeMiterlimit="10"
        strokeWidth="2.6"
      />
      <path
        id="Path_2654"
        dataname="Path 2654"
        d="M288.985,3978.134a3.324,3.324,0,0,0-.914-1.708c-1.3-1.3-6.331-1.609-6.331-1.609s.306,5.027,1.609,6.33a3.32,3.32,0,0,0,1.782.928"
        transform="translate(-281.741 -3964.395)"
        fill="none"
        stroke="#839ca9"
        strokeMiterlimit="10"
        strokeWidth="2.6"
      />
      <path
        id="Path_2655"
        dataname="Path 2655"
        d="M299.2,3962.063a3.324,3.324,0,0,1,.9-1.64c1.3-1.3,6.331-1.609,6.331-1.609s-.305,5.026-1.609,6.33a3.327,3.327,0,0,1-1.37.828"
        transform="translate(-291.811 -3955.164)"
        fill="none"
        stroke="#839ca9"
        strokeMiterlimit="10"
        strokeWidth="2.6"
      />
      <path
        id="Path_2656"
        dataname="Path 2656"
        d="M291.948,3954.5a22.058,22.058,0,0,1,2.989-4.318,22.051,22.051,0,0,1,2.99,4.318"
        transform="translate(-287.629 -3950.187)"
        fill="none"
        stroke="#839ca9"
        strokeMiterlimit="10"
        strokeWidth="2.6"
      />
      <path
        id="Path_2657"
        dataname="Path 2657"
        d="M288.985,3962.131a3.322,3.322,0,0,0-.914-1.708c-1.3-1.3-6.331-1.609-6.331-1.609s.306,5.026,1.609,6.33a3.322,3.322,0,0,0,1.782.927"
        transform="translate(-281.741 -3955.164)"
        fill="none"
        stroke="#839ca9"
        strokeMiterlimit="10"
        strokeWidth="2.6"
      />
      <line
        id="Line_523"
        dataname="Line 523"
        y2="15.625"
        transform="translate(7.244 48.084)"
        fill="none"
        stroke="#839ca9"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="2.6"
      />
    </g>
  </svg>
);
