/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import Helmet from 'react-helmet';
import JssProvider from 'react-jss/lib/JssProvider';
import CssBaseline from '@material-ui/core/CssBaseline';
import { jssPreset, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
// eslint-disable-next-line import/no-extraneous-dependencies
import { create } from 'jss';
import CircularProgress from '@material-ui/core/CircularProgress';

import MuiTheme from 'data/muiTheme.json';

import TermsAndConditionsPopOver from 'containers/TermsAndConditionsPopOver/TermsAndConditionsPopOver';
import Routes from 'Routes';
import { updateUser, toggleNavigation } from 'Redux/actions';
import { initUserAgentApplication, UserAgentApplication } from 'Utils/MSAL_AuthUtils';
import userUtils from 'Utils/UserUtils';
import { store } from 'Redux/createStore';
import { getGTMBody, getGTMHead } from 'data/Config';
import { UserContext } from 'context';
import 'isomorphic-fetch'; // To make fetch work on IE

// Polyfill
require('core-js');

class App extends Component {
  static propTypes = {
    user: PropTypes.object,
    UI: PropTypes.object,
    toggleNavigation: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {};

    this.closeNavigationBar = this.closeNavigationBar.bind(this);

    this.state.wait = false;

    store.dispatch(updateUser({ loggingOut: false, authenticating: false }));

    if (!UserAgentApplication) {
      initUserAgentApplication(props.user ? props.user.lang : null);
    }
    // eslint-disable-next-line no-underscore-dangle
    if (UserAgentApplication._cacheStorage.getItem('msal.urlHash')) {
      this.state.wait = true;
    }

    const GTM_SCRIPT_HEAD = document.createRange().createContextualFragment(getGTMHead());
    document.head.appendChild(GTM_SCRIPT_HEAD);

    const GTM_SCRIPT_BODY = document.createRange().createContextualFragment(getGTMBody());
    document.body.insertBefore(GTM_SCRIPT_BODY, document.body.firstChild);
  }

  closeNavigationBar() {
    const {
      UI: { isNavOpen },
      toggleNavigation,
    } = this.props;
    if (isNavOpen) {
      toggleNavigation(false);
    }
  }

  render() {
    const { user, UI } = this.props;
    const { lang, country, role, locale } = user;
    const { wait } = this.state;
    const jss = create(jssPreset());
    // We define a custom insertion point that JSS will look for injecting the styles in the DOM.
    jss.options.insertionPoint = document.getElementById('jss-insertion-point');
    const theme = createMuiTheme(MuiTheme);
    return (
      <JssProvider jss={jss}>
        <BrowserRouter>
          <MuiThemeProvider theme={theme}>
            <UserContext.Provider
              value={{
                language: lang,
                country,
                role,
                locale,
              }}
            >
              <Helmet htmlAttributes={{ lang: (lang || '').toLowerCase() }} />
              <CssBaseline />
              {!user.authenticating && !UI.blocked && !wait && !user.loggingOut ? (
                <>
                  {userUtils.shouldRenewTerms(user) ? (
                    <TermsAndConditionsPopOver lang={lang} country={country} />
                  ) : null}
                  <Routes closeNavigationBar={this.closeNavigationBar} />
                </>
              ) : (
                <div className="app-busy-placeholder app-busy-placeholder--white">
                  <CircularProgress className="form__spinner" color="secondary" />
                </div>
              )}
            </UserContext.Provider>
          </MuiThemeProvider>
        </BrowserRouter>
      </JssProvider>
    );
  }
}

const mapStateToProps = (state) => {
  const { user, UI, myPlans } = state;
  const myPlansStatus = myPlans.status;
  return {
    user,
    UI,
    myPlansStatus,
  };
};

export default connect(mapStateToProps, { updateUser, toggleNavigation })(App);
