/* eslint-disable import/prefer-default-export */
/* eslint-disable react/prop-types */
import React from 'react';
import { PropTypes } from 'prop-types';
import { useLocalizedFromContext } from 'data/Localization';

export const Crop10 = ({ title, fill, ...props }) => (
  <svg
    id="prefix__Layer_1"
    x={0}
    y={0}
    width="53"
    height="78"
    viewBox="0 0 37.7 55.3"
    xmlSpace="preserve"
    {...props}
  >
    <title>{useLocalizedFromContext(title)}</title>
    <style>{`.prefix__st0{fill:${fill || '#71bf44'}}`}</style>
    <path
      className="prefix__st0"
      d="M6.2 17.7c-.3 0-.5-.1-.7-.4-.2-.4-.1-.9.3-1.1.5-.3.5-.9.5-1.1 0-.7-.4-1.7-1.5-2.2-.4-.2-.8-.4-1.1-.7.1.9.2 1.9 0 2.8-.1.4-.5.7-.9.6-.4-.1-.7-.5-.6-.9.4-2.3-.6-4.6-1.3-5.8-.4-.6-.6-1-.7-1-.3-.4-.2-.9.1-1.1.4-.2.9-.2 1.1.2 0 .1.3.5.7 1.1.9 1.2 2.2 2.9 3.3 3.4 1.4.7 2.3 2.1 2.4 3.5 0 1.1-.4 2.1-1.3 2.6-.1.1-.2.1-.3.1z"
    />
    <path
      className="prefix__st0"
      d="M6.2 22c-.3 0-.5-.1-.7-.4-.2-.4-.1-.9.3-1.1.5-.3.5-.9.5-1.1 0-.7-.4-1.7-1.5-2.2-.4-.2-.8-.4-1.2-.7.1.9.2 1.9 0 2.8-.1.4-.5.7-.9.6-.4-.1-.7-.5-.6-.9.4-2.3-.6-4.6-1.3-5.8-.4-.6-.6-1-.7-1-.2-.4-.1-.8.2-1.1.3-.2.8-.2 1.1.2 0 .1.3.5.7 1.1.8 1.2 2.2 2.9 3.3 3.4 1.4.7 2.3 2.1 2.4 3.5 0 1.1-.4 2.1-1.3 2.6-.1.1-.2.1-.3.1z"
    />
    <path
      className="prefix__st0"
      d="M6.2 26.2c-.3 0-.5-.1-.7-.4-.2-.4-.1-.9.3-1.1.5-.3.5-.9.5-1.1 0-.7-.4-1.7-1.5-2.2-.4-.1-.8-.4-1.2-.7.1.9.2 1.9 0 2.8-.1.4-.5.7-.9.6-.4-.1-.7-.5-.6-.9.4-2.3-.6-4.6-1.3-5.8-.4-.6-.6-1-.7-1-.2-.4-.1-.9.2-1.1.3-.2.8-.2 1.1.2 0 .1.3.5.7 1.1.9 1.2 2.2 2.8 3.3 3.4 1.4.7 2.3 2.1 2.4 3.5 0 1.1-.4 2.1-1.3 2.6-.1.1-.2.1-.3.1z"
    />
    <path
      className="prefix__st0"
      d="M6.2 30.5c-.3 0-.5-.1-.7-.4-.2-.4-.1-.9.3-1.1.5-.3.5-.9.5-1.1 0-.7-.4-1.7-1.5-2.2-.4-.2-.8-.4-1.1-.7.1.9.2 1.9 0 2.8-.1.4-.5.7-.9.6-.4-.1-.7-.5-.6-.9.4-2.3-.6-4.6-1.3-5.8-.4-.6-.6-1-.7-1-.2-.4-.1-.8.2-1.1.3-.2.8-.2 1.1.2 0 .1.3.5.7 1.1.8 1.1 2.1 2.8 3.2 3.3 1.4.7 2.3 2.1 2.4 3.5 0 1.1-.4 2.1-1.3 2.6-.1.1-.2.2-.3.2z"
    />
    <path
      className="prefix__st0"
      d="M6.2 34.7c-.3 0-.5-.1-.7-.4-.2-.4-.1-.9.3-1.1.5-.3.5-.9.5-1.1 0-.7-.4-1.7-1.5-2.2-.4-.2-.8-.4-1.1-.7.1.9.2 1.9 0 2.8-.1.4-.5.7-.9.6-.4-.1-.7-.5-.6-.9.4-2.3-.6-4.6-1.3-5.8-.4-.6-.6-1-.7-1.1-.3-.3-.2-.8.1-1 .3-.2.8-.2 1.1.2 0 .1.3.5.7 1.1.8 1.2 2.2 2.9 3.3 3.4 1.4.7 2.3 2.1 2.4 3.5 0 1.1-.4 2.1-1.3 2.6-.1.1-.2.1-.3.1z"
    />
    <path
      className="prefix__st0"
      d="M5.3 40.7c-1.3 0-2.1-.6-2.5-1.1-1.1-1.4-.8-3.5-.7-3.7.4-2.3-.6-4.6-1.3-5.8-.4-.6-.6-1-.7-1-.2-.4-.1-.9.2-1.1.3-.2.8-.2 1.1.2 0 .1.3.5.7 1.1.8 1.2 2.2 2.9 3.3 3.4 2.5 1.2 2.5 3.5 2.5 5.6v.3c0 .2-.1.3-.1.5-.3 1-1.2 1.6-2.5 1.6zm-1.7-7.3c.1.9.2 1.9 0 2.8-.1.5-.2 1.8.4 2.5.3.3.7.5 1.3.5 1 0 1.1-.5 1.1-1 0-2.1-.1-3.2-1.6-3.9l-1.2-.9z"
    />
    <path
      className="prefix__st0"
      d="M9 42.8c-1.3 0-2.2-.6-2.5-1.6-.1-.1-.1-.3-.1-.5v-.3c0-2.2 0-4.4 2.5-5.6 1.2-.6 2.5-2.2 3.3-3.4.4-.7.7-1.1.7-1.1.3-.3.7-.4 1.1-.2.3.2.4.7.2 1.1 0 .1-.3.5-.7 1-.7 1.2-1.8 3.6-1.3 5.8 0 .2.4 2.4-.7 3.7-.4.5-1.1 1.1-2.5 1.1zm-1.1-2.7c0 .6.1 1.1 1.1 1.1.6 0 1-.2 1.3-.5.6-.7.5-2 .4-2.4-.2-1-.2-1.9 0-2.8-.4.3-.8.6-1.1.7-1.5.7-1.7 1.8-1.7 3.9zM8 19.7c-.1 0-.3 0-.4-.1-.9-.5-1.3-1.5-1.3-2.6.1-1.5 1-2.9 2.4-3.5 1.2-.6 2.5-2.2 3.3-3.4.4-.7.7-1.1.7-1.1.3-.3.7-.4 1.1-.2.3.2.4.7.2 1 0 .1-.3.5-.7 1-.7 1.2-1.8 3.6-1.3 5.8.1.4-.2.9-.6.9-.4.1-.8-.2-.9-.6-.2-1-.2-1.9 0-2.8-.4.3-.8.6-1.1.7-1.1.5-1.5 1.5-1.5 2.2 0 .2 0 .9.5 1.1.4.2.5.7.3 1.1-.2.3-.5.5-.7.5z"
    />
    <path
      className="prefix__st0"
      d="M8 23.9c-.1 0-.3 0-.4-.1-.9-.5-1.3-1.5-1.3-2.6.1-1.5 1-2.9 2.4-3.5 1.2-.6 2.5-2.2 3.3-3.4.4-.7.7-1.1.7-1.1.3-.3.7-.4 1.1-.2.3.2.4.7.2 1.1 0 .1-.3.5-.7 1-.7 1.2-1.8 3.6-1.3 5.8.1.4-.2.9-.6.9-.4.1-.8-.2-.9-.6-.2-1-.2-1.9 0-2.8-.4.3-.8.6-1.1.7-1.1.5-1.5 1.5-1.5 2.2 0 .2 0 .9.5 1.1.4.2.5.7.3 1.1-.2.3-.5.4-.7.4z"
    />
    <path
      className="prefix__st0"
      d="M8 28.2c-.1 0-.3 0-.4-.1-.9-.5-1.3-1.5-1.3-2.6.1-1.5 1-2.9 2.4-3.5 1.2-.6 2.5-2.2 3.3-3.4.4-.7.7-1.1.7-1.1.3-.3.7-.4 1.1-.2.3.2.4.7.2 1.1 0 .1-.3.5-.7 1-.7 1.2-1.8 3.6-1.3 5.8.1.4-.2.9-.6.9-.4.1-.8-.2-.9-.6-.2-1-.2-1.9 0-2.8-.4.3-.8.6-1.1.7-1.1.5-1.5 1.5-1.5 2.2 0 .2 0 .9.5 1.1.4.2.5.7.3 1.1-.2.2-.5.4-.7.4z"
    />
    <path
      className="prefix__st0"
      d="M8 32.4c-.1 0-.3 0-.4-.1-.9-.5-1.3-1.5-1.3-2.6.1-1.5 1-2.9 2.4-3.5 1.2-.6 2.5-2.2 3.3-3.4.4-.7.7-1.1.7-1.1.3-.3.7-.4 1.1-.2.3.2.4.7.2 1.1 0 .1-.3.5-.7 1-.7 1.2-1.8 3.6-1.3 5.8.1.4-.2.9-.6.9-.4.1-.8-.2-.9-.6-.2-1-.2-1.9 0-2.8-.4.3-.8.6-1.1.7-1.1.5-1.5 1.5-1.5 2.2 0 .2 0 .9.5 1.1.4.2.5.7.3 1.1-.2.3-.5.4-.7.4z"
    />
    <path
      className="prefix__st0"
      d="M8 36.6c-.1 0-.3 0-.4-.1-.9-.5-1.4-1.4-1.3-2.5.1-1.5 1-2.9 2.4-3.5 1.2-.6 2.5-2.2 3.3-3.4.4-.7.7-1.1.7-1.1.3-.3.7-.4 1.1-.2.3.2.4.7.2 1.1 0 .1-.3.5-.7 1-.7 1.2-1.8 3.6-1.3 5.8.1.4-.2.9-.6.9-.4.1-.8-.2-.9-.6-.2-1-.2-1.9 0-2.8-.4.3-.8.6-1.1.7-1.1.5-1.5 1.5-1.5 2.2 0 .2 0 .9.5 1.1.4.2.5.7.3 1.1-.2.2-.5.3-.7.3zM4.8 12.5h-.2c-.4-.1-.7-.5-.6-1 .1-.5.3-.9.6-1.2C6 8.6 6.2 6.9 6.2 6.9c0-.4.4-.8.9-.7.4 0 .8.4.7.9 0 .1-.2 2.2-2 4.3l-.3.6c0 .3-.3.5-.7.5z"
    />
    <path
      className="prefix__st0"
      d="M9.1 14.7h-.3c-.4-.1-.6-.6-.5-1 .1-.4.5-1.6-.1-2.3-1.8-2.1-2-4.2-2-4.3 0-.4.3-.8.7-.9.4 0 .8.3.9.7 0 0 .2 1.8 1.6 3.4 1.1 1.4.7 3.2.4 3.9-.1.3-.4.5-.7.5zM7.2 55.3c-.4 0-.8-.4-.8-.8v-17c0-.4.4-.8.8-.8s.8.4.8.8v17c-.1.4-.4.8-.8.8zM36.8 7.8h-2c-.1 0-.3-.1-.3-.2-.3-.7-.5-1.4-.8-2.1.1-.1.3-.3.4-.5.2-.2.4-.3.5-.5.4-.4.4-.9 0-1.2-.3-.3-.8-.3-1.2.1l-.9.9c-.7-.3-1.4-.5-2.1-.8-.1 0-.2-.2-.2-.3v-2c0-.6-.3-1-.8-1s-.8.4-.8 1v2c0 .1-.1.3-.2.3-.7.3-1.3.5-2.1.8-.3-.3-.6-.6-1-.9-.4-.4-.9-.4-1.2-.1-.4.3-.4.8 0 1.2.3.2.6.5.9.8.1.1.1.3.1.4-.3.7-.6 1.3-.9 2.1h-2.1c-.6 0-1 .3-1 .9 0 .5.3.8 1 .8h2.1c.3.8.7 1.4.9 2.1 0 .1 0 .2-.1.3-.3.3-.6.6-.8.9-.4.4-.4.9 0 1.3s.8.3 1.2-.1c.3-.3.6-.7.9-.9.8.4 1.6.7 2.3 1v2.2c0 .6.3 1 .8 1s.8-.4.8-.9v-2c0-.1.1-.3.2-.4.7-.3 1.3-.5 2-.8l.9.9c.4.4.9.5 1.3.1.4-.3.4-.9-.1-1.3-.3-.3-.7-.6-1-.8.3-.8.6-1.5.8-2.1.1-.3.2-.3.4-.3h2c.5 0 .8-.3.9-.7.2-.8-.2-1.2-.8-1.2zm-7.4 4.4c-1.9 0-3.5-1.6-3.5-3.6 0-1.9 1.6-3.5 3.5-3.5s3.5 1.6 3.5 3.5-1.6 3.6-3.5 3.6z"
    />
  </svg>
);

Crop10.defaultProps = {
  title: 'summer_barley',
};

Crop10.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
};
