/* eslint-disable import/prefer-default-export */
/* eslint-disable react/prop-types */
import React from 'react';

export const MapMarker = ({ inActive, ...props }) => {
  const { selected, inMap } = props;
  const getProps = () => {
    if (inMap) {
      return {
        width: selected ? '26px' : '21px',
        height: selected ? '38px' : '30px',
        style: {
          position: 'absolute',
          top: selected ? '-38px' : '-30px',
          left: selected ? '-13px' : '-11.5px',
        },
      };
    }
    return {
      width: '21px',
      height: '30px',
      style: {
        position: 'absolute',
        top: '0px',
        left: '0px',
      },
    };
  };

  return (
    <div>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 30" {...getProps()}>
        <title>MapMarker</title>
        {selected ? (
          <g dataname="Group 29" transform="translate(-1408.511 -888.202)">
            <path
              id="Path_370"
              dataname="Path 370"
              d="M1419.012,893.441a5.356,5.356,0,1,0,5.307,5.355,5.407,5.407,0,0,0-.133-1.18A5.318,5.318,0,0,0,1419.012,893.441Z"
              fill="none"
            />
            <path
              id="Path_371"
              dataname="Path 371"
              d="M1419.012,918.2s1.038-.924,2.461-2.5l-4.071.964C1418.368,917.65,1419.012,918.2,1419.012,918.2Z"
              fill="#b95700"
            />
            <path
              id="Path_372"
              dataname="Path 372"
              d="M1417.4,916.664l4.071-.964a35.806,35.806,0,0,0,6.673-10.3l-16.436,3.891A38.863,38.863,0,0,0,1417.4,916.664Z"
              fill="#eb6909"
            />
            <path
              id="Path_373"
              dataname="Path 373"
              d="M1428.146,905.4a18.078,18.078,0,0,0,1.365-6.6,10.686,10.686,0,0,0-.269-2.377l-5.057,1.2a5.407,5.407,0,0,1,.133,1.18,5.3,5.3,0,0,1-10.46,1.265l-5.148,1.219a23.091,23.091,0,0,0,3,8.011Z"
              fill="#5f9501"
            />
            <path
              id="Path_374"
              dataname="Path 374"
              d="M1413.7,898.8a5.3,5.3,0,0,1,10.482-1.18l5.057-1.2a10.589,10.589,0,0,0-5.972-7.307l-12.342,2.922a10.608,10.608,0,0,0-2.417,6.762,16.32,16.32,0,0,0,.2,2.483l5.148-1.219A5.385,5.385,0,0,1,1413.7,898.8Z"
              fill="#79b61d"
            />
            <path
              id="Path_375"
              dataname="Path 375"
              d="M1419.012,888.2a10.442,10.442,0,0,0-8.083,3.833l12.342-2.922A10.376,10.376,0,0,0,1419.012,888.2Z"
              fill="#c8d200"
            />
          </g>
        ) : (
          <path
            dataname="Path 103"
            d="M1429.243,896.42a10.589,10.589,0,0,0-5.972-7.307,10.431,10.431,0,0,0-12.342,2.922,10.608,10.608,0,0,0-2.417,6.762,16.32,16.32,0,0,0,.2,2.483,23.091,23.091,0,0,0,3,8.011,38.863,38.863,0,0,0,5.692,7.373c.966.986,1.609,1.538,1.609,1.538s1.038-.924,2.461-2.5a35.806,35.806,0,0,0,6.673-10.3,18.078,18.078,0,0,0,1.365-6.6A10.686,10.686,0,0,0,1429.243,896.42ZM1413.7,898.8a5.3,5.3,0,0,1,10.482-1.18,5.407,5.407,0,0,1,.133,1.18,5.308,5.308,0,0,1-10.615,0Z"
            transform="translate(-1408.511 -888.202)"
            fill={inActive ? '#79b61d' : '#839CA9'}
          />
        )}
      </svg>
    </div>
  );
};
