const typeConstants = {
  string: 'string',
};

// NG4 Functionalities
const NG4SupportedCountries = ['HU', 'RO', 'AT', 'BG', 'DE', 'FR', 'UK', 'SK', 'CZ'];

// NG Functionalities just for Landing Page
const NG4SupportedCountriesForLP = ['HU', 'RO', 'BG'];

const PostalCodeAndCityNotObligatoryAtXMLUploadCounties = ['HU', 'UK', 'FR', 'RO', 'SK', 'CZ'];

const AvailableLocalesForLive = ['de_DE', 'de_AT', 'ro_RO', 'fr_FR', 'hu_HU', 'bg_BG', 'en_UK', 'cz_CZ', 'sk_SK'];

const nutrizoneAppLinks = {
  iStore: 'https://apps.apple.com/ro/app/nutrizones/id1599299638',
  playStore: 'https://play.google.com/store/apps/details?id=com.cognitivecreators.applicationmap',
};

export {
  typeConstants,
  NG4SupportedCountries,
  PostalCodeAndCityNotObligatoryAtXMLUploadCounties,
  NG4SupportedCountriesForLP,
  nutrizoneAppLinks,
  AvailableLocalesForLive,
};
