/* eslint-disable import/prefer-default-export */
/* eslint-disable react/prop-types */
import React from 'react';
import { PropTypes } from 'prop-types';
import { useLocalizedFromContext } from 'data/Localization';

export const Crop25 = ({ title, fill, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="148.372"
    height="154.311"
    viewBox="0 0 148.372 154.311"
    {...props}
  >
    <title>{useLocalizedFromContext(title)}</title>
    <g id="Component_10_1" dataname="Component 10 – 1" transform="translate(2.5 2.5)">
      <g id="Group_487" dataname="Group 487" transform="translate(-2812.921 -7050.175)">
        <g id="Group_484" dataname="Group 484">
          <path
            id="Path_1433"
            dataname="Path 1433"
            d="M2884.15,7057.773a7.6,7.6,0,0,0-15.2,0s-.34,6.835,8.532,15.418c3.2,3.092,6.665,6.544,6.665,6.544"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
          <path
            id="Path_1434"
            dataname="Path 1434"
            d="M2884.15,7078.693v-20.92a7.6,7.6,0,0,1,15.2,0s.339,6.835-8.532,15.418c-3.2,3.092-6.665,6.544-6.665,6.544"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
        <g id="Group_485" dataname="Group 485">
          <path
            id="Path_1435"
            dataname="Path 1435"
            d="M2904.934,7087.148a7.6,7.6,0,0,1-4.93,14.375s-6.575-1.9-11.815-13.073c-1.889-4.027-4.028-8.428-4.028-8.428"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
          <path
            id="Path_1436"
            dataname="Path 1436"
            d="M2885.146,7080.36l19.788,6.788a7.6,7.6,0,0,0,4.931-14.375s-6.354-2.538-17.351,3.068c-3.963,2.021-8.353,4.181-8.353,4.181"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
        <g id="Group_486" dataname="Group 486">
          <path
            id="Path_1437"
            dataname="Path 1437"
            d="M2863.376,7087.148a7.6,7.6,0,0,0,4.931,14.375s6.575-1.9,11.815-13.073c1.888-4.027,4.028-8.428,4.028-8.428"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
          <path
            id="Path_1438"
            dataname="Path 1438"
            d="M2883.164,7080.36l-19.788,6.788a7.6,7.6,0,1,1-4.931-14.375s6.355-2.538,17.352,3.068c3.963,2.021,8.353,4.181,8.353,4.181"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
        <line
          id="Line_320"
          dataname="Line 320"
          y2="91.929"
          transform="translate(2884.15 7080.243)"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
      </g>
      <g id="Group_491" dataname="Group 491" transform="translate(-2812.921 -7050.175)">
        <g id="Group_488" dataname="Group 488">
          <path
            id="Path_1439"
            dataname="Path 1439"
            d="M2928.132,7107.044a6.214,6.214,0,0,0-12.428,0s-.278,5.589,6.977,12.608c2.615,2.529,5.451,5.352,5.451,5.352"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
          <path
            id="Path_1440"
            dataname="Path 1440"
            d="M2928.132,7124.152v-17.108a6.214,6.214,0,0,1,12.428,0s.278,5.589-6.978,12.608c-2.614,2.529-5.45,5.352-5.45,5.352"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
        <g id="Group_489" dataname="Group 489">
          <path
            id="Path_1441"
            dataname="Path 1441"
            d="M2945.13,7131.066a6.214,6.214,0,0,1-4.033,11.756s-5.377-1.551-9.662-10.691c-1.544-3.293-3.294-6.892-3.294-6.892"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
          <path
            id="Path_1442"
            dataname="Path 1442"
            d="M2928.947,7125.515l16.183,5.551a6.214,6.214,0,1,0,4.032-11.755s-5.2-2.076-14.19,2.509c-3.241,1.652-6.831,3.419-6.831,3.419"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
        <g id="Group_490" dataname="Group 490">
          <path
            id="Path_1443"
            dataname="Path 1443"
            d="M2911.143,7131.066a6.214,6.214,0,1,0,4.033,11.756s5.376-1.551,9.662-10.691c1.544-3.293,3.294-6.892,3.294-6.892"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
          <path
            id="Path_1444"
            dataname="Path 1444"
            d="M2927.326,7125.515l-16.183,5.551a6.214,6.214,0,1,1-4.032-11.755s5.2-2.076,14.19,2.509c3.241,1.652,6.831,3.419,6.831,3.419"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
        <line
          id="Line_321"
          dataname="Line 321"
          y2="33.7"
          transform="translate(2928.132 7125.42)"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
      </g>
      <g id="Group_495" dataname="Group 495" transform="translate(-2812.921 -7050.175)">
        <g id="Group_492" dataname="Group 492">
          <path
            id="Path_1445"
            dataname="Path 1445"
            d="M2840.159,7107.044a6.214,6.214,0,0,0-12.428,0s-.278,5.589,6.978,12.608c2.614,2.529,5.45,5.352,5.45,5.352"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
          <path
            id="Path_1446"
            dataname="Path 1446"
            d="M2840.159,7124.152v-17.108a6.214,6.214,0,0,1,12.428,0s.278,5.589-6.977,12.608c-2.615,2.529-5.451,5.352-5.451,5.352"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
        <g id="Group_493" dataname="Group 493">
          <path
            id="Path_1447"
            dataname="Path 1447"
            d="M2857.157,7131.066a6.214,6.214,0,0,1-4.033,11.756s-5.377-1.551-9.662-10.691c-1.544-3.293-3.294-6.892-3.294-6.892"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
          <path
            id="Path_1448"
            dataname="Path 1448"
            d="M2840.974,7125.515l16.183,5.551a6.214,6.214,0,1,0,4.032-11.755s-5.2-2.076-14.19,2.509c-3.241,1.652-6.831,3.419-6.831,3.419"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
        <g id="Group_494" dataname="Group 494">
          <path
            id="Path_1449"
            dataname="Path 1449"
            d="M2823.17,7131.066a6.214,6.214,0,0,0,4.033,11.756s5.377-1.551,9.662-10.691c1.544-3.293,3.294-6.892,3.294-6.892"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
          <path
            id="Path_1450"
            dataname="Path 1450"
            d="M2839.353,7125.515l-16.183,5.551a6.214,6.214,0,1,1-4.032-11.755s5.2-2.076,14.19,2.509c3.241,1.652,6.831,3.419,6.831,3.419"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
        <line
          id="Line_322"
          dataname="Line 322"
          y2="31.394"
          transform="translate(2840.159 7125.42)"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
      </g>
      <g id="Group_496" dataname="Group 496" transform="translate(-2812.921 -7050.175)">
        <path
          id="Path_1451"
          dataname="Path 1451"
          d="M2867.823,7198.494c-7.781-24.2-22.86-45.222-47.724-49.26,0,0,13.742.589,24.781,49.628"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
        <path
          id="Path_1452"
          dataname="Path 1452"
          d="M2823.022,7198.237c-2.495-7.932-5.78-15.366-10.1-20.939,0,0,15.065,0,30.129,13.966"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
        <path
          id="Path_1453"
          dataname="Path 1453"
          d="M2946,7198.86c2.518-8.164,5.861-15.843,10.295-21.562,0,0-23.359,5.209-33.467,21.058"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
        <path
          id="Path_1454"
          dataname="Path 1454"
          d="M2861.569,7183.069a55.847,55.847,0,0,0,0-28.907s15,2.652,20.678,24.081"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
        <path
          id="Path_1455"
          dataname="Path 1455"
          d="M2875.176,7196.121c5.347-14.7,11.434-35.232,33.789-43.353,0,0-9.385,17.483-8.2,46.094"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
        <path
          id="Path_1456"
          dataname="Path 1456"
          d="M2874.332,7198.55c.327-.986.453-1.354.844-2.429"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
        <path
          id="Path_1457"
          dataname="Path 1457"
          d="M2902.091,7175.815a117.056,117.056,0,0,1,38.66-21.653s-10.709,10.7-17.925,44.7"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
        <line
          id="Line_323"
          dataname="Line 323"
          x2="122.977"
          transform="translate(2823.021 7198.862)"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
      </g>
    </g>
  </svg>
);

Crop25.defaultProps = {
  title: 'clover_grass_max60',
};

Crop25.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
};
