/* eslint-disable class-methods-use-this */
import moment from 'moment';

class DateHelper {
  static shared = DateHelper.shared ? DateHelper.shared : new DateHelper();

  monthDiff(dateFrom, dateTo) {
    return (
      dateTo.getMonth() - dateFrom.getMonth() + 12 * (dateTo.getFullYear() - dateFrom.getFullYear())
    );
  }

  isBeetween(date1, date2, refDate) {
    return moment(refDate).isBetween(date1, date2, null, '[]');
  }

  getFormattedDateString(date, format) {
    return moment(date).format(format);
  }

  getOneDayBefore(date) {
    return moment(date).subtract(1, 'day').format('YYYY-MM-DD');
  }

  getOneDayAfter(date) {
    return moment(date).add(1, 'day').format('YYYY-MM-DD');
  }

  today() {
    return moment().format('YYYY-MM-DD');
  }

  getOneMonthBefore(date) {
    return moment(date).subtract(1, 'month').format('YYYY-MM-DD');
  }

  getOneYearBefore(date) {
    return moment(date).subtract(1, 'year').format('YYYY-MM-DD');
  }

  getNumberWithLeadingZeros(number) {
    if (number !== '0' && number[0] === '0') {
      return number;
    }
    return `${number < 10 ? `0${number}` : number}`;
  }

  // Some browsers has problem with converting datestrings to Date objects if the dateString is not in the correct format
  getNormalizedDateString(dateString) {
    const [year, month, day] = dateString.split('-');
    return `${year}-${this.getNumberWithLeadingZeros(month)}-${this.getNumberWithLeadingZeros(
      day,
    )}T00:00:00`;
  }
}

export default DateHelper;
