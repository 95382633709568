/* eslint-disable import/prefer-default-export */
/* eslint-disable react/prop-types */
import React from 'react';
import { PropTypes } from 'prop-types';
import { useLocalizedFromContext } from 'data/Localization';

export const Crop6 = ({ title, fill, ...props }) => (
  <svg
    id="prefix__Layer_1"
    x={0}
    y={0}
    width="66"
    height="78"
    viewBox="0 0 66 78"
    xmlSpace="preserve"
    {...props}
  >
    <title>{useLocalizedFromContext(title)}</title>
    <style>{`.prefix__st0{fill:${fill || '#71bf44'}}`}</style>
    <path
      className="prefix__st0"
      d="M63.8 13.1C60.4 5.3 54.1 2.5 51.6 1.7 44.5-.7 33.7 0 26.8 5.9c-3.9 3.3-8.1 9.6-6.5 21.1-.3 0-.6.1-.9.1h-.1c-.3.1-.6.1-.9.2-.7.2-1.3.4-2 .8l-.1.1c-3.3 1.6-5.9 4.6-8.7 7.9-.5.6-1 1.1-1.5 1.7-6.3 7.3-7.8 16.3-4 24.7 4 8.9 13.2 15.3 21.8 15.3 5.7 0 25-1.7 34.9-23.9 7.6-17.3 9.3-31.1 5-40.8zM19 29.8c.3-.1.5-.1.8-.2h.2c.2 0 .5-.1.7-.1.6 2.6 1.5 4.9 2.8 7.1-2.4-1.8-4-4.3-5-6.6.1-.1.3-.2.5-.2zM56.4 53c-9.3 20.8-27.3 22.5-32.6 22.5-7.6 0-15.8-5.8-19.5-13.9-2-4.4-4.2-13.1 3.6-22 .5-.6 1-1.2 1.5-1.7 2.5-2.9 4.5-5.3 6.9-6.7 1.7 4 5 8.5 11.1 10h.3c.4 0 .9-.2 1.1-.6.3-.5.2-1.1-.2-1.5-3.2-3.3-4.9-6.8-5.7-11.2-1.4-8.9.4-15.6 5.5-19.9 4-3.5 9.7-4.9 15-4.9 2.7 0 5.3.4 7.5 1.1 2.2.7 7.7 3.2 10.7 10 3.9 9 2.2 22-5.2 38.8z"
    />
    <path
      className="prefix__st0"
      d="M27 65.4c-7.3-1.8-10.8-7.6-10.9-7.7-.3-.6-1.1-.8-1.7-.4-.6.3-.8 1.1-.4 1.7.2.3 4 6.8 12.4 8.9h.3c.6 0 1.1-.4 1.2-.9.2-.8-.2-1.4-.9-1.6zM57.1 43.2c-4.5 2.7-11.7-.1-11.8-.1-.6-.3-1.4.1-1.6.7-.3.6.1 1.4.7 1.6.2.1 3.8 1.5 7.7 1.5 2.1 0 4.3-.4 6.3-1.6.6-.4.8-1.1.4-1.7-.3-.6-1.1-.8-1.7-.4zM13.9 53.3c-1.1-1.6-1.6-3.7-1.6-3.7-.1-.7-.8-1.1-1.5-1-.7.1-1.1.8-1 1.5 0 .1.6 2.5 1.9 4.6.2.4.6.6 1 .6.2 0 .5-.1.7-.2.7-.4.9-1.2.5-1.8zM58.7 15.3s-1.8 2-5.1 1.7c-.7-.1-1.3.5-1.3 1.1s.5 1.3 1.1 1.3h.8c4.1 0 6.3-2.5 6.4-2.6.5-.5.4-1.3-.1-1.8-.5-.2-1.3-.2-1.8.3z"
    />
  </svg>
);

Crop6.defaultProps = {
  title: 'potato',
};

Crop6.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
};
