/* eslint-disable react/prop-types */
import React from 'react';

const ColouredReseller = ({ fill, ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52.93231 62" {...props}>
    <title>Reseller avatar</title>
    <path
      d="M34.32074,23.13647V17.34534H5.5351V59.78571h4.7976v-39.119a1.10715,1.10715,0,0,1,2.2143,0v3.32141h4.57556V20.66675a1.10715,1.10715,0,0,1,2.21429,0v39.119H34.32074V23.13647ZM30.70557,55.21008a1.10715,1.10715,0,1,1-2.2143,0V20.66663a1.10715,1.10715,0,1,1,2.2143,0V55.21008Z"
      fill="none"
    />
    <path
      d="M19.92792,2.21429h-.00073A14.407,14.407,0,0,0,5.60968,15.13092h28.6358A14.44118,14.44118,0,0,0,19.92792,2.21429ZM9.74524,10.77765A11.68376,11.68376,0,0,1,19.85632,4.94788a1.10715,1.10715,0,1,1,0,2.21429,9.48255,9.48255,0,0,0-8.19427,4.7226,1.26685,1.26685,0,0,1-.96015.51611,1.344,1.344,0,0,1-.516-.147h.00049A1.11686,1.11686,0,0,1,9.74524,10.77765Z"
      fill="none"
    />
    <rect x="12.547" y="33.43634" width="4.57556" height="5.01965" fill="none" />
    <polygon
      points="47.321 23.345 36.46 23.345 36.46 37.018 36.46 51.402 36.46 59.786 38.749 59.786 38.75 59.786 47.321 59.786 47.321 48.854 47.321 34.47 47.321 23.345"
      fill="none"
    />
    <polygon
      points="12.547 40.595 12.547 42.628 12.547 45.615 17.123 45.615 17.123 41.555 17.123 40.595 12.547 40.595"
      fill="none"
    />
    <path
      d="M47.09949,20.13855A14.41317,14.41317,0,0,0,35.56488,8.46112c-.43323-.08044-.87262-.14362-1.3186-.1842.083.14331.15674.29229.23553.43823a16.26268,16.26268,0,0,1,1.97821,7.892v4.5238H47.24548C47.2113,20.79565,47.15643,20.46674,47.09949,20.13855Z"
      fill="none"
    />
    <polygon
      points="12.547 55.063 12.547 57.012 12.547 59.786 17.123 59.786 17.123 55.938 17.123 55.063 12.547 55.063"
      fill="none"
    />
    <polygon
      points="12.547 26.202 12.547 28.245 12.547 31.222 17.123 31.222 17.123 27.171 17.123 26.202 12.547 26.202"
      fill="none"
    />
    <rect x="12.547" y="47.82904" width="4.57556" height="5.01959" fill="none" />
    <path
      d="M28.49127,55.21008a1.10715,1.10715,0,1,0,2.2143,0V52.75159l-2.2143.51947Z"
      fill={fill || '#b95700'}
    />
    <path
      d="M38.8252,62h13a1.13434,1.13434,0,0,0,1.10711-1.10718,1.15323,1.15323,0,0,0-1.18213-1.10711l-.00085-.00019H49.46V48.35175l-2.13928.50189V59.78571H38.75018l-.00085-.00019H36.46v-8.384l-2.13928.50189v7.88227H19.33685V55.41864l-2.21429.51947v3.8476H12.547V57.01154l-2.2143.51953v2.25464H5.5351V58.65656l-2.13868.5017v.62745H1.10712a1.10715,1.10715,0,0,0,0,2.21429H38.8252Z"
      fill={fill || '#b95700'}
    />
    <polygon
      points="34.321 37.52 34.321 51.903 36.46 51.402 36.46 37.018 34.321 37.52"
      fill={fill || '#eb6909'}
    />
    <polygon
      points="28.491 38.888 28.491 53.271 30.706 52.752 30.706 38.368 28.491 38.888"
      fill={fill || '#eb6909'}
    />
    <path
      d="M17.12256,41.55463v4.06024H12.547V42.62805l-2.2143.51947V57.53107l2.2143-.51953V55.06311h4.57556v.875l2.21429-.51947V41.03516Zm0,11.294H12.547V47.829h4.57556Z"
      fill={fill || '#eb6909'}
    />
    <polygon
      points="47.321 48.854 49.46 48.352 49.46 33.968 47.321 34.47 47.321 48.854"
      fill={fill || '#eb6909'}
    />
    <polygon
      points="5.535 44.273 3.396 44.775 3.396 59.158 5.535 58.657 5.535 44.273"
      fill={fill || '#eb6909'}
    />
    <path
      d="M47.24548,21.13092H42.86957l-8.54883,2.00555V37.52L36.46,37.01807V23.34534H47.32074V34.47015L49.46,33.96826V22.60712a16.40089,16.40089,0,0,0-.258-2.9618l-2.10248.49323C47.15643,20.46674,47.2113,20.79565,47.24548,21.13092Z"
      fill={fill || '#5f9501'}
    />
    <polygon
      points="30.706 23.985 28.491 24.504 28.491 38.888 30.706 38.368 30.706 23.985"
      fill={fill || '#5f9501'}
    />
    <polygon
      points="5.535 29.89 3.396 30.391 3.396 44.775 5.535 44.273 5.535 29.89"
      fill={fill || '#5f9501'}
    />
    <path
      d="M12.547,40.59528h4.57556v.95935l2.21429-.51947V26.65167l-2.21429.51947v4.05078H12.547V28.24457l-2.2143.51953V43.14752l2.2143-.51947Zm0-7.15894h4.57556V38.456H12.547Z"
      fill={fill || '#5f9501'}
    />
    <path
      d="M47.09949,20.13855l2.10248-.49323a16.51523,16.51523,0,0,0-9.46088-12.164l-4.17621.9798A14.41317,14.41317,0,0,1,47.09949,20.13855Z"
      fill={fill || '#79b61d'}
    />
    <path
      d="M30.70557,20.66663a1.10715,1.10715,0,1,0-2.2143,0v3.83746l2.2143-.51947Z"
      fill={fill || '#79b61d'}
    />
    <path
      d="M5.5351,17.34534H34.32074v5.79113l8.54883-2.00555H36.46v-4.5238a16.26268,16.26268,0,0,0-1.97821-7.892L32.273,9.2334a14.27659,14.27659,0,0,1,1.97253,5.89752H7.13428l-3.73157.87543c-.00085.02862-.00562.05615-.00629.08484V30.3913l2.13868-.50171Z"
      fill={fill || '#79b61d'}
    />
    <path
      d="M12.547,26.20233h4.57556v.96881l2.21429-.51947V20.66675a1.10715,1.10715,0,0,0-2.21429,0v3.32141H12.547V20.66675a1.10715,1.10715,0,0,0-2.2143,0V28.7641l2.2143-.51953Z"
      fill={fill || '#79b61d'}
    />
    <path
      d="M10.18634,12.25385h-.00049a1.344,1.344,0,0,0,.516.147,1.26685,1.26685,0,0,0,.96015-.51611,9.48255,9.48255,0,0,1,8.19427-4.7226,1.10715,1.10715,0,1,0,0-2.21429A11.68376,11.68376,0,0,0,9.74524,10.77765,1.11686,1.11686,0,0,0,10.18634,12.25385Z"
      fill={fill || '#c8d200'}
    />
    <path
      d="M5.60968,15.13092A14.407,14.407,0,0,1,19.92719,2.21429h.00073A14.38075,14.38075,0,0,1,32.273,9.2334l2.20886-.51825c-.07879-.14594-.15253-.29492-.23553-.43823.446.04058.88537.10376,1.3186.1842l4.17621-.9798A16.4599,16.4599,0,0,0,32.92822,6c-.08172,0-.1607.01044-.24206.01172A16.537,16.537,0,0,0,3.40271,16.00635l3.73157-.87543Z"
      fill={fill || '#c8d200'}
    />
  </svg>
);

export default ColouredReseller;
