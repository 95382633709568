/* eslint-disable import/prefer-default-export */
/* eslint-disable react/prop-types */
import React from 'react';

export const AddCropRotationIcon = ({ color, ...props }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 128 128"
      enableBackground="new 0 0 128 128"
      xmlSpace="preserve"
      {...props}
    >
      <g>
        <defs>
          <path
            id="SVGID_49_AddCropRotationIcon"
            d="M70.4,98c0.3,0.3,0.4,0.7,0.4,1.1s-0.2,0.8-0.4,1.1l-5.3,5.3c-0.3,0.3-0.7,0.4-1.1,0.4
            c-0.2,0-0.4,0-0.6-0.1c-0.6-0.2-0.9-0.8-0.9-1.4v-3.8C49.1,100,36.8,92,31,79.8c-0.4-0.7,0-1.6,0.7-2c0.7-0.4,1.6,0,2,0.7
            C39,89.7,50.3,97,62.6,97.6v-3.7c0-0.6,0.4-1.2,0.9-1.4c0.6-0.2,1.2-0.1,1.6,0.3L70.4,98z M105.5,62.9l-5.3-5.3
            c-0.6-0.6-1.6-0.6-2.1,0l-5.3,5.3c-0.4,0.4-0.6,1.1-0.3,1.6s0.8,0.9,1.4,0.9h3.7C97,77.8,89.7,89,78.5,94.3
            c-0.7,0.4-1.1,1.3-0.7,2c0.3,0.5,0.8,0.9,1.4,0.9c0.2,0,0.4,0,0.6-0.1C92,91.2,100,78.9,100.6,65.4h3.8c0.6,0,1.2-0.4,1.4-0.9
            S105.9,63.3,105.5,62.9z M62.9,35.2c0.3,0.3,0.7,0.4,1.1,0.4c0.2,0,0.4,0,0.6-0.1c0.6-0.2,0.9-0.8,0.9-1.4v-3.7
            C77.8,31,89,38.3,94.3,49.5c0.3,0.5,0.8,0.9,1.4,0.9c0.2,0,0.4,0,0.6-0.1c0.7-0.4,1.1-1.3,0.7-2C91.2,36,78.9,28,65.5,27.4v-3.8
            c0-0.6-0.4-1.2-0.9-1.4c-0.6-0.2-1.2-0.1-1.6,0.3l-5.3,5.3c-0.6,0.6-0.6,1.5,0,2.1L62.9,35.2z M35.6,63.4
            c-0.2-0.6-0.8-0.9-1.4-0.9h-3.7C31,50.2,38.4,39,49.5,33.7c0.7-0.4,1.1-1.3,0.7-2c-0.4-0.7-1.3-1.1-2-0.7
            C36,36.8,28,49.1,27.4,62.5h-3.8c-0.6,0-1.2,0.4-1.4,0.9s-0.1,1.2,0.3,1.6l5.3,5.3c0.3,0.3,0.7,0.4,1.1,0.4s0.8-0.1,1.1-0.4
            l5.3-5.3C35.7,64.6,35.8,64,35.6,63.4z M64,79.1c0.8,0,1.5-0.7,1.5-1.5V65.5h12.1c0.8,0,1.5-0.7,1.5-1.5s-0.7-1.5-1.5-1.5H65.5
            V50.4c0-0.8-0.7-1.5-1.5-1.5c-0.8,0-1.5,0.7-1.5,1.5v12.1H50.4c-0.8,0-1.5,0.7-1.5,1.5s0.7,1.5,1.5,1.5h12.1v12.1
            C62.5,78.5,63.2,79.1,64,79.1z"
          />
        </defs>
        <clipPath id="SVGID_2_AddCropRotationIcon">
          <use xlinkHref="#SVGID_49_AddCropRotationIcon" overflow="visible" />
        </clipPath>
        <g clipPath="url(#SVGID_2_AddCropRotationIcon)">
          <rect
            x="-12"
            y="109.6"
            transform="matrix(0.9736 -0.2284 0.2284 0.9736 -26.5992 20.8694)"
            fill="#B95700"
            width="177.8"
            height="31.5"
          />
          <rect
            x="-19.2"
            y="78.9"
            transform="matrix(0.9736 -0.2284 0.2284 0.9736 -19.7827 18.4147)"
            fill="#EB6909"
            width="177.8"
            height="31.5"
          />
          <rect
            x="-26.4"
            y="48.2"
            transform="matrix(0.9736 -0.2284 0.2284 0.9736 -12.9663 15.9601)"
            fill="#5F9501"
            width="177.8"
            height="31.5"
          />
          <rect
            x="-33.6"
            y="17.6"
            transform="matrix(0.9736 -0.2284 0.2284 0.9736 -6.1498 13.5054)"
            fill="#79B61D"
            width="177.8"
            height="31.5"
          />
          <rect
            x="-40.8"
            y="-13.1"
            transform="matrix(0.9736 -0.2284 0.2284 0.9736 0.6667 11.0508)"
            fill="#C8D200"
            width="177.8"
            height="31.5"
          />
        </g>
      </g>
    </svg>
  );
};
