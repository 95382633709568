/* eslint-disable react/prop-types */
import React from 'react';

const PlusGradient = ({ disabled, ...props }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 70 70"
      enableBackground="new 0 0 70 70"
      width="70"
      height="70"
      {...props}
    >
      <path
        fill={disabled ? 'rgba(131, 156, 169, .5)' : '#B95700'}
        d="M37,53.1h-3.8v-2.4l-3,0.7v3.2c0,0.8,0.7,1.5,1.5,1.5h6.8c0.8,0,1.5-0.7,1.5-1.5v-5.5l-3,0.7V53.1z"
      />
      <g>
        <path
          fill={disabled ? 'rgba(131, 156, 169, .5)' : '#EB6909'}
          d="M53,35.9v1.2h-0.1h-5L37,39.7v10.1v0l3-0.7v0v-9h14.5c0.8,0,1.5-0.7,1.5-1.5v-3.4L53,35.9z"
        />
        <polygon
          fill={disabled ? 'rgba(131, 156, 169, .5)' : '#EB6909'}
          points="30.2,51.4 30.2,51.4 33.2,50.7 33.2,50.6 33.2,40.6 30.2,41.3"
        />
      </g>
      <path
        fill={disabled ? 'rgba(131, 156, 169, .5)' : '#5F9501'}
        d="M31.7,33.3c0.8,0,1.5-0.7,1.5-1.5v-1.4l-12.4,2.9H31.7z"
      />
      <path
        fill={disabled ? 'rgba(131, 156, 169, .5)' : '#5F9501'}
        d="M47.8,37.1h-9.3c-0.8,0-1.5,0.7-1.5,1.5v1v0l10.9-2.6H47.8z"
      />
      <g>
        <path
          fill={disabled ? 'rgba(131, 156, 169, .5)' : '#5F9501'}
          d="M31.7,37.1H17.1v-2.9l-3,0.7v3.7c0,0.8,0.7,1.5,1.5,1.5h14.6v1.1v0l3-0.7v0v-1.9C33.2,37.8,32.5,37.1,31.7,37.1z"
        />
        <path
          fill={disabled ? 'rgba(131, 156, 169, .5)' : '#5F9501'}
          d="M54.4,30.3H40v-1.5l-3,0.7v2.3c0,0.8,0.7,1.5,1.5,1.5H53v2.6v0l3-0.7v0v-3.4C55.9,31,55.3,30.3,54.4,30.3z"
        />
      </g>
      <g>
        <path
          fill={disabled ? 'rgba(131, 156, 169, .5)' : '#79B61D'}
          d="M30.2,30.3H15.6c-0.8,0-1.5,0.7-1.5,1.5v3.1l3-0.7v-0.9h3.7l12.4-2.9V20.2l-3,0.7V30.3z"
        />
        <polygon
          fill={disabled ? 'rgba(131, 156, 169, .5)' : '#79B61D'}
          points="37,19.3 37,29.5 40,28.8 40,18.6"
        />
      </g>
      <path
        fill={disabled ? 'rgba(131, 156, 169, .5)' : '#C8D200'}
        d="M38.5,14.3h-6.8c-0.8,0-1.5,0.7-1.5,1.5v5.1v0l3-0.7v0v-2.9H37v2v0l3-0.7v0v-2.8C40,15,39.3,14.3,38.5,14.3z"
      />
    </svg>
  );
};

export default PlusGradient;
