import { localized } from 'data/Localization';
import { range } from 'lodash';
import UserUtils from 'Utils/UserUtils';

const supportedErrorCodes = range(1, 89, 1);

const EAMAErrors = {
  11: 'Nutzungsbestimmungen müssen akzeptiert werden.',
  21: 'Der eingeloggte Bewirtschafter ist für die angeforderten Daten nicht berechtigt.',
  22: 'Der eingeloggte Bewirtschafter hat keine Einwilligungserklärung abgegeben.',
  24: 'Für den eingeloggten Bewirtschafter existieren in der übergebenen Förderart keine Feldstücke.',
  25: 'Bei der übergebenen Betriebsnummer handelt es sich nicht um einen Hauptbetrieb.',
  26: 'Der übergebene Betrieb existiert nicht.',
  27: 'Keine gültige Förderart.',
  41: 'Die Gültigkeit des PIN-Codes ist abgelaufen.',
  42: 'PIN-Code ist abgelaufen und Nutzungsbestimmungen müssen akzeptiert werden.',
  43: 'Betriebs-/Klientennummer oder PIN ist ungültig.',
  44: 'PIN-Code ist gesperrt.',
  46: 'PIN Prüfung OK. LW hat keine ZLB Berechtigung. Könnte sich nicht in eAMA einloggen.',
  47: 'Betrieb ist inaktiv.',
  48: 'Unbekannter Fehler.',
};

class ErrorMessageHelper {
  static getErrorFromCode(code, locale) {
    if (!supportedErrorCodes.includes(code)) {
      return localized(locale, 'alert_failed_webservice_2');
    }
    if (
      UserUtils.isNG4() &&
      !localized(locale, `error_message_${code}_NG4`).includes('labels error') &&
      code !== 43
    ) {
      return localized(locale, `error_message_${code}_NG4`);
    }
    return localized(locale, `error_message_${code}`);
  }

  static getErrorFromEamaErrorCode(code, locale) {
    if (code === 1) {
      return localized(locale, `error_message_${code}`);
    }
    let errorMessage = localized(locale, 'alert_failed_webservice_2');
    if (EAMAErrors[code]) {
      errorMessage = EAMAErrors[code];
    }
    return errorMessage;
  }

  static getErrorZipFileUpload(errorData, file, code, locale) {
    if (!supportedErrorCodes.includes(code)) {
      return localized(locale, 'alert_failed_webservice_1');
    }
    const errorMessage = errorData.slice(0, 5).map((message) => {
      return `${localized(locale, `upload_error_message_${message.errorCode}`).replace(
        'fileName',
        file,
      )}`;
    });
    return errorMessage;
  }
}

export default ErrorMessageHelper;
