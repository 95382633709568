/* eslint-disable import/prefer-default-export */
/* eslint-disable react/prop-types */
import React from 'react';
import { PropTypes } from 'prop-types';
import { useLocalizedFromContext } from 'data/Localization';

export const Crop46 = ({ title, fill, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="138.406"
    height="160.686"
    viewBox="0 0 138.406 160.686"
    {...props}
  >
    <title>{useLocalizedFromContext(title)}</title>
    <g id="Component_8_1" data-name="Component 8 – 1" transform="translate(2.5 2.5)">
      <path
        id="Path_749"
        data-name="Path 749"
        d="M793.411,6583.968s2.92-71.606-29.957-95.656c0,0,13.053,6.313,13.053,95.656Z"
        transform="translate(-743.938 -6440.746)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_750"
        data-name="Path 750"
        d="M778.939,6507.182s-.232-34.328-3.218-53c0,0,17.34,13.61,20.326,57.948"
        transform="translate(-743.938 -6440.746)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_751"
        data-name="Path 751"
        d="M791.991,6555.158s-.286-75.074,35.338-114.412c0,0-16.852,59.34-16.852,121.014v22.208H793.411"
        transform="translate(-743.938 -6440.746)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_752"
        data-name="Path 752"
        d="M810.651,6550.756s4.092-58.671,35.11-80.006c0,0-18.272,5.1-18.272,113.218H810.477"
        transform="translate(-743.938 -6440.746)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_753"
        data-name="Path 753"
        d="M774.585,6534.122s-15.731-22.868-23.837-23.868c0,0,12.706,17.041,12.706,73.714h12.418"
        transform="translate(-743.938 -6440.746)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_754"
        data-name="Path 754"
        d="M854.248,6565.114s.793-24.484,23.1-31.524c0,0-10.758,10.124-10.729,50.378H856.526"
        transform="translate(-743.938 -6440.746)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_755"
        data-name="Path 755"
        d="M831.208,6514.989s7.533-12.864,25.318-24.365c0,0-15.974-2.295-15.974,93.344H827.329"
        transform="translate(-743.938 -6440.746)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_756"
        data-name="Path 756"
        d="M841.927,6583.968h11.839s-2.056-49.228,14.388-78.828c0,0-20.808,12.642-26.281,38.387"
        transform="translate(-743.938 -6440.746)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_757"
        data-name="Path 757"
        d="M862.5,6583.968H750.379s1.149-25.447-6.441-43.338c0,0,18.69,11.018,18.69,21.346"
        transform="translate(-743.938 -6440.746)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <rect
        id="Rectangle_114"
        data-name="Rectangle 114"
        width="116.208"
        height="12.463"
        transform="translate(6.441 143.222)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
    </g>
  </svg>
);

Crop46.defaultProps = {
  title: 'foddergrass',
};

Crop46.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
};
