/* eslint-disable prefer-destructuring */
/* eslint-disable no-restricted-syntax, no-prototype-builtins */
import { cloneDeep, sortBy, setWith, unionBy } from 'lodash';
import { normalizeInput } from 'Utils/GlobalUtils';
import { formatLegalDeadline } from 'Utils/ProductUtils';
import { harvestingYearArrayToName, harvestingYearArrayToNameNG4 } from 'Utils/HarvestingYearUtils';

const emptyMonthObject = {
  8: [],
  9: [],
  10: [],
  11: [],
  12: [],
  1: [],
  2: [],
  3: [],
  4: [],
  5: [],
  6: [],
  7: [],
};

// Filter function to decide if the plan is included by the current search terms
const planShouldStay = (plan, searchTerms) => {
  const normalizedSearchTerms = normalizeInput(searchTerms);
  const splitSearchTerms = normalizedSearchTerms.split(/\s+/);

  for (let index = 0; index < splitSearchTerms.length; index += 1) {
    const searchTerm = splitSearchTerms[index];
    if (normalizeInput(plan.paramsOut.planName.title).includes(searchTerm)) {
      return true;
    }
    if (normalizeInput(plan.paramsOut.plot.name).includes(searchTerm)) {
      return true;
    }
    if (normalizeInput(plan.paramsOut.plot.farm.name).includes(searchTerm)) {
      return true;
    }
  }
  return false;
};

// Filter function to decide if the plan is included by the current search terms
const planShouldStayNG4 = (plan, searchTerms) => {
  const normalizedSearchTerms = normalizeInput(searchTerms);
  const splitSearchTerms = normalizedSearchTerms.split(/\s+/);

  for (let index = 0; index < splitSearchTerms.length; index += 1) {
    const searchTerm = splitSearchTerms[index];
    if (normalizeInput(plan.title).includes(searchTerm)) {
      return true;
    }
    if (normalizeInput(plan.plot.name).includes(searchTerm)) {
      return true;
    }
    if (normalizeInput(plan.plot.farm.name).includes(searchTerm)) {
      return true;
    }
  }
  return false;
};

const filterPlansForTerms = (planList, searchTerms, role) => {
  if (!searchTerms || searchTerms.length === 0) {
    return planList;
  }
  const filteredPlanList = planList.filter((plan) => planShouldStay(plan, searchTerms, role));
  return filteredPlanList;
};

const filterPlansForTermsNG4 = (planList, searchTerms, role) => {
  if (!searchTerms || searchTerms.length === 0) {
    return planList;
  }
  const filteredPlanList = planList.filter((plan) => planShouldStayNG4(plan, searchTerms, role));
  return filteredPlanList;
};

const getFarmerStructuredData = (planListUnfiltered, planListFiltered, role, lang) => {
  const getPlansPerMonth = (yearPlanList) => {
    const monthObject = cloneDeep(emptyMonthObject);
    yearPlanList.forEach((plan) => {
      const {
        paramsOut: {
          applicationTimeline,
          id,
          legalDeadline,
          plot,
          planName: { logoId, cropName },
        },
      } = plan;
      const emptyPlot = {
        size: '',
        plotName: '',
        farmName: '',
      };

      if (plot) {
        const {
          size,
          name: plotName,
          farm: { name: farmName },
        } = plot;
        emptyPlot.size = size;
        emptyPlot.plotName = plotName;
        emptyPlot.farmName = farmName;
      }

      applicationTimeline.forEach((item) => {
        monthObject[item.month].push({
          link: `/myplan/${id}`,
          plotSize: emptyPlot.size,
          planId: id,
          icon: logoId,
          title: cropName,
          subtitle1: emptyPlot.plotName,
          subtitle2: emptyPlot.farmName,
          legalDeadline: formatLegalDeadline(legalDeadline, lang),
        });
      });
    });

    const sortMonthItems = (key) => {
      monthObject[key] = sortBy(monthObject[key], ['title', 'subtitle1']);
    };
    Object.keys(monthObject).forEach(sortMonthItems);

    return monthObject;
  };

  const getProductsFromPlanList = (planList) => {
    const productObjects = {};
    planList.forEach((plan) => {
      plan.paramsOut.applicationTimeline.forEach((application) => {
        const { product, quantity: productQuantity } = application.recommendation;

        let productObject = productObjects[product.id];
        if (productObject === undefined) {
          productObject = {
            ...product,
            plans: {},
            totalQuantity: 0,
          };
        }
        const plotSize = plan.paramsOut.plot ? plan.paramsOut.plot.size : 0;
        const ammountToAdd = productQuantity * plotSize;

        const title = plan.paramsOut.plot ? plan.paramsOut.plot.title : '';
        if (title && title !== '') {
          if (productObject.plans[title]) {
            productObject.plans[title] += ammountToAdd;
          } else {
            productObject.plans[title] = ammountToAdd;
          }
        }

        productObject.totalQuantity += productQuantity * plotSize;
        productObjects[product.id] = productObject;
      });
    });
    return productObjects;
  };

  const plansByHarvestingYear = {};

  planListUnfiltered.forEach((plan) => {
    const {
      paramsIn: { harvestingYear },
    } = plan;
    const harvestingYearAsName = harvestingYearArrayToName(harvestingYear);
    if (!plansByHarvestingYear[harvestingYearAsName])
      plansByHarvestingYear[harvestingYearAsName] = { plans: [] };
  });

  planListFiltered.forEach((plan) => {
    const {
      paramsIn: { harvestingYear },
    } = plan;
    const harvestingYearAsName = harvestingYearArrayToName(harvestingYear);
    plansByHarvestingYear[harvestingYearAsName].plans.push(plan);
  });

  Object.keys(plansByHarvestingYear).forEach((key) => {
    const { plans } = plansByHarvestingYear[key];

    plansByHarvestingYear[key] = {
      ...plansByHarvestingYear[key],
      plansByMonth: getPlansPerMonth(plans),
      products: getProductsFromPlanList(plans),
    };
  });
  return plansByHarvestingYear;
};

const getFarmerStructuredDataNG4 = (planListUnfiltered, planListFiltered) => {
  const getProductsFromPlanList = (planList) => {
    const productObjects = {};
    planList.forEach((plan) => {
      plan.steps.forEach((application) => {
        let { product, quantity: productQuantity } = application.recommended;

        // If product was changed the changed product is added to the product list
        if (application.isProductChanged) {
          product = application.selected.product;
          productQuantity = application.selected.quantity;
        }

        let productObject = productObjects[product.id];
        if (productObject === undefined) {
          productObject = {
            ...product,
            plans: {},
            totalQuantity: 0,
          };
        }
        const plotSize = plan.plot ? plan.plot.size : 0;
        const ammountToAdd = productQuantity * plotSize;

        const title = plan.plot ? plan.plot.title : '';
        if (title && title !== '') {
          if (productObject.plans[title]) {
            productObject.plans[title] += ammountToAdd;
          } else {
            productObject.plans[title] = ammountToAdd;
          }
        }

        productObject.totalQuantity += productQuantity * plotSize;
        productObjects[product.id] = productObject;
      });
    });
    return productObjects;
  };

  const plansByHarvestingYear = {};

  planListUnfiltered.forEach((plan) => {
    const { harvestingYear } = plan;
    const harvestingYearAsName = harvestingYearArrayToNameNG4(harvestingYear);
    if (!plansByHarvestingYear[harvestingYearAsName])
      plansByHarvestingYear[harvestingYearAsName] = { plans: [] };
  });

  planListFiltered.forEach((plan) => {
    const { harvestingYear } = plan;
    const harvestingYearAsName = harvestingYearArrayToNameNG4(harvestingYear);
    plansByHarvestingYear[harvestingYearAsName].plans.push(plan);
  });

  Object.keys(plansByHarvestingYear).forEach((key) => {
    const { plans } = plansByHarvestingYear[key];

    plansByHarvestingYear[key] = {
      ...plansByHarvestingYear[key],
      products: getProductsFromPlanList(plans),
    };
  });
  return plansByHarvestingYear;
};

export const getFarmerStructuredDataFiltered = (planListUnfiltered, searchTerms, lang, role) => {
  const planListFiltered = filterPlansForTerms(planListUnfiltered, searchTerms, role);
  const planListFormatted = getFarmerStructuredData(
    planListUnfiltered,
    planListFiltered,
    role,
    lang,
  );
  return planListFormatted;
};

export const getFarmerStructuredDataFilteredNG4 = (planListUnfiltered, searchTerms, role) => {
  const planListFiltered = filterPlansForTermsNG4(planListUnfiltered, searchTerms, role);
  const planListFormatted = getFarmerStructuredDataNG4(planListUnfiltered, planListFiltered);
  return planListFormatted;
};

export const getPlansWithNoCropRotation = (plans) =>
  plans.filter(
    ({
      paramsOut: {
        planName: { parentId, cropRotationId },
      },
    }) => parentId === null && cropRotationId === null,
  );

export const getPlansWithNoCropRotationNG4 = (plans) =>
  plans.filter(
    ({ parentId, cropRotationId }) =>
      (parentId === null && cropRotationId === null) ||
      (parentId === undefined && cropRotationId === undefined),
  );

export const getPlansStateWithoutPlotNG4 = (payload, data) => {
  if (!data) {
    return data;
  }
  const filteredPlans = cloneDeep(data).raw.filter(({ plot: { id } }) => id !== payload.plotId);
  const newData = {
    raw: filteredPlans,
    structured: getFarmerStructuredDataFilteredNG4(filteredPlans, '', payload.role),
  };
  return newData;
};

export const getPlansStateWithoutPlanWithId = (payload, data) => {
  const filteredPlans = cloneDeep(data).raw.filter(
    ({ paramsOut: { id } }) => id !== payload.planId,
  );
  const structuredPlans = getFarmerStructuredDataFiltered(
    getPlansWithNoCropRotation(filteredPlans),
    '',
    payload.lang,
    payload.role,
  );
  const newData = {
    raw: filteredPlans,
    structured: structuredPlans,
  };
  return newData;
};

export const getPlansStateWithoutPlanWithIdNG4 = (payload, data) => {
  const filteredPlans = cloneDeep(data).raw.filter(({ id }) => id !== payload.planId);
  const structuredPlans = getFarmerStructuredDataFilteredNG4(
    getPlansWithNoCropRotationNG4(filteredPlans),
    '',
    payload.role,
  );
  const newData = {
    raw: filteredPlans,
    structured: structuredPlans,
  };
  return newData;
};

export const buildStructuredData = (planList) => {
  let structuredData = {};
  planList.forEach(
    ({
      paramsIn: { harvestingYear },
      paramsOut: {
        applicationTimeline,
        id,
        planName: { cropName, farmName, farmersName, plotName, plotSize, logoId },
      },
    }) => {
      const products = {};
      applicationTimeline.forEach(({ selected, recommendation }) => {
        let product = selected == null ? recommendation.product : selected.product;
        const productQuantity = selected == null ? recommendation.quantity : selected.quantity;

        product = {
          ...product,
          totalQuantity: productQuantity * plotSize,
          farmer: farmersName,
          farm: farmName,
          crop: cropName,
          plot: plotName,
        };

        if (!products[product.id]) products[product.id] = product;
        else products[product.id].totalQuantity += product.totalQuantity;
      });

      structuredData = setWith(
        structuredData,
        [harvestingYearArrayToName(harvestingYear), farmersName, farmName, cropName, id],
        {
          plotName,
          plotSize,
          products,
          logoId,
        },
      );
    },
  );
  return structuredData;
};

export const getMergedPlanList = (state, newPlans, isNG4) => {
  const rawPlanList = state.data ? state.data.raw : [];
  if (!rawPlanList) {
    return newPlans;
  }
  const key = isNG4 ? 'id' : 'paramsOut.id';
  const newPlansWithNoCropRotation = isNG4
    ? getPlansWithNoCropRotationNG4(newPlans)
    : getPlansWithNoCropRotation(newPlans);
  const mergedRawData = unionBy(newPlansWithNoCropRotation, rawPlanList, key);
  return mergedRawData;
};

export const getUpdatedPlansWithPlotNG4 = (updatedPlans, plot) => {
  const updatedPlansWithPlot = updatedPlans.map((plan) => ({
    ...plan,
    plot: {
      ...plot,
    },
  }));
  return updatedPlansWithPlot;
};

export const getPlansMergedWithPlots = (plans, plots) =>
  plans.map((plan) => {
    if (typeof plan.plot === 'string') {
      const plot = plots.find((plot) => plot.id === plan.plot);
      return {
        ...plan,
        plot,
      };
    }
    return plan;
  });
