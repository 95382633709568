/* eslint-disable import/prefer-default-export */
/* eslint-disable react/prop-types */
import React from 'react';
import { PropTypes } from 'prop-types';
import { useLocalizedFromContext } from 'data/Localization';

export const Crop12 = ({ title, fill, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="59.493"
    height="66.918"
    viewBox="0 0 59.493 66.918"
    {...props}
  >
    <title>{useLocalizedFromContext(title)}</title>
    <g id="Group_1553" dataname="Group 1553" transform="translate(1.3 1.3)">
      <path
        id="Path_2464"
        dataname="Path 2464"
        d="M776.229,6527.829s1.245-29.582-12.776-39.518c0,0,5.567,2.609,5.567,39.518Z"
        transform="translate(-755.131 -6468.661)"
        fill="none"
        stroke={fill || '#849daa'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.6"
      />
      <path
        id="Path_2465"
        dataname="Path 2465"
        d="M777.093,6476.08s-.1-14.182-1.372-21.895c0,0,7.4,5.622,8.668,23.939"
        transform="translate(-762.167 -6448.633)"
        fill="none"
        stroke={fill || '#849daa'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.6"
      />
      <path
        id="Path_2466"
        dataname="Path 2466"
        d="M791.991,6488.013s-.122-31.016,15.07-47.267c0,0-7.187,24.515-7.187,49.994v9.174H792.6"
        transform="translate(-771.499 -6440.746)"
        fill="none"
        stroke={fill || '#849daa'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.6"
      />
      <path
        id="Path_2467"
        dataname="Path 2467"
        d="M810.551,6503.8s1.745-24.238,14.973-33.052c0,0-7.792,2.106-7.792,46.773h-7.255"
        transform="translate(-782.101 -6458.354)"
        fill="none"
        stroke={fill || '#849daa'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.6"
      />
      <path
        id="Path_2468"
        dataname="Path 2468"
        d="M760.914,6520.114s-6.709-9.447-10.166-9.86c0,0,5.419,7.04,5.419,30.453h5.3"
        transform="translate(-747.844 -6481.538)"
        fill="none"
        stroke={fill || '#849daa'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.6"
      />
      <path
        id="Path_2469"
        dataname="Path 2469"
        d="M854.248,6546.612s.338-10.114,9.85-13.022c0,0-4.588,4.182-4.576,20.812h-4.3"
        transform="translate(-807.205 -6495.234)"
        fill="none"
        stroke={fill || '#849daa'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.6"
      />
      <path
        id="Path_2470"
        dataname="Path 2470"
        d="M828.983,6500.686s3.212-5.314,10.8-10.066c0,0-6.812-.947-6.812,38.563h-5.64"
        transform="translate(-791.766 -6470.014)"
        fill="none"
        stroke={fill || '#849daa'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.6"
      />
      <path
        id="Path_2471"
        dataname="Path 2471"
        d="M841.9,6537.7h5.049s-.877-20.336,6.136-32.565A26.309,26.309,0,0,0,841.873,6521"
        transform="translate(-800.107 -6478.536)"
        fill="none"
        stroke={fill || '#849daa'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.6"
      />
      <path
        id="Path_2472"
        dataname="Path 2472"
        d="M794.5,6558.533H746.685s.49-10.512-2.746-17.9c0,0,7.97,4.553,7.97,8.819"
        transform="translate(-743.939 -6499.364)"
        fill="none"
        stroke={fill || '#849daa'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.6"
      />
      <rect
        id="Rectangle_1197"
        width="49.558"
        height="5.149"
        transform="translate(2.746 59.169)"
        strokeWidth="2.6"
        stroke={fill || '#849daa'}
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </g>
  </svg>
);

Crop12.defaultProps = {
  title: 'forage_grass',
};

Crop12.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
};
