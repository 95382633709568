import { getYear, isBefore } from 'date-fns';
import UserUtils from 'Utils/UserUtils';

const today = new Date();
const thisYear = getYear(today);
const lastYear = thisYear - 1;
const nextYear = thisYear + 1;

const firstOfJuneThisYear = new Date(thisYear, 5, 1);
const todayIsBeforeFirstOfJuneThisYear = isBefore(today, firstOfJuneThisYear);

const firstOfSeptemberThisYear = new Date(thisYear, 8, 1);
const todayIsBeforeFirstOfSeptemberThisYear = isBefore(today, firstOfSeptemberThisYear);

export const getCurrentHarvestingYearArray = () => {
  if (todayIsBeforeFirstOfJuneThisYear) {
    return [lastYear, thisYear];
  }
  return [thisYear, nextYear];
};
export const getHarvestingYearRange = () => [lastYear, lastYear + 10];

export const getCurrentHarvestingYear = () => {
  if (UserUtils.isNG4()) {
    if (todayIsBeforeFirstOfSeptemberThisYear) {
      return {
        label: `${thisYear}`,
        value: thisYear,
      };
    }
    return {
      label: `${nextYear}`,
      value: nextYear,
    };
  }
  if (todayIsBeforeFirstOfJuneThisYear) {
    return {
      label: `${lastYear} - ${thisYear}`,
      value: [lastYear, thisYear],
    };
  }
  return {
    label: `${thisYear} - ${nextYear}`,
    value: [thisYear, nextYear],
  };
};

export const getNextHarvestingYear = () => {
  if (UserUtils.isNG4()) {
    return {
      label: `${nextYear}`,
      value: nextYear,
    };
  }
  if (todayIsBeforeFirstOfJuneThisYear) {
    return {
      label: `${thisYear} - ${nextYear}`,
      value: [thisYear, nextYear],
    };
  }
  const inTwoYears = nextYear + 1;
  return {
    label: `${nextYear} - ${inTwoYears}`,
    value: [nextYear, inTwoYears],
  };
};

export const harvestingYearArrayToName = (harvestingYearArray) => {
  if (harvestingYearArray.length === 2) {
    return harvestingYearArray.join(' - ');
  }
  console.error('Invalid harvesting array');
  return 'Invalid harvesting array';
};

export const harvestingYearArrayToNameNG4 = (harvestingYearArray) => {
  if (harvestingYearArray.length === 2) {
    return harvestingYearArray[1];
  }
  console.error('Invalid harvesting array');
  return 'Invalid harvesting array';
};
