import {
  UPDATE_METRO,
  ATTEMPT_PLOT_NEXT,
  ATTEMPT_CROP_NEXT,
  ATTEMPT_USAGE_NEXT,
  ATTEMPT_SOIL_NEXT,
  ATTEMPT_NUTRIENT_NEXT,
  SELECT_PLAN_SECTION,
  SELECT_APPLICATION_INDEX,
  SELECT_HARVESTING_YEAR,
  SELECT_OVERVIEW_SECTION,
  SELECT_OVERVIEW_MONTH,
  RESET_UI,
  TOGGLE_NAVIGATION,
  TOGGLE_ACCEPT_TERMS,
  TERMS_SCROLLED_TO_BOTTOM,
} from './types';

export const updateMetro = (update) => ({
  type: UPDATE_METRO,
  payload: update,
});

export const attemptPlotNext = () => ({
  type: ATTEMPT_PLOT_NEXT,
});

export const attemptCropNext = () => ({
  type: ATTEMPT_CROP_NEXT,
});

export const attemptUsageNext = () => ({
  type: ATTEMPT_USAGE_NEXT,
});

export const attemptSoilNext = () => ({
  type: ATTEMPT_SOIL_NEXT,
});

export const attemptNutrientNext = () => ({
  type: ATTEMPT_NUTRIENT_NEXT,
});

export const updateSelectedPlanSection = (update) => ({
  type: SELECT_PLAN_SECTION,
  payload: update,
});

export const updateSelectedApplicationIndex = (update) => ({
  type: SELECT_APPLICATION_INDEX,
  payload: update,
});

export const updateSelectedHarvestingYear = (update) => ({
  type: SELECT_HARVESTING_YEAR,
  payload: update,
});

export const updateSelectedOverviewSection = (update) => ({
  type: SELECT_OVERVIEW_SECTION,
  payload: update,
});

export const updateSelectedOverviewMonth = (update) => ({
  type: SELECT_OVERVIEW_MONTH,
  payload: update,
});

export const resetUI = () => ({
  type: RESET_UI,
});

export const toggleNavigation = (update) => ({
  type: TOGGLE_NAVIGATION,
  payload: update,
});

export const toggleAcceptTerms = (update) => ({
  type: TOGGLE_ACCEPT_TERMS,
  payload: update,
});

export const watchTermsAndConditionScroll = (update) => ({
  type: TERMS_SCROLLED_TO_BOTTOM,
  payload: update,
});
