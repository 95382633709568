/* eslint-disable import/prefer-default-export */
import { SET_LAST_USED } from 'Redux/actions/types';
import { store } from 'Redux/createStore';

export const setLastUsed = (value) => {
  store.dispatch({
    type: SET_LAST_USED,
    payload: value,
  });
};
