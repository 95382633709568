/* eslint-disable import/prefer-default-export */
/* eslint-disable react/prop-types */
import React from 'react';
import { PropTypes } from 'prop-types';
import { useLocalizedFromContext } from 'data/Localization';

export const Crop17 = ({ title, fill, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="128.5"
    height="158.882"
    viewBox="0 0 128.5 158.882"
    {...props}
  >
    <title>{useLocalizedFromContext(title)}</title>
    <g id="Component_3_1" dataname="Component 3 – 1" transform="translate(2.549 2.5)">
      <g id="Group_64" dataname="Group 64" transform="translate(-1582.527 -5202.965)">
        <path
          id="Path_309"
          dataname="Path 309"
          d="M1644.217,5218.164a15.2,15.2,0,0,0-30.4,0s-.679,13.672,17.067,30.841c6.4,6.186,13.332,13.091,13.332,13.091"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
        <path
          id="Path_310"
          dataname="Path 310"
          d="M1644.217,5260.012v-41.848a15.2,15.2,0,0,1,30.4,0s.679,13.672-17.068,30.841c-6.394,6.186-13.332,13.091-13.332,13.091"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
      </g>
      <g id="Group_65" dataname="Group 65" transform="translate(-1582.527 -5202.965)">
        <path
          id="Path_311"
          dataname="Path 311"
          d="M1685.793,5276.925a15.2,15.2,0,1,1-9.863,28.754s-13.152-3.793-23.634-26.15c-3.777-8.056-8.057-16.859-8.057-16.859"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
        <path
          id="Path_312"
          dataname="Path 312"
          d="M1646.21,5263.347l39.583,13.578a15.2,15.2,0,1,0,9.864-28.755s-12.711-5.078-34.709,6.137c-7.927,4.042-16.709,8.363-16.709,8.363"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
      </g>
      <g id="Group_66" dataname="Group 66" transform="translate(-1582.527 -5202.965)">
        <path
          id="Path_313"
          dataname="Path 313"
          d="M1602.662,5276.925a15.2,15.2,0,0,0,9.864,28.754s13.152-3.793,23.634-26.15c3.777-8.056,8.057-16.859,8.057-16.859"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
        <path
          id="Path_314"
          dataname="Path 314"
          d="M1642.246,5263.347l-39.584,13.578a15.2,15.2,0,1,1-9.863-28.755s12.711-5.078,34.709,6.137c7.927,4.042,16.709,8.363,16.709,8.363"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
      </g>
      <line
        id="Line_19"
        dataname="Line 19"
        y2="93.734"
        transform="translate(61.69 60.148)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
    </g>
  </svg>
);

Crop17.defaultProps = {
  title: 'clover',
};

Crop17.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
};
