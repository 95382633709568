import {
    landingPageVideo1DE,
    landingPageVideo2DE,
    landingPageVideo1DEWebP,
    landingPageVideo2DEWebP,
    farmdokENMockAppleMobile,
    farmdokENMockAppleMobileWebP,
} from 'assets/images';
import { NutriGuide_user_manual_EN_LAT } from 'assets/pdf';
import PhoneAT from 'components/Icons/phones/PhoneAT';
import { nutriguideStorageURL } from 'data/Config';
import { fileRouteConstants, privacyRouteConstants, termsRouteConstants } from 'constants/RoutesConstants';

// MARK: - LocaleDependentBehaviours

export const sk_SK = {
    borealis_lat_localization: '/sk/sk/',
    farmdok_mock_apple_mobile: farmdokENMockAppleMobile,
    farmdok_mock_apple_mobile_webp: farmdokENMockAppleMobileWebP,
    terms_conditions_pdf: `${nutriguideStorageURL()}/${fileRouteConstants.TERMS}/${termsRouteConstants.TERMS_SK}`,
    privacy_notice_pdf: `${nutriguideStorageURL()}/${fileRouteConstants.TERMS}/${privacyRouteConstants.PRIVACY_SK}`,
    number_locales_code: 'sk-SK',
    xml_upload_instructions_pdf: false,
    user_manual_pdf: NutriGuide_user_manual_EN_LAT,
};

// MARK: - Behaviours

export const SK = {
    is_letters_available_in_postalcode: true,
    intro_youtube_video_id: 'Yl8Wiy6IqFs',
    landing_page_top_video_id: 'zsB77jd_H_E',
    landing_page_second_top_video_id: 'u6aCUjrlj-o',
    postal_code_length: 5,
    grass: false,
    n_mineralization: false,
    cover_crop_details: false,
    fertilizer_period: false,
    fertilizer_lasttime: false,
    soil_type: false,
    work_your_soil: false,
    periodic_el_info: false,
    soil_heaviness: true,
    soil_organic_matter_level: true,
    soil_analysis: true,
    applied_element_year: 0,
    show_applied_element_year: false,
    soil_depth_id: 5,
    add_product: false,
    my_plots_functionalities: true,
    has_options_for_cover_crop_details: false,
    upload_xml: true,
    get_plots_from_3rd_party: false,
    can_select_postal_code_and_city_for_entire_farm_plots: false,
    can_use_map_and_postal_code_for_city_selection: false,
    crop_rotation: true,
    farmer_can_change_product: true,
    available_crops_id: [1, 2, 4, 10, 11, 5, 6, 7, 8, 9],
    all_crops_presented: true, // Option to present all crop icons at landing page
    only_for_bavaria_region: false,
    only_long_flow_supported: false,
    has_cover_crop_question: true,
    available_crops_v3: [
        {
        name: 'cereal',
        logoId: 'Lp3',
        },
        {
        name: 'rape_seed',
        logoId: 'Lp1',
        },
        {
        name: 'maize',
        logoId: 37,
        },
        {
        name: 'potato',
        logoId: 6,
        },
        {
        name: 'sunflower',
        logoId: 7,
        },
        {
        name: 'sugar_beet',
        logoId: 9,
        },
        {
        name: 'forage_grass',
        logoId: 12,
        },
    ],
    reseller_farmer_video: true,
    has_farmDok_slide: false,
    fachbeirat_logo: false,
    phone: PhoneAT,
    product_category_filter: false,
    spreader_links: {
        sulky: 'http://fertitest.sulky-burel.com/FertiKli/?lng=ge',
        amazone: 'https://amazone.de/78.asp',
        kuhn: 'https://streutabellen.rauch-community.de/kuhntabelle/?lang=49',
        bogballe: 'http://www.bogballe-charts.com/spreader.asp?S=4',
        kverneland: 'https://www.kvernelandspreadingcharts.com',
        vicon: 'https://www.viconspreadingcharts.com/vicon/home/',
        rauch: 'https://streutabellen.rauch-community.de/streutabelle/index.php?lang=44&mass=met',
    },
    map_options: {
        defaultCenter: {
            lat: 48.66,
            lng: 19.69,
        },
        zoom: 7,
    },
    xml_files_download_link: false,
    fertilizer_needs: true,
    my_applications: true,
    landing_page_video_1: landingPageVideo1DE,
    landing_page_video_1_webp: landingPageVideo1DEWebP,
    landing_page_video_2: landingPageVideo2DE,
    landing_page_video_2_webp: landingPageVideo2DEWebP,
    landing_page_yt_video_1: 'h7dk_1fPfMI',
    landing_page_yt_video_2: 'xnyfCJ1ES9Q',
    supported_file_types: 'application/zip',
    promotion_image: null,
    refer_a_farmer_functionality: false,
    empty_soil: {
        soilFactorP: 1,
        soilFactorK: 1,
        nMineralization: 0,
        nMin: 50,
    },
    has_n_min: true,
    soil_class_classification_presented: false,
    application_map_functionality: true,
    application_map_functionality_not_working: false,
    shouldShowChangeFormulae_in_all_steps: true,
    has_nutrizone: false,
    nutrizone_brand_video_id: null,
    nutrizone_testimonial_video_id: null,
    get_postal_code_on_xml_import: false,
    tutorial_videos_link: '',
    nmineralization_humus_option: false,
    has_organic_fertilization_in_the_previous_year_option: false,
    has_red_zone: false,
    only_soil_classification_letters_presented: false,
    has_no_options_for_nMineralization: false,
    eAMA_text: true,
    click_here_text: true,
    want_to_set_N_upper_limit: true,
    try_nutriguide_button_visibility: true,
    modified_long_flow_supported: false,
};
