/* eslint-disable import/prefer-default-export */
/* eslint-disable react/prop-types */
import React from 'react';
import { PropTypes } from 'prop-types';
import { useLocalizedFromContext } from 'data/Localization';

export const GradientCrop22 = ({ title, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="45.776"
    height="88.462"
    viewBox="0 0 45.776 88.462"
    {...props}
  >
    <title>{useLocalizedFromContext(title)}</title>
    <defs>
      <clipPath id="clip-path22">
        <path
          id="Path_196"
          dataname="Path 196"
          d="M680.564,4.488C679.627,3.005,677.856.7,675.6.134a4.265,4.265,0,0,0-3.585.7c-1.377.968-4.19,4.4-7.16,9.84a1.46,1.46,0,0,0-.347.642c-.373.7-.747,1.432-1.121,2.193a1.493,1.493,0,0,0-.264.534,84.005,84.005,0,0,0-4.138,10.342l-.874-4.293a1.5,1.5,0,0,0-2.356-.911l-5.6,4.1a1.493,1.493,0,0,0-.338.355,1.494,1.494,0,0,0-.341.243c-4.913,4.807-6.343,16.912-2.685,22.723a1.5,1.5,0,0,0,1.094.69,1.285,1.285,0,0,0,.175.011,1.5,1.5,0,0,0,1.049-.428c4.6-4.5,6.146-15.4,3.321-21.541l3.209-2.352,1.558,7.656a89.5,89.5,0,0,0-2.579,23.74c.592,23.206,1.359,32.613,1.367,32.7a1.5,1.5,0,0,0,1.493,1.375c.042,0,.084,0,.127,0a1.5,1.5,0,0,0,1.371-1.621c-.008-.09-.77-9.411-1.358-32.531-.036-1.4-.029-2.776,0-4.134l3.049,4.068c-1.111,6.722,3.882,17.068,9.83,19.969a1.5,1.5,0,0,0,2.114-.99c1.616-6.572-3.368-17.358-9.414-20.564a1.51,1.51,0,0,0-.129-.213l-5.229-6.976a87.322,87.322,0,0,1,2.255-14.3,1.505,1.505,0,0,0,.108-.443,84.033,84.033,0,0,1,2.777-8.967l-.075,6.1c-3.968,5.557-3.857,17.222.346,22.417a1.5,1.5,0,0,0,1.166.556h0a1.5,1.5,0,0,0,1.167-.559c4.189-5.2,4.283-16.848.319-22.4l.164-13.3c.054-.109.108-.223.162-.331l2.788,3.054c-2.6,6.268-.428,17.26,4.488,21.525a1.5,1.5,0,0,0,2.3-.411c3.3-6.025,1.163-18.025-4.023-22.525l0,0c-.008-.009-.009-.021-.017-.03l-4.04-4.426c2.518-4.486,4.836-7.31,6.017-8.14a1.282,1.282,0,0,1,1.124-.248c1.206.3,2.559,2.021,3.361,3.372-.972,6.726,3.983,16.89,9.868,19.76a1.5,1.5,0,0,0,.657.152,1.5,1.5,0,0,0,1.457-1.142C691.844,18.529,686.713,7.536,680.564,4.488ZM648.4,42.938a21.011,21.011,0,0,1,1.788-15.114A20.991,20.991,0,0,1,648.4,42.938Zm21.532,27.307a21,21,0,0,1-6.448-13.786A20.993,20.993,0,0,1,669.933,70.245Zm-5.517-23.8a21,21,0,0,1-.012-15.22A20.991,20.991,0,0,1,664.416,46.448Zm9.567-11.6a20.993,20.993,0,0,1-2.672-14.984A21,21,0,0,1,673.983,34.848Zm7.1-26.425a21,21,0,0,1,6.448,13.786A21,21,0,0,1,681.079,8.423Z"
          fill="none"
        />
      </clipPath>
    </defs>
    <g id="Group_78" dataname="Group 78" transform="translate(-644.74 0)">
      <g id="Group_77" dataname="Group 77" clipPath="url(#clip-path22)">
        <g id="Group_76" dataname="Group 76">
          <path
            id="Path_193"
            dataname="Path 193"
            d="M709.75,88.462V53.44L625.568,73.159v15.3Z"
            fill="#5f9501"
          />
          <path
            id="Path_194"
            dataname="Path 194"
            d="M625.568,34.492V73.159L709.75,53.44V14.774Z"
            fill="#79b61d"
          />
          <path
            id="Path_195"
            dataname="Path 195"
            d="M625.568.006V34.492L709.75,14.774V.006Z"
            fill="#c8d200"
          />
        </g>
      </g>
    </g>
  </svg>
);

GradientCrop22.defaultProps = {
  title: 'oats',
};

GradientCrop22.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
};
