/* eslint-disable import/prefer-default-export */
/* eslint-disable react/prop-types */
import React from 'react';

export const CropLp1 = ({ title, fill, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="44.664"
    height="66.896"
    viewBox="0 0 44.664 66.896"
    {...props}
  >
    <g id="Group_1548" dataname="Group 1548" transform="translate(1.3 1.3)">
      <g id="Group_1542" dataname="Group 1542" transform="translate(0 24.219)">
        <line
          id="Line_498"
          dataname="Line 498"
          y2="36.973"
          transform="translate(21.032 3.104)"
          fill="none"
          stroke="#849daa"
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="2.6"
        />
        <g id="Group_1540" dataname="Group 1540" transform="translate(29.245 9.864)">
          <path
            id="Path_2401"
            dataname="Path 2401"
            d="M842.933,4679.486a2.41,2.41,0,0,1-4.818,0c0-1.29,2.409-3.875,2.409-3.875S842.933,4678.2,842.933,4679.486Z"
            transform="translate(-834.115 -4669.402)"
            fill="none"
            stroke="#849daa"
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="2.6"
          />
          <path
            id="Path_2402"
            dataname="Path 2402"
            d="M847.534,4670.192a2.335,2.335,0,1,1,0,4.667c-1.33,0-4-2.333-4-2.333S846.2,4670.192,847.534,4670.192Z"
            transform="translate(-837.125 -4666.317)"
            fill="none"
            stroke="#849daa"
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="2.6"
          />
          <path
            id="Path_2403"
            dataname="Path 2403"
            d="M838.115,4663.529a2.41,2.41,0,0,1,4.818,0c0,1.289-2.409,3.875-2.409,3.875S838.115,4664.818,838.115,4663.529Z"
            transform="translate(-834.115 -4661.195)"
            fill="none"
            stroke="#849daa"
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="2.6"
          />
          <path
            id="Path_2404"
            dataname="Path 2404"
            d="M831.526,4674.859a2.335,2.335,0,1,1,0-4.667c1.33,0,4,2.333,4,2.333S832.857,4674.859,831.526,4674.859Z"
            transform="translate(-829.117 -4666.317)"
            fill="none"
            stroke="#849daa"
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="2.6"
          />
        </g>
        <g id="Group_1541" dataname="Group 1541" transform="translate(0 0)">
          <path
            id="Path_2405"
            dataname="Path 2405"
            d="M777.148,4656.582a2.41,2.41,0,0,1-4.818,0c0-1.289,2.409-3.875,2.409-3.875S777.148,4655.293,777.148,4656.582Z"
            transform="translate(-768.33 -4646.498)"
            fill="none"
            stroke="#849daa"
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="2.6"
          />
          <path
            id="Path_2406"
            dataname="Path 2406"
            d="M781.749,4647.288a2.335,2.335,0,1,1,0,4.667c-1.331,0-4-2.334-4-2.334S780.418,4647.288,781.749,4647.288Z"
            transform="translate(-771.34 -4643.413)"
            fill="none"
            stroke="#849daa"
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="2.6"
          />
          <path
            id="Path_2407"
            dataname="Path 2407"
            d="M772.33,4640.625a2.41,2.41,0,0,1,4.818,0c0,1.289-2.409,3.875-2.409,3.875S772.33,4641.914,772.33,4640.625Z"
            transform="translate(-768.33 -4638.291)"
            fill="none"
            stroke="#849daa"
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="2.6"
          />
          <path
            id="Path_2408"
            dataname="Path 2408"
            d="M765.741,4651.955a2.335,2.335,0,1,1,0-4.667c1.33,0,4,2.333,4,2.333S767.071,4651.955,765.741,4651.955Z"
            transform="translate(-763.332 -4643.413)"
            fill="none"
            stroke="#849daa"
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="2.6"
          />
        </g>
        <line
          id="Line_499"
          dataname="Line 499"
          x1="8.214"
          transform="translate(12.818 6.208)"
          fill="none"
          stroke="#849daa"
          strokeMiterlimit="10"
          strokeWidth="2.6"
        />
        <line
          id="Line_500"
          dataname="Line 500"
          x2="8.214"
          transform="translate(21.032 16.072)"
          fill="none"
          stroke="#849daa"
          strokeMiterlimit="10"
          strokeWidth="2.6"
        />
      </g>
      <g id="Group_1547" dataname="Group 1547" transform="translate(8.032)">
        <line
          id="Line_501"
          dataname="Line 501"
          y2="5.452"
          transform="translate(13 25.185)"
          fill="none"
          stroke="#849daa"
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="2.6"
        />
        <g id="Group_1543" dataname="Group 1543" transform="translate(6.502)">
          <path
            id="Path_2409"
            dataname="Path 2409"
            d="M810.033,4600.6a2.444,2.444,0,0,1-4.885,0c0-1.307,2.443-3.929,2.443-3.929S810.033,4599.291,810.033,4600.6Z"
            transform="translate(-801.093 -4590.375)"
            fill="none"
            stroke="#849daa"
            strokeMiterlimit="10"
            strokeWidth="2.6"
          />
          <path
            id="Path_2410"
            dataname="Path 2410"
            d="M814.7,4591.174a2.367,2.367,0,1,1,0,4.732c-1.349,0-4.055-2.366-4.055-2.366S813.349,4591.174,814.7,4591.174Z"
            transform="translate(-804.145 -4587.246)"
            fill="none"
            stroke="#849daa"
            strokeMiterlimit="10"
            strokeWidth="2.6"
          />
          <path
            id="Path_2411"
            dataname="Path 2411"
            d="M805.148,4584.419a2.444,2.444,0,0,1,4.885,0c0,1.307-2.442,3.929-2.442,3.929S805.148,4585.727,805.148,4584.419Z"
            transform="translate(-801.093 -4582.054)"
            fill="none"
            stroke="#849daa"
            strokeMiterlimit="10"
            strokeWidth="2.6"
          />
          <path
            id="Path_2412"
            dataname="Path 2412"
            d="M798.468,4595.907a2.367,2.367,0,1,1,0-4.732c1.349,0,4.056,2.366,4.056,2.366S799.818,4595.907,798.468,4595.907Z"
            transform="translate(-796.026 -4587.246)"
            fill="none"
            stroke="#849daa"
            strokeMiterlimit="10"
            strokeWidth="2.6"
          />
        </g>
        <g id="Group_1544" dataname="Group 1544" transform="translate(10.558 12.596)">
          <path
            id="Path_2413"
            dataname="Path 2413"
            d="M810.033,4629.846a2.444,2.444,0,0,1-4.885,0c0-1.307,2.443-3.929,2.443-3.929S810.033,4628.539,810.033,4629.846Z"
            transform="translate(-805.148 -4619.623)"
            fill="none"
            stroke="#849daa"
            strokeMiterlimit="10"
            strokeWidth="2.6"
          />
          <path
            id="Path_2414"
            dataname="Path 2414"
            d="M805.148,4613.667a2.444,2.444,0,0,1,4.885,0c0,1.307-2.442,3.929-2.442,3.929S805.148,4614.974,805.148,4613.667Z"
            transform="translate(-805.148 -4611.301)"
            fill="none"
            stroke="#849daa"
            strokeMiterlimit="10"
            strokeWidth="2.6"
          />
        </g>
        <g id="Group_1545" dataname="Group 1545" transform="translate(15.383 9.906)">
          <path
            id="Path_2415"
            dataname="Path 2415"
            d="M820.171,4621.452a2.5,2.5,0,0,1-3.454,0,2.313,2.313,0,0,1,0-3.346c.954-.924,4.594-1.1,4.594-1.1S821.125,4620.527,820.171,4621.452Z"
            transform="translate(-816.001 -4611.857)"
            fill="none"
            stroke="#849daa"
            strokeMiterlimit="10"
            strokeWidth="2.6"
          />
          <path
            id="Path_2416"
            dataname="Path 2416"
            d="M832.54,4618.106a2.313,2.313,0,0,1,0,3.346,2.5,2.5,0,0,1-3.454,0c-.954-.924-1.14-4.45-1.14-4.45S831.587,4617.182,832.54,4618.106Z"
            transform="translate(-822.636 -4611.857)"
            fill="none"
            stroke="#849daa"
            strokeMiterlimit="10"
            strokeWidth="2.6"
          />
          <path
            id="Path_2417"
            dataname="Path 2417"
            d="M829.086,4605.749a2.5,2.5,0,0,1,3.454,0,2.314,2.314,0,0,1,0,3.347c-.954.924-4.595,1.1-4.595,1.1S828.133,4606.673,829.086,4605.749Z"
            transform="translate(-822.636 -4605.056)"
            fill="none"
            stroke="#849daa"
            strokeMiterlimit="10"
            strokeWidth="2.6"
          />
          <path
            id="Path_2418"
            dataname="Path 2418"
            d="M816.717,4609.1a2.314,2.314,0,0,1,0-3.347,2.5,2.5,0,0,1,3.454,0c.954.925,1.14,4.452,1.14,4.452S817.671,4610.019,816.717,4609.1Z"
            transform="translate(-816.001 -4605.056)"
            fill="none"
            stroke="#849daa"
            strokeMiterlimit="10"
            strokeWidth="2.6"
          />
        </g>
        <g id="Group_1546" dataname="Group 1546" transform="translate(0 9.906)">
          <path
            id="Path_2419"
            dataname="Path 2419"
            d="M785.569,4621.452a2.5,2.5,0,0,1-3.454,0,2.314,2.314,0,0,1,0-3.346c.954-.924,4.595-1.1,4.595-1.1S786.523,4620.527,785.569,4621.452Z"
            transform="translate(-781.399 -4611.857)"
            fill="none"
            stroke="#849daa"
            strokeMiterlimit="10"
            strokeWidth="2.6"
          />
          <path
            id="Path_2420"
            dataname="Path 2420"
            d="M797.939,4618.106a2.313,2.313,0,0,1,0,3.346,2.5,2.5,0,0,1-3.454,0c-.954-.924-1.14-4.45-1.14-4.45S796.985,4617.182,797.939,4618.106Z"
            transform="translate(-788.034 -4611.857)"
            fill="none"
            stroke="#849daa"
            strokeMiterlimit="10"
            strokeWidth="2.6"
          />
          <path
            id="Path_2421"
            dataname="Path 2421"
            d="M794.484,4605.749a2.5,2.5,0,0,1,3.454,0,2.314,2.314,0,0,1,0,3.347c-.954.924-4.594,1.1-4.594,1.1S793.531,4606.673,794.484,4605.749Z"
            transform="translate(-788.034 -4605.056)"
            fill="none"
            stroke="#849daa"
            strokeMiterlimit="10"
            strokeWidth="2.6"
          />
          <path
            id="Path_2422"
            dataname="Path 2422"
            d="M782.115,4609.1a2.315,2.315,0,0,1,0-3.347,2.5,2.5,0,0,1,3.454,0c.954.925,1.14,4.452,1.14,4.452S783.069,4610.019,782.115,4609.1Z"
            transform="translate(-781.399 -4605.056)"
            fill="none"
            stroke="#849daa"
            strokeMiterlimit="10"
            strokeWidth="2.6"
          />
        </g>
      </g>
    </g>
  </svg>
);
