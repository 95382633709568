/* eslint-disable react/prop-types */
import React from 'react';

const Austria = (props) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    space="preserve"
    {...props}
  >
    <path
      fill="#F0F0F0"
      d="M496.077,345.043C506.368,317.311,512,287.314,512,256s-5.632-61.309-15.923-89.043L256,144.696
    L15.923,166.957C5.633,194.691,0,224.686,0,256s5.633,61.311,15.923,89.043L256,367.304L496.077,345.043z"
    />
    <g>
      <path
        fill="#D80027"
        d="M256,512c110.07,0,203.906-69.472,240.076-166.957H15.922C52.094,442.528,145.928,512,256,512z"
      />
      <path
        fill="#D80027"
        d="M256,0C145.928,0,52.094,69.472,15.922,166.957h480.155C459.906,69.472,366.07,0,256,0z"
      />
    </g>
  </svg>
);

export default Austria;
