// Web Actions
export const GET_TERMS_LOADING = 'GET_TERMS_LOADING ';
export const GET_TERMS_SUCCESS = 'GET_TERMS_SUCCESS';
export const GET_TERMS_FAILED = 'GET_TERMS_FAILED ';
export const RESET_TERMS = 'RESET_TERMS';

export const GET_ORGANIZATION_LOADING = 'GET_ORGANIZATION_LOADING';
export const GET_ORGANIZATION_SUCCESS = 'GET_ORGANIZATION_SUCCESS';
export const GET_ORGANIZATION_FAILED = 'GET_ORGANIZATION_FAILED';

export const GET_APPLICATION_STEPS_LOADING = 'GET_APPLICATION_STEPS_LOADING';
export const GET_APPLICATION_STEPS_SUCCESS = 'GET_APPLICATION_STEPS_SUCCESS';
export const GET_APPLICATION_STEPS_FAILED = 'GET_APPLICATION_STEPS_FAILED';

export const GET_CITY_LIST_LOADING = 'GET_CITY_LIST_LOADING';
export const GET_CITY_LIST_SUCCESS = 'GET_CITY_LIST_SUCCESS';
export const GET_CITY_LIST_FAILED = 'GET_CITY_LIST_FAILED';
export const SET_CITY_LIST = 'SET_CITY_LIST';

export const POST_USER_LOADING = 'POST_USER_LOADING';
export const POST_USER_SUCCESS = 'POST_USER_SUCCESS';
export const POST_USER_FAILED = 'POST_USER_FAILED';

export const PUT_USER_LOADING = 'PUT_USER_LOADING';
export const PUT_USER_SUCCESS = 'PUT_USER_SUCCESS';
export const PUT_USER_FAILED = 'PUT_USER_FAILED';

export const GET_USER_LOADING = 'GET_USER_LOADING';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAILED = 'GET_USER_FAILED';

export const POST_PLAN_LOADING = 'POST_PLAN_LOADING';
export const POST_PLAN_SUCCESS = 'POST_PLAN_SUCCESS';
export const POST_PLAN_FAILED = 'POST_PLAN_FAILED';

export const POST_PLAN_BULK_LOADING = 'POST_PLAN_BULK_LOADING';
export const POST_PLAN_BULK_SUCCESS = 'POST_PLAN_BULK_SUCCESS';
export const POST_PLAN_BULK_FAILED = 'POST_PLAN_BULK_FAILED';

export const PUT_PLAN_LOADING = 'PUT_PLAN_LOADING';
export const PUT_PLAN_SUCCESS = 'PUT_PLAN_SUCCESS';
export const PUT_PLAN_FAILED = 'PUT_PLAN_FAILED';

export const GET_CROP_LIST_LOADING = 'GET_CROP_LIST_LOADING';
export const GET_CROP_LIST_SUCCESS = 'GET_CROP_LIST_SUCCESS';
export const GET_CROP_LIST_FAILED = 'GET_CROP_LIST_FAILED';

export const GET_ORGANIC_FERTILIZER_LIST_LOADING = 'GET_ORGANIC_FERTILIZER_LIST_LOADING';
export const GET_ORGANIC_FERTILIZER_LIST_SUCCESS = 'GET_ORGANIC_FERTILIZER_LIST_SUCCESS';
export const GET_ORGANIC_FERTILIZER_LIST_FAILED = 'GET_ORGANIC_FERTILIZER_LIST_FAILED';
export const POST_ORGANIC_FERTILIZER_LOADING = 'POST_ORGANIC_FERTILIZER_LOADING';
export const POST_ORGANIC_FERTILIZER_SUCCESS = 'POST_ORGANIC_FERTILIZER_SUCCESS';
export const POST_ORGANIC_FERTILIZER_FAILED = 'POST_ORGANIC_FERTILIZER_FAILED';
export const PUT_ORGANIC_FERTILIZER_LOADING = 'PUT_ORGANIC_FERTILIZER_LOADING';
export const PUT_ORGANIC_FERTILIZER_SUCCESS = 'PUT_ORGANIC_FERTILIZER_SUCCESS';
export const PUT_ORGANIC_FERTILIZER_FAILED = 'PUT_ORGANIC_FERTILIZER_FAILED';
export const DELETE_ORGANIC_FERTILIZER_LOADING = 'DELETE_ORGANIC_FERTILIZER_LOADING';
export const DELETE_ORGANIC_FERTILIZER_SUCCESS = 'DELETE_ORGANIC_FERTILIZER_SUCCESS';
export const DELETE_ORGANIC_FERTILIZER_FAILED = 'DELETE_ORGANIC_FERTILIZER_FAILED';

export const GET_SOIL_LIST_LOADING = 'GET_SOIL_LIST_LOADING';
export const GET_SOIL_LIST_SUCCESS = 'GET_SOIL_LIST_SUCCESS';
export const GET_SOIL_LIST_FAILED = 'GET_SOIL_LIST_FAILED';

export const GET_SOIL_COMPOSITION_LOADING = 'GET_SOIL_COMPOSITION_LOADING';
export const GET_SOIL_COMPOSITION_SUCCESS = 'GET_SOIL_COMPOSITION_SUCCESS';
export const GET_SOIL_COMPOSITION_FAILED = 'GET_SOIL_COMPOSITION_FAILED';

export const GET_PRODUCT_SELECT_LOADING = 'GET_PRODUCT_SELECT_LOADING';
export const GET_PRODUCT_SELECT_SUCCESS = 'GET_PRODUCT_SELECT_SUCCESS';
export const GET_PRODUCT_SELECT_FAILED = 'GET_PRODUCT_SELECT_FAILED';

export const GET_PRODUCT_LIST_LOADING = 'GET_PRODUCT_LIST_LOADING';
export const GET_PRODUCT_LIST_SUCCESS = 'GET_PRODUCT_LIST_SUCCESS';
export const GET_PRODUCT_LIST_FAILED = 'GET_PRODUCT_LIST_FAILED';

export const GET_PRODUCT_LOADING = 'GET_PRODUCT_LOADING';
export const GET_PRODUCT_SUCCESS = 'GET_PRODUCT_SUCCESS';
export const GET_PRODUCT_FAILED = 'GET_PRODUCT_FAILED';
export const RESET_PRODUCT = 'RESET_PRODUCT';

export const POST_PRODUCT_LOADING = 'POST_PRODUCT_LOADING';
export const POST_PRODUCT_SUCCESS = 'POST_PRODUCT_SUCCESS';
export const POST_PRODUCT_FAILED = 'POST_PRODUCT_FAILED';

export const PUT_PRODUCT_LOADING = 'PUT_PRODUCT_LOADING';
export const PUT_PRODUCT_SUCCESS = 'PUT_PRODUCT_SUCCESS';
export const PUT_PRODUCT_FAILED = 'PUT_PRODUCT_FAILED';

export const DELETE_PRODUCT_LOADING = 'DELETE_PRODUCT_LOADING';
export const DELETE_PRODUCT_SUCCESS = 'DELETE_PRODUCT_SUCCESS';
export const DELETE_PRODUCT_FAILED = 'DELETE_PRODUCT_FAILED';

export const GET_MY_PLANS_LOADING = 'GET_MY_PLANS_LOADING';
export const GET_MY_PLANS_SUCCESS = 'GET_MY_PLANS_SUCCESS';
export const GET_MY_PLANS_NG4_SUCCESS = 'GET_MY_PLANS_NG4_SUCCESS';
export const GET_MY_PLANS_FAILED = 'GET_MY_PLANS_FAILED';
export const RESET_PLANS = 'RESET_PLANS';
export const MERGE_PLAN = 'MERGE_PLAN';
export const MERGE_NG4_PLAN = 'MERGE_NG4_PLAN';

export const DELETE_PLAN_LOADING = 'DELETE_PLAN_LOADING';
export const DELETE_PLAN_SUCCESS = 'DELETE_PLAN_SUCCESS';
export const DELETE_PLAN_SUCCESS_NG4 = 'DELETE_PLAN_SUCCESS_NG4';
export const DELETE_PLAN_FAILED = 'DELETE_PLAN_FAILED';

export const MY_PLOTS_LOADING = 'MY_PLOTS_LOADING';
export const MY_PLOTS_SUCCESS = 'MY_PLOTS_SUCCESS';
export const MY_PLOTS_FAILED = 'MY_PLOTS_FAILED';

export const DELETE_MY_PLOT_LOADING = 'DELETE_MY_PLOT_LOADING';
export const DELETE_MY_PLOT_SUCCESS = 'DELETE_MY_PLOT_SUCCESS';
export const DELETE_MY_PLOT_FAILED = 'DELETE_MY_PLOT_FAILED';

export const DELETE_MY_PLOT_BULK_LOADING = 'DELETE_MY_PLOT_BULK_LOADING';
export const DELETE_MY_PLOT_BULK_SUCCESS = 'DELETE_MY_PLOT_BULK_SUCCESS';
export const DELETE_MY_PLOT_BULK_FAILED = 'DELETE_MY_PLOT_BULK_FAILED';

export const POST_MY_PLOT_LOADING = 'POST_MY_PLOT_LOADING';
export const POST_MY_PLOT_SUCCESS = 'POST_MY_PLOT_SUCCESS';
export const POST_MY_PLOT_FAILED = 'POST_MY_PLOT_FAILED';

export const POST_PLOT_LOADING = 'POST_PLOT_LOADING';
export const POST_PLOT_SUCCESS = 'POST_PLOT_SUCCESS';
export const POST_PLOT_FAILED = 'POST_PLOT_FAILED';

export const MY_CROP_ROTATIONS_LOADING = 'MY_CROP_ROTATIONS_LOADING';
export const MY_CROP_ROTATIONS_SUCCESS = 'MY_CROP_ROTATIONS_SUCCESS';
export const MY_CROP_ROTATIONS_SUCCESS_NG4 = 'MY_CROP_ROTATIONS_SUCCESS_NG4';
export const MY_CROP_ROTATIONS_FAILED = 'MY_CROP_ROTATIONS_FAILED';

export const DELETE_MY_CROP_ROTATION_LOADING = 'DELETE_MY_CROP_ROTATION_LOADING';
export const DELETE_MY_CROP_ROTATION_SUCCESS = 'DELETE_MY_CROP_ROTATION_SUCCESS';
export const DELETE_MY_CROP_ROTATION_FAILED = 'DELETE_MY_CROP_ROTATION_FAILED';

export const POST_MY_CROP_ROTATION_LOADING = 'POST_MY_CROP_ROTATION_LOADING';
export const POST_MY_CROP_ROTATION_SUCCESS = 'POST_MY_CROP_ROTATION_SUCCESS';
export const POST_MY_CROP_ROTATION_SUCCESS_NG4 = 'POST_MY_CROP_ROTATION_SUCCESS_NG4';
export const POST_MY_CROP_ROTATION_FAILED = 'POST_MY_CROP_ROTATION_FAILED';
export const RESET_MY_CROP_ROTATION_STATUS = 'RESET_MY_CROP_ROTATION_STATUS';

export const GET_REFERALS_LOADING = 'GET_REFERALS_LOADING';
export const GET_REFERALS_SUCCESS = 'GET_REFERALS_SUCCESS';
export const GET_REFERALS_FAILED = 'GET_REFERALS_FAILED';

export const POST_REFERALS_LOADING = 'POST_REFERALS_LOADING';
export const POST_REFERALS_SUCCESS = 'POST_REFERALS_SUCCESS';
export const POST_REFERALS_FAILED = 'POST_REFERALS_FAILED';

export const GET_TERRAZO_FIELDS_LOADING = 'GET_TERRAZO_FIELDS_LOADING';
export const GET_TERRAZO_FIELDS_SUCCESS = 'GET_TERRAZO_FIELDS_SUCCESS';
export const GET_TERRAZO_FIELDS_FAILED = 'GET_TERRAZO_FIELDS_FAILED';
export const POST_TERRAZO_FIELDS_LOADING = 'POST_TERRAZO_FIELDS_LOADING';
export const POST_TERRAZO_FIELDS_SUCCESS = 'POST_TERRAZO_FIELDS_SUCCESS';
export const POST_TERRAZO_FIELDS_FAILED = 'POST_TERRAZO_FIELDS_FAILED';
export const PATCH_TERRAZO_FIELDS_LOADING = 'PATCH_TERRAZO_FIELDS_LOADING';
export const PATCH_TERRAZO_FIELDS_SUCCESS = 'PATCH_TERRAZO_FIELDS_SUCCESS';
export const PATCH_TERRAZO_FIELDS_FAILED = 'PATCH_TERRAZO_FIELDS_FAILED';
export const DELETE_TERRAZO_FIELDS_LOADING = 'DELETE_TERRAZO_FIELDS_LOADING';
export const DELETE_TERRAZO_FIELDS_SUCCESS = 'DELETE_TERRAZO_FIELDS_SUCCESS';
export const DELETE_TERRAZO_FIELDS_FAILED = 'DELETE_TERRAZO_FIELDS_FAILED';
export const DELETE_TERRAZO_FIELDS_FOR_RESOURCE_LOADING =
  'DELETE_TERRAZO_FIELDS_FOR_RESOURCE_LOADING';
export const DELETE_TERRAZO_FIELDS_FOR_RESOURCE_SUCCESS =
  'DELETE_TERRAZO_FIELDS_FOR_RESOURCE_SUCCESS';
export const DELETE_TERRAZO_FIELDS_FOR_RESOURCE_FAILED =
  'DELETE_TERRAZO_FIELDS_FOR_RESOURCE_FAILED';
export const SET_TERRAZO_FIELDS = 'SET_TERRAZO_FIELDS';
export const RESET_TERRAZO_FIELDS = 'RESET_TERRAZO_FIELDS';

// User Actions
export const UPDATE_USER = 'UPDATE_USER';

export const CREATE_USER_ATTEMPT = 'CREATE_USER_ATTEMPT';
export const CREATE_USER_FAILED = 'CREATE_USER_FAILED';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const RESET_ACCESS_TOKEN = 'RESET_ACCESS_TOKEN';

export const RESET_USER = 'RESET_USER';

// Plan Builder
export const UPDATE_PLAN = 'UPDATE_PLAN';
export const RESET_PLAN = 'RESET_PLAN';

// To know if validation help text should appear
export const ATTEMPT_PLOT_NEXT = 'ATTEMPT_PLOT_NEXT';
export const ATTEMPT_CROP_NEXT = 'ATTEMPT_CROP_NEXT';
export const ATTEMPT_USAGE_NEXT = 'ATTEMPT_USAGE_NEXT';
export const ATTEMPT_SOIL_NEXT = 'ATTEMPT_SOIL_NEXT';
export const ATTEMPT_NUTRIENT_NEXT = 'ATTEMPT_NUTRIENT_NEXT';

// Plan Builder: UI
export const UPDATE_METRO = 'UPDATE_METRO';
export const RESET_UI = 'RESET_UI';

// My Applications
export const UPDATE_MY_APPLICATIONS_SEARCH_TERMS_NG4 = 'UPDATE_MY_APPLICATIONS_SEARCH_TERMS_NG4';

// My Plan
export const SELECT_PLAN_SECTION = 'SELECT_PLAN_SECTION';
export const SELECT_APPLICATION_INDEX = 'SELECT_APPLICATION_INDEX';
export const DELETE_PLAN_FOR_PLOT_ID_NG4 = 'DELETE_PLAN_FOR_PLOT_ID_NG4';
export const RESET_PLAN_STATUS = 'RESET_PLAN_STATUS';

// Plot Actions
export const MERGE_PLOT = 'MERGE_PLOT';
export const RESET_MY_PLOTS = 'RESET_MY_PLOTS';
export const REMOVE_TEMP_PLOTS = 'REMOVE_TEMP_PLOTS';
export const SET_MY_PLOTS_STATUS = 'SET_MY_PLOTS_STATUS';

// My Crop Rotations
export const RESET_MY_CROP_ROTATIONS = 'RESET_MY_CROP_ROTATIONS';
export const DELETE_CROP_ROTATION_FOR_PLOT_ID = 'DELETE_CROP_ROTATION_FOR_PLOT_ID';
export const SET_CROP_ROTATIONS = 'SET_CROP_ROTATIONS';

// Crop Rotation Actions
export const SET_CROP_ROTATION = 'SET_CROP_ROTATION';
export const SET_CURRENT_CROP_ROTATION_PLAN = 'SET_CURRENT_CROP_ROTATION_PLAN';
export const UPDATE_CROP_ROTATION = 'UPDATE_CROP_ROTATION';
export const UPDATE_CROP_ROTATIONS_PLAN = 'UPDATE_CROP_ROTATIONS_PLAN';
export const UPDATE_CROP_ROTATIONS_PLAN_NG4 = 'UPDATE_CROP_ROTATIONS_PLAN_NG4';
export const RESET_CROP_ROTATION = 'RESET_CROP_ROTATION';
export const SET_CROP_ROTATION_WAS_EDITED = 'SET_CROP_ROTATION_WAS_EDITED';

// My Overview
export const SELECT_OVERVIEW_SECTION = 'SELECT_OVERVIEW_SECTION';
export const SELECT_OVERVIEW_MONTH = 'SELECT_OVERVIEW_MONTH';
export const SELECT_HARVESTING_YEAR = 'SELECT_HARVESTING_YEAR';

// User interactions
export const UPDATE_UI = 'UPDATE_UI';
export const TOGGLE_NAVIGATION = 'TOGGLE_NAVIGATION';
export const RESET_MY_PLANS = 'RESET_MY_PLANS';
export const TOGGLE_ACCEPT_TERMS = 'TOGGLE_ACCEPT_TERMS';
export const TERMS_SCROLLED_TO_BOTTOM = 'TERMS_SCROLLED_TO_BOTTOM';

// Product portfolio: UI
export const UPDATE_PRODUCT_FILTERS = 'UPDATE_PRODUCT_FILTERS';

// Last used state action
export const SET_LAST_USED = 'SET_LAST_USED';

// API_OBJECT_STATES

// @DRIES: suggest to add to these...
export const STATUS_DEFAULT = 'STATUS_DEFAULT';
export const STATUS_GETTING = 'STATUS_GETTING';
export const STATUS_POSTING = 'STATUS_POSTING';
export const STATUS_PUTTING = 'STATUS_PUTTING';
export const STATUS_PATCHING = 'STATUS_PATCHING';
export const STATUS_DELETING = 'STATUS_DELETING';
export const STATUS_GET_SUCCESS = 'STATUS_GET_SUCCESS';
export const STATUS_POST_SUCCESS = 'STATUS_POST_SUCCESS';
export const STATUS_PUT_SUCCESS = 'STATUS_PUT_SUCCESS';
export const STATUS_PATCH_SUCCESS = 'STATUS_PATCH_SUCCESS';
export const STATUS_DELETE_SUCCESS = 'STATUS_DELETE_SUCCESS';
export const STATUS_GET_FAILED = 'STATUS_GET_FAILED';
export const STATUS_POST_FAILED = 'STATUS_POST_FAILED';
export const STATUS_PUT_FAILED = 'STATUS_PUT_FAILED';
export const STATUS_PATCH_FAILED = 'STATUS_PATCH_FAILED';
export const STATUS_DELETE_FAILED = 'STATUS_DELETE_FAILED';
