/* eslint-disable import/prefer-default-export */
/* eslint-disable react/prop-types */
import React from 'react';
import { PropTypes } from 'prop-types';

export const GradientCrop5 = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 59.96 87.75" {...props}>
    <title>Corn</title>
    <path
      fill="none"
      d="M30.06,4.21a3.1,3.1,0,0,0-.42,0,2.23,2.23,0,0,0-1.87,1l-.17.25a6.33,6.33,0,0,0-.67,1.13l-.4.93c-.62,1.44-1.26,2.92-1.76,4.42a38.08,38.08,0,0,0-1.48,5.39,20.6,20.6,0,0,0-.35,3.53c-.05,7.27,0,14.65,0,21.8v4a1.23,1.23,0,0,0,.26.72,31.19,31.19,0,0,1,4.88,7.09c.35.72.63.87,1.32.74a5.29,5.29,0,0,1,1.64,0c.35,0,.45,0,.6-.32a35,35,0,0,1,5.08-7.49,1.4,1.4,0,0,0,.31-.84c0-2.38,0-4.76,0-7.14l0-2.51h-1c-.25,0-.5,0-.74,0a2.12,2.12,0,1,1,.1-4.23H37V30.17H35.57a2.11,2.11,0,0,1-2.33-2.09,2.16,2.16,0,0,1,2.28-2.17c.42,0,.84,0,1.28,0H37c0-.65,0-1.29,0-1.93a30.36,30.36,0,0,0-.43-6.63c-.19-.88-.38-1.71-.59-2.52a38.4,38.4,0,0,0-3.24-8.74A2.89,2.89,0,0,0,30.06,4.21ZM32.7,28.33a2,2,0,0,1-2,1.83c-.33,0-.65,0-1,0H27.9a2.05,2.05,0,0,1-2-1.95A2,2,0,0,1,27.56,26a18.58,18.58,0,0,1,3.5,0,1.73,1.73,0,0,1,1.19.64A2.26,2.26,0,0,1,32.7,28.33Z"
    />
    <path
      fill="none"
      d="M52.45,45.59c.39-.25.79-.48,1.21-.72h0a7.08,7.08,0,0,0-2.86-.62c-2.51-.07-4.85.82-7.44,2.71A27.88,27.88,0,0,0,34.69,58c-.66,1.43-1.27,1.75-2.82,1.49-.48-.08-1-.11-1.53-.16l-.32,0h0c.06.27.11.49.17.72a58.63,58.63,0,0,1,1.63,9.5c.12,1.34.2,2.69.23,4a2.54,2.54,0,0,1-.63,1.81A2,2,0,0,1,30,76h0c-1.19,0-2-.85-2.07-2.3l-.11-1.5c-.05-.6-.09-1.2-.14-1.81-.12-1.57-.27-3.16-.51-4.7A28,28,0,0,0,23.27,54.3c-2.64-4-5.17-6.55-8.22-8.33a10.26,10.26,0,0,0-6.77-1.65,10.38,10.38,0,0,0-1.82.47l0,0A26,26,0,0,1,11.74,49c6.29,6.56,9.66,14.12,10,22.53a35.91,35.91,0,0,1,0,3.88,1.33,1.33,0,0,0,.19.78,9.9,9.9,0,0,0,15.31,1.15,2.51,2.51,0,0,0,.9-2.57,2.14,2.14,0,0,1-.06-.6V74a30.58,30.58,0,0,1,.34-5.73A35,35,0,0,1,42,57.41,29.79,29.79,0,0,1,52.45,45.59Z"
    />
    <path
      fill="#5f9501"
      d="M23.14,82.81a6,6,0,0,0,.78.35,2.05,2.05,0,0,1,1,.56A2.43,2.43,0,0,1,25,85a7.68,7.68,0,0,0,0,.82,2,2,0,0,0,2,2h6a2,2,0,0,0,2-1.93,16.81,16.81,0,0,0,0-1.74c0-.25,0-.33.35-.47A13.85,13.85,0,0,0,42,77.89a3.75,3.75,0,0,0,.4-1.93c0-.65,0-1.29-.09-1.94a19.27,19.27,0,0,1,0-2.94,29.34,29.34,0,0,1,.55-3.66l-4.38.85A30.58,30.58,0,0,0,38.1,74v.15a2.14,2.14,0,0,0,.06.6,2.51,2.51,0,0,1-.9,2.57A9.9,9.9,0,0,1,22,76.17a1.33,1.33,0,0,1-.19-.78,35.91,35.91,0,0,0,0-3.88l-4,.79a22.24,22.24,0,0,1-.17,3.16,3.73,3.73,0,0,0,.67,2.73A14.73,14.73,0,0,0,23.14,82.81Z"
    />
    <path
      fill="#5f9501"
      d="M27.9,73.68C28,75.13,28.78,76,30,76h0a2,2,0,0,0,1.41-.58,2.54,2.54,0,0,0,.63-1.81c0-1.34-.11-2.69-.23-4l-4.17.81c.05.61.09,1.21.14,1.81Z"
    />
    <path
      fill="#79b61d"
      d="M11.74,49a26,26,0,0,0-5.33-4.17l0,0a10.38,10.38,0,0,1,1.82-.47A10.26,10.26,0,0,1,15.05,46c3.05,1.78,5.58,4.35,8.22,8.33a28,28,0,0,1,3.87,11.37c.24,1.54.39,3.13.51,4.7l4.17-.81a58.63,58.63,0,0,0-1.63-9.5c-.06-.23-.11-.45-.17-.72h0l.32,0c.54,0,1.05.08,1.53.16,1.55.26,2.16-.06,2.82-1.49A27.88,27.88,0,0,1,43.37,47c2.59-1.89,4.93-2.78,7.44-2.71a7.08,7.08,0,0,1,2.86.62h0c-.42.24-.82.47-1.21.72A29.79,29.79,0,0,0,42,57.41a35,35,0,0,0-3.6,10.86l4.38-.85a30.57,30.57,0,0,1,6.71-13.76,18.38,18.38,0,0,1,8.66-6.1A2.42,2.42,0,0,0,59.89,46a2.32,2.32,0,0,0-.64-2.18A11,11,0,0,0,50.92,40h-.16a16,16,0,0,0-8.87,2.91l-.71.47v-.22c0-1.86,0-3.73,0-5.59,0-4.27,0-8.69.05-13A42.31,42.31,0,0,0,40,14.05l-4,.78c.21.81.4,1.64.59,2.52A30.36,30.36,0,0,1,37,24c0,.64,0,1.28,0,1.93H36.8c-.44,0-.86,0-1.28,0a2.16,2.16,0,0,0-2.28,2.17,2.11,2.11,0,0,0,2.33,2.09H37v2.46H35.41a2.12,2.12,0,1,0-.1,4.23c.24,0,.49,0,.74,0h1l0,2.51c0,2.38,0,4.76,0,7.14a1.4,1.4,0,0,1-.31.84,35,35,0,0,0-5.08,7.49c-.15.3-.25.36-.6.32a5.29,5.29,0,0,0-1.64,0c-.69.13-1,0-1.32-.74a31.19,31.19,0,0,0-4.88-7.09,1.23,1.23,0,0,1-.26-.72v-4c0-7.15,0-14.53,0-21.8a20.6,20.6,0,0,1,.35-3.53l-4.13.81a38.05,38.05,0,0,0-.41,6.15c.08,4.42.07,8.9.05,13.25,0,1.89,0,3.78,0,5.67v.23a18.82,18.82,0,0,0-5.85-2.85C8.06,39.25,4,40.33.73,43.8a2.27,2.27,0,0,0-.68,2.07A2.48,2.48,0,0,0,1.7,47.56,21.54,21.54,0,0,1,6,49.64a26.68,26.68,0,0,1,9.46,12.23A27.69,27.69,0,0,1,17.75,72.3l4-.79C21.4,63.1,18,55.54,11.74,49Z"
    />
    <path
      fill="#79b61d"
      d="M31.06,26a18.58,18.58,0,0,0-3.5,0,2,2,0,0,0-1.7,2.21,2.05,2.05,0,0,0,2,1.95h1.84c.33,0,.65,0,1,0a2,2,0,0,0,2-1.83,2.26,2.26,0,0,0-.45-1.68A1.73,1.73,0,0,0,31.06,26Z"
    />
    <path
      fill="#c8d200"
      d="M24.77,11.92c.5-1.5,1.14-3,1.76-4.42l.4-.93a6.33,6.33,0,0,1,.67-1.13l.17-.25a2.23,2.23,0,0,1,1.87-1,3.1,3.1,0,0,1,.42,0,2.89,2.89,0,0,1,2.72,1.88A38.4,38.4,0,0,1,36,14.83l4-.78c-.23-1-.5-2-.81-3.07a26.22,26.22,0,0,0-3.74-8.24C34,.69,32.21-.2,30.08,0a5.69,5.69,0,0,1-.83,0H29a4.2,4.2,0,0,0-2.91,1,11.82,11.82,0,0,0-1.76,1.86,24.37,24.37,0,0,0-3.42,7.43,50.39,50.39,0,0,0-1.78,7.77l4.13-.81A38.08,38.08,0,0,1,24.77,11.92Z"
    />
  </svg>
);

GradientCrop5.propTypes = {
  className: PropTypes.string,
};
