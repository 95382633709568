import React from 'react';
import { PropTypes } from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { countryBehaviour } from 'data/Behaviour';

const ReferAFarmerRoute = ({ component: Component, user: { country }, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      if (!countryBehaviour(country, 'refer_a_farmer_functionality')) {
        return <Redirect to="/plan-builder/flow-select" />;
      }
      return <Component {...props} />;
    }}
  />
);

ReferAFarmerRoute.propTypes = {
  component: PropTypes.any,
  user: PropTypes.object,
};

export default ReferAFarmerRoute;
