/* eslint-disable import/prefer-default-export */
/* eslint-disable react/prop-types */
import React from 'react';

const ChevronLeft = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 30" {...props} width="30px" height="30px">
    <path d="M3.063 17.173l15.602-14.21L15.937.108.335 14.317z" fill="#79B61D" />
    <path d="M3.063 11.827l15.602 14.21-2.728 2.856L.335 14.683z" fill="#79B61D" />
  </svg>
);

export default ChevronLeft;
