/* eslint-disable import/prefer-default-export */
/* eslint-disable react/prop-types */
import React from 'react';
import { PropTypes } from 'prop-types';
import { useLocalizedFromContext } from 'data/Localization';

export const Crop42 = ({ title, fill, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="99.619"
    height="154.298"
    viewBox="0 0 99.619 154.298"
    {...props}
  >
    <title>{useLocalizedFromContext(title)}</title>
    <g id="Component_4_1" data-name="Component 4 – 1" transform="translate(2.5 2.5)">
      <g id="Group_143" data-name="Group 143" transform="translate(-763.332 -4582.053)">
        <line
          id="Line_49"
          data-name="Line 49"
          y2="85.853"
          transform="translate(810.641 4645.499)"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="5"
        />
        <g id="Group_141" data-name="Group 141">
          <path
            id="Path_669"
            data-name="Path 669"
            d="M848.953,4684.609a5.419,5.419,0,0,1-10.838,0c0-2.993,5.419-9,5.419-9S848.953,4681.616,848.953,4684.609Z"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="5"
          />
          <path
            id="Path_670"
            data-name="Path 670"
            d="M852.531,4670.192a5.419,5.419,0,1,1,0,10.838c-2.992,0-9-5.419-9-5.419S849.539,4670.192,852.531,4670.192Z"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="5"
          />
          <path
            id="Path_671"
            data-name="Path 671"
            d="M838.115,4666.614a5.419,5.419,0,0,1,10.838,0c0,2.993-5.419,9-5.419,9S838.115,4669.607,838.115,4666.614Z"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="5"
          />
          <path
            id="Path_672"
            data-name="Path 672"
            d="M834.537,4681.03a5.419,5.419,0,1,1,0-10.838c2.992,0,9,5.419,9,5.419S837.529,4681.03,834.537,4681.03Z"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="5"
          />
        </g>
        <g id="Group_142" data-name="Group 142">
          <path
            id="Path_673"
            data-name="Path 673"
            d="M783.168,4661.705a5.42,5.42,0,0,1-10.839,0c0-2.993,5.42-9,5.42-9S783.168,4658.712,783.168,4661.705Z"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="5"
          />
          <path
            id="Path_674"
            data-name="Path 674"
            d="M786.746,4647.288a5.419,5.419,0,1,1,0,10.838c-2.993,0-9-5.419-9-5.419S783.753,4647.288,786.746,4647.288Z"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="5"
          />
          <path
            id="Path_675"
            data-name="Path 675"
            d="M772.329,4643.71a5.42,5.42,0,0,1,10.839,0c0,2.993-5.419,9-5.419,9S772.329,4646.7,772.329,4643.71Z"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="5"
          />
          <path
            id="Path_676"
            data-name="Path 676"
            d="M768.751,4658.126a5.419,5.419,0,1,1,0-10.838c2.993,0,9,5.419,9,5.419S771.744,4658.126,768.751,4658.126Z"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="5"
          />
        </g>
        <line
          id="Line_50"
          data-name="Line 50"
          x1="18.476"
          transform="translate(792.165 4652.708)"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeMiterlimit="10"
          strokeWidth="5"
        />
        <line
          id="Line_51"
          data-name="Line 51"
          x2="18.476"
          transform="translate(810.641 4675.611)"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeMiterlimit="10"
          strokeWidth="5"
        />
      </g>
      <g id="Group_148" data-name="Group 148" transform="translate(-763.332 -4582.053)">
        <line
          id="Line_52"
          data-name="Line 52"
          y2="12.66"
          transform="translate(810.643 4640.534)"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="5"
        />
        <g id="Group_144" data-name="Group 144">
          <path
            id="Path_677"
            data-name="Path 677"
            d="M816.137,4605.791a5.495,5.495,0,1,1-10.989,0c0-3.034,5.494-9.122,5.494-9.122S816.137,4602.757,816.137,4605.791Z"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeMiterlimit="10"
            strokeWidth="5"
          />
          <path
            id="Path_678"
            data-name="Path 678"
            d="M819.765,4591.175a5.495,5.495,0,0,1,0,10.989c-3.035,0-9.123-5.5-9.123-5.5S816.73,4591.175,819.765,4591.175Z"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeMiterlimit="10"
            strokeWidth="5"
          />
          <path
            id="Path_679"
            data-name="Path 679"
            d="M805.148,4587.547a5.495,5.495,0,0,1,10.989,0c0,3.035-5.495,9.122-5.495,9.122S805.148,4590.582,805.148,4587.547Z"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeMiterlimit="10"
            strokeWidth="5"
          />
          <path
            id="Path_680"
            data-name="Path 680"
            d="M801.52,4602.164a5.495,5.495,0,0,1,0-10.989c3.035,0,9.122,5.494,9.122,5.494S804.555,4602.164,801.52,4602.164Z"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeMiterlimit="10"
            strokeWidth="5"
          />
        </g>
        <g id="Group_145" data-name="Group 145">
          <path
            id="Path_681"
            data-name="Path 681"
            d="M816.137,4635.039a5.495,5.495,0,1,1-10.989,0c0-3.034,5.494-9.122,5.494-9.122S816.137,4632,816.137,4635.039Z"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeMiterlimit="10"
            strokeWidth="5"
          />
          <path
            id="Path_682"
            data-name="Path 682"
            d="M805.148,4616.795a5.495,5.495,0,0,1,10.989,0c0,3.035-5.495,9.122-5.495,9.122S805.148,4619.83,805.148,4616.795Z"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeMiterlimit="10"
            strokeWidth="5"
          />
        </g>
        <g id="Group_146" data-name="Group 146">
          <path
            id="Path_683"
            data-name="Path 683"
            d="M825.381,4627.336a5.494,5.494,0,0,1-7.77-7.77c2.145-2.146,10.335-2.565,10.335-2.565S827.526,4625.19,825.381,4627.336Z"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeMiterlimit="10"
            strokeWidth="5"
          />
          <path
            id="Path_684"
            data-name="Path 684"
            d="M838.281,4619.566a5.494,5.494,0,0,1-7.77,7.77c-2.145-2.146-2.565-10.335-2.565-10.335S836.136,4617.42,838.281,4619.566Z"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeMiterlimit="10"
            strokeWidth="5"
          />
          <path
            id="Path_685"
            data-name="Path 685"
            d="M830.511,4606.665a5.494,5.494,0,0,1,7.77,7.77c-2.145,2.146-10.335,2.566-10.335,2.566S828.366,4608.811,830.511,4606.665Z"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeMiterlimit="10"
            strokeWidth="5"
          />
          <path
            id="Path_686"
            data-name="Path 686"
            d="M817.611,4614.435a5.494,5.494,0,0,1,7.77-7.77c2.145,2.146,2.565,10.336,2.565,10.336S819.756,4616.581,817.611,4614.435Z"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeMiterlimit="10"
            strokeWidth="5"
          />
        </g>
        <g id="Group_147" data-name="Group 147">
          <path
            id="Path_687"
            data-name="Path 687"
            d="M790.779,4627.336a5.494,5.494,0,0,1-7.77-7.77c2.145-2.146,10.335-2.565,10.335-2.565S792.924,4625.19,790.779,4627.336Z"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeMiterlimit="10"
            strokeWidth="5"
          />
          <path
            id="Path_688"
            data-name="Path 688"
            d="M803.679,4619.566a5.494,5.494,0,0,1-7.77,7.77c-2.145-2.146-2.565-10.335-2.565-10.335S801.534,4617.42,803.679,4619.566Z"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeMiterlimit="10"
            strokeWidth="5"
          />
          <path
            id="Path_689"
            data-name="Path 689"
            d="M795.909,4606.665a5.494,5.494,0,0,1,7.77,7.77c-2.145,2.146-10.335,2.566-10.335,2.566S793.764,4608.811,795.909,4606.665Z"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeMiterlimit="10"
            strokeWidth="5"
          />
          <path
            id="Path_690"
            data-name="Path 690"
            d="M783.009,4614.435a5.494,5.494,0,0,1,7.77-7.77c2.145,2.146,2.565,10.336,2.565,10.336S785.154,4616.581,783.009,4614.435Z"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeMiterlimit="10"
            strokeWidth="5"
          />
        </g>
      </g>
    </g>
  </svg>
);

Crop42.defaultProps = {
  title: 'canolaoilrapeseed',
};

Crop42.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
};
