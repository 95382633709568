/* eslint-disable react/prop-types */
import React from 'react';

const PlusFlat = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40.00006 40"
    height="13"
    width="12"
    {...props}
  >
    <title>Plus icon flat</title>
    <path
      d="M36.66666,16.66667H23.33333V3.33333a3.33334,3.33334,0,0,0-6.66667,0V16.66667H3.33333a3.33333,3.33333,0,0,0,0,6.66666H16.66667V36.66666A3.33334,3.33334,0,0,0,20,40a3.29629,3.29629,0,0,0,2.37037-1,3.25922,3.25922,0,0,0,.963-2.33333V23.33333H36.66666a3.29632,3.29632,0,0,0,2.37038-1A3.25932,3.25932,0,0,0,40,20,3.33334,3.33334,0,0,0,36.66666,16.66667Z"
      fill="#fff"
    />
  </svg>
);

export default PlusFlat;
