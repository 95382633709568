/* eslint-disable react/prop-types */
import React from 'react';

const GradientUsage = (props) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 128 128"
      enableBackground="new 0 0 128 128"
      xmlSpace="preserve"
      {...props}
    >
      <g>
        <defs>
          <path
            id="SVGID_5_GradientUsage"
            d="M110,32.7l-9-21V1.5c0-0.8-0.7-1.5-1.5-1.5H33.8c-0.8,0-1.5,0.7-1.5,1.5v10.2l-9,21
            c-0.1,0.2-0.1,0.4-0.1,0.6v93.2c0,0.8,0.7,1.5,1.5,1.5h84c0.8,0,1.5-0.7,1.5-1.5V33.3C110.2,33.1,110.1,32.9,110,32.7z M98.1,3
            v7.5H35.3V3H98.1z M107.2,125h-81V33.6l8.6-20.1h63.8l8.6,20.1V125z M88.7,86.5H68.2V62.2c2.3,0,9.1-0.5,14.1-5.5
            c5.9-6,4.6-14.2,4.5-14.5c-0.1-0.7-0.7-1.2-1.4-1.2c-0.4,0-9-0.5-14.9,5.4c-2,2.1-3.2,4.4-3.9,6.5c-0.7-2.2-1.8-4.5-3.9-6.5
            c-5.9-6-14.5-5.5-14.9-5.4c-0.7,0-1.3,0.6-1.4,1.2c-0.1,0.3-1.4,8.5,4.5,14.5c4.9,5,11.7,5.5,14.1,5.5v24.3H44.7
            c-0.8,0-1.5,0.7-1.5,1.5s0.7,1.5,1.5,1.5h44c0.8,0,1.5-0.7,1.5-1.5S89.5,86.5,88.7,86.5z M72.7,48.5c3.7-3.7,8.9-4.4,11.2-4.5
            c0.1,2.2-0.2,7-3.8,10.7c-3.7,3.7-8.9,4.4-11.2,4.5C68.8,56.9,69.1,52.2,72.7,48.5z M64.5,59.2c-2.4-0.1-7.5-0.8-11.2-4.5
            c-3.6-3.7-3.9-8.4-3.8-10.7c2.4,0.1,7.5,0.8,11.2,4.5C64.3,52.2,64.6,57,64.5,59.2z"
          />
        </defs>
        <clipPath id="SVGID_2_GradientUsage">
          <use xlinkHref="#SVGID_5_GradientUsage" overflow="visible" />
        </clipPath>
        <g clipPath="url(#SVGID_2_GradientUsage)">
          <rect
            x="-7.8"
            y="108.5"
            transform="matrix(0.9736 -0.2284 0.2284 0.9736 -26.2255 21.8018)"
            fill="#B95700"
            width="177.8"
            height="31.5"
          />
          <rect
            x="-15"
            y="77.8"
            transform="matrix(0.9736 -0.2284 0.2284 0.9736 -19.409 19.3471)"
            fill="#EB6909"
            width="177.8"
            height="31.5"
          />
          <rect
            x="-22.2"
            y="47.1"
            transform="matrix(0.9736 -0.2284 0.2284 0.9736 -12.5925 16.8925)"
            fill="#5F9501"
            width="177.8"
            height="31.5"
          />
          <rect
            x="-29.4"
            y="16.4"
            transform="matrix(0.9736 -0.2284 0.2284 0.9736 -5.776 14.4378)"
            fill="#79B61D"
            width="177.8"
            height="31.5"
          />
          <rect
            x="-36.6"
            y="-14.3"
            transform="matrix(0.9736 -0.2284 0.2284 0.9736 1.0404 11.9831)"
            fill="#C8D200"
            width="177.8"
            height="31.5"
          />
        </g>
      </g>
    </svg>
  );
};

export default GradientUsage;
