import { UPDATE_USER, RESET_USER } from './types';

export const updateUser = (update) => (dispatch) => {
  dispatch({
    type: UPDATE_USER,
    payload: update,
    meta: {
      analytics: {
        type: UPDATE_USER,
        dataLayerPath: 'user',
      },
    },
  });
  return Promise.resolve();
};

export const resetUser = () => ({
  type: RESET_USER,
});
