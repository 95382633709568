/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { lazy, Suspense, useRef, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { connect } from 'react-redux';
import AnonymousRoute from 'components/Routes/AnonymousRoute';
import OnboardedRoute from 'components/Routes/OnboardedRoute';
import GenericRoute from 'components/Routes/GenericRoute';
import PlanNowRoute from 'components/Routes/PlanNowRoute';
import TestingRoute from 'components/Routes/TestingRoute';
import ReferAFarmerRoute from 'components/Routes/ReferAFarmerRoute';
import NZStepRoute from 'components/Routes/NZStepRoute';
import ScrollToTop from 'hoc/ScrollToTop';
import SignOut from 'containers/SignOut/SignOut';
import Footer from 'components/Molecules/Footer/Footer';
import ScrollToTopButton from 'components/Atoms/ScrollToTopButton/ScrollToTopButton';
import UserUtils from 'Utils/UserUtils';
import StepsContainerComponent from 'components/Organisms/StepsContainerComponent/StepsContainerComponent';

const Welcome = lazy(() => import('containers/Welcome/Welcome'));
const GetStarted = lazy(() => import('containers/GetStarted/GetStarted'));
const PlanBuilder = lazy(() => import('containers/PlanBuilder/PlanBuilder'));
const NG4MyPlots = lazy(() => import('containers/MyPlots/NG4MyPlots'));
const ImportPlot = lazy(() => import('containers/ImportPlot/ImportPlot'));
const AddPlotManually = lazy(() => import('containers/AddPlotManually/AddPlotManually'));
const NG4MyCropRotations = lazy(() => import('containers/MyCropRotations/NG4MyCropRotations'));
const AddCropRotation = lazy(() => import('containers/AddCropRotation/AddCropRotation'));
const AddPlanToCropRotation = lazy(() =>
  import('containers/AddPlanToCropRotation/AddPlanToCropRotation'),
);
const NG4EditCropRotation = lazy(() => import('containers/EditCropRotation/NG4EditCropRotation'));
const NG4MyPlan = lazy(() => import('containers/MyPlan/NG4MyPlan.jsx'));
const NG4MyPlans = lazy(() => import('containers/MyPlans/NG4MyPlans'));
const ShoppingListManagerForProducts = lazy(() =>
  import('containers/ShoppingListManager/ShoppingListManager'),
);
const ShoppingListManagerForApplications = lazy(() =>
  import('containers/ShoppingListManager/ShoppingListManager'),
);
const ProductPortfolio = lazy(() => import('containers/ProductPortfolio/ProductPortfolio'));
const AddProduct = lazy(() => import('containers/AddProduct/AddProduct'));
const EditProduct = lazy(() => import('containers/EditProduct/EditProduct'));
const FAQ = lazy(() => import('containers/FAQ/FAQ'));
const NG4ServiceDesk = lazy(() => import('containers/ServiceDesk/NG4ServiceDesk'));
const NG4MyOverView = lazy(() => import('containers/MyOverview/NG4MyOverView'));
const MyOverviewRedirecter = lazy(() => import('containers/MyOverview/Redirecter'));
const MyOverviewReseller = lazy(() => import('containers/MyOverview/reseller/MyOverview'));
const Navigation = lazy(() => import('components/Molecules/Navigation/Navigation'));
const MyAccount = lazy(() => import('containers/MyAccount/MyAccount'));
const SpreaderCallibration = lazy(() =>
  import('containers/SpreaderCallibration/SpreaderCallibration'),
);
const CatchError = lazy(() => import('containers/CatchError/CatchError'));
const NG4Test = lazy(() => import('containers/NG4Test/NG4Test'));
const CopyCropRotation = lazy(() => import('containers/CopyCropRotation/CopyCropRotation'));
const NG3CopyCropRotation = lazy(() => import('containers/CopyCropRotation/NG3CopyCropRotation'));
const ReferAFarmer = lazy(() => import('containers/ReferAFarmer/ReferAFarmer'));
const MyApplicationMaps = lazy(() => import('containers/MyApplicationMaps/MyApplicationMaps'));
const GetMyApplicationMap = lazy(() =>
  import('containers/GetMyApplicationMap/GetMyApplicationMap'),
);

function defineWrapper(children) {
  if (window.location.pathname.includes('/welcome') || window.location.pathname === '/') {
    return React.createElement('div', {}, children);
  }
  return React.createElement(
    Grid,
    {
      className: 'content-wrapper',
      item: true,
      xs: 12,
      lg: 9,
    },
    children,
  );
}

const fallback = <div />;

const Routes = ({ user, closeNavigationBar, location }) => {
  const main = useRef();
  const generateScrollToTopButton = useCallback(() => {
    if (main.current) {
      return <ScrollToTopButton scrollComponent={main.current} />;
    }
    return null;
  }, []);

  useEffect(() => {
    if (window.hj !== undefined) {
      window.hj('stateChange', location.pathname);
    }
  }, [location]);

  return (
    <>
      <Route
        path="/"
        render={(props) =>
          props.location.pathname !== '/' &&
          !props.location.pathname.includes('/welcome') && (
            <Suspense fallback={fallback}>
              <Navigation location={location} />
            </Suspense>
          )
        }
      />
      <ScrollToTop elementRef={main}>
        {defineWrapper(
          <div onClick={() => closeNavigationBar()} className="content-main">
            {/* CHECK ACCESSIBILITY FOR ABOVE ELEMENT */}
            {/* eslint-disable-next-line no-return-assign */}
            <main role="main" ref={main}>
              <NZStepRoute path="/" component={StepsContainerComponent} />
              <Switch>
                <Route
                  path="/"
                  exact
                  render={({ location: { hash } }) => (
                    <Redirect to={hash ? `/welcome${hash}` : '/welcome'} />
                  )}
                />
                <Route path="/signOut" component={SignOut} />
                <Route
                  path="/fr"
                  render={() => (
                    <Redirect to={{ pathname: '/welcome', state: { lang: 'FR', country: 'FR' } }} />
                  )}
                />
                <Route
                  path="/at"
                  render={() => (
                    <Redirect to={{ pathname: '/welcome', state: { lang: 'DE', country: 'AT' } }} />
                  )}
                />
                <Suspense fallback={fallback}>
                  <Switch>
                    <AnonymousRoute user={user} path="/welcome" component={Welcome} />
                    <GenericRoute user={user} path="/get-started" component={GetStarted} />
                    <GenericRoute path="/plan-builder" component={PlanBuilder} />
                    <PlanNowRoute exact path="/myplots" user={user} component={NG4MyPlots} />
                    <PlanNowRoute
                      exact
                      path="/myplots/edit/:plotId"
                      user={user}
                      component={AddPlotManually}
                    />
                    <PlanNowRoute
                      exact
                      path="/myplots/add-manually"
                      user={user}
                      component={AddPlotManually}
                    />
                    <PlanNowRoute
                      exact
                      path="/myplots/import-xml"
                      user={user}
                      component={ImportPlot}
                    />
                    <OnboardedRoute
                      exact
                      path="/my-crop-rotation"
                      user={user}
                      component={NG4MyCropRotations}
                    />
                    <OnboardedRoute
                      exact
                      path="/my-crop-rotation/copy/:cropRotationId"
                      user={user}
                      render={() => {
                        if (UserUtils.isNG4()) {
                          return <CopyCropRotation />;
                        }
                        if (UserUtils.isAT()) {
                          return <NG3CopyCropRotation />;
                        }
                        return null;
                      }}
                    />
                    <OnboardedRoute
                      exact
                      path="/add-crop-rotation"
                      user={user}
                      component={AddCropRotation}
                    />
                    <OnboardedRoute
                      exact
                      path="/add-crop-rotation/edit"
                      user={user}
                      component={NG4EditCropRotation}
                    />
                    <OnboardedRoute
                      exact
                      path="/my-application-maps"
                      user={user}
                      component={MyApplicationMaps}
                    />
                    <OnboardedRoute
                      exact
                      path="/my-application-maps/:planId/:applicationStepId?"
                      user={user}
                      component={GetMyApplicationMap}
                    />
                    <OnboardedRoute
                      exact
                      path="/add-crop-rotation/edit/add-plan"
                      user={user}
                      component={AddPlanToCropRotation}
                    />
                    <OnboardedRoute
                      exact
                      path="/add-crop-rotation/edit/plan-detail/:planId"
                      user={user}
                      component={NG4MyPlan}
                    />
                    <OnboardedRoute
                      path="/add-crop-rotation/edit/create-plan"
                      user={user}
                      component={PlanBuilder}
                    />
                    <OnboardedRoute
                      exact
                      path="/add-crop-rotation/edit/plan-list"
                      user={user}
                      component={NG4MyPlans}
                    />
                    <OnboardedRoute
                      exact
                      path="/add-crop-rotation/edit/plan-list/plan-detail/:planId"
                      user={user}
                      component={NG4MyPlan}
                    />
                    <OnboardedRoute
                      exact
                      path="/my-crop-rotation/edit/:cropRotationId"
                      user={user}
                      component={NG4EditCropRotation}
                    />
                    <OnboardedRoute
                      exact
                      path="/my-crop-rotation/edit/:cropRotationId/plan-detail/:planId"
                      user={user}
                      component={NG4MyPlan}
                    />
                    <OnboardedRoute
                      exact
                      path="/my-crop-rotation/edit/:cropRotationId/add-plan"
                      user={user}
                      component={AddPlanToCropRotation}
                    />
                    <OnboardedRoute
                      path="/my-crop-rotation/edit/:cropRotationId/create-plan"
                      user={user}
                      component={PlanBuilder}
                    />
                    <OnboardedRoute
                      exact
                      path="/my-crop-rotation/edit/:cropRotationId/plan-list"
                      user={user}
                      component={NG4MyPlans}
                    />
                    <OnboardedRoute
                      exact
                      path="/my-crop-rotation/edit/:cropRotationId/plan-list/plan-detail/:planId"
                      user={user}
                      component={NG4MyPlan}
                    />
                    <OnboardedRoute path="/myplan/:planId" user={user} component={NG4MyPlan} />
                    <OnboardedRoute
                      exact
                      path="/product-select/:planId/:stepNr"
                      user={user}
                      component={NG4MyPlan}
                    />
                    <OnboardedRoute
                      exact
                      path="/product-select/:planId/:stepNr/filter"
                      user={user}
                      component={NG4MyPlan}
                    />
                    <OnboardedRoute
                      exact
                      path="/fertilizer-needs"
                      user={user}
                      component={ShoppingListManagerForProducts}
                    />
                    <OnboardedRoute
                      exact
                      path="/my-applications"
                      user={user}
                      component={ShoppingListManagerForApplications}
                    />
                    <OnboardedRoute
                      exact
                      path="/product/overview"
                      user={user}
                      component={ProductPortfolio}
                    />
                    <OnboardedRoute
                      exact
                      path="/product/overview/filter"
                      user={user}
                      component={ProductPortfolio}
                    />
                    <OnboardedRoute
                      exact
                      path="/product/add/:method?"
                      user={user}
                      component={AddProduct}
                    />
                    <OnboardedRoute
                      exact
                      path="/product/:productId/edit"
                      user={user}
                      component={EditProduct}
                    />
                    <OnboardedRoute
                      exact
                      path={['/myoverview', '/null']}
                      user={user}
                      component={MyOverviewRedirecter}
                    />
                    <OnboardedRoute
                      exact
                      path="/myoverview/farmer/:harvestingYear"
                      user={user}
                      component={NG4MyOverView}
                    />
                    <OnboardedRoute
                      exact
                      path="/myoverview/reseller/:harvestingYear/:farmersName?/:farmName?/:cropName?"
                      user={user}
                      component={MyOverviewReseller}
                    />
                    <OnboardedRoute path="/myaccount" user={user} component={MyAccount} />
                    <ReferAFarmerRoute
                      path="/refer-a-farmer"
                      user={user}
                      component={ReferAFarmer}
                    />
                    <TestingRoute path="/ng4-test" user={user} component={NG4Test} />
                    <GenericRoute path="/spreader-callibration" component={SpreaderCallibration} />
                    <GenericRoute path="/faq" component={FAQ} />
                    <GenericRoute path="/service-desk" component={NG4ServiceDesk} />
                    <GenericRoute path="/error/:status" component={CatchError} />
                    <GenericRoute component={CatchError} />
                  </Switch>
                </Suspense>
              </Switch>
              {generateScrollToTopButton()}
            </main>
            <Route
              path="/"
              render={(props) =>
                props.location.pathname !== '/' &&
                !props.location.pathname.includes('/welcome') &&
                !props.location.pathname.includes('/product-select/') &&
                !props.location.pathname.includes('/product/') && <Footer />
              }
            />
          </div>,
        )}
      </ScrollToTop>
    </>
  );
};

Routes.propTypes = {
  user: PropTypes.object,
  closeNavigationBar: PropTypes.func,
  location: PropTypes.object,
};

export default withRouter(
  connect(
    ({ user, terrazoFields }) => ({
      user,
      terrazoFields,
    }),
    {},
  )(Routes),
);
