/* eslint-disable import/prefer-default-export */
/* eslint-disable react/prop-types */
import React from 'react';
import { PropTypes } from 'prop-types';
import { useLocalizedFromContext } from 'data/Localization';

export const Crop34 = ({ title, fill, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="92.726"
    height="166.132"
    viewBox="0 0 92.726 166.132"
    {...props}
  >
    <title>{useLocalizedFromContext(title)}</title>
    <g id="Component_12_1" dataname="Component 12 – 1" transform="translate(2.5 2.5)">
      <path
        id="Path_1403"
        dataname="Path 1403"
        d="M2346.04,7075.329s41.577,40.037,14.373,142.7"
        transform="translate(-2317.016 -7057.449)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_1404"
        dataname="Path 1404"
        d="M2366.994,7186.693s0-28.845-23.981-27.343"
        transform="translate(-2317.016 -7057.449)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_1405"
        dataname="Path 1405"
        d="M2319.33,7165.987s5.446-15.465,20.04-16.119,11.826,15.574,7.841,17.861c-6.008,3.448-10.026-2.119-16.078-2.535A42.438,42.438,0,0,0,2319.33,7165.987Z"
        transform="translate(-2317.016 -7057.449)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_1406"
        dataname="Path 1406"
        d="M2399.657,7070.964s-12.777-10.274-25.525-3.14-4.116,19.886,2.747,19.312c6.9-.576,7.4-7.084,12.329-10.627A42.48,42.48,0,0,1,2399.657,7070.964Z"
        transform="translate(-2317.016 -7057.449)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_1407"
        dataname="Path 1407"
        d="M2369.645,7163.166"
        transform="translate(-2317.016 -7057.449)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_1408"
        dataname="Path 1408"
        d="M2378.06,7122.292c-1.4-3.817,1.432-7.676,3.268-12.473,5.084,2.916,9.155,3.591,10.553,7.408a7.36,7.36,0,1,1-13.821,5.065Z"
        transform="translate(-2317.016 -7057.449)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_1409"
        dataname="Path 1409"
        d="M2397.468,7126.311c3.8,1.446,4.857,6.114,7.274,10.646-5.536,1.926-8.714,4.557-12.513,3.11a7.36,7.36,0,0,1,5.239-13.756Z"
        transform="translate(-2317.016 -7057.449)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_1410"
        dataname="Path 1410"
        d="M2335.2,7072.611c-2.067-3.5.018-7.809.954-12.859,5.529,1.947,9.654,1.873,11.721,5.374a7.36,7.36,0,0,1-12.675,7.485Z"
        transform="translate(-2317.016 -7057.449)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_1411"
        dataname="Path 1411"
        d="M2346.04,7062.823c2.085-2.662,6.211-3.5,10.293-5.374,1.6,5.64,4.036,8.968,2.369,12.675a7.36,7.36,0,0,1-9.732,3.692"
        transform="translate(-2317.016 -7057.449)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_1412"
        dataname="Path 1412"
        d="M2389.124,7113.352"
        transform="translate(-2317.016 -7057.449)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_1413"
        dataname="Path 1413"
        d="M2388.467,7112.825c1.809-1.852,4.491-3.37,7.016-5.411,2.935,5.073,6.118,7.7,5.414,11.7a7.361,7.361,0,0,1-8.525,5.973"
        transform="translate(-2317.016 -7057.449)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_1414"
        dataname="Path 1414"
        d="M2389.124,7125.827s-14.955,11.089-19.511,30.84"
        transform="translate(-2317.016 -7057.449)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_1415"
        dataname="Path 1415"
        d="M2331.085,7093.316"
        transform="translate(-2317.016 -7057.449)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <g id="Group_477" dataname="Group 477" transform="translate(-2317.016 -7057.449)">
        <path
          id="Path_1416"
          dataname="Path 1416"
          d="M2345.04,7095.769c-.292-4.054-4.458-6.411-8.1-10.03-3.438,4.747-6.873,7.034-6.58,11.088a7.36,7.36,0,1,0,14.681-1.058Z"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
        <path
          id="Path_1417"
          dataname="Path 1417"
          d="M2329,7107.4c-2.869,2.879-1.917,7.57-2.26,12.694,5.838-.517,9.817.576,12.686-2.3A7.36,7.36,0,0,0,2329,7107.4Z"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
        <path
          id="Path_1418"
          dataname="Path 1418"
          d="M2328.2,7094.283c-4.05-.351-7.034,3.391-11.183,6.418,4.145,4.143,5.862,7.9,9.911,8.247"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
        <path
          id="Path_1419"
          dataname="Path 1419"
          d="M2336.405,7103.536s18.19,3.968,30.456,20.1"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
      </g>
      <path
        id="Path_1420"
        dataname="Path 1420"
        d="M2375.72,7078.591s-12.651,9.924-13.034,27.253"
        transform="translate(-2317.016 -7057.449)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
    </g>
  </svg>
);

Crop34.defaultProps = {
  title: 'buckwheat',
};

Crop34.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
};
