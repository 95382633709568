/* eslint-disable react/prop-types */
import React from 'react';

const MyPlotsMenuIcon = (props) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="70"
      height="70"
      viewBox="0 0 70 70"
      enableBackground="new 0 0 70 70"
      xmlSpace="preserve"
      {...props}
    >
      <g>
        <g>
          <path
            fill="#EB6909"
            d="M24.2,43.6c0.1,0,0.1,0.1,0.2,0.1v3.8c0,0.8,0.7,1.5,1.5,1.5s1.5-0.7,1.5-1.5v-3.8c0.6-0.2,1.1-0.6,1.5-1c1.8,2.6,3.2,6.5,4,9l3-0.7c-0.8-2.5-2.1-6.3-4-9.3L24.2,43.6z"
          />
          <path
            fill="#EB6909"
            d="M41.3,39.6c1.9,3.1,3.3,6.4,4.3,9.2l3-0.7c-1-2.7-2.4-6-4.2-9.2L41.3,39.6z"
          />
          <path
            fill="#EB6909"
            d="M35.2,41c1.9,3.4,3.1,6.8,3.9,9.3l2.9-0.7c-0.7-2.5-1.9-5.8-3.8-9.3L35.2,41z"
          />
          <path
            fill="#EB6909"
            d="M50.8,37.4L47,38.2c2.1,2.9,3.7,6.2,4.8,9.1l3.5-0.8C54.3,43.7,52.8,40.4,50.8,37.4z"
          />
        </g>
        <g>
          <path
            fill="#79B61D"
            d="M54.5,16.2l-4.3,1c1.5,0.1,2.7,1.3,2.7,2.9c0,1.6-1.3,2.9-2.9,2.9c-1.6,0-2.9-1.3-2.9-2.9c0-1.1,0.6-2,1.5-2.5l-4.3,1c-0.1,0.5-0.2,1-0.2,1.5c0,3.2,2.6,5.9,5.9,5.9c3.2,0,5.9-2.6,5.9-5.9C56,18.6,55.4,17.3,54.5,16.2z"
          />
          <path
            fill="#79B61D"
            d="M29.7,28.9c-0.8-2.6-1.6-4.6-1.9-5.1c-0.4-0.7-0.9-0.8-1.4-0.8c-0.9,0-1.3,0.4-1.5,0.8c-0.2,0.4-0.9,2-1.6,4.1c-0.1-1.4-0.4-2.8-0.7-4.2l-2.7,0.6c0.8,3.1,1.1,6.2,0.3,7.8c-0.3,0.5-0.7,0.8-1.3,0.9c-0.6-0.1-1-0.4-1.3-0.9c-0.8-1.5-0.6-4.3,0.2-7.3L14,25.7c-0.3,1.6-0.4,3.1-0.4,4.6c-1.4,0.2-2.1,0.4-2.2,0.4c-0.8,0.2-1.3,1-1.2,1.8c0.1,0.7,0.8,1.2,1.5,1.2c0.1,0,0.2,0,0.3,0c0,0,0.8-0.2,2.2-0.4c0.1,0.1,0.1,0.2,0.2,0.4c0.4,0.7,0.9,1.2,1.5,1.7l9.3-2.2c0.3-1.5,0.8-3.1,1.2-4.6c0.2,0.7,0.4,1.4,0.6,2.1c0,0.1,0,0.2,0.1,0.3c0.2,0.5,0.3,1.1,0.4,1.6l13.9-3.3C37.7,29,33.8,28.8,29.7,28.9z"
          />
        </g>
        <g>
          <path
            fill="#B95700"
            d="M35.9,51l-3,0.7c0.5,1.6,0.8,2.7,0.8,2.8c0.2,0.7,0.8,1.1,1.5,1.1c0.1,0,0.2,0,0.4,0c0.8-0.2,1.3-1,1.1-1.8C36.7,53.7,36.4,52.6,35.9,51z"
          />
          <path
            fill="#B95700"
            d="M48.6,48.1l-3,0.7c1.3,3.4,1.9,5.9,1.9,5.9c0.2,0.7,0.8,1.1,1.5,1.1c0.1,0,0.2,0,0.4,0c0.8-0.2,1.3-1,1.1-1.8C50.4,53.7,49.8,51.3,48.6,48.1z"
          />
          <path
            fill="#B95700"
            d="M41.9,49.6l-2.9,0.7c0.7,2.5,1,4.1,1,4.1c0.1,0.7,0.8,1.3,1.5,1.3c0.1,0,0.2,0,0.2,0c0.8-0.1,1.4-0.9,1.2-1.7C43,53.8,42.7,52.1,41.9,49.6z"
          />
          <path
            fill="#B95700"
            d="M57.5,54.2c-0.1-0.3-0.7-3.5-2.2-7.7l-3.5,0.8c1.6,4.3,2.3,7.8,2.3,7.8c0.1,0.7,0.8,1.2,1.5,1.2c0.1,0,0.2,0,0.3,0C57.1,55.8,57.7,55,57.5,54.2z"
          />
        </g>
        <path
          fill="#5F9501"
          d="M30.5,39.8c0.4-1.6,0.3-3.6,0-5.5c1.9,2.1,3.4,4.4,4.7,6.7l3-0.7c-1.5-2.7-3.3-5.6-5.7-8.1c0.9,0,1.7,0,2.6,0.1c2.4,1.8,4.5,4.5,6.2,7.3l3.1-0.7c-1.3-2.2-2.8-4.3-4.5-6.2c0.6,0.1,1.2,0.1,1.8,0.2c2.1,1.4,3.8,3.3,5.3,5.3l3.7-0.9c-0.9-1.4-1.9-2.7-3.1-4c2.7,0.5,5.3,1.2,7.7,2.1c0.2,0.1,0.3,0.1,0.5,0.1c0.6,0,1.2-0.4,1.4-1c0.3-0.8-0.1-1.6-0.9-1.9c-4.4-1.6-9.5-2.7-15-3.3l-13.9,3.3c0.7,2.9,0.9,5.7,0.1,7.3c-0.3,0.5-0.7,0.8-1.3,0.9c-0.6-0.1-1-0.4-1.3-0.9c-0.6-1.4-0.4-4,0.2-6.7l-9.3,2.2c0.3,0.2,0.6,0.4,0.9,0.5v3.8c0,0.8,0.7,1.5,1.5,1.5s1.5-0.7,1.5-1.5v-3.8c0.6-0.2,1.1-0.6,1.5-1c-0.3,2.5-0.2,4.9,0.7,6.6c0.6,0.9,1.3,1.6,2.2,2.1l7.7-1.8C31.5,41.1,31,40.4,30.5,39.8z"
        />
        <g>
          <path
            fill="#C8D200"
            d="M50.1,14.2c-2.8,0-5,1.8-5.7,4.4l4.3-1c0.4-0.2,0.9-0.4,1.4-0.4c0.1,0,0.1,0,0.2,0l4.3-1C53.5,15,51.9,14.2,50.1,14.2z"
          />
          <path
            fill="#C8D200"
            d="M18.5,15.3c-0.5,0-1.2,0.2-1.5,0.8c-0.4,0.7-2.2,5.2-3,9.6l3.7-0.9c0.3-1.3,0.7-2.7,1.1-4c0.4,1.1,0.7,2.3,1,3.5l2.7-0.6c-0.9-3.7-2.2-7-2.6-7.6C19.6,15.3,19,15.3,18.5,15.3z"
          />
        </g>
      </g>
    </svg>
  );
};

export default MyPlotsMenuIcon;
