/* eslint-disable import/prefer-default-export */
/* eslint-disable react/prop-types */
import React from 'react';

export const FachbeiratLogo = (props) => (
  <svg
    id="prefix__Layer_1"
    x={0}
    y={0}
    height="90"
    width="77"
    viewBox="0 0 278 323.8"
    xmlSpace="preserve"
    {...props}
  >
    <title>Fachbeirat für Bodenfruchtbarkeit und Bodenschutz.</title>
    <style>
      {
        ".prefix__st1{fill:#855d25}.prefix__st4{fill:#f9f9fa}.prefix__st5{fill:none;stroke:#855e25;strokeWidth:5;strokeMiterlimit:10}.prefix__st7{font-family:'Arial-BoldMT'}.prefix__st8{font-size:21px}"
      }
    </style>
    <path
      d="M239.2 2.5H39.8C19.2 2.5 2.5 19.2 2.5 39.8v180.4h273V38.8c0-20-16.2-36.3-36.3-36.3z"
      fill="#85c441"
    />
    <path
      className="prefix__st1"
      d="M12.7 168.8c106.2-5 90.7 84.7 244.8 11.6-127.1 52.4-157.6-29-244.8-11.6z"
    />
    <path
      d="M48.3 163.4c63.7-37.8 114.8-1.8 214.1 12.6-53.8 19.3-115.2-11.5-153 4.1-18.8-7.4-29.6-15.2-61.1-16.7z"
      opacity={0.5}
      fill="#855d25"
    />
    <path
      d="M113.1 182c14 7.1 78.8 20 122.7 3.8-32.7 3.1-101.7-15.3-122.7-3.8z"
      opacity={0.7}
      fill="#855d25"
    />
    <path
      className="prefix__st4"
      d="M136.2 146.7c-18.2-42.1 46-60.8 6.3-105.9-.3-.3-.3-.8 0-1.1.4-.4 1-.4 1.4 0 45.5 49.7-11.8 64.8 12.2 111-11.3-3.7-19.9-4-19.9-4zM127.6 27.6c-.5-.2-.9-.4-1.4-.5.4.1.9.2 1.4.5 5.4 2.1 12.1 7.5 14.7 10.2.2-1.9-.2-4.2-1.4-6.9-6.1-14.3-28.2-12.4-36.8-7.3 11.8 8.6 12.4 20.4 20.2 22.3 5.5 1.3 14.6.4 17.3-5.3-4.1-5.1-10.5-11.2-14-13z"
    />
    <path
      className="prefix__st4"
      d="M87.1 75.6c17 13.1 0 39.7 24 44.9 11.3 1.8 17.4-.4 20.6-2.9-.9-5.9-3.8-15.3-16.2-29.5 8.2 4.5 15.5 15 19.3 25.9.3-.6.4-.9.4-.9s12.2-39.3-48.1-37.5zM164.9 72.1c12.9-10 14.7-12.7 23.3-14.7-12.9 7.2-20.8 15.7-24 20 0 .5-.1 1-.1 1.5C201 100 197.7 59.6 214 54.7c-36.6-13.5-46.5 3.7-49.1 17.4z"
    />
    <path
      className="prefix__st5"
      d="M239 2.5H39C18.9 2.5 2.5 18.9 2.5 39v245.6c0 20.2 16.4 36.5 36.5 36.5h200c20.2 0 36.5-16.4 36.5-36.5V39c0-20.1-16.3-36.5-36.5-36.5zM2.5 220.2h273"
    />
    <path fill="none" d="M2.5 236.7h273.3v85.5H2.5z" />
    <text transform="translate(44.434 252.464)">
      <tspan x={0} y={0} className="prefix__st1 prefix__st7 prefix__st8">
        {'FACHBEIRAT F\xDCR '}
      </tspan>
      <tspan x={-35.9} y={26.4} className="prefix__st1 prefix__st7 prefix__st8">
        {'BODENFRUCHTBARKEIT '}
      </tspan>
      <tspan x={-16.7} y={52.8} className="prefix__st1 prefix__st7 prefix__st8">
        {'UND BODENSCHUTZ.'}
      </tspan>
    </text>
  </svg>
);

export default FachbeiratLogo;
