/* eslint-disable import/prefer-default-export */
/* eslint-disable react/prop-types */
import React from 'react';
import { PropTypes } from 'prop-types';

export const GradientCrop8 = (props) => (
  <svg id="prefix__Layer_1" x={0} y={0} viewBox="0 0 63 78" xmlSpace="preserve" {...props}>
    <title>Pumpkin</title>
    <style>{'.prefix__st0{fill:none}.prefix__st2{fill:#79b61d}'}</style>
    <path
      className="prefix__st0"
      d="M57.31 35.42c-4.03-3.52-8.34-4.08-11.21-3.94.54.41 1.04.86 1.5 1.36 3.92 4.23 3.54 9.98 3.53 10.22-.05.68-.64 1.19-1.33 1.14-.68-.05-1.19-.64-1.14-1.32 0-.05.3-4.95-2.89-8.38-2.14-2.29-5.39-3.46-9.67-3.46-13.39 0-14.2 12.04-14.23 12.55 0 .14-.19 12.08 3 20.94 1.3 3.61 3.16 6.7 5.82 8.28 3.45 2.05 6.5 2.66 9.06 1.83 3.25-1.06 4.81-4.19 5.21-5.13.98-2.29 1.52-5.65 2.03-9.29l.18-1.3c.21-1.54.43-3.13.69-4.7a1.236 1.236 0 1 1 2.44.4c-.25 1.53-.47 3.11-.68 4.64l-.06.46c-.57 4.13-1.14 8.01-2.33 10.77-.16.38-.41.9-.76 1.48.3.29.62.48 1 .54 1.31.21 2.99-.94 3.53-1.4 2.82-2.58 5.48-7.59 7.26-13.08 1.43-4.38 2.3-9.08 2.25-13.1-.02-3.07-.61-7.25-3.2-9.51zM31.68 16.33c-.12 1.45-.19 2.85-.2 4.05-.06 4.43-3.01 7.64-4.96 9.27.56.19 1.08.39 1.54.6 2.12-1.02 4.76-1.67 8.05-1.67.31 0 .62.01.92.02-1.56-2.1-3.41-5.64-4.49-11.38-.07-.35-.12-.68-.18-1.02-.16-.91-.3-1.79-.41-2.63-.11.93-.2 1.86-.27 2.76z"
    />
    <path
      className="prefix__st0"
      d="M19.41 43.52c.01-.13.46-7.26 5.99-11.62-2.62-.81-7.17-1.51-10.48 1.76l-.01.01c-.24.23-.47.48-.69.76-5.27 6.56-1.45 15.31-1.41 15.39a1.234 1.234 0 0 1-1.13 1.74c-.47 0-.92-.27-1.13-.73-.18-.4-4.18-9.49 1.1-17.1-1.3.01-3.01.24-4.59 1.2-1.98 1.2-3.35 3.3-4.07 6.24-1.03 4.23 1.54 18.44 7.03 26.27.87 1.24 1.8 2.32 2.81 3.17 2.07 1.75 4.21 2.41 6.5 2.01-.92-1.05-1.37-2.01-1.4-2.09-.28-.62-.01-1.35.61-1.64.62-.28 1.35-.01 1.64.61.02.04 1.13 2.37 3.35 3.12.95.32 1.99.31 3.11-.03-1.81-2.02-3.16-4.69-4.18-7.58-3.25-9.2-3.07-20.78-3.05-21.49z"
    />
    <path
      d="M11.22 72.51c2.12 1.8 4.43 2.7 6.83 2.7 1.2 0 2.41-.23 3.64-.68.31.17.65.32 1 .44.74.25 1.5.38 2.29.38 1.19 0 2.43-.29 3.71-.87.24.17.49.34.75.49 2.85 1.69 5.52 2.54 7.98 2.54 1.07 0 2.11-.16 3.1-.48 1.96-.64 3.42-1.8 4.5-3a4.185 4.185 0 0 0 2.79 1.02c2.41 0 4.57-1.81 4.84-2.05 3.38-3.09 6.47-9.06 8.37-15.46l-2.75.54c-1.79 5.5-4.44 10.5-7.26 13.08-.53.46-2.21 1.61-3.53 1.4-.37-.06-.7-.25-1-.54.34-.58.59-1.1.76-1.48 1.19-2.76 1.75-6.64 2.33-10.77l-2.57.48c-.51 3.64-1.05 7.01-2.03 9.29-.4.94-1.96 4.07-5.21 5.13-2.56.83-5.6.22-9.06-1.83-2.67-1.58-4.52-4.68-5.82-8.28l-2.43.47c1.02 2.9 2.37 5.56 4.18 7.58-1.12.33-2.17.35-3.11.03-2.22-.75-3.33-3.08-3.35-3.12-.28-.62-1.02-.89-1.64-.61-.62.28-.9 1.02-.61 1.64.03.07.49 1.04 1.4 2.09-2.28.41-4.42-.25-6.5-2.01-1.01-.85-1.94-1.94-2.81-3.17l-2.65.52c1.15 1.76 2.43 3.32 3.86 4.53z"
      fill="#5f9501"
    />
    <path
      className="prefix__st2"
      d="M50.32 54.65a1.236 1.236 0 1 0-2.44-.4c-.26 1.56-.48 3.16-.69 4.7l-.18 1.3 2.57-.5.06-.46c.21-1.53.43-3.11.68-4.64z"
    />
    <path
      className="prefix__st2"
      d="M2.98 41.18c.72-2.94 2.09-5.04 4.07-6.24 1.57-.95 3.28-1.19 4.59-1.2-5.28 7.6-1.27 16.7-1.1 17.1a1.242 1.242 0 0 0 1.64.62c.62-.28.9-1.01.62-1.63-.04-.09-3.86-8.83 1.41-15.39.22-.28.46-.53.69-.76l.01-.01c3.31-3.27 7.86-2.57 10.48-1.76-5.53 4.36-5.98 11.49-5.99 11.62-.01.7-.2 12.29 3.04 21.51l2.43-.47c-3.2-8.86-3.01-20.8-3-20.94.03-.51.84-12.55 14.23-12.55 4.28 0 7.53 1.16 9.67 3.46 3.19 3.42 2.89 8.33 2.89 8.38-.05.68.46 1.27 1.14 1.32.69.04 1.27-.46 1.33-1.14.02-.24.4-5.99-3.53-10.22-.46-.5-.97-.95-1.5-1.36 2.87-.14 7.18.42 11.21 3.94 2.59 2.26 3.18 6.43 3.21 9.53.05 4.02-.83 8.71-2.25 13.1l2.75-.54c1.26-4.25 2.01-8.68 1.97-12.59-.06-5.17-1.46-9.1-4.06-11.37-6.94-6.06-14.61-4.52-16.5-4.01-.49-.17-.99-.32-1.52-.44-.58-.4-4.24-3.28-5.95-12.35-.07-.36-.12-.69-.18-1.04l-2.43.47c.06.34.11.67.18 1.02 1.08 5.74 2.93 9.28 4.49 11.38-.3-.01-.61-.02-.92-.02-3.29 0-5.93.65-8.05 1.67-.46-.21-.98-.41-1.54-.6 1.95-1.64 4.91-4.84 4.96-9.27.02-1.2.09-2.59.2-4.05l-2.52.49c-.12 1.77-.15 3.09-.15 3.52-.06 5.09-5.38 8.38-5.44 8.42-.08.05-.15.1-.21.16-3.13-.42-6.77-.01-9.67 2.49-1.42-.22-4.79-.47-7.89 1.39-2.58 1.55-4.34 4.17-5.22 7.78-1.09 4.45 1.12 18.59 6.78 27.37l2.65-.52c-5.48-7.83-8.05-22.04-7.02-26.27z"
    />
    <path
      d="M31.96 13.57c.11.83.25 1.72.41 2.63l2.43-.47c-1.62-9.33-.05-11.98.73-12.69.65-.59 1.37-.45 1.49-.41.01 0 .03.01.04.01.65.2 1.34-.16 1.54-.8.21-.65-.14-1.34-.79-1.55-.04-.01-.09-.03-.14-.04-1.28-.36-2.52-.14-3.59.63-3.49 2.54-4.58 11.01-4.92 15.95l2.52-.49c.07-.91.16-1.84.28-2.77z"
      fill="#c8d200"
    />
  </svg>
);

GradientCrop8.propTypes = {
  className: PropTypes.string,
};
