/* eslint-disable import/prefer-default-export */
/* eslint-disable react/prop-types */
import React from 'react';
import { PropTypes } from 'prop-types';
import { useLocalizedFromContext } from 'data/Localization';

export const Crop49 = ({ title, fill, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="138.633"
    height="160.832"
    viewBox="0 0 138.633 160.832"
    {...props}
  >
    <title>{useLocalizedFromContext(title)}</title>
    <g id="Component_2_1" dataname="Component 2 – 1" transform="translate(2.5 2.5)">
      <path
        id="Path_412"
        dataname="Path 412"
        d="M1467.163,7181.5a40.1,40.1,0,0,0,31.4-10.191c11.44-10.5,18.465-24.731,18.465-40.4,0-20.249-11.739-38.086-29.57-48.6a4.74,4.74,0,0,0-3.569-.484c-7.462,1.873-20.066,3.1-34.366,3.1-13.755,0-25.942-1.139-33.493-2.892a4.7,4.7,0,0,0-3.49.528c-17.591,10.545-29.145,28.257-29.145,48.343,0,15.668,7.026,29.894,18.465,40.4a40.246,40.246,0,0,0,31.52,10.179q1.344-.144,2.707-.267c4.572-.409,9.293-.624,14.125-.624A157.323,157.323,0,0,1,1467.163,7181.5Z"
        transform="translate(-1383.396 -7057.482)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_413"
        dataname="Path 413"
        d="M1433.262,7181.5c10.99,13.269-2.494,20.714-2.494,27.373s14.487,3.9,19.445,3.9,19.444,2.756,19.444-3.9-13.484-14.1-2.494-27.373"
        transform="translate(-1383.396 -7057.482)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_414"
        dataname="Path 414"
        d="M1456.582,7180.722s37.76.779,37.76-47.642c0-25.372-13.685-34.018-14.643-36.9"
        transform="translate(-1383.396 -7057.482)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_415"
        dataname="Path 415"
        d="M1424.844,7170.489c-7.87-7.559-19.446-18.058-18.995-39.961.454-22.069,17.891-43.131,24.153-46.426"
        transform="translate(-1383.396 -7057.482)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_416"
        dataname="Path 416"
        d="M1457.53,7170.489c.918-1.148,6.343-6.848,7.032-28.891s-7.98-45.417-7.98-45.417"
        transform="translate(-1383.396 -7057.482)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_417"
        dataname="Path 417"
        d="M1440.017,7057.482a10.746,10.746,0,1,0,21.492,0"
        transform="translate(-1383.396 -7057.482)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_418"
        dataname="Path 418"
        d="M1461.509,7057.482a10.746,10.746,0,1,0,21.492,0"
        transform="translate(-1383.396 -7057.482)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_419"
        dataname="Path 419"
        d="M1483,7057.482a10.747,10.747,0,0,0,10.747,10.746s-9.858,3.989-9.858,13.6"
        transform="translate(-1383.396 -7057.482)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_420"
        dataname="Path 420"
        d="M1417.919,7057.482a10.746,10.746,0,0,1-10.746,10.746s9.857,3.989,9.857,13.6"
        transform="translate(-1383.396 -7057.482)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_421"
        dataname="Path 421"
        d="M1407.779,7068.228a10.746,10.746,0,0,0,10.746-10.746"
        transform="translate(-1383.396 -7057.482)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_422"
        dataname="Path 422"
        d="M1418.525,7057.482a10.746,10.746,0,1,0,21.492,0"
        transform="translate(-1383.396 -7057.482)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_423"
        dataname="Path 423"
        d="M1473.3,7068.228s-8.5.987-8.5,15.874"
        transform="translate(-1383.396 -7057.482)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_424"
        dataname="Path 424"
        d="M1428.227,7068.228s8.5.987,8.5,15.874"
        transform="translate(-1383.396 -7057.482)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <line
        id="Line_33"
        dataname="Line 33"
        y2="16.694"
        transform="translate(67.367 10.746)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
    </g>
  </svg>
);

Crop49.defaultProps = {
  title: 'poppy',
};

Crop49.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
};
