/* eslint-disable import/prefer-default-export */
/* eslint-disable react/prop-types */
import React from 'react';
import { PropTypes } from 'prop-types';
import { useLocalizedFromContext } from 'data/Localization';

export const Crop39 = ({ title, fill, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="98.817"
    height="160.672"
    viewBox="0 0 98.817 160.672"
    {...props}
  >
    <title>{useLocalizedFromContext(title)}</title>
    <g id="Component_6_1" data-name="Component 6 – 1" transform="translate(2.5 2.56)">
      <path
        id="Path_799"
        data-name="Path 799"
        d="M1246.277,5202.178s-39.337-2.792-40.393,30.449c-.767,24.178-11.538,100.217-53.424,118.2,0,0,20.149,13.886,35.286,2.166,22.911-17.738,31.725-54.655,31.725-54.655s12.641-49.442,10.121-70.951c-1.549-13.212,5.347-23,16.685-25.206Z"
        transform="translate(-1152.46 -5202.121)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <circle
        id="Ellipse_30"
        data-name="Ellipse 30"
        cx="7.519"
        cy="7.519"
        r="7.519"
        transform="translate(25.158 136.113) rotate(-9.41)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <circle
        id="Ellipse_31"
        data-name="Ellipse 31"
        cx="8.115"
        cy="8.115"
        r="8.115"
        transform="translate(31.925 129.063) rotate(-45)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <circle
        id="Ellipse_32"
        data-name="Ellipse 32"
        cx="8.115"
        cy="8.115"
        r="8.115"
        transform="translate(40.04 114.269) rotate(-45)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <circle
        id="Ellipse_33"
        data-name="Ellipse 33"
        cx="8.115"
        cy="8.115"
        r="8.115"
        transform="translate(48.447 90.843)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <circle
        id="Ellipse_34"
        data-name="Ellipse 34"
        cx="8.115"
        cy="8.115"
        r="8.115"
        transform="translate(50.063 82.728) rotate(-45)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <circle
        id="Ellipse_35"
        data-name="Ellipse 35"
        cx="7.592"
        cy="7.592"
        r="7.592"
        transform="translate(57.908 58.383)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <circle
        id="Ellipse_36"
        data-name="Ellipse 36"
        cx="7.366"
        cy="7.366"
        r="7.366"
        transform="translate(60.781 43.651)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <circle
        id="Ellipse_37"
        data-name="Ellipse 37"
        cx="7.366"
        cy="7.366"
        r="7.366"
        transform="translate(61.937 28.919)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_800"
        data-name="Path 800"
        d="M1229.894,5231.682a11.989,11.989,0,1,0-23.977,0"
        transform="translate(-1152.46 -5202.121)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
    </g>
  </svg>
);

Crop39.defaultProps = {
  title: 'pea',
};

Crop39.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
};
