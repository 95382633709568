import React from 'react';
import PropTypes from 'prop-types';

const StepItemComponent = ({ isCompleted, text, stepNr }) => (
  <div className="step-item-component">
    <div className={`step-item-component__step-nr ${isCompleted ? 'completed' : ''}`}>{stepNr}</div>
    <div className="step-item-component__text">{text}</div>
  </div>
);

StepItemComponent.propTypes = {
  isCompleted: PropTypes.bool,
  text: PropTypes.string,
  stepNr: PropTypes.number,
};

export default StepItemComponent;
