/* eslint-disable import/prefer-default-export */
/* eslint-disable react/prop-types */
import React from 'react';
import { PropTypes } from 'prop-types';
import { useLocalizedFromContext } from 'data/Localization';

export const Crop21 = ({ title, fill, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="93.963"
    height="164.333"
    viewBox="0 0 93.963 164.333"
    {...props}
  >
    <title>{useLocalizedFromContext(title)}</title>
    <g id="Component_6_1" dataname="Component 6 – 1" transform="translate(2.5 2.526)">
      <g id="Group_98" dataname="Group 98" transform="translate(-965.19 -7057.482)">
        <path
          id="Path_380"
          dataname="Path 380"
          d="M1022.78,7097.57c-1.45,4.964-5.618,18.887-7.029,19.917-7.4,5.406-10.6-5.424-14.362-8.711-3.893-3.4-14.833-8.947.235-15.775,8.42-3.815,13.407-2.236,15.607-.983"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
        <path
          id="Path_381"
          dataname="Path 381"
          d="M1022.6,7098.081c-1.853,6.362-6.356,24,.688,24,8.594,0,8.947-3.078,12.832-4.011s15.892-1.4,12.713-9.115-20.13-13.125-20.13-13.125"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
        <path
          id="Path_382"
          dataname="Path 382"
          d="M998.488,7094.617s-8.459-.262-8.459-7.325,5.65-9.536,7.24-13.067,2.825-9.618,9.358-7.988c5.7,1.424,11.409,15.468,11.942,22.653"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
        <path
          id="Path_383"
          dataname="Path 383"
          d="M1028.313,7090.6c3.809-2.635,9.434-6.957,12.042-11.08,1.648-2.606,5.474-13.95,0-16.952s-8.83,2.119-10.949,0-9.182-7.063-12.713-4.238-8.046,7.364-5.389,11.642"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
        <path
          id="Path_384"
          dataname="Path 384"
          d="M1042.469,7102.3s9.716,3.447,10.636-2.23-1.94-5.449-1.613-10.111c.28-4,4.909-8.612,1.687-12.656a7.939,7.939,0,0,0-11.138-1.784"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
        <path
          id="Path_385"
          dataname="Path 385"
          d="M1020.543,7104.98s-17.91,97.714-17.91,111.808"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
        <path
          id="Path_386"
          dataname="Path 386"
          d="M999.3,7124.206s3.115,7.257,7.129,14.425c4.509,8.049,4.542,19.2,3.208,28.475-1.344,9.356-9.222-12.013-9.986-16.363C998.371,7143.437,997.3,7128.656,999.3,7124.206Z"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
        <path
          id="Path_387"
          dataname="Path 387"
          d="M965.19,7134.488s7.795-3.08,12.795,1.809c3.636,3.555,5.226,10.383,5.226,10.383S969.714,7146.828,965.19,7134.488Z"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
        <path
          id="Path_388"
          dataname="Path 388"
          d="M989.241,7125.779c-.2-1.407,3.521,9.378-1.1,15.408s-4.928,5.493-4.928,5.493-3.077-8.145-1.17-13.383a13.9,13.9,0,0,1,7.2-7.518"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
        <path
          id="Path_389"
          dataname="Path 389"
          d="M969.947,7132.612s-1.737-5.227-3.143-8.844,8.817,5.144,9.219-1.69,1.907-9.9,1.907-9.9,5.876,13.835,7.437,16.187"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
        <path
          id="Path_390"
          dataname="Path 390"
          d="M983.358,7146.68s6.719,38.222,13.5,51.644c6.071,12.009,5.977,14.884,5.977,14.884"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
        <path
          id="Path_391"
          dataname="Path 391"
          d="M1004.708,7199.091c.154-.461,10.849-18.989,15.477-23.567,7.667-7.584,16.613-6.178,21.117-10.168,2.464-2.182-11.946,21.419-22.114,29.253-6.231,4.8-14.48,5.249-14.48,5.249"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
      </g>
      <circle
        id="Ellipse_32"
        dataname="Ellipse 32"
        cx="5.17"
        cy="5.17"
        r="5.17"
        transform="translate(52.659 29.464)"
        strokeWidth="5"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </g>
  </svg>
);

Crop21.defaultProps = {
  title: 'fiber_flax',
};

Crop21.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
};
