import { SET_LAST_USED } from 'Redux/actions/types';

const initialState = {
  farmName: '',
  plotName: '',
  farmId: '',
  plotId: '',
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_LAST_USED:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};
