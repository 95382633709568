/* eslint-disable import/prefer-default-export */
/* eslint-disable react/prop-types */
import React from 'react';
import { PropTypes } from 'prop-types';
import { useLocalizedFromContext } from 'data/Localization';

export const GradientCrop47 = ({ title, ...props }) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 70 70"
    enableBackground="new 0 0 70 70"
    {...props}
  >
    <title>{useLocalizedFromContext(title)}</title>
    <g>
      <clipPath id="SVGID_2_">
        <path
          id="SVGID_81_"
          d="M47.4,58.7H37.3c0-1.9-0.1-5.6-0.1-10c0.6,0.2,1.1,0.3,1.6,0.3c0.6,0,1.2-0.1,1.7-0.3
          c2.7-1,4.5-4.4,6.1-11.2c0.2-0.6-0.1-1.3-0.7-1.6c-0.6-0.3-1.3-0.2-1.8,0.2c0,0-2.2,2.1-7,5.5c0-0.6,0-1.3,0-1.9c0,0,0,0,0-0.1
          c0-0.1,0-0.2,0-0.3c0-1.8,0-3.6,0-5.4c1.9-0.4,3-1.6,3.6-3.1c0.1-0.1,0.1-0.3,0.2-0.4c0.4-1.2,0.7-2.5,0.9-3.7l0.2-0.9
          c0.5-3.1,3.5-5.5,3.5-5.5c0.4-0.3,0.6-0.8,0.5-1.3c-0.1-0.5-0.4-0.9-0.8-1.2c-1.5-0.8-3.1-1-4.7-0.5v-4.9c0-1.7-0.6-3.4-1.8-4.9
          c-0.6-0.8-1.7-1.2-2.8-1.2c0,0,0,0,0,0c-1.1,0-2.1,0.4-2.8,1.2c0,0,0,0,0,0c-1.2,1.4-1.8,3.1-1.8,4.8v4.9
          c-1.7-0.5-3.4-0.3-5.1,0.6c-0.4,0.2-0.7,0.7-0.8,1.2c0,0.5,0.2,1,0.5,1.3c0,0,3,2.5,3.5,5.5l0.2,0.9c0.5,2.9,1.1,6.5,4.6,7.2
          c0,1.2,0,2.4,0,3.7c-2.8-1.3-6.6-4.8-8.6-7.2c-0.5-0.5-1.2-0.7-1.8-0.4c-0.6,0.3-0.9,1-0.8,1.7c1.6,6.6,3.9,10.4,7,11.6
          c0.6,0.2,1.3,0.4,2,0.4c0.8,0,1.5-0.2,2.3-0.5c0,0.4,0,0.8,0,1.2c0,0,0,0.1,0,0.1c0,1.4,0,2.8,0,4.2c-5.3-3.6-12-2.5-12.4-2.5
          c-0.8,0.1-1.3,0.8-1.2,1.6c0,0.8,0.6,1.4,1.4,1.4c1,0.1,2.1,1.2,3.2,2.4c1.1,1.2,2.4,2.6,4.1,3.3c1.8,0.7,3,0.9,4,0.9
          c0.3,0,0.6,0,0.9-0.1c0,1.2,0,2.2,0,3H23.8c-0.8,0-1.5,0.7-1.5,1.5s0.7,1.5,1.5,1.5h12c0,0,0,0,0,0h11.6c0.8,0,1.5-0.7,1.5-1.5
          S48.2,58.7,47.4,58.7z M30.9,40.4c-1.1-0.4-2.2-1.6-3.1-3.5c1.6,1.4,3.4,2.7,5.2,3.5C32.2,40.6,31.5,40.7,30.9,40.4z M42.4,41.4
          c-0.7,2-1.7,4-2.9,4.5c-0.2,0.1-0.9,0.3-2.3-0.4c0-0.1,0-0.2,0-0.2C39.3,43.7,41,42.5,42.4,41.4z M41.4,20c-1,1.3-2.1,3.1-2.5,5.2
          c-0.4-0.8-0.9-1.6-1.4-2.4C38.9,21.2,40.2,20.3,41.4,20z M34.1,12.3c0-1,0.4-2,1.2-3c0,0,0,0,0,0c0,0,0.2-0.1,0.5-0.1h0
          c0.3,0,0.4,0.1,0.5,0.1c0.8,0.9,1.2,1.9,1.2,3v6.5c-0.6,0.5-1.2,1-1.7,1.6c-0.5-0.5-1-1-1.5-1.5V12.3z M30.4,52.1
          c-1.1-0.4-2.1-1.5-3-2.6c-0.1-0.1-0.2-0.3-0.4-0.4c2.3,0.3,5.1,1.2,7.2,3.4c0,0,0,0.1,0,0.1C33.3,52.9,32.4,52.9,30.4,52.1z
          M32.5,26.2l-0.2-0.9c-0.4-2.2-1.6-4-2.6-5.3c0.3,0,0.6,0.1,0.9,0.2c3.3,1.3,6.1,7.1,7.2,9.8c-0.4,0.8-1,1.1-2,1.1
          c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0C33.5,31,33.1,30,32.5,26.2z"
        />
      </clipPath>
      <g clipPath="url(#SVGID_2_)">
        <rect
          x="4.5"
          y="47.6"
          transform="matrix(0.9736 -0.2284 0.2284 0.9736 -12.1137 10.7813)"
          fill="#B95700"
          width="72.1"
          height="20.2"
        />
        <rect
          x="1"
          y="38.2"
          transform="matrix(0.9736 -0.2284 0.2284 0.9736 -8.8637 9.6109)"
          fill="#EB6909"
          width="72.1"
          height="9.9"
        />
        <rect
          x="-1.2"
          y="28.5"
          transform="matrix(0.9736 -0.2284 0.2284 0.9736 -6.7258 8.8411)"
          fill="#5F9501"
          width="72.1"
          height="9.9"
        />
        <rect
          x="-3.5"
          y="18.9"
          transform="matrix(0.9736 -0.2284 0.2284 0.9736 -4.588 8.0712)"
          fill="#79B61D"
          width="72.1"
          height="9.9"
        />
        <rect
          x="-6.9"
          y="-0.9"
          transform="matrix(0.9736 -0.2284 0.2284 0.9736 -1.3367 6.9004)"
          fill="#C8D200"
          width="72.1"
          height="20.2"
        />
      </g>
    </g>
  </svg>
);

GradientCrop47.defaultProps = {
  title: 'maise',
};

GradientCrop47.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
};
