export const GTMEventTypes = {
  PAGE_INITIALIZED: 'PAGE_INITIALIZED',
  SIGN_IN_BUTTON_PRESSED: 'SIGN_IN_BUTTON_PRESSED',
  USER_REGISTERED: 'USER_REGISTERED',
  PLAN_CREATED: 'PLAN_CREATED',
  VIRTUAL_PAGE_VIEW: 'VIRTUAL_PAGE_VIEW',
  RESELLER_OPENED_SUBSCRIPTION_DIALOG: 'RESELLER_OPENED_SUBSCRIPTION_DIALOG',
  FARMER_OPENED_SUBSCRIPTION_DIALOG: 'FARMER_OPENED_SUBSCRIPTION_DIALOG',
  RESELLER_SUBSCRIBED: 'RESELLER_SUBSCRIBED',
  FARMER_SUBSCRIBED: 'FARMER_SUBSCRIBED',
  NG4_USER_REGISTRATION_START_SHORT: 'NG4_USER_REGISTRATION_START_SHORT',
  NG4_USER_REGISTRATION_START_LONG: 'NG4_USER_REGISTRATION_START_LONG',
  NG4_PLAN_CREATION_START_SHORT: 'NG4_PLAN_CREATION_START_SHORT',
  NG4_PLAN_CREATION_START_LONG: 'NG4_PLAN_CREATION_START_LONG',
};

export const ButtonIds = {
  tryNutriguideFromHeader: 'LP_NGtry1',
  loginTop: 'LP_Login1',
  tryNutriguideFromTopCarousel: 'LP_NGtry2',
  tryNutriZonesFromTopCarousel: 'LP_NZtry1',
  videoTopImage: 'LP_videoClick1',
  videoTopButton: 'LP_videoClick2',
  videoSecondTopButton: 'LP_videoClick5',
  tryNutriguideFromPhoneMockSection: 'LP_NGtry3',
  videoFarmer: 'LP_videoClick3',
  videoReseller: 'LP_videoClick4',
  tryNutriguideFromVideos: 'LP_NGtry4',
  visitLat: 'LP_LATvisit',
  tryFarmDok: 'LP_NGtry5',
  changeLang: 'LP_ChangeLang',
  tryNgFromThirdSlider: 'LP_NGtry6',
  tryNGFromPromotion: 'LP_NGtry7',
  ngReferral: 'LP_NGreferral',
  nutrizonesIStore: 'LP_NZ_I_Store',
  nutrizonesPlayStore: 'LP_NZ_Play_Store',
  nutrizonesTestimonialVideo: 'LP_NZ_Testimonial_Video',
  nutrizonesBrandVideo: 'LP_NZ_Brand_Video',
  loginNZ: 'LP_NZ_Login',
};
