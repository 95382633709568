/* eslint-disable import/prefer-default-export */
/* eslint-disable react/prop-types */
import React from 'react';
import { PropTypes } from 'prop-types';
import { useLocalizedFromContext } from 'data/Localization';

export const Crop44 = ({ title, fill, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="104.466"
    height="152.49"
    viewBox="0 0 104.466 152.49"
    {...props}
  >
    <title>{useLocalizedFromContext(title)}</title>
    <g id="Component_6_1" data-name="Component 6 – 1" transform="translate(2.515 2.5)">
      <path
        id="Path_1620"
        data-name="Path 1620"
        d="M255.367,7178.872a12.441,12.441,0,0,1-1.213-1.478c-5.109-7.351-17.59-29.091-2.255-60.888,15.786-32.733,54.348-26.027,77.576-54.508,0,0,28.64,41.88,5.187,88.335-18.674,36.989-52.79,40.666-70.585,33.912"
        transform="translate(-244.509 -7061.998)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_1621"
        data-name="Path 1621"
        d="M244.873,7206s5.667-25.666,29.667-51.666c23.567-25.531,30-36.584,30-36.584s-7.217,10.262-32.167,49.584c-15.333,24.166-16,41-16,41S248.1,7212.1,244.873,7206Z"
        transform="translate(-244.509 -7061.998)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
    </g>
  </svg>
);

Crop44.defaultProps = {
  title: 'others',
};

Crop44.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
};
