/* eslint-disable import/prefer-default-export */
/* eslint-disable react/prop-types */
import React from 'react';
import { PropTypes } from 'prop-types';
import { useLocalizedFromContext } from 'data/Localization';

export const Crop36 = ({ title, fill, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="39.876"
    height="156.83"
    viewBox="0 0 39.876 156.83"
    {...props}
  >
    <title>{useLocalizedFromContext(title)}</title>
    <g id="Component_4_1" data-name="Component 4 – 1" transform="translate(2.666 3.771)">
      <path
        id="Path_883"
        data-name="Path 883"
        d="M312.481,4070.145a7.89,7.89,0,0,1-11.158-11.158c3.081-3.081,14.961-3.8,14.961-3.8S315.562,4067.064,312.481,4070.145Z"
        transform="translate(-281.741 -3950.187)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeMiterlimit="10"
        strokeWidth="5"
      />
      <path
        id="Path_884"
        data-name="Path 884"
        d="M291.948,4056.762c2.023-4.51,7.064-10.2,7.064-10.2s5.042,5.693,7.065,10.2"
        transform="translate(-281.741 -3950.187)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeMiterlimit="10"
        strokeWidth="5"
      />
      <path
        id="Path_885"
        data-name="Path 885"
        d="M285.544,4070.145a7.89,7.89,0,1,0,11.158-11.158c-3.082-3.081-14.961-3.8-14.961-3.8S282.462,4067.064,285.544,4070.145Z"
        transform="translate(-281.741 -3950.187)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeMiterlimit="10"
        strokeWidth="5"
      />
      <path
        id="Path_886"
        data-name="Path 886"
        d="M299.2,4046.771a7.863,7.863,0,0,1,2.126-3.875c3.081-3.081,14.961-3.8,14.961-3.8s-.722,11.879-3.8,14.96a7.86,7.86,0,0,1-3.238,1.957"
        transform="translate(-281.741 -3950.187)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeMiterlimit="10"
        strokeWidth="5"
      />
      <path
        id="Path_887"
        data-name="Path 887"
        d="M291.948,4040.671c2.023-4.51,7.064-10.2,7.064-10.2s5.042,5.694,7.065,10.2"
        transform="translate(-281.741 -3950.187)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeMiterlimit="10"
        strokeWidth="5"
      />
      <path
        id="Path_888"
        data-name="Path 888"
        d="M298.861,4046.932a7.849,7.849,0,0,0-2.159-4.036c-3.082-3.081-14.961-3.8-14.961-3.8s.721,11.879,3.8,14.96a7.853,7.853,0,0,0,4.212,2.192"
        transform="translate(-281.741 -3950.187)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeMiterlimit="10"
        strokeWidth="5"
      />
      <path
        id="Path_889"
        data-name="Path 889"
        d="M299.2,4030.68a7.859,7.859,0,0,1,2.126-3.875c3.081-3.081,14.961-3.8,14.961-3.8s-.722,11.879-3.8,14.961a7.86,7.86,0,0,1-3.238,1.957"
        transform="translate(-281.741 -3950.187)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeMiterlimit="10"
        strokeWidth="5"
      />
      <path
        id="Path_890"
        data-name="Path 890"
        d="M291.948,4024.58c2.023-4.511,7.064-10.2,7.064-10.2s5.042,5.693,7.065,10.2"
        transform="translate(-281.741 -3950.187)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeMiterlimit="10"
        strokeWidth="5"
      />
      <path
        id="Path_891"
        data-name="Path 891"
        d="M298.861,4030.841a7.849,7.849,0,0,0-2.159-4.036c-3.082-3.081-14.961-3.8-14.961-3.8s.721,11.879,3.8,14.961a7.853,7.853,0,0,0,4.212,2.192"
        transform="translate(-281.741 -3950.187)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeMiterlimit="10"
        strokeWidth="5"
      />
      <path
        id="Path_892"
        data-name="Path 892"
        d="M299.2,4014.589a7.858,7.858,0,0,1,2.126-3.875c3.081-3.082,14.961-3.8,14.961-3.8s-.722,11.879-3.8,14.96a7.865,7.865,0,0,1-3.238,1.958"
        transform="translate(-281.741 -3950.187)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeMiterlimit="10"
        strokeWidth="5"
      />
      <path
        id="Path_893"
        data-name="Path 893"
        d="M291.948,4008.489c2.023-4.511,7.064-10.2,7.064-10.2s5.042,5.693,7.065,10.2"
        transform="translate(-281.741 -3950.187)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeMiterlimit="10"
        strokeWidth="5"
      />
      <path
        id="Path_894"
        data-name="Path 894"
        d="M298.861,4014.75a7.849,7.849,0,0,0-2.159-4.036c-3.082-3.082-14.961-3.8-14.961-3.8s.721,11.879,3.8,14.96a7.849,7.849,0,0,0,4.212,2.193"
        transform="translate(-281.741 -3950.187)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeMiterlimit="10"
        strokeWidth="5"
      />
      <path
        id="Path_895"
        data-name="Path 895"
        d="M299.2,3998.5a7.861,7.861,0,0,1,2.126-3.876c3.081-3.081,14.961-3.8,14.961-3.8s-.722,11.879-3.8,14.96a7.862,7.862,0,0,1-3.238,1.958"
        transform="translate(-281.741 -3950.187)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeMiterlimit="10"
        strokeWidth="5"
      />
      <path
        id="Path_896"
        data-name="Path 896"
        d="M291.948,3992.4c2.023-4.51,7.064-10.2,7.064-10.2s5.042,5.693,7.065,10.2"
        transform="translate(-281.741 -3950.187)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeMiterlimit="10"
        strokeWidth="5"
      />
      <path
        id="Path_897"
        data-name="Path 897"
        d="M298.861,3998.659a7.855,7.855,0,0,0-2.159-4.037c-3.082-3.081-14.961-3.8-14.961-3.8s.721,11.879,3.8,14.96a7.848,7.848,0,0,0,4.212,2.192"
        transform="translate(-281.741 -3950.187)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeMiterlimit="10"
        strokeWidth="5"
      />
      <path
        id="Path_898"
        data-name="Path 898"
        d="M299.2,3982.5a7.862,7.862,0,0,1,2.126-3.876c3.081-3.081,14.961-3.8,14.961-3.8s-.722,11.879-3.8,14.96a7.849,7.849,0,0,1-3.238,1.957"
        transform="translate(-281.741 -3950.187)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeMiterlimit="10"
        strokeWidth="5"
      />
      <path
        id="Path_899"
        data-name="Path 899"
        d="M291.948,3976.394c2.023-4.51,7.064-10.2,7.064-10.2s5.042,5.694,7.065,10.2"
        transform="translate(-281.741 -3950.187)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeMiterlimit="10"
        strokeWidth="5"
      />
      <path
        id="Path_900"
        data-name="Path 900"
        d="M298.861,3982.656a7.852,7.852,0,0,0-2.159-4.037c-3.082-3.081-14.961-3.8-14.961-3.8s.721,11.879,3.8,14.96a7.848,7.848,0,0,0,4.212,2.192"
        transform="translate(-281.741 -3950.187)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeMiterlimit="10"
        strokeWidth="5"
      />
      <path
        id="Path_901"
        data-name="Path 901"
        d="M299.2,3966.491a7.863,7.863,0,0,1,2.126-3.875c3.081-3.081,14.961-3.8,14.961-3.8s-.722,11.879-3.8,14.96a7.86,7.86,0,0,1-3.238,1.957"
        transform="translate(-281.741 -3950.187)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeMiterlimit="10"
        strokeWidth="5"
      />
      <path
        id="Path_902"
        data-name="Path 902"
        d="M291.948,3960.391c2.023-4.51,7.064-10.2,7.064-10.2s5.042,5.694,7.065,10.2"
        transform="translate(-281.741 -3950.187)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeMiterlimit="10"
        strokeWidth="5"
      />
      <path
        id="Path_903"
        data-name="Path 903"
        d="M298.861,3966.652a7.849,7.849,0,0,0-2.159-4.036c-3.082-3.081-14.961-3.8-14.961-3.8s.721,11.879,3.8,14.96a7.847,7.847,0,0,0,4.212,2.192"
        transform="translate(-281.741 -3950.187)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeMiterlimit="10"
        strokeWidth="5"
      />
      <line
        id="Line_69"
        data-name="Line 69"
        y2="36.926"
        transform="translate(17.12 113.633)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="5"
      />
    </g>
  </svg>
);

Crop36.defaultProps = {
  title: 'cereal',
};

Crop36.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
};
