/* eslint-disable import/prefer-default-export */
/* eslint-disable react/prop-types */
import React from 'react';
import { PropTypes } from 'prop-types';
import { useLocalizedFromContext } from 'data/Localization';

export const GradientCrop17 = ({ title, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="71.533"
    height="88.462"
    viewBox="0 0 71.533 88.462"
    {...props}
  >
    <title>{useLocalizedFromContext(title)}</title>
    <defs>
      <clipPath id="clip-path17">
        <path
          id="Path_212"
          dataname="Path 212"
          d="M279.744,25.213c-.313-.126-7.836-3-20.515,3.465-.492.25-.989.5-1.486.754l1.323-1.285C269.293,18.253,269.017,10.2,269,9.941a9.931,9.931,0,0,0-18.383-5.225,9.93,9.93,0,0,0-18.381,5.151c-.016.338-.293,8.386,9.934,18.28.434.421.874.847,1.31,1.273l-1.461-.742c-12.678-6.463-20.2-3.59-20.445-3.491a9.938,9.938,0,0,0,1.016,19.092,9.939,9.939,0,0,0,7.687,16.235,9.72,9.72,0,0,0,3.156-.527c.325-.094,8.026-2.445,14.068-15.329.534-1.139,1.087-2.307,1.616-3.418V86.962a1.5,1.5,0,1,0,3,0V41.216c.532,1.117,1.09,2.3,1.628,3.442,6.042,12.884,13.743,15.235,14,15.306a9.931,9.931,0,0,0,10.913-15.685,9.937,9.937,0,0,0,1.086-19.066ZM259.061,3A6.964,6.964,0,0,1,266,10c0,.07.133,7.13-9.023,15.987-1.7,1.641-3.472,3.384-4.861,4.754V9.941A6.949,6.949,0,0,1,259.061,3ZM235.236,9.941a6.942,6.942,0,0,1,13.883,0v20.8c-1.39-1.37-3.163-3.111-4.861-4.754C235.1,17.134,235.232,10.074,235.236,9.941Zm-17,26.9A6.944,6.944,0,0,1,222.608,28a12.654,12.654,0,0,1,4.018-.52c3.086,0,7.863.723,14.032,3.868,2.1,1.071,4.327,2.186,6.073,3.056l-19.676,6.749A6.947,6.947,0,0,1,218.235,36.843Zm26.551,6.542c-5.408,11.534-12.127,13.7-12.254,13.741a6.942,6.942,0,0,1-4.505-13.132l19.678-6.75C246.86,39,245.789,41.246,244.786,43.385Zm32.75,9.428a6.967,6.967,0,0,1-8.878,4.3c-.067-.021-6.786-2.189-12.194-13.723-1-2.139-2.074-4.382-2.919-6.141l19.678,6.75a6.941,6.941,0,0,1,4.313,8.819Zm5.479-15.97a6.949,6.949,0,0,1-8.82,4.313l-19.676-6.749c1.746-.87,3.97-1.985,6.073-3.056,11.348-5.786,17.983-3.372,18.109-3.326a6.94,6.94,0,0,1,4.314,8.818Z"
          fill="none"
        />
      </clipPath>
    </defs>
    <g id="Group_90" dataname="Group 90" transform="translate(-214.859)">
      <g id="Group_89" dataname="Group 89" clipPath="url(#clip-path17)">
        <g id="Group_88" dataname="Group 88">
          <path
            id="Path_209"
            dataname="Path 209"
            d="M292.75,88.462V53.44L208.568,73.159v15.3Z"
            fill="#5f9501"
          />
          <path
            id="Path_210"
            dataname="Path 210"
            d="M208.568,34.492V73.159L292.75,53.44V14.774Z"
            fill="#79b61d"
          />
          <path
            id="Path_211"
            dataname="Path 211"
            d="M208.568.006V34.492L292.75,14.774V.006Z"
            fill="#c8d200"
          />
        </g>
      </g>
    </g>
  </svg>
);

GradientCrop17.defaultProps = {
  title: 'clover',
};

GradientCrop17.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
};
