/* eslint-disable import/prefer-default-export */
/* eslint-disable react/prop-types */
import React from 'react';
import { PropTypes } from 'prop-types';
import { useLocalizedFromContext } from 'data/Localization';

export const Crop40 = ({ title, fill, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="142.641"
    height="169.827"
    viewBox="0 0 142.641 169.827"
    {...props}
  >
    <title>{useLocalizedFromContext(title)}</title>
    <g id="Component_2_1" data-name="Component 2 – 1" transform="translate(2.5 2.5)">
      <g id="Group_188" data-name="Group 188" transform="translate(-234.308 -5203.638)">
        <g id="Group_187" data-name="Group 187">
          <path
            id="Path_809"
            data-name="Path 809"
            d="M266.016,5267.843c-17.88,3.037-31.708,19.753-31.708,44.119s13.828,42.4,31.708,44.119c10.746,1.036,26.058-6.705,26.058-19.523,0-16.727-8.972-15.252-8.972-24.6,0-8.871,9.5-7.263,9.5-24.595C292.6,5274.026,276.995,5265.978,266.016,5267.843Z"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
          <path
            id="Path_810"
            data-name="Path 810"
            d="M274.838,5319.7s-5.27-6.631,0-15.642"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
        <path
          id="Path_811"
          data-name="Path 811"
          d="M260.027,5282.376s-12.547,6.838-14.3,25.163"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
        <path
          id="Path_812"
          data-name="Path 812"
          d="M272.015,5278.641a11.3,11.3,0,0,1,6.437,2.485"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
      </g>
      <circle
        id="Ellipse_38"
        data-name="Ellipse 38"
        cx="27.174"
        cy="27.174"
        r="27.174"
        transform="translate(59.746 125.361) rotate(-45)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_813"
        data-name="Path 813"
        d="M346.61,5329a14.126,14.126,0,0,0-14.126-14.127"
        transform="translate(-234.308 -5203.638)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <ellipse
        id="Ellipse_39"
        data-name="Ellipse 39"
        cx="28.227"
        cy="36.572"
        rx="28.227"
        ry="36.572"
        transform="translate(72.956 1.184)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_814"
        data-name="Path 814"
        d="M320.99,5238.773c0-11.523,6.154-20.864,13.745-20.864"
        transform="translate(-234.308 -5203.638)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_815"
        data-name="Path 815"
        d="M267.862,5207.708s-6.369-4.07-12.914-4.07a10.647,10.647,0,0,0-6.142,2.389,18,18,0,0,0-7.252,12.905c-.993,10.858.3,32.468,26.308,32.468"
        transform="translate(-234.308 -5203.638)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_816"
        data-name="Path 816"
        d="M267.862,5251.4c26.007,0,27.3-21.61,26.308-32.468a18,18,0,0,0-7.253-12.905,10.647,10.647,0,0,0-6.142-2.389c-6.545,0-12.913,4.07-12.913,4.07s-5.325,7.534-2.483,21.58"
        transform="translate(-234.308 -5203.638)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
    </g>
  </svg>
);

Crop40.defaultProps = {
  title: 'legumes',
};

Crop40.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
};
