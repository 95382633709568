/* eslint-disable import/prefer-default-export */
/* eslint-disable react/prop-types */
import React from 'react';
import { PropTypes } from 'prop-types';
import { useLocalizedFromContext } from 'data/Localization';

export const Crop2 = ({ title, fill, ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="57" height="78" viewBox="0 0 40 55.26" {...props}>
    <title>{useLocalizedFromContext(title)}</title>

    <path
      d="M40,13.49c-.39,1-.84,1.11-1.76.57l-.36-.21c0,.13,0,.21,0,.29a.93.93,0,0,1-1.43.81,2.18,2.18,0,0,1-1.22-2.22c0-.15,0-.3,0-.52l-.47.18a.85.85,0,0,1-1.18-.68c0-.46-.27-.6-.66-.74,0,.35-.11.65.28.91a.9.9,0,0,1,0,1.45c-.1.09-.21.16-.38.29l.36.18a2.14,2.14,0,0,1,1.36,1.8,1.24,1.24,0,0,1-.39,1.27.94.94,0,0,1-1.23-.12c-.08.77.19,1.77-1,1.76s-.87-1-.92-1.69a3.67,3.67,0,0,1-.64.21.87.87,0,0,1-1-.7,2.19,2.19,0,0,1,1.21-2.47l.45-.2a2.57,2.57,0,0,0-.19-.21c-.69-.51-.68-1.11-.05-1.7.16-.15.17-.47.27-.81-.48.16-.74.28-.76.74a.85.85,0,0,1-1.16.72l-.51-.2c0,.19,0,.32.05.44a2.17,2.17,0,0,1-.9,2.12,1.15,1.15,0,0,1-1.21.24c-.38-.18-.53-.48-.55-1.14-.66.35-1.35,1-2,0-.33-.51-.09-.86,1-1.67L24.91,12a1,1,0,0,1,0-1.56,2.1,2.1,0,0,1,2.59-.08l.45.33c0-.23,0-.37.06-.51a.85.85,0,0,1,1.19-.72c.43.23.65,0,1-.22-.32-.2-.54-.41-1-.2A.85.85,0,0,1,28,8.32c0-.13,0-.26-.06-.49l-.38.28a2.07,2.07,0,0,1-2.32.19,1.07,1.07,0,0,1-.71-1,1,1,0,0,1,.72-1l-.67-.42a1,1,0,0,1-.37-1.33.92.92,0,0,1,1.34-.27l.59.34a3.07,3.07,0,0,1,0-.38.92.92,0,0,1,1.38-.73,2.2,2.2,0,0,1,1.26,2.29c0,.14,0,.28,0,.5a4.91,4.91,0,0,1,.45-.21.89.89,0,0,1,1.22.71c.07.46.07.46.63.7,0-.35.11-.64-.28-.91a.9.9,0,0,1,0-1.45L31.05,5s0,0,0-.09-.16-.1-.25-.13a2.11,2.11,0,0,1-1.38-1.88c-.11-1.14.55-1.62,1.62-1.14C31.11,1,30.89.07,32,0c.62,0,.8.34.91,1.75.13-.06.24-.14.37-.19a.9.9,0,0,1,1.21.61,2.19,2.19,0,0,1-1.17,2.49l-.53.25.38.28a.91.91,0,0,1,.08,1.42.82.82,0,0,0-.33,1c.33-.15.63-.23.64-.72a.88.88,0,0,1,1.21-.72l.45.22c0-.24,0-.4,0-.56a2.17,2.17,0,0,1,1.18-2.19.94.94,0,0,1,1.47.84c0,.06,0,.11,0,.15a5.61,5.61,0,0,1,1.26-.41c.5,0,.69.42.87.82v.18L38.92,6.42a.94.94,0,0,1,.58.94,1.15,1.15,0,0,1-.85,1,2,2,0,0,1-2.15-.23l-.4-.29c0,.24-.05.39-.08.54a.84.84,0,0,1-1.14.69c-.44-.21-.68,0-1,.2.32.21.54.43,1,.21a.85.85,0,0,1,1.18.68c0,.16,0,.31.07.43A9,9,0,0,1,37.48,10a1.83,1.83,0,0,1,1.74.55.87.87,0,0,1,.26.71,4.31,4.31,0,0,1-.55,1c.88.65.88.65,1.07,1.13Z"
      fill={fill || '#71bf44'}
    />

    <path
      d="M9.48,21.78a8.73,8.73,0,0,1,6.16-3.35A1.37,1.37,0,0,0,16.87,17V7.34a1.65,1.65,0,0,1,.07-.59.92.92,0,0,1,1-.64.94.94,0,0,1,.91.72,2.35,2.35,0,0,1,.06.65c0,3.34,0,6.68,0,10a1.89,1.89,0,0,1-.68,1.68,2.17,2.17,0,0,0-.79,1.59,9.84,9.84,0,0,1-1.71,4.38A6.17,6.17,0,0,1,10,27.73c-.6,0-1.21,0-1.81,0A6.09,6.09,0,0,1,2.49,24,19.26,19.26,0,0,1,1.43,20.5a1.75,1.75,0,0,0-.58-1.15,2.27,2.27,0,0,1-.85-2C0,14,0,10.61,0,7.25a1,1,0,1,1,2,0c0,.69,0,1.39,0,2.09v7.83a1.32,1.32,0,0,0,1.17,1.3,9.5,9.5,0,0,1,5,2.07C8.67,20.87,9,21.33,9.48,21.78Zm1,3.81c2.48.3,4.45-1.9,4.86-4.95C12.52,21.1,10.38,22.82,10.47,25.59Zm-2.12,0c.39-2.33-1.72-4.57-4.78-4.95C4,23.77,5.92,25.8,8.35,25.62Z"
      fill={fill || '#71bf44'}
    />
    <path
      d="M9.42,39.33c.37-.4.69-.8,1.06-1.14A9.4,9.4,0,0,1,15.61,36a1.34,1.34,0,0,0,.88-.44,8.14,8.14,0,0,1,.64-.67,1,1,0,0,1,1.43,0,1,1,0,0,1,.05,1.46A4,4,0,0,0,17.29,39a8.72,8.72,0,0,1-1.86,4.1,6,6,0,0,1-4.59,2.21l-.29,0V54a1.89,1.89,0,0,1-.09.63.93.93,0,0,1-1,.64.92.92,0,0,1-.9-.67,2.29,2.29,0,0,1-.08-.69V45.33c-.56-.07-1.1-.09-1.62-.2A6.15,6.15,0,0,1,2.39,41.3a19.93,19.93,0,0,1-1-3.25,1.77,1.77,0,0,0-.57-1.16,5,5,0,0,1-.55-.57,1.06,1.06,0,0,1,0-1.5,1,1,0,0,1,1.44.08A3.53,3.53,0,0,0,4,36.12,8,8,0,0,1,9.42,39.33Zm1.07,3.84c2.48.15,4.81-2.27,4.83-5C12.3,38.67,10.34,40.55,10.49,43.17Zm-6.91-5c0,2.66,2.5,5.24,4.8,4.94C8.7,40.77,6.19,38.19,3.58,38.21Z"
      fill={fill || '#71bf44'}
    />
    <path
      d="M9.45,30.57a8.17,8.17,0,0,1,5.46-3.27,3.12,3.12,0,0,0,2.15-1.16,1.06,1.06,0,0,1,.53-.35.94.94,0,0,1,1.05.31,1,1,0,0,1,.14,1.14.61.61,0,0,1-.15.23,3.74,3.74,0,0,0-1.33,2.67,8.63,8.63,0,0,1-1.58,3.74A5.89,5.89,0,0,1,11,36.45a31.25,31.25,0,0,1-3.21,0A6.07,6.07,0,0,1,2.64,33,10.55,10.55,0,0,1,1.42,29.2a1.5,1.5,0,0,0-.53-1.09,4.43,4.43,0,0,1-.64-.66A1.05,1.05,0,0,1,.38,26a.94.94,0,0,1,1.37.09,3.65,3.65,0,0,0,2.4,1.27A8,8,0,0,1,9.45,30.57Zm5.92-1.21c-3,.36-5.31,2.8-4.85,5C12.93,34.54,14.87,32.55,15.37,29.36Zm-11.79,0c-.11,2.59,2.57,5.35,4.8,5C8.63,31.92,6.73,29.9,3.58,29.37Z"
      fill={fill || '#71bf44'}
    />
    <path
      d="M10.46,7.86a1.76,1.76,0,0,0,.45,1.38,9.29,9.29,0,0,1,2,5.75A6.54,6.54,0,0,1,10,20.13,1,1,0,0,1,8.72,20a6.66,6.66,0,0,1-2.33-7.65A11.21,11.21,0,0,1,8.13,9a1.36,1.36,0,0,0,.3-.94,6.41,6.41,0,0,1,0-1,1,1,0,0,1,1.05-1,1,1,0,0,1,1,1.08Zm-1,3c-1.78,2.38-2,5.43,0,7C11.42,16,11.18,13.29,9.46,10.82Z"
      fill={fill || '#71bf44'}
    />
  </svg>
);

Crop2.defaultProps = {
  title: 'winter_wheat',
};

Crop2.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
};
