/* eslint-disable import/prefer-default-export */
/* eslint-disable react/prop-types */
import React from 'react';
import { PropTypes } from 'prop-types';
import { useLocalizedFromContext } from 'data/Localization';

export const GradientCrop50 = ({ title, ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" {...props}>
    <title>{useLocalizedFromContext(title)}</title>
    <defs>
      <clipPath id="b">
        <rect width="50" height="50" />
      </clipPath>
    </defs>
    <g id="a" clipPath="url(#b)">
      <rect width="50" height="50" fill="#fff" />
      <g transform="translate(-222.732 -9.719)">
        <path
          d="M256.5,53.128a10.281,10.281,0,0,1,4.371,1.1,8.807,8.807,0,0,1,.316-4.043,13.956,13.956,0,0,0,.608-3.206c.021-.292.044-.582.044-.891,0-2.2-.748-4.779-5.986-7.49a14.178,14.178,0,0,1-2.443,5.432,1.149,1.149,0,0,1-1.276.385,5.981,5.981,0,0,1-3.286-2.668,10.189,10.189,0,0,0-.758,6.019,6.587,6.587,0,0,0,.691,2.266C250.229,52.723,253.465,53.06,256.5,53.128Zm.265-10.3a1.15,1.15,0,1,1,1.063-2.04,6.476,6.476,0,0,1,3.333,6.336,8.781,8.781,0,0,1-.679,2.592,4.239,4.239,0,0,1-3.815,2.784,3.65,3.65,0,0,1-.631-.054l-.178-.033a1.15,1.15,0,1,1,.469-2.252l.1.019a1.912,1.912,0,0,0,1.935-1.357,6.951,6.951,0,0,0,.364-1.128A4.237,4.237,0,0,0,256.767,42.827Z"
          fill="none"
        />
        <path
          d="M258.365,48.822a1.912,1.912,0,0,1-1.935,1.357l-.1-.019a1.15,1.15,0,1,0-.469,2.252l.178.033a3.65,3.65,0,0,0,.631.054,4.239,4.239,0,0,0,3.815-2.784,8.781,8.781,0,0,0,.679-2.592l-2.434.571A6.951,6.951,0,0,1,258.365,48.822Z"
          fill="#b95700"
        />
        <path
          d="M261.189,50.181a8.807,8.807,0,0,0-.316,4.043,10.281,10.281,0,0,0-4.371-1.1c-3.037-.068-6.273-.4-7.719-3.1l-2.293.538c2.023,4.674,7.448,4.8,9.961,4.862a8.006,8.006,0,0,1,4.031,1.21,1.882,1.882,0,0,0,2.808-2.085,6.5,6.5,0,0,1,.09-3.67,15.743,15.743,0,0,0,.748-4.455l-2.331.547A13.956,13.956,0,0,1,261.189,50.181Z"
          fill="#b95700"
        />
        <path
          d="M236.353,58.28h.041a1.151,1.151,0,0,0,1.11-1.19c0-.04-.072-2.051-.106-4.39l-2.293.537c.036,2.08.094,3.784.1,3.934A1.15,1.15,0,0,0,236.353,58.28Z"
          fill="#b95700"
        />
        <path
          d="M238.8,57.175a1.075,1.075,0,0,0,1.255,1c1.226-.049,1.043-1.087,1.043-1.087s-.093-2.369-.206-5.2l-2.281.535C238.715,55.049,238.8,57.175,238.8,57.175Z"
          fill="#b95700"
        />
        <path
          d="M237.5,46.369a3.578,3.578,0,0,0-1.532-3.406L235.9,42.9l-2.606.611a8.724,8.724,0,0,0,1.162,1.182c.654.574.83.727.758,1.447-.157,1.57-.147,4.6-.105,7.1L237.4,52.7C237.363,50.375,237.363,47.728,237.5,46.369Z"
          fill="#eb6909"
        />
        <path
          d="M238.232,43.094c.068,1.574.241,5.881.378,9.32l2.281-.535c-.134-3.361-.3-7.376-.362-8.884-.017-.392-.03-.782-.043-1.172l-2.281.535C238.214,42.6,238.221,42.849,238.232,43.094Z"
          fill="#eb6909"
        />
        <path
          d="M252.109,41.874a2.325,2.325,0,0,1-1.343-2.462l-3.719.872a9.505,9.505,0,0,0-.777,1.723,3.177,3.177,0,0,1-.655-.173c.2-.4.439-.879.676-1.373l-2.9.682c-.206.4-.35.682-.354.69a1.151,1.151,0,0,0,.457,1.535,7.432,7.432,0,0,0,2.278.879,15.024,15.024,0,0,0,.045,3.816,9.334,9.334,0,0,0,.677,2.5l2.293-.538a6.587,6.587,0,0,1-.691-2.266,10.189,10.189,0,0,1,.758-6.019,5.981,5.981,0,0,0,3.286,2.668,1.149,1.149,0,0,0,1.276-.385,14.178,14.178,0,0,0,2.443-5.432c5.238,2.711,5.986,5.294,5.986,7.49,0,.309-.023.6-.044.891l2.331-.547c0-.115.013-.229.013-.344a8.936,8.936,0,0,0-3.646-7.227,4.263,4.263,0,0,0,1.679-2.122l-8.744,2.052A12.108,12.108,0,0,1,252.109,41.874Z"
          fill="#eb6909"
        />
        <path
          d="M261.163,47.123a6.476,6.476,0,0,0-3.333-6.336,1.15,1.15,0,1,0-1.063,2.04,4.237,4.237,0,0,1,1.962,4.867Z"
          fill="#eb6909"
        />
        <path
          d="M238.205,42.358l2.281-.535c-.12-3.7-.05-7.257.114-10.177l-2.336.548C238.115,35.241,238.074,38.8,238.205,42.358Z"
          fill="#5f9501"
        />
        <path
          d="M247.063,38.783a5.962,5.962,0,0,1,.564-1.015,1.13,1.13,0,0,0,1.4.038,1.158,1.158,0,0,0,.231-1.6,4.721,4.721,0,0,1-.707-2.558,17.887,17.887,0,0,0-.715-3.7l-2.239.525a16.418,16.418,0,0,1,.656,3.227,7.947,7.947,0,0,0,.249,1.805,8.528,8.528,0,0,0-1.552,2.371c-.449,1.046-1.145,2.443-1.563,3.267l2.9-.682C246.566,39.891,246.841,39.3,247.063,38.783Z"
          fill="#5f9501"
        />
        <path
          d="M250.38,33.454a6.056,6.056,0,0,1,2.18,1.883,1.15,1.15,0,0,0,2.076-.879,6.531,6.531,0,0,1,3.231,1.306,9.435,9.435,0,0,0,1.661.944,3.649,3.649,0,0,1-1.116.683,28.538,28.538,0,0,0-2.95-1.544,1.15,1.15,0,0,0-1.622.939,13.174,13.174,0,0,1-.41,2l8.744-2.052a2.463,2.463,0,0,0,.133-.677,1.168,1.168,0,0,0-1-1.18,5.532,5.532,0,0,1-2.114-1,8.112,8.112,0,0,0-6.408-1.622,6.552,6.552,0,0,0-1.4-.881,9.6,9.6,0,0,1-.972-2.034l-2.261.53C248.985,32.03,249.542,33.1,250.38,33.454Z"
          fill="#5f9501"
        />
        <path
          d="M234.339,40.672a3.339,3.339,0,0,1,1.373.54,1.15,1.15,0,0,0,1.81-.99c0-.059-.181-4.309-.155-7.818l-2.3.54c0,1.991.048,4.1.094,5.551a6.5,6.5,0,0,0-2.487-.108,1.149,1.149,0,0,0-.924,1.316,7.948,7.948,0,0,0,1.543,3.808L235.9,42.9A4.674,4.674,0,0,1,234.339,40.672Z"
          fill="#5f9501"
        />
        <path
          d="M250.8,39.231a1.15,1.15,0,0,0-1.732-1.28,7.173,7.173,0,0,0-2.019,2.333l3.719-.872C250.778,39.351,250.78,39.294,250.8,39.231Z"
          fill="#5f9501"
        />
        <path
          d="M237.515,28.89c.142-1.232.657-4.043,1.221-6.958l-2.453.576c-.493,2.585-.92,4.97-1.053,6.119-.12,1.048-.163,2.641-.166,4.317l2.3-.54C237.377,31.015,237.418,29.739,237.515,28.89Z"
          fill="#79b61d"
        />
        <path
          d="M243.862,20.729l-3.92.92a30.109,30.109,0,0,0-.922,3.191,51.4,51.4,0,0,0-.756,7.354l2.336-.548a47.021,47.021,0,0,1,.666-6.308,27.922,27.922,0,0,1,.885-3.036,32.617,32.617,0,0,1,3.446,8.171l2.239-.525A34.182,34.182,0,0,0,243.862,20.729Z"
          fill="#79b61d"
        />
        <path
          d="M247.485,28.139c.244.653.458,1.217.66,1.736l2.261-.53c-.258-.649-.524-1.359-.767-2.011a54.485,54.485,0,0,0-3.116-7.229l-2.331.547A49.2,49.2,0,0,1,247.485,28.139Z"
          fill="#79b61d"
        />
        <path
          d="M240.507,13.1a1.15,1.15,0,1,0-2.252-.467c-.069.331-1.119,5.4-1.972,9.871l2.453-.576C239.561,17.666,240.491,13.179,240.507,13.1Z"
          fill="#c8d200"
        />
        <path
          d="M242.664,19.176a1.15,1.15,0,0,0-1.908.325c-.027.063-.4.934-.814,2.148l3.92-.92A13.339,13.339,0,0,0,242.664,19.176Z"
          fill="#c8d200"
        />
        <path
          d="M243.6,11.741a1.146,1.146,0,0,0-1.346.913l-.488,2.544-.017-.008a1.15,1.15,0,0,0-.766,2.169,7.162,7.162,0,0,1,3.208,3.293l2.331-.547a14.22,14.22,0,0,0-2.666-3.589l.657-3.43A1.15,1.15,0,0,0,243.6,11.741Z"
          fill="#c8d200"
        />
      </g>
    </g>
  </svg>
);

GradientCrop50.defaultProps = {
  title: 'chickpea',
};

GradientCrop50.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
};
