/* eslint-disable react/prop-types */
import React from 'react';

const AddNewPlan = ({ color, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="49.998"
    height="50"
    viewBox="0 0 49.998 50"
    {...props}
  >
    <path
      id="Shape_2235"
      dataname="Shape 2235"
      d="M2512,3698a5.776,5.776,0,0,1-5.769-5.769v-13.462h-13.462a5.769,5.769,0,0,1,0-11.539h13.462v-13.462a5.77,5.77,0,0,1,11.539,0v13.462h13.461a5.785,5.785,0,0,1,5.769,5.735l0,.006a5.79,5.79,0,0,1-5.764,5.8H2517.77v13.462A5.776,5.776,0,0,1,2512,3698Zm-19.232-28.846a3.846,3.846,0,0,0,0,7.693h15.386v15.385a3.846,3.846,0,0,0,7.692,0v-15.385h15.385a3.852,3.852,0,0,0,3.847-3.846l.959-.028-.961.005a3.855,3.855,0,0,0-3.845-3.824h-15.385v-15.385a3.846,3.846,0,0,0-7.692,0v15.385Z"
      transform="translate(-2487.002 -3647.999)"
      fill="#71bf44"
    />
  </svg>
);

export default AddNewPlan;
