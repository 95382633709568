/* eslint-disable import/prefer-default-export */
/* eslint-disable react/prop-types */
import React from 'react';
import { PropTypes } from 'prop-types';

const Chevron = ({ fill = '#839ca9', ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.97661 10.39869" {...props}>
    <title>Chevron</title>
    <path
      d="M0,1.34575A1.2804,1.2804,0,0,1,.68313.20626,1.0457,1.0457,0,0,1,1.89474.42457L8.90526,7.83634,15.99158.42457a1.06367,1.06367,0,0,1,1.14328-.381,1.22176,1.22176,0,0,1,.81827.9703,1.35394,1.35394,0,0,1-.40787,1.253L10.53474,9.67869a2.30579,2.30579,0,0,1-1.59158.72,2.12706,2.12706,0,0,1-1.61053-.79412L.36,2.27752A1.368,1.368,0,0,1,0,1.34575Z"
      fill={fill}
    />
  </svg>
);

Chevron.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
};

export default Chevron;
