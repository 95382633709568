import {
  GET_REFERALS_LOADING,
  GET_REFERALS_SUCCESS,
  GET_REFERALS_FAILED,
  POST_REFERALS_LOADING,
  POST_REFERALS_SUCCESS,
  POST_REFERALS_FAILED,
  STATUS_DEFAULT,
  STATUS_GET_FAILED,
} from 'Redux/actions/types';

const initialState = {
  status: STATUS_DEFAULT,
  errorCode: null,
  data: [],
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_REFERALS_LOADING:
      return {
        ...state,
        status: GET_REFERALS_LOADING,
      };
    case GET_REFERALS_SUCCESS:
      return {
        ...state,
        status: GET_REFERALS_SUCCESS,
        data: payload,
      };
    case GET_REFERALS_FAILED:
      return {
        ...state,
        errorCode: payload.errorCode,
        status: STATUS_GET_FAILED,
      };
    case POST_REFERALS_LOADING:
      return {
        ...state,
        status: POST_REFERALS_LOADING,
      };
    case POST_REFERALS_SUCCESS:
      return {
        ...state,
        status: POST_REFERALS_SUCCESS,
        data: [...state.data, payload],
      };
    case POST_REFERALS_FAILED:
      return {
        ...state,
        errorCode: payload.errorCode,
        status: POST_REFERALS_FAILED,
      };
    default:
      return state;
  }
};
