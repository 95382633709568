/* eslint-disable import/prefer-default-export */
const numberOfCutsData = {
  2: '2-4',
  3: '4-8',
  4: '8-10',
  5: '10-12',
  6: ' >12',
};

export { numberOfCutsData };
