/* eslint-disable import/prefer-default-export */
/* eslint-disable react/prop-types */
import React from 'react';
import { PropTypes } from 'prop-types';
import { useLocalizedFromContext } from 'data/Localization';

export const Crop27 = ({ title, fill, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="91.98"
    height="82.34"
    viewBox="0 0 91.98 82.34"
    {...props}
  >
    <title>{useLocalizedFromContext(title)}</title>
    <g id="Component_4_1" dataname="Component 4 – 1" transform="translate(2.5 2.5)">
      <path
        id="Path_4770"
        dataname="Path 4770"
        d="M3010.55,5276.466a19.41,19.41,0,0,0-1.78,4.493c-.858,3.665-3.98,5.5-3.98,14.307,0,4.538,2.761,6.5,5.76,6.5,3.54,0,5.76-3.271,5.76-6.378,0-8.714-3.188-10.548-3.706-14.459A8.478,8.478,0,0,0,3010.55,5276.466Z"
        transform="translate(-2960.52 -5259.107)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_4771"
        dataname="Path 4771"
        d="M2976.663,5300.346c-5.893-.205-8.058,1.869-11.418,1.8a8.479,8.479,0,0,0-4.725,1.35,19.461,19.461,0,0,0,4.169,2.444c3.227,1.3,4.612,4.269,11.671,5.771"
        transform="translate(-2960.52 -5259.107)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_4772"
        dataname="Path 4772"
        d="M2982.674,5273.348a16.548,16.548,0,0,0-2.221,1.608c-2.833,2.479-6.438,2.149-11.57,9.309-2.644,3.689-1.544,6.893.894,8.64a5.368,5.368,0,0,0,4.6.806"
        transform="translate(-2960.52 -5259.107)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_4773"
        dataname="Path 4773"
        d="M2978.174,5291.077c5.077-7.083,3.554-10.43,5.411-13.911a8.314,8.314,0,0,0,.856-2.533"
        transform="translate(-2960.52 -5259.107)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_4774"
        dataname="Path 4774"
        d="M2993.191,5264.307c-8.686-.7-10.771,2.328-14.711,2.529a8.468,8.468,0,0,0-4.615,1.687,19.423,19.423,0,0,0,4.335,2.136c2.892.929,4.476,3.231,9.595,4.459"
        transform="translate(-2960.52 -5259.107)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_4775"
        dataname="Path 4775"
        d="M3036.1,5277.125c1.964-5.463.69-8.149,1.792-11.3a8.483,8.483,0,0,0,.183-4.911,19.451,19.451,0,0,0-3.617,3.206c-2.117,2.523-5.148,3.011-8.537,8.36"
        transform="translate(-2960.52 -5259.107)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_4776"
        dataname="Path 4776"
        d="M3024.892,5282.177a6.182,6.182,0,0,0,1.762,1.315,5.278,5.278,0,0,0,3.556.455"
        transform="translate(-2960.52 -5259.107)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_4777"
        dataname="Path 4777"
        d="M2997.86,5274.128c1.937,2.074,3.46,2.635,4.886,4.673"
        transform="translate(-2960.52 -5259.107)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_4778"
        dataname="Path 4778"
        d="M3006.639,5274.654c0-2.6.362-6.225-2.393-11.534-2.091-4.028-5.446-4.5-8.108-3.117-3.142,1.631-3.6,5.558-2.174,8.315.371.714.729,1.359,1.075,1.942"
        transform="translate(-2960.52 -5259.107)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_4779"
        dataname="Path 4779"
        d="M3025.988,5273.98c-3.589-7.942-7.249-8.3-9.331-11.651a8.484,8.484,0,0,0-3.71-3.222,19.4,19.4,0,0,0,.228,4.827c.364,1.847.023,3.509.044,5.713"
        transform="translate(-2960.52 -5259.107)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_4780"
        dataname="Path 4780"
        d="M3036.513,5317.167a24.294,24.294,0,0,0,6.595-1.777c4.205-1.706,4.986-5,3.859-7.782-1.331-3.281-5.2-4.107-8.076-2.94"
        transform="translate(-2960.52 -5259.107)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_4781"
        dataname="Path 4781"
        d="M3014.866,5325.173c-3.293.739-5.06,2.068-7.613,2.213a8.478,8.478,0,0,0-4.606,1.714,19.431,19.431,0,0,0,4.347,2.112c3.591,1.129,5.184,4.38,13.969,5.04,4.525.34,6.69-2.266,6.915-5.257a5.376,5.376,0,0,0-1.7-4.347"
        transform="translate(-2960.52 -5259.107)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_4782"
        dataname="Path 4782"
        d="M2985.591,5318.322c0,8.715,3.179,9.749,3.7,13.66a8.468,8.468,0,0,0,2.053,4.465,19.419,19.419,0,0,0,1.78-4.494c.462-1.972,1.58-3.414,2.514-5.7a21.832,21.832,0,0,0,1.466-8.608"
        transform="translate(-2960.52 -5259.107)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_4783"
        dataname="Path 4783"
        d="M2988.079,5299.135c-4.187-4.7-7.291-4.709-9.677-7.212a8.481,8.481,0,0,0-4.29-2.4,19.413,19.413,0,0,0,1.209,4.679c1.366,3.231.2,6.334,4.24,12.5"
        transform="translate(-2960.52 -5259.107)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_4784"
        dataname="Path 4784"
        d="M2987.852,5300.331c-2.687,2.636-5.876,2.121-10.594,9.561-2.43,3.832-1.15,6.969,1.382,8.575,2.99,1.9,6.617.322,8.281-2.3a25.188,25.188,0,0,0,2.636-5.321"
        transform="translate(-2960.52 -5259.107)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_4785"
        dataname="Path 4785"
        d="M3034.166,5291.885a28.728,28.728,0,0,0,2.623,3.313"
        transform="translate(-2960.52 -5259.107)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_4786"
        dataname="Path 4786"
        d="M3042.6,5294.986c.465-2.892,1.729-5.913-.21-12.763-1.235-4.366-4.427-5.5-7.312-4.687-3.407.964-4.652,4.717-3.806,7.706a29.79,29.79,0,0,0,1,2.975"
        transform="translate(-2960.52 -5259.107)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_4787"
        dataname="Path 4787"
        d="M2991.342,5288.821a6.988,6.988,0,0,0,2.3-2.235,24.783,24.783,0,0,0,2.708-5.563"
        transform="translate(-2960.52 -5259.107)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_4788"
        dataname="Path 4788"
        d="M2997.14,5277.563a17.8,17.8,0,0,1,1.091-5.18,8.478,8.478,0,0,0,.649-4.871,19.454,19.454,0,0,0-3.9,2.847c-2.683,2.64-6.3,2.521-11.008,9.968-2.425,3.836-1.14,6.971,1.4,8.573a5.266,5.266,0,0,0,2.651.828"
        transform="translate(-2960.52 -5259.107)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_4789"
        dataname="Path 4789"
        d="M3028.339,5319.742c2.835,1.681,4.389,1.658,6.323,3.349a8.825,8.825,0,0,0,4.676,2.083,20.266,20.266,0,0,0-1.695-4.74c-1.848-3.459-.663-7.039-6.718-13.935a8.015,8.015,0,0,0-3.2-2.332"
        transform="translate(-2960.52 -5259.107)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_4790"
        dataname="Path 4790"
        d="M2996.708,5305.017c1.541,8.029,4.8,9.154,5.972,12.665a7.952,7.952,0,0,0,2.681,3.75,18.243,18.243,0,0,0,.846-4.454c.1-2.421,1.329-4.235,1.59-7.756"
        transform="translate(-2960.52 -5259.107)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_4791"
        dataname="Path 4791"
        d="M3013.922,5283.639c1.243-1.46,1.966-2.774,3.156-6.13,1.424-4.012-.4-6.614-3.053-7.555-3.13-1.11-6.118,1.086-7.093,3.832a24.472,24.472,0,0,0-1.242,4.92"
        transform="translate(-2960.52 -5259.107)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_4792"
        dataname="Path 4792"
        d="M3004.191,5302.035c.527,2.228,1.255,4.442,4.493,8.293,2.166,2.575,4.6,2.954,6.586,2.106"
        transform="translate(-2960.52 -5259.107)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_4793"
        dataname="Path 4793"
        d="M3005.69,5287.338c-2.233-.419-4.03.832-5.334,2.637-4.789,6.625-3.372,9.771-5.128,13.029a7.954,7.954,0,0,0-.892,4.523,18.208,18.208,0,0,0,3.823-2.438c2.189-1.9,4.173-1.343,7.74-5.037"
        transform="translate(-2960.52 -5259.107)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_4794"
        dataname="Path 4794"
        d="M2970.676,5309.817c.716,3.7-1.386,5.724,2.218,13.769,1.857,4.145,5.181,4.809,7.921,3.582a5.938,5.938,0,0,0,2.978-7.907"
        transform="translate(-2960.52 -5259.107)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_4795"
        dataname="Path 4795"
        d="M3009.123,5326.986a33.9,33.9,0,0,0,3.734-6.973"
        transform="translate(-2960.52 -5259.107)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_4796"
        dataname="Path 4796"
        d="M3002.221,5317.006a28.056,28.056,0,0,0-4.064,4.259c-2.987,3.783-1.977,7.237.523,9.211a6.088,6.088,0,0,0,7.1.159"
        transform="translate(-2960.52 -5259.107)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_4797"
        dataname="Path 4797"
        d="M3010.67,5312.588c-1.224.653-2.547,1.163-4.017,1.909"
        transform="translate(-2960.52 -5259.107)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_4798"
        dataname="Path 4798"
        d="M3011.146,5312.84a26.388,26.388,0,0,0,3.525,11.017c2.667,4.955,6.835,5.475,10.109,3.713,3.865-2.081,4.366-6.957,2.54-10.348a34.819,34.819,0,0,0-2.671-4.258"
        transform="translate(-2960.52 -5259.107)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_4799"
        dataname="Path 4799"
        d="M2997.189,5295.857c-1.726-1.738-3.266-2.962-4.342-4.952a10.509,10.509,0,0,0-4.349-4.267,24.068,24.068,0,0,0-.08,5.992c.622,4.625-2.187,8.125,1.7,18.333,2,5.259,6.067,6.316,9.542,4.993"
        transform="translate(-2960.52 -5259.107)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_4800"
        dataname="Path 4800"
        d="M3026.758,5290.2a21.987,21.987,0,0,1-2.526,5.392c-2.176,2.78-5.557,3.107-9.034,10.6-1.791,3.861-.217,6.622,2.335,7.806,3.013,1.4,6.193-.511,7.42-3.154,3.44-7.416,1.45-10.235,2.553-13.767C3028.279,5294.6,3026.758,5290.2,3026.758,5290.2Z"
        transform="translate(-2960.52 -5259.107)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_4801"
        dataname="Path 4801"
        d="M3033.82,5308.185c7.524-4.02,7.635-7.618,10.773-9.87a8.3,8.3,0,0,0,2.907-3.833,19.026,19.026,0,0,0-4.7.536c-3.56.951-6.582-.9-14.187,3.165"
        transform="translate(-2960.52 -5259.107)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_4802"
        dataname="Path 4802"
        d="M3027.2,5298.549c3.834-2.645,4.553-5.109,7.022-6.664a7.953,7.953,0,0,0,3-3.5,18.23,18.23,0,0,0-4.527.241c-2.594.527-4.808-.434-8.918.5"
        transform="translate(-2960.52 -5259.107)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_4803"
        dataname="Path 4803"
        d="M3025.169,5282.414a17.11,17.11,0,0,1,1.9-3.359,7.956,7.956,0,0,0,1.84-4.226,18.228,18.228,0,0,0-4.256,1.562c-2.32,1.265-4.714,1-8.355,3.095"
        transform="translate(-2960.52 -5259.107)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_4804"
        dataname="Path 4804"
        d="M3009.089,5280.536c-5.333-2.5-8.008-1.362-10.986-2.638a7.95,7.95,0,0,0-4.584-.484,18.188,18.188,0,0,0,2.769,3.589c1.97,1.9,2.4,4.361,5.8,7.338"
        transform="translate(-2960.52 -5259.107)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_4805"
        dataname="Path 4805"
        d="M3022.063,5297.623c2.457-5.7,1.11-8.368,2.268-11.472a7.949,7.949,0,0,0,.266-4.6,18.281,18.281,0,0,0-3.454,2.937c-1.544,1.765-2.99,1.867-5.306,4.245"
        transform="translate(-2960.52 -5259.107)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_4806"
        dataname="Path 4806"
        d="M3012.035,5301.557c.493.669.659.544,1.417.947a4.929,4.929,0,0,0,2.849.567"
        transform="translate(-2960.52 -5259.107)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
    </g>
  </svg>
);

Crop27.defaultProps = {
  title: 'linseed',
};

Crop27.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
};
