import React, { useState, useEffect, useCallback, useRef } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Backdrop from '@material-ui/core/Backdrop';
import ChevronLeft from 'components/Icons/ChevronLeft';
import PlusFlat from 'components/Icons/PlusFlat';
import { toggleNavigation } from 'Redux/actions';

const Header = ({
  title,
  subtitle,
  backButton,
  backButtonData,
  showHamburger,
  UI: { isNavOpen },
  landing,
  children,
  history,
  plusButton,
  className,
  toggleNavigation,
}) => {
  const [showHeader, setShowHeader] = useState(true);
  const [showPanel, setShowPanel] = useState(false);

  const scrollPosition = useRef(0);

  const showHeaderLocal = useRef(showHeader); // Hacky workaround to get new state of show header in componentDidMount (hooks)
  const setShowHeaderAndLocal = useCallback((newState) => {
    showHeaderLocal.current = newState;
    setShowHeader(newState);
  }, []);

  const handleScroll = useCallback(() => {
    const newScrollPosition = window.pageYOffset || document.documentElement.scrollTop;
    if (scrollPosition.current === newScrollPosition) {
      return;
    }
    const shouldHideHeader = scrollPosition.current < newScrollPosition && newScrollPosition > 100;
    if (shouldHideHeader && showHeaderLocal.current) {
      setShowHeaderAndLocal(false);
    }
    if (!shouldHideHeader && !showHeaderLocal.current) {
      setShowHeaderAndLocal(true);
    }
    scrollPosition.current = newScrollPosition;
  }, [setShowHeaderAndLocal, showHeaderLocal]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  const defineClassNamesH1 = () => {
    if (showHamburger) {
      return 'appbar-title appbar-title--hamburger';
    }
    return 'appbar-title';
  };

  const generateBackButton = () => {
    if (backButtonData) {
      const { to, labelText, className } = backButtonData;
      const content = (
        <>
          <ChevronLeft className="subheader__buttons-picto" />
          <span className="go_back__label">{labelText}</span>
        </>
      );
      if (to) {
        return (
          <Link className={`go_back ${className}`} id="go_back" to={to}>
            {content}
          </Link>
        );
      }
      return (
        <form
          onSubmit={(event) => {
            event.preventDefault();
            history.goBack();
          }}
        >
          <button className={`go_back ${className}`} id="go_back" to={to} type="submit">
            {content}
          </button>
        </form>
      );
    }
    return null;
  };

  const toggleNavigationHandler = () => {
    toggleNavigation(!isNavOpen);
    setTimeout(() => {
      if (isNavOpen === true) {
        toggleNavigation(null);
      }
    }, 500);
  };

  const generateAnimationHeader = () => {
    let className = '';
    if (showHeader || showPanel) className = 'toolbar__show';
    else className = 'toolbar__hide';
    return className;
  };

  const shouldShowHamburgerMenu = () => {
    if (showHamburger && !backButton) {
      return (
        <IconButton
          className="hambutton"
          color="inherit"
          aria-label="Menu"
          onClick={() => toggleNavigationHandler()}
        >
          <div />
          <div />
          <div />
        </IconButton>
      );
    }
    return null;
  };

  const generatePlusButton = () => {
    if (plusButton) {
      const { to, panel } = plusButton;

      const linkOrPanelProps = () => {
        if (panel) {
          return {
            onClick: () => setShowPanel(!showPanel),
          };
        }
        return {
          to,
          component: Link,
        };
      };

      const activePanelPlusButtonClassName = () => (showPanel ? 'plus_button--open-panel' : '');

      return (
        <IconButton
          className={`plus_button ${activePanelPlusButtonClassName()}`}
          {...linkOrPanelProps()}
        >
          <PlusFlat className="plus_button__icon" />
        </IconButton>
      );
    }
    return null;
  };

  const styles = {
    appbar: {
      backgroundColor: 'transparent',
      boxShadow: 'none',
    },
  };
  const subtitleComp = subtitle ? <h3 className="appbar-subtitle">{subtitle}</h3> : null;

  const activePanelClassName = (baseClassName) => (showPanel ? `${baseClassName}--active` : '');

  const getPanel = () => {
    if (get(plusButton, ['panel'])) {
      const {
        to,
        panel: { Icon, title },
      } = plusButton;
      return (
        <>
          <div className="appbar__panel__wrapper">
            <Link to={to}>
              <section className={`appbar__panel ${activePanelClassName('appbar__panel')}`}>
                <div className="appbar__panel__content">
                  <Icon className="appbar__panel__icon" />
                  <h4 className="appbar__panel__title">{title}</h4>
                </div>
              </section>
            </Link>
          </div>
          <Backdrop
            onClick={() => setShowPanel(false)}
            transitionDuration={400}
            open={showPanel}
            className={`appbar__backdrop ${activePanelClassName('appbar__backdrop')}`}
          />
        </>
      );
    }
    return null;
  };

  return (
    <AppBar
      position="static"
      className={[
        'appbar',
        isNavOpen ? 'appbar--open' : null,
        landing ? 'appbar--landing' : 'appbar--not-landing',
        className || '',
      ]
        .join(' ')
        .trim()}
      style={styles.appbar}
    >
      {title ? <Helmet title={`${title} - NutriGuide®`} /> : <Helmet title="NutriGuide®" />}
      <Grid item className="content-row__grid-wrapper">
        <Toolbar className={`toolbar ${generateAnimationHeader()}`}>
          {children}
          {backButton}
          {generateBackButton()}
          {shouldShowHamburgerMenu()}
          <h1 className={defineClassNamesH1()}>{title}</h1>
          {generatePlusButton()}
        </Toolbar>
        {subtitleComp}
      </Grid>
      {getPanel()}
    </AppBar>
  );
};

Header.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  backButton: PropTypes.node,
  showHamburger: PropTypes.bool,
  toggleNavigation: PropTypes.func,
  UI: PropTypes.object,
  landing: PropTypes.bool,
  children: PropTypes.node,
  backButtonData: PropTypes.object,
  history: PropTypes.object,
  plusButton: PropTypes.object,
  className: PropTypes.string,
};

export default withRouter(connect(({ UI }) => ({ UI }), { toggleNavigation })(Header));
