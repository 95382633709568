/* eslint-disable import/prefer-default-export */
/* eslint-disable react/prop-types */
import React from 'react';
import { PropTypes } from 'prop-types';
import { useLocalizedFromContext } from 'data/Localization';

export const Crop20 = ({ title, fill, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="146.656"
    height="166.038"
    viewBox="0 0 146.656 166.038"
    {...props}
  >
    <title>{useLocalizedFromContext(title)}</title>
    <g id="Component_5_1" dataname="Component 5 – 1" transform="translate(2.518 3.097)">
      <g id="Group_171" dataname="Group 171" transform="translate(-2056.586 -5824.828)">
        <g id="Group_169" dataname="Group 169">
          <ellipse
            id="Ellipse_34"
            dataname="Ellipse 34"
            cx="4.075"
            cy="8.481"
            rx="4.075"
            ry="8.481"
            transform="translate(2107.65 5841.202) rotate(-15.068)"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
          <ellipse
            id="Ellipse_35"
            dataname="Ellipse 35"
            cx="3.56"
            cy="7.409"
            rx="3.56"
            ry="7.409"
            transform="translate(2104.187 5826.708) rotate(-15.068)"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
          <ellipse
            id="Ellipse_36"
            dataname="Ellipse 36"
            cx="8.111"
            cy="4.261"
            rx="8.111"
            ry="4.261"
            transform="matrix(0.086, -0.996, 0.996, 0.086, 2117.411, 5862.383)"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
          <ellipse
            id="Ellipse_37"
            dataname="Ellipse 37"
            cx="7.852"
            cy="4.402"
            rx="7.852"
            ry="4.402"
            transform="matrix(0.216, -0.976, 0.976, 0.216, 2123.634, 5868.735)"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
          <ellipse
            id="Ellipse_38"
            dataname="Ellipse 38"
            cx="7.852"
            cy="4.402"
            rx="7.852"
            ry="4.402"
            transform="matrix(0.216, -0.976, 0.976, 0.216, 2129.305, 5878.617)"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
          <ellipse
            id="Ellipse_39"
            dataname="Ellipse 39"
            cx="7.852"
            cy="4.402"
            rx="7.852"
            ry="4.402"
            transform="matrix(0.216, -0.976, 0.976, 0.216, 2133.879, 5890.407)"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
          <ellipse
            id="Ellipse_40"
            dataname="Ellipse 40"
            cx="7.852"
            cy="4.402"
            rx="7.852"
            ry="4.402"
            transform="matrix(0.216, -0.976, 0.976, 0.216, 2137.407, 5902.914)"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
          <ellipse
            id="Ellipse_41"
            dataname="Ellipse 41"
            cx="5.779"
            cy="3.569"
            rx="5.779"
            ry="3.569"
            transform="translate(2125.269 5852.571) rotate(-68.003)"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
          <ellipse
            id="Ellipse_42"
            dataname="Ellipse 42"
            cx="5.825"
            cy="3.541"
            rx="5.825"
            ry="3.541"
            transform="translate(2138.546 5872.613) rotate(-55.049)"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
          <ellipse
            id="Ellipse_43"
            dataname="Ellipse 43"
            cx="5.825"
            cy="3.541"
            rx="5.825"
            ry="3.541"
            transform="translate(2142.725 5885.111) rotate(-55.049)"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
          <ellipse
            id="Ellipse_44"
            dataname="Ellipse 44"
            cx="5.8"
            cy="3.556"
            rx="5.8"
            ry="3.556"
            transform="translate(2132.956 5861.811) rotate(-62.058)"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
          <ellipse
            id="Ellipse_45"
            dataname="Ellipse 45"
            cx="5.754"
            cy="3.584"
            rx="5.754"
            ry="3.584"
            transform="translate(2115.815 5846.736) rotate(-75.905)"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
        <g id="Group_170" dataname="Group 170">
          <ellipse
            id="Ellipse_46"
            dataname="Ellipse 46"
            cx="4.075"
            cy="8.481"
            rx="4.075"
            ry="8.481"
            transform="translate(2107.639 5841.286) rotate(-16.17)"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
          <ellipse
            id="Ellipse_47"
            dataname="Ellipse 47"
            cx="3.56"
            cy="7.409"
            rx="3.56"
            ry="7.409"
            transform="translate(2104.073 5826.811) rotate(-16.17)"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
          <ellipse
            id="Ellipse_48"
            dataname="Ellipse 48"
            cx="4.261"
            cy="8.111"
            rx="4.261"
            ry="8.111"
            transform="matrix(0.807, -0.591, 0.591, 0.807, 2101.648, 5854.131)"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
          <ellipse
            id="Ellipse_49"
            dataname="Ellipse 49"
            cx="4.402"
            cy="7.852"
            rx="4.402"
            ry="7.852"
            transform="translate(2098.863 5865.584) rotate(-43.727)"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
          <ellipse
            id="Ellipse_50"
            dataname="Ellipse 50"
            cx="4.402"
            cy="7.852"
            rx="4.402"
            ry="7.852"
            transform="translate(2099.138 5876.974) rotate(-43.727)"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
          <ellipse
            id="Ellipse_51"
            dataname="Ellipse 51"
            cx="4.402"
            cy="7.852"
            rx="4.402"
            ry="7.852"
            transform="translate(2101.342 5889.428) rotate(-43.727)"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
          <ellipse
            id="Ellipse_52"
            dataname="Ellipse 52"
            cx="4.402"
            cy="7.852"
            rx="4.402"
            ry="7.852"
            transform="translate(2104.811 5901.95) rotate(-43.727)"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
          <ellipse
            id="Ellipse_53"
            dataname="Ellipse 53"
            cx="3.569"
            cy="5.779"
            rx="3.569"
            ry="5.779"
            transform="matrix(0.599, -0.801, 0.801, 0.599, 2092.767, 5856.674)"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
          <ellipse
            id="Ellipse_54"
            dataname="Ellipse 54"
            cx="3.541"
            cy="5.825"
            rx="3.541"
            ry="5.825"
            transform="matrix(0.404, -0.915, 0.915, 0.404, 2091.823, 5883.672)"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
          <ellipse
            id="Ellipse_55"
            dataname="Ellipse 55"
            cx="3.541"
            cy="5.825"
            rx="3.541"
            ry="5.825"
            transform="matrix(0.404, -0.915, 0.915, 0.404, 2094.73, 5896.525)"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
          <ellipse
            id="Ellipse_56"
            dataname="Ellipse 56"
            cx="3.556"
            cy="5.8"
            rx="3.556"
            ry="5.8"
            transform="matrix(0.512, -0.859, 0.859, 0.512, 2090.912, 5869.926)"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
          <ellipse
            id="Ellipse_57"
            dataname="Ellipse 57"
            cx="3.584"
            cy="5.754"
            rx="3.584"
            ry="5.754"
            transform="translate(2098.131 5844.991) rotate(-45.333)"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
      </g>
      <path
        id="Path_557"
        dataname="Path 557"
        d="M2120.173,5870.781s11.575,31.224,11.217,58.549c-.445,33.976-11.119,55.079-11.119,55.079"
        transform="translate(-2056.586 -5824.828)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_558"
        dataname="Path 558"
        d="M2169.226,5949.054c14.56,3.9,16.116,15.9,28.581,23.166,3.449,2.011-16.182,5.724-25.618-3.313-10.628-10.18-29.2-10.552-35.628-4.976a148.529,148.529,0,0,0-13.619,14.157S2150.6,5944.072,2169.226,5949.054Z"
        transform="translate(-2056.586 -5824.828)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_559"
        dataname="Path 559"
        d="M2085.542,5929.885c-12.064,2.1-16.67,13.593-28.58,23.165-3.113,2.5,13.816,2.255,24.745-4.9,11.462-7.51,29.635-9.922,35.9-4.166,8.133,7.472,11.146,11.492,11.146,11.492S2105.449,5926.42,2085.542,5929.885Z"
        transform="translate(-2056.586 -5824.828)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_560"
        dataname="Path 560"
        d="M2119.272,5905.694s2.837,5.949,10.7,4.183,8.973-9.856,8.973-9.856"
        transform="translate(-2056.586 -5824.828)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
    </g>
  </svg>
);

Crop20.defaultProps = {
  title: 'sorghum',
};

Crop20.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
};
