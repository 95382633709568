import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { withRouter, Redirect } from 'react-router-dom';
import { updateUser, resetPlan } from 'Redux/actions';

class SignOut extends Component {
  static propTypes = {
    updateUser: PropTypes.func,
    resetPlan: PropTypes.func,
  };

  UNSAFE_componentWillMount() {
    const { updateUser, resetPlan } = this.props;
    updateUser({
      // Only blanking out the required fields
      id_token: undefined,
      role: undefined,
      firstName: undefined,
      lastName: undefined,
      userName: undefined,
      vatNumber: undefined,
      organisation: undefined,
      policy: undefined,
    });
    resetPlan();
  }

  render() {
    return <Redirect to="/welcome" />;
  }
}

const mapStateToProps = ({ user }) => ({ user });

export default withRouter(connect(mapStateToProps, { updateUser, resetPlan })(SignOut));
