/* eslint-disable react/prop-types */
import React from 'react';

const Plan = ({ map, pointer, ...props }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 70 70"
      enableBackground="new 0 0 70 70"
      width="70"
      height="70"
      {...props}
    >
      <g>
        <g>
          <polygon fill="#EB6909" points="56.5,35 53.6,35.7 56.2,45.3 59.1,44.6" />
          <polygon fill="#EB6909" points="43.9,38 45,47.9 48,47.2 46.9,37.3" />
          <path
            fill="#EB6909"
            d="M36.9,42c0.6-0.7,1.5-1.8,2.4-3l-6.8,1.6c0.5,0.5,0.9,1.1,1.4,1.6v6L24.7,51l1-8.8L22.6,43l-0.9,7.6l-8-4.7l0.2-0.9l-3.4,0.8l-0.1,0.5c-0.2,0.7,0.1,1.3,0.7,1.7l9.5,5.6l18.9-4.4l-2.7-0.9V42z"
          />
        </g>
        <g>
          <path
            fill="#79B61D"
            d="M37.8,19.1c1.1,0.7,1.9,2,1.9,3.4c0,2.2-1.8,4-4,4s-4-1.8-4-4c0-0.8,0.2-1.5,0.6-2.1l-3.5,0.8c-0.1,0.4-0.1,0.8-0.1,1.3c0,3.9,3.1,7,7,7c3.9,0,7-3.1,7-7c0-1.6-0.5-3-1.4-4.2L37.8,19.1z"
          />
          <path
            fill="#79B61D"
            d="M53.9,25.2c-0.1-0.5-0.5-0.8-0.9-1s-0.9-0.1-1.3,0.1L47.4,27l6.6-1.6L53.9,25.2z"
          />
          <path
            fill="#79B61D"
            d="M46.1,17.2l-3,0.7c0.8,1.3,1.3,2.9,1.3,4.6c0,1.6-0.6,3.5-1.5,5.5l3.5-0.8c0.6-1.6,1-3.3,1-4.7C47.4,20.6,46.9,18.8,46.1,17.2z"
          />
          <path
            fill="#79B61D"
            d="M26.5,21.8l-3.1,0.7c0,1.8,0.4,3.7,1.2,5.6l-6.2-3.8C18,24.1,17.5,24,17,24.2c-0.4,0.2-0.8,0.5-0.9,1l-2.6,9.7l3.4-0.8l1.7-6.2l5.4,3.3l-0.2,1.3l5.7-1.3c-1.8-3.1-3-6.2-3-8.7C26.5,22.3,26.5,22,26.5,21.8z"
          />
        </g>
        <path
          fill="#B95700"
          d="M22.4,54.6L22.4,54.6c0.2,0,0.2,0,0.3,0c0.1,0,0.1,0,0.2,0c0.1,0,0.3,0,0.4-0.1L35,50.8h0.1l11.4,3.7c0.2,0,0.3,0.1,0.5,0.1c0.1,0,0.1,0,0.2,0c0.3,0,0.6-0.2,0.8-0.4L58.9,48c0.6-0.4,0.8-1,0.7-1.7l-0.5-1.7l-2.9,0.7l0.2,0.7l-8,4.7L48,47.2l-3,0.7l0.4,3.2l-5.8-1.9l-18.9,4.4l1.5,0.9C22.2,54.6,22.3,54.6,22.4,54.6z"
        />
        <g>
          <polygon fill="#5F9501" points="10.5,45.8 13.9,45 16.8,34.1 13.5,34.9" />
          <path
            fill="#5F9501"
            d="M54,25.4L47.4,27l-1.2,0.7c0.1-0.2,0.1-0.3,0.2-0.5L42.9,28c-1.7,3.7-4.6,7.8-7.5,11.2c-2.2-2.4-4.3-5.2-5.9-8l-5.7,1.3L22.6,43l3.1-0.7l1-9.2l0.3,0.2c1.5,2.7,3.5,5.2,5.5,7.4l6.8-1.6c1.3-1.7,2.8-3.7,4.1-5.8l0.5,4.8l2.9-0.7l-0.7-6l5.3-3.3l2.1,7.7l3-0.7L54,25.4z"
          />
        </g>
        <g>
          <path
            fill="#C8D200"
            d="M35.7,15.5c-3.5,0-6.3,2.5-6.9,5.7l3.5-0.8c0.7-1.1,2-1.9,3.4-1.9c0.8,0,1.5,0.2,2.1,0.6l3.5-0.8C40.1,16.6,38,15.5,35.7,15.5z"
          />
          <path
            fill="#C8D200"
            d="M35.4,10.5c-6.6,0-12,5.4-12,12c0,0,0,0,0,0l3.1-0.7c0.3-4.6,4.1-8.3,8.9-8.3c3.2,0,6.1,1.8,7.7,4.4l3-0.7C44.2,13.2,40.1,10.5,35.4,10.5z"
          />
        </g>
      </g>
    </svg>
  );
};

Plan.defaultProps = {
  map: true,
  pointer: true,
};

export default Plan;
