/* eslint-disable prefer-destructuring */
/* eslint-disable class-methods-use-this */
import { DateHelper } from 'helpers';
import { kgDecimals } from 'constants/index.js';
import { getCurrentHarvestingYearArray } from 'Utils/HarvestingYearUtils';
import StraightFertilizerService from 'services/StraightFertilizerService';

export const planElementDataTypes = {
  GAP: 'GAP',
  PLAN: 'PLAN',
  COVER: 'COVER',
  MOP: 'MOP',
};

class CropRotationService {
  constructor() {
    this.takenYears = []; // Used to adjust planting and harvesting dates
    this.availableYear = null; // Used to adjust planting and harvesting dates
  }

  getSortedStructuredCropRotationListByYearAndPlotName(structuredCropRotations) {
    const sortedStructuredCropRotationsByYear = structuredCropRotations.sort(
      ({ year: yearA }, { year: yearB }) => yearA - yearB,
    );

    const sortedStructuredCropRotationsByYearAndPlotName = [];
    sortedStructuredCropRotationsByYear.forEach(({ year, list }) => {
      const sortedList = list.sort(({ plot: { name: nameA } }, { plot: { name: nameB } }) =>
        nameA > nameB ? 1 : -1,
      );
      const sortedYearElement = {
        year,
        list: sortedList,
      };
      sortedStructuredCropRotationsByYearAndPlotName.push(sortedYearElement);
    });

    return sortedStructuredCropRotationsByYearAndPlotName;
  }

  // Modifies the list for the my crop rotations table, the list will be grouped together based on the planting year, activeCropNames and recommendations will be set
  getStructuredList(rawCropRotations) {
    const structuredList = [];
    const today = new Date();
    rawCropRotations.forEach((cropRotation) => {
      const structuredCropRotation = { ...cropRotation };
      let activeCropName = '';
      let activeApplications = [];
      let activePlan = {};
      cropRotation.plans.forEach((plan) => {
        const plantingDate = new Date(plan.plantingDate);
        const harvestingDate = new Date(plan.harvestingDate);

        // Getting active crop name
        if (today > plantingDate && today < harvestingDate) {
          activeCropName = plan.planName.cropName;
          activeApplications = plan.applicationTimeline;
          activePlan = plan;
        }
      });

      structuredCropRotation.activeCropName = activeCropName;
      structuredCropRotation.activeApplications = activeApplications;
      structuredCropRotation.activePlan = activePlan;
      structuredCropRotation.endBalance = this.getEndBalanceForCropRotation(cropRotation);

      // structuredCropRotation.endBalancesByCrops = this.getEndBalancesByCrops(cropRotation.plans);
      structuredCropRotation.demandAndFertilizedByCrops = this.getDemandAndFertilizedByCrops(
        cropRotation.plans,
      );

      const yearElement = structuredList.find((element) => element.year === cropRotation.startYear);
      if (yearElement) {
        yearElement.list.push(structuredCropRotation);
      } else {
        const yearElement = {
          year: cropRotation.startYear,
          list: [structuredCropRotation],
        };
        structuredList.push(yearElement);
      }
    });

    const orderdedStructuredList =
      this.getSortedStructuredCropRotationListByYearAndPlotName(structuredList);

    return orderdedStructuredList;
  }

  getStructuredListNG4(rawCropRotations) {
    const structuredList = [];
    const today = new Date();
    rawCropRotations.forEach((cropRotation) => {
      const structuredCropRotation = { ...cropRotation };
      let activeCropName = '';
      let activeApplications = [];
      let activePlan = {};
      cropRotation.genericPlans.forEach((plan) => {
        const plantingDate = new Date(plan.plantingDate);
        const harvestingDate = new Date(plan.harvestingDate);

        // Getting active crop name
        if (today > plantingDate && today < harvestingDate) {
          activeCropName = plan.cropName;
          activeApplications = plan.steps;
          activePlan = plan;
        }
      });

      structuredCropRotation.activeCropName = activeCropName;
      structuredCropRotation.activeApplications = activeApplications;
      structuredCropRotation.activePlan = activePlan;
      structuredCropRotation.endBalance = this.getEndBalanceForCropRotationNG4(cropRotation);

      // structuredCropRotation.endBalancesByCrops = this.getEndBalancesByCrops(cropRotation.plans);
      structuredCropRotation.demandAndFertilizedByCrops = this.getDemandAndFertilizedByCropsNG4(
        cropRotation.genericPlans,
      );

      const yearElement = structuredList.find((element) => element.year === cropRotation.startYear);
      if (yearElement) {
        yearElement.list.push(structuredCropRotation);
      } else {
        const yearElement = {
          year: cropRotation.startYear,
          list: [structuredCropRotation],
        };
        structuredList.push(yearElement);
      }
    });

    const orderdedStructuredList =
      this.getSortedStructuredCropRotationListByYearAndPlotName(structuredList);

    return orderdedStructuredList;
  }

  getSortedPlansByPlantingDate(plans) {
    const sortedPlans = plans.sort(
      ({ plantingDate: plantingDateA }, { plantingDate: plantingDateB }) =>
        new Date(plantingDateA) - new Date(plantingDateB),
    );
    return sortedPlans;
  }

  getEndBalancesByCrops(plans) {
    const endBalance = {};
    const sortedPlans = this.getSortedPlansByPlantingDate(plans);
    sortedPlans.forEach(({ components, planName: { cropName } }) => {
      components.forEach(({ shortName, needed, recommended }) => {
        if (!endBalance[cropName]) {
          endBalance[cropName] = {};
        }
        endBalance[cropName][shortName] = endBalance[cropName][shortName]
          ? { value: endBalance[cropName][shortName].value + recommended - needed }
          : { value: recommended - needed };
      });
    });

    Object.keys(endBalance).forEach((cropName) => {
      this.normalizeEndBalance(endBalance[cropName]);
    });
    return endBalance;
  }

  getDemandAndFertilizedByCrops(plans) {
    const demandAndFertilized = [];
    const sortedPlans = this.getSortedPlansByPlantingDate(plans);
    sortedPlans.forEach(({ id, components, planName: { cropName } }) => {
      const planElement = {
        id,
        cropName,
      };
      components.forEach(({ shortName, needed, recommended }) => {
        if (planElement[shortName] === undefined) {
          planElement[shortName] = {};
        }
        planElement[shortName].needed = needed;
        planElement[shortName].recommended = recommended;
      });
      demandAndFertilized.push(planElement);
    });
    return demandAndFertilized;
  }

  getDemandAndFertilizedByCropsNG4(plans) {
    const demandAndFertilized = [];
    const sortedPlans = this.getSortedPlansByPlantingDate(plans);
    sortedPlans.forEach(({ id, components, cropName }) => {
      const planElement = {
        id,
        cropName,
      };
      components.forEach(({ shortName, fertilizationNeed, recommendedAmount }) => {
        if (planElement[shortName] === undefined) {
          planElement[shortName] = {};
        }
        planElement[shortName].needed = fertilizationNeed;
        planElement[shortName].recommended = recommendedAmount;
      });
      demandAndFertilized.push(planElement);
    });
    return demandAndFertilized;
  }

  getEndBalanceForPlans(plans) {
    const endBalance = {};
    plans.forEach(({ components }) => {
      components.forEach(({ shortName, needed, recommended, name }) => {
        endBalance[shortName] = endBalance[shortName]
          ? { value: endBalance[shortName].value + recommended - needed, name }
          : { value: recommended - needed, name };
      });
    });
    return endBalance;
  }

  getEndBalanceForPlansNG4(plans) {
    const endBalance = {};
    plans.forEach(({ components }) => {
      components.forEach(({ shortName, fertilizationNeed, recommendedAmount, name }) => {
        endBalance[shortName] = endBalance[shortName]
          ? { value: endBalance[shortName].value + recommendedAmount - fertilizationNeed, name }
          : { value: recommendedAmount - fertilizationNeed, name };
      });
    });
    return endBalance;
  }

  getEndBalanceForCropRotationNG4(cropRotation) {
    const { genericPlans, straightFertilizers } = cropRotation;
    const endBalance = this.getEndBalanceForPlansNG4(genericPlans);
    const recommendedMOP =
      straightFertilizers && straightFertilizers.applicationTimeline[0].date
        ? straightFertilizers.components[0].recommended
        : null;

    if (recommendedMOP && genericPlans.length > 0) {
      endBalance.K.valueWithMOP = 0.6 * recommendedMOP;
    }

    this.normalizeEndBalance(endBalance);
    return endBalance;
  }

  getEndBalanceForCropRotation(cropRotation) {
    const { plans, straightFertilizers } = cropRotation;
    const endBalance = this.getEndBalanceForPlans(plans);
    const recommendedMOP =
      straightFertilizers && straightFertilizers.applicationTimeline[0].date
        ? straightFertilizers.components[0].recommended
        : null;

    if (recommendedMOP && plans.length > 0) {
      endBalance.K.valueWithMOP = 0.6 * recommendedMOP;
    }

    this.normalizeEndBalance(endBalance);
    return endBalance;
  }

  getSumOfComponentsForCropRotation(cropRotation) {
    const { plans } = cropRotation;
    const sumOfComponents = {};
    plans.forEach(({ components }) => {
      components.forEach(({ shortName, needed, recommended }) => {
        sumOfComponents[shortName] = {
          shortName,
          needed: sumOfComponents[shortName] ? sumOfComponents[shortName].needed + needed : needed,
          recommended: sumOfComponents[shortName]
            ? sumOfComponents[shortName].recommended + recommended
            : recommended,
        };
      });
    });
    return Object.values(sumOfComponents);
  }

  getSumOfComponentsForCropRotationNG4(cropRotation) {
    const { genericPlans } = cropRotation;
    const sumOfComponents = {};
    genericPlans.forEach(({ steps }) => {
      steps.forEach(({ components }) => {
        components.forEach(
          ({ shortName, fertilizationNeed, recommendedAmount, selectedAmount }) => {
            sumOfComponents[shortName] = {
              shortName,
              fertilizationNeed: sumOfComponents[shortName]
                ? sumOfComponents[shortName].fertilizationNeed + fertilizationNeed
                : fertilizationNeed,
              recommendedAmount: sumOfComponents[shortName]
                ? sumOfComponents[shortName].recommendedAmount + recommendedAmount
                : recommendedAmount,
              selectedAmount: sumOfComponents[shortName]
                ? sumOfComponents[shortName].selectedAmount + selectedAmount
                : selectedAmount,
            };
          },
        );
      });
    });
    return Object.values(sumOfComponents);
  }

  // Negative 0 values are removed
  normalizeEndBalance(endBalance) {
    Object.keys(endBalance).forEach((component) => {
      const zeros = new Array(kgDecimals).fill(0).join('');
      // eslint-disable-next-line no-param-reassign
      endBalance[component].value =
        endBalance[component].value < 0 && endBalance[component].value > parseFloat(`-0.${zeros}5`)
          ? Math.abs(endBalance[component].value).toFixed(kgDecimals)
          : endBalance[component].value.toFixed(kgDecimals);
    });
  }

  // Groups the product quantities toghether for different products
  getStructuredProductsInPlans(plans) {
    const structuredProducts = {};
    plans.forEach(({ applicationTimeline }) => {
      applicationTimeline.forEach(
        ({
          recommendation: {
            product: { id, name },
            quantity,
          },
        }) => {
          const startingQuantiy = structuredProducts[id] ? structuredProducts[id].quantity : 0;
          structuredProducts[id] = {
            name,
            quantity: startingQuantiy + quantity,
          };
        },
      );
    });
    return structuredProducts;
  }

  getStructuredProductsInPlansNG4(plans) {
    const structuredProducts = {};
    plans.forEach(({ steps }) => {
      steps.forEach(({ isProductChanged, recommended, selected }) => {
        const id = isProductChanged ? selected.product.id : recommended.product.id;
        const name = isProductChanged ? selected.product.name : recommended.product.name;
        const quantity = isProductChanged ? selected.quantity : recommended.quantity;
        const startingQuantiy = structuredProducts[id] ? structuredProducts[id].quantity : 0;
        structuredProducts[id] = {
          name,
          quantity: startingQuantiy + quantity,
        };
      });
    });
    return structuredProducts;
  }

  getCropRotationEndYear(plans) {
    return Math.max(...plans.map(({ harvestingDate }) => new Date(harvestingDate).getFullYear()));
  }

  // MOP application date and the plantingDate is taken into account
  getCropRotationStartYear(orderedPlans, forUI = false) {
    const key = forUI ? 'plantingDate' : 'harvestingDate';

    // CropRotations might not have plans at all
    if (orderedPlans.length === 0) {
      return new Date().getFullYear();
    }

    // plan is MOP
    if (orderedPlans[0].applicationDate) {
      return orderedPlans[0].applicationDate.getFullYear();
    }
    // plan is already ordered
    if (orderedPlans[0].paramsOut) {
      return new Date(orderedPlans[0].paramsOut[key]).getFullYear();
    }
    // plan was just added
    return new Date(orderedPlans[0][key]).getFullYear();
  }

  getCropRotationStartYearNG4(orderedPlans, forUI = false) {
    const key = forUI ? 'plantingDate' : 'harvestingDate';

    // CropRotations might not have plans at all
    if (orderedPlans.length === 0) {
      return new Date().getFullYear();
    }

    // plan is MOP
    if (orderedPlans[0].applicationDate) {
      return orderedPlans[0].applicationDate.getFullYear();
    }
    // plan is already ordered
    if (orderedPlans[0].plan) {
      return new Date(orderedPlans[0].plan[key]).getFullYear();
    }
    // plan was just added
    return new Date(orderedPlans[0][key]).getFullYear();
  }

  // MOP application date and the harvestingyear is taken into account
  getCropRotationStartYearForBackend(orderedPlans) {
    // plan is MOP
    if (orderedPlans[0].applicationDate) {
      return orderedPlans[0].applicationDate.getFullYear();
    }
    // plan is already ordered
    if (orderedPlans[0].plan) {
      return new Date(orderedPlans[0].plan.harvestingDate).getFullYear();
    }
    // plan was just added
    return new Date(orderedPlans[0].harvestingDate).getFullYear();
  }

  getNumberOfColumnsNeededForCalendar(plans, startYear) {
    const cropRotationEndYear = this.getCropRotationEndYear(plans);

    // Minimum is one year of columns which is 14
    const columnCount = (cropRotationEndYear - startYear + 1) * 12 + 2;
    return columnCount < 14 ? 14 : columnCount;
  }

  getColumnIndexForDate(startYear, date) {
    return DateHelper.shared.monthDiff(new Date(`${startYear}-01-01`), date) + 1;
  }

  // Orders the plans by plantingDate and inserts MOP to the right place
  getOrderedPlans(plans, straightFertilizers) {
    const { PLAN, MOP } = planElementDataTypes;
    const orderedPlans = plans.sort(
      ({ plantingDate: dateA }, { plantingDate: dateB }) => new Date(dateA) - new Date(dateB),
    );
    const mopApplicationDate =
      straightFertilizers && straightFertilizers.applicationTimeline[0].date
        ? new Date(straightFertilizers.applicationTimeline[0].date)
        : null;
    const mopRecommendedAmmount =
      straightFertilizers && straightFertilizers.components[0].recommended;

    let indexOfPlanBeforeMOP = null;
    let mopShouldBeAppliedAfter = false;

    const structuredPlanList = orderedPlans.map((plan, index) => {
      if (
        indexOfPlanBeforeMOP === null &&
        mopApplicationDate &&
        mopApplicationDate <= new Date(plan.plantingDate)
      ) {
        indexOfPlanBeforeMOP = index;
        mopShouldBeAppliedAfter =
          orderedPlans[index - 1] &&
          new Date(orderedPlans[index - 1].harvestingDate).getFullYear() ===
            mopApplicationDate.getFullYear();
      }
      return {
        type: PLAN,
        plan,
      };
    });
    if (indexOfPlanBeforeMOP === null) {
      indexOfPlanBeforeMOP = orderedPlans.length;
      mopShouldBeAppliedAfter = true;
    }
    if (mopApplicationDate) {
      const mopPlan = {
        type: MOP,
        duration: mopShouldBeAppliedAfter
          ? `${mopApplicationDate.getFullYear()} / ${mopApplicationDate.getFullYear() + 1}`
          : `${mopApplicationDate.getFullYear() - 1} / ${mopApplicationDate.getFullYear()}`,
        applicationDate: mopApplicationDate,
        recommendedAmount: mopRecommendedAmmount,
      };
      structuredPlanList.splice(indexOfPlanBeforeMOP, 0, mopPlan);
    }
    return structuredPlanList;
  }

  shouldReplaceGapWithCover(index, orderedPlans, coverCropId, planElementsData) {
    const { GAP, MOP } = planElementDataTypes;
    if (
      index >= 1 &&
      orderedPlans[index - 1].type === MOP &&
      DateHelper.shared.monthDiff(
        orderedPlans[index - 1].applicationDate,
        new Date(orderedPlans[index].plan.plantingDate),
      ) > 0
    ) {
      return false;
    }
    return (
      (index === 1 && orderedPlans[index - 1].type === MOP && coverCropId) ||
      (index > 1 &&
        orderedPlans[index - 1].type === MOP &&
        coverCropId &&
        planElementsData[planElementsData.length - 2].type === GAP)
    );
  }

  getCalendarElementForPlan(
    plan,
    startYear,
    currentColumnIndex,
    orderedPlans,
    index,
    planElementsData,
  ) {
    const {
      plantingDate: plantingDateString,
      harvestingDate: harvestingDateString,
      id,
      planName: { cropName, coverCropId },
    } = plan;
    const { PLAN, COVER, GAP } = planElementDataTypes;
    const plantingDate = new Date(plantingDateString);
    const harvestingDate = new Date(harvestingDateString);
    const planLength = DateHelper.shared.monthDiff(plantingDate, harvestingDate) + 1;
    const today = new Date();
    const planElement = {
      type: PLAN,
      length: planLength,
      isActive: today > plantingDate && today < harvestingDate,
      plan: {
        id,
        cropName,
        plantingDate: DateHelper.shared.getFormattedDateString(plantingDate, 'DD.MM.YYYY'),
        harvestingDate: DateHelper.shared.getFormattedDateString(harvestingDate, 'DD.MM.YYYY'),
      },
    };
    const planStartColumnIndex = this.getColumnIndexForDate(startYear, plantingDate);
    let gapElement = null;
    let newPlanElementsData = null;
    if (this.shouldReplaceGapWithCover(index, orderedPlans, coverCropId, planElementsData)) {
      newPlanElementsData = JSON.parse(JSON.stringify(planElementsData));
      newPlanElementsData[newPlanElementsData.length - 2].type = COVER;
      newPlanElementsData[newPlanElementsData.length - 2].coverCropId = coverCropId;
    } else if (planStartColumnIndex !== currentColumnIndex) {
      const gapLength = planStartColumnIndex - currentColumnIndex + 1;
      gapElement = {
        type: coverCropId ? COVER : GAP,
        length: gapLength < 1 ? 1 : gapLength,
        coverCropId,
      };
    }
    return [planElement, planLength, gapElement, newPlanElementsData];
  }

  getCalendarElementForPlanNG4(plan, startYear, currentColumnIndex) {
    const {
      plantingDate: plantingDateString,
      harvestingDate: harvestingDateString,
      id,
      cropName,
      legumeContent,
    } = plan;
    const { PLAN, GAP, COVER } = planElementDataTypes;
    const plantingDate = new Date(plantingDateString);
    const harvestingDate = new Date(harvestingDateString);
    const planLength = DateHelper.shared.monthDiff(plantingDate, harvestingDate) + 1;
    const today = new Date();
    const planElement = {
      type: PLAN,
      length: planLength,
      isActive: today > plantingDate && today < harvestingDate,
      plan: {
        id,
        cropName,
        plantingDate: DateHelper.shared.getFormattedDateString(plantingDate, 'DD.MM.YYYY'),
        harvestingDate: DateHelper.shared.getFormattedDateString(harvestingDate, 'DD.MM.YYYY'),
      },
    };
    const planStartColumnIndex = this.getColumnIndexForDate(startYear, plantingDate);
    let gapElement = null;
    if (planStartColumnIndex !== currentColumnIndex) {
      const gapLength = planStartColumnIndex - currentColumnIndex + 1;
      gapElement = {
        type: legumeContent ? COVER : GAP,
        length: gapLength < 1 ? 1 : gapLength,
      };
    }
    return [planElement, planLength, gapElement, null];
  }

  getCalendarElementForMop(
    duration,
    startYear,
    applicationDate,
    recommendedAmount,
    currentColumnIndex,
  ) {
    const { MOP, GAP } = planElementDataTypes;
    const mopElement = {
      type: MOP,
      length: 1,
      duration,
      recommendedAmount,
    };
    const planStartColumnIndex = this.getColumnIndexForDate(startYear, applicationDate);
    let gapElement = null;
    if (planStartColumnIndex > currentColumnIndex) {
      const gapLength = planStartColumnIndex - currentColumnIndex + 1;
      gapElement = {
        type: GAP,
        length: gapLength,
      };
    }
    return [mopElement, 1, gapElement];
  }

  getStructuredPlanElementsDataForCalendar(
    plans,
    straightFertilizers,
    startYear,
    calendarColumnsLength,
  ) {
    const { GAP, PLAN, MOP } = planElementDataTypes;
    let planElementsData = [];
    const orderedPlans = this.getOrderedPlans(plans, straightFertilizers);
    let currentColumnIndex = 1;
    orderedPlans.forEach(({ type, plan, duration, applicationDate, recommendedAmount }, index) => {
      let [planElement, planLength, gapElement, newPlanElementsData] = Array(3).fill(null);
      switch (type) {
        case PLAN:
          [planElement, planLength, gapElement, newPlanElementsData] =
            this.getCalendarElementForPlan(
              plan,
              startYear,
              currentColumnIndex,
              orderedPlans,
              index,
              planElementsData,
            );
          break;
        case MOP:
          [planElement, planLength, gapElement] = this.getCalendarElementForMop(
            duration,
            startYear,
            applicationDate,
            recommendedAmount,
            currentColumnIndex,
          );
          break;
        default:
          break;
      }

      const usedPlanElementsData = newPlanElementsData || planElementsData;
      planElementsData = gapElement
        ? [...usedPlanElementsData, gapElement, planElement]
        : [...usedPlanElementsData, planElement];
      const iterationLength = gapElement ? planLength + gapElement.length : planLength;
      currentColumnIndex += iterationLength;
    });

    // The end of the calendar is filled with a gap if no plan reaches the end of the period
    if (currentColumnIndex < calendarColumnsLength) {
      planElementsData = [
        ...planElementsData,
        {
          type: GAP,
          length: calendarColumnsLength - currentColumnIndex + 1,
        },
      ];
    }
    return planElementsData;
  }

  getStructuredPlanElementsDataForCalendarNG4(
    plans,
    straightFertilizers,
    startYear,
    calendarColumnsLength,
  ) {
    const { GAP, PLAN, MOP } = planElementDataTypes;
    let planElementsData = [];
    const orderedPlans = this.getOrderedPlans(plans, straightFertilizers);
    let currentColumnIndex = 1;
    orderedPlans.forEach(({ type, plan, duration, applicationDate, recommendedAmount }, index) => {
      let [planElement, planLength, gapElement, newPlanElementsData] = Array(3).fill(null);
      switch (type) {
        case PLAN:
          [planElement, planLength, gapElement, newPlanElementsData] =
            this.getCalendarElementForPlanNG4(
              plan,
              startYear,
              currentColumnIndex,
              orderedPlans,
              index,
              planElementsData,
            );
          break;
        case MOP:
          [planElement, planLength, gapElement] = this.getCalendarElementForMop(
            duration,
            startYear,
            applicationDate,
            recommendedAmount,
            currentColumnIndex,
          );
          break;
        default:
          break;
      }

      const usedPlanElementsData = newPlanElementsData || planElementsData;
      planElementsData = gapElement
        ? [...usedPlanElementsData, gapElement, planElement]
        : [...usedPlanElementsData, planElement];
      const iterationLength = gapElement ? planLength + gapElement.length : planLength;
      currentColumnIndex += iterationLength;
    });

    // The end of the calendar is filled with a gap if no plan reaches the end of the period
    if (currentColumnIndex < calendarColumnsLength) {
      planElementsData = [
        ...planElementsData,
        {
          type: GAP,
          length: calendarColumnsLength - currentColumnIndex + 1,
        },
      ];
    }
    return planElementsData;
  }

  getUpdatedPlans({ planId, date, key }, plans) {
    const plansCopy = [...plans];
    const planToUpdate = plansCopy.find((planElement) => planElement.id === planId);
    planToUpdate[key] = date;
    return plansCopy;
  }

  // Merges together the new plans with already added plans. Rawplans are ordered by harvestingYear
  getCropRotationPlansFromRawPlans(rawPlans, orderedPlans) {
    this.takenYears = [];
    orderedPlans.forEach(({ plantingDate, harvestingDate }) => {
      this.takenYears.push(new Date(plantingDate).getFullYear());
      this.takenYears.push(new Date(harvestingDate).getFullYear());
    });
    this.takenYears = Array.from(new Set(this.takenYears));

    const cropRotationPlans = [];
    rawPlans.forEach(
      ({
        paramsIn: { coverCrop, harvestingYear },
        paramsOut: { id, planName, applicationTimeline, components },
      }) => {
        this.availableYear = harvestingYear[0];

        this.adjustAvailableYear(this.availableYear);
        let plantingDate = `${this.availableYear}-03-15`;
        let harvestingDate = `${this.availableYear}-09-15`;

        harvestingDate = DateHelper.shared.getNormalizedDateString(harvestingDate);
        plantingDate = DateHelper.shared.getNormalizedDateString(plantingDate);

        const cropRotationPlan = {
          id,
          planName: {
            ...planName,
            coverCropId: coverCrop ? coverCrop.crop.id : null,
          },
          applicationTimeline,
          components,
          plantingDate,
          harvestingDate,
        };
        cropRotationPlans.push(cropRotationPlan);
      },
    );

    this.takenYears = [];
    this.availableYear = null;
    return cropRotationPlans;
  }

  adjustAvailableYear(currentYear) {
    if (this.takenYears.length === 0) {
      const now = new Date();
      this.availableYear = now.getFullYear();
      // If the date is after 1st of September, the plan should be shifted to next year
      if (now.getMonth() > 8 || (now.getMonth() === 8 && now.getDate() > 1)) {
        this.availableYear += 1;
      }
      this.takenYears.push(this.availableYear);
    } else if (
      this.takenYears.includes(currentYear) ||
      Math.max(...this.takenYears) >= currentYear
    ) {
      this.adjustAvailableYear(currentYear + 1);
    } else {
      this.availableYear = currentYear;
      this.takenYears.push(currentYear);
    }
  }

  getPlantingAndHarvestingDates(crop, plans, index, alreadyAddedPlans) {
    let plantingDate = `${this.availableYear}-03-15`;
    let harvestingDate = `${this.availableYear}-09-15`;
    if (
      crop &&
      crop.plantingMonth &&
      crop.plantingDay &&
      crop.harvestingMonth &&
      crop.harvestingDay
    ) {
      const plantingMonthDay = `${crop.plantingMonth}-${crop.plantingDay}`;
      const harvestingMonthDay = `${crop.harvestingMonth}-${crop.harvestingDay}`;
      plantingDate = `${this.availableYear}-${plantingMonthDay}`;
      harvestingDate = `${this.availableYear}-${harvestingMonthDay}`;

      let lastAlreadyAddedPlan = null;
      if (alreadyAddedPlans && alreadyAddedPlans.length > 0) {
        lastAlreadyAddedPlan = alreadyAddedPlans[alreadyAddedPlans.length - 1];
      }

      // If plantingMonth is greater then harvestingMonth, the planting year is last year.
      if (
        crop.plantingMonth > crop.harvestingMonth ||
        (crop.plantingMonth === crop.harvestingMonth && crop.plantingDay > crop.harvestingDay)
      ) {
        // If planting date not interfers with previous plans harvesting date
        if (
          this.takenYears.includes(this.availableYear - 1) &&
          lastAlreadyAddedPlan &&
          new Date(`${this.availableYear - 1}-${plantingMonthDay}`) >
            new Date(lastAlreadyAddedPlan.harvestingDate)
        ) {
          plantingDate = `${this.availableYear - 1}-${plantingMonthDay}`;
          harvestingDate = `${this.availableYear}-${harvestingMonthDay}`;

          // If next year's planting date is before this years harvesting date
        } else if (
          lastAlreadyAddedPlan &&
          new Date(lastAlreadyAddedPlan.harvestingDate) < new Date(plantingDate)
        ) {
          harvestingDate = `${this.availableYear + 1}-${harvestingMonthDay}`;
        } else if (
          lastAlreadyAddedPlan &&
          new Date(lastAlreadyAddedPlan.harvestingDate) > new Date(plantingDate)
        ) {
          plantingDate = `${this.availableYear + 1}-${plantingMonthDay}`;
        } else {
          plantingDate = `${this.availableYear - 1}-${plantingMonthDay}`;
        }
      }
    }

    harvestingDate = DateHelper.shared.getNormalizedDateString(harvestingDate);
    plantingDate = DateHelper.shared.getNormalizedDateString(plantingDate);
    return { plantingDate, harvestingDate };
  }

  getCropRotationPlansFromRawPlansNG4(rawPlans, orderedPlans, cropList) {
    orderedPlans.forEach(({ plantingDate, harvestingDate }) => {
      this.takenYears.push(new Date(plantingDate).getFullYear());
      this.takenYears.push(new Date(harvestingDate).getFullYear());
    });
    this.takenYears = Array.from(new Set(this.takenYears));

    const cropRotationPlans = [];
    rawPlans.forEach(
      ({ harvestingYear, id, cropName, cropId, components, steps, previousCropId }, index) => {
        this.availableYear = harvestingYear[0];
        const crop = cropList.find(({ id }) => cropId === id);

        this.adjustAvailableYear(this.availableYear);
        const { plantingDate, harvestingDate } = this.getPlantingAndHarvestingDates(
          crop,
          rawPlans,
          index,
          orderedPlans,
        );

        const cropRotationPlan = {
          id,
          cropName,
          cropId,
          steps,
          components,
          plantingDate,
          harvestingDate,
          previousCropId,
        };
        cropRotationPlans.push(cropRotationPlan);
      },
    );
    this.takenYears = [];
    this.availableYear = null;
    return cropRotationPlans;
  }

  getStrippedCropRotationForBackEnd(cropRotation, isCopy) {
    const {
      id,
      name,
      plot: { id: plotId },
      plans,
      straightFertilizers,
    } = cropRotation;
    const strippedPlans = plans.map(
      ({ id, plantingDate, harvestingDate, planName: { parentId } }) => ({
        id: parentId || id,
        plantingDate: DateHelper.shared.getFormattedDateString(plantingDate, 'YYYY-MM-DD'),
        harvestingDate: DateHelper.shared.getFormattedDateString(harvestingDate, 'YYYY-MM-DD'),
      }),
    );
    const strippedCropRotation = {
      id,
      name,
      startYear: this.getCropRotationStartYearForBackend(plans),
      plotId,
      plans: strippedPlans,
      straightFertilizers,
    };
    if (isCopy) {
      strippedCropRotation.isCopy = true;
    }
    return strippedCropRotation;
  }

  getStrippedCropRotationForBackEndNG4(cropRotation, locale, isCopy) {
    const {
      id,
      name,
      plot: { id: plotId },
      genericPlans,
      straightFertilizers,
    } = cropRotation;
    const strippedPlans = genericPlans.map(({ id, plantingDate, harvestingDate, parentId }) => ({
      id: parentId || id,
      plantingDate: DateHelper.shared.getFormattedDateString(plantingDate, 'YYYY-MM-DD'),
      harvestingDate: DateHelper.shared.getFormattedDateString(harvestingDate, 'YYYY-MM-DD'),
    }));
    const strippedCropRotation = {
      id,
      name,
      startYear: this.getCropRotationStartYearForBackend(genericPlans),
      plotId,
      genericPlans: strippedPlans,
      straightFertilizers,
      locale,
    };
    if (isCopy) {
      strippedCropRotation.isCopy = true;
    }
    return strippedCropRotation;
  }

  getCropNameForId(cropId, cropList) {
    const cropIndex = cropList.findIndex((crop) => crop.id === cropId);
    return cropList[cropIndex].name;
  }

  convertCropRotationsToFrontEnd(cropRotations) {
    const getStrippedPlans = (plans) =>
      plans.map(
        ({
          paramsIn: {
            coverCrop,
            organicFertilizers,
            yieldTarget,
            crop: {
              id: cropId,
              growthStage: { id: growthStageId },
            },
          },
          paramsOut: {
            id,
            planName,
            applicationTimeline,
            components,
            harvestingDate,
            plantingDate,
          },
        }) => ({
          id,
          planName: {
            ...planName,
            coverCropId: coverCrop ? coverCrop.crop.id : null,
          },
          applicationTimeline,
          components,
          harvestingDate,
          plantingDate,
          organicFertilizers,
          yieldTarget,
          cropId,
          growthStageId,
        }),
      );
    const frontEndCompatibleCropRotations = cropRotations.map(
      ({ id, name, plot, plans, straightFertilizers }) => ({
        id,
        name,
        plot,
        startYear: this.getCropRotationStartYear(plans),
        plans: getStrippedPlans(plans),
        straightFertilizers,
      }),
    );
    return frontEndCompatibleCropRotations;
  }

  convertCropRotationsToFrontEndNG4(cropRotations) {
    const getStrippedPlans = (plans) =>
      plans.map(
        ({
          organicFertilizers,
          yieldTarget,
          cropId,
          cropLogoId,
          id,
          steps,
          components,
          harvestingDate,
          plantingDate,
          cropName,
          parentId,
          legumeContent,
        }) => ({
          id,
          steps,
          components,
          harvestingDate,
          plantingDate,
          organicFertilizers,
          yieldTarget,
          cropId,
          cropLogoId,
          cropName,
          parentId,
          legumeContent,
        }),
      );
    const frontEndCompatibleCropRotations = cropRotations.map(
      ({ id, name, plot, genericPlans = [], straightFertilizers }) => ({
        id,
        name,
        plot,
        startYear: this.getCropRotationStartYearNG4(genericPlans),
        genericPlans: getStrippedPlans(genericPlans),
        straightFertilizers,
      }),
    );
    return frontEndCompatibleCropRotations;
  }

  getUpdatedCropRotationsWithPlot(cropRotations, plot) {
    const updatedCropRotations = [];
    cropRotations.forEach((cropRotation) => {
      const {
        plot: { id: cropRotationPlotId },
      } = cropRotation;
      const cropRotationCopy = { ...cropRotation };
      if (cropRotationPlotId === plot.id) {
        cropRotationCopy.plot = plot;
      }
      updatedCropRotations.push(cropRotationCopy);
    });
    return updatedCropRotations;
  }

  getNextHarvestingYearInCropRotation(cropRotation) {
    if (!cropRotation.plans || cropRotation.plans.length === 0) {
      return getCurrentHarvestingYearArray();
    }
    const cropRotationEndYear = this.getCropRotationEndYear(cropRotation.plans);
    return [cropRotationEndYear, cropRotationEndYear + 1];
  }

  getPreviousCropNG4(cropRotation) {
    if (cropRotation.genericPlans.length === 0) {
      return null;
    }
    const { cropId } = cropRotation.genericPlans[cropRotation.genericPlans.length - 1];
    return cropId;
  }

  getCropRotationWithStraigthFertilizersAutoAdjustedNG3(cropRotation, copiedCropRotation) {
    const { plans } = cropRotation;
    const { straightFertilizers } = copiedCropRotation;
    let adjustedStraightFertilizers;
    if (straightFertilizers) {
      const straighFertilizerApplicationDate = new Date(
        StraightFertilizerService.getApplicationDate(straightFertilizers, 'MOP'),
      );

      let newApplicationDate;
      let i = 0;
      while (!newApplicationDate) {
        let { plantingDate, harvestingDate } = plans[i];

        plantingDate = new Date(plantingDate);
        harvestingDate = new Date(harvestingDate);

        if (i === 0 && plantingDate > straighFertilizerApplicationDate) {
          newApplicationDate = DateHelper.shared.getFormattedDateString(
            straighFertilizerApplicationDate,
            'YYYY-MM-DD',
          );
        } else if (harvestingDate > straighFertilizerApplicationDate) {
          newApplicationDate = DateHelper.shared.getOneDayAfter(harvestingDate);
        } else if (i === plans.length && straighFertilizerApplicationDate > harvestingDate) {
          newApplicationDate = DateHelper.shared.getFormattedDateString(
            straighFertilizerApplicationDate,
            'YYYY-MM-DD',
          );
        }

        i += 1;
      }

      adjustedStraightFertilizers = StraightFertilizerService.getStraighFertilizerWithUpdatedDate(
        straightFertilizers,
        'MOP',
        newApplicationDate,
      );
    }
    return {
      ...cropRotation,
      straightFertilizers: adjustedStraightFertilizers,
    };
  }

  getCropRotationWithStraigthFertilizersAutoAdjusted(cropRotation, copiedCropRotation) {
    const { genericPlans } = cropRotation;
    const { straightFertilizers } = copiedCropRotation;
    let adjustedStraightFertilizers;
    if (straightFertilizers) {
      const straighFertilizerApplicationDate = new Date(
        StraightFertilizerService.getApplicationDate(straightFertilizers, 'MOP'),
      );

      let newApplicationDate;
      let i = 0;
      while (!newApplicationDate) {
        let { plantingDate, harvestingDate } = genericPlans[i];

        plantingDate = new Date(plantingDate);
        harvestingDate = new Date(harvestingDate);

        if (i === 0 && plantingDate > straighFertilizerApplicationDate) {
          newApplicationDate = DateHelper.shared.getFormattedDateString(
            straighFertilizerApplicationDate,
            'YYYY-MM-DD',
          );
        } else if (harvestingDate > straighFertilizerApplicationDate) {
          newApplicationDate = DateHelper.shared.getOneDayAfter(harvestingDate);
        } else if (i === genericPlans.length && straighFertilizerApplicationDate > harvestingDate) {
          newApplicationDate = DateHelper.shared.getFormattedDateString(
            straighFertilizerApplicationDate,
            'YYYY-MM-DD',
          );
        }

        i += 1;
      }

      adjustedStraightFertilizers = StraightFertilizerService.getStraighFertilizerWithUpdatedDate(
        straightFertilizers,
        'MOP',
        newApplicationDate,
      );
    }
    return {
      ...cropRotation,
      straightFertilizers: adjustedStraightFertilizers,
    };
  }

  getCropRotationCopy(localData, cropRotation, cropListData) {
    const {
      name,
      plot: { id: plotId },
      startingPlanId,
    } = localData;
    const { startYear, genericPlans } = cropRotation;
    const selectedIndex = genericPlans.findIndex(({ parentId }) => parentId === startingPlanId);
    const reorderedGenericPlans = [
      genericPlans[selectedIndex],
      ...genericPlans.slice(selectedIndex + 1, genericPlans.length),
      ...genericPlans.slice(0, selectedIndex),
    ];
    const genericPlansCopy = reorderedGenericPlans.map(({ parentId, cropId }, index) => {
      const crop = cropListData.find(({ id }) => cropId === id);

      this.availableYear = new Date(genericPlans[index].plantingDate).getFullYear();
      this.adjustAvailableYear(this.availableYear);
      const { plantingDate, harvestingDate } = this.getPlantingAndHarvestingDates(
        crop,
        reorderedGenericPlans,
        index,
      );

      return {
        parentId,
        harvestingDate,
        plantingDate,
      };
    });

    this.takenYears = [];
    this.availableYear = null;

    return {
      name,
      startYear,
      plot: {
        id: plotId,
      },
      genericPlans: genericPlansCopy,
    };
  }

  getCropRotationCopyNG3(localData, cropRotation) {
    const {
      name,
      plot: { id: plotId },
      startingPlanId,
    } = localData;
    const { startYear, plans } = cropRotation;
    const selectedIndex = plans.findIndex(
      ({ planName: { parentId } }) => parentId === startingPlanId,
    );
    const reorderedGenericPlans = [
      plans[selectedIndex],
      ...plans.slice(selectedIndex + 1, plans.length),
      ...plans.slice(0, selectedIndex),
    ];
    const plansCopy = reorderedGenericPlans.map(({ planName: { parentId } }, index) => ({
      planName: {
        parentId,
      },
      harvestingDate: plans[index].harvestingDate,
      plantingDate: plans[index].plantingDate,
    }));

    return {
      name,
      startYear,
      plot: {
        id: plotId,
      },
      plans: plansCopy,
    };
  }
}

export default CropRotationService;
