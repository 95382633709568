import {
  RESET_ACCESS_TOKEN,
  UPDATE_USER,
  POST_USER_LOADING,
  POST_USER_SUCCESS,
  POST_USER_FAILED,
  PUT_USER_LOADING,
  PUT_USER_SUCCESS,
  PUT_USER_FAILED,
  GET_USER_LOADING,
  GET_USER_SUCCESS,
  GET_USER_FAILED,
  STATUS_DEFAULT,
  STATUS_GETTING,
  STATUS_POSTING,
  STATUS_PUTTING,
  STATUS_GET_SUCCESS,
  STATUS_POST_SUCCESS,
  STATUS_PUT_SUCCESS,
  STATUS_GET_FAILED,
  STATUS_POST_FAILED,
  STATUS_PUT_FAILED,
  RESET_USER,
} from 'Redux/actions/types';

const initialState = {
  status: STATUS_DEFAULT,
  statusCode: null,
  errorCode: null,
  country: 'AT',
  lang: 'EN_AT',
  locale: 'en_AT',
  confirmedLanguage: undefined,
  role: undefined,
  firstName: '',
  lastName: '',
  userName: '',
  vatNumber: '',
  organisation: '',
  acceptCommunication: true,
  termsCompliant: true,
  startedPlanningWithoutRegistration: false,
  loggingOut: false,
  authenticating: false,
  isLoggedIn: false,
};

const reducer = (state = initialState, action) => {
  const { payload, type } = action;
  // if (payload) payload.lang = 'EN_AT'; // SWITCH TO ENGLISH FOR DEV PURPOSES
  switch (type) {
    case POST_USER_LOADING:
      return {
        ...state,
        status: STATUS_POSTING,
        statusCode: null,
        errorCode: null,
      };

    case POST_USER_SUCCESS:
      return {
        ...state,
        ...payload,
        status: STATUS_POST_SUCCESS,
        errorCode: null,
      };

    case POST_USER_FAILED:
      return {
        ...state,
        status: STATUS_POST_FAILED,
        statusCode: payload.statusCode,
        errorCode: payload.errorCode,
      };

    case PUT_USER_LOADING:
      return {
        ...state,
        status: STATUS_PUTTING,
        statusCode: null,
        errorCode: null,
      };

    case PUT_USER_SUCCESS:
      return {
        ...state,
        ...payload,
        status: STATUS_PUT_SUCCESS,
        errorCode: null,
      };

    case PUT_USER_FAILED:
      return {
        ...state,
        status: STATUS_PUT_FAILED,
        statusCode: payload.statusCode,
        errorCode: payload.errorCode,
      };

    case GET_USER_LOADING:
      return {
        ...state,
        status: STATUS_GETTING,
        statusCode: null,
        errorCode: null,
      };

    case GET_USER_SUCCESS:
      return {
        ...state,
        ...payload,
        status: STATUS_GET_SUCCESS,
        errorCode: null,
      };

    case GET_USER_FAILED:
      return {
        ...state,
        status: STATUS_GET_FAILED,
        statusCode: payload.statusCode,
        errorCode: payload.errorCode,
      };

    case RESET_ACCESS_TOKEN:
      return { ...state, id_token: undefined };

    case RESET_USER: {
      return {
        ...initialState,
        country: state.country,
        lang: state.lang,
        locale: state.locale,
        confirmedLanguage: state.confirmedLanguage,
      };
    }

    case UPDATE_USER:
      return { ...state, ...payload };

    default:
      return state;
  }
};

export default reducer;
