import XMLInstructions from './Anleitung_Export_Import_Flaechen_LAT.pdf';
import Import_XML_Instructions_FR from './Import_XML_Instructions_FR.pdf';
import Import_Plot_Instructions_RO from './Description_to_download_the_GPX_file_RO_RO_LAT.pdf';
import Import_Plot_Instructions_HU from './Import_plots_XLS_HU_HU_LAT.pdf';
import EAMA_Instructions from './EAMA_Instructions.pdf';
import Nutriguide_User_Manual_DE from './NutriGuide_user_manual_DE_Düngekarten.pdf';
import Nutriguide_User_Manual_HU from './NutriGuide_user_manual_HU_formatted.pdf';
import Nutriguide_User_Manual_RO from './NutriGuide_user_manual_RO_formatted.pdf';
import Nutriguide_User_Manual_BG from './NutriGuide_user_manual_BG_formatted.pdf';
import NutriGuide_user_manual_BG_LAT from './NutriGuide_user_manual_BG_LAT.pdf';
import NutriGuide_user_manual_DE_LAT from './NutriGuide_user_manual_DE_LAT.pdf';
import NutriGuide_user_manual_EN_LAT from './NutriGuide_user_manual_EN_LAT.pdf';
import NutriGuide_user_manual_HU_LAT from './NutriGuide_user_manual_HU_LAT.pdf';
import NutriGuide_user_manual_RO_LAT from './NutriGuide_user_manual_RO_LAT.pdf';

export {
  XMLInstructions,
  Import_XML_Instructions_FR,
  Import_Plot_Instructions_RO,
  Import_Plot_Instructions_HU,
  EAMA_Instructions,
  Nutriguide_User_Manual_DE,
  Nutriguide_User_Manual_HU,
  Nutriguide_User_Manual_RO,
  Nutriguide_User_Manual_BG,
  NutriGuide_user_manual_BG_LAT,
  NutriGuide_user_manual_DE_LAT,
  NutriGuide_user_manual_EN_LAT,
  NutriGuide_user_manual_HU_LAT,
  NutriGuide_user_manual_RO_LAT,
};
