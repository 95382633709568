/* eslint-disable import/prefer-default-export */
/* eslint-disable react/prop-types */
import React from 'react';
import { PropTypes } from 'prop-types';
import { useLocalizedFromContext } from 'data/Localization';

export const GradientCrop43 = ({ title, ...props }) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 70 70"
    enableBackground="new 0 0 70 70"
    {...props}
  >
    <title>{useLocalizedFromContext(title)}</title>
    <g>
      <clipPath id="SVGID_2_">
        <path
          id="SVGID_79_"
          d="M55.4,47.1l-4,3.1v-2.3c1.1-0.4,1.9-1.3,2.2-2.6c0.1-0.5,0.1-1,0.1-1.6c0.2,0,0.3,0,0.5,0
          c0.4,0,0.8,0,1.1-0.1c1.8-0.4,2.9-1.8,2.9-3.7s-1.1-3.3-2.9-3.7C54.9,36,54.4,36,53.8,36c0-0.5,0-1.1-0.1-1.6
          c-0.4-1.8-1.8-2.9-3.7-2.9s-3.3,1.1-3.7,2.9c-0.1,0.5-0.1,1-0.1,1.6c-0.6,0-1.1,0-1.6,0.1c-1.8,0.4-2.9,1.8-2.9,3.7
          s1.1,3.3,2.9,3.7c0.4,0.1,0.8,0.1,1.1,0.1c0.2,0,0.3,0,0.5,0c0,0.5,0,1.1,0.1,1.6c0.3,1.3,1.1,2.2,2.2,2.6v2.3l-4-3.1
          c-0.7-0.5-1.6-0.4-2.1,0.3c-0.5,0.7-0.4,1.6,0.3,2.1l5.8,4.5v3.7c0,0.8,0.7,1.5,1.5,1.5s1.5-0.7,1.5-1.5v-3.7l5.8-4.5
          c0.7-0.5,0.8-1.4,0.3-2.1C57,46.7,56.1,46.6,55.4,47.1z M49.2,44.7c-0.2-0.5-0.1-1.1,0.1-1.6c0.3,0.1,0.6,0.1,0.9,0.1
          c0.2,0,0.4,0,0.5-0.1c0.1,0.5,0.2,1.1,0.1,1.5c-0.1,0.2-0.1,0.4-0.8,0.4C49.4,45.1,49.3,44.9,49.2,44.7z M49.2,35
          c0.1-0.2,0.1-0.4,0.8-0.4c0.6,0,0.7,0.2,0.8,0.4c0.1,0.4,0.1,0.9,0,1.4c-0.2,0-0.4-0.1-0.6-0.1c-0.3,0-0.6,0.1-0.9,0.1
          C49.1,36,49.1,35.4,49.2,35z M54.9,39.1c0.2,0.1,0.4,0.1,0.4,0.8c0,0.6-0.2,0.7-0.4,0.8c-0.4,0.1-0.9,0.1-1.4,0
          c0.1-0.3,0.1-0.5,0.1-0.8c0-0.2,0-0.5-0.1-0.7C54,39,54.5,39,54.9,39.1z M45.1,40.6c-0.2-0.1-0.4-0.1-0.4-0.8
          c0-0.6,0.2-0.7,0.4-0.8c0.5-0.2,1.2-0.1,1.7,0.1c0,0.2-0.1,0.4-0.1,0.6c0,0.2,0,0.5,0.1,0.7C46.3,40.7,45.6,40.8,45.1,40.6z
          M38.9,30.9c1.2,1.1,3.8,1.4,5.3,1.5c0.1,0,0.2,0,0.3,0c0.2,0,0.4,0,0.6-0.1c0.4-0.2,0.8-0.6,0.9-1c0.1-0.2,0.1-0.4,0-0.6
          c-0.1-1.5-0.5-4.1-1.5-5.4c0,0,0,0,0,0c1.6,0,3.8-1.6,5-2.7c0.3-0.2,0.5-0.4,0.6-0.7c0.2-0.4,0.1-0.9-0.1-1.3
          c-0.1-0.2-0.2-0.3-0.4-0.4c-1.2-1.1-3.4-2.8-5-2.8c0,0-0.1,0-0.1,0c0,0,0.1-0.1,0.1-0.1c0,0,0,0,0,0c1.2-1.2,1.5-4.1,1.6-5.4
          c0.1-0.3,0-0.6-0.1-0.9c-0.2-0.4-0.6-0.8-1-0.9c-0.2,0-0.4-0.1-0.6,0c-1.6,0.1-4.4,0.5-5.5,1.6c0,0,0,0.1-0.1,0.1
          c-0.1-1.6-1.7-3.7-2.7-4.9c-0.2-0.3-0.4-0.5-0.7-0.6c-0.4-0.2-0.9-0.1-1.3,0.1C34,6.7,33.8,6.8,33.7,7c-1,1.2-2.7,3.3-2.7,4.9
          c-1.2-1.1-3.8-1.4-5.4-1.6c-0.3-0.1-0.7,0-1,0.1c-0.4,0.2-0.7,0.6-0.8,1.1c0,0.2,0,0.3,0,0.5c0.1,1.3,0.4,4.3,1.6,5.5c0,0,0,0,0,0
          c-1.6,0-3.8,1.6-5,2.7c-0.3,0.2-0.5,0.4-0.6,0.7c-0.2,0.4-0.1,0.9,0.1,1.3c0.1,0.2,0.2,0.3,0.4,0.4c1.2,1.1,3.4,2.7,5,2.8
          c-1.1,1.2-1.5,4.1-1.6,5.4c0,0.3,0,0.6,0.1,0.8c0.1,0.3,0.3,0.5,0.6,0.7c0.1,0,0.1,0.1,0.2,0.1c0.2,0.1,0.4,0.2,0.7,0.2
          c0.1,0,0.1,0,0.2,0c1.6-0.1,4.3-0.5,5.5-1.6c0.1,1.4,1.4,3.3,2.4,4.5v22.2c0,0.8,0.7,1.5,1.5,1.5s1.5-0.7,1.5-1.5V35.6
          C37.4,34.3,38.8,32.4,38.9,30.9z M31.1,17.5C31.1,17.5,31.1,17.5,31.1,17.5c0.1-0.1,0.1-0.1,0.1-0.2c1-0.9,2.3-1.4,3.7-1.4
          c3,0,5.5,2.4,5.5,5.4c0,1.4-0.6,2.7-1.5,3.7c-0.1,0-0.1,0.1-0.2,0.1c0,0,0,0-0.1,0.1c0,0-0.1,0.1-0.1,0.1c-1,0.9-2.3,1.5-3.7,1.5
          c-3,0-5.5-2.4-5.5-5.4c0-1.4,0.6-2.8,1.5-3.7C30.9,17.6,31,17.6,31.1,17.5z M35.9,30.8c0,0.3-0.4,1-1,1.7c-0.5-0.8-0.9-1.4-1-1.7
          c0-0.5,0.4-0.9,0.9-0.9c0,0,0,0,0,0C35.5,29.9,35.9,30.3,35.9,30.8z M40.9,28.7c-0.3-0.3-0.3-0.9-0.1-1.2c0.1-0.1,0.1-0.1,0.2-0.2
          c0.4-0.3,0.9-0.3,1.2,0c0.2,0.2,0.4,0.9,0.6,1.9C41.9,29.1,41.1,28.9,40.9,28.7z M46.2,21.5c-0.8,0.5-1.4,0.9-1.7,1
          c-0.5,0-0.9-0.4-0.9-0.9c0,0,0,0,0,0c0-0.5,0.4-1,0.9-1C44.8,20.5,45.5,20.9,46.2,21.5z M41,14c0.2-0.2,1-0.4,1.9-0.6
          c-0.2,0.9-0.4,1.7-0.5,1.9c-0.4,0.4-1,0.4-1.3,0c0,0,0,0,0,0C40.7,14.9,40.7,14.3,41,14z M34.9,10.3c0.5,0.8,0.9,1.4,1,1.7
          c0,0.5-0.4,0.9-0.9,0.9c0,0,0,0,0,0c-0.5,0-1-0.4-1-0.9C34,11.7,34.4,11.1,34.9,10.3z M29,14.1c0.3,0.3,0.3,0.9,0.1,1.3
          c-0.1,0.1-0.1,0.1-0.2,0.2c-0.4,0.3-0.9,0.3-1.2-0.1c-0.2-0.2-0.4-1-0.6-1.9C28,13.7,28.7,13.9,29,14.1z M25.4,20.5
          c0.5,0,0.9,0.4,0.9,0.9c0,0,0,0,0,0c0,0.5-0.4,1-0.9,1c-0.3,0-1-0.4-1.7-1C24.5,20.9,25.1,20.5,25.4,20.5z M28.9,28.8
          c-0.2,0.2-1,0.4-1.9,0.6c0.2-0.9,0.4-1.7,0.5-1.9c0.4-0.4,1-0.4,1.3,0c0,0,0,0,0,0C29.3,27.8,29.3,28.5,28.9,28.8z M28,39.8
          c0-2.4-1.9-4.3-4.3-4.3c-0.1,0-0.3,0-0.4,0c0-0.1,0-0.3,0-0.4c0-2.4-1.9-4.3-4.3-4.3s-4.3,1.9-4.3,4.3c0,0.1,0,0.3,0,0.4
          c-0.1,0-0.3,0-0.4,0c-2.4,0-4.3,1.9-4.3,4.3s1.9,4.3,4.3,4.3c0.1,0,0.3,0,0.4,0c0,0.1,0,0.3,0,0.4c0,1.8,1.2,3.4,2.8,4v1.8l-4-3.1
          c-0.7-0.5-1.6-0.4-2.1,0.3c-0.5,0.7-0.4,1.6,0.3,2.1l5.8,4.5v3.7c0,0.8,0.7,1.5,1.5,1.5s1.5-0.7,1.5-1.5v-3.7l5.8-4.5
          c0.7-0.5,0.8-1.4,0.3-2.1c-0.5-0.7-1.4-0.8-2.1-0.3l-4,3.1v-1.8c1.6-0.6,2.8-2.2,2.8-4c0-0.1,0-0.3,0-0.4c0.1,0,0.3,0,0.4,0
          C26.1,44.1,28,42.1,28,39.8z M23.7,38.5c0.7,0,1.3,0.6,1.3,1.3c0,0.7-0.6,1.3-1.3,1.3c-0.4,0-1.3-0.6-2.2-1.3
          C22.4,39.1,23.4,38.5,23.7,38.5z M19.1,45.7c-0.7,0-1.3-0.6-1.3-1.3c0-0.4,0.6-1.3,1.3-2.2c0.7,1,1.2,1.9,1.3,2.2
          C20.4,45.1,19.8,45.7,19.1,45.7z M19.1,33.9c0.7,0,1.3,0.6,1.3,1.3c0,0.4-0.6,1.3-1.3,2.2c-0.7-1-1.2-1.9-1.3-2.2
          C17.8,34.5,18.4,33.9,19.1,33.9z M14.5,41.1c-0.7,0-1.3-0.6-1.3-1.3c0-0.7,0.6-1.3,1.3-1.3c0.4,0,1.3,0.6,2.2,1.3
          C15.8,40.5,14.9,41,14.5,41.1z"
        />
      </clipPath>
      <g clipPath="url(#SVGID_2_)">
        <rect
          x="4.5"
          y="49.2"
          transform="matrix(0.9736 -0.2284 0.2284 0.9736 -12.4735 10.8392)"
          fill="#B95700"
          width="72.1"
          height="20.2"
        />
        <rect
          x="1.1"
          y="39.7"
          transform="matrix(0.9736 -0.2284 0.2284 0.9736 -9.2234 9.6689)"
          fill="#EB6909"
          width="72.1"
          height="9.9"
        />
        <rect
          x="-1.2"
          y="30.1"
          transform="matrix(0.9736 -0.2284 0.2284 0.9736 -7.0856 8.899)"
          fill="#5F9501"
          width="72.1"
          height="9.9"
        />
        <rect
          x="-3.4"
          y="20.5"
          transform="matrix(0.9736 -0.2284 0.2284 0.9736 -4.9477 8.1291)"
          fill="#79B61D"
          width="72.1"
          height="9.9"
        />
        <rect
          x="-6.9"
          y="0.7"
          transform="matrix(0.9736 -0.2284 0.2284 0.9736 -1.6964 6.9583)"
          fill="#C8D200"
          width="72.1"
          height="20.2"
        />
      </g>
    </g>
  </svg>
);

GradientCrop43.defaultProps = {
  title: 'oilcrops',
};

GradientCrop43.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
};
