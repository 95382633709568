/* eslint-disable react/prop-types */
import React from 'react';

const EnglishAustria = (props) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 46.628 48"
    space="preserve"
    {...props}
  >
    <g dataname="Group 1">
      <path
        fill="none"
        d="M47.183 17.708l.047.139a3.788 3.788 0 00-.047-.139z"
        dataname="Path 1"
        transform="translate(-1.686 -1)"
      />
      <path
        fill="none"
        d="M19.515 1.481c-.239.048-.473.1-.709.16.046-.01.094-.017.14-.028.19-.046.378-.093.569-.132z"
        dataname="Path 2"
        transform="translate(-1.686 -1)"
      />
      <path
        fill="none"
        d="M47.5 18.81c.016.06.036.118.051.179-.011-.061-.031-.119-.051-.179z"
        dataname="Path 3"
        transform="translate(-1.686 -1)"
      />
      <path
        fill="none"
        d="M46.825 16.663l.025.064-.025-.064z"
        dataname="Path 4"
        transform="translate(-1.686 -1)"
      />
      <path
        fill="none"
        d="M4.686 38.8c-.115-.162-.23-.323-.341-.488-.067-.1-.141-.2-.207-.3.314.486.64.963.988 1.423-.154-.205-.292-.423-.44-.635z"
        dataname="Path 5"
        transform="translate(-1.686 -1)"
      />
      <path
        fill="none"
        d="M4.686 11.2c.148-.211.286-.429.441-.635-.354.469-.687.954-1.006 1.448.071-.11.152-.214.225-.323.11-.168.225-.329.34-.49z"
        dataname="Path 6"
        transform="translate(-1.686 -1)"
      />
      <path
        fill="none"
        d="M17.023 47.869c.046.015.092.032.138.046l-.138-.046z"
        dataname="Path 7"
        transform="translate(-1.686 -1)"
      />
      <path
        fill="none"
        d="M11.357 4.8q.444-.285.9-.551c.273-.159.547-.314.826-.462.066-.035.128-.074.194-.108a23.913 23.913 0 00-2.866 1.749c.086-.061.178-.116.266-.176.223-.152.451-.302.68-.452z"
        dataname="Path 8"
        transform="translate(-1.686 -1)"
      />
      <path
        fill="none"
        d="M14.8 2.966c.085-.037.167-.081.253-.117-.512.214-1.013.449-1.506.7.06-.03.123-.055.183-.084q.524-.265 1.07-.499z"
        dataname="Path 9"
        transform="translate(-1.686 -1)"
      />
      <path
        fill="none"
        d="M10.034 5.712c.064-.047.123-.1.187-.146-.114.083-.229.163-.342.247l.008.008z"
        dataname="Path 10"
        transform="translate(-1.686 -1)"
      />
      <path
        fill="none"
        d="M16.456 2.323c.061-.021.12-.047.181-.067-.492.166-.978.345-1.456.541.1-.042.206-.074.308-.114q.478-.191.967-.36z"
        dataname="Path 11"
        transform="translate(-1.686 -1)"
      />
      <path
        fill="none"
        d="M18.43 1.729l.075-.021q-.8.2-1.577.45c.037-.012.075-.021.112-.033q.687-.217 1.39-.396z"
        dataname="Path 12"
        transform="translate(-1.686 -1)"
      />
      <path
        fill="none"
        d="M47.769 19.912c.017.081.039.16.056.241-.017-.081-.039-.16-.056-.241z"
        dataname="Path 13"
        transform="translate(-1.686 -1)"
      />
      <path
        fill="none"
        d="M19.467 48.51c-.076-.016-.15-.036-.226-.052.076.016.151.037.226.052z"
        dataname="Path 14"
        transform="translate(-1.686 -1)"
      />
      <path
        fill="none"
        d="M18.3 48.241c-.06-.016-.119-.035-.179-.051.063.016.122.035.179.051z"
        dataname="Path 15"
        transform="translate(-1.686 -1)"
      />
      <path
        fill="none"
        d="M46.4 15.588l.021.047z"
        dataname="Path 16"
        transform="translate(-1.686 -1)"
      />
      <path
        fill="none"
        d="M16.042 47.536l-.064-.026z"
        dataname="Path 17"
        transform="translate(-1.686 -1)"
      />
      <path
        fill="none"
        d="M14.949 47.1l-.046-.021z"
        dataname="Path 18"
        transform="translate(-1.686 -1)"
      />
      <path
        fill="#d80027"
        d="M48.255 23.392c-.019-.284-.036-.568-.065-.85-.013-.131-.034-.26-.05-.39-.032-.272-.062-.544-.1-.813-.009-.06-.022-.119-.032-.179H31.549l.959-.96L42.9 9.814q-.719-.878-1.516-1.683l-.675.675L29.31 20.2l-.2.2-.764.764-.195.195-.765.765-.2.2-.406.406-.679.679-.678.678-2.037 2.037-.679.679-.679.679-.406.406-.195.2-.765.765-.2.195-.764.765-.195.2L8.12 41.39l-.675.674q.805.8 1.684 1.516l10.386-10.386.959-.96V48.69c.06.01.119.023.179.032.269.041.542.072.814.1.13.015.259.036.39.05.281.028.566.046.85.064.124.009.246.022.371.029.409.02.822.031 1.236.031a24.073 24.073 0 003.841-.31V32.234l.959.96L39.5 43.582a24.278 24.278 0 001.782-1.61 23.854 23.854 0 001.61-1.782L32.508 29.8l-.959-.96h16.456a24.187 24.187 0 00.309-3.84q0-.623-.031-1.237c-.006-.124-.02-.247-.028-.371z"
        dataname="Path 19"
        transform="translate(-1.686 -1)"
      />
      <path
        fill="#d80027"
        d="M12.92 17h16.762L39.9 6.779c-.036-.031-.07-.066-.107-.1L39.7 6.6c-.069-.058-.144-.109-.214-.166-.25-.2-.506-.4-.763-.595-.11-.082-.213-.171-.324-.251l-.243-.174a24.02 24.02 0 00-2.816-1.719c-.1-.052-.2-.1-.3-.155a24.096 24.096 0 00-1.476-.683l-.193-.076c-.46-.188-.927-.36-1.4-.52-.1-.035-.2-.071-.306-.1q-.771-.249-1.566-.446c-.106-.027-.214-.05-.321-.075-.218-.051-.442-.088-.663-.133-.27-.055-.538-.118-.812-.164l-.147-.026-.086-.017a23.773 23.773 0 00-2.043-.229A22.88 22.88 0 0024.3 1h-.038c-.56 0-1.113.028-1.663.066h-.037c-.127.009-.254.018-.38.029h-.031c-.53.046-1.056.108-1.575.189-.018 0-.037 0-.055.006l-.051.009c-.061.01-.121.023-.182.033s-.092.02-.138.028c-.215.037-.426.086-.639.129-.19.038-.381.071-.569.114-.046.011-.094.018-.14.028-.1.024-.2.045-.3.07l-.075.021c-.469.118-.932.251-1.39.4-.037.012-.075.021-.112.033-.1.031-.194.065-.291.1-.061.02-.12.046-.181.067q-.489.17-.967.36c-.1.04-.207.072-.308.114l-.132.052c-.086.036-.168.08-.253.117-.362.156-.718.322-1.07.495-.06.029-.123.054-.183.084l-.266.137c-.066.034-.128.073-.194.108-.279.148-.553.3-.826.462q-.456.266-.9.551c-.229.147-.456.3-.68.452-.088.06-.18.115-.266.176-.063.045-.127.089-.19.135s-.123.1-.187.146-.1.075-.144.112q-.341.255-.67.522c-.031.024-.063.047-.093.072-.079.064-.162.122-.24.187l-.068.059c-.129.108-.248.228-.374.339q-.256.227-.506.459c-.1.092-.2.178-.3.272l-.069.062-.073.07c-.062.06-.117.126-.178.188s-.129.137-.2.2c-.206.211-.411.424-.61.643-.067.075-.14.143-.206.218l-.087.1c-.1.111-.185.23-.28.342-.069.083-.137.169-.2.253s-.124.151-.185.227c-.14.175-.29.342-.425.521-.155.206-.293.424-.441.635-.115.162-.23.323-.341.489-.073.109-.154.213-.225.323l-.067.105c-.071.11-.131.227-.2.339-.1.158-.189.321-.283.481q-.387.663-.732 1.352c-.08.161-.161.323-.238.486-.135.287-.268.575-.392.867l-.115.29q-.216.528-.407 1.069H12.92z"
        dataname="Path 20"
        transform="translate(-1.686 -1)"
      />
      <path
        fill="#d80027"
        d="M12.92 33H1.686q.19.542.407 1.069c.039.1.075.194.115.29.124.292.257.58.392.867.077.163.158.325.238.486q.345.688.732 1.352c.094.16.186.323.283.481.065.105.122.215.188.319.032.05.064.1.1.15.066.1.14.2.207.3.111.165.226.326.341.488.148.211.286.429.441.635.135.179.285.346.425.521l.185.227c.062.076.136.17.2.253.047.054.09.112.136.166h.005l7.6-7.6z"
        dataname="Path 21"
        transform="translate(-1.686 -1)"
      />
      <path
        fill="#2e3192"
        d="M10.034 5.712l-.147.109c.052-.034.098-.073.147-.109z"
        dataname="Path 22"
        transform="translate(-1.686 -1)"
      />
      <path
        fill="#2e3192"
        d="M19.515 1.5v-.019c-.191.039-.379.086-.569.129.188-.043.379-.076.569-.11z"
        dataname="Path 23"
        transform="translate(-1.686 -1)"
      />
      <path
        fill="#2e3192"
        d="M35.34 3.695a24.02 24.02 0 012.817 1.719l.243.174c.111.08.214.169.324.251l.01-.01.008-.008a24.009 24.009 0 00-5.161-2.955 23.777 23.777 0 011.759.829z"
        dataname="Path 24"
        transform="translate(-1.686 -1)"
      />
      <path
        fill="#2e3192"
        d="M33.563 2.857l-.032-.012.05.021z"
        dataname="Path 25"
        transform="translate(-1.686 -1)"
      />
      <path
        fill="#2e3192"
        d="M30.1 1.711q.794.2 1.566.446c.1.033.2.069.306.1.473.16.94.332 1.4.52l.161.064a23.748 23.748 0 00-4.417-1.364V1.5c.221.045.445.082.663.133.105.028.213.051.321.078z"
        dataname="Path 26"
        transform="translate(-1.686 -1)"
      />
      <path
        fill="#0052b4"
        d="M47.769 19.912a23.764 23.764 0 00-.214-.923c-.015-.061-.035-.119-.051-.179a23.176 23.176 0 00-.274-.963l-.047-.139c-.1-.329-.214-.657-.333-.981l-.025-.064c-.129-.346-.263-.69-.407-1.028l-.021-.047a23.944 23.944 0 00-2.9-5.015L33.866 20.2h13.967l-.008-.047c-.017-.081-.039-.16-.056-.241z"
        dataname="Path 27"
        transform="translate(-1.686 -1)"
      />
      <path
        fill="#0052b4"
        d="M9.887 44.179a23.991 23.991 0 005.016 2.9l.046.021q.508.216 1.029.407l.064.026c.323.118.651.228.981.333.046.015.092.032.138.046.318.1.64.189.963.275.06.016.119.035.179.051.31.08.624.149.938.217.076.016.15.036.226.052l.048.009V34.552l-9.627 9.627z"
        dataname="Path 28"
        transform="translate(-1.686 -1)"
      />
      <path
        fill="#0052b4"
        d="M29.114 48.519a23.887 23.887 0 009.628-4.34l-9.628-9.627z"
        dataname="Path 29"
        transform="translate(-1.686 -1)"
      />
      <path
        fill="#0052b4"
        d="M43.493 39.427a23.875 23.875 0 004.34-9.627H33.866z"
        dataname="Path 30"
        transform="translate(-1.686 -1)"
      />
    </g>
  </svg>
);

export default EnglishAustria;
