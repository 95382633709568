/* eslint-disable import/prefer-default-export */
/* eslint-disable react/prop-types */
import React from 'react';
import { PropTypes } from 'prop-types';
import { useLocalizedFromContext } from 'data/Localization';

export const GradientCrop45 = ({ title, ...props }) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 70 70"
    enableBackground="new 0 0 70 70"
    {...props}
  >
    <title>{useLocalizedFromContext(title)}</title>
    <g>
      <clipPath id="SVGID_2_">
        <path
          id="SVGID_71_"
          d="M58.4,37.5c-0.2-0.6-0.8-1.1-1.4-1.1c-3.3,0-5.9,1.3-7.8,3c2.3-10.2,5.8-17.8,5.8-18
          c0.3-0.6,0.1-1.2-0.3-1.7c-0.4-0.4-1.1-0.6-1.7-0.3c-5,2.2-8.4,5.7-10.8,9.4c2.1-8.9,4.9-15.4,5-15.5c0.3-0.6,0.1-1.3-0.4-1.8
          c-0.5-0.4-1.3-0.5-1.8-0.1c-5.9,4.2-9.7,9.8-12,15.1c-2.5-8.5-8.1-11.4-8.4-11.5c-0.5-0.3-1.2-0.2-1.6,0.2s-0.7,1-0.5,1.5
          c0.7,2.9,1,7.2,1.1,10.3c-1.3-1.1-2.7-2.1-4.2-2.8c-0.7-0.3-1.5-0.1-1.9,0.5c-0.4,0.6-0.3,1.4,0.2,1.9c0,0,1.5,1.7,2.8,7.1
          c-2.2-1.7-4.9-3.6-7.1-3.8c-0.6,0-1.1,0.2-1.4,0.7s-0.3,1.1,0,1.6c0.1,0.1,5.1,7.9,5.1,22.6c0,0.8,0.7,1.5,1.5,1.5h5.3h7.4h7.2
          h7.1h5.3c0.8,0,1.5-0.7,1.5-1.5c0-12.4,5.1-15.8,5.1-15.8C58.2,38.8,58.5,38.1,58.4,37.5z M50.5,24.2c-2.2,5.9-5.9,17.2-6.2,29.2
          h-4.2v-7.9c0-1,0-1.9,0.1-2.9C40.3,41.7,41.9,30.4,50.5,24.2z M42.2,17.7c-1.9,5.6-4.5,14.8-5,24.6c0,0,0,0,0,0.1c0,0,0,0.1,0,0.1
          c0,1-0.1,2-0.1,3v7.9h-4.2c0-2-0.1-5.7-0.9-9.8C32,42.1,32.7,27.7,42.2,17.7z M30.9,31.8c-0.5,1.6-0.8,3.1-1.1,4.4
          c-0.9-2.2-2-4.2-3.2-5.9c0-1.2-0.1-6-0.6-10.4C27.9,22.1,30.2,25.8,30.9,31.8z M29.9,53.4h-4.5c-0.1-11.8-1.5-19-2.8-23.2
          C29.2,37,29.8,49.3,29.9,53.4z M16.4,34.5c1.7,1.2,3.6,2.8,4.9,4c0.6,3.8,1,8.6,1,14.8h-2.3C19.9,44.6,18,38.2,16.4,34.5z
          M49.6,53.4h-2.3c0.1-2.2,0.2-4.4,0.5-6.6c0,0,0,0,0-0.1c0-0.1,1.4-4.4,5-6.4C51.3,42.9,49.8,47,49.6,53.4z"
        />
      </clipPath>
      <g clipPath="url(#SVGID_2_)">
        <rect
          x="4.5"
          y="49.2"
          transform="matrix(0.9736 -0.2284 0.2284 0.9736 -12.47 10.8225)"
          fill="#B95700"
          width="72.1"
          height="20.2"
        />
        <rect
          x="1"
          y="39.7"
          transform="matrix(0.9736 -0.2284 0.2284 0.9736 -9.2199 9.6522)"
          fill="#EB6909"
          width="72.1"
          height="9.9"
        />
        <rect
          x="-1.2"
          y="30.1"
          transform="matrix(0.9736 -0.2284 0.2284 0.9736 -7.0821 8.8823)"
          fill="#5F9501"
          width="72.1"
          height="9.9"
        />
        <rect
          x="-3.5"
          y="20.5"
          transform="matrix(0.9736 -0.2284 0.2284 0.9736 -4.9442 8.1125)"
          fill="#79B61D"
          width="72.1"
          height="9.9"
        />
        <rect
          x="-6.9"
          y="0.7"
          transform="matrix(0.9736 -0.2284 0.2284 0.9736 -1.6929 6.9416)"
          fill="#C8D200"
          width="72.1"
          height="20.2"
        />
      </g>
    </g>
  </svg>
);

GradientCrop45.defaultProps = {
  title: 'grassland',
};

GradientCrop45.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
};
