/* eslint-disable import/prefer-default-export */
/* eslint-disable react/prop-types */
import React from 'react';
import { PropTypes } from 'prop-types';
import { useLocalizedFromContext } from 'data/Localization';

export const Crop37 = ({ title, fill, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="29.37"
    height="68.485"
    viewBox="0 0 29.37 68.485"
    {...props}
  >
    <title>{useLocalizedFromContext(title)}</title>
    <g id="Group_1549" dataname="Group 1549" transform="translate(1.3 1.3)">
      <path
        id="Path_2423"
        dataname="Path 2423"
        d="M1691.563,5855.119c1.026-13.619,4.852-23.736,9.416-23.736s8.4,10.154,9.422,23.809"
        transform="translate(-1687.655 -5831.382)"
        fill="none"
        stroke={fill || '#849daa'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.6"
      />
      <path
        id="Path_2424"
        dataname="Path 2424"
        d="M1704.778,5860.344c.119-16.174,1.612-28.962,3.435-28.962s3.308,12.717,3.434,28.826"
        transform="translate(-1694.771 -5831.382)"
        fill="none"
        stroke={fill || '#849daa'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.6"
      />
      <line
        id="Line_502"
        dataname="Line 502"
        x2="10.034"
        transform="translate(8.378 4.569)"
        fill="none"
        stroke={fill || '#849daa'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.6"
      />
      <line
        id="Line_503"
        dataname="Line 503"
        x2="13.294"
        transform="translate(6.8 9.448)"
        fill="none"
        stroke={fill || '#849daa'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.6"
      />
      <line
        id="Line_504"
        dataname="Line 504"
        x2="15.848"
        transform="translate(5.43 14.163)"
        fill="none"
        stroke={fill || '#849daa'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.6"
      />
      <line
        id="Line_505"
        dataname="Line 505"
        x2="17.736"
        transform="translate(4.517 18.987)"
        fill="none"
        stroke={fill || '#849daa'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.6"
      />
      <line
        id="Line_506"
        dataname="Line 506"
        x2="18.56"
        transform="translate(4.007 23.742)"
        fill="none"
        stroke={fill || '#849daa'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.6"
      />
      <line
        id="Line_507"
        dataname="Line 507"
        x2="7.229"
        transform="translate(9.745 28.634)"
        fill="none"
        stroke={fill || '#849daa'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.6"
      />
      <path
        id="Path_2425"
        dataname="Path 2425"
        d="M1718.4,5916.89c3.842-4.581,7.367-14.751,7.119-36.636,0,0-7,1.891-13.332,11.445"
        transform="translate(-1698.759 -5858.405)"
        fill="none"
        stroke={fill || '#849daa'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.6"
      />
      <path
        id="Path_2426"
        dataname="Path 2426"
        d="M1683.107,5880.253s10.591,2.861,17.513,19.342c6.988,16.638.516,19.432-1.319,20.358S1682.607,5924.409,1683.107,5880.253Z"
        transform="translate(-1683.095 -5858.405)"
        fill="none"
        stroke={fill || '#849daa'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.6"
      />
      <path
        id="Path_2427"
        dataname="Path 2427"
        d="M1711.7,5969.012v4.354h-6.287v-4.354"
        transform="translate(-1695.111 -5907.48)"
        fill="none"
        stroke={fill || '#849daa'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.6"
      />
      <path
        id="Path_2428"
        dataname="Path 2428"
        d="M1697.755,5933.988s1.756,11.793,5.868,15.848"
        transform="translate(-1690.989 -5888.116)"
        fill="none"
        stroke={fill || '#849daa'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.6"
      />
      <path
        id="Path_2429"
        dataname="Path 2429"
        d="M1723.127,5900.824a30.039,30.039,0,0,0-5.206,6.982"
        transform="translate(-1701.848 -5869.778)"
        fill="none"
        stroke={fill || '#849daa'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.6"
      />
    </g>
  </svg>
);

Crop37.defaultProps = {
  title: 'maize',
};

Crop37.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
};
