/* eslint-disable react/prop-types */
/* eslint-disable import/prefer-default-export */
import React from 'react';

const Tractor = ({ brokenWheel, ...props }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 52 42"
      enableBackground="new 0 0 52 42"
      {...props}
    >
      <path
        fill="#79B61D"
        d="M50.4,23.3h-0.9l-0.2-2.7c-0.1-2-1.7-3.6-3.7-3.6h-2.4h-4l-2.8-8.4c-0.1-3.9-2.4-6.4-5.8-6.4H20.2
        c-0.7,0-1.3,0.5-1.4,1.1l-1.4,5.2c-0.1,0.5,0,0.9,0.3,1.3c0.3,0.4,0.7,0.6,1.2,0.6h1l-0.3,1.7H1.7c-0.8,0-1.5,0.7-1.5,1.5v5.2
        c0,0.8,0.6,1.4,1.4,1.5v4.4c0,0.2,0,0.4,0.1,0.5c0,0,0,0,0,0c0.1,0.2,0.2,0.3,0.3,0.5c0,0,0,0,0,0l2.2,2.3v3.1
        c0,0.8,0.7,1.5,1.5,1.5h5.8c1.2,4,5,7,9.4,7c3.6,0,6.8-2,8.5-5h10.2c0.2,2.9,2.6,5.1,5.5,5.1c2.9,0,5.3-2.3,5.5-5.1
        c0.7-0.1,1.2-0.7,1.2-1.5v-8.4C51.9,24,51.2,23.3,50.4,23.3z M21.4,5.2h9.2c1.6,0,2.3,1.1,2.6,2.2h-0.8H21.7h-0.9L21.4,5.2z
        M13.7,23.3H4.6v-2.9h13.7l0,0.1C16.4,21,14.9,21.9,13.7,23.3z M3.2,15.1h15.9l-0.4,2.3c-0.1,0-0.2,0-0.3,0H3.2V15.1z M6.8,26.4
        l-0.2-0.2h5.2c-0.4,1.1-0.7,2.2-0.7,3.5H7.2v-2.2C7.2,27.1,7,26.7,6.8,26.4z M20.9,36.7c-3.8,0-6.8-3.1-6.8-6.8s3.1-6.8,6.8-6.8
        s6.8,3.1,6.8,6.8S24.6,36.7,20.9,36.7z M21.3,20.1l1.6-9.7h9.5h1.4l2.2,6.6h-6.2c-0.8,0-1.5,0.7-1.5,1.5s0.7,1.5,1.5,1.5h8.3h5.1
        h2.4c0.4,0,0.7,0.3,0.7,0.7l0.2,3.4l0,0.8c0,0.8,0.7,1.4,1.5,1.4h0.8v3.9c-1-0.9-2.3-1.5-3.8-1.5c-2.2,0-4,1.2-4.9,3h-9.7
        c0.1-0.6,0.2-1.2,0.2-1.8C30.7,24.6,26.5,20.3,21.3,20.1z M45.1,36.8c-1.4,0-2.6-1.2-2.6-2.6c0-1.4,1.2-2.6,2.6-2.6
        c1.4,0,2.6,1.2,2.6,2.6C47.7,35.7,46.5,36.8,45.1,36.8z"
      />
    </svg>
  );
};

Tractor.defaultProps = {
  brokenWheel: false,
};

export default Tractor;
