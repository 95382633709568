/* eslint-disable import/prefer-default-export */
/* eslint-disable react/prop-types */
import React from 'react';
import { PropTypes } from 'prop-types';
import { useLocalizedFromContext } from 'data/Localization';

export const Crop14 = ({ title, fill, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="72.839"
    height="136.341"
    viewBox="0 0 72.839 136.341"
    {...props}
  >
    <title>{useLocalizedFromContext(title)}</title>
    <g id="Component_1_1" dataname="Component 1 – 1" transform="translate(2.5 2.5)">
      <g id="Group_403" dataname="Group 403" transform="translate(-2323.697 -5227.853)">
        <path
          id="Path_1133"
          dataname="Path 1133"
          d="M2368.524,5256.023c-7.6-7.386-.665-15.066,6.424-18.3a11.183,11.183,0,0,0,6.934-9.869s23.155,29.558-2.27,32.681"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
        <path
          id="Path_1134"
          dataname="Path 1134"
          d="M2335.63,5330.392c-10.578-.684-11.62,9.612-9.265,17.04a11.18,11.18,0,0,1-2.668,11.762s37.636-2.665,22.608-23.757"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
        <g id="Group_399" dataname="Group 399">
          <path
            id="Path_1135"
            dataname="Path 1135"
            d="M2351.688,5292.961c-3.832-3.706-5.443-8.536-3.682-12.5,1.829-4.119,6.785-6.166,12.355-5.606"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
          <path
            id="Path_1136"
            dataname="Path 1136"
            d="M2372.384,5280.4c3.835,3.707,5.448,8.539,3.686,12.509-1.826,4.112-6.767,6.159-12.324,5.609"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
        <g id="Group_400" dataname="Group 400">
          <path
            id="Path_1137"
            dataname="Path 1137"
            d="M2359.967,5274.312c-3.832-3.707-5.443-8.537-3.682-12.5,1.829-4.12,6.785-6.167,12.355-5.607"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
          <path
            id="Path_1138"
            dataname="Path 1138"
            d="M2380.663,5261.755c3.835,3.707,5.448,8.539,3.686,12.509-1.826,4.113-6.767,6.159-12.324,5.61"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
        <g id="Group_401" dataname="Group 401">
          <path
            id="Path_1139"
            dataname="Path 1139"
            d="M2343.463,5311.489c-3.832-3.706-5.443-8.537-3.681-12.5,1.828-4.12,6.784-6.166,12.354-5.606"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
          <path
            id="Path_1140"
            dataname="Path 1140"
            d="M2364.159,5298.932c3.835,3.707,5.448,8.54,3.686,12.51-1.826,4.112-6.767,6.158-12.324,5.609"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5 "
          />
        </g>
        <g id="Group_402" dataname="Group 402">
          <path
            id="Path_1141"
            dataname="Path 1141"
            d="M2335.241,5330.01c-3.832-3.707-5.443-8.537-3.681-12.5,1.829-4.12,6.784-6.167,12.354-5.607"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
          <path
            id="Path_1142"
            dataname="Path 1142"
            d="M2355.937,5317.453c3.835,3.707,5.448,8.54,3.686,12.51-1.825,4.112-6.766,6.158-12.324,5.609"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
      </g>
      <path
        id="Path_1143"
        dataname="Path 1143"
        d="M2340.225,5323.5s2.135,5.518,10.8,4.154"
        transform="translate(-2323.697 -5227.853)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_1144"
        dataname="Path 1144"
        d="M2348.416,5305.018s2.136,5.518,10.8,4.153"
        transform="translate(-2323.697 -5227.853)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_1145"
        dataname="Path 1145"
        d="M2356.607,5286.538s2.136,5.518,10.8,4.154"
        transform="translate(-2323.697 -5227.853)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_1146"
        dataname="Path 1146"
        d="M2364.8,5268.059s2.136,5.518,10.8,4.153"
        transform="translate(-2323.697 -5227.853)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
    </g>
  </svg>
);

Crop14.defaultProps = {
  title: 'protein_peas',
};

Crop14.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
};
