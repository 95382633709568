/* eslint-disable import/prefer-default-export */
/* eslint-disable react/prop-types */
import React from 'react';

export const Target = (props) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 128 128"
      enableBackground="new 0 0 128 128"
      xmlSpace="preserve"
      {...props}
    >
      <g>
        <defs>
          <path
            id="SVGID_17_Target"
            d="M108.1,34.2c7.1,10.1,10.9,21.9,10.9,34.3c0,32.8-26.7,59.5-59.5,59.5C26.7,128,0,101.3,0,68.5
            C0,35.7,26.7,9,59.5,9c12.2,0,24,3.7,34,10.7c0.7,0.5,0.8,1.4,0.4,2.1c-0.5,0.7-1.4,0.8-2.1,0.4C82.3,15.5,71.1,12,59.5,12
            C28.3,12,3,37.4,3,68.5S28.3,125,59.5,125c31.1,0,56.5-25.3,56.5-56.5c0-11.7-3.6-23-10.3-32.6c-0.5-0.7-0.3-1.6,0.4-2.1
            C106.7,33.4,107.6,33.5,108.1,34.2z M83.5,33.3c0.4-0.7,0.2-1.6-0.5-2.1c-7-4.5-15.2-6.8-23.5-6.8c-24.3,0-44.1,19.8-44.1,44.1
            c0,24.3,19.8,44.1,44.1,44.1c24.3,0,44.1-19.8,44.1-44.1c0-7.9-2.1-15.7-6.2-22.5c-0.4-0.7-1.4-0.9-2.1-0.5
            c-0.7,0.4-0.9,1.4-0.5,2.1c3.8,6.3,5.8,13.6,5.8,21c0,22.7-18.4,41.1-41.1,41.1c-22.7,0-41.1-18.4-41.1-41.1
            c0-22.7,18.4-41.1,41.1-41.1c7.8,0,15.4,2.2,21.9,6.3C82.1,34.2,83,34,83.5,33.3z M73.7,45.2c0.4-0.7,0.1-1.6-0.6-2
            c-4.1-2.2-8.8-3.4-13.6-3.4c-15.8,0-28.7,12.9-28.7,28.7s12.9,28.7,28.7,28.7c15.8,0,28.7-12.9,28.7-28.7c0-3.9-0.8-7.6-2.3-11.2
            c-0.3-0.8-1.2-1.1-2-0.8c-0.8,0.3-1.1,1.2-0.8,2c1.3,3.2,2,6.5,2,10c0,14.2-11.5,25.7-25.7,25.7c-14.2,0-25.7-11.5-25.7-25.7
            c0-14.2,11.5-25.7,25.7-25.7c4.2,0,8.4,1.1,12.1,3.1C72.4,46.2,73.3,46,73.7,45.2z M58.7,58.2c0.8-0.1,1.5-0.8,1.4-1.6
            c-0.1-0.8-0.8-1.5-1.6-1.4c-6.9,0.5-12.3,6.4-12.3,13.3c0,7.4,6,13.4,13.4,13.4c6.6,0,12.3-5,13.2-11.5c0.1-0.8-0.5-1.6-1.3-1.7
            c-0.8-0.1-1.6,0.5-1.7,1.3c-0.7,5.1-5.1,8.9-10.2,8.9c-5.7,0-10.3-4.6-10.3-10.3C49.1,63.1,53.3,58.6,58.7,58.2z M126.4,12.3
            l-4.6-4.1l4.3-4.3c0.6-0.6,0.6-1.5,0-2.1c-0.6-0.6-1.5-0.6-2.1,0l-4.4,4.4l-5.7-5.1c0,0,0,0,0,0c-1.2-1-2.9-1.4-4.4-0.9
            c-1.5,0.5-2.7,1.7-3.1,3.3l-5,20.9L58.4,67.4c-0.6,0.6-0.6,1.5,0,2.1c0.3,0.3,0.7,0.4,1.1,0.4s0.8-0.1,1.1-0.4l42.9-42.9l21.3-6.4
            c1.6-0.5,2.9-1.8,3.2-3.5C128.2,15.1,127.7,13.4,126.4,12.3z"
          />
        </defs>
        <clipPath id="SVGID_2_Target">
          <use xlinkHref="#SVGID_17_Target" overflow="visible" />
        </clipPath>
        <g clipPath="url(#SVGID_2_Target)">
          <rect
            x="-5.5"
            y="89.9"
            transform="matrix(0.9736 -0.2284 0.2284 0.9736 -24.4455 20.0059)"
            fill="#5F9501"
            width="159.5"
            height="51.4"
          />
          <rect
            x="-17.3"
            y="39.9"
            transform="matrix(0.9736 -0.2284 0.2284 0.9736 -13.3258 16.0017)"
            fill="#79B61D"
            width="159.5"
            height="51.4"
          />
          <rect
            x="-29"
            y="-10.2"
            transform="matrix(0.9736 -0.2284 0.2284 0.9736 -2.21 12.0009)"
            fill="#C8D200"
            width="159.5"
            height="51.4"
          />
        </g>
      </g>
    </svg>
  );
};
