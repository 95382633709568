/* eslint-disable react/prop-types */
import React from 'react';

const Trash = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 25.09091" {...props}>
    <title>Trash</title>
    <path
      d="M17.09153,4.51181,16.418,22.69629a.61275.61275,0,0,1-.59986.57644H4.18183a.61367.61367,0,0,1-.59986-.57644L2.90847,4.51181a.90909.90909,0,0,0-1.81694.06729L1.765,22.76358a2.43145,2.43145,0,0,0,2.4168,2.32733H15.81817a2.43057,2.43057,0,0,0,2.4168-2.32733l.6735-18.18448a.90909.90909,0,0,0-1.81694-.06729Z"
      fill="#839ca9"
    />
    <path
      d="M9.27273,8.18182V20.54545a.72727.72727,0,1,0,1.45454,0V8.18182a.72727.72727,0,0,0-1.45454,0Z"
      fill="#839ca9"
    />
    <path
      d="M5.273,8.2032l.36364,12.36364a.72727.72727,0,1,0,1.45391-.04277L6.727,8.16044A.72727.72727,0,0,0,5.273,8.2032Z"
      fill="#839ca9"
    />
    <path
      d="M13.273,8.16044l-.36363,12.36363a.72727.72727,0,1,0,1.45391.04277L14.727,8.2032A.72727.72727,0,1,0,13.273,8.16044Z"
      fill="#839ca9"
    />
    <path
      d="M.90909,4.90909H19.09091a.90909.90909,0,0,0,0-1.81818H.90909a.90909.90909,0,1,0,0,1.81818Z"
      fill="#839ca9"
    />
    <path
      d="M7.23775,3.70429,7.6163,2.37938a.88116.88116,0,0,1,.74424-.5612h3.27892a.88064.88064,0,0,1,.74424.5612l.37855,1.32491,1.74823-.49949-.37855-1.32492A2.68627,2.68627,0,0,0,11.63946,0H8.36054A2.6867,2.6867,0,0,0,5.86807,1.87988L5.48952,3.2048Z"
      fill="#839ca9"
    />
  </svg>
);

export default Trash;
