/* eslint-disable import/prefer-default-export */
/* eslint-disable react/prop-types */
import React from 'react';

export const Usage = (props) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 71 71"
      enableBackground="new 0 0 71 71"
      {...props}
    >
      <g>
        <path
          fill="#839CA9"
          d="M50.1,25.3l-2.7-6.4v-6c0-0.8-0.7-1.5-1.5-1.5H25.2c-0.8,0-1.5,0.7-1.5,1.5v6l-2.7,6.4
          c-0.1,0.2-0.1,0.4-0.1,0.6v29.3c0,0.8,0.7,1.5,1.5,1.5h26.4c0.8,0,1.5-0.7,1.5-1.5V25.9C50.2,25.7,50.1,25.5,50.1,25.3z M44.3,14.4
          v3.3H26.7v-3.3H44.3z M47.2,53.6H23.8V26.2l2.3-5.5h18.7l2.3,5.5V53.6z"
        />
        <path
          fill="#839CA9"
          d="M42.4,43.8H37v-5.2c1.3-0.2,3.2-0.7,4.7-2.2c2.5-2.6,2-6,1.9-6.2c-0.1-0.7-0.7-1.2-1.4-1.2
          c-0.2,0-3.7-0.2-6.2,2.3c-0.2,0.2-0.3,0.4-0.5,0.6c-0.2-0.2-0.3-0.4-0.5-0.6c-2.5-2.5-6.1-2.3-6.2-2.3c-0.7,0-1.3,0.6-1.4,1.2
          c0,0.1-0.6,3.6,1.9,6.2c1.5,1.5,3.4,2,4.7,2.2v5.2h-5.4c-0.8,0-1.5,0.7-1.5,1.5s0.7,1.5,1.5,1.5h13.8c0.8,0,1.5-0.7,1.5-1.5
          S43.2,43.8,42.4,43.8z M38.1,33.5c0.7-0.7,1.7-1.1,2.4-1.3c-0.1,0.7-0.4,1.5-1.1,2.2c-0.7,0.7-1.7,1.1-2.4,1.3
          C37.2,35,37.5,34.1,38.1,33.5z M31.5,34.4c-0.7-0.7-1-1.5-1.1-2.2c0.8,0.2,1.7,0.5,2.4,1.3c0.7,0.7,1,1.5,1.1,2.2
          C33.2,35.5,32.2,35.1,31.5,34.4z"
        />
      </g>
    </svg>
  );
};
