import React, { useMemo, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { localized } from 'data/Localization';
import StepItemComponent from 'components/Molecules/StepItemComponent/StepItemComponent';
import useUser from 'customHooks/globalHooks/useUser';
import { useWindowWidth } from 'customHooks/globalHooks';
import UserUtils from 'Utils/UserUtils';
import useGetWsLogic from 'customHooks/planBuilderWSHooks';
import { getTerrazoFields } from 'Redux/actions/WebActions';
import { countryBehaviour } from 'data/Behaviour';
import { STATUS_DEFAULT } from 'Redux/actions/types';
import Dialogue from 'components/Molecules/Dialogue';

const StepsContainerComponent = ({
  user,
  user: { id_token, country },
  plan: { stepCompleted },
  myPlots: { data: plotsData },
  location: { pathname },
  terrazoFields: {
    data: terrazoFieldsData,
    status: terrazoFieldsStatus,
    errorCode: terrazoFieldsStatusErrorCode,
  },
  getTerrazoFields,
}) => {
  const [dialogue, setDialogue] = useState(null);
  const { locale } = useUser();
  const isMobileScreen = useWindowWidth(1000);

  const getTerrazoFieldsWs = useCallback(() => {
    getTerrazoFields(id_token);
  }, [id_token, getTerrazoFields]);
  useGetWsLogic({
    locale,
    shouldCallWebservice: () =>
      UserUtils.isInDatabase(user) &&
      terrazoFieldsStatus === STATUS_DEFAULT &&
      countryBehaviour(country, 'application_map_functionality'),
    webservice: getTerrazoFieldsWs,
    status: terrazoFieldsStatus,
    setDialogue,
    errorCode: terrazoFieldsStatusErrorCode,
  });

  const completedStepIndex = useMemo(() => {
    // Has terrazoFields
    if (terrazoFieldsData && terrazoFieldsData.length > 0) {
      return 4;
    }
    // User registered
    if (UserUtils.isInDatabase(user)) {
      return 3;
    }
    // Plan was created and the user arrived at accept terms screen
    if (pathname === '/get-started/terms-conditions-ng4') {
      return 2;
    }
    // Plot was created
    if (stepCompleted > 0 || plotsData.length > 0) {
      return 1;
    }
    return 0;
  }, [terrazoFieldsData, user, pathname, stepCompleted, plotsData.length]);

  const stepsForNZRegistration = useMemo(() => {
    const steps = [
      {
        index: 1,
        name: localized(locale, 'create_a_plot'),
        completed: completedStepIndex >= 1,
      },
      {
        index: 2,
        name: localized(locale, 'add_a_plan'),
        completed: completedStepIndex >= 2,
      },
      {
        index: 3,
        name: id_token
          ? localized(locale, 'accept_terms_and_conditions')
          : localized(locale, 'registration'),
        completed: completedStepIndex >= 3,
      },
      {
        index: 4,
        name: localized(locale, 'create_application_map'),
        completed: completedStepIndex >= 4,
      },
      {
        index: 5,
        name: localized(locale, 'download_and_login_to_nutrizones'),
        completed: completedStepIndex >= 5,
      },
    ];
    return steps;
  }, [completedStepIndex, locale, id_token]);

  const currentStep = useMemo(() => {
    let step;
    let i = stepsForNZRegistration.length - 1;
    while (step === undefined && i - 1 > 0) {
      const currentStep = stepsForNZRegistration[i - 1];
      if (currentStep.completed) {
        step = stepsForNZRegistration[i];
      }
      i -= 1;
    }
    if (step === undefined) {
      return stepsForNZRegistration[0];
    }
    return step;
  }, [stepsForNZRegistration]);

  if (isMobileScreen) {
    return (
      <>
        {dialogue && <Dialogue {...dialogue} />}
        <div className="steps-container-component-mobile">
          <div className="steps-container-component-mobile__top">
            <div className="steps-box">{localized(locale, 'step')}</div>
            <StepItemComponent
              isCompleted={false}
              text={currentStep.name}
              stepNr={currentStep.index}
            />
          </div>
          <div className="steps-container-component-mobile__bottom">
            {stepsForNZRegistration.map(({ index, completed }) => (
              <div key={index} className={`step-mark ${completed ? 'completed' : ''}`} />
            ))}
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      {dialogue && <Dialogue {...dialogue} />}
      <div className="steps-container-component">
        <div className="steps-container-component__steps-box">{localized(locale, 'step')}</div>
        {stepsForNZRegistration.map(({ index, name, completed }) => (
          <StepItemComponent key={index} isCompleted={completed} text={name} stepNr={index} />
        ))}
      </div>
    </>
  );
};

StepsContainerComponent.propTypes = {
  user: PropTypes.object,
  plan: PropTypes.object,
  myPlots: PropTypes.object,
  location: PropTypes.object,
  terrazoFields: PropTypes.object,
  getTerrazoFields: PropTypes.func,
};

export default withRouter(
  connect(
    ({ user, plan, myPlots, terrazoFields }) => ({
      user,
      plan,
      myPlots,
      terrazoFields,
    }),
    {
      getTerrazoFields,
    },
  )(StepsContainerComponent),
);
