/* eslint-disable import/prefer-default-export */
/* eslint-disable react/prop-types */
import React from 'react';

export const GradientProduct = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 62.44 91" {...props}>
    <title>Product</title>
    <rect fill="none" x="10.77" y="4.33" width="40.79" height="2.78" />
    <path
      fill="none"
      d="M57,21.94,52.26,11.55H10.06L6.21,20l-.87,1.9a10,10,0,0,0-1,4.56V86.67H58V26.5A10.64,10.64,0,0,0,57,21.94ZM20.79,42.28c-3.74-3.61-3.85-9.44-3.8-11.15A2.12,2.12,0,0,1,19.09,29c2.24-.11,7.69,0,11.29,3.5a8.75,8.75,0,0,1,1.53,1.77,9.75,9.75,0,0,1,1.31-1.63c3.59-3.71,9.42-3.82,11.13-3.78A2.12,2.12,0,0,1,46.49,31c.1,2.24,0,7.7-3.51,11.29C40.88,44.35,37.87,45.6,34,46V60.48H45.7a2.25,2.25,0,0,1,0,4.5H16.34a1.68,1.68,0,0,1-1.2-.5,2.58,2.58,0,0,1-.63-1.87,2.07,2.07,0,0,1,2.23-2.13h13.1V45.75C27.78,45.63,23.67,45,20.79,42.28Z"
    />
    <path
      fill="none"
      d="M42,33.41c-2.64.31-4.57,1.1-5.75,2.34v0a8.9,8.9,0,0,0-2.18,5.72c2.65-.31,4.59-1.1,5.77-2.35A9.8,9.8,0,0,0,42,33.41Z"
    />
    <path
      fill="none"
      d="M21.28,33.37c.31,2.64,1.1,4.56,2.34,5.74l0,0a9.14,9.14,0,0,0,5.71,2.15c-.31-2.64-1.1-4.57-2.34-5.75A9.8,9.8,0,0,0,21.28,33.37Z"
    />
    <path
      fill="#5f9501"
      d="M2.16,91H60.28a2.09,2.09,0,0,0,2.16-2.16V63L58,63.86V86.67H4.33V74.31L0,75.15V88.84A2.09,2.09,0,0,0,2.16,91Z"
    />
    <path
      fill="#79b61d"
      d="M4.33,26.5a10,10,0,0,1,1-4.56L6.21,20,1.08,21A14.72,14.72,0,0,0,0,26.5V75.15l4.33-.84Z"
    />
    <path
      fill="#79b61d"
      d="M16.74,60.48a2.07,2.07,0,0,0-2.23,2.13,2.58,2.58,0,0,0,.63,1.87,1.68,1.68,0,0,0,1.2.5H45.7a2.25,2.25,0,0,0,0-4.5H34V46c3.84-.36,6.85-1.61,8.95-3.71,3.47-3.59,3.61-9,3.51-11.29a2.12,2.12,0,0,0-2.14-2.1c-1.71,0-7.54.07-11.13,3.78a9.75,9.75,0,0,0-1.31,1.63,8.75,8.75,0,0,0-1.53-1.77c-3.6-3.48-9-3.61-11.29-3.5A2.12,2.12,0,0,0,17,31.13c0,1.71.06,7.54,3.8,11.15,2.88,2.76,7,3.35,9.05,3.47V60.48ZM36.23,35.77v0c1.18-1.24,3.11-2,5.75-2.34a9.8,9.8,0,0,1-2.17,5.73c-1.18,1.25-3.12,2-5.77,2.35A8.9,8.9,0,0,1,36.23,35.77ZM23.64,39.13l0,0c-1.24-1.18-2-3.1-2.34-5.74A9.8,9.8,0,0,1,27,35.53c1.24,1.18,2,3.11,2.34,5.75A9.14,9.14,0,0,1,23.64,39.13Z"
    />
    <path
      fill="#79b61d"
      d="M52.26,11.55,57,21.94a10.64,10.64,0,0,1,1,4.56V63.86L62.44,63V26.5A14.29,14.29,0,0,0,61,20.17l-4.52-9.92-6.68,1.3Z"
    />
    <path
      fill="#c8d200"
      d="M10.06,11.55H49.81l6.68-1.3-.6-1.31V2.27A2.29,2.29,0,0,0,53.72,0H8.61A2.09,2.09,0,0,0,6.45,2.17V9.05l-5,11.11c-.13.29-.24.59-.35.88l5.13-1Zm.71-7.22H51.56V7.11H10.77Z"
    />
  </svg>
);
