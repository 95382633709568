/* eslint-disable quotes, quote-props, import/prefer-default-export */
import useUser from 'customHooks/globalHooks/useUser';
import { behaviours, localeDependentBehaviours } from './Behaviours';

export const localeBehaviour = (locale, label) => {
  let labelValue;
  if (!label) {
    console.error(`label not defined`);
    return labelValue;
  }
  if (!locale) {
    console.error(`country not defined`);
    return labelValue;
  }
  const behaviourForLocale = localeDependentBehaviours[locale];
  if (!behaviourForLocale) {
    console.error(`locale ${locale} not found in behaviour`);
    return labelValue;
  }
  labelValue = behaviourForLocale[label];
  if (labelValue === undefined) {
    console.error(`label ${label} not found in behaviour of ${locale}`);
  }
  return labelValue;
};

export function countryBehaviour(country, label) {
  if (!label) return console.error(`label not defined`);
  if (!country) return console.error(`country not defined`);
  const behaviourCountry = behaviours[country.toUpperCase()];
  if (!behaviourCountry) return console.error(`country ${country} not found in behaviour`);

  const behaviourCountryLabel = behaviourCountry[label];
  if (behaviourCountryLabel === undefined) {
    return console.error(`label ${label} not found in behaviour of ${country}`);
  }
  return behaviourCountryLabel;
}

export const useCountryBehaviourFromContext = (label) => {
  const { country } = useUser();
  return countryBehaviour(country, label);
};

export const useLocaleBehaviourFromContext = (label) => {
  const { locale } = useUser();
  return localeBehaviour(locale, label);
};
