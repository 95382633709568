/* eslint-disable import/prefer-default-export */
/* eslint-disable react/prop-types */
import React from 'react';
import { PropTypes } from 'prop-types';
import { useLocalizedFromContext } from 'data/Localization';

export const Crop9 = ({ title, fill, ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="37" height="78" viewBox="0 0 37 78" {...props}>
    <title>{useLocalizedFromContext(title)}</title>
    <path
      d="M30.4.4c-.2-.2-.5-.4-.8-.4-.3 0-.6.1-.8.2-4.4 3.6-4.9 10.8-5.3 15.1 0 .7-.1 1.3-.1 1.8-.4 4.1-2 13.8-2.1 14.5-.7 3.3-2.3 6.2-3.4 8.1h-.6c-.5-2.3-1.2-5.8-1.8-9.6.8-.7 2.5-2.3 3.8-3.6 1.6-1.6 3.5-4.6.9-11.4-2.4-6.4-7.6-12.4-7.8-12.7-.2-.3-.6-.4-.9-.4-.3 0-.6.2-.8.5-.2.3-4.1 6.9-5.5 13-1-.8-1.7-1.3-1.8-1.3-.3-.2-.6-.2-1-.1-.3.1-.6.4-.7.7-.1.3-2 7.3-1.3 12.4.6 4.1 2.3 6.5 5.2 7.1 1.6.4 2.4.5 3.5.6 1.1 2 2.6 3.8 3.9 5.3-1.7.5-3.2 1.3-4.5 2.4l-.1.1c-2.8 2.4-4.7 6-4.7 10 0 2.2 1.2 5.3 2.8 8.7 0 .1 0 .1.1.2 3.5 7.3 9 15.4 9.3 15.9.2.3.5.5.9.5s.7-.2.9-.5c.5-.7 12.1-17.9 12.1-24.8 0-6-4.1-11.1-9.7-12.6 1.1-1.9 2.4-4.6 3.1-7.6 2.4-1.2 13.3-7.2 13.5-15.1.5-8.3-6.1-16.6-6.3-17zm-17 30.3c.2 1.1.4 2.1.5 3 .4 2.3.8 4.1 1 5.5-1.2-1.4-2.6-3.1-3.7-4.9.4-.7 1.4-2.2 2.1-3.4.1-.1.1-.2.1-.2zM6.9 18.1c.6-4.7 3.5-10.5 4.8-13 1.6 2 4.8 6.4 6.5 10.8 2.1 5.6.9 7.8-.4 9.1-.8.8-1.9 1.8-2.7 2.6-.5-3.7-1-7.4-1.1-10.4 0-.6-.5-1-1.1-1-.6 0-1.1.5-1 1.1.1 3.2.6 7.2 1.1 10.8-.9-.4-1.8-.7-3.2-1.5-1.6-.9-3.6-2.7-2.9-8.5zm-.7 14.3C5 32.1 3.3 31.2 2.6 27c-.4-3.3.4-7.7.9-10 .4.3.8.6 1.3 1-.6 5.2.7 8.6 4 10.4l2.8 1.4c-.4.7-.9 1.5-1.3 2.2-.1-.3-.2-.5-.3-.8-.2-.6-.8-.9-1.3-.7-.6.2-.9.8-.7 1.3l.3.9c-.7 0-1.3-.1-2.1-.3zm21.5 20.3c0 2.6-2.2 7.4-4.8 12.2-.6.3-3 1.3-7.2 1.3-.6 0-1.1.5-1.1 1.1 0 .6.5 1.1 1.1 1.1 2.4 0 4.2-.3 5.6-.6-1.6 2.8-3.3 5.4-4.5 7.3-.5-.8-1.1-1.6-1.7-2.6.5.1 1.1.1 1.7.1.6 0 1.1-.5 1.1-1.1s-.5-1.1-1.1-1.1c-1.2 0-2.6-.3-3.2-.5-1.3-2.2-2.7-4.6-3.9-6.9 1.4.3 3.3.7 5.7.7.6 0 1.1-.5 1.1-1.1 0-.6-.5-1.1-1.1-1.1-3.8 0-6.2-.9-7-1.2C7 57.2 6 54.5 6 52.7c0-3.1 1.3-5.9 3.4-7.9 1.2.6 3.7 1.7 7.4 1.7 1.4 0 3-.2 4.8-.6.6-.1.9-.7.8-1.3-.1-.6-.7-.9-1.3-.8-4.8 1.1-8.1.2-9.8-.5 1.1-.6 2.2-1.1 3.5-1.3.6.6 1 1 1.3 1.2.3.5.5.5.8.5h.7s.1 0 .1-.1l.1-.1.1-.1c.1-.1.5-.6 1-1.4 5.1 1.1 8.8 5.5 8.8 10.7zm7-35.3c-.2 4.8-5.4 9-9.2 11.4 3.7-6.7 4.6-13.1 4.6-13.5.1-.6-.3-1.1-.9-1.2-.6-.1-1.1.3-1.2.9 0 .1-.8 5.5-3.7 11.5.5-3.2 1-7 1.3-9.2.1-.5.1-1.1.1-1.8.3-3.9.7-9.6 3.6-12.8 1.7 2.3 5.6 8.6 5.4 14.7z"
      fill={fill || '#71bf44'}
    />
  </svg>
);

Crop9.defaultProps = {
  title: 'sugar_beet',
};

Crop9.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
};
