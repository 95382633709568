import shoopingListFooterBg from './png/print-footer-bg.png';
import shoopingListHeaderBg from './png/print-header-bg.png';
import printHeader from './png/print-header-background.jpg';
import farmdokENMockAppleMobile from './png/farmdokMockAppleMobileApplicationEN.png';
import farmdokFRMockAppleMobile from './png/farmdokMockAppleMobileApplicationFR.png';
import farmdokDEMockAppleMobile from './png/farmdokMockAppleMobileApplicationDE.png';
import footerTopBg from './png/footerTopBg.png';
import nutriguideMobileMock from './png/nutriguideMobileMock.png';
import nutriguideAndroidMobileMock from './png/androidMock.png';
import landingPageVideo1DE from './png/landingPageVideo1.png';
import landingPageVideo2DE from './png/landingPageVideo2.png';
import landingPageVideo1FR from './png/landingPageVideo1FR.png';
import landingPageVideo2FR from './png/landingPageVideo2FR.png';
import mobileSpashScreen from './png/mobileSpashScreen.png';
import supportedCropsImage from './png/supportedCropsImage.png';
import landingPageVideoTop from './png/landingPageVideoTop.png';
import landingPageVideoSecondTop from './png/landingPageVideoSecondTop.png';
import allCrops from './png/allCrops.png';
import newsLetterSubscriptionSuccess from './png/subscription-success-icon.png';
import topCarouselBgVideoThumbnail from './png/topCarouselBgVideoThumbnail.jpg';
import tabletDesktopMobile from './png/tabletDesktopMobile.png';
import vegetationGradient from './vegetation_gradient.png';
import plot_drawing_example from './png/plot_drawing_example.png';
import nutrizones_footer_bg from './png/nutrizones_footer_bg.png';
import nutrizones_testimonial_video from './png/nutrizones_testimonial_video.jpg';
import nutrizone_brand_video from './png/nutrizones_new_brand_video.jpg';
import istore from './png/istore.png';
import playstore from './png/playstore.png';
import tabletMobile from './png/tabletMobile.png';
import CC_NZ_1st_video_screen from './png/CC_NZ_1st_video_screen.png';
import CC_NZ_2nd_video_screen_screen from './png/CC_NZ_2nd_video_screen.png';

import shoopingListFooterBgWebP from './webp/print-footer-bg.webp';
import shoopingListHeaderBgWebP from './webp/print-header-bg.webp';
import printHeaderWebP from './webp/print-header-background.webp';
import farmdokENMockAppleMobileWebP from './webp/farmdokMockAppleMobileApplicationEN.webp';
import farmdokFRMockAppleMobileWebP from './webp/farmdokMockAppleMobileApplicationFR.webp';
import farmdokDEMockAppleMobileWebP from './webp/farmdokMockAppleMobileApplicationDE.webp';
import footerTopBgWebP from './webp/footerTopBg.webp';
import nutriguideMobileMockWebP from './webp/nutriguideMobileMock.webp';
import nutriguideAndroidMobileMockWebP from './webp/androidMock.webp';
import landingPageVideo1DEWebP from './webp/landingPageVideo1.webp';
import landingPageVideo2DEWebP from './webp/landingPageVideo2.webp';
import landingPageVideo1FRWebP from './webp/landingPageVideo1FR.webp';
import landingPageVideo2FRWebP from './webp/landingPageVideo2FR.webp';
import mobileSpashScreenWebP from './webp/mobileSpashScreen.webp';
import supportedCropsImageWebP from './webp/supportedCropsImage.webp';
import landingPageVideoTopWebP from './webp/landingPageVideoTop.webp';
import allCropsWebP from './webp/allCrops.webp';
import newsLetterSubscriptionSuccessWebP from './webp/subscription-success-icon.webp';
import topCarouselBgVideoThumbnailWebP from './webp/topCarouselBgVideoThumbnail.webp';
import tabletDesktopMobileWebP from './webp/tabletDesktopMobile.webp';

import farmdokDEMockAppleMobileAnimation from './farmdokDEMockAppleMobileAnimation.gif';
import farmdokHUMockAppleMobileAnimation from './CC_NG_LP_animation_HU.gif';
import farmdokROMockAppleMobileAnimation from './CC_NG_LP_animation_RO.gif';
import farmdokBGMockAppleMobileAnimation from './CC_NG_LP_animation_BG.gif';

import bannerImageHU from './CC_NG_Referral_HU.png';
import bannerImageRO from './CC_NG_Referral_RO.png';

import promotionImageDE from './promotion_image_de.jpg';
import promotionImageRO from './promotion_image_ro.jpg';

export {
  shoopingListFooterBg,
  shoopingListHeaderBg,
  farmdokENMockAppleMobile,
  farmdokFRMockAppleMobile,
  farmdokDEMockAppleMobile,
  footerTopBg,
  nutriguideMobileMock,
  nutriguideAndroidMobileMock,
  landingPageVideo1DE,
  landingPageVideo2DE,
  landingPageVideo1FR,
  landingPageVideo2FR,
  mobileSpashScreen,
  supportedCropsImage,
  landingPageVideoTop,
  landingPageVideoSecondTop,
  allCrops,
  newsLetterSubscriptionSuccess,
  topCarouselBgVideoThumbnail,
  farmdokDEMockAppleMobileAnimation,
  farmdokHUMockAppleMobileAnimation,
  farmdokROMockAppleMobileAnimation,
  farmdokBGMockAppleMobileAnimation,
  printHeader,
  tabletDesktopMobile,
  vegetationGradient,
  plot_drawing_example,
  nutrizones_footer_bg,
  nutrizones_testimonial_video,
  nutrizone_brand_video,
  istore,
  playstore,
  tabletMobile,
  CC_NZ_1st_video_screen,
  CC_NZ_2nd_video_screen_screen,
  shoopingListFooterBgWebP,
  shoopingListHeaderBgWebP,
  printHeaderWebP,
  farmdokENMockAppleMobileWebP,
  farmdokFRMockAppleMobileWebP,
  farmdokDEMockAppleMobileWebP,
  footerTopBgWebP,
  nutriguideMobileMockWebP,
  nutriguideAndroidMobileMockWebP,
  landingPageVideo1DEWebP,
  landingPageVideo2DEWebP,
  landingPageVideo1FRWebP,
  landingPageVideo2FRWebP,
  mobileSpashScreenWebP,
  supportedCropsImageWebP,
  landingPageVideoTopWebP,
  allCropsWebP,
  newsLetterSubscriptionSuccessWebP,
  topCarouselBgVideoThumbnailWebP,
  tabletDesktopMobileWebP,
  bannerImageHU,
  bannerImageRO,
  promotionImageDE,
  promotionImageRO,
};
