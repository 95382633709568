/* eslint-disable import/prefer-default-export */
/* eslint-disable react/prop-types */
import React from 'react';
import { PropTypes } from 'prop-types';
import { useLocalizedFromContext } from 'data/Localization';

export const GradientCrop39 = ({ title, ...props }) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 70 70"
    enableBackground="new 0 0 70 70"
    {...props}
  >
    <title>{useLocalizedFromContext(title)}</title>
    <g>
      <clipPath id="SVGID_2_">
        <path
          id="SVGID_63_"
          d="M49.1,9.7C49.1,9.7,49.1,9.7,49.1,9.7c-4.5,0-12.1,1.7-14.6,7.8c-0.4,0.6-0.6,1.3-0.6,2
            c-0.1,0.6-0.2,1.2-0.2,1.8c-0.2,7.5-4.6,29.6-16.5,34.7c-0.6,0.2-0.9,0.8-0.9,1.4c0,0.6,0.4,1.1,0.9,1.4c0.3,0.1,4.2,1.7,8.4,1.7
            c2.3,0,4.6-0.5,6.4-1.9c7.4-5.7,10.2-17.1,10.3-17.6c0.2-0.6,3.4-13.5,3.2-20.8c0,0,0,0,0,0c0,0,0,0,0-0.1c0-0.5,0-1-0.1-1.4
            c-0.4-3.2,1.1-5.5,3.9-6.1c0.8-0.1,1.3-0.8,1.2-1.6C50.5,10.3,49.9,9.7,49.1,9.7z M42.7,15.6c-0.6-0.4-1.3-0.7-2-0.8
            c0.9-0.5,1.8-0.9,2.8-1.2C43.1,14.2,42.9,14.9,42.7,15.6z M30.7,55.6c-0.4,0-0.8-0.4-0.8-0.8s0.4-0.8,0.8-0.8
            c0.4,0,0.8,0.4,0.8,0.8c0,0.2-0.1,0.4-0.2,0.5c0,0,0,0-0.1,0C31.1,55.5,30.9,55.6,30.7,55.6z M37.7,42.5c-0.1,0-0.2,0-0.3-0.1
            c0,0-0.1,0-0.1,0c-0.3-0.2-0.6-0.5-0.6-0.9c0-0.5,0.4-1,1-1c0.5,0,1,0.4,1,1S38.2,42.5,37.7,42.5z M39.2,37.5c-0.5,0-1-0.4-1-1
            s0.4-1,1-1c0.5,0,1,0.4,1,1S39.7,37.5,39.2,37.5z M40.4,32.2c-0.5,0-0.8-0.4-0.8-0.8s0.4-0.8,0.8-0.8s0.8,0.4,0.8,0.8
            S40.9,32.2,40.4,32.2z M41.2,26c0.4,0,0.8,0.3,0.8,0.8s-0.3,0.8-0.8,0.8s-0.8-0.3-0.8-0.8S40.8,26,41.2,26z M42.3,22.3
            c0,0.4-0.3,0.8-0.8,0.8s-0.8-0.3-0.8-0.8s0.3-0.8,0.8-0.8S42.3,21.9,42.3,22.3z M36.5,45.3c0.3,0.2,0.6,0.5,0.6,0.9
            c0,0.5-0.4,1-1,1c-0.5,0-1-0.4-1-1s0.4-1,1-1c0.1,0,0.2,0,0.3,0.1C36.5,45.3,36.5,45.3,36.5,45.3z M33.6,49.7c0.5,0,1,0.4,1,1
            s-0.4,1-1,1c-0.5,0-1-0.4-1-1S33.1,49.7,33.6,49.7z M21.6,57c11.7-8.2,14.9-30.1,15.1-35.6c0-0.9,0.2-1.7,0.5-2.5
            c0.5-0.7,1.6-1.2,2.6-1.2c0.6,0,1.3,0.3,1.9,0.8c0,0,0,0,0,0c-2.1,0-3.8,1.7-3.8,3.8c0,0.7,0.2,1.4,0.6,2c-0.6,0.7-1,1.5-1,2.5
            c0,0.6,0.2,1.2,0.4,1.7c-0.8,0.7-1.3,1.7-1.3,2.9c0,0.6,0.2,1.3,0.5,1.8c-1.1,0.7-1.8,1.9-1.8,3.3c0,0.6,0.1,1.1,0.3,1.6
            c-1.1,0.7-1.9,1.9-1.9,3.4c0,0.5,0.1,0.9,0.3,1.4c-1.1,0.7-1.8,1.9-1.8,3.3c0,0.3,0,0.6,0.1,0.8c-1.5,0.6-2.6,2-2.6,3.7
            c0,0.1,0,0.3,0,0.4c-1.6,0.4-2.8,1.9-2.8,3.7c0,1,0.4,1.8,0.9,2.5C25.9,57.7,23.5,57.4,21.6,57z"
        />
      </clipPath>
      <g clipPath="url(#SVGID_2_)">
        <rect
          x="4.5"
          y="49.2"
          transform="matrix(0.9736 -0.2284 0.2284 0.9736 -12.47 10.8225)"
          fill="#B95700"
          width="72.1"
          height="20.2"
        />
        <rect
          x="1"
          y="39.7"
          transform="matrix(0.9736 -0.2284 0.2284 0.9736 -9.2199 9.6522)"
          fill="#EB6909"
          width="72.1"
          height="9.9"
        />
        <rect
          x="-1.2"
          y="30.1"
          transform="matrix(0.9736 -0.2284 0.2284 0.9736 -7.0821 8.8823)"
          fill="#5F9501"
          width="72.1"
          height="9.9"
        />
        <rect
          x="-3.5"
          y="20.5"
          transform="matrix(0.9736 -0.2284 0.2284 0.9736 -4.9442 8.1125)"
          fill="#79B61D"
          width="72.1"
          height="9.9"
        />
        <rect
          x="-6.9"
          y="0.7"
          transform="matrix(0.9736 -0.2284 0.2284 0.9736 -1.6929 6.9416)"
          fill="#C8D200"
          width="72.1"
          height="20.2"
        />
      </g>
    </g>
  </svg>
);

GradientCrop39.defaultProps = {
  title: 'pea',
};

GradientCrop39.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
};
