/* eslint-disable import/prefer-default-export */
import {
  RESET_MY_CROP_ROTATIONS,
  DELETE_CROP_ROTATION_FOR_PLOT_ID,
  SET_CROP_ROTATIONS,
} from './types';

export const resetMyCropRotations = () => ({
  type: RESET_MY_CROP_ROTATIONS,
});

export const deleteCropRotationsForPlotId = (plotId) => ({
  type: DELETE_CROP_ROTATION_FOR_PLOT_ID,
  payload: plotId,
});

export const setCropRotations = (cropRotations) => ({
  type: SET_CROP_ROTATIONS,
  payload: cropRotations,
});
