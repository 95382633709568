/* eslint-disable import/prefer-default-export */
/* eslint-disable react/prop-types */
import React from 'react';
import { PropTypes } from 'prop-types';
import { useLocalizedFromContext } from 'data/Localization';

export const Crop30 = ({ title, fill, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="87.564"
    height="163.243"
    viewBox="0 0 87.564 163.243"
    {...props}
  >
    <title>{useLocalizedFromContext(title)}</title>
    <g id="Component_6_1" dataname="Component 6 – 1" transform="translate(2.589 2.5)">
      <g id="Group_282" dataname="Group 282" transform="translate(-2761.243 -3951.37)">
        <line
          id="Line_76"
          dataname="Line 76"
          y2="36.405"
          transform="translate(2777.221 4073.208)"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="5"
        />
        <g id="Group_265" dataname="Group 265">
          <path
            id="Path_978"
            dataname="Path 978"
            d="M2784.038,4037.756s3.621,2.466,6.783-4.549,2.184-15.344,2.184-15.344-6.568,4.072-9.48,10.532c-3.161,7.014.513,9.361.513,9.361"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
        <g id="Group_266" dataname="Group 266">
          <path
            id="Path_979"
            dataname="Path 979"
            d="M2784.038,4053.677s3.621,2.466,6.783-4.549,2.184-15.344,2.184-15.344-6.568,4.072-9.48,10.532c-3.161,7.014.513,9.361.513,9.361"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
        <g id="Group_267" dataname="Group 267">
          <path
            id="Path_980"
            dataname="Path 980"
            d="M2784.038,4022.109s3.621,2.466,6.783-4.548,2.184-15.344,2.184-15.344-6.568,4.072-9.48,10.531c-3.161,7.015.513,9.361.513,9.361"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
        <g id="Group_268" dataname="Group 268">
          <path
            id="Path_981"
            dataname="Path 981"
            d="M2784.038,4006.79s3.621,2.465,6.783-4.549S2793,3986.9,2793,3986.9s-6.568,4.072-9.48,10.531c-3.161,7.015.513,9.362.513,9.362"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
        <g id="Group_269" dataname="Group 269">
          <path
            id="Path_982"
            dataname="Path 982"
            d="M2784.038,3990.542s3.621,2.466,6.783-4.549,2.184-15.344,2.184-15.344-6.568,4.072-9.48,10.532c-3.161,7.014.513,9.361.513,9.361"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
        <g id="Group_270" dataname="Group 270">
          <path
            id="Path_983"
            dataname="Path 983"
            d="M2777.383,3981.8s5.026-.035,4.863-8.023-5.326-14.63-5.326-14.63-4.874,7.48-4.724,14.835c.163,7.988,5.187,7.818,5.187,7.818"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
        <g id="Group_271" dataname="Group 271">
          <path
            id="Path_984"
            dataname="Path 984"
            d="M2777.383,4004.454s5.026-.035,4.863-8.023-5.326-14.63-5.326-14.63-4.874,7.48-4.724,14.836c.163,7.988,5.187,7.817,5.187,7.817"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
        <g id="Group_272" dataname="Group 272">
          <path
            id="Path_985"
            dataname="Path 985"
            d="M2777.383,4027.108s5.026-.035,4.863-8.023-5.326-14.63-5.326-14.63-4.874,7.479-4.724,14.835c.163,7.988,5.187,7.818,5.187,7.818"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
        <g id="Group_273" dataname="Group 273">
          <path
            id="Path_986"
            dataname="Path 986"
            d="M2777.383,4049.761s5.026-.035,4.863-8.023-5.326-14.63-5.326-14.63-4.874,7.48-4.724,14.835c.163,7.988,5.187,7.818,5.187,7.818"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
        <g id="Group_274" dataname="Group 274">
          <path
            id="Path_987"
            dataname="Path 987"
            d="M2777.383,4072.414s5.026-.034,4.863-8.023-5.326-14.63-5.326-14.63-4.874,7.48-4.724,14.836c.163,7.988,5.187,7.817,5.187,7.817"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
        <rect
          id="Rectangle_117"
          dataname="Rectangle 117"
          width="1.445"
          height="1.445"
          transform="translate(2781.74 4007.923)"
          strokeWidth="5"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="none"
        />
        <g id="Group_275" dataname="Group 275">
          <path
            id="Path_988"
            dataname="Path 988"
            d="M2770.352,4037.756s-3.62,2.466-6.782-4.549-2.184-15.344-2.184-15.344,6.567,4.072,9.479,10.532c3.162,7.014-.513,9.361-.513,9.361"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
        <g id="Group_276" dataname="Group 276">
          <path
            id="Path_989"
            dataname="Path 989"
            d="M2770.352,4053.677s-3.62,2.466-6.782-4.549-2.184-15.344-2.184-15.344,6.567,4.072,9.479,10.532c3.162,7.014-.513,9.361-.513,9.361"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
        <g id="Group_277" dataname="Group 277">
          <path
            id="Path_990"
            dataname="Path 990"
            d="M2784.038,4069.191s3.621,2.465,6.783-4.549S2793,4049.3,2793,4049.3s-6.568,4.072-9.48,10.531c-3.161,7.015.513,9.362.513,9.362"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
        <g id="Group_278" dataname="Group 278">
          <path
            id="Path_991"
            dataname="Path 991"
            d="M2770.352,4069.191s-3.62,2.465-6.782-4.549-2.184-15.344-2.184-15.344,6.567,4.072,9.479,10.531c3.162,7.015-.513,9.362-.513,9.362"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
        <g id="Group_279" dataname="Group 279">
          <path
            id="Path_992"
            dataname="Path 992"
            d="M2770.352,4022.109s-3.62,2.466-6.782-4.548-2.184-15.344-2.184-15.344,6.567,4.072,9.479,10.531c3.162,7.015-.513,9.361-.513,9.361"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
        <g id="Group_280" dataname="Group 280">
          <path
            id="Path_993"
            dataname="Path 993"
            d="M2770.352,4006.79s-3.62,2.465-6.782-4.549-2.184-15.344-2.184-15.344,6.567,4.072,9.479,10.531c3.162,7.015-.513,9.362-.513,9.362"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
        <g id="Group_281" dataname="Group 281">
          <path
            id="Path_994"
            dataname="Path 994"
            d="M2770.352,3990.542s-3.62,2.466-6.782-4.549-2.184-15.344-2.184-15.344,6.567,4.072,9.479,10.532c3.162,7.014-.513,9.361-.513,9.361"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
        <rect
          id="Rectangle_118"
          dataname="Rectangle 118"
          width="1.445"
          height="1.445"
          transform="translate(2772.65 4009.368) rotate(180)"
          strokeWidth="5"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="none"
        />
      </g>
      <g id="Group_294" dataname="Group 294" transform="translate(-2761.243 -3951.37)">
        <g id="Group_293" dataname="Group 293">
          <circle
            id="Ellipse_43"
            dataname="Ellipse 43"
            cx="12.285"
            cy="12.285"
            r="12.285"
            transform="translate(2809.873 3960.964) rotate(-9.217)"
            strokeWidth="5"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            fill="none"
          />
          <line
            id="Line_98"
            dataname="Line 98"
            y1="7.467"
            transform="translate(2823.966 3951.37)"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
          <line
            id="Line_99"
            dataname="Line 99"
            y1="7.467"
            transform="translate(2823.966 3983.407)"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
          <line
            id="Line_100"
            dataname="Line 100"
            x2="7.467"
            transform="translate(2836.251 3971.122)"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
          <line
            id="Line_101"
            dataname="Line 101"
            x2="7.467"
            transform="translate(2804.214 3971.122)"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
          <line
            id="Line_102"
            dataname="Line 102"
            x1="5.28"
            y1="5.28"
            transform="translate(2810 3957.156)"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
          <line
            id="Line_103"
            dataname="Line 103"
            x1="5.28"
            y1="5.28"
            transform="translate(2832.653 3979.809)"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
          <line
            id="Line_104"
            dataname="Line 104"
            y1="5.28"
            x2="5.28"
            transform="translate(2832.653 3957.156)"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
          <line
            id="Line_105"
            dataname="Line 105"
            y1="5.28"
            x2="5.28"
            transform="translate(2810 3979.809)"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
      </g>
    </g>
  </svg>
);

Crop30.defaultProps = {
  title: 'spring_triticale',
};

Crop30.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
};
