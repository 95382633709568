/* eslint-disable import/prefer-default-export */
/* eslint-disable react/prop-types */
import React from 'react';
import { PropTypes } from 'prop-types';
import { useLocalizedFromContext } from 'data/Localization';

export const Crop50 = ({ title, fill, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="106.72"
    height="155.082"
    viewBox="0 0 106.72 155.082"
    {...props}
  >
    <title>{useLocalizedFromContext(title)}</title>
    <g id="Group_71" data-name="Group 71" transform="translate(-741.929 -5202.186)">
      <path
        id="Path_327"
        data-name="Path 327"
        d="M801.22,5293.134s-12.226,7.932-9.255,30.4c2.72,20.565,21.978,21.275,32.176,21.5a30.979,30.979,0,0,1,15.577,4.67h0a2.471,2.471,0,0,0,3.686-2.738h0a25.918,25.918,0,0,1,.36-14.587,49.625,49.625,0,0,0,2.385-15.027c0-12.382-7.429-22.1-27.05-31.05,0,0-1.143,12.382-8.381,21.716C810.718,5308.018,798.172,5303.8,801.22,5293.134Z"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_328"
        data-name="Path 328"
        d="M810.994,5274.715c5.4-1.136,11.706-1.293,20.078,4.594,5.145,3.618,8.887,4.039,8.887,4.039s-.2,5.9-9.719,8.937"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_329"
        data-name="Path 329"
        d="M792.648,5307.083s-2.867,1.974-10.481-2.3c0,0,4.258-8.159,6.6-13.632a25.376,25.376,0,0,1,5.606-8.072"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_330"
        data-name="Path 330"
        d="M826.918,5302.9s14.608,7.6,7.183,25.209c-1.9,4.5-5.967,7.737-10.779,6.905q-.233-.041-.471-.089"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_331"
        data-name="Path 331"
        d="M796.651,5286.3s-2.995-3.866-3.168-10.884-7.8-34.213-18.878-46.355a115.632,115.632,0,0,0-5.644,17.36c-2.018,9.11-3.677,35.335-2.577,60.66.409,9.394,1.917,47.587,1.917,47.587"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_332"
        data-name="Path 332"
        d="M814.118,5278.853s-3.728-5.485-9.053-7.748c-5.884-2.5-14.185-48.178-31.971-54.461"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_333"
        data-name="Path 333"
        d="M756.16,5354.67s-.958-27.158,0-36.743-9.026-6.151-11.731-22.76c0,0,6.459-1.2,11.794,2.548,0,0-1.216-28.353,0-38.9s10.161-53.677,10.161-53.677"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <line
        id="Line_31"
        data-name="Line 31"
        y1="13.689"
        x2="2.623"
        transform="translate(777.287 5205.141)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
    </g>
  </svg>
);

Crop50.defaultProps = {
  title: 'chickpea',
};

Crop50.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
};
