import { combineReducers } from 'redux';
import UserReducer from './UserReducer';
import PlanReducer from './PlanReducer';
import CropListReducer from './CropListReducer';
import OrganicFertilizerListReducer from './OrganicFertilizerListReducer';
import SoilCompositionReducer from './SoilCompositionReducer';
import SoilListReducer from './SoilListReducer';
import MyPlansReducer from './MyPlansReducer';
import UIReducer from './UIReducer';
import CityListReducer from './CityListReducer';
import TermsReducer from './TermsReducer';
import OrganizatonReducer from './OrganisationReducer';
import ProductSelectReducer from './ProductSelectReducer';
import ProductListReducer from './ProductListReducer';
import ProductReducer from './ProductReducer';
import ApplicationStepsReducer from './ApplicationStepsReducer';
import MyPlotsReducer from './MyPlotsReducer';
import MyCropRotationsReducer from './MyCropRotationsReducer';
import CropRotationReducer from './CropRotationReducer';
import RefererReducer from './RefererReducer';
import LastUsedReducer from './LastUsedReducer';
import TerrazoFieldsReducer from './TerrazoFieldsReducer';

export default combineReducers({
  user: UserReducer,
  plan: PlanReducer,
  cropList: CropListReducer,
  organicFertilizerList: OrganicFertilizerListReducer,
  soilList: SoilListReducer,
  soilComposition: SoilCompositionReducer,
  myPlans: MyPlansReducer,
  UI: UIReducer,
  cityList: CityListReducer,
  applicationSteps: ApplicationStepsReducer,
  terms: TermsReducer,
  organisation: OrganizatonReducer,
  productSelectList: ProductSelectReducer,
  productList: ProductListReducer,
  product: ProductReducer,
  myPlots: MyPlotsReducer,
  myCropRotations: MyCropRotationsReducer,
  currentCropRotation: CropRotationReducer,
  referers: RefererReducer,
  lastUsed: LastUsedReducer,
  terrazoFields: TerrazoFieldsReducer,
});
