/* eslint-disable import/prefer-default-export */
/* eslint-disable react/prop-types */
import React from 'react';
import { PropTypes } from 'prop-types';
import { useLocalizedFromContext } from 'data/Localization';

export const Crop5 = ({ title, fill, ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="53" height="78" viewBox="0 0 37.43 55" {...props}>
    <title>{useLocalizedFromContext(title)}</title>

    <path
      d="M11.87,27.67v-.6c0-4,.06-8,0-12a27.81,27.81,0,0,1,1.37-8.73,15.5,15.5,0,0,1,2.12-4.62A6.91,6.91,0,0,1,16.44.61,2.39,2.39,0,0,1,18.11,0a4.09,4.09,0,0,0,.7,0A3.37,3.37,0,0,1,22,1.63a16.59,16.59,0,0,1,2.33,5.14,28.22,28.22,0,0,1,1.25,8.52c-.08,3.92,0,7.85,0,11.78v.58l.81-.54A10,10,0,0,1,32,25.31,6.66,6.66,0,0,1,37,27.63a1.13,1.13,0,0,1-.56,2,11.88,11.88,0,0,0-5.59,3.94,19.48,19.48,0,0,0-4.64,11.14c-.09,1,.08,2.07.09,3.11a2.22,2.22,0,0,1-.22,1.09A8.45,8.45,0,0,1,22,52.39c-.27.11-.4.24-.37.54a9.55,9.55,0,0,1,0,1.07,1,1,0,0,1-1,1H16.82a1,1,0,0,1-1.05-1c0-.48.14-1-.07-1.41s-.79-.43-1.19-.66a9,9,0,0,1-3-2.85,2.1,2.1,0,0,1-.39-1.56,17,17,0,0,0-1.38-8.72A17.18,17.18,0,0,0,3.66,31,13.39,13.39,0,0,0,.92,29.61a1.15,1.15,0,0,1-.52-2,7,7,0,0,1,7.48-2A12.46,12.46,0,0,1,11.87,27.67ZM23.42,16.38a30,30,0,0,0-.28-5.7A26.86,26.86,0,0,0,20.69,3.5a2.06,2.06,0,0,0-2.21-1.3,1.66,1.66,0,0,0-1.36.71,5.74,5.74,0,0,0-.55.92c-.48,1.12-1,2.24-1.37,3.39A18.11,18.11,0,0,0,14,12.94c0,5.43,0,10.85,0,16.28a1,1,0,0,0,.23.62,19.51,19.51,0,0,1,3.05,4.43.86.86,0,0,0,1.09.59,2.88,2.88,0,0,1,1,0,.52.52,0,0,0,.61-.34,21.83,21.83,0,0,1,3.18-4.68,1.19,1.19,0,0,0,.26-.69c0-2,0-3.91,0-5.86v-.48h-.67c-.22,0-.44,0-.65,0a1.1,1.1,0,1,1,0-2.2h1.24v-2H22.25A1.11,1.11,0,0,1,21,17.51a1.14,1.14,0,0,1,1.21-1.13ZM18.44,37c.07.33.12.56.18.78a35.89,35.89,0,0,1,1.16,8.5,1.09,1.09,0,0,1-1.05,1.27c-.65,0-1-.47-1.09-1.23-.13-1.7-.23-3.41-.48-5.09A17.65,17.65,0,0,0,14.67,34a16.14,16.14,0,0,0-5.28-5.34A6.79,6.79,0,0,0,5,27.55,13.29,13.29,0,0,0,3.24,28,17,17,0,0,1,7,30.89a21.15,21.15,0,0,1,6.27,16.52,1.1,1.1,0,0,0,.17.64,6.49,6.49,0,0,0,10,.76A1.85,1.85,0,0,0,24.12,47a1.84,1.84,0,0,1,0-.41,20.72,20.72,0,0,1,2.47-10.38,18.74,18.74,0,0,1,6.5-7.38c.36-.23.73-.43,1.15-.67a4.88,4.88,0,0,0-2.31-.62A7.93,7.93,0,0,0,27,29.26a17.74,17.74,0,0,0-5.56,7.09c-.38.81-.66.95-1.53.81C19.48,37.08,19,37.06,18.44,37ZM18.29,18.6h-.88a1.07,1.07,0,0,1-1.06-1,1,1,0,0,1,.86-1.15,12.1,12.1,0,0,1,2.17,0,1,1,0,0,1,.82,1.21,1.06,1.06,0,0,1-1,1Z"
      fill={fill || '#71bf44'}
    />
  </svg>
);

Crop5.defaultProps = {
  title: 'corn',
};

Crop5.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
};
