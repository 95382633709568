/* eslint-disable import/prefer-default-export */
import { GTMEventTypes } from 'constants/index.js';

const { PAGE_INITIALIZED, VIRTUAL_PAGE_VIEW } = GTMEventTypes;

window.dataLayer = window.dataLayer || [];
let dataLayer = [];

if (localStorage && localStorage['persist:root']) {
  const persistRoot = JSON.parse(localStorage['persist:root']);
  if (persistRoot) {
    dataLayer = { user: JSON.parse(persistRoot.user) };
  }
}

window.dataLayer.push({
  event: PAGE_INITIALIZED,
  site: 'Nutriguide',
  ...dataLayer,
});

export const pushDataLayer = (analyticsMeta, data) => {
  const action = {
    event: analyticsMeta.type,
    ...{ [analyticsMeta.dataLayerPath]: data },
  };

  window.dataLayer.push(action);
};

export const createVirtualPageView = (url) => {
  window.dataLayer.push({
    event: VIRTUAL_PAGE_VIEW,
    virtualPageURL: `/virtual-page-view/${url}`,
  });
  window.hj('stateChange', `/virtual-page-view/${url}`);
};
