/* eslint-disable import/prefer-default-export */
/* eslint-disable react/prop-types */
import React from 'react';
import { PropTypes } from 'prop-types';
import { useLocalizedFromContext } from 'data/Localization';

export const GradientCrop41 = ({ title, ...props }) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 70 70"
    enableBackground="new 0 0 70 70"
    {...props}
  >
    <title>{useLocalizedFromContext(title)}</title>
    <g>
      <g>
        <clipPath id="SVGID_2_">
          <path
            id="SVGID_1_"
            d="M30.4,37.7c0-2.7,1.6-5.2,4.1-6.2c0.8-0.3,1.6,0,2,0.8c0.3,0.8,0,1.6-0.8,2c-1.4,0.6-2.3,2-2.3,3.5
            c0,1.1,0.5,2.1,1.3,2.8c0.6,0.6,0.7,1.5,0.1,2.1c-0.3,0.3-0.7,0.5-1.1,0.5c-0.4,0-0.7-0.1-1-0.4C31.3,41.5,30.4,39.7,30.4,37.7z
            M37.5,53.7c-2.5-0.2-4.4-2.5-4.1-5c0.1-0.8-0.5-1.6-1.4-1.6c-0.8-0.1-1.6,0.5-1.6,1.4c-0.2,2,0.4,4,1.7,5.6s3.1,2.5,5.1,2.7h0.1
            c0.8,0,1.4-0.6,1.5-1.4C38.9,54.5,38.3,53.8,37.5,53.7z M51.5,41c0.1,0.7-0.3,1.4-1,1.7c-0.2,0.1-0.3,0.1-0.5,0.1
            c-0.5,0-1.1-0.3-1.3-0.8c0,0-0.3-0.5-0.7-1.3c0,0.8,0,1.5,0,2.3c-0.1,16.4-6.5,22-6.8,22.2s-0.6,0.3-1,0.3c-0.1,0-0.2,0-0.3,0
            c-0.4-0.1-0.8-0.4-1-0.8c-1.1-2.1-2.1-2.5-4.1-3.4c-0.7-0.3-1.5-0.6-2.4-1.1c-4-2.1-7-6.5-6.8-10.4c0.1-1.8,0.4-2.9,0.8-3.8
            c0.3-0.9,0.5-1.5,0.5-2.9c0-0.7-0.2-1.2-0.4-1.8c-0.3-0.9-0.6-1.9-0.6-3.8c-1.8,2.3-2.8,4.3-2.9,4.4c-0.3,0.5-0.8,0.8-1.3,0.8
            c-0.2,0-0.3,0-0.5-0.1c-0.7-0.2-1.1-1-1-1.7c0.1-0.4,2-11,6.7-15.4c3.4-3.2,3.4-5.3,3.4-5.4l0,0l0,0c0,0,0,0,0-0.1s0-0.2,0-0.3
            c0.1-2.5,2-4.6,4.4-5.1c0.3-1.3,0.4-4-3.7-6.4c-0.7-0.4-1-1.3-0.6-2s1.3-1,2-0.6c5,2.8,5.7,6.6,5.2,9.2c2.1,0.8,3.6,2.8,3.6,5.2
            l0,0l0,0c0,0,0,2.2,3.4,5.4C49.5,29.9,51.4,40.5,51.5,41z M25.6,33.2c0.4-0.4,0.7-0.8,1.1-1.2c0,0,0,0,0.1-0.1
            c1-0.9,2-1.8,3.1-2.5c4.8-3.2,4.5-5.9,4.5-5.9c-0.2-0.8,0.3-1.6,1.1-1.8c0.2-0.1,0.5,0,0.7,0c0,0,0,0,0.1,0
            c0.8,0.2,1.3,1,1.1,1.8c0,0.1-0.3,2.7,4.5,5.9c1.6,1.1,3,2.4,4.3,3.8c-0.9-2.1-2-4.1-3.4-5.4c-4.1-3.8-4.4-6.9-4.4-7.6
            c0-1.4-1.1-2.5-2.5-2.5l0,0l0,0c-1.4,0-2.5,1.1-2.5,2.5c0,0.8-0.2,3.8-4.4,7.6C27.5,29.1,26.4,31.1,25.6,33.2z M44.3,35.7
            c-1.2-1.4-2.6-2.8-4.2-3.8c-2.1-1.4-3.5-2.8-4.3-4c-0.9,1.2-2.2,2.6-4.3,4c-0.8,0.6-1.6,1.2-2.4,1.9c-0.1,0.4-0.2,0.7-0.2,1.1
            c-0.3,3.3,0,4.5,0.4,5.5c0.2,0.7,0.5,1.5,0.5,2.7c0,1.9-0.3,3-0.7,3.9c-0.3,0.8-0.5,1.6-0.6,2.9c-0.1,2.6,2.2,6,5.2,7.6
            c0.8,0.4,1.5,0.7,2.2,1c1.6,0.7,3.1,1.3,4.4,2.9C42.1,59.3,45,53.7,45,43C45,40.2,44.8,37.8,44.3,35.7z"
          />
        </clipPath>
        <g clipPath="url(#SVGID_2_)">
          <rect
            x="4.5"
            y="49.2"
            transform="matrix(0.9736 -0.2284 0.2284 0.9736 -12.4708 10.8294)"
            fill="#B95700"
            width="72.1"
            height="20.2"
          />
          <rect
            x="1"
            y="39.7"
            transform="matrix(0.9736 -0.2284 0.2284 0.9736 -9.221 9.6424)"
            fill="#EB6909"
            width="72.1"
            height="9.9"
          />
          <rect
            x="-1.2"
            y="30.1"
            transform="matrix(0.9736 -0.2284 0.2284 0.9736 -7.0833 8.8866)"
            fill="#5F9501"
            width="72.1"
            height="9.9"
          />
          <rect
            x="-3.5"
            y="20.5"
            transform="matrix(0.9736 -0.2284 0.2284 0.9736 -4.9534 8.1091)"
            fill="#79B61D"
            width="72.1"
            height="9.9"
          />
          <rect
            x="-6.9"
            y="0.7"
            transform="matrix(0.9736 -0.2284 0.2284 0.9736 -1.6958 6.944)"
            fill="#C8D200"
            width="72.1"
            height="20.2"
          />
        </g>
      </g>
    </g>
  </svg>
);

GradientCrop41.defaultProps = {
  title: 'lentil',
};

GradientCrop41.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
};
