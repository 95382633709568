/* eslint-disable import/prefer-default-export */
/* eslint-disable react/prop-types */
import React from 'react';

export const Field = ({ isActive, isPast, ...props }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 71 71"
      enableBackground="new 0 0 71 71"
      {...props}
    >
      <g>
        <path
          fill="#839CA9"
          d="M45.1,17.9c-0.1-0.4-0.4-0.8-0.7-1l-10.9-6c-0.7-0.4-1.5-0.2-1.9,0.4l-21,28.3c-0.2,0.3-0.3,0.8-0.3,1.2
          c0.1,0.4,0.3,0.8,0.7,1l11.9,7.4c0.2,0.2,0.5,0.2,0.8,0.2c0.5,0,1-0.2,1.2-0.7l20-29.8C45.1,18.7,45.2,18.3,45.1,17.9z M23.3,46
          l-9.3-5.8l19.3-26l8.3,4.6L23.3,46z"
        />
        <path
          fill="#839CA9"
          d="M60.7,26.4c-0.1-0.4-0.4-0.7-0.7-0.9L49.7,20c-0.7-0.4-1.5-0.2-2,0.5L37.1,36.3c-0.4,0.6-0.3,1.4,0.2,1.9
          L47,48.2c0.3,0.3,0.7,0.5,1.1,0.5c0.1,0,0.1,0,0.2,0c0.5-0.1,0.9-0.3,1.1-0.8l11.2-20.4C60.8,27.2,60.8,26.7,60.7,26.4z M47.7,44.7
          l-7.5-7.7l9.2-13.7l7.8,4.1L47.7,44.7z"
        />
        <path
          fill="#839CA9"
          d="M36,41.3c-0.3-0.3-0.8-0.5-1.2-0.5c-0.5,0-0.9,0.3-1.1,0.7l-5.9,8.8c-0.2,0.3-0.3,0.7-0.2,1.1
          c0.1,0.4,0.3,0.7,0.7,1l12.5,8c0.2,0.2,0.5,0.2,0.8,0.2c0.1,0,0.2,0,0.4,0c0.4-0.1,0.7-0.4,0.9-0.7l3.5-6.3
          c0.3-0.6,0.2-1.3-0.2-1.8L36,41.3z M41,57l-9.9-6.3l4-6l8,8.4L41,57z"
        />
      </g>
    </svg>
  );
};
