/* eslint-disable import/prefer-default-export */
/* eslint-disable react/prop-types */
import React from 'react';

export const AtomicBottle = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 75 90.8" {...props}>
    <title>Atomic Bottle</title>
    <path
      d="M50.4,81.1a96.8,96.8,0,0,1-9.5-6.6,77,77,0,0,1-8.1,5.8,24,24,0,0,0,10,2.2A21.4,21.4,0,0,0,50.4,81.1Z"
      fill="none"
    />
    <path
      d="M64.8,42.5c1-1.6,1.9-3.3,2.8-4.8,3.2-6.4,4.1-11.6,2.3-13.5a4.3,4.3,0,0,0-3.1-.9h0c-2.9,0-6.9,1.5-11.5,3.8v7.4A27.5,27.5,0,0,1,64.8,42.5Z"
      fill="none"
    />
    <path
      d="M11.3,25.4h-.5a6.9,6.9,0,0,0-3.9,1.2A6.5,6.5,0,0,0,10,38.4h.7a6.4,6.4,0,0,0,3.4-.9,6.5,6.5,0,0,0-2.8-12.1Z"
      fill="none"
    />
    <path
      d="M30.3,34.5V29.3c-4.8-2.9-9.2-5-12.7-5.7a10.5,10.5,0,0,1,3.8,8.2,10.7,10.7,0,0,1-5.3,9.3h0a27.9,27.9,0,0,0,2.8,4.2A29.6,29.6,0,0,1,30.3,34.5Z"
      fill="none"
    />
    <path
      d="M16.4,88.3h0a4.5,4.5,0,0,0,3.3-1.7,3.6,3.6,0,0,0,.6-2,3.8,3.8,0,0,0-.7-2.2,4,4,0,0,0-3.2-1.6A4.1,4.1,0,0,0,13,82.9a3.3,3.3,0,0,0-.4,1.7,3.7,3.7,0,0,0,1.2,2.6A3.6,3.6,0,0,0,16.4,88.3Z"
      fill="none"
    />
    <path
      d="M69.9,82.2h0c1.6-1.6,1.1-6.1-1.8-12.4a28.5,28.5,0,0,1-9,11.3,20.4,20.4,0,0,0,7.7,2A4.3,4.3,0,0,0,69.9,82.2Z"
      fill="none"
    />
    <path
      d="M22.2,56.4,20,53.7a24.2,24.2,0,0,0-.6,5.4v1.3h0A23.6,23.6,0,0,0,22,69.9a21.1,21.1,0,0,0,6.9,7.9,76.8,76.8,0,0,0,8.8-6L33,67.7l-3.3-3.2C27,61.8,24.5,59.2,22.2,56.4Z"
      fill="none"
    />
    <path
      d="M57.1,53.3c.8-1.1,1.7-2.1,2.5-3.3s1.9-2.6,2.9-3.8a23.4,23.4,0,0,0-9.9-8.5l-1.2-.5V29H48.2a1.9,1.9,0,0,1-1.7-1,1.7,1.7,0,0,1-.3-1,2,2,0,0,1,2-2h3.2v-2H48.2a2,2,0,0,1-2-2,2.1,2.1,0,0,1,2-2.1h3.2V15.6H34.3V37.3l-1.2.5h0A21.4,21.4,0,0,0,21.8,49.2l.7.9c.8,1,1.7,2.2,2.6,3.2,2.3,2.7,4.9,5.6,7.6,8.3s3.6,3.5,5.4,5.1l3,2.6,4.5-4c1.3-1.2,2.6-2.4,3.8-3.7C52.2,58.9,54.7,56.1,57.1,53.3Z"
      fill="none"
    />
    <path
      d="M12.9,71.6c-1.9,4.3-2.4,7.7-1.7,9.6a6.2,6.2,0,0,1,5.1-2.7,6.1,6.1,0,0,1,5.4,3.1h0A31.1,31.1,0,0,0,25,80.1a27.5,27.5,0,0,1-7.2-9.4A22.1,22.1,0,0,1,16,65.6,58.1,58.1,0,0,0,12.9,71.6Z"
      fill="none"
    />
    <path
      d="M66.3,58.9a22.4,22.4,0,0,0-1.7-8.6l-2.3,3.1-2.7,3.1c-2.1,2.5-4.3,4.9-6.7,7.3l-.7.8c-2.7,2.5-5.4,5.1-8.1,7.3a89.5,89.5,0,0,0,10.8,7.3h0a24.4,24.4,0,0,0,10.6-14,17.6,17.6,0,0,0,.7-4,12.7,12.7,0,0,0,.1-2Z"
      fill="none"
    />
    <polygon
      points="28.3 4.1 28.3 11.5 53 11.5 57.2 11.5 57.2 10.7 57.2 4.1 28.3 4.1"
      fill="none"
    />
    <path
      d="M10.5,86.3a6.1,6.1,0,0,0,11.9-.5,32.6,32.6,0,0,0,6.3-3A27.7,27.7,0,0,0,55,83.9c4.6,2.3,8.7,3.5,11.8,3.5a8.3,8.3,0,0,0,6-2.1c3.9-4,2-11.9-3.1-20.7a33.9,33.9,0,0,0,.6-4.1l-4.1.7a17.6,17.6,0,0,1-.7,4,24.4,24.4,0,0,1-10.6,14h0a89.5,89.5,0,0,1-10.8-7.3c2.7-2.2,5.4-4.8,8.1-7.3l.7-.8-7.3,1.5-4.5,4-3-2.6-5.1,1,4.7,4.1a76.8,76.8,0,0,1-8.8,6A21.1,21.1,0,0,1,22,69.9l-4.2.8A27.5,27.5,0,0,0,25,80.1a31.1,31.1,0,0,1-3.3,1.5h0a6.1,6.1,0,0,0-5.4-3.1,6.2,6.2,0,0,0-5.1,2.7c-.7-1.9-.2-5.3,1.7-9.6l-4.6.9c-1.9,5.4-2,9.9.8,12.7ZM68.1,69.8c2.9,6.3,3.4,10.8,1.8,12.4h0a4.3,4.3,0,0,1-3.1.9,20.4,20.4,0,0,1-7.7-2A28.5,28.5,0,0,0,68.1,69.8ZM40.9,74.5a96.8,96.8,0,0,0,9.5,6.6,21.4,21.4,0,0,1-7.6,1.4,24,24,0,0,1-10-2.2A77,77,0,0,0,40.9,74.5ZM13,82.9a4.1,4.1,0,0,1,3.4-2.1,4,4,0,0,1,3.2,1.6,3.8,3.8,0,0,1,.7,2.2,3.6,3.6,0,0,1-.6,2,4.5,4.5,0,0,1-3.3,1.7h0a3.6,3.6,0,0,1-2.6-1.1,3.7,3.7,0,0,1-1.2-2.6A3.3,3.3,0,0,1,13,82.9Z"
      fill="#5f9501"
    />
    <path
      d="M16,65.6a22.1,22.1,0,0,0,1.8,5.1l4.2-.8a23.6,23.6,0,0,1-2.6-9.5h0V59.1a24.2,24.2,0,0,1,.6-5.4l2.2,2.7c2.3,2.8,4.8,5.4,7.5,8.1L33,67.7l5.1-1q-2.7-2.4-5.4-5.1c-2.7-2.7-5.3-5.6-7.6-8.3-.9-1-1.8-2.2-2.6-3.2l-.7-.9A21.4,21.4,0,0,1,33.1,37.8h0l1.2-.5V15.6H51.4v3.2H48.2a2.1,2.1,0,0,0-2,2.1,2,2,0,0,0,2,2h3.2v2H48.2a2,2,0,0,0-2,2,1.7,1.7,0,0,0,.3,1,1.9,1.9,0,0,0,1.7,1h3.2v8.2l1.2.5a23.4,23.4,0,0,1,9.9,8.5c-1,1.2-1.9,2.5-2.9,3.8s-1.7,2.2-2.5,3.3c-2.4,2.8-4.9,5.6-7.7,8.3-1.2,1.3-2.5,2.5-3.8,3.7l7.3-1.5c2.4-2.4,4.6-4.8,6.7-7.3l2.7-3.1,2.3-3.1a22.4,22.4,0,0,1,1.7,8.6v.3a12.7,12.7,0,0,1-.1,2l4.1-.7V59.1h0a27.4,27.4,0,0,0-3.1-12.7,62.9,62.9,0,0,0,4-6.8c4.4-8.5,5-14.8,1.7-18.2a8.4,8.4,0,0,0-6-2.1c-3.1,0-7,1.2-11.5,3.3v-7h1.9a4,4,0,0,0,4-4.1V9.9l-4.1.8v.8H53L30.2,16v8.7c-5.9-3.5-11.4-5.4-15.3-5.4a7.9,7.9,0,0,0-5.7,2,10.7,10.7,0,0,0,1.5,21.3h1.4a82.6,82.6,0,0,0,4.8,7.3,26.9,26.9,0,0,0-1.6,9.4v.2a56.3,56.3,0,0,0-7,13.1l4.6-.9A58.1,58.1,0,0,1,16,65.6ZM55.3,27.1c4.6-2.3,8.6-3.8,11.5-3.8h0a4.3,4.3,0,0,1,3.1.9c1.8,1.9.9,7.1-2.3,13.5-.9,1.5-1.8,3.2-2.8,4.8a27.5,27.5,0,0,0-9.5-8ZM14.1,37.5a6.4,6.4,0,0,1-3.4.9H10A6.5,6.5,0,0,1,6.9,26.6a6.9,6.9,0,0,1,3.9-1.2h.5a6.5,6.5,0,0,1,2.8,12.1Zm2,3.6h0a10.7,10.7,0,0,0,5.3-9.3,10.5,10.5,0,0,0-3.8-8.2c3.5.7,7.9,2.8,12.7,5.7v5.2A29.6,29.6,0,0,0,18.9,45.3,27.9,27.9,0,0,1,16.1,41.1Z"
      fill="#79b61d"
    />
    <path
      d="M28.3,11.5V4.1H57.2v6.6l4.1-.8V4.1a4,4,0,0,0-4-4.1h-29a4,4,0,0,0-4,4.1v7.4a4,4,0,0,0,4,4.1h1.9V16L53,11.5Z"
      fill="#c8d200"
    />
  </svg>
);
