/* eslint-disable react/prop-types */
import React from 'react';

const Pencil = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 23.79167 23.67526"
    height="15"
    width="15"
    {...props}
  >
    <title>Pencil icon flat</title>
    <path
      d="M0,20.37043a3.3086,3.3086,0,0,0,3.30483,3.30483H19.29288a3.3086,3.3086,0,0,0,3.30483-3.30483V15.31112a1.02564,1.02564,0,0,0-2.05127,0v5.05931A1.255,1.255,0,0,1,19.29288,21.624H3.30483a1.255,1.255,0,0,1-1.25355-1.25355V5.522A1.255,1.255,0,0,1,3.30483,4.26848H8.36414a1.02564,1.02564,0,0,0,0-2.05128H3.30483A3.3086,3.3086,0,0,0,0,5.522Z"
      fill="#839ca9"
    />
    <path
      d="M20.103,0a3.00033,3.00033,0,0,0-2.13544.8845L7.80191,11.05017a1.02582,1.02582,0,0,0-.26319.45139l-1.33691,4.826a1.02559,1.02559,0,0,0,1.26227,1.26222l4.826-1.33692a1.02539,1.02539,0,0,0,.45139-.26319L22.90717,5.824a3.02,3.02,0,0,0,0-4.27081L22.23851.88444h0A3.00082,3.00082,0,0,0,20.103,0Zm0,2.05128a.96251.96251,0,0,1,.685.2837l.66872.66871a.96873.96873,0,0,1,0,1.36992l-.282.282L19.13613,2.617l.282-.28205A.96184.96184,0,0,1,20.103,2.05128ZM10.01023,11.74282l7.6753-7.67531L19.7241,6.10608l-7.67524,7.67531ZM9.06,13.69358l1.03806,1.03806-1.43578.39772Z"
      fill="#839ca9"
    />
  </svg>
);

export default Pencil;
