/* eslint-disable import/prefer-default-export */
/* eslint-disable react/prop-types */
import React from 'react';
import { PropTypes } from 'prop-types';

export const GradientCrop6 = (props) => (
  <svg x={0} y={0} viewBox="0 0 66 78" xmlSpace="preserve" {...props}>
    <title>Potato</title>
    <style>{'.prefix__st2{fill:none}.prefix__st3{fill:#5f9501}.prefix__st4{fill:#79b61d}'}</style>
    <path
      className="prefix__st2"
      d="M23.47 36.52c-1.32-2.15-2.23-4.44-2.79-7.04-.24.02-.49.06-.72.1-.06.01-.13.02-.19.04-.24.05-.47.1-.7.16-.02.01-.05.01-.07.02-.17.05-.34.11-.51.17.99 2.27 2.55 4.75 4.98 6.55z"
    />
    <path
      className="prefix__st2"
      d="M61.43 14.15c-.54-1.22-1.16-2.3-1.83-3.26-3.04-4.38-7.06-6.15-8.88-6.76-2.14-.71-4.72-1.11-7.43-1.11-5.19 0-10.87 1.45-14.9 4.9-3.01 2.57-4.87 5.97-5.61 10.13-.51 2.87-.48 6.11.1 9.72.75 4.43 2.49 7.89 5.64 11.19a1.252 1.252 0 0 1-.9 2.11c-.1 0-.21-.01-.31-.04-6.07-1.54-9.33-6.03-11.07-9.98-2.32 1.48-4.38 3.87-6.84 6.73-.49.56-.98 1.14-1.5 1.73-7.74 8.84-5.54 17.54-3.54 21.95 1.05 2.31 2.48 4.42 4.16 6.28 4.15 4.59 9.84 7.55 15.27 7.55 4.7 0 19.44-1.29 29.14-16.19 1.2-1.85 2.34-3.9 3.35-6.19 7.41-16.67 9.14-29.7 5.15-38.76zm-3.07 31.17c-1.93 1.17-4.15 1.56-6.25 1.56-3.96 0-7.48-1.38-7.7-1.47-.64-.26-.95-.98-.69-1.62.26-.64.98-.95 1.62-.69.11.04 7.25 2.82 11.74.09a1.24 1.24 0 0 1 1.71.42c.34.58.15 1.35-.43 1.71zm2.17-28.27c-.1.11-2.32 2.62-6.36 2.62-.27 0-.55-.01-.83-.03a1.243 1.243 0 1 1 .2-2.48c3.32.28 5.08-1.71 5.1-1.73a1.25 1.25 0 0 1 1.76-.13c.53.45.58 1.23.13 1.75zM10.91 48.57c.67-.15 1.34.28 1.48.95.01.05.48 2.1 1.56 3.73.38.57.23 1.35-.35 1.73a1.247 1.247 0 0 1-1.73-.34c-1.36-2.04-1.9-4.47-1.92-4.58-.14-.68.29-1.34.96-1.49zm16.1 16.73c.67.17 1.07.84.91 1.51a1.238 1.238 0 0 1-1.51.9c-2.2-.55-4.07-1.4-5.66-2.36-4.48-2.7-6.62-6.27-6.74-6.47-.35-.59-.15-1.36.45-1.7.59-.35 1.35-.15 1.7.45.04.08 2.83 4.66 8.62 6.95.7.27 1.43.52 2.23.72z"
    />
    <path
      className="prefix__st3"
      d="M26.4 67.72c.1.03.2.04.3.04a1.243 1.243 0 0 0 .3-2.45c-.79-.2-1.53-.45-2.23-.73l-4.04.79c1.6.95 3.48 1.8 5.67 2.35z"
    />
    <path
      className="prefix__st3"
      d="M23.79 77.78c5.25 0 22.17-1.49 32.46-19.33l-3.32.65C43.24 74 28.5 75.29 23.79 75.29c-5.43 0-11.12-2.96-15.27-7.55l-2.8.55c4.67 5.72 11.54 9.49 18.07 9.49z"
    />
    <path
      className="prefix__st4"
      d="M58.65 15.42c-.02.02-1.78 2.02-5.1 1.73-.68-.05-1.29.45-1.34 1.14-.06.69.45 1.29 1.14 1.34.29.02.56.03.83.03 4.04 0 6.26-2.51 6.36-2.62.45-.52.39-1.31-.13-1.76a1.27 1.27 0 0 0-1.76.14zM4.37 61.46c-2-4.4-4.19-13.1 3.54-21.95.51-.59 1.01-1.16 1.5-1.73 2.46-2.86 4.52-5.24 6.84-6.73 1.74 3.95 5 8.44 11.07 9.98a1.234 1.234 0 0 0 1.38-.58c.28-.48.21-1.09-.17-1.49-3.15-3.31-4.89-6.76-5.64-11.19-.58-3.6-.61-6.84-.1-9.72l-2.58.5c-.34 2.45-.38 5.24.05 8.45-.32.04-.63.09-.95.15-.02 0-.04.01-.07.01-.3.06-.61.13-.9.22-.01 0-.03.01-.04.01-.67.19-1.33.44-1.98.76-.05.02-.09.05-.13.08-3.25 1.63-5.83 4.62-8.67 7.92-.48.56-.97 1.13-1.48 1.71-6.32 7.23-7.76 16.2-3.94 24.62a26.74 26.74 0 0 0 3.62 5.8l2.8-.55c-1.67-1.85-3.1-3.96-4.15-6.27zM19 29.79c.02-.01.05-.01.07-.02.23-.06.46-.12.7-.16.06-.01.13-.02.19-.04.24-.04.48-.07.72-.1.57 2.6 1.47 4.89 2.79 7.04-2.43-1.8-3.99-4.28-4.99-6.56.18-.05.34-.11.52-.16zM57.06 43.19c-4.48 2.73-11.63-.05-11.74-.09a1.25 1.25 0 0 0-1.62.69c-.26.64.06 1.36.69 1.62.22.09 3.74 1.47 7.7 1.47 2.1 0 4.33-.39 6.25-1.56.59-.36.77-1.12.42-1.71-.35-.59-1.11-.78-1.7-.42z"
    />
    <path
      className="prefix__st4"
      d="M11.88 54.63c.24.36.63.55 1.04.55a1.254 1.254 0 0 0 1.04-1.94c-1.09-1.63-1.55-3.68-1.56-3.73a1.244 1.244 0 0 0-2.43.53c.01.12.55 2.55 1.91 4.59zM16.15 57.63c-.35-.59-1.11-.79-1.7-.45-.59.35-.79 1.11-.45 1.7.12.2 2.26 3.77 6.74 6.47l4.04-.79c-5.8-2.27-8.58-6.85-8.63-6.93zM61.43 14.15c3.99 9.05 2.26 22.09-5.14 38.75a44.928 44.928 0 0 1-3.35 6.19l3.32-.65a49.1 49.1 0 0 0 2.31-4.53c7.7-17.33 9.43-31.04 5.14-40.77-.44-1-.94-1.92-1.46-2.77l-2.65.53c.67.95 1.29 2.03 1.83 3.25z"
    />
    <path
      d="M28.4 7.93c4.04-3.45 9.71-4.9 14.9-4.9 2.71 0 5.29.4 7.43 1.11 1.82.61 5.83 2.37 8.88 6.76l2.64-.51c-3.51-5.66-8.53-7.87-10.73-8.6C44.46-.56 33.7.14 26.78 6.05c-2.77 2.37-5.69 6.29-6.57 12.53l2.58-.5c.73-4.18 2.6-7.58 5.61-10.15z"
      fill="#c8d200"
    />
  </svg>
);

GradientCrop6.propTypes = {
  className: PropTypes.string,
};
