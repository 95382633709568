/* eslint-disable import/prefer-default-export */
/* eslint-disable react/prop-types */
import React from 'react';
import { PropTypes } from 'prop-types';
import { useLocalizedFromContext } from 'data/Localization';

export const Crop28 = ({ title, fill, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="129.545"
    height="156.417"
    viewBox="0 0 129.545 156.417"
    {...props}
  >
    <title>{useLocalizedFromContext(title)}</title>
    <g id="Component_11_1" dataname="Component 11 – 1" transform="translate(2.5 2.5)">
      <line
        id="Line_99"
        dataname="Line 99"
        y1="101.67"
        transform="translate(62.272 49.746)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <g id="Group_262" dataname="Group 262" transform="translate(-1388.204 -4581.203)">
        <g id="Group_258" dataname="Group 258">
          <path
            id="Path_792"
            dataname="Path 792"
            d="M1484.69,4629.987c-5.656-2.773-9.477-20.936,3.257-20.936"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
          <path
            id="Path_793"
            dataname="Path 793"
            d="M1491.2,4629.987c5.656-2.773,9.477-20.936-3.257-20.936"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
        <g id="Group_259" dataname="Group 259">
          <path
            id="Path_794"
            dataname="Path 794"
            d="M1491.813,4630.426c2.774-5.656,20.936-9.477,20.936,3.257"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
          <path
            id="Path_795"
            dataname="Path 795"
            d="M1491.813,4636.94c2.774,5.656,20.936,9.477,20.936-3.257"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
        <g id="Group_260" dataname="Group 260">
          <path
            id="Path_796"
            dataname="Path 796"
            d="M1491.2,4637.379c5.656,2.774,9.477,20.936-3.257,20.936"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
          <path
            id="Path_797"
            dataname="Path 797"
            d="M1484.69,4637.379c-5.656,2.774-9.477,20.936,3.257,20.936"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
        <g id="Group_261" dataname="Group 261">
          <path
            id="Path_798"
            dataname="Path 798"
            d="M1484.081,4636.94c-2.774,5.656-20.936,9.477-20.936-3.257"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
          <path
            id="Path_799"
            dataname="Path 799"
            d="M1484.081,4630.426c-2.774-5.656-20.936-9.477-20.936,3.257"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
        <circle
          id="Ellipse_92"
          dataname="Ellipse 92"
          cx="5.047"
          cy="5.047"
          r="5.047"
          transform="translate(1483.54 4628.373)"
          strokeWidth="5"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="#fff"
        />
      </g>
      <g id="Group_267" dataname="Group 267" transform="translate(-1388.204 -4581.203)">
        <g id="Group_263" dataname="Group 263">
          <path
            id="Path_800"
            dataname="Path 800"
            d="M1409.749,4629.987c-5.656-2.773-9.477-20.936,3.257-20.936"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
          <path
            id="Path_801"
            dataname="Path 801"
            d="M1416.263,4629.987c5.656-2.773,9.477-20.936-3.257-20.936"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
        <g id="Group_264" dataname="Group 264">
          <path
            id="Path_802"
            dataname="Path 802"
            d="M1416.872,4630.426c2.774-5.656,20.936-9.477,20.936,3.257"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
          <path
            id="Path_803"
            dataname="Path 803"
            d="M1416.872,4636.94c2.774,5.656,20.936,9.477,20.936-3.257"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
        <g id="Group_265" dataname="Group 265">
          <path
            id="Path_804"
            dataname="Path 804"
            d="M1416.263,4637.379c5.656,2.774,9.477,20.936-3.257,20.936"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
          <path
            id="Path_805"
            dataname="Path 805"
            d="M1409.749,4637.379c-5.656,2.774-9.477,20.936,3.257,20.936"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
        <g id="Group_266" dataname="Group 266">
          <path
            id="Path_806"
            dataname="Path 806"
            d="M1409.14,4636.94c-2.774,5.656-20.936,9.477-20.936-3.257"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
          <path
            id="Path_807"
            dataname="Path 807"
            d="M1409.14,4630.426c-2.774-5.656-20.936-9.477-20.936,3.257"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
        <circle
          id="Ellipse_93"
          dataname="Ellipse 93"
          cx="5.047"
          cy="5.047"
          r="5.047"
          transform="translate(1408.599 4628.373)"
          strokeWidth="5"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="#fff"
        />
      </g>
      <g id="Group_272" dataname="Group 272" transform="translate(-1388.204 -4581.203)">
        <g id="Group_268" dataname="Group 268">
          <path
            id="Path_808"
            dataname="Path 808"
            d="M1447.22,4602.139c-5.656-2.773-9.477-20.936,3.256-20.936"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
          <path
            id="Path_809"
            dataname="Path 809"
            d="M1453.733,4602.139c5.656-2.773,9.477-20.936-3.257-20.936"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
        <g id="Group_269" dataname="Group 269">
          <path
            id="Path_810"
            dataname="Path 810"
            d="M1454.343,4602.579c2.773-5.656,20.936-9.477,20.936,3.256"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
          <path
            id="Path_811"
            dataname="Path 811"
            d="M1454.343,4609.092c2.773,5.656,20.936,9.477,20.936-3.257"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
        <g id="Group_270" dataname="Group 270">
          <path
            id="Path_812"
            dataname="Path 812"
            d="M1453.733,4609.532c5.656,2.773,9.477,20.936-3.257,20.936"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
          <path
            id="Path_813"
            dataname="Path 813"
            d="M1447.22,4609.532c-5.656,2.773-9.477,20.936,3.256,20.936"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
        <g id="Group_271" dataname="Group 271">
          <path
            id="Path_814"
            dataname="Path 814"
            d="M1446.61,4609.092c-2.773,5.656-20.936,9.477-20.936-3.257"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
          <path
            id="Path_815"
            dataname="Path 815"
            d="M1446.61,4602.579c-2.773-5.656-20.936-9.477-20.936,3.256"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
        <circle
          id="Ellipse_94"
          dataname="Ellipse 94"
          cx="5.047"
          cy="5.047"
          r="5.047"
          transform="translate(1446.069 4600.525)"
          strokeWidth="5"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="#fff"
        />
      </g>
      <line
        id="Line_100"
        dataname="Line 100"
        x2="31.785"
        y2="24.582"
        transform="translate(30.488 57.836)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <line
        id="Line_101"
        dataname="Line 101"
        x1="31.785"
        y2="24.582"
        transform="translate(62.272 57.836)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
    </g>
  </svg>
);

Crop28.defaultProps = {
  title: 'mustard',
};

Crop28.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
};
