import {
  SET_CROP_ROTATION,
  SET_CURRENT_CROP_ROTATION_PLAN,
  UPDATE_CROP_ROTATIONS_PLAN,
  UPDATE_CROP_ROTATIONS_PLAN_NG4,
  UPDATE_CROP_ROTATION,
  RESET_CROP_ROTATION,
  SET_CROP_ROTATION_WAS_EDITED,
} from 'Redux/actions/types';
import CropRotationService from 'services/CropRotationService';

export const initialCropRotationState = {
  cropRotation: null,
  presentedPlan: undefined,
  wasEdited: false,
};

export default (state = initialCropRotationState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_CROP_ROTATION:
      return {
        ...state,
        cropRotation: payload,
      };
    case SET_CURRENT_CROP_ROTATION_PLAN:
      return {
        ...state,
        presentedPlan: payload,
      };
    case UPDATE_CROP_ROTATIONS_PLAN:
      return {
        ...state,
        cropRotation: {
          ...state.cropRotation,
          plans: new CropRotationService().getUpdatedPlans(payload, state.cropRotation.plans),
        },
      };
    case UPDATE_CROP_ROTATIONS_PLAN_NG4:
      return {
        ...state,
        cropRotation: {
          ...state.cropRotation,
          genericPlans: new CropRotationService().getUpdatedPlans(
            payload,
            state.cropRotation.genericPlans,
          ),
        },
      };
    case UPDATE_CROP_ROTATION:
      return {
        ...state,
        cropRotation: {
          ...state.cropRotation,
          [payload.key]: payload.value,
        },
      };
    case RESET_CROP_ROTATION:
      return {
        ...initialCropRotationState,
      };
    case SET_CROP_ROTATION_WAS_EDITED:
      return {
        ...state,
        wasEdited: payload,
      };
    default:
      return state;
  }
};
