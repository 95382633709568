import _ from 'lodash';
import {
  STATUS_DEFAULT,
  MY_PLOTS_FAILED,
  MY_PLOTS_SUCCESS,
  MY_PLOTS_LOADING,
  DELETE_MY_PLOT_LOADING,
  DELETE_MY_PLOT_SUCCESS,
  DELETE_MY_PLOT_FAILED,
  DELETE_MY_PLOT_BULK_LOADING,
  DELETE_MY_PLOT_BULK_SUCCESS,
  POST_MY_PLOT_LOADING,
  POST_MY_PLOT_SUCCESS,
  POST_MY_PLOT_FAILED,
  POST_PLOT_LOADING,
  POST_PLOT_SUCCESS,
  POST_PLOT_FAILED,
  MERGE_PLOT,
  RESET_MY_PLOTS,
  STATUS_GET_FAILED,
  SET_MY_PLOTS_STATUS,
  REMOVE_TEMP_PLOTS,
} from 'Redux/actions/types';
import { temporaryPlotId } from 'constants/index.js';

export const initialMyPlotsState = {
  status: STATUS_DEFAULT,
  errorCode: null,
  data: [],
};

const getTransformedPlotListToFrontEnd = (plotlist) => {
  const plotListFrontTend = plotlist.map((plot) => {
    const {
      farm: { farmerName },
    } = plot;
    return {
      ...plot,
      farm: {
        ...plot.farm,
        farmerName: farmerName === null ? '' : farmerName,
      },
    };
  });
  return plotListFrontTend;
};

const getWithNoTemporaryPlots = (plotlist) => {
  const filteredPlots = plotlist.filter(({ id }) => id !== temporaryPlotId);
  return filteredPlots;
};

export default (state = initialMyPlotsState, action) => {
  const { type, payload } = action;
  switch (type) {
    case STATUS_DEFAULT:
      return {
        status: STATUS_DEFAULT,
        data: [...state.data],
      };
    case MY_PLOTS_FAILED:
      return {
        status: STATUS_GET_FAILED,
        errorCode: payload.errorCode,
        data: state.data ? [...state.data] : [],
      };
    case MY_PLOTS_LOADING:
      return {
        ...state,
        status: MY_PLOTS_LOADING,
        errorCode: null,
      };
    case MY_PLOTS_SUCCESS:
      return {
        status: MY_PLOTS_SUCCESS,
        errorCode: null,
        data: getTransformedPlotListToFrontEnd(payload),
      };
    case DELETE_MY_PLOT_LOADING:
      return {
        status: DELETE_MY_PLOT_LOADING,
        errorCode: null,
        data: [...state.data],
      };
    case DELETE_MY_PLOT_SUCCESS:
      return {
        status: DELETE_MY_PLOT_SUCCESS,
        errorCode: null,
        data: state.data.filter((plot) => plot.id !== payload),
      };
    case DELETE_MY_PLOT_FAILED:
      return {
        status: DELETE_MY_PLOT_FAILED,
        errorCode: payload.errorCode,
        data: [...state.data],
      };
    case DELETE_MY_PLOT_BULK_LOADING:
      return {
        ...state,
        status: DELETE_MY_PLOT_BULK_LOADING,
      };
    case DELETE_MY_PLOT_BULK_SUCCESS:
      return {
        ...state,
        status: DELETE_MY_PLOT_BULK_SUCCESS,
        data: state.data.filter((plot) => !payload.includes(plot.id)),
      };
    case POST_MY_PLOT_LOADING:
      return {
        ...state,
        status: POST_MY_PLOT_LOADING,
        errorCode: null,
      };
    case POST_MY_PLOT_SUCCESS:
      return {
        status: POST_MY_PLOT_SUCCESS,
        data: [...state.data, ...getTransformedPlotListToFrontEnd(payload)],
        errorCode: null,
      };
    case POST_MY_PLOT_FAILED:
      return {
        status: POST_MY_PLOT_FAILED,
        errorCode: payload.errorCode,
        data: [...state.data],
      };
    case POST_PLOT_LOADING:
      return {
        status: POST_PLOT_LOADING,
        data: [...state.data],
        errorCode: null,
      };
    case POST_PLOT_SUCCESS:
      return {
        status: POST_PLOT_SUCCESS,
        errorCode: null,
        data: _.unionBy([action.payload], state.data, 'id'),
      };
    case POST_PLOT_FAILED:
      return {
        status: POST_PLOT_FAILED,
        data: [...state.data],
        errorCode: payload.errorCode,
      };
    case MERGE_PLOT:
      return {
        ...state,
        data: _.unionBy(getTransformedPlotListToFrontEnd([action.payload]), state.data, 'id'),
      };
    case RESET_MY_PLOTS:
      return initialMyPlotsState;
    case REMOVE_TEMP_PLOTS:
      return {
        ...state,
        data: getWithNoTemporaryPlots(state.data),
      };
    case SET_MY_PLOTS_STATUS:
      return {
        status: payload,
        ...state,
      };
    default:
      return state;
  }
};
