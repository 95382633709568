/* eslint-disable react/prop-types */
import React from 'react';

const GradientWelcome = ({ onlyField, ...props }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 170 170"
      enableBackground="new 0 0 170 170"
      xmlSpace="preserve"
      {...props}
    >
      <g>
        <defs>
          <path
            id="SVGID_9_GradientWelcome"
            d="M69.9,34c0,8.2,6.7,14.9,14.9,14.9c8.2,0,14.9-6.7,14.9-14.9c0-8.2-6.7-14.9-14.9-14.9
            C76.5,19.1,69.9,25.8,69.9,34z M96.6,34c0,6.5-5.3,11.8-11.8,11.8c-6.5,0-11.8-5.3-11.8-11.8c0-6.5,5.3-11.8,11.8-11.8
            C91.3,22.2,96.6,27.5,96.6,34z M83.2,9.9V2.4c0-0.8,0.7-1.5,1.5-1.5s1.5,0.7,1.5,1.5v7.6c0,0.8-0.7,1.5-1.5,1.5
            S83.2,10.8,83.2,9.9z M96.6,13l4.1-6.3c0.5-0.7,1.4-0.9,2.1-0.4c0.7,0.5,0.9,1.4,0.4,2.1l-4.1,6.3c-0.3,0.4-0.8,0.7-1.3,0.7
            c-0.3,0-0.6-0.1-0.8-0.2C96.3,14.6,96.1,13.7,96.6,13z M104.9,23.9c-0.4-0.7-0.1-1.7,0.7-2l6.8-3.4c0.7-0.4,1.7-0.1,2,0.7
            c0.4,0.7,0.1,1.7-0.7,2l-6.8,3.4c-0.2,0.1-0.4,0.2-0.7,0.2C105.7,24.7,105.2,24.4,104.9,23.9z M108.9,33.5l7.6,0.3
            c0.8,0,1.5,0.7,1.4,1.6c0,0.8-0.7,1.5-1.5,1.5c0,0,0,0-0.1,0l-7.6-0.3c-0.8,0-1.5-0.7-1.4-1.6C107.3,34.1,108,33.5,108.9,33.5z
            M103.7,46.3c0.5-0.7,1.4-0.9,2.1-0.4l6.3,4.1c0.7,0.5,0.9,1.4,0.4,2.1c-0.3,0.4-0.8,0.7-1.3,0.7c-0.3,0-0.6-0.1-0.8-0.2l-6.3-4.1
            C103.4,47.9,103.2,47,103.7,46.3z M97,54.7l3.4,6.8c0.4,0.7,0.1,1.7-0.7,2c-0.2,0.1-0.4,0.2-0.7,0.2c-0.6,0-1.1-0.3-1.4-0.8
            l-3.4-6.8c-0.4-0.7-0.1-1.7,0.7-2C95.8,53.7,96.7,54,97,54.7z M67.2,60.3l3.9-6.5c0.4-0.7,1.4-1,2.1-0.5c0.7,0.4,1,1.4,0.5,2.1
            l-3.9,6.5c-0.3,0.5-0.8,0.7-1.3,0.7c-0.3,0-0.5-0.1-0.8-0.2C67,62,66.7,61.1,67.2,60.3z M65,44.8c0.4,0.7,0.1,1.7-0.6,2.1
            l-6.6,3.6c-0.2,0.1-0.5,0.2-0.7,0.2c-0.5,0-1.1-0.3-1.3-0.8c-0.4-0.7-0.1-1.7,0.6-2.1l6.6-3.6C63.6,43.8,64.6,44.1,65,44.8z
            M51.6,33.7c0-0.8,0.7-1.5,1.5-1.5c0,0,0,0,0,0l7.6,0.1c0.8,0,1.5,0.7,1.5,1.5c0,0.8-0.7,1.5-1.5,1.5c0,0,0,0,0,0l-7.6-0.1
            C52.3,35.2,51.6,34.6,51.6,33.7z M56.3,17c0.4-0.7,1.4-1,2.1-0.5l6.5,3.9c0.7,0.4,1,1.4,0.5,2.1c-0.3,0.5-0.8,0.7-1.3,0.7
            c-0.3,0-0.5-0.1-0.8-0.2L56.8,19C56.1,18.6,55.9,17.7,56.3,17z M68.3,7c-0.4-0.7-0.1-1.7,0.6-2.1c0.7-0.4,1.7-0.1,2.1,0.6l3.6,6.6
            c0.4,0.7,0.1,1.7-0.6,2.1c-0.2,0.1-0.5,0.2-0.7,0.2c-0.5,0-1.1-0.3-1.3-0.8L68.3,7z M84.5,56.5c0.8,0,1.5,0.7,1.5,1.5v7.6
            c0,0.8-0.7,1.5-1.5,1.5S83,66.5,83,65.6v-7.6C83,57.2,83.7,56.5,84.5,56.5z M169.9,132.3c-0.2-0.8-1-1.3-1.8-1.1
            c-1.3,0.3-2.6,0.6-3.9,1c-0.6,0.2-1.1,0.3-1.7,0.5c-0.7,0.2-1.4,0.4-2.1,0.6c-0.6,0.2-1.2,0.4-1.8,0.5c-0.7,0.2-1.4,0.4-2,0.6
            c-0.6,0.2-1.2,0.4-1.8,0.5c-0.7,0.2-1.4,0.4-2.1,0.6c-0.6,0.2-1.1,0.4-1.7,0.6c-0.7,0.2-1.4,0.5-2.1,0.7c-0.6,0.2-1.1,0.4-1.7,0.6
            c-0.7,0.2-1.4,0.5-2.1,0.7c-0.6,0.2-1.1,0.4-1.7,0.6c-0.7,0.3-1.4,0.5-2.1,0.8c-0.6,0.2-1.1,0.4-1.7,0.6c-0.5,0.2-1.1,0.4-1.6,0.6
            c-1.5-1.1-3-2.1-4.5-3.2c-0.2-0.1-0.4-0.3-0.6-0.4c-1.4-1-2.9-1.9-4.3-2.9c-0.6-0.4-1.1-0.7-1.7-1.1c-1.1-0.7-2.2-1.4-3.4-2.1
            c-0.6-0.4-1.3-0.8-1.9-1.1c-0.5-0.3-1.1-0.7-1.6-1c0.6-0.2,1.2-0.5,1.8-0.7c0.9-0.3,1.8-0.7,2.8-1c1.1-0.4,2.3-0.8,3.4-1.2
            c0.9-0.3,1.8-0.6,2.8-0.9c1.1-0.4,2.3-0.7,3.5-1.1c0.9-0.3,1.8-0.6,2.8-0.9c1.2-0.4,2.4-0.7,3.5-1c0.9-0.3,1.8-0.5,2.7-0.8
            c1.2-0.3,2.5-0.6,3.7-1c0.9-0.2,1.8-0.5,2.6-0.7c1.3-0.3,2.6-0.6,3.9-0.9c0.8-0.2,1.6-0.4,2.5-0.6c1.5-0.3,2.9-0.6,4.4-0.9
            c0.7-0.1,1.4-0.3,2-0.4c2.1-0.4,4.3-0.8,6.4-1.1c0.8-0.1,1.4-0.9,1.3-1.7c-0.1-0.8-0.9-1.4-1.7-1.3c-2.2,0.3-4.4,0.7-6.7,1.1
            c-0.7,0.1-1.3,0.3-2,0.4c-1.6,0.3-3.1,0.6-4.6,0.9c-0.8,0.2-1.6,0.4-2.4,0.6c-1.4,0.3-2.8,0.6-4.2,1c-0.9,0.2-1.7,0.5-2.6,0.7
            c-1.3,0.3-2.6,0.7-3.9,1c-0.9,0.2-1.8,0.5-2.7,0.8c-1.3,0.4-2.5,0.7-3.8,1.1c-0.9,0.3-1.8,0.6-2.8,0.9c-1.2,0.4-2.4,0.8-3.7,1.2
            c-0.9,0.3-1.9,0.7-2.8,1c-1.2,0.4-2.4,0.8-3.5,1.3c-1,0.4-1.9,0.7-2.9,1.1c-1,0.4-2.1,0.8-3.1,1.2c-1.7-1-3.4-1.9-5.2-2.9
            c-0.3-0.2-0.6-0.3-1-0.5c-1.6-0.8-3.1-1.6-4.7-2.4c-0.6-0.3-1.3-0.6-1.9-0.9c-1.3-0.6-2.5-1.2-3.8-1.8c-0.7-0.3-1.4-0.7-2.2-1
            c-0.6-0.3-1.2-0.6-1.9-0.9c0.3-0.1,0.5-0.2,0.8-0.4c0.7-0.3,1.3-0.6,2-0.9c0.8-0.4,1.6-0.7,2.4-1.1c0.7-0.3,1.4-0.6,2-0.9
            c0.8-0.3,1.6-0.7,2.4-1c0.7-0.3,1.4-0.6,2.1-0.8c0.8-0.3,1.6-0.6,2.4-1c0.7-0.3,1.4-0.5,2.1-0.8c0.8-0.3,1.6-0.6,2.5-0.9
            c0.7-0.3,1.4-0.5,2.1-0.8c0.8-0.3,1.6-0.6,2.5-0.9c0.7-0.2,1.4-0.5,2.1-0.7c0.8-0.3,1.7-0.5,2.5-0.8c0.7-0.2,1.4-0.4,2.1-0.7
            c0.8-0.3,1.7-0.5,2.5-0.8c0.7-0.2,1.4-0.4,2.1-0.6c0.9-0.2,1.7-0.5,2.6-0.7c0.7-0.2,1.4-0.4,2.1-0.6c0.9-0.2,1.8-0.5,2.6-0.7
            c0.7-0.2,1.4-0.3,2.1-0.5c0.9-0.2,1.8-0.4,2.7-0.6c0.7-0.2,1.3-0.3,2-0.5c0.9-0.2,1.8-0.4,2.8-0.6c0.7-0.1,1.3-0.3,2-0.4
            c1-0.2,1.9-0.4,2.9-0.5c0.6-0.1,1.3-0.2,1.9-0.4c1-0.2,2-0.3,3-0.5c0.6-0.1,1.2-0.2,1.8-0.3c1.1-0.2,2.2-0.3,3.4-0.5
            c0.5-0.1,1-0.1,1.5-0.2c1.6-0.2,3.2-0.4,4.8-0.6c0.8-0.1,1.4-0.8,1.3-1.7c-0.1-0.8-0.9-1.4-1.7-1.3c-1.7,0.2-3.3,0.4-5,0.6
            c-0.4,0-0.7,0.1-1.1,0.2c-1.3,0.2-2.6,0.4-3.9,0.6c-0.5,0.1-1,0.2-1.5,0.3c-1.1,0.2-2.3,0.4-3.4,0.6c-0.6,0.1-1.2,0.2-1.8,0.3
            c-1,0.2-2.1,0.4-3.1,0.6c-0.6,0.1-1.3,0.3-1.9,0.4c-1,0.2-2,0.4-3,0.6c-0.7,0.1-1.3,0.3-2,0.5c-1,0.2-1.9,0.4-2.9,0.7
            c-0.7,0.2-1.4,0.3-2,0.5c-0.9,0.2-1.9,0.5-2.8,0.7c-0.7,0.2-1.4,0.4-2.1,0.6c-0.9,0.2-1.8,0.5-2.7,0.8c-0.7,0.2-1.4,0.4-2.1,0.6
            c-0.9,0.3-1.7,0.5-2.6,0.8c-0.7,0.2-1.4,0.5-2.2,0.7c-0.9,0.3-1.7,0.5-2.6,0.8c-0.7,0.2-1.5,0.5-2.2,0.8c-0.8,0.3-1.7,0.6-2.5,0.9
            c-0.7,0.3-1.5,0.5-2.2,0.8c-0.8,0.3-1.6,0.6-2.5,0.9c-0.7,0.3-1.5,0.6-2.2,0.9c-0.8,0.3-1.6,0.6-2.4,1c-0.7,0.3-1.5,0.6-2.2,0.9
            c-0.8,0.3-1.6,0.7-2.4,1c-0.7,0.3-1.5,0.7-2.2,1c-0.8,0.4-1.6,0.7-2.4,1.1c-0.7,0.3-1.5,0.7-2.2,1c-0.7,0.3-1.3,0.6-2,0.9
            c-1.8-0.7-3.5-1.5-5.3-2.2c-0.4-0.2-0.8-0.3-1.2-0.4c-1.5-0.6-3.1-1.2-4.6-1.7c-0.7-0.2-1.4-0.5-2-0.7c-1.3-0.5-2.6-0.9-3.8-1.3
            c-0.7-0.2-1.5-0.5-2.2-0.7c-0.6-0.2-1.1-0.4-1.7-0.6c30.2-15.9,62.8-24.9,97.1-26.7c0.8,0,1.5-0.8,1.4-1.6c0-0.8-0.8-1.5-1.6-1.4
            c-7.2,0.4-14.4,1.1-21.5,2.1v-6.3c0.6,0.1,1.5,0.1,2.6,0.1c2.9,0,7.1-0.5,9.8-3.2c4.4-4.4,2.7-12.7,2.7-13c-0.1-0.6-0.6-1-1.2-1.2
            c-0.3-0.1-6-1.2-10.5,0.9c1.6-2,3.2-4.6,3.2-7.6c0-6.3-7-10.9-7.3-11.1c-0.5-0.3-1.1-0.3-1.6,0c-0.3,0.2-7.3,4.8-7.3,11.1
            c0,2.9,1.5,5.5,3.2,7.6c-4.5-2.1-10.2-1-10.5-0.9c-0.6,0.1-1,0.6-1.2,1.2c-0.1,0.3-1.8,8.6,2.7,13c2.7,2.7,6.8,3.2,9.8,3.2
            c1.1,0,2-0.1,2.6-0.1v6.7c-26.8,4.1-52.4,12.8-76.5,25.9c-12.2-3.6-24.6-6.3-37.3-7.9v-8.1c0.6,0.1,1.5,0.1,2.6,0.1
            c2.9,0,7.1-0.5,9.8-3.2c4.4-4.4,2.7-12.7,2.7-13c-0.1-0.6-0.6-1-1.2-1.2c-0.3-0.1-6-1.2-10.5,0.9c1.6-2,3.2-4.6,3.2-7.6
            c0-6.3-7-10.9-7.3-11.1c-0.5-0.3-1.1-0.3-1.6,0c-0.3,0.2-7.3,4.8-7.3,11.1c0,2.9,1.5,5.5,3.2,7.6c-4.5-2.1-10.2-1-10.5-0.9
            c-0.6,0.1-1,0.6-1.2,1.2c-0.1,0.3-1.8,8.6,2.7,13c2.7,2.7,6.8,3.2,9.8,3.2c1.1,0,2-0.1,2.6-0.1v7.7c-8.5-1-17-1.5-25.6-1.6
            c0,0,0,0,0,0c-0.8,0-1.5,0.7-1.5,1.5c0,0.8,0.7,1.5,1.5,1.5c22.3,0.2,44.4,3.5,65.6,9.9c8.4,2.5,16.6,5.5,24.6,8.9
            c8.2,3.5,16.3,7.5,24,12c7.2,4.1,14.3,8.7,21.2,13.7c10.3,7.5,20.1,15.9,29.1,24.9c0.3,0.3,0.7,0.4,1.1,0.4c0.4,0,0.8-0.1,1.1-0.4
            c0.6-0.6,0.6-1.5,0-2.1c-1.1-1.1-2.2-2.2-3.3-3.3c-0.4-0.4-0.8-0.7-1.2-1.1c-0.7-0.7-1.5-1.4-2.2-2.1c-0.5-0.4-1-0.9-1.4-1.3
            c-0.7-0.6-1.4-1.2-2-1.9c-0.5-0.5-1-0.9-1.5-1.4c-0.7-0.6-1.3-1.2-2-1.7c-0.5-0.5-1.1-0.9-1.6-1.4c-0.7-0.6-1.3-1.1-2-1.7
            c-0.5-0.5-1.1-0.9-1.7-1.4c-0.7-0.5-1.3-1.1-2-1.6c-0.6-0.4-1.1-0.9-1.7-1.3c-0.7-0.5-1.3-1-2-1.6c-0.6-0.4-1.2-0.9-1.7-1.3
            c-0.3-0.2-0.6-0.4-0.8-0.6c0.2-0.1,0.4-0.2,0.6-0.2c0.5-0.2,1-0.4,1.5-0.6c0.7-0.3,1.4-0.5,2.1-0.8c0.5-0.2,1-0.4,1.6-0.6
            c0.7-0.2,1.4-0.5,2.1-0.7c0.5-0.2,1-0.4,1.6-0.5c0.7-0.2,1.4-0.5,2.1-0.7c0.5-0.2,1.1-0.3,1.6-0.5c0.7-0.2,1.4-0.4,2.1-0.7
            c0.5-0.2,1-0.3,1.6-0.5c0.7-0.2,1.5-0.4,2.2-0.7c0.5-0.1,1-0.3,1.5-0.4c0.8-0.2,1.6-0.5,2.4-0.7c0.4-0.1,0.8-0.2,1.3-0.3
            c1.2-0.3,2.5-0.6,3.7-1C169.7,134,170.2,133.1,169.9,132.3z M159.2,59c0.3,2,0.5,6.7-2,9.2c-2.5,2.5-7.3,2.4-9.5,2.2
            c-0.2-2.5-0.1-7.1,2.3-9.5C152.5,58.5,157.2,58.7,159.2,59z M145.4,41.6c1.6,1.3,5.1,4.4,5.1,7.9c0,3.6-3.5,6.9-5.1,8.3
            c-2-1.6-5.1-5-5.1-8.3C140.3,46,143.8,42.9,145.4,41.6z M143.1,70.5c-2.2,0.2-7,0.3-9.5-2.2c-2.5-2.5-2.3-7.2-2-9.2
            c2.4-0.3,6.9-0.3,9.2,2C143.3,63.5,143.3,68.3,143.1,70.5z M42.4,75.7c0.3,2,0.5,6.7-2,9.2c-2.5,2.5-7.3,2.4-9.5,2.2
            c-0.2-2.5-0.1-7.1,2.3-9.5C35.7,75.2,40.4,75.4,42.4,75.7z M28.6,58.3c1.6,1.3,5.1,4.4,5.1,7.9c0,3.6-3.5,6.9-5.1,8.3
            c-2-1.6-5.1-5-5.1-8.3C23.5,62.7,27,59.6,28.6,58.3z M26.3,87.2c-2.5,0.2-7.1,0.1-9.5-2.3c-2.5-2.5-2.3-7.2-2-9.2
            c2.4-0.3,6.9-0.3,9.2,2C26.5,80.2,26.5,85,26.3,87.2z M138.7,166.5c0.6,0.5,0.7,1.5,0.2,2.1c-0.3,0.4-0.7,0.5-1.2,0.5
            c-0.3,0-0.7-0.1-1-0.4c-6.6-5.6-13.5-10.8-20.5-15.5c-7-4.7-14.4-9.1-22-13.1c-7.6-4-15.4-7.5-23.2-10.6c-7.7-3-15.8-5.7-24-7.9
            c-14.8-4-30.2-6.6-45.6-7.6c-0.8-0.1-1.5-0.8-1.4-1.6c0.1-0.8,0.8-1.5,1.6-1.4c15.6,1.1,31.2,3.7,46.2,7.7c8.3,2.3,16.5,5,24.3,8
            c7.9,3.1,15.8,6.7,23.5,10.7c7.7,4,15.2,8.5,22.3,13.3C125,155.5,132,160.8,138.7,166.5z M100.6,168.5c-0.3,0.4-0.8,0.7-1.3,0.7
            c-0.3,0-0.6-0.1-0.8-0.3c-1.4-0.9-2.8-1.8-4.2-2.7c0,0,0,0,0,0c-7.2-4.6-14.8-8.8-22.5-12.6c0,0,0,0,0,0
            c-7.2-3.5-14.5-6.7-21.9-9.4c-7.5-2.8-15.3-5.2-23-7.1c-8.3-2.1-16.9-3.8-25.6-5c-0.8-0.1-1.4-0.9-1.3-1.7
            c0.1-0.8,0.9-1.4,1.7-1.3c8.8,1.2,17.5,2.9,25.9,5.1c7.9,2,15.7,4.4,23.3,7.2c7.5,2.7,14.9,5.9,22.2,9.5c0,0,0,0,0,0
            c7.8,3.8,15.5,8.1,22.8,12.8c0,0,0,0,0,0c1.4,0.9,2.8,1.8,4.2,2.7C100.8,166.8,101,167.8,100.6,168.5z M56.1,166.2
            c-0.3,0.5-0.8,0.9-1.4,0.9c-0.2,0-0.4,0-0.6-0.1c-6.7-3.2-13.7-6.1-20.6-8.6c0,0,0,0,0,0c-7.2-2.6-14.6-4.8-22.1-6.7
            c-3.6-0.9-6.9-1.7-10.2-2.3c-0.8-0.2-1.4-1-1.2-1.8c0.2-0.8,1-1.4,1.8-1.2c3.3,0.7,6.7,1.4,10.3,2.4c7.5,1.9,15.1,4.2,22.4,6.8
            c0,0,0,0,0,0c7.1,2.5,14.1,5.4,20.9,8.7C56.2,164.5,56.5,165.4,56.1,166.2z"
          />
        </defs>
        <clipPath id="SVGID_2_GradientWelcome">
          <use xlinkHref="#SVGID_9_GradientWelcome" overflow="visible" />
        </clipPath>
        <g clipPath="url(#SVGID_2_GradientWelcome)">
          <rect
            x="-15.2"
            y="145"
            transform="matrix(0.9736 -0.2284 0.2284 0.9736 -35.136 27.323)"
            fill="#B95700"
            width="231.3"
            height="41"
          />
          <rect
            x="-24.5"
            y="105.1"
            transform="matrix(0.9736 -0.2284 0.2284 0.9736 -26.2689 24.1299)"
            fill="#EB6909"
            width="231.3"
            height="41"
          />
          <rect
            x="-33.9"
            y="65.2"
            transform="matrix(0.9736 -0.2284 0.2284 0.9736 -17.4018 20.9368)"
            fill="#5F9501"
            width="231.3"
            height="41"
          />
          <rect
            x="-43.2"
            y="25.3"
            transform="matrix(0.9736 -0.2284 0.2284 0.9736 -8.5348 17.7437)"
            fill="#79B61D"
            width="231.3"
            height="41"
          />
          <rect
            x="-52.6"
            y="-14.7"
            transform="matrix(0.9736 -0.2284 0.2284 0.9736 0.3323 14.5506)"
            fill="#C8D200"
            width="231.3"
            height="41"
          />
        </g>
      </g>
    </svg>
  );
};

GradientWelcome.defaultProps = {
  onlyField: false,
};

export default GradientWelcome;
