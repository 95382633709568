/* eslint-disable import/prefer-default-export */
/* eslint-disable react/prop-types */
import React from 'react';
import { PropTypes } from 'prop-types';
import { useLocalizedFromContext } from 'data/Localization';

export const GradientCrop40 = ({ title, ...props }) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 70 70"
    enableBackground="new 0 0 70 70"
    {...props}
  >
    <title>{useLocalizedFromContext(title)}</title>
    <g>
      <g>
        <clipPath id="SVGID_2_">
          <path
            id="SVGID_1_"
            d="M35.1,37.2c0-1.9-0.9-3.7-2.4-5c-1.9-1.6-4.5-2.4-6.8-2c-6.1,1-10.2,6.6-10.2,13.9c0,7.5,4.3,13.4,10.3,14
            c0.2,0,0.5,0,0.7,0c2.2,0,4.5-0.9,6.1-2.4c1.4-1.3,2.2-2.9,2.2-4.7c0-3.2-1.1-4.6-1.9-5.5c-0.5-0.6-0.7-0.8-0.7-1.4
            c0-0.5,0.1-0.6,0.6-1.2C33.9,42,35.1,40.6,35.1,37.2z M30.8,47.4c0.6,0.8,1.2,1.5,1.2,3.6c0,0.9-0.4,1.7-1.2,2.5
            c-1.2,1.1-3,1.7-4.5,1.6c-4.4-0.4-7.6-5-7.6-11c0-5.8,3.1-10.2,7.7-11c1.4-0.2,3.2,0.3,4.4,1.3c0.6,0.5,1.4,1.4,1.4,2.7
            c0,2.3-0.6,3-1.3,3.8c-0.6,0.7-1.4,1.6-1.4,3.2C29.5,45.8,30.2,46.7,30.8,47.4z M28.3,42.7c-0.9,1.6-0.2,2.6-0.1,2.8
            c0.5,0.7,0.4,1.6-0.3,2.1c-0.3,0.2-0.6,0.3-0.9,0.3c-0.4,0-0.9-0.2-1.2-0.6c-0.8-1-1.7-3.4-0.1-6.1c0.4-0.7,1.3-1,2.1-0.5
            C28.5,41,28.7,42,28.3,42.7z M44.9,39.8c-5.1,0-9.2,4.1-9.2,9.2s4.1,9.2,9.2,9.2s9.2-4.1,9.2-9.2S50,39.8,44.9,39.8z M44.9,55.1
            c-3.4,0-6.2-2.8-6.2-6.2s2.8-6.2,6.2-6.2s6.2,2.8,6.2,6.2S48.3,55.1,44.9,55.1z M49.9,49.7c0,0.8-0.7,1.5-1.5,1.5
            s-1.5-0.7-1.5-1.5c0-1.4-1.1-2.5-2.5-2.5c-0.8,0-1.5-0.7-1.5-1.5s0.7-1.5,1.5-1.5C47.5,44.2,49.9,46.7,49.9,49.7z M45.7,36
            c5.2,0,9.5-5.3,9.5-11.8S51,12.4,45.7,12.4c-5.2,0-9.5,5.3-9.5,11.8S40.5,36,45.7,36z M45.7,15.4c3.6,0,6.5,4,6.5,8.8
            S49.3,33,45.7,33s-6.5-4-6.5-8.8S42.2,15.4,45.7,15.4z M42.5,25.7c-0.8,0-1.5-0.7-1.5-1.5c0-4.1,2.4-7.4,5.4-7.4
            c0.8,0,1.5,0.7,1.5,1.5s-0.7,1.5-1.5,1.5c-1.1,0-2.4,1.9-2.4,4.4C44,25,43.3,25.7,42.5,25.7z M32.9,13c-0.9-0.6-1.8-1-2.6-1
            c-1.5,0-2.8,0.5-3.6,0.9c-0.8-0.4-2.2-0.9-3.6-0.9c-0.9,0-1.8,0.3-2.6,1c-1.5,1.1-2.5,2.8-2.7,4.7c-0.2,2.4,0,5.8,2.2,8.2
            c1.6,1.7,3.8,2.6,6.8,2.6s5.2-0.9,6.8-2.6c2.2-2.4,2.4-5.8,2.2-8.2C35.4,15.9,34.4,14.1,32.9,13z M31.2,23.9
            c-1,1.1-2.5,1.6-4.5,1.6s-3.6-0.5-4.5-1.6c-1.5-1.7-1.5-4.4-1.4-5.9c0.1-1,0.6-2,1.4-2.6c0.3-0.2,0.6-0.4,0.8-0.4
            c0.6,0,1.2,0.2,1.7,0.4c-0.4,1.2-0.7,3.1-0.2,5.7c0.2,0.8,1,1.3,1.8,1.2c0.8-0.2,1.3-1,1.2-1.8c-0.5-2.5,0.1-4.1,0.3-4.7
            c0.6-0.3,1.6-0.7,2.5-0.7c0.2,0,0.5,0.1,0.8,0.4c0.8,0.6,1.4,1.5,1.4,2.6C32.7,19.5,32.7,22.3,31.2,23.9z"
          />
        </clipPath>
        <g clipPath="url(#SVGID_2_)">
          <rect
            x="5.3"
            y="49.2"
            transform="matrix(0.9736 -0.2284 0.2284 0.9736 -12.4512 11.0125)"
            fill="#B95700"
            width="72.1"
            height="20.2"
          />
          <rect
            x="1.9"
            y="39.7"
            transform="matrix(0.9736 -0.2284 0.2284 0.9736 -9.1936 9.8471)"
            fill="#EB6909"
            width="72.1"
            height="9.9"
          />
          <rect
            x="-0.4"
            y="30.1"
            transform="matrix(0.9736 -0.2284 0.2284 0.9736 -7.0637 9.0697)"
            fill="#5F9501"
            width="72.1"
            height="9.9"
          />
          <rect
            x="-2.7"
            y="20.5"
            transform="matrix(0.9736 -0.2284 0.2284 0.9736 -4.9338 8.2922)"
            fill="#79B61D"
            width="72.1"
            height="9.9"
          />
          <rect
            x="-6.1"
            y="0.7"
            transform="matrix(0.9736 -0.2284 0.2284 0.9736 -1.6762 7.1271)"
            fill="#C8D200"
            width="72.1"
            height="20.2"
          />
        </g>
      </g>
    </g>
  </svg>
);

GradientCrop40.defaultProps = {
  title: 'legumes',
};

GradientCrop40.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
};
