/* eslint-disable import/prefer-default-export */
/* eslint-disable react/prop-types */
import React from 'react';
import { PropTypes } from 'prop-types';
import { useLocalizedFromContext } from 'data/Localization';

export const Crop8 = ({ title, fill, ...props }) => (
  <svg
    id="prefix__Layer_1"
    x={0}
    y={0}
    width="66"
    height="78"
    viewBox="0 0 66 78"
    xmlSpace="preserve"
    {...props}
  >
    <title>{useLocalizedFromContext(title)}</title>
    <path
      d="M58.9 33.4c-6.9-6-14.6-4.5-16.4-4-.5-.2-1-.3-1.5-.4-.6-.4-4.2-3.3-5.9-12.3-2-10.2-.3-13 .5-13.7.6-.6 1.4-.4 1.5-.4.6.2 1.3-.2 1.5-.8.2-.6-.1-1.3-.8-1.5h-.1c-1.3-.4-2.5-.1-3.6.6-4.7 3.4-5 17.8-5.1 19.4-.1 5.1-5.4 8.4-5.4 8.4-.1 0-.1.1-.2.2-3.1-.4-6.7 0-9.6 2.5-1.4-.4-4.7-.6-7.8 1.2-2.6 1.5-4.3 4.2-5.2 7.8C-.5 45.7 2.9 65 11.4 72.2c2.1 1.8 4.4 2.7 6.8 2.7 1.2 0 2.4-.2 3.6-.7.3.2.6.3 1 .4.7.3 1.5.4 2.3.4 1.2 0 2.4-.3 3.7-.9.2.2.5.3.7.5 2.8 1.7 5.5 2.5 8 2.5 1.1 0 2.1-.2 3.1-.5 2-.6 3.4-1.8 4.5-3 .7.6 1.5.9 2.1 1 .2 0 .5.1.7.1 2.4 0 4.6-1.8 4.8-2 5.6-5.1 10.4-18.2 10.3-28-.1-5.1-1.5-9.1-4.1-11.3zM31.6 20.2c0-1.9.2-4.4.5-6.8.1 1.1.3 2.4.6 3.6 1.1 5.7 2.9 9.3 4.5 11.3h-.9c-3.3 0-5.9.6-8 1.7l-1.5-.6c1.8-1.5 4.7-4.7 4.8-9.2zm-8 52.1c-2.2-.7-3.3-3.1-3.3-3.1-.3-.6-1-.9-1.6-.6-.6.3-.9 1-.6 1.6 0 .1.5 1 1.4 2.1-2.3.4-4.4-.3-6.5-2C5.6 64.1 2 45.9 3.2 41c.7-2.9 2.1-5 4.1-6.2 1.6-.9 3.3-1.2 4.6-1.2-5.3 7.6-1.3 16.6-1.1 17 .2.5.7.7 1.1.7.2 0 .3 0 .5-.1.6-.3.9-1 .6-1.6 0-.1-3.8-8.8 1.4-15.3.2-.3.5-.5.7-.8 3.3-3.3 7.8-2.6 10.4-1.8-5.5 4.3-6 11.5-6 11.6 0 .9-.3 20.6 7.2 29-1.1.3-2.1.4-3.1 0zM51 70.8c-.5.5-2.2 1.6-3.5 1.4-.4-.1-.7-.2-1-.5.3-.6.6-1.1.8-1.5 1.2-2.9 1.8-6.9 2.4-11.2.2-1.5.4-3.1.7-4.6.1-.7-.3-1.3-1-1.4-.7-.1-1.3.3-1.4 1-.3 1.6-.5 3.1-.7 4.7-.6 4.1-1.1 8-2.2 10.6-.4.9-2 4.1-5.2 5.1-2.5.8-5.6.2-9-1.8-9.3-5.5-8.9-29-8.9-29.2 0-.5.8-12.5 14.2-12.5 4.3 0 7.5 1.2 9.6 3.4 3.2 3.4 2.9 8.3 2.9 8.3 0 .7.5 1.3 1.1 1.3.7 0 1.3-.5 1.3-1.1 0-.2.4-6-3.5-10.2-.5-.5-1-.9-1.5-1.4 2.9-.1 7.2.4 11.2 3.9 2.6 2.3 3.2 6.4 3.2 9.5.1 9.2-4.4 21.6-9.5 26.2z"
      fill={fill || '#71bf44'}
    />
  </svg>
);

Crop8.defaultProps = {
  title: 'pumpkin',
};

Crop8.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
};
