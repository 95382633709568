/* eslint-disable import/prefer-default-export */
/* eslint-disable react/prop-types */
import React from 'react';
import { PropTypes } from 'prop-types';
import { useLocalizedFromContext } from 'data/Localization';

export const Crop33 = ({ title, fill, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="79.232"
    height="157.225"
    viewBox="0 0 79.232 157.225"
    {...props}
  >
    <title>{useLocalizedFromContext(title)}</title>
    <g id="Component_9_1" dataname="Component 9 – 1" transform="translate(2.586 3.536)">
      <g id="Group_994" dataname="Group 994" transform="translate(-2212.369 -3958.424)">
        <line
          id="Line_379"
          dataname="Line 379"
          y2="35.299"
          transform="translate(2227.862 4074.314)"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
        <g id="Group_977" dataname="Group 977">
          <path
            id="Path_4661"
            dataname="Path 4661"
            d="M2234.472,4039.938s3.511,2.391,6.577-4.41,2.117-14.878,2.117-14.878-6.368,3.948-9.191,10.211c-3.066,6.8.5,9.077.5,9.077"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
        <g id="Group_978" dataname="Group 978">
          <path
            id="Path_4662"
            dataname="Path 4662"
            d="M2234.472,4055.376s3.511,2.391,6.577-4.411,2.117-14.878,2.117-14.878-6.368,3.949-9.191,10.212c-3.066,6.8.5,9.077.5,9.077"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
        <g id="Group_979" dataname="Group 979">
          <path
            id="Path_4663"
            dataname="Path 4663"
            d="M2234.472,4024.767s3.511,2.391,6.577-4.411,2.117-14.877,2.117-14.877-6.368,3.948-9.191,10.211c-3.066,6.8.5,9.077.5,9.077"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
        <g id="Group_980" dataname="Group 980">
          <path
            id="Path_4664"
            dataname="Path 4664"
            d="M2234.472,4009.912s3.511,2.391,6.577-4.41,2.117-14.878,2.117-14.878-6.368,3.948-9.191,10.211c-3.066,6.8.5,9.077.5,9.077"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
        <g id="Group_981" dataname="Group 981">
          <path
            id="Path_4665"
            dataname="Path 4665"
            d="M2234.472,3994.158s3.511,2.391,6.577-4.41,2.117-14.878,2.117-14.878-6.368,3.948-9.191,10.211c-3.066,6.8.5,9.077.5,9.077"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
        <g id="Group_982" dataname="Group 982">
          <path
            id="Path_4666"
            dataname="Path 4666"
            d="M2228.019,3985.683s4.874-.034,4.716-7.78-5.165-14.186-5.165-14.186-4.726,7.253-4.58,14.385c.158,7.746,5.029,7.581,5.029,7.581"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
        <g id="Group_983" dataname="Group 983">
          <path
            id="Path_4667"
            dataname="Path 4667"
            d="M2228.019,4007.648s4.874-.034,4.716-7.779-5.165-14.186-5.165-14.186-4.726,7.253-4.58,14.385c.158,7.745,5.029,7.58,5.029,7.58"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
        <g id="Group_984" dataname="Group 984">
          <path
            id="Path_4668"
            dataname="Path 4668"
            d="M2228.019,4029.613s4.874-.033,4.716-7.779-5.165-14.186-5.165-14.186-4.726,7.253-4.58,14.385c.158,7.746,5.029,7.58,5.029,7.58"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
        <g id="Group_985" dataname="Group 985">
          <path
            id="Path_4669"
            dataname="Path 4669"
            d="M2228.019,4051.579s4.874-.034,4.716-7.779-5.165-14.186-5.165-14.186-4.726,7.252-4.58,14.385c.158,7.745,5.029,7.58,5.029,7.58"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
        <g id="Group_986" dataname="Group 986">
          <path
            id="Path_4670"
            dataname="Path 4670"
            d="M2228.019,4073.544s4.874-.034,4.716-7.779-5.165-14.186-5.165-14.186-4.726,7.253-4.58,14.385c.158,7.745,5.029,7.58,5.029,7.58"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
        <rect
          id="Rectangle_167"
          dataname="Rectangle 167"
          width="1.401"
          height="1.401"
          transform="translate(2232.244 4011.011)"
          strokeWidth="5"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="none"
        />
        <g id="Group_987" dataname="Group 987">
          <path
            id="Path_4671"
            dataname="Path 4671"
            d="M2221.2,4039.938s-3.511,2.391-6.577-4.41-2.118-14.878-2.118-14.878,6.369,3.948,9.192,10.211c3.066,6.8-.5,9.077-.5,9.077"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
        <g id="Group_988" dataname="Group 988">
          <path
            id="Path_4672"
            dataname="Path 4672"
            d="M2221.2,4055.376s-3.511,2.391-6.577-4.411-2.118-14.878-2.118-14.878,6.369,3.949,9.192,10.212c3.066,6.8-.5,9.077-.5,9.077"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
        <g id="Group_989" dataname="Group 989">
          <path
            id="Path_4673"
            dataname="Path 4673"
            d="M2234.472,4070.418s3.511,2.391,6.577-4.41,2.117-14.878,2.117-14.878-6.368,3.948-9.191,10.211c-3.066,6.8.5,9.077.5,9.077"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
        <g id="Group_990" dataname="Group 990">
          <path
            id="Path_4674"
            dataname="Path 4674"
            d="M2221.2,4070.418s-3.511,2.391-6.577-4.41-2.118-14.878-2.118-14.878,6.369,3.948,9.192,10.211c3.066,6.8-.5,9.077-.5,9.077"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
        <g id="Group_991" dataname="Group 991">
          <path
            id="Path_4675"
            dataname="Path 4675"
            d="M2221.2,4024.767s-3.511,2.391-6.577-4.411-2.118-14.877-2.118-14.877,6.369,3.948,9.192,10.211c3.066,6.8-.5,9.077-.5,9.077"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
        <g id="Group_992" dataname="Group 992">
          <path
            id="Path_4676"
            dataname="Path 4676"
            d="M2221.2,4009.912s-3.511,2.391-6.577-4.41-2.118-14.878-2.118-14.878,6.369,3.948,9.192,10.211c3.066,6.8-.5,9.077-.5,9.077"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
        <g id="Group_993" dataname="Group 993">
          <path
            id="Path_4677"
            dataname="Path 4677"
            d="M2221.2,3994.158s-3.511,2.391-6.577-4.41-2.118-14.878-2.118-14.878,6.369,3.948,9.192,10.211c3.066,6.8-.5,9.077-.5,9.077"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
        <rect
          id="Rectangle_168"
          dataname="Rectangle 168"
          width="1.401"
          height="1.401"
          transform="translate(2223.43 4012.412) rotate(180)"
          strokeWidth="5"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="none"
        />
      </g>
      <g id="Group_999" dataname="Group 999" transform="translate(-2212.369 -3958.424)">
        <line
          id="Line_380"
          dataname="Line 380"
          y1="18.337"
          transform="translate(2268.617 3966.16)"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
        <line
          id="Line_381"
          dataname="Line 381"
          x2="18.337"
          transform="translate(2259.407 3975.286)"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
        <g id="Group_995" dataname="Group 995">
          <line
            id="Line_382"
            dataname="Line 382"
            x1="7.735"
            y1="7.735"
            transform="translate(2251.672 3967.551)"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
          <line
            id="Line_383"
            dataname="Line 383"
            y1="7.735"
            x2="7.735"
            transform="translate(2251.672 3975.286)"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
        <g id="Group_996" dataname="Group 996">
          <line
            id="Line_384"
            dataname="Line 384"
            x2="7.735"
            y2="7.735"
            transform="translate(2277.744 3975.286)"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
          <line
            id="Line_385"
            dataname="Line 385"
            x1="7.735"
            y2="7.735"
            transform="translate(2277.744 3967.551)"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
        <g id="Group_997" dataname="Group 997">
          <line
            id="Line_386"
            dataname="Line 386"
            x1="7.735"
            y2="7.735"
            transform="translate(2260.882 3984.497)"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
          <line
            id="Line_387"
            dataname="Line 387"
            x1="7.735"
            y1="7.735"
            transform="translate(2268.617 3984.497)"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
        <g id="Group_998" dataname="Group 998">
          <line
            id="Line_388"
            dataname="Line 388"
            y1="7.735"
            x2="7.735"
            transform="translate(2268.617 3958.424)"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
          <line
            id="Line_389"
            dataname="Line 389"
            x2="7.735"
            y2="7.735"
            transform="translate(2260.882 3958.424)"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
      </g>
    </g>
  </svg>
);

Crop33.defaultProps = {
  title: 'winter_triticale',
};

Crop33.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
};
