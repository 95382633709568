export const PLAN_ID = '/:planId';
export const CROP_ROTATION_ID = '/:cropRotationId';
export const FLOW_SELECT = '/plan-builder/flow-select';
export const MY_OVERVIEW = `${window.location.origin}/myoverview`;

export const fileRouteConstants = {
    TERMS: 'terms',
};

export const termsRouteConstants = {
    TERMS_UK: 'Terms_2024_UK.pdf',
    TERMS_HU: 'Terms_2023_HU.pdf',
    TERMS_RO: 'Terms_2023_RO.pdf',
    TERMS_FR: 'Terms_2023_FR.pdf',
    TERMS_BG: 'Terms_2023_BG.pdf',
    TERMS_AT: 'Terms_2023_AT.pdf',
    TERMS_DE: 'Terms_2023_DE.pdf',
    TERMS_SK: 'Terms_2024_SK.pdf',
    TERMS_CZ: 'Terms_2024_CZ.pdf',
};

export const privacyRouteConstants = {
    PRIVACY_UK: 'Privacy_2024_UK.pdf',
    PRIVACY_HU: 'Privacy_2023_HU_v2.pdf',
    PRIVACY_RO: 'Privacy_2023_RO_v2.pdf',
    PRIVACY_FR: 'Privacy_2023_FR_v2.pdf',
    PRIVACY_BG: 'Privacy_2023_BG_v2.pdf',
    PRIVACY_AT: 'Privacy_2023_AT_v2.pdf',
    PRIVACY_DE: 'Privacy_2023_DE_v2.pdf',
    PRIVACY_SK: 'Privacy_2024_SK.pdf',
    PRIVACY_CZ: 'Privacy_2024_CZ.pdf',
};
