/* eslint-disable import/prefer-default-export */
/* eslint-disable react/prop-types */
import React from 'react';
import { PropTypes } from 'prop-types';
import { useLocalizedFromContext } from 'data/Localization';

export const Crop18 = ({ title, fill, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="153.234"
    height="162.983"
    viewBox="0 0 153.234 162.983"
    {...props}
  >
    <title>{useLocalizedFromContext(title)}</title>
    <g id="Component_4_1" dataname="Component 4 – 1" transform="translate(2.5 2.5)">
      <path
        id="Path_359"
        dataname="Path 359"
        d="M1951.147,5236.23c3.206-7.782,4.525-35.316,4.525-35.316s-26.532,9.18-33.034,20.212c-7.027,11.926.437,20.121,5.226,23.048C1933.093,5247.372,1945.148,5250.785,1951.147,5236.23Z"
        transform="translate(-1807.438 -5200.914)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_360"
        dataname="Path 360"
        d="M1934.661,5233.606s-27.5,23.355-27.5,55.528"
        transform="translate(-1807.438 -5200.914)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_361"
        dataname="Path 361"
        d="M1894.9,5242.466c-.7-5.062,2.733-21.442,2.733-21.442s14.189,9.445,16.325,16.921c2.308,8.08-3.329,11.756-6.59,12.745C1903.806,5251.77,1896.2,5251.935,1894.9,5242.466Z"
        transform="translate(-1807.438 -5200.914)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_362"
        dataname="Path 362"
        d="M1904.275,5242.686s6.251,12.382,7.017,23.016"
        transform="translate(-1807.438 -5200.914)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <g id="Group_88" dataname="Group 88" transform="translate(-1807.438 -5200.914)">
        <path
          id="Path_363"
          dataname="Path 363"
          d="M1936.475,5271.31c4.786-1.788,17.58-12.577,17.58-12.577s-15.046-8.011-22.64-6.345c-8.208,1.8-9.17,8.507-8.494,11.847C1923.66,5267.883,1927.521,5274.655,1936.475,5271.31Z"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
        <path
          id="Path_364"
          dataname="Path 364"
          d="M1931.429,5262.919a47.979,47.979,0,0,0-19.987,4.016"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
      </g>
      <path
        id="Path_365"
        dataname="Path 365"
        d="M1862.836,5274.707s3.767-13.6,20.157-17.2,21.675,11.489,22.786,18.789c3.791,24.907,2.393,76.781,2.393,76.781"
        transform="translate(-1807.438 -5200.914)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_366"
        dataname="Path 366"
        d="M1859.94,5276.3s-13.325,16.79-14.658,19.188-6.129,4.8-10.394,8.528-4.53,7.2-6.929,9.328-20.521,8-20.521,13.059,11.993,11.993,19.988,5.863,1.932-7,1.932-7,10.727-1.665,18.589-9.527c3.816-3.816,5.131-13.259,5.131-13.259s.439,4.138,2.731,3.131c5.464-2.4,8.4-14.391,7.862-23.186S1862.1,5273.558,1859.94,5276.3Z"
        transform="translate(-1807.438 -5200.914)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_367"
        dataname="Path 367"
        d="M1877.511,5279.08c-2.88,4.408-3.873,16.523-3.4,20.787s1.128,7.463,0,9.595-4.326,8.528-4.593,10.926.533,8.275-2.132,13.066-12.259,17.05-10.394,23.179,19.455-1.332,20.521-8.794-3.4-7.729-3.4-7.729,9-7.462,10.331-14.658,0-20.255,0-20.255a11.83,11.83,0,0,0,2.666-5.6c.8-3.731-2.893-20.587-5.92-21.684C1880.413,5277.637,1878.969,5276.849,1877.511,5279.08Z"
        transform="translate(-1807.438 -5200.914)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_368"
        dataname="Path 368"
        d="M1879.737,5277.134s-3.3-16.374,1.4-18.98"
        transform="translate(-1807.438 -5200.914)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
    </g>
  </svg>
);

Crop18.defaultProps = {
  title: 'soya',
};

Crop18.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
};
