import {
  POST_PRODUCT_LOADING,
  POST_PRODUCT_SUCCESS,
  POST_PRODUCT_FAILED,
  STATUS_POSTING,
  STATUS_POST_SUCCESS,
  STATUS_POST_FAILED,
  PUT_PRODUCT_LOADING,
  PUT_PRODUCT_SUCCESS,
  PUT_PRODUCT_FAILED,
  STATUS_PUTTING,
  STATUS_PUT_SUCCESS,
  STATUS_PUT_FAILED,
  GET_PRODUCT_LOADING,
  GET_PRODUCT_SUCCESS,
  GET_PRODUCT_FAILED,
  STATUS_GETTING,
  STATUS_GET_SUCCESS,
  STATUS_GET_FAILED,
  STATUS_DEFAULT,
  RESET_PRODUCT,
} from 'Redux/actions/types';

const initialState = {
  status: STATUS_DEFAULT,
  statusCode: null,
  errorCode: null,
  data: null,
};

export default (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case GET_PRODUCT_LOADING:
      return {
        ...state,
        errorCode: null,
        status: STATUS_GETTING,
      };

    case GET_PRODUCT_SUCCESS:
      return {
        ...state,
        data: payload,
        errorCode: null,
        status: STATUS_GET_SUCCESS,
      };

    case GET_PRODUCT_FAILED:
      return {
        ...state,
        status: STATUS_GET_FAILED,
        statusCode: payload.statusCode,
        errorCode: payload.errorCode,
      };

    case POST_PRODUCT_LOADING:
      return {
        ...state,
        status: STATUS_POSTING,
        errorCode: null,
        statusCode: null,
      };
    case POST_PRODUCT_SUCCESS:
      return {
        ...state,
        status: STATUS_POST_SUCCESS,
        statusCode: payload.statusCode,
        errorCode: null,
      };
    case POST_PRODUCT_FAILED:
      return {
        ...state,
        status: STATUS_POST_FAILED,
        statusCode: payload.statusCode,
        errorMessage: payload.json,
        errorCode: payload.errorCode,
      };

    case PUT_PRODUCT_LOADING:
      return {
        ...state,
        status: STATUS_PUTTING,
        statusCode: null,
        errorCode: null,
      };
    case PUT_PRODUCT_SUCCESS:
      return {
        ...state,
        status: STATUS_PUT_SUCCESS,
        statusCode: payload.statusCode,
        errorCode: null,
      };
    case PUT_PRODUCT_FAILED:
      return {
        ...state,
        status: STATUS_PUT_FAILED,
        statusCode: payload.statusCode,
        errorMessage: payload.json,
        errorCode: payload.errorCode,
      };

    case RESET_PRODUCT:
      return { ...initialState };

    default:
      return state;
  }
};
