/* eslint-disable import/prefer-default-export */
import React from 'react';

export const Filter = (props) => (
  <svg viewBox="0 0 12 12" {...props}>
    <defs>
      <path
        id="prefix__a"
        d="M11.96.28a.447.447 0 0 0-.42-.28H.46C.27 0 .11.11.04.28c-.08.18-.04.38.1.51l4.29 4.29c.09.09.19.33.19.46v4.15c0 .12.05.24.14.33l1.85 1.85c.07.08.19.13.31.13.06 0 .12-.01.18-.04.17-.07.28-.24.28-.43v-6c0-.13.1-.37.19-.46L11.86.78c.14-.12.18-.32.1-.5z"
      />
    </defs>
    <use
      xlinkHref="#prefix__a"
      overflow="visible"
      fillRule="evenodd"
      clipRule="evenodd"
      fill="#fff"
    />
    <clipPath id="prefix__b">
      <use xlinkHref="#prefix__a" overflow="visible" />
    </clipPath>
    <path clipPath="url(#prefix__b)" fill="#fff" d="M-5-5h22v22H-5z" />
  </svg>
);
