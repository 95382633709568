/* eslint-disable import/prefer-default-export */
/* eslint-disable react/prop-types */
import React from 'react';
import { PropTypes } from 'prop-types';
import { useLocalizedFromContext } from 'data/Localization';

export const GradientCrop49 = ({ title, ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" {...props}>
    <title>{useLocalizedFromContext(title)}</title>
    <defs>
      <clipPath id="b">
        <rect width="50" height="50" />
      </clipPath>
    </defs>
    <g id="a" clipPath="url(#b)">
      <rect width="50" height="50" fill="#fff" />
      <g transform="translate(-10.022 -9.891)">
        <path
          d="M34.005,19.617v-2.58A4.175,4.175,0,0,1,32.127,15.9a4.212,4.212,0,0,1-.787.652,6.276,6.276,0,0,1,.967,3.022C32.862,19.6,33.43,19.611,34.005,19.617Z"
          fill="none"
        />
        <path
          d="M50.455,26.3a16.425,16.425,0,0,0-5.543-5.271.18.18,0,0,0-.141-.012,44.955,44.955,0,0,1-9.612.906h-.008c-.115,0-.229,0-.345,0-1.846,0-3.615-.072-5.216-.2a18.235,18.235,0,0,0-5.867,10.85c-.041.358-.067.717-.075,1.077a11.519,11.519,0,0,0,3.007,8.386c.6.685,1.229,1.289,1.818,1.854l.175.168a1.15,1.15,0,0,1-1.594,1.659l-.172-.166a24.572,24.572,0,0,1-2.714-2.931A13.812,13.812,0,0,1,21.349,33.6c0-.16.018-.319.027-.479a20.393,20.393,0,0,1,4.865-11.292c.134-.159.268-.313.4-.458-.55-.088-1.071-.183-1.532-.29a.181.181,0,0,0-.133.015,17.42,17.42,0,0,0-2.164,1.537,14.464,14.464,0,0,0-5.488,11.1c0,.113.015.224.018.336a14.089,14.089,0,0,0,3.865,9.245c.3.326.612.649.947.956a10.149,10.149,0,0,0,7.981,2.572q.39-.042.784-.077a46.349,46.349,0,0,1,5.83-.133c.024,0,.043-.013.067-.012a9.617,9.617,0,0,0,6.237-2.638,11.223,11.223,0,0,0,3.006-6.5,20.891,20.891,0,0,0,.227-3.138,12.114,12.114,0,0,0-3.258-8.876,3.518,3.518,0,0,1-.81-1.159,1.151,1.151,0,0,1,2.152-.811,5.393,5.393,0,0,0,.378.442,14.626,14.626,0,0,1,2.23,3.176,15.576,15.576,0,0,1,1.609,7.228,23.885,23.885,0,0,1-.149,2.58,13.87,13.87,0,0,1-3.8,8.724,10.924,10.924,0,0,1-1.086.928,9.973,9.973,0,0,0,4.3-2.307,14.543,14.543,0,0,0,4.623-8.291,13.45,13.45,0,0,0,.208-2.246A13.759,13.759,0,0,0,50.455,26.3ZM40.193,36.779c-.023.748-.073,1.442-.134,2.11-.287,3.1-.977,5.354-2.073,6.683a1.2,1.2,0,0,1-.921.431,1.14,1.14,0,0,1-.9-1.837l.048-.058a9.616,9.616,0,0,0,1.477-4.663c.1-.835.174-1.746.205-2.738a31.128,31.128,0,0,0-.765-7.282,41.469,41.469,0,0,0-1.413-5.08,1.15,1.15,0,0,1,2.157-.8,42.364,42.364,0,0,1,1.5,5.353A33.492,33.492,0,0,1,40.193,36.779Z"
          fill="none"
        />
        <path
          d="M32.191,48.985a4.689,4.689,0,0,1,.383,1.811A6.754,6.754,0,0,1,31.3,54.412a4.777,4.777,0,0,0-.625,1.221,9.446,9.446,0,0,0,3.159.084c.463-.036.869-.067,1.164-.067s.7.031,1.164.067a9.5,9.5,0,0,0,3.159-.083,4.817,4.817,0,0,0-.625-1.222,5.844,5.844,0,0,1-1.1-4.8,5.075,5.075,0,0,1,.207-.638A44.451,44.451,0,0,0,32.191,48.985Z"
          fill="none"
        />
        <path
          d="M38.007,19.546c.017-.178.048-.331.073-.495a6.015,6.015,0,0,1,.89-2.5,4.174,4.174,0,0,1-.787-.652,4.168,4.168,0,0,1-1.878,1.135v2.571C36.886,19.595,37.452,19.573,38.007,19.546Z"
          fill="none"
        />
        <path
          d="M43.434,18.956a4.739,4.739,0,0,1,.45-1.266,5.268,5.268,0,0,1,.916-1.28,4.284,4.284,0,0,1-.563-.505,4.155,4.155,0,0,1-2.428,1.253,1.083,1.083,0,0,1-.135.048c-.075.017-.7.207-1.094,1.259a4.478,4.478,0,0,0-.237.917C41.507,19.273,42.557,19.13,43.434,18.956Z"
          fill="none"
        />
        <path
          d="M29.976,19.441c-.281-1.993-1.3-2.226-1.345-2.236a.99.99,0,0,1-.111-.045A4.158,4.158,0,0,1,26.071,15.9a4.147,4.147,0,0,1-.664.577,4.949,4.949,0,0,1,1.314,2.575C27.665,19.215,28.768,19.346,29.976,19.441Z"
          fill="none"
        />
        <path
          d="M32.077,58.111c.656,0,1.325-.052,1.94-.1a10.414,10.414,0,0,1,1.966,0c1.717.136,3.849.3,4.967-.731a2.1,2.1,0,0,0,.68-1.578,4.906,4.906,0,0,0-.975-2.5c-.813-1.315-1.445-2.384-.315-4.035.226.012.453.037.678.037a12.43,12.43,0,0,0,6.714-1.966L37.6,49.616a5.844,5.844,0,0,0,1.1,4.8,4.817,4.817,0,0,1,.625,1.222,9.5,9.5,0,0,1-3.159.083C35.7,55.681,35.3,55.65,35,55.65s-.7.031-1.164.067a9.446,9.446,0,0,1-3.159-.084,4.777,4.777,0,0,1,.625-1.221A6.754,6.754,0,0,0,32.574,50.8l-2.354.552a5.9,5.9,0,0,1-.875,1.855,4.9,4.9,0,0,0-.974,2.5,2.1,2.1,0,0,0,.679,1.578A4.448,4.448,0,0,0,32.077,58.111Z"
          fill="#b95700"
        />
        <path
          d="M32.191,48.985a44.451,44.451,0,0,1,5.619-.007,5.075,5.075,0,0,0-.207.638l10.129-2.377a12.144,12.144,0,0,0,1.67-1.277A16.72,16.72,0,0,0,54.888,35.41l-2.419.567a14.543,14.543,0,0,1-4.623,8.291,9.973,9.973,0,0,1-4.3,2.307,10.924,10.924,0,0,0,1.086-.928,13.87,13.87,0,0,0,3.8-8.724l-2.379.558a11.223,11.223,0,0,1-3.006,6.5,9.617,9.617,0,0,1-6.237,2.638c-.024,0-.043.011-.067.012a46.349,46.349,0,0,0-5.83.133q-.394.034-.784.077a10.149,10.149,0,0,1-7.981-2.572c-.335-.307-.645-.63-.947-.956l-2.511.589a18.475,18.475,0,0,0,1.9,2.061,12.421,12.421,0,0,0,9.061,3.2,2.743,2.743,0,0,1,.562,2.182l2.354-.552A4.689,4.689,0,0,0,32.191,48.985Z"
          fill="#eb6909"
        />
        <path
          d="M26.882,45.548l.172.166a1.15,1.15,0,0,0,1.594-1.659l-.175-.168c-.589-.565-1.216-1.169-1.818-1.854l-2.487.584A24.572,24.572,0,0,0,26.882,45.548Z"
          fill="#eb6909"
        />
        <path
          d="M36.212,44.108l-.048.058a1.14,1.14,0,0,0,.9,1.837,1.2,1.2,0,0,0,.921-.431c1.1-1.329,1.786-3.582,2.073-6.683l-2.37.556A9.616,9.616,0,0,1,36.212,44.108Z"
          fill="#eb6909"
        />
        <path
          d="M52.677,33.731a13.45,13.45,0,0,1-.208,2.246l2.419-.567a15.775,15.775,0,0,0-2.078-9.664l-2.355.553A13.759,13.759,0,0,1,52.677,33.731Z"
          fill="#5f9501"
        />
        <path
          d="M46.977,27.115l-2.319.544a12.976,12.976,0,0,1,1.627,6.684,20.891,20.891,0,0,1-.227,3.138l2.379-.558a23.885,23.885,0,0,0,.149-2.58A15.576,15.576,0,0,0,46.977,27.115Z"
          fill="#5f9501"
        />
        <path
          d="M24.168,42.617l2.487-.584a11.519,11.519,0,0,1-3.007-8.386c.008-.36.034-.719.075-1.077l-2.347.551c-.009.16-.023.319-.027.479A13.812,13.812,0,0,0,24.168,42.617Z"
          fill="#5f9501"
        />
        <path
          d="M17.341,34.067l-2.272.533a16.236,16.236,0,0,0,3.626,9.3l2.511-.589A14.089,14.089,0,0,1,17.341,34.067Z"
          fill="#5f9501"
        />
        <path
          d="M37.894,36.707c-.031.992-.1,1.9-.205,2.738l2.37-.556c.061-.668.111-1.362.134-2.11a33.492,33.492,0,0,0-.816-7.881l-2.248.527A31.128,31.128,0,0,1,37.894,36.707Z"
          fill="#5f9501"
        />
        <path
          d="M44.747,23.939a5.393,5.393,0,0,1-.378-.442,1.151,1.151,0,0,0-2.152.811,3.518,3.518,0,0,0,.81,1.159,12.954,12.954,0,0,1,1.631,2.192l2.319-.544A14.626,14.626,0,0,0,44.747,23.939Z"
          fill="#79b61d"
        />
        <path
          d="M43.434,18.956c-.877.174-1.927.317-3.091.426a4.478,4.478,0,0,1,.237-.917l-2.5.586c-.025.164-.056.317-.073.495-.555.027-1.121.049-1.7.062v-.141L26.241,21.829a20.393,20.393,0,0,0-4.865,11.292l2.347-.551A18.235,18.235,0,0,1,29.59,21.72c1.6.133,3.37.2,5.216.2.116,0,.23,0,.345,0h.008a44.955,44.955,0,0,0,9.612-.906.18.18,0,0,1,.141.012A16.425,16.425,0,0,1,50.455,26.3l2.355-.553a18.578,18.578,0,0,0-6.731-6.7,2.212,2.212,0,0,0-.232-.107,3.81,3.81,0,0,1,1.852-1.8,1.139,1.139,0,0,0,.539-.466L43.884,17.69A4.739,4.739,0,0,0,43.434,18.956Z"
          fill="#79b61d"
        />
        <path
          d="M17.323,33.731a14.464,14.464,0,0,1,5.488-11.1l-4.177.98a16.251,16.251,0,0,0-3.612,10.118c0,.292.031.58.047.869l2.272-.533C17.338,33.955,17.323,33.844,17.323,33.731Z"
          fill="#79b61d"
        />
        <path
          d="M36.4,22.867a1.151,1.151,0,0,0-.679,1.478,41.469,41.469,0,0,1,1.413,5.08l2.248-.527a42.364,42.364,0,0,0-1.5-5.353A1.149,1.149,0,0,0,36.4,22.867Z"
          fill="#79b61d"
        />
        <path
          d="M24.975,21.1a.181.181,0,0,1,.133-.015c.461.107.982.2,1.532.29-.131.145-.265.3-.4.458l10.064-2.362v-2.43A4.168,4.168,0,0,0,38.183,15.9a4.174,4.174,0,0,0,.787.652,6.015,6.015,0,0,0-.89,2.5l2.5-.586c.393-1.052,1.019-1.242,1.094-1.259a1.083,1.083,0,0,0,.135-.048A4.155,4.155,0,0,0,44.237,15.9a4.284,4.284,0,0,0,.563.505,5.268,5.268,0,0,0-.916,1.28l4.354-1.022a1.137,1.137,0,0,0,.158-.819,1.15,1.15,0,0,0-1.129-.931,1.879,1.879,0,0,1-1.878-1.877,1.15,1.15,0,1,0-2.3,0,1.878,1.878,0,0,1-3.755,0,1.15,1.15,0,0,0-2.3,0,1.878,1.878,0,0,1-3.756,0,1.151,1.151,0,0,0-2.3,0,1.877,1.877,0,0,1-3.755,0,1.15,1.15,0,1,0-2.3,0,1.879,1.879,0,0,1-1.878,1.877c-.032,0-.058.016-.089.018a1.134,1.134,0,0,0-1.142.7,1.153,1.153,0,0,0,.622,1.5,3.825,3.825,0,0,1,1.845,1.773,2.451,2.451,0,0,0-.485.218,19.062,19.062,0,0,0-5.16,4.49l4.177-.98A17.42,17.42,0,0,1,24.975,21.1ZM32.127,15.9a4.175,4.175,0,0,0,1.878,1.134v2.58c-.575-.006-1.143-.019-1.7-.04a6.276,6.276,0,0,0-.967-3.022A4.212,4.212,0,0,0,32.127,15.9Zm-6.056,0A4.158,4.158,0,0,0,28.52,17.16a.99.99,0,0,0,.111.045c.049.01,1.064.243,1.345,2.236-1.208-.095-2.311-.226-3.255-.387a4.949,4.949,0,0,0-1.314-2.575A4.147,4.147,0,0,0,26.071,15.9Z"
          fill="#c8d200"
        />
      </g>
    </g>
  </svg>
);

GradientCrop49.defaultProps = {
  title: 'poppy',
};

GradientCrop49.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
};
