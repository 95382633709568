/* eslint-disable no-restricted-syntax */
/* eslint-disable consistent-return */
import { useEffect } from 'react';
import { updateMetro } from 'Redux/actions';
import { store } from 'Redux/createStore';

import organicFertilizerValid from 'Utils/OrganicFertilizerValidation';
import { countryBehaviour } from 'data/Behaviour';
import { temporaryPlotId, temporaryFarmId } from 'constants/index.js';
import { STATUS_DEFAULT } from 'Redux/actions/types';
import UserUtils from 'Utils/UserUtils';

export const planFlowTypes = {
  short: 'short',
  full: 'full',
  automaticPlanning: 'automatic_planning',
};

export const previousCropOptions = {
  coverCrop: 'coverCrop',
  previousCrop: 'previousCrop',
};

export const coverCropTypes = {
  incorporatedAutumnLow: 'incorporatedAutumnLow',
  incorporatedAutumnHigh: 'incorporatedAutumnHigh',
  incorporatedSpringLow: 'incorporatedSpringLow',
  incorporatedSpringHigh: 'incorporatedSpringHigh',
  unincorporatedLow: 'unincorporatedLow',
  unincorporatedHigh: 'unincorporatedHigh',
};

export const flattenComponentValuePair = (key, data) => {
  const result = {};
  result[`${key}_name`] = data.name;
  result[`${key}_value`] = data.value;
  result[`${key}_min`] = data.min;
  result[`${key}_max`] = data.max;
  result[`${key}_stepSize`] = data.stepSize;
  return result;
};

export const flattenIdNamePair = (key, data) => {
  if (!data) return;
  const result = {};
  result[`${key}_id`] = data.id;
  result[`${key}_localName`] = data.localName;
  return result;
};

export const flattenSoilComposition = (data) => {
  let result = {
    ...flattenIdNamePair('soilTexture', data.soilTexture),
    ...flattenIdNamePair('soilDepth', data.soilDepth),
    ...flattenIdNamePair('soilMoisture', data.soilMoisture),
    ...flattenIdNamePair('soilRockContent', data.soilRockContent),
    ...flattenIdNamePair('soilType', data.soilType),
  };

  // eslint-disable-next-line guard-for-in
  for (const index in data.components) {
    result = {
      ...result,
      ...flattenComponentValuePair(data.components[index].name, data.components[index]),
    };
  }
  return result;
};

export const flattenParamsInSoilComposition = (data) => {
  const result = {
    soilRockContent: data.rockContent.id,
    soilDepthID: data.depth.id,
    soilMoistureID: data.moisture.id,
  };

  if (data.components) {
    data.components.forEach((component) => {
      const key = `${component.component}_value`;
      const { value } = component;
      result[key] = value;
    });
  }
  return result;
};

export const removePercentage = (value) => {
  if (!value) {
    return value;
  }
  return value.replace(/%/g, '');
};

const convertFrontendToBackendNG3 = (plan, flow, role, lang) => {
  if (flow === planFlowTypes.short) {
    const planToReturn = {
      id: plan.id,
      languageCode: lang,
      isReseller: role === 'reseller',
      flow: planFlowTypes.short,
      farmersName: removePercentage(plan.farmersName),
      farmName: removePercentage(plan.farmName),
      farmId: plan.farmId === temporaryFarmId ? undefined : plan.farmId,
      plotName: removePercentage(plan.plotName),
      plotId: plan.plotId === temporaryPlotId ? undefined : plan.plotId,
      plotSize: parseFloat(plan.plotSize),
      country: plan.country,
      postalCode: plan.postalCode,
      city: plan.city,
      harvestingYear: plan.harvestingYear,
      numberOfApplicationSteps: plan.numberOfApplicationSteps,
      components: [
        {
          shortName: 'N',
          value: parseInt(plan.calculatedN, 10),
        },
        {
          shortName: 'P',
          value: parseInt(plan.calculatedP, 10),
        },
        {
          shortName: 'K',
          value: parseInt(plan.calculatedK, 10),
        },
        {
          shortName: 'S',
          value: parseInt(plan.calculatedS, 10),
        },
      ],
      crop: {
        id: plan.cropID,
        variety: {
          id: plan.cropVarietyID,
        },
        growthStage: {
          id: plan.cropGrowthStageID ? plan.cropGrowthStageID : null,
        },
      },
      yieldTarget: parseFloat(plan.yieldTarget),
    };
    return planToReturn;
  }

  if (flow === planFlowTypes.full) {
    const planToReturn = {
      id: plan.id,
      languageCode: lang,
      isReseller: role === 'reseller',
      flow: planFlowTypes.full,
      farmersName: removePercentage(plan.farmersName),
      farmName: removePercentage(plan.farmName),
      farmId: plan.farmId === temporaryFarmId ? undefined : plan.farmId,
      plotName: removePercentage(plan.plotName),
      plotId: plan.plotId === temporaryPlotId ? undefined : plan.plotId,
      plotSize: parseFloat(plan.plotSize),
      country: plan.country,
      postalCode: plan.postalCode,
      city: plan.city,
      harvestingYear: plan.harvestingYear,
      numberOfApplicationSteps: plan.numberOfApplicationSteps,
      crop: {
        id: plan.cropID,
        variety: {
          id: plan.cropVarietyID,
        },
        growthStage: {
          id: plan.cropGrowthStageID ? plan.cropGrowthStageID : null,
        },
      },
      yieldTarget: parseFloat(plan.yieldTarget),
      previousCrop: { id: parseInt(plan.previousCropID, 10) },
      previousPlotYield: parseFloat(plan.previousPlotYield),
      strawRemoved: plan.strawRemoved,
    };

    // don't send cover crop if there is none
    if (plan.coverCropID === 'none' || plan.coverCropID === '' || plan.coverCropID === null) {
      planToReturn.coverCrop = null;
    } else {
      planToReturn.coverCrop = {
        crop: { id: parseInt(plan.coverCropID, 10) },
        stageId: parseInt(plan.coverCropStageID, 10),
        coverCropDestroyedBeforeJanuary: plan.coverCropDestroyedBeforeJanuary,
      };
    }

    // only push up organic fertilizers if selected
    planToReturn.organicFertilizers = [];
    if (plan.usage_organic_fertilizer) {
      const filterFunc = (fertilizer) => organicFertilizerValid(fertilizer, plan.country);
      const mapFunc = (fertilizer) => ({
        id: parseInt(fertilizer.type, 10),
        period: parseInt(fertilizer.period, 10),
        lastApplied: fertilizer.lastApplied,
        quantity: parseInt(fertilizer.quantity, 10),
      });
      const organicFertilizers = plan.organicFertilizers.filter(filterFunc).map(mapFunc);

      if (organicFertilizers.length > 0) {
        planToReturn.organicFertilizers = organicFertilizers;
      }
    }

    // Default year to 0 if no fertilizers, or quantity is zero
    planToReturn.appliedN = plan.usage_mineral_fertilizer ? parseInt(plan.appliedN, 10) : 0;
    planToReturn.appliedP =
      plan.usage_mineral_fertilizer && parseInt(plan.appliedP, 10)
        ? parseInt(plan.appliedP, 10)
        : 0;
    planToReturn.appliedPYear =
      plan.usage_mineral_fertilizer && parseInt(plan.appliedPYear, 10) >= 0
        ? parseInt(plan.appliedPYear, 10)
        : 3;
    planToReturn.appliedK =
      plan.usage_mineral_fertilizer && parseInt(plan.appliedK, 10)
        ? parseInt(plan.appliedK, 10)
        : 0;
    planToReturn.appliedKYear =
      plan.usage_mineral_fertilizer && parseInt(plan.appliedKYear, 10) >= 0
        ? parseInt(plan.appliedKYear, 10)
        : 3;
    planToReturn.appliedC =
      plan.usage_mineral_fertilizer && parseInt(plan.appliedC, 10)
        ? parseInt(plan.appliedC, 10)
        : 0;
    planToReturn.appliedCYear =
      plan.usage_mineral_fertilizer && parseInt(plan.appliedCYear, 10) >= 0
        ? parseInt(plan.appliedCYear, 10)
        : 3;

    // build soil
    planToReturn.soil = {
      depth: { id: plan.soilDepthID },
      moisture: { id: plan.soilMoistureID },
      rockContent: { id: plan.soilRockContent },
    };

    // for france: build soil composition object
    if (plan.country === 'FR') {
      planToReturn.soil.type = { id: parseInt(plan.soilTypeID, 10) };
      planToReturn.soil.workYourSoil = plan.workYourSoil;
      planToReturn.soil.components = [
        {
          component: 'organicMatter',
          value: parseFloat(plan.organicMatter_value),
        },
        {
          component: 'PH',
          value: parseFloat(plan.PH_value),
        },
        {
          component: 'P',
          value: parseFloat(plan.P_value),
        },
        {
          component: 'K',
          value: parseFloat(plan.K_value),
        },
        {
          component: 'M',
          value: parseFloat(plan.M_value),
        },
        {
          component: 'C',
          value: parseFloat(plan.C_value),
        },
        {
          component: 'Cu',
          value: parseFloat(plan.Cu_value),
        },
        {
          component: 'Zn',
          value: parseFloat(plan.Zn_value),
        },
        {
          component: 'Fe',
          value: parseFloat(plan.Fe_value),
        },
        {
          component: 'Mn',
          value: parseFloat(plan.Mn_value),
        },
        {
          component: 'B',
          value: parseFloat(plan.B_value),
        },
      ];
      planToReturn.grassBeforeArable = plan.grassBeforeArable;
      planToReturn.howManyYearsGrassland = parseInt(plan.howManyYearsGrassland, 10);
      planToReturn.grassMixClover = plan.grassMixClover;
      planToReturn.regularlyMowed = plan.regularlyMowed;
      planToReturn.animalsGrazing = plan.animalsGrazing;
      planToReturn.whenIntoArable = parseInt(plan.whenIntoArable, 10);
      planToReturn.NMineralization = parseInt(plan.NMineralization, 10);
    }

    const { country } = plan;
    if (countryBehaviour(country, 'soil_heaviness')) planToReturn.soil.heaviness = { id: plan.soilHeavinessID };
    if (countryBehaviour(country, 'soil_organic_matter_level')) planToReturn.soil.organicMatterLevel = { id: plan.soilOrganicMatterLevelId };

    if (countryBehaviour(country, 'soil_analysis')) {
      if (plan.soil_analysis === true) {
        planToReturn.soilClassPotassium = plan.soilClassPotassium;
        planToReturn.soilClassPhosphorus = plan.soilClassPhosphorus;
      }
    }
    return planToReturn;
  }

  return null;
};

const convertFrontendToBackendNG4 = (plan, flow) => {
  if (flow === planFlowTypes.automaticPlanning) {
    return {
      id: plan.id,
      locale: UserUtils.getLocale(),
      flow: planFlowTypes.automaticPlanning,
      farmersName: plan.farmersName,
      farmId: plan.farmId === temporaryFarmId ? undefined : plan.farmId,
      farmName: plan.farmName,
      plotName: plan.plotName,
      plotId: plan.plotId === temporaryPlotId ? undefined : plan.plotId,
      plotSize: plan.plotSize,
      postalCode: plan.postalCode,
      coordinates: plan.coordinates,
      city: plan.city,
      cropName: plan.cropName,
      cropId: plan.cropId,
      soil: {
        soilFactorP: parseFloat(plan.soil.soilFactorP),
        soilFactorK: parseFloat(plan.soil.soilFactorK),
        nMin: parseFloat(plan.soil.nMin),
        nMineralization: parseFloat(plan.soil.nMineralization),
        hasHumusEffect: plan.soil.hasHumusEffect,
        isRedArea: plan.soil.isRedArea,
      },
    };
  }
  if (flow === planFlowTypes.short) {
    return {
      id: plan.id,
      locale: UserUtils.getLocale(),
      flow: planFlowTypes.short,
      farmersName: plan.farmersName,
      farmId: plan.farmId === temporaryFarmId ? undefined : plan.farmId,
      farmName: plan.farmName,
      plotName: plan.plotName,
      plotId: plan.plotId === temporaryPlotId ? undefined : plan.plotId,
      plotSize: plan.plotSize,
      postalCode: plan.postalCode,
      coordinates: plan.coordinates,
      city: plan.city,
      cropName: plan.cropName,
      cropId: plan.cropId,
      soil: {
        soilFactorP: parseFloat(plan.soil.soilFactorP),
        soilFactorK: parseFloat(plan.soil.soilFactorK),
        nMin: parseFloat(plan.soil.nMin),
        nMineralization: parseFloat(plan.soil.nMineralization),
        hasHumusEffect: plan.soil.hasHumusEffect,
        isRedArea: plan.soil.isRedArea,
      },
      components: [
        {
          shortName: 'N',
          name: 'N',
          cropNeed: 0,
          fertilizationNeed: plan.calculatedN,
          recommendedAmount: 0,
          unit: 'kg/ha',
        },
        {
          shortName: 'P',
          name: 'P2O5',
          cropNeed: 0,
          fertilizationNeed: plan.calculatedP,
          recommendedAmount: 0,
          unit: 'kg/ha',
        },
        {
          shortName: 'K',
          name: 'K2O',
          cropNeed: 0,
          fertilizationNeed: plan.calculatedK,
          recommendedAmount: 0,
          unit: 'kg/ha',
        },
        {
          shortName: 'S',
          name: 'SO3',
          cropNeed: 0,
          fertilizationNeed: plan.calculatedS,
          recommendedAmount: 0,
          unit: 'kg/ha',
        },
      ],
    };
  }
  const longFlowPlan = {
    id: plan.id,
    locale: UserUtils.getLocale(),
    flow: planFlowTypes.full,
    farmersName: plan.farmersName,
    farmId: plan.farmId === temporaryFarmId ? undefined : plan.farmId,
    farmName: plan.farmName,
    plotName: plan.plotName,
    plotId: plan.plotId === temporaryPlotId ? undefined : plan.plotId,
    plotSize: plan.plotSize,
    coordinates: plan.coordinates,
    postalCode: plan.postalCode,
    city: plan.city,
    cropId: plan.cropId,
    hasCoverCropImpact: plan.hasCoverCrop === true && plan.hasCoverCropRemoved === false,
    previousCropId:
      plan.previousCrop === previousCropOptions.coverCrop ? undefined : plan.previousCropId,
    legumeContent: plan.legumeContent,
    yieldTarget: plan.yieldTarget,
    hasOrganicFertilizer: false,
    hasOrganicFertPreviousYear: false,
    nLegalMaximum: plan.nLegalMaximum,
    coverCropType: '',
    soil: {
      soilFactorP: parseFloat(plan.soil.soilFactorP),
      soilFactorK: parseFloat(plan.soil.soilFactorK),
      nMin: parseFloat(plan.soil.nMin),
      nMineralization: parseFloat(plan.soil.nMineralization),
      hasHumusEffect: plan.soil.hasHumusEffect,
      isRedArea: plan.soil.isRedArea,
    },
  };
  if (plan.strawsRemoved !== undefined) {
    longFlowPlan.strawsRemoved = plan.strawsRemoved;
  }
  if (plan.hasOrganicFertilizer) {
    longFlowPlan.hasOrganicFertilizer = true;
    longFlowPlan.organicFertQuantity = plan.organicFertQuantity;
    longFlowPlan.organicFertilizerName = plan.organicFertilizerName;
    longFlowPlan.organicFertId = plan.organicFertId;
  }
  if (plan.hasMineralFertilization) {
    longFlowPlan.previousMineralFertilization = {
      N: plan.previousMineralFertilization_N,
      P: plan.previousMineralFertilization_P,
      K: plan.previousMineralFertilization_K,
    };
  }
  if (plan.reduceToMaximum) {
    longFlowPlan.reduceToMaximum = true;
    longFlowPlan.nLegalMaximum = plan.nLegalMaximum;
  }

  if (plan.hasOrganicFertPreviousYear) {
    longFlowPlan.hasOrganicFertPreviousYear = true;
    longFlowPlan.organicFertQuantityPreviousYear = plan.organicFertQuantityPreviousYear;
    longFlowPlan.organicFertIdPreviousYear = plan.organicFertIdPreviousYear;
  }

  switch (true) {
    case plan.hasCoverCrop === true && !plan.isIncorporated && !plan.moreThanSeventyFive:
      longFlowPlan.coverCropType = coverCropTypes.unincorporatedLow;
      break;
    case plan.hasCoverCrop === true && !plan.isIncorporated && plan.moreThanSeventyFive:
      longFlowPlan.coverCropType = coverCropTypes.unincorporatedHigh;
      break;
    case plan.hasCoverCrop === true &&
      plan.isIncorporated &&
      plan.incorporatedAutumnOrFrozen &&
      plan.moreThanSeventyFive:
      longFlowPlan.coverCropType = coverCropTypes.incorporatedAutumnHigh;
      break;
    case plan.hasCoverCrop === true &&
      plan.isIncorporated &&
      plan.incorporatedAutumnOrFrozen &&
      !plan.moreThanSeventyFive:
      longFlowPlan.coverCropType = coverCropTypes.incorporatedAutumnLow;
      break;
    case plan.hasCoverCrop === true &&
      plan.isIncorporated &&
      !plan.incorporatedAutumnOrFrozen &&
      plan.moreThanSeventyFive:
      longFlowPlan.coverCropType = coverCropTypes.incorporatedSpringHigh;
      break;
    case plan.hasCoverCrop === true &&
      plan.isIncorporated &&
      !plan.incorporatedAutumnOrFrozen &&
      !plan.moreThanSeventyFive:
      longFlowPlan.coverCropType = coverCropTypes.incorporatedSpringLow;
      break;
    default:
      break;
  }

  return longFlowPlan;
};

export const convertFrontendToBackend = (plan, flow, role, lang) => {
  if (UserUtils.isNG4()) {
    return convertFrontendToBackendNG4(plan, flow, role, lang);
  }
  return convertFrontendToBackendNG3(plan, flow, role, lang);
};

export const convertFrontendToBackendForBulkAction = (
  plan,
  flow,
  role,
  lang,
  plotIds,
  myPlotsData,
) => {
  const plans = [];
  plotIds.forEach((plotId) => {
    const plot = myPlotsData.find(({ id }) => id === plotId);
    if (plot) {
      const convertedPlan = convertFrontendToBackend(
        {
          ...plan,
          plotId,
          soil: plot.soil,
        },
        flow,
        role,
        lang,
      );
      plans.push(convertedPlan);
    }
  });
  return plans;
};

export const convertBackendToFrontend = (plan) => {
  const { paramsIn, paramsOut } = plan;
  let planToReturn = {};
  if (paramsIn.flow === planFlowTypes.short) {
    planToReturn = {
      id: paramsOut.id,
      flow: planFlowTypes.short,
      farmersName: paramsIn.farmersName,
      farmName: paramsIn.farmName,
      farmId: paramsOut.plot.farm.id,
      plotName: paramsIn.plotName,
      plotId: paramsOut.plot.id,
      plotSize: paramsIn.plotSize,
      country: paramsIn.country,
      postalCode: paramsIn.postalCode,
      city: paramsIn.city,
      cropID: paramsIn.crop.id,
      cropVarietyID: paramsIn.crop.variety.id,
      cropGrowthStageID: paramsIn.crop.growthStage.id,
      harvestingYear: paramsIn.harvestingYear,
      optimizedApplicationSplit: !paramsIn.numberOfApplicationSteps,
      numberOfApplicationSteps: paramsIn.numberOfApplicationSteps,
      yieldTarget: paramsIn.yieldTarget,
    };
    paramsIn.components.forEach((element) => {
      planToReturn[`calculated${element.shortName}`] = `${element.value}`;
    });
    return planToReturn;
  }

  if (paramsIn.flow === planFlowTypes.full) {
    const usage_mineral_fertilizer =
      paramsIn.appliedN > 0 ||
      paramsIn.appliedP > 0 ||
      paramsIn.appliedK > 0 ||
      paramsIn.appliedS > 0;

    const soil_analysis = paramsIn.soilClassPhosphorus && paramsIn.soilClassPhosphorus !== '';
    planToReturn = {
      id: paramsOut.id,
      flow: planFlowTypes.full,
      farmersName: paramsIn.farmersName,
      farmName: paramsIn.farmName,
      farmId: paramsOut.plot.farm.id,
      plotName: paramsIn.plotName,
      plotId: paramsOut.plot.id,
      plotSize: paramsIn.plotSize,
      country: paramsIn.country,
      postalCode: paramsIn.postalCode,
      city: paramsIn.city,
      cropID: paramsIn.crop.id,
      cropVarietyID: paramsIn.crop.variety.id,
      cropGrowthStageID: paramsIn.crop.growthStage.id,
      grassBeforeArable: Boolean(paramsIn.grassBeforeArable),
      howManyYearsGrassland: paramsIn.howManyYearsGrassland,
      whenIntoArable: paramsIn.whenIntoArable,
      grassMixClover: Boolean(paramsIn.grassMixClover),
      regularlyMowed: Boolean(paramsIn.regularlyMowed),
      animalsGrazing: Boolean(paramsIn.animalsGrazing),
      yieldTarget: paramsIn.yieldTarget,
      NMineralization: paramsIn.NMineralization,
      previousCropID: paramsIn.previousCrop.id,
      previousPlotYield: `${paramsIn.previousPlotYield}`,
      strawRemoved: Boolean(paramsIn.strawRemoved),
      usage_mineral_fertilizer,
      appliedN: `${paramsIn.appliedN}`,
      appliedP: `${paramsIn.appliedP}`,
      appliedPYear: paramsIn.appliedPYear,
      appliedK: `${paramsIn.appliedK}`,
      appliedKYear: paramsIn.appliedKYear,
      appliedC: `${paramsIn.appliedC}`,
      appliedCYear: paramsIn.appliedCYear,
      soilDepthID: paramsIn.soil.depth.id,
      soilMoistureID: paramsIn.soil.moisture.id,
      soilRockContent: paramsIn.rockContent,
      soil_analysis,
      soilClassPhosphorus: paramsIn.soilClassPhosphorus,
      soilClassPotassium: paramsIn.soilClassPotassium,
      soilOrganicMatterLevelId: paramsIn.soil.organicMatterLevel.id,
      workYourSoil: paramsIn.soil.workYourSoil,
      harvestingYear: paramsIn.harvestingYear,
      optimizedApplicationSplit: !paramsIn.numberOfApplicationSteps,
      numberOfApplicationSteps: paramsIn.numberOfApplicationSteps,
    };

    if (paramsIn.soil.type) {
      planToReturn.soilTypeID = paramsIn.soil.type.id;
    }
    if (paramsIn.soil.heaviness) {
      planToReturn.soilHeavinessID = paramsIn.soil.heaviness.id;
    }

    planToReturn = { ...planToReturn, ...flattenParamsInSoilComposition(paramsIn.soil) };

    if (!paramsIn.coverCrop) {
      planToReturn.coverCropID = 'none'; // Used as we can't handle nulls in MUI text fields
    } else if (paramsIn.coverCrop) {
      planToReturn.coverCropID = paramsIn.coverCrop.crop.id;
      planToReturn.coverCropStageID = `${paramsIn.coverCrop.stageId}`;
      planToReturn.coverCropDestroyedBeforeJanuary =
        paramsIn.coverCrop.coverCropDestroyedBeforeJanuary;
    }

    if (paramsIn.organicFertilizers.length > 0) {
      planToReturn.usage_organic_fertilizer = true;
      planToReturn.organicFertilizers = paramsIn.organicFertilizers.map((fert) => ({
        type: fert.id,
        period: fert.period,
        lastApplied: fert.lastApplied,
        quantity: fert.quantity,
      }));
    } else {
      planToReturn.usage_organic_fertilizer = false;
    }
    return planToReturn;
  }
};

const getValueForComponentFromComponents = (components, component) => {
  const { fertilizationNeed } = components.find(({ shortName }) => shortName === component);
  return fertilizationNeed;
};

export const convertBackendToFrontendNG4 = (plan) => {
  const { flow } = plan;
  let planToReturn = {};

  if (flow === planFlowTypes.short) {
    planToReturn = {
      id: plan.id,
      flow,
      farmersName: plan.plot.farm.farmerName,
      farmName: plan.plot.farm.name,
      country: plan.country,
      farmId: plan.plot.farm.id,
      plotName: plan.plot.name,
      plotId: plan.plot.id,
      plotSize: plan.plot.size,
      postalCode: plan.plot.postalCode,
      coordinates: plan.plot.coordinates,
      city: plan.plot.city,
      cropName: plan.cropName,
      cropId: plan.cropId,
      soil: {
        soilFactorP: plan.plot.soil.soilFactorP,
        soilFactorK: plan.plot.soil.soilFactorK,
        nMineralization: plan.plot.soil.nMineralization,
        nMin: plan.plot.soil.nMin,
        hasHumusEffect: plan.plot.soil.hasHumusEffect,
        isRedArea: plan.plot.soil.isRedArea,
      },

      calculatedN: getValueForComponentFromComponents(plan.components, 'N'),
      calculatedP: getValueForComponentFromComponents(plan.components, 'P'),
      calculatedK: getValueForComponentFromComponents(plan.components, 'K'),
      calculatedS: getValueForComponentFromComponents(plan.components, 'S'),

      stepCompleted: plan.stepCompleted,
    };

    return planToReturn;
  }

  if (flow === planFlowTypes.full) {
    planToReturn = {
      id: plan.id,
      flow,
      farmersName: plan.plot.farm.farmerName,
      farmName: plan.plot.farm.name,
      country: plan.country,
      farmId: plan.plot.farm.id,
      plotName: plan.plot.name,
      plotId: plan.plot.id,
      plotSize: plan.plot.size,
      postalCode: plan.plot.postalCode,
      coordinates: plan.plot.coordinates,
      city: plan.plot.city,
      cropName: plan.cropName,
      cropId: plan.cropId,
      soil: {
        soilFactorP: plan.plot.soil.soilFactorP,
        soilFactorK: plan.plot.soil.soilFactorK,
        nMineralization: plan.plot.soil.nMineralization,
        nMin: plan.plot.soil.nMin,
        hasHumusEffect: plan.plot.soil.hasHumusEffect,
        isRedArea: plan.plot.soil.isRedArea,
      },

      yieldTarget: plan.yieldTarget,
      previousCropId: plan.previousCropId,

      hasCoverCrop: plan.hasCoverCropImpact,
      hasCoverCropRemoved: plan.hasCoverCropImpact && !plan.legumeContent,
      legumeContent: plan.legumeContent,
      hasOrganicFertilizer: false,
      hasOrganicFertPreviousYear: false,
      hasMineralFertilization: false,
      reduceToMaximum: false,
      nLegalMaximum: plan.nLegalMaximum,
    };

    if (planToReturn.previousCropId) {
      planToReturn.previousCrop = planToReturn.previousCropId
        ? previousCropOptions.previousCrop
        : previousCropOptions.coverCrop;
    }

    if (plan.strawsRemoved !== undefined) {
      planToReturn.strawsRemoved = plan.strawsRemoved;
    }

    if (
      plan.previousMineralFertilization &&
      (plan.previousMineralFertilization.N !== 0 ||
        plan.previousMineralFertilization.P !== 0 ||
        plan.previousMineralFertilization.K !== 0)
    ) {
      planToReturn.hasMineralFertilization = true;
      planToReturn.previousMineralFertilization_N = plan.previousMineralFertilization.N;
      planToReturn.previousMineralFertilization_P = plan.previousMineralFertilization.P;
      planToReturn.previousMineralFertilization_K = plan.previousMineralFertilization.K;
    }

    if (plan.organicFertilizers !== undefined) {
      planToReturn.hasOrganicFertilizer = true;
      planToReturn.organicFertQuantity = plan.organicFertilizers.quantity;
      planToReturn.organicFertId = plan.organicFertilizers.organicFertilizerId;
    }

    if (plan.reduceToMaximum === true) {
      planToReturn.reduceToMaximum = true;
      planToReturn.nLegalMaximum = plan.nLegalMaximum;
    }

    if (plan.previousYearOrganicFertilizer !== undefined) {
      planToReturn.hasOrganicFertPreviousYear = true;
      planToReturn.organicFertQuantityPreviousYear = plan.previousYearOrganicFertilizer.quantity;
      planToReturn.organicFertIdPreviousYear =
        plan.previousYearOrganicFertilizer.organicFertilizerId;
    }

    switch (plan.coverCropType) {
      case coverCropTypes.unincorporatedLow:
        planToReturn.isIncorporated = false;
        planToReturn.moreThanSeventyFive = false;
        planToReturn.previousCrop = previousCropOptions.coverCrop;
        break;
      case coverCropTypes.unincorporatedHigh:
        planToReturn.isIncorporated = false;
        planToReturn.moreThanSeventyFive = true;
        planToReturn.previousCrop = previousCropOptions.coverCrop;
        break;
      case coverCropTypes.incorporatedAutumnLow:
        planToReturn.isIncorporated = true;
        planToReturn.incorporatedAutumnOrFrozen = true;
        planToReturn.moreThanSeventyFive = false;
        planToReturn.previousCrop = previousCropOptions.coverCrop;
        break;
      case coverCropTypes.incorporatedAutumnHigh:
        planToReturn.isIncorporated = true;
        planToReturn.incorporatedAutumnOrFrozen = true;
        planToReturn.moreThanSeventyFive = true;
        planToReturn.previousCrop = previousCropOptions.coverCrop;
        break;
      case coverCropTypes.incorporatedSpringLow:
        planToReturn.isIncorporated = true;
        planToReturn.incorporatedAutumnOrFrozen = false;
        planToReturn.moreThanSeventyFive = false;
        planToReturn.previousCrop = previousCropOptions.coverCrop;
        break;
      case coverCropTypes.incorporatedSpringHigh:
        planToReturn.isIncorporated = true;
        planToReturn.incorporatedAutumnOrFrozen = false;
        planToReturn.moreThanSeventyFive = true;
        planToReturn.previousCrop = previousCropOptions.coverCrop;
        break;
      default:
        planToReturn.isIncorporated = true;
        planToReturn.incorporatedAutumnOrFrozen = true;
        planToReturn.moreThanSeventyFive = false;
        break;
    }
    return planToReturn;
  }
};

export const useMetroUpdater = (step) => {
  useEffect(() => {
    store.dispatch(updateMetro(step));
  }, [step]);
};

export const getInitialPlanState = (state) => {
  if (UserUtils.isNG4(state)) {
    return {
      status: STATUS_DEFAULT,
      statusCode: null,
      errorCode: null,
      farmersName: '',
      farmName: '',
      plotName: '',
      plotId: '',
      plotSize: '',
      postalCode: '',
      city: '',
      cropName: '',
      cropId: '',
      soil: countryBehaviour(state.country, 'empty_soil'),
      calculatedN: 0,
      calculatedP: 0,
      calculatedK: 0,
      calculatedS: 0,
      stepCompleted: 0,
      previousCropId: null,

      hasCoverCrop: false,
      hasCoverCropRemoved: true,
      strawsRemoved: false,
      isIncorporated: true,
      incorporatedAutumnOrFrozen: true,
      moreThanSeventyFive: false,

      previousCrop: previousCropOptions.previousCrop,

      legumeContent: '0-25%',
      yieldTarget: 0,
      hasOrganicFertilizer: false,
      hasOrganicFertPreviousYear: false,
      organicFertQuantityPreviousYear: 0,
      organicFertIdPreviousYear: '',
      organicFertQuantity: 0,
      organicFertId: '',
      coverCropType: '',
      hasMineralFertilization: false,
      previousMineralFertilization_N: 0,
      previousMineralFertilization_P: 0,
      previousMineralFertilization_K: 0,
      reduceToMaximum: false,
      nLegalMaximum: 170,

      selectedPlotIds: [],
    };
  }

  let initialState = {
    status: STATUS_DEFAULT,
    statusCode: null,
    errorCode: null,
    farmersName: '',
    farmId: null,
    plotName: '',
    plotId: null,
    farmName: '',
    plotSize: '',
    postalCode: '',
    country: '',
    city: '',
    grassBeforeArable: false,
    howManyYearsGrassland: 1,
    whenIntoArable: 1,
    grassMixClover: false,
    regularlyMowed: false,
    animalsGrazing: false,
    cropID: '',
    cropVarietyID: '',
    yieldTarget: 0,
    cropGrowthStageID: '',
    NMineralization: 0,
    previousCropID: null,
    previousPlotYield: 0,
    strawRemoved: false,
    coverCropID: '',
    coverCropStageID: '1',
    coverCropDestroyedBeforeJanuary: 1,
    usage_organic_fertilizer: false,
    organicFertilizers: [
      {
        type: '',
        period: 1,
        lastApplied: 1,
        quantity: 0,
      },
    ],
    usage_mineral_fertilizer: false,
    appliedN: 0,
    appliedP: 0,
    appliedPYear: '',
    appliedK: 0,
    appliedKYear: '',
    appliedC: 0,
    appliedCYear: '',
    soilTypeID: '',
    soilHeavinessID: 'light',
    soilDepthID: '',
    soilMoistureID: 1,
    soilRockContent: 0,
    soil_analysis: false,
    soilClassPhosphorus: '',
    soilClassPotassium: '',
    workYourSoil: 'workYourSoil_plowed',
    soilOrganicMatterLevelId: 1,
    calculatedN: 0,
    calculatedP: 0,
    calculatedK: 0,
    calculatedS: 0,
    stepCompleted: 0,
    harvestingYear: [],
    optimizedApplicationSplit: true,
    numberOfApplicationSteps: null,
  };
  const appliedElementYear = countryBehaviour(state.country, 'applied_element_year');
  initialState = {
    ...initialState,
    soilDepthID: countryBehaviour(state.country, 'soil_depth_id'),
    appliedPYear: appliedElementYear,
    appliedKYear: appliedElementYear,
    appliedCYear: appliedElementYear,
  };

  return initialState;
};
