import { unionBy } from 'lodash';

export const organicFertilizerUnits = ['m3', 't'];

export const defaultOrganicFertilizer = {
  generalName: '',
  name: '',
  nTotal: 0,
  nAvailable: 0,
  P: 0,
  K: 0,
  unit: organicFertilizerUnits[0],
};

class OrganicFertilizerService {
  static isValid(organicFertilizer) {
    return organicFertilizer.name !== '';
  }

  static getMergedOrganicFertilizerList(organicFertilizer, organicFertilizerList) {
    return unionBy([organicFertilizer], [...organicFertilizerList], 'id');
  }

  static getOrganicFertilizerListWithout(organicFertilizerId, organicFertilizers) {
    return organicFertilizers.filter(({ id }) => id !== organicFertilizerId);
  }

  static convertOrcFertilizerToBackend(organicFertilizer) {
    return {
      ...organicFertilizer,
      nTotal: parseFloat(organicFertilizer.nTotal),
      nAvailable: parseFloat(organicFertilizer.nAvailable),
      P: parseFloat(organicFertilizer.P),
      K: parseFloat(organicFertilizer.K),
    };
  }
}

export default OrganicFertilizerService;
