import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  Grid,
  Button,
  Checkbox,
  FormControlLabel,
  LinearProgress,
  TextField,
} from '@material-ui/core';
import SanitizedHTML from 'react-sanitized-html';
import ReactPlaceholder from 'react-placeholder';
import { STATUS_GET_FAILED, STATUS_GET_SUCCESS, STATUS_GETTING } from 'Redux/actions/types';
import Dialogue from 'components/Molecules/Dialogue';
import { localized } from 'data/Localization';
import { updateUser, getTerms } from 'Redux/actions';
import Header from 'components/Molecules/Header/Header';
import ErrorMessageHelper from 'helpers/ErrorMessageHelper';
import 'react-placeholder/lib/reactPlaceholder.css';
import AvatarSmall from 'components/Icons/AvatarSmall';

class NG4TermsAndConditions extends Component {
  static propTypes = {
    lang: PropTypes.string,
    country: PropTypes.string,
    terms: PropTypes.object,
    user: PropTypes.object,
    location: PropTypes.object,
    termsAccepted: PropTypes.bool,
    acceptCommunication: PropTypes.bool,
    nextStep: PropTypes.func,
    getTerms: PropTypes.func,
    nextLabel: PropTypes.string,
    acceptCommunicationEnabled: PropTypes.bool,
    isUpdate: PropTypes.bool,
    updateUser: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      isTermsBottomReached: false,
      acceptCommunication: props.acceptCommunication,
      termsAccepted: props.termsAccepted,
    };
  }

  componentDidMount() {
    const { terms, user, updateUser } = this.props;
    const { lang, country } = user;
    updateUser({ role: 'farmer' });
    if (lang && country !== '' && terms.data === null && terms.status !== STATUS_GETTING) {
      this.handleGetTerms();
    }
  }

  componentDidUpdate(prevProps) {
    const { user, terms } = this.props;
    const { lang, country } = user;
    if (prevProps.terms.status !== terms.status) {
      this.termsStatusChanged();
    }
    if (lang && country !== '' && country !== prevProps.user.country) {
      this.handleGetTerms();
    }
  }

  termsStatusChanged = () => {
    const { terms, user, getTerms } = this.props;
    switch (terms.status) {
      case STATUS_GET_FAILED:
        this.setState({
          dialogue: {
            id: 'dialogue-get-data-failed',
            text1: localized(user.locale, 'alert_failed_webservice_1'),
            text2: ErrorMessageHelper.getErrorFromCode(terms.errorCode, user.locale),
            buttons: [
              {
                id: 'dialogue-get-data-failed-button-retry',
                handler: () => {
                  getTerms({ locale: user.locale });
                  this.setState({ dialogue: undefined });
                },
                color: 'primary',
                title: localized(user.locale, 'retry'),
              },
            ],
          },
        });
        break;
      default:
        break;
    }
  };

  handleGetTerms() {
    const { user, getTerms } = this.props;
    getTerms({ locale: user.locale });
  }

  render() {
    const {
      user,
      nextStep,
      terms,
      nextLabel,
      acceptCommunicationEnabled = true,
      isUpdate = false,
      updateUser,
    } = this.props;
    const { dialogue } = this.state;
    const { locale, firstName, lastName } = user;
    const { isTermsBottomReached, termsAccepted, acceptCommunication } = this.state;
    const classLabelCheckbox = isTermsBottomReached ? { label: 'color-primary' } : null;
    let isReadOnly = !user.isOnboarding && user.termsCompliant;
    if (user.isOnboarding === undefined) isReadOnly = true;
    const title = isUpdate
      ? localized(locale, 'terms_conditions_update')
      : localized(locale, 'terms_conditions');
    const linearProgress =
      !terms.data && terms.status === STATUS_GETTING ? (
        <LinearProgress className="linear-progress--in-container" variant="query" color="primary" />
      ) : null;

    const firstNameInvalid = !firstName || `${firstName}`.trim() === '';
    const lastNameInvalid = !lastName || `${lastName}`.trim() === '';

    return (
      <>
        {dialogue ? <Dialogue {...dialogue} /> : null}
        <Header title={title} showHamburger={!isUpdate} />
        <Grid container className="grid-container" direction="column" alignItems="center">
          <Grid className="terms__content--NG4--text-fields" item xs={12} sm={9} md={5}>
            <div className="form__row__picto-field">
              <AvatarSmall />
              <TextField
                error={false}
                helperText=""
                className="form__field"
                color="secondary"
                margin="dense"
                id="user_firstname"
                label={localized(locale, 'first_name')}
                value={firstName}
                onChange={(event) => {
                  updateUser({ firstName: event.target.value });
                }}
                autoComplete="off"
              />
            </div>
            <div className="form__row__picto-field">
              <AvatarSmall />
              <TextField
                error={false}
                helperText=""
                className="form__field"
                id="user_lastname"
                margin="dense"
                label={localized(locale, 'last_name')}
                value={lastName}
                onChange={(event) => {
                  updateUser({ lastName: event.target.value });
                }}
                autoComplete="off"
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={9} md={5}>
            <div className="no-style--flex-wrapper">
              {!isReadOnly ? <p>{localized(locale, 'i_understand_that')}</p> : null}
              <div
                id="js-scroll-tracking"
                className={
                  !isReadOnly
                    ? 'terms-conditions__wrapper'
                    : 'terms-conditions__wrapper terms-conditions__wrapper--read'
                }
              >
                {linearProgress}
                <ReactPlaceholder
                  className="margin-top--sixteen"
                  color="#F8F8F8"
                  type="text"
                  rows={3}
                  ready={terms && terms.data !== undefined}
                >
                  <>
                    <SanitizedHTML html={terms && terms.data ? terms.data.description : ''} />
                  </>
                </ReactPlaceholder>
              </div>
              {!isReadOnly ? (
                <p className="margin--zero">
                  <FormControlLabel
                    classes={classLabelCheckbox}
                    disabled={terms.status !== STATUS_GET_SUCCESS}
                    control={
                      <Checkbox
                        id="terms_checkbox"
                        checked={termsAccepted}
                        onChange={() => this.setState({ termsAccepted: !termsAccepted })}
                        value={`${termsAccepted}`}
                        color="primary"
                      />
                    }
                    label={localized(locale, 'read_accept_terms')}
                  />
                </p>
              ) : null}
              {!isReadOnly && acceptCommunicationEnabled ? (
                <p className="margin--zero">
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="marketing_checkbox"
                        checked={acceptCommunication}
                        onChange={() =>
                          this.setState({ acceptCommunication: !acceptCommunication })
                        }
                        value={`${acceptCommunication}`}
                        color="primary"
                      />
                    }
                    label={localized(locale, 'accept_communication')}
                  />
                </p>
              ) : null}
            </div>
            {!isReadOnly ? (
              <Grid className="body__full-h__buttons" item xs={12} sm={8} md={6}>
                <Button
                  id="terms-validation"
                  className="full-width"
                  variant="contained"
                  color="primary"
                  disabled={
                    !termsAccepted ||
                    terms.status !== STATUS_GET_SUCCESS ||
                    firstNameInvalid ||
                    lastNameInvalid
                  }
                  onClick={() =>
                    termsAccepted && terms.status === STATUS_GET_SUCCESS
                      ? nextStep({ terms, acceptCommunication })
                      : null
                  }
                >
                  {localized(locale, nextLabel)}
                </Button>
              </Grid>
            ) : null}
          </Grid>
        </Grid>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { user, terms } = state;
  return { user, terms };
};

export default withRouter(
  connect(mapStateToProps, {
    updateUser,
    getTerms,
  })(NG4TermsAndConditions),
);
