import {
  GET_PRODUCT_LIST_LOADING,
  GET_PRODUCT_LIST_SUCCESS,
  GET_PRODUCT_LIST_FAILED,
  STATUS_GETTING,
  STATUS_GET_SUCCESS,
  STATUS_GET_FAILED,
  DELETE_PRODUCT_LOADING,
  DELETE_PRODUCT_SUCCESS,
  DELETE_PRODUCT_FAILED,
  STATUS_DELETING,
  STATUS_DELETE_FAILED,
  STATUS_DELETE_SUCCESS,
} from 'Redux/actions/types';

const initialState = {
  status: STATUS_GETTING,
  statusCode: null,
  errorCode: null,
  data: null,
};

export default (state = initialState, action) => {
  const { payload, type } = action;

  switch (type) {
    case GET_PRODUCT_LIST_LOADING:
      return {
        status: STATUS_GETTING,
        errorCode: null,
      };

    case GET_PRODUCT_LIST_SUCCESS:
      return {
        data: payload,
        status: STATUS_GET_SUCCESS,
        errorCode: null,
      };

    case GET_PRODUCT_LIST_FAILED:
      return {
        status: STATUS_GET_FAILED,
        statusCode: payload.statusCode,
        errorCode: payload.errorCode,
      };

    case DELETE_PRODUCT_LOADING:
      return {
        ...state,
        status: STATUS_DELETING,
        statusCode: null,
        errorCode: null,
      };
    case DELETE_PRODUCT_SUCCESS:
      return {
        ...state,
        status: STATUS_DELETE_SUCCESS,
        statusCode: payload.statusCode,
        errorCode: null,
      };
    case DELETE_PRODUCT_FAILED:
      return {
        ...state,
        status: STATUS_DELETE_FAILED,
        statusCode: payload.statusCode,
        errorCode: payload.errorCode,
      };

    default:
      return state;
  }
};
