import {
  UPDATE_UI,
  UPDATE_METRO,
  ATTEMPT_PLOT_NEXT,
  ATTEMPT_CROP_NEXT,
  ATTEMPT_USAGE_NEXT,
  ATTEMPT_SOIL_NEXT,
  ATTEMPT_NUTRIENT_NEXT,
  SELECT_PLAN_SECTION,
  SELECT_APPLICATION_INDEX,
  SELECT_HARVESTING_YEAR,
  SELECT_OVERVIEW_SECTION,
  SELECT_OVERVIEW_MONTH,
  UPDATE_PRODUCT_FILTERS,
  RESET_UI,
  TOGGLE_NAVIGATION,
  TOGGLE_ACCEPT_TERMS,
  TERMS_SCROLLED_TO_BOTTOM,
} from 'Redux/actions/types';
import { tabNames } from 'constants/index.js';

const initialState = {
  metroSelected: 'plot',
  plotNextAttempted: false,
  cropNextAttempted: false,
  usageNextAttempted: false,
  soilNextAttempted: false,
  nutrientNextAttempted: false,
  selectedPlanSection: 'tab_1',
  selectedOverviewSection: tabNames.ultra_tab_1,
  selectedApplicationIndex: 1,
  selectedHarvestingYear: null,
  isNavOpen: null,
  isTermsBottomReached: false,
  productFilters: {
    select: [],
    portfolio: [],
  },
  planCreationBasePath: '/plan-builder',
};

export default (state = initialState, action) => {
  const { payload, type } = action;

  switch (type) {
    case UPDATE_UI: {
      return { ...state, ...payload };
    }

    case UPDATE_METRO:
      return { ...state, metroSelected: payload };

    case ATTEMPT_PLOT_NEXT:
      return { ...state, plotNextAttempted: true };

    case ATTEMPT_CROP_NEXT:
      return { ...state, cropNextAttempted: true };

    case ATTEMPT_USAGE_NEXT:
      return { ...state, usageNextAttempted: true };

    case ATTEMPT_SOIL_NEXT:
      return { ...state, soilNextAttempted: true };

    case ATTEMPT_NUTRIENT_NEXT:
      return { ...state, nutrientNextAttempted: true };

    case SELECT_PLAN_SECTION: {
      return { ...state, selectedPlanSection: payload };
    }

    case SELECT_APPLICATION_INDEX: {
      return { ...state, selectedApplicationIndex: payload };
    }

    case SELECT_HARVESTING_YEAR: {
      return { ...state, selectedHarvestingYear: payload };
    }

    case SELECT_OVERVIEW_SECTION: {
      return { ...state, selectedOverviewSection: payload };
    }

    case SELECT_OVERVIEW_MONTH: {
      return { ...state, selectedOverviewMonth: payload };
    }

    case UPDATE_PRODUCT_FILTERS: {
      return { ...state, productFilters: payload };
    }

    case RESET_UI: {
      return { ...initialState };
    }

    case TOGGLE_NAVIGATION: {
      return { ...state, isNavOpen: payload };
    }

    case TOGGLE_ACCEPT_TERMS: {
      return { ...state, isAcceptTermsChecked: payload };
    }

    case TERMS_SCROLLED_TO_BOTTOM: {
      return { ...state, isTermsBottomReached: payload };
    }

    default:
      return state;
  }
};
