/* eslint-disable import/prefer-default-export */
/* eslint-disable react/prop-types */
import React from 'react';

export const ChatBubbles = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 79.2" {...props}>
    <title>Chat bubbles</title>
    <path
      d="M37.5,4.2C21.1,4.2,7.4,13.9,4.7,26.6a18.3,18.3,0,0,0-.5,4.7c0,8.2,4.6,15.9,12.6,21.1a2.1,2.1,0,0,1,.7,2.7l-1.9,3.8,5.2-3.3a2.1,2.1,0,0,1,1.9-.1,39.3,39.3,0,0,0,14.8,2.8h0c18.4,0,33.3-12.1,33.3-27A23.9,23.9,0,0,0,64.1,15C58.1,8.4,48.4,4.2,37.5,4.2Z"
      fill="none"
    />
    <path
      d="M42.6,70.2a44.3,44.3,0,0,0,35.3,2l10.6,6.7a2,2,0,0,0,1.1.3,2,2,0,0,0,1.4-.6,1.9,1.9,0,0,0,.4-2.4L87,67.4a32.5,32.5,0,0,0,9.5-10.5L90.8,58a29.9,29.9,0,0,1-7.6,6.9,2.1,2.1,0,0,0-.7,2.7l1.9,3.8-5.2-3.3a2.1,2.1,0,0,0-1.9-.1,40,40,0,0,1-32-1.1l-3.7.7A2.2,2.2,0,0,0,42.6,70.2Z"
      fill="#5f9501"
    />
    <path
      d="M100,43.8h0c0-12-8-22.8-21-28.1a2.1,2.1,0,0,0-2.7,1.2,2.1,2.1,0,0,0,1.1,2.7c11.4,4.6,18.4,13.9,18.4,24.2a22.8,22.8,0,0,1-5,14.2l5.7-1.1A26.2,26.2,0,0,0,100,43.8Z"
      fill="#79b61d"
    />
    <path d="M44.5,66.5a2.1,2.1,0,0,0-2.8.9v.2l3.7-.7Z" fill="#79b61d" />
    <path
      d="M13,54.9,8.6,63.7A1.9,1.9,0,0,0,9,66.1a2,2,0,0,0,1.4.6,2,2,0,0,0,1.1-.3l10.6-6.7a42.4,42.4,0,0,0,15.4,2.8c20.7,0,37.5-14,37.5-31.3h0a27.4,27.4,0,0,0-6.2-17.1l-4.7.9a23.9,23.9,0,0,1,6.7,16.3c0,14.9-14.9,27-33.3,27h0a39.3,39.3,0,0,1-14.8-2.8,2.1,2.1,0,0,0-1.9.1l-5.2,3.3,1.9-3.8a2.1,2.1,0,0,0-.7-2.7C8.8,47.2,4.2,39.5,4.2,31.3a18.3,18.3,0,0,1,.5-4.7l-4.4.8A25.3,25.3,0,0,0,0,31.2C0,40.4,4.7,48.9,13,54.9Z"
      fill="#79b61d"
    />
    <path
      d="M37.5,4.2C48.4,4.2,58.1,8.4,64.1,15l4.7-.9C62.1,5.6,50.6,0,37.5,0,18.4,0,2.6,12,.3,27.4l4.4-.8C7.4,13.9,21.1,4.2,37.5,4.2Z"
      fill="#c8d200"
    />
  </svg>
);
