/* eslint-disable import/prefer-default-export */
export const userTypes = {
  reseller: 'reseller',
  farmer: 'farmer',
};

export const testerEmailAddresses = [
  'christoph.steiner@borealisgroup.com',
  'franzflorian.heinzlmaier@borealisgroup.com',
  'Andrei.Buznea@borealisgroup.com',
  'zsolt.balint@cognitivecreators.com',
  'hunor.andras@cognitivecreators.com',
  'kazmer.mate@cognitivecreators.com',
  'botond.gyorgy@cognitivecreators.com',
  'yazen.kervin@eellee.org',
  'mgt03685@eoopy.com',
  'perrin.khoury@eellee.org',
  'pej13698@bcaoo.com',
  'franz.heinzlmaier@borealisgroup.com',
];
