/* eslint-disable import/prefer-default-export */
import { useState, useEffect, useCallback } from 'react';

const useWindowWidth = (maxMobileWidth) => {
  const [shouldPresentMobile, setShouldPresentMobile] = useState(undefined);
  const checkIfShouldPresentMobile = useCallback(() => {
    setShouldPresentMobile(window.innerWidth <= maxMobileWidth);
  }, [maxMobileWidth]);
  useEffect(() => {
    checkIfShouldPresentMobile();
    window.addEventListener('resize', checkIfShouldPresentMobile);
    return () => {
      window.removeEventListener('resize', checkIfShouldPresentMobile);
    };
  }, [checkIfShouldPresentMobile]);
  return shouldPresentMobile;
};

export default useWindowWidth;
