import React from 'react';
import { PropTypes } from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import userUtils from 'Utils/UserUtils';
import { testerEmailAddresses } from 'constants/UserTypes';

const TestingRoute = ({ user, component, ...props }) => {
  if (!userUtils.isLoggedIn || !testerEmailAddresses.includes(user.email)) {
    return <Redirect to="/myoverview" />;
  }
  return <Route component={component} {...props} />;
};

TestingRoute.propTypes = {
  component: PropTypes.any,
  user: PropTypes.object,
};

export default TestingRoute;
