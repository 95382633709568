import { useContext } from 'react';
import { UserContext } from 'context';

const useUser = () => {
  const { locale, language, country } = useContext(UserContext);

  return { locale, lang: language, country };
};

export default useUser;
