import {
  GET_MY_PLANS_LOADING,
  GET_MY_PLANS_SUCCESS,
  GET_MY_PLANS_NG4_SUCCESS,
  GET_MY_PLANS_FAILED,
  DELETE_PLAN_LOADING,
  DELETE_PLAN_SUCCESS,
  DELETE_PLAN_SUCCESS_NG4,
  DELETE_PLAN_FAILED,
  STATUS_DEFAULT,
  STATUS_DELETING,
  STATUS_DELETE_SUCCESS,
  STATUS_DELETE_FAILED,
  UPDATE_MY_APPLICATIONS_SEARCH_TERMS_NG4,
  RESET_PLANS,
  MERGE_PLAN,
  MERGE_NG4_PLAN,
  DELETE_PLAN_FOR_PLOT_ID_NG4,
  RESET_PLAN_STATUS,
} from 'Redux/actions/types';

import {
  getFarmerStructuredDataFiltered,
  getFarmerStructuredDataFilteredNG4,
  getMergedPlanList,
  getPlansWithNoCropRotation,
  getPlansWithNoCropRotationNG4,
  getPlansStateWithoutPlotNG4,
  getPlansStateWithoutPlanWithId,
  getPlansStateWithoutPlanWithIdNG4,
} from 'Utils/MyPlansUtils';

const initialState = {
  status: STATUS_DEFAULT,
  statusCode: null,
  errorCode: null,
  data: null,
  searchString: '',
};

export default (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case RESET_PLANS:
      return {
        ...initialState,
      };

    case RESET_PLAN_STATUS:
      return {
        ...state,
        status: STATUS_DEFAULT,
      };

    case GET_MY_PLANS_LOADING:
      return {
        ...state,
        searchString: state.searchString,
        status: GET_MY_PLANS_LOADING,
        errorCode: null,
      };

    case GET_MY_PLANS_SUCCESS:
      return {
        ...state,
        status: GET_MY_PLANS_SUCCESS,
        errorCode: null,
        data: {
          raw: payload.data,
          structured: getFarmerStructuredDataFiltered(
            getPlansWithNoCropRotation(payload.data),
            state.searchString,
            payload.lang,
            payload.role,
          ),
        },
      };

    case GET_MY_PLANS_NG4_SUCCESS:
      return {
        ...state,
        status: GET_MY_PLANS_SUCCESS,
        errorCode: null,
        data: {
          raw: payload.data,
          structured: getFarmerStructuredDataFilteredNG4(
            getPlansWithNoCropRotationNG4(payload.data),
            state.searchString,
            payload.role,
          ),
        },
      };

    case GET_MY_PLANS_FAILED:
      return {
        ...state,
        status: GET_MY_PLANS_FAILED,
        statusCode: payload.statusCode,
        errorCode: payload.errorCode,
      };

    case DELETE_PLAN_LOADING:
      return {
        ...state,
        status: STATUS_DELETING,
        statusCode: null,
        errorCode: null,
      };

    case DELETE_PLAN_SUCCESS:
      return {
        ...state,
        status: STATUS_DELETE_SUCCESS,
        statusCode: null,
        errorCode: null,
        data: getPlansStateWithoutPlanWithId(payload, state.data),
      };

    case DELETE_PLAN_SUCCESS_NG4:
      return {
        ...state,
        status: STATUS_DELETE_SUCCESS,
        statusCode: null,
        errorCode: null,
        data: getPlansStateWithoutPlanWithIdNG4(payload, state.data),
      };

    case DELETE_PLAN_FAILED:
      return {
        ...state,
        status: STATUS_DELETE_FAILED,
        statusCode: payload.statusCode,
        errorCode: payload.errorCode,
      };

    case UPDATE_MY_APPLICATIONS_SEARCH_TERMS_NG4:
      return {
        ...state,
        searchString: payload.update,
        data: {
          ...state.data,
          structured: getFarmerStructuredDataFilteredNG4(
            getPlansWithNoCropRotationNG4(state.data.raw),
            payload.update,
            payload.role,
          ),
        },
      };

    case MERGE_PLAN:
      // eslint-disable-next-line no-case-declarations
      const mergedPlanList = getMergedPlanList(state, payload.plans, false);
      return {
        ...state,
        data: {
          raw: mergedPlanList,
          structured: getFarmerStructuredDataFiltered(
            getPlansWithNoCropRotation(mergedPlanList),
            state.searchString,
            payload.lang,
            payload.role,
          ),
        },
      };

    case MERGE_NG4_PLAN:
      // eslint-disable-next-line no-case-declarations
      const mergedPlanListNG4 = getMergedPlanList(state, payload.plans, true);
      return {
        ...state,
        data: {
          raw: mergedPlanListNG4,
          structured: getFarmerStructuredDataFilteredNG4(
            getPlansWithNoCropRotationNG4(mergedPlanListNG4),
            state.searchString,
            payload.role,
          ),
        },
      };

    case DELETE_PLAN_FOR_PLOT_ID_NG4:
      return {
        ...state,
        data: getPlansStateWithoutPlotNG4(payload, state.data),
      };

    default:
      return state;
  }
};
