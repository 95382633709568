import { identifiers, temporaryPlotId, temporaryFarmId } from './Identifiers';
import { userTypes } from './UserTypes';
import { GTMEventTypes, ButtonIds } from './GTMEventTypes';
import { errorTypes, errorCodes } from './ErrorTypes';
import { supportedCountries } from './SupportedLocales';
import { kgDecimals, tonneDecimals, tabNames } from './UIConstants';
import {
  typeConstants,
  NG4SupportedCountries,
  PostalCodeAndCityNotObligatoryAtXMLUploadCounties,
  NG4SupportedCountriesForLP,
  nutrizoneAppLinks,
  AvailableLocalesForLive,
} from './MainConstants';
import { numberOfCutsData } from './PlanCreation';
import { anchorTags } from './NgAnchorTags';

export {
  identifiers,
  userTypes,
  GTMEventTypes,
  errorTypes,
  errorCodes,
  supportedCountries,
  tabNames,
  ButtonIds,
  kgDecimals,
  tonneDecimals,
  typeConstants,
  numberOfCutsData,
  temporaryPlotId,
  temporaryFarmId,
  NG4SupportedCountries,
  NG4SupportedCountriesForLP,
  AvailableLocalesForLive,
  PostalCodeAndCityNotObligatoryAtXMLUploadCounties,
  anchorTags,
  nutrizoneAppLinks,
};
