/* eslint-disable react/prop-types */
import React from 'react';

const ProductGradient = (props) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 70 70"
      enableBackground="new 0 0 70 70"
      {...props}
      width="70"
      height="70"
    >
      <g>
        <g>
          <polygon fill="#EB6909" points="46.7,37.4 46.7,47.6 49.9,46.9 49.9,36.7" />
          <path
            fill="#EB6909"
            d="M42,44.5h-5.4v-4.7l-3,0.7v4h-5.4c-0.8,0-1.5,0.7-1.5,1.5s0.7,1.5,1.5,1.5H42c0.8,0,1.5-0.7,1.5-1.5S42.8,44.5,42,44.5z"
          />
          <polygon fill="#EB6909" points="20.5,53.8 23.4,53.1 23.4,42.9 20.5,43.6" />
        </g>
        <g>
          <path
            fill="#79B61D"
            d="M28.4,29.7c-0.7,0-1.3,0.6-1.4,1.2c0,0-0.1,0.4-0.1,1l5.6-1.3C30.5,29.6,28.5,29.7,28.4,29.7z"
          />
          <path
            fill="#79B61D"
            d="M49.9,26.5c-0.1-0.2-0.2-0.4-0.3-0.6l-2.7-6.4V17l-3,0.7v0.6h-2.5l-12.8,3h15.8l2.3,5.5v0.4L49.9,26.5z"
          />
          <path
            fill="#79B61D"
            d="M20.6,25.9c-0.1,0.2-0.1,0.4-0.1,0.6v6.9l2.9-0.7v-5.9l2-4.7l-3.5,0.8L20.6,25.9z"
          />
        </g>
        <path
          fill="#B95700"
          d="M22,57.3h26.4c0.8,0,1.5-0.7,1.5-1.5v-8.9l-3.2,0.8v6.7h0.1h-0.1H23.4v-1.2l-2.9,0.7v2C20.5,56.6,21.2,57.3,22,57.3z"
        />
        <g>
          <polygon fill="#5F9501" points="20.5,43.6 23.4,42.9 23.4,32.7 20.5,33.4" />
          <path
            fill="#5F9501"
            d="M41.8,29.7c-0.2,0-3.7-0.2-6.2,2.3c-0.2,0.2-0.3,0.4-0.5,0.6c-0.2-0.2-0.3-0.4-0.5-0.6c-0.6-0.6-1.3-1.1-2.1-1.4l-5.6,1.3c0,1.2,0.3,3.4,2,5.2c1.5,1.5,3.4,2,4.7,2.2v1.2l3-0.7v-0.5c1.3-0.2,3.2-0.7,4.7-2.2c2.5-2.6,2-6,1.9-6.2C43.1,30.2,42.5,29.7,41.8,29.7z M31.1,35c-0.7-0.7-1-1.5-1.1-2.2c0.8,0.2,1.7,0.5,2.4,1.3c0.7,0.7,1,1.5,1.1,2.2C32.7,36.1,31.8,35.8,31.1,35z M39,35c-0.7,0.7-1.7,1.1-2.4,1.3c0.2-0.7,0.4-1.5,1.1-2.2c0.7-0.7,1.7-1.1,2.4-1.3C40,33.5,39.7,34.3,39,35z"
          />
          <polygon fill="#5F9501" points="49.9,26.5 46.7,27.2 46.7,37.4 49.9,36.7 49.9,26.5" />
        </g>
        <path
          fill="#C8D200"
          d="M45.4,12H24.9h-0.1c-0.2,0-0.5,0.1-0.7,0.2c-0.5,0.3-0.8,0.8-0.8,1.3v6l-1.4,3.4l3.5-0.8l0.3-0.8h2.9l12.8-3H26.3V15h17.6v2.7l3-0.7v-3.5C46.9,12.7,46.2,12,45.4,12z"
        />
      </g>
    </svg>
  );
};

export default ProductGradient;
