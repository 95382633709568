/* eslint-disable react/prop-types */
import React from 'react';

const NPKSRotastionIcon = (props) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 128 128"
      enableBackground="new 0 0 128 128"
      xmlSpace="preserve"
      {...props}
    >
      <g>
        <defs>
          <path
            id="SVGID_51_NPKSRotastionIcon"
            d="M70.4,98c0.6,0.6,0.6,1.5,0,2.1l-5.3,5.3c-0.3,0.3-0.7,0.4-1.1,0.4c-0.2,0-0.4,0-0.6-0.1
            c-0.6-0.2-0.9-0.8-0.9-1.4v-3.8C49.1,100,36.8,92,31,79.8c-0.4-0.7,0-1.6,0.7-2c0.7-0.4,1.6,0,2,0.7C39,89.7,50.3,97,62.6,97.6
            v-3.7c0-0.6,0.4-1.2,0.9-1.4s1.2-0.1,1.6,0.3L70.4,98z M105.5,62.9l-5.3-5.3c-0.3-0.3-0.7-0.4-1.1-0.4s-0.8,0.2-1.1,0.4l-5.3,5.3
            c-0.4,0.4-0.6,1.1-0.3,1.6s0.8,0.9,1.4,0.9h3.7C97,77.8,89.7,89,78.5,94.3c-0.7,0.4-1.1,1.3-0.7,2c0.3,0.5,0.8,0.9,1.4,0.9
            c0.2,0,0.4,0,0.6-0.1C92,91.2,100,78.9,100.6,65.4h3.8c0.6,0,1.2-0.4,1.4-0.9S105.9,63.3,105.5,62.9z M62.9,35.2
            c0.3,0.3,0.7,0.4,1.1,0.4c0.2,0,0.4,0,0.6-0.1c0.6-0.2,0.9-0.8,0.9-1.4v-3.7C77.8,31,89,38.3,94.3,49.5c0.3,0.5,0.8,0.9,1.4,0.9
            c0.2,0,0.4,0,0.6-0.1c0.7-0.4,1.1-1.3,0.7-2C91.2,36,78.9,28,65.5,27.4v-3.8c0-0.6-0.4-1.2-0.9-1.4c-0.6-0.2-1.2-0.1-1.6,0.3
            l-5.3,5.3c-0.3,0.3-0.4,0.7-0.4,1.1s0.2,0.8,0.4,1.1L62.9,35.2z M35.6,63.4c-0.2-0.6-0.8-0.9-1.4-0.9h-3.7
            C31,50.2,38.4,39,49.5,33.7c0.7-0.4,1.1-1.3,0.7-2c-0.4-0.7-1.3-1.1-2-0.7C36,36.8,28,49.1,27.4,62.5h-3.8c-0.6,0-1.2,0.4-1.4,0.9
            s-0.1,1.2,0.3,1.6l5.3,5.3c0.3,0.3,0.7,0.4,1.1,0.4s0.8-0.1,1.1-0.4l5.3-5.3C35.7,64.6,35.8,64,35.6,63.4z M74.6,47.4
            c-0.8,0-1.5,0.7-1.5,1.5v24.1L56.5,49.5c-0.4-0.5-1.1-0.8-1.7-0.6c-0.6,0.2-1,0.8-1,1.4v27.3c0,0.8,0.7,1.5,1.5,1.5
            s1.5-0.7,1.5-1.5V55.1l16.6,23.4c0.3,0.4,0.7,0.6,1.2,0.6c0.2,0,0.3,0,0.5-0.1c0.6-0.2,1-0.8,1-1.4V48.9
            C76.1,48,75.4,47.4,74.6,47.4z"
          />
        </defs>
        <clipPath id="SVGID_2_NPKSRotastionIcon">
          <use xlinkHref="#SVGID_51_NPKSRotastionIcon" overflow="visible" />
        </clipPath>
        <g clipPath="url(#SVGID_2_NPKSRotastionIcon)">
          <rect
            x="-12"
            y="108.5"
            transform="matrix(0.9736 -0.2284 0.2284 0.9736 -26.3369 20.839)"
            fill="#B95700"
            width="177.8"
            height="31.5"
          />
          <rect
            x="-19.2"
            y="77.8"
            transform="matrix(0.9736 -0.2284 0.2284 0.9736 -19.5204 18.3844)"
            fill="#EB6909"
            width="177.8"
            height="31.5"
          />
          <rect
            x="-26.4"
            y="47.1"
            transform="matrix(0.9736 -0.2284 0.2284 0.9736 -12.7039 15.9297)"
            fill="#5F9501"
            width="177.8"
            height="31.5"
          />
          <rect
            x="-33.6"
            y="16.4"
            transform="matrix(0.9736 -0.2284 0.2284 0.9736 -5.8875 13.4751)"
            fill="#79B61D"
            width="177.8"
            height="31.5"
          />
          <rect
            x="-40.8"
            y="-14.3"
            transform="matrix(0.9736 -0.2284 0.2284 0.9736 0.929 11.0204)"
            fill="#C8D200"
            width="177.8"
            height="31.5"
          />
        </g>
      </g>
    </svg>
  );
};

export default NPKSRotastionIcon;
