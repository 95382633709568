/* eslint-disable react/prop-types */
import React from 'react';

const BorealisLogo = ({ fill, ...props }) => (
  <svg x="0px" y="0px" {...props} viewBox="0 0 825.6 351.3">
    <path
      fill={fill || '#003663'}
      d="M237,217.5c0-10.1-8.4-13.9-16.4-15.2c8-2,13.9-6.6,13.8-14.3c-0.1-9.5-7.4-15.7-24.9-15.7h-37.4v62h39.1C228.8,234.4,237,227.3,237,217.5z M192.1,183.8h11.3c7.3,0,10.5,2,10.5,6.8c0,4.7-2.9,7-10.5,7h-11.3V183.8z M192.1,222.9v-14.4h13.8c5.7,0,10.6,1.4,10.6,7.1c0,5.4-4,7.3-10.6,7.3L192.1,222.9L192.1,222.9z M281.8,236c24.5,0,38-12.4,38-32.6c0-19.3-11-32.6-38-32.6c-24.6,0-38,12.3-38,32.6C243.8,222.7,254.3,236,281.8,236z M281.8,183.4c12.8,0,17.3,8.3,17.3,19.9c0,11.5-4.6,19.9-17.3,19.9c-12.9,0-17.3-8.5-17.3-19.9C264.5,191.8,269.7,183.4,281.8,183.4z M349.4,211h11c8,0,10.2,1.4,11.1,5.5c0.3,1.7,1,7.9,1.9,12.9c0.3,2,0.7,3.8,1.1,5h21.1c-0.4-1.1-0.9-3.2-1.4-5.5c-0.7-3.4-2-11.3-2.6-14.4c-1.4-6.9-5.1-9-13.9-10.1c8.2-1.2,15.4-6,15.4-15c0-11.3-9-16.9-24.4-16.9h-39.5v62h20.1V211H349.4z M349.4,184.4c3.5,0,13.2,0,13.2,0c6.8,0,10.5,1.9,10.5,7.2c0,4.8-2.8,7.6-10.5,7.6h-13.2V184.4z M460.9,221.6h-35.3v-12.2h32.7v-12.6h-32.7v-11.6h34.7v-12.8h-54.8v62h55.4L460.9,221.6L460.9,221.6z M489.8,222.6h33.4l5.3,11.8h21.6l-30.6-62h-23.6l-31,62h19.6L489.8,222.6z M506.7,185.4l11.1,25.2h-22.6L506.7,185.4z M607.5,221.1h-33.2v-48.8h-20.2v62h53.4V221.1z M634.4,172.3h-20.2v62h20.2V172.3z M688.3,197.6l-10.9-2.5c-6.7-1.5-11.1-2.6-11.1-6.6c0-3.2,3-5.9,9.8-5.9c5.7,0,11,2.3,11.3,6.7h19.5c-0.3-9.2-9.9-18.5-30.3-18.5c-17.1,0-30.7,5.9-30.7,20.3c0,11.4,9.9,14.9,19.9,17.2l11.3,2.6c6.7,1.6,11.3,2.8,11.3,7.1c0,3.2-2.1,6.3-11.6,6.3c-6.4,0-12-3.3-12.2-8.1h-20.3c0,4.7,6.7,19.9,31.9,19.9c16,0,32.5-5.1,32.5-20.1C708.6,203.3,699.2,200.1,688.3,197.6z M27.7,234.4h89.2v-60.6c-3.7-0.4-6.3-0.5-9.3-0.5C72.3,173.3,38.2,203.3,27.7,234.4z"
    />
    <path
      fill={fill || '#0073AE'}
      d="M116.9,163.3V117c-2.9,0-7,0.6-9.6,1.3c-36,8.6-63.5,39-83.2,80.1h-0.5c0.7-4.4,4.2-49.1,44.4-81.4H0v117.4h14.8C30.7,203.6,65.2,166.8,116.9,163.3z"
    />
  </svg>
);

export default BorealisLogo;
