/* eslint-disable no-param-reassign */
import React, { useContext, useRef, useEffect } from 'react';
import { remove as removeDiacritics } from 'diacritics';
import { mapValues } from 'lodash';
import { localized } from 'data/Localization';

import { useLocaleBehaviourFromContext, localeBehaviour } from 'data/Behaviour';
import { UserContext } from 'context';
import { harvestingYearArrayToName, getCurrentHarvestingYear } from 'Utils/HarvestingYearUtils';

// Forms
// Input type numbers
export const blockCharOnKeyPress = (event, isFloat = false, maxDecimals) => {
  // if float accept both comma and dot. Comma will be replaced auto with dots
  const input = event.target.value;
  if (
    ((!isFloat || input.length === 0) && (event.which === 44 || event.which === 46)) ||
    ((input.indexOf(',') !== -1 || input.indexOf('.') !== -1) &&
      (event.which === 44 || event.which === 46)) ||
    (event.which !== 8 &&
      event.which !== 44 &&
      event.which !== 46 &&
      event.which !== 0 &&
      (event.which < 48 || event.which > 57)) ||
    (isFloat &&
      maxDecimals &&
      maxDecimals > 0 &&
      ((input.indexOf(',') !== -1 && input.split(',')[1].length >= maxDecimals) ||
        (input.indexOf('.') !== -1 && input.split('.')[1].length >= maxDecimals)))
  ) {
    event.preventDefault();
  }
};

export const removeLeadingZero = (input) => input.replace(/^0+(?!\.|$)/, '');
export const convertAccentedCharacters = (input) => removeDiacritics(input);

export const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);

export const normalizeInput = (input) => {
  if (!input) {
    return input;
  }
  let newInput = input;
  newInput = newInput.toLowerCase();
  newInput = convertAccentedCharacters(newInput);
  newInput = newInput.trim();
  return newInput;
};

// NO RESULTS HANDLER
// for object of arrays
export const checkResultLengthHandler = (data, locale) => {
  const hasResults = Object.keys(data).some((key) => data[key].length > 0);
  let noResultElement = '';

  if (!hasResults) {
    noResultElement = <p className="no-results">{localized(locale, 'no_result')}</p>;
  }

  return noResultElement;
};

export const createCookie = (name, value, days) => {
  let expires = '';
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = `; expires=${date.toGMTString()}`;
  }
  document.cookie = `${name}=${value}${expires}; path=/`;
};

export const readCookie = (name) => {
  const nameEQ = `${name}=`;
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};

export const eraseCookie = (name) => {
  createCookie(name, '', -1);
};

export const highlightPattern = (input, pattern) => {
  const text = normalizeInput(input);
  const splitText = text.split(pattern);

  if (splitText.length <= 1) {
    return text;
  }

  const matches = text.match(RegExp(pattern, 'g'));

  return splitText.reduce(
    (arr, element, index) =>
      matches[index]
        ? [...arr, element, <span className="highlight">{matches[index]}</span>]
        : [...arr, element],
    [],
  );
};

export const useGetLocalizedLinkForLATWebsite = (link) => {
  const localizedPart = useLocaleBehaviourFromContext('borealis_lat_localization');

  if (!link) {
    return null;
  }

  const [protocol, , origin, ...pathComponents] = link.split('/');

  if ((origin !== 'www.lat-nitrogen.com') || (pathComponents.length > 2 && pathComponents[0].length === 2 && pathComponents[1].length === 2 && pathComponents[2] === 'product')) {
    return link;
  }

  return `${protocol}//${origin}${localizedPart}${pathComponents.join('/')}`;
};

export const elemenToIdOfLatWebsite = {
  N: 1,
  P: 2,
  K: 3,
  Ca: 4,
  Mg: 5,
  S: 6,
  B: 7,
  Cu: 8,
  Fe: 9,
  Mn: 10,
  Mo: 11,
  Zn: 12,
};

export const websiteLat = 'https://www.lat-nitrogen.com/';

export const useUrlToLatWebsiteForNutrient = (element) => {
  const path = '/nutrients/';
  const id = elemenToIdOfLatWebsite[element];
  const localizedLink = useGetLocalizedLinkForLATWebsite(`${websiteLat}${path}${id}`);
  if (id) {
    return localizedLink;
  }
  return null;
};

export const getUrlParams = (search) => {
  // https://gist.github.com/pirate/9298155edda679510723
  const hashes = search.slice(search.indexOf('?') + 1).split('&');
  return hashes.reduce((params, hash) => {
    const [key, val] = hash.split('=');
    if (key && val) {
      return Object.assign(params, { [key]: decodeURIComponent(val) });
    }
    return {};
  }, {});
};

export const getUrlSearchStringFromObject = (object) => {
  let string = '';
  Object.keys(object).forEach((key) => {
    const newChunk = `${key}=${object[key]}`;
    string = string + (string.length > 0 ? '&' : '') + newChunk;
  });
  return string;
};

export const getRoleLogic = (role, farmerLogic, resellerLogic) => {
  if (role === 'reseller') {
    return resellerLogic;
  }
  if (role === 'farmer') {
    return farmerLogic;
  }
};

export const toLocalNumber = (locale, number, decimals) => {
  let tranformedNumber = number;
  const numberLocalesCode = localeBehaviour(locale, 'number_locales_code');
  if (typeof number !== 'number') {
    tranformedNumber = Number(number);
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(tranformedNumber)) {
      console.error(
        `${number} can't be transformed to a number so number localisation is impossible`,
      );
      return tranformedNumber;
    }
  }
  if (decimals !== undefined) {
    return tranformedNumber.toLocaleString(numberLocalesCode, {
      maximumFractionDigits: decimals,
    });
  }
  return tranformedNumber.toLocaleString(numberLocalesCode);
};

export const useLocalNumber = (number, decimals) => {
  const { locale } = useContext(UserContext);
  if (number === undefined) {
    return '';
  }
  return toLocalNumber(locale, number, decimals);
};

export const getMyOverviewLink = (role, myPlan, previousSelectedHarvestingYear) => {
  const baseLink = `/myoverview/${role}`;
  let myOverviewResellerLink = baseLink;
  let myOverviewFarmerLink = baseLink;
  if (myPlan) {
    const {
      paramsIn: { harvestingYear },
      paramsOut: {
        planName: { farmersName, farmName, cropName },
      },
    } = myPlan;
    myOverviewFarmerLink = `${myOverviewFarmerLink}/${harvestingYearArrayToName(harvestingYear)}`;
    myOverviewResellerLink = `${myOverviewResellerLink}/${harvestingYearArrayToName(
      harvestingYear,
    )}`;
    [farmersName, farmName, cropName].forEach((key) => {
      myOverviewResellerLink += `/${encodeURIComponent(key)}`;
    });
  } else {
    const { label: currentHarvestingYearLabel } = getCurrentHarvestingYear();
    const defaultHarvestingYear = previousSelectedHarvestingYear || currentHarvestingYearLabel;
    myOverviewFarmerLink = `${myOverviewFarmerLink}/${defaultHarvestingYear}`;
    myOverviewResellerLink = `${myOverviewResellerLink}/${defaultHarvestingYear}`;
  }
  return getRoleLogic(role, myOverviewFarmerLink, myOverviewResellerLink);
};

export const roundNumber = (value) => {
  // eslint-disable-next-line no-restricted-globals
  if (!isNaN(value)) {
    value = Number(value);
    return Math.round(value);
  }
  return value;
};

export const mapRange = (num, in_min, in_max, out_min, out_max) =>
  ((num - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min; // https://stackoverflow.com/questions/10756313/javascript-jquery-map-a-range-of-numbers-to-another-range-of-numbers/23202637

export const useInitialMounted = () => {
  const isInitialMount = useRef(true);
  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    }
  }, []);
  return isInitialMount.current;
};

export const decodeUriParam = (param) => {
  if (param) {
    let formattedValue = param.replace('%', '__percentage__');
    formattedValue = decodeURIComponent(formattedValue);
    formattedValue = formattedValue.replace('__percentage__', '%');
    return formattedValue;
  }
  return param;
};

export const decodeParams = (params) => mapValues(params, (value) => decodeUriParam(value));

export const addCopyRightToText = (text) => `©${new Date().getFullYear()} ${text}`;

export const isIE = () => /MSIE|Trident/.test(window.navigator.userAgent);
export const isEdge = () => /Edge/.test(window.navigator.userAgent);
