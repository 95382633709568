/* eslint-disable import/prefer-default-export */
/* eslint-disable react/prop-types */
import React from 'react';
import { PropTypes } from 'prop-types';
import { useLocalizedFromContext } from 'data/Localization';

export const Crop29 = ({ title, fill, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="81.845"
    height="165.805"
    viewBox="0 0 81.845 165.805"
    {...props}
  >
    <title>{useLocalizedFromContext(title)}</title>
    <g id="Component_5_1" dataname="Component 5 – 1" transform="translate(2.504 2.5)">
      <g id="Group_289" dataname="Group 289" transform="translate(-2943.886 -3951.37)">
        <line
          id="Line_89"
          dataname="Line 89"
          y2="136.031"
          transform="translate(2957.662 3976.145)"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="5"
        />
        <g id="Group_288" dataname="Group 288">
          <path
            id="Path_1016"
            dataname="Path 1016"
            d="M2957.421,3978.5s4.505-5.979,4.417-12.393-4.654-11.767-4.654-11.767-4.41,5.985-4.328,11.891c.088,6.413,4.565,12.269,4.565,12.269"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
        <path
          id="Path_1017"
          dataname="Path 1017"
          d="M2957.345,3980.634s.2-4.522-5.485-8.652-6.3-17.107-6.3-17.107-4.042,15.757.427,23.032,7.295,3.872,11.356,9.332Z"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
        <path
          id="Path_1018"
          dataname="Path 1018"
          d="M2957.346,3980.634s-.2-4.522,5.485-8.652,6.3-17.107,6.3-17.107,4.042,15.757-.427,23.032-7.295,3.872-11.357,9.332Z"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
        <path
          id="Path_1019"
          dataname="Path 1019"
          d="M2957.345,4004.264s.2-4.523-5.485-8.652-6.3-17.107-6.3-17.107-4.042,15.757.427,23.032,7.295,3.871,11.356,9.331Z"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
        <path
          id="Path_1020"
          dataname="Path 1020"
          d="M2957.346,4004.264s-.2-4.523,5.485-8.652,6.3-17.107,6.3-17.107,4.042,15.757-.427,23.032-7.295,3.871-11.357,9.331Z"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
        <path
          id="Path_1021"
          dataname="Path 1021"
          d="M2957.345,4026.294s.2-4.523-5.485-8.652-6.3-17.107-6.3-17.107-4.042,15.757.427,23.032,7.295,3.871,11.356,9.331Z"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
        <path
          id="Path_1022"
          dataname="Path 1022"
          d="M2957.346,4026.294s-.2-4.523,5.485-8.652,6.3-17.107,6.3-17.107,4.042,15.757-.427,23.032-7.295,3.871-11.357,9.331Z"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
        <path
          id="Path_1023"
          dataname="Path 1023"
          d="M2957.345,4048.324s.2-4.523-5.485-8.652-6.3-17.107-6.3-17.107-4.042,15.757.427,23.032,7.295,3.871,11.356,9.331Z"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
        <path
          id="Path_1024"
          dataname="Path 1024"
          d="M2957.346,4048.324s-.2-4.523,5.485-8.652,6.3-17.107,6.3-17.107,4.042,15.757-.427,23.032-7.295,3.871-11.357,9.331Z"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
        <path
          id="Path_1025"
          dataname="Path 1025"
          d="M2957.345,4070.827s.2-4.523-5.485-8.652-6.3-17.107-6.3-17.107-4.042,15.756.427,23.032,7.295,3.871,11.356,9.331Z"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
        <path
          id="Path_1026"
          dataname="Path 1026"
          d="M2957.346,4070.827s-.2-4.523,5.485-8.652,6.3-17.107,6.3-17.107,4.042,15.756-.427,23.032-7.295,3.871-11.357,9.331Z"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
      </g>
      <g id="Group_291" dataname="Group 291" transform="translate(-2943.886 -3951.37)">
        <g id="Group_290" dataname="Group 290">
          <circle
            id="Ellipse_42"
            dataname="Ellipse 42"
            cx="12.285"
            cy="12.285"
            r="12.285"
            transform="translate(2986.197 3961.989) rotate(-13.282)"
            strokeWidth="5"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            fill="none"
          />
          <line
            id="Line_90"
            dataname="Line 90"
            y1="7.467"
            transform="translate(3000.975 3951.37)"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
          <line
            id="Line_91"
            dataname="Line 91"
            y1="7.467"
            transform="translate(3000.975 3983.407)"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
          <line
            id="Line_92"
            dataname="Line 92"
            x2="7.467"
            transform="translate(3013.26 3971.122)"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
          <line
            id="Line_93"
            dataname="Line 93"
            x2="7.467"
            transform="translate(2981.223 3971.122)"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
          <line
            id="Line_94"
            dataname="Line 94"
            x1="5.28"
            y1="5.28"
            transform="translate(2987.009 3957.156)"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
          <line
            id="Line_95"
            dataname="Line 95"
            x1="5.28"
            y1="5.28"
            transform="translate(3009.662 3979.809)"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
          <line
            id="Line_96"
            dataname="Line 96"
            y1="5.28"
            x2="5.28"
            transform="translate(3009.662 3957.156)"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
          <line
            id="Line_97"
            dataname="Line 97"
            y1="5.28"
            x2="5.28"
            transform="translate(2987.009 3979.809)"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
      </g>
    </g>
  </svg>
);

Crop29.defaultProps = {
  title: 'spring_rye',
};

Crop29.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
};
