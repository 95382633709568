import React, { useEffect, useState } from 'react';
import { Fab, Zoom } from '@material-ui/core';
import PropTypes from 'prop-types';
import { ArrowUp } from 'components/Icons';

const ScrollToTopButton = ({ scrollComponent }) => {
  const [active, setActive] = useState(false);

  const handleScroll = () => {
    const { top: scrollPosition } = scrollComponent.getBoundingClientRect();
    const treshold = -1500;
    if (scrollPosition < treshold && !active) {
      setActive(true);
    } else if (scrollPosition > treshold && active) {
      setActive(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  });

  const scrollAction = (event) => {
    event.preventDefault();
    scrollComponent.scrollIntoView({ block: 'start', behavior: 'smooth' });
  };

  return (
    <form onSubmit={(event) => scrollAction(event)}>
      <Zoom in={active}>
        <Fab
          className="btn--scroll-to-top"
          color="primary"
          aria-label="Scroll to top"
          type="submit"
        >
          <ArrowUp color="white" />
        </Fab>
      </Zoom>
    </form>
  );
};

ScrollToTopButton.propTypes = {
  scrollComponent: PropTypes.any.isRequired,
};

export default ScrollToTopButton;
