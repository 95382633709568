import { unionBy } from 'lodash';

import {
  GET_TERRAZO_FIELDS_LOADING,
  GET_TERRAZO_FIELDS_SUCCESS,
  GET_TERRAZO_FIELDS_FAILED,
  SET_TERRAZO_FIELDS,
  POST_TERRAZO_FIELDS_LOADING,
  POST_TERRAZO_FIELDS_SUCCESS,
  POST_TERRAZO_FIELDS_FAILED,
  PATCH_TERRAZO_FIELDS_LOADING,
  PATCH_TERRAZO_FIELDS_SUCCESS,
  PATCH_TERRAZO_FIELDS_FAILED,
  DELETE_TERRAZO_FIELDS_LOADING,
  DELETE_TERRAZO_FIELDS_SUCCESS,
  DELETE_TERRAZO_FIELDS_FAILED,
  DELETE_TERRAZO_FIELDS_FOR_RESOURCE_LOADING,
  DELETE_TERRAZO_FIELDS_FOR_RESOURCE_SUCCESS,
  DELETE_TERRAZO_FIELDS_FOR_RESOURCE_FAILED,
  STATUS_DEFAULT,
  STATUS_GETTING,
  STATUS_GET_SUCCESS,
  STATUS_GET_FAILED,
  STATUS_POSTING,
  STATUS_POST_SUCCESS,
  STATUS_POST_FAILED,
  STATUS_PATCHING,
  STATUS_PATCH_SUCCESS,
  STATUS_PATCH_FAILED,
  STATUS_DELETING,
  STATUS_DELETE_SUCCESS,
  STATUS_DELETE_FAILED,
  RESET_TERRAZO_FIELDS,
} from 'Redux/actions/types';

const initialState = {
  status: STATUS_DEFAULT,
  statusCode: null,
  errorCode: null,
  data: [],
};

export default (state = initialState, action) => {
  const { payload, type } = action;

  switch (type) {
    case GET_TERRAZO_FIELDS_LOADING:
      return {
        status: STATUS_GETTING,
        errorCode: null,
      };
    case GET_TERRAZO_FIELDS_SUCCESS:
      return {
        data: payload,
        status: STATUS_GET_SUCCESS,
        errorCode: null,
      };
    case GET_TERRAZO_FIELDS_FAILED:
      return {
        status: STATUS_GET_FAILED,
        statusCode: payload.statusCode,
        errorCode: payload.errorCode,
      };
    case POST_TERRAZO_FIELDS_LOADING:
      return {
        ...state,
        status: STATUS_POSTING,
      };
    case POST_TERRAZO_FIELDS_SUCCESS:
      return {
        ...state,
        status: STATUS_POST_SUCCESS,
        data: unionBy([action.payload], state.data, 'id'),
      };
    case POST_TERRAZO_FIELDS_FAILED:
      return {
        ...state,
        status: STATUS_POST_FAILED,
        statusCode: payload.statusCode,
        errorCode: payload.errorCode,
      };
    case PATCH_TERRAZO_FIELDS_LOADING:
      return {
        ...state,
        status: STATUS_PATCHING,
      };
    case PATCH_TERRAZO_FIELDS_SUCCESS:
      return {
        ...state,
        status: STATUS_PATCH_SUCCESS,
        data: unionBy([action.payload], state.data, 'id'),
      };
    case PATCH_TERRAZO_FIELDS_FAILED:
      return {
        ...state,
        status: STATUS_PATCH_FAILED,
        statusCode: payload.statusCode,
        errorCode: payload.errorCode,
      };
    case DELETE_TERRAZO_FIELDS_LOADING:
      return {
        ...state,
        status: STATUS_DELETING,
      };
    case DELETE_TERRAZO_FIELDS_SUCCESS:
      return {
        ...state,
        status: STATUS_DELETE_SUCCESS,
        data: state.data.filter((terrazoField) => terrazoField.id !== payload),
      };
    case DELETE_TERRAZO_FIELDS_FAILED:
      return {
        ...state,
        status: STATUS_DELETE_FAILED,
      };
    case DELETE_TERRAZO_FIELDS_FOR_RESOURCE_LOADING:
      return {
        ...state,
        status: STATUS_DELETING,
      };
    case DELETE_TERRAZO_FIELDS_FOR_RESOURCE_SUCCESS:
      return {
        ...state,
        status: STATUS_DELETE_SUCCESS,
        data: state.data.filter((terrazoField) => !payload.includes(terrazoField.id)),
      };
    case DELETE_TERRAZO_FIELDS_FOR_RESOURCE_FAILED:
      return {
        ...state,
        status: STATUS_DELETE_FAILED,
        statusCode: payload.statusCode,
        errorCode: payload.errorCode,
      };
    case SET_TERRAZO_FIELDS:
      return {
        ...state,
        status: STATUS_GET_SUCCESS,
        data: payload,
      };
    case RESET_TERRAZO_FIELDS:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};
