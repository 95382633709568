import { countryBehaviour } from 'data/Behaviour';

export default (fertilizer, country) => {
  if (fertilizer.type === '' || fertilizer.type === undefined) return false;
  if (
    countryBehaviour(country, 'fertilizer_period') &&
    (fertilizer.period === '' || fertilizer.period === undefined)
  )
    return false;
  if (
    countryBehaviour(country, 'fertilizer_lasttime') &&
    (fertilizer.lastApplied === '' || fertilizer.lastApplied === undefined)
  )
    return false;
  if (fertilizer.quantity === '' || fertilizer.quantity === 0 || fertilizer.quantity === undefined)
    return false;
  return true;
};
