/* eslint-disable import/prefer-default-export */
/* eslint-disable react/prop-types */
import React from 'react';
import { PropTypes } from 'prop-types';
import { useLocalizedFromContext } from 'data/Localization';

export const Crop43 = ({ title, fill, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="197.947"
    height="152.462"
    viewBox="0 0 197.947 152.462"
    {...props}
  >
    <title>{useLocalizedFromContext(title)}</title>
    <g id="Component_5_1" data-name="Component 5 – 1" transform="translate(2.5 3.771)">
      <g id="Group_490" data-name="Group 490" transform="translate(-214.876 -4585.16)">
        <line
          id="Line_225"
          data-name="Line 225"
          y2="13.523"
          transform="translate(246.451 4717.816)"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="5"
        />
        <g id="Group_486" data-name="Group 486">
          <path
            id="Path_1548"
            data-name="Path 1548"
            d="M252.383,4680.3a5.932,5.932,0,0,1-11.864,0c0-3.276,5.932-9.849,5.932-9.849S252.383,4677.028,252.383,4680.3Z"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeMiterlimit="10"
            strokeWidth="5"
          />
          <path
            id="Path_1549"
            data-name="Path 1549"
            d="M256.3,4664.523a5.932,5.932,0,1,1,0,11.864c-3.276,0-9.849-5.932-9.849-5.932S253.024,4664.523,256.3,4664.523Z"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeMiterlimit="10"
            strokeWidth="5"
          />
          <path
            id="Path_1550"
            data-name="Path 1550"
            d="M240.519,4660.6a5.932,5.932,0,1,1,11.864,0c0,3.277-5.932,9.85-5.932,9.85S240.519,4663.882,240.519,4660.6Z"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeMiterlimit="10"
            strokeWidth="5"
          />
          <path
            id="Path_1551"
            data-name="Path 1551"
            d="M236.6,4676.387a5.932,5.932,0,1,1,0-11.864c3.276,0,9.849,5.932,9.849,5.932S239.878,4676.387,236.6,4676.387Z"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeMiterlimit="10"
            strokeWidth="5"
          />
        </g>
        <g id="Group_487" data-name="Group 487">
          <path
            id="Path_1552"
            data-name="Path 1552"
            d="M252.383,4711.884a5.932,5.932,0,1,1-11.864,0c0-3.276,5.932-9.849,5.932-9.849S252.383,4708.608,252.383,4711.884Z"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeMiterlimit="10"
            strokeWidth="5"
          />
          <path
            id="Path_1553"
            data-name="Path 1553"
            d="M240.519,4692.185a5.932,5.932,0,1,1,11.864,0c0,3.277-5.932,9.85-5.932,9.85S240.519,4695.462,240.519,4692.185Z"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeMiterlimit="10"
            strokeWidth="5"
          />
        </g>
        <g id="Group_488" data-name="Group 488">
          <path
            id="Path_1554"
            data-name="Path 1554"
            d="M262.364,4703.566a5.932,5.932,0,0,1-8.389-8.389c2.317-2.317,11.159-2.77,11.159-2.77S264.681,4701.25,262.364,4703.566Z"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeMiterlimit="10"
            strokeWidth="5"
          />
          <path
            id="Path_1555"
            data-name="Path 1555"
            d="M276.293,4695.177a5.932,5.932,0,0,1-8.389,8.389c-2.317-2.316-2.77-11.159-2.77-11.159S273.977,4692.86,276.293,4695.177Z"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeMiterlimit="10"
            strokeWidth="5"
          />
          <path
            id="Path_1556"
            data-name="Path 1556"
            d="M267.9,4681.248a5.932,5.932,0,0,1,8.389,8.389c-2.316,2.317-11.159,2.77-11.159,2.77S265.587,4683.564,267.9,4681.248Z"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeMiterlimit="10"
            strokeWidth="5"
          />
          <path
            id="Path_1557"
            data-name="Path 1557"
            d="M253.975,4689.637a5.932,5.932,0,0,1,8.389-8.389c2.317,2.316,2.77,11.159,2.77,11.159S256.292,4691.954,253.975,4689.637Z"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeMiterlimit="10"
            strokeWidth="5"
          />
        </g>
        <g id="Group_489" data-name="Group 489">
          <path
            id="Path_1558"
            data-name="Path 1558"
            d="M225,4703.566a5.932,5.932,0,0,1-8.389-8.389c2.317-2.317,11.159-2.77,11.159-2.77S227.32,4701.25,225,4703.566Z"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeMiterlimit="10"
            strokeWidth="5"
          />
          <path
            id="Path_1559"
            data-name="Path 1559"
            d="M238.933,4695.177a5.932,5.932,0,0,1-8.39,8.389c-2.317-2.316-2.77-11.159-2.77-11.159S236.616,4692.86,238.933,4695.177Z"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeMiterlimit="10"
            strokeWidth="5"
          />
          <path
            id="Path_1560"
            data-name="Path 1560"
            d="M230.543,4681.248a5.932,5.932,0,0,1,8.39,8.389c-2.317,2.317-11.16,2.77-11.16,2.77S228.226,4683.564,230.543,4681.248Z"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeMiterlimit="10"
            strokeWidth="5"
          />
          <path
            id="Path_1561"
            data-name="Path 1561"
            d="M216.614,4689.637a5.932,5.932,0,0,1,8.389-8.389c2.317,2.316,2.77,11.159,2.77,11.159S218.931,4691.954,216.614,4689.637Z"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeMiterlimit="10"
            strokeWidth="5"
          />
        </g>
      </g>
      <g id="Group_506" data-name="Group 506" transform="translate(-214.876 -4585.16)">
        <line
          id="Line_226"
          data-name="Line 226"
          y1="35.116"
          transform="translate(361.377 4696.224)"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
        <g id="Group_495" data-name="Group 495">
          <g id="Group_491" data-name="Group 491">
            <path
              id="Path_1562"
              data-name="Path 1562"
              d="M386.895,4695.506c-4.218-2.069-7.068-15.615,2.429-15.615"
              fill="none"
              stroke={fill || '#839ca9'}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="5"
            />
            <path
              id="Path_1563"
              data-name="Path 1563"
              d="M391.753,4695.506c4.218-2.069,7.069-15.615-2.429-15.615"
              fill="none"
              stroke={fill || '#839ca9'}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="5"
            />
          </g>
          <g id="Group_492" data-name="Group 492">
            <path
              id="Path_1564"
              data-name="Path 1564"
              d="M392.208,4695.834c2.068-4.219,15.615-7.069,15.615,2.429"
              fill="none"
              stroke={fill || '#839ca9'}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="5"
            />
            <path
              id="Path_1565"
              data-name="Path 1565"
              d="M392.208,4700.692c2.068,4.218,15.615,7.068,15.615-2.429"
              fill="none"
              stroke={fill || '#839ca9'}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="5"
            />
          </g>
          <g id="Group_493" data-name="Group 493">
            <path
              id="Path_1566"
              data-name="Path 1566"
              d="M391.753,4701.019c4.218,2.069,7.069,15.615-2.429,15.615"
              fill="none"
              stroke={fill || '#839ca9'}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="5"
            />
            <path
              id="Path_1567"
              data-name="Path 1567"
              d="M386.895,4701.019c-4.218,2.069-7.068,15.615,2.429,15.615"
              fill="none"
              stroke={fill || '#839ca9'}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="5"
            />
          </g>
          <g id="Group_494" data-name="Group 494">
            <path
              id="Path_1568"
              data-name="Path 1568"
              d="M386.44,4700.692c-2.068,4.218-15.615,7.068-15.615-2.429"
              fill="none"
              stroke={fill || '#839ca9'}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="5"
            />
            <path
              id="Path_1569"
              data-name="Path 1569"
              d="M386.44,4695.834c-2.068-4.219-15.615-7.069-15.615,2.429"
              fill="none"
              stroke={fill || '#839ca9'}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="5"
            />
          </g>
          <path
            id="Path_1570"
            data-name="Path 1570"
            d="M393.565,4698.066a3.764,3.764,0,1,0-3.764,3.764A3.764,3.764,0,0,0,393.565,4698.066Z"
            fill="#fff"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
        <g id="Group_500" data-name="Group 500">
          <g id="Group_496" data-name="Group 496">
            <path
              id="Path_1571"
              data-name="Path 1571"
              d="M331,4695.506c-4.218-2.069-7.068-15.615,2.429-15.615"
              fill="none"
              stroke={fill || '#839ca9'}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="5"
            />
            <path
              id="Path_1572"
              data-name="Path 1572"
              d="M335.859,4695.506c4.219-2.069,7.068-15.615-2.429-15.615"
              fill="none"
              stroke={fill || '#839ca9'}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="5"
            />
          </g>
          <g id="Group_497" data-name="Group 497">
            <path
              id="Path_1573"
              data-name="Path 1573"
              d="M336.314,4695.834c2.068-4.219,15.615-7.069,15.615,2.429"
              fill="none"
              stroke={fill || '#839ca9'}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="5"
            />
            <path
              id="Path_1574"
              data-name="Path 1574"
              d="M336.314,4700.692c2.068,4.218,15.615,7.068,15.615-2.429"
              fill="none"
              stroke={fill || '#839ca9'}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="5"
            />
          </g>
          <g id="Group_498" data-name="Group 498">
            <path
              id="Path_1575"
              data-name="Path 1575"
              d="M335.859,4701.019c4.219,2.069,7.068,15.615-2.429,15.615"
              fill="none"
              stroke={fill || '#839ca9'}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="5"
            />
            <path
              id="Path_1576"
              data-name="Path 1576"
              d="M331,4701.019c-4.218,2.069-7.068,15.615,2.429,15.615"
              fill="none"
              stroke={fill || '#839ca9'}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="5"
            />
          </g>
          <g id="Group_499" data-name="Group 499">
            <path
              id="Path_1577"
              data-name="Path 1577"
              d="M330.546,4700.692c-2.068,4.218-15.615,7.068-15.615-2.429"
              fill="none"
              stroke={fill || '#839ca9'}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="5"
            />
            <path
              id="Path_1578"
              data-name="Path 1578"
              d="M330.546,4695.834c-2.068-4.219-15.615-7.069-15.615,2.429"
              fill="none"
              stroke={fill || '#839ca9'}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="5"
            />
          </g>
          <path
            id="Path_1579"
            data-name="Path 1579"
            d="M337.671,4698.066a3.764,3.764,0,1,0-3.764,3.764A3.764,3.764,0,0,0,337.671,4698.066Z"
            fill="#fff"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
        <g id="Group_505" data-name="Group 505">
          <g id="Group_501" data-name="Group 501">
            <path
              id="Path_1580"
              data-name="Path 1580"
              d="M358.948,4674.736c-4.218-2.069-7.068-15.615,2.429-15.615"
              fill="none"
              stroke={fill || '#839ca9'}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="5"
            />
            <path
              id="Path_1581"
              data-name="Path 1581"
              d="M363.806,4674.736c4.218-2.069,7.068-15.615-2.429-15.615"
              fill="none"
              stroke={fill || '#839ca9'}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="5"
            />
          </g>
          <g id="Group_502" data-name="Group 502">
            <path
              id="Path_1582"
              data-name="Path 1582"
              d="M364.261,4675.064c2.068-4.219,15.615-7.069,15.615,2.429"
              fill="none"
              stroke={fill || '#839ca9'}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="5"
            />
            <path
              id="Path_1583"
              data-name="Path 1583"
              d="M364.261,4679.922c2.068,4.218,15.615,7.068,15.615-2.429"
              fill="none"
              stroke={fill || '#839ca9'}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="5"
            />
          </g>
          <g id="Group_503" data-name="Group 503">
            <path
              id="Path_1584"
              data-name="Path 1584"
              d="M363.806,4680.249c4.218,2.069,7.068,15.615-2.429,15.615"
              fill="none"
              stroke={fill || '#839ca9'}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="5"
            />
            <path
              id="Path_1585"
              data-name="Path 1585"
              d="M358.948,4680.249c-4.218,2.069-7.068,15.615,2.429,15.615"
              fill="none"
              stroke={fill || '#839ca9'}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="5"
            />
          </g>
          <g id="Group_504" data-name="Group 504">
            <path
              id="Path_1586"
              data-name="Path 1586"
              d="M358.493,4679.922c-2.068,4.218-15.615,7.068-15.615-2.429"
              fill="none"
              stroke={fill || '#839ca9'}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="5"
            />
            <path
              id="Path_1587"
              data-name="Path 1587"
              d="M358.493,4675.064c-2.068-4.219-15.615-7.069-15.615,2.429"
              fill="none"
              stroke={fill || '#839ca9'}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="5"
            />
          </g>
          <path
            id="Path_1588"
            data-name="Path 1588"
            d="M365.618,4677.3a3.764,3.764,0,1,0-3.764,3.764A3.763,3.763,0,0,0,365.618,4677.3Z"
            fill="#fff"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
        <line
          id="Line_227"
          data-name="Line 227"
          x2="23.706"
          y2="18.334"
          transform="translate(337.671 4702.257)"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
        <line
          id="Line_228"
          data-name="Line 228"
          x1="23.706"
          y2="18.334"
          transform="translate(361.377 4702.257)"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
      </g>
      <g id="Group_507" data-name="Group 507" transform="translate(-214.876 -4585.16)">
        <line
          id="Line_229"
          data-name="Line 229"
          y2="58.454"
          transform="translate(301.713 4672.898)"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="5"
        />
        <path
          id="Path_1589"
          data-name="Path 1589"
          d="M307.34,4599.9a6.655,6.655,0,0,1-12.283-3.554c0-3.675,6.656-11.19,6.656-11.19s5.526,6.241,6.507,10.13"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="5"
        />
        <path
          id="Path_1590"
          data-name="Path 1590"
          d="M272.564,4623.386a6.656,6.656,0,0,1-3.53,12.3c-3.675,0-11.191-6.655-11.191-6.655s6.288-5.568,10.174-6.518"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="5"
        />
        <path
          id="Path_1591"
          data-name="Path 1591"
          d="M285.1,4604.461a6.656,6.656,0,0,1-11.2,6.167c-2.6-2.6-3.208-12.619-3.208-12.619s8.337.506,11.774,2.567"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="5"
        />
        <path
          id="Path_1592"
          data-name="Path 1592"
          d="M277.163,4645.638a6.656,6.656,0,0,1,6.148,11.2c-2.6,2.6-12.619,3.207-12.619,3.207s.507-8.348,2.572-11.781"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="5"
        />
        <path
          id="Path_1593"
          data-name="Path 1593"
          d="M295.5,4598.707a6.657,6.657,0,0,1-12.769,2.9c-1.447-3.379,1.712-12.907,1.712-12.907s9.076,4.288,10.523,7.667c.038.088.074.176.108.265"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="5"
        />
        <path
          id="Path_1594"
          data-name="Path 1594"
          d="M271.4,4635.243a6.656,6.656,0,0,1,2.894,12.769c-3.379,1.447-12.908-1.711-12.908-1.711s4.289-9.077,7.667-10.524c.087-.037.173-.072.261-.1"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="5"
        />
        <path
          id="Path_1595"
          data-name="Path 1595"
          d="M275.89,4612a6.656,6.656,0,0,1-6.989,11.069c-3.412-1.366-7.916-10.337-7.916-10.337s9.45-3.386,12.862-2.02c.088.035.175.072.26.11"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="5"
        />
        <path
          id="Path_1596"
          data-name="Path 1596"
          d="M284.664,4654.867a6.656,6.656,0,0,1,11.084,6.973c-1.366,3.412-10.337,7.917-10.337,7.917s-3.386-9.451-2.02-12.863c.037-.093.076-.184.117-.274"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="5"
        />
        <path
          id="Path_1597"
          data-name="Path 1597"
          d="M296.085,4658.153a6.656,6.656,0,0,1,12.283,3.554c0,3.676-6.655,11.191-6.655,11.191s-5.527-6.241-6.508-10.13"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="5"
        />
        <path
          id="Path_1598"
          data-name="Path 1598"
          d="M330.861,4634.672a6.656,6.656,0,0,1,3.53-12.3c3.675,0,11.191,6.655,11.191,6.655s-6.288,5.568-10.174,6.518"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="5"
        />
        <path
          id="Path_1599"
          data-name="Path 1599"
          d="M318.326,4653.6a6.656,6.656,0,0,1,11.2-6.167c2.6,2.6,3.208,12.619,3.208,12.619s-8.337-.506-11.774-2.567"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="5"
        />
        <path
          id="Path_1600"
          data-name="Path 1600"
          d="M326.262,4612.419a6.655,6.655,0,0,1-6.148-11.2c2.6-2.6,12.619-3.207,12.619-3.207s-.507,8.348-2.572,11.781"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="5"
        />
        <path
          id="Path_1601"
          data-name="Path 1601"
          d="M307.927,4659.35a6.656,6.656,0,0,1,12.769-2.9c1.447,3.378-1.712,12.907-1.712,12.907s-9.076-4.288-10.523-7.667c-.038-.088-.074-.177-.107-.265"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="5"
        />
        <path
          id="Path_1602"
          data-name="Path 1602"
          d="M332.025,4622.814a6.656,6.656,0,0,1-2.893-12.768c3.378-1.448,12.907,1.711,12.907,1.711s-4.288,9.077-7.667,10.524c-.087.037-.173.072-.26.105"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="5"
        />
        <path
          id="Path_1603"
          data-name="Path 1603"
          d="M327.535,4646.063a6.656,6.656,0,0,1,6.989-11.069c3.412,1.366,7.916,10.337,7.916,10.337s-9.45,3.386-12.862,2.02c-.088-.035-.175-.072-.26-.111"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="5"
        />
        <path
          id="Path_1604"
          data-name="Path 1604"
          d="M318.761,4603.191a6.657,6.657,0,0,1-11.084-6.974c1.366-3.412,10.338-7.916,10.338-7.916s3.385,9.45,2.019,12.863c-.037.092-.076.184-.117.274"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="5"
        />
        <ellipse
          id="Ellipse_94"
          data-name="Ellipse 94"
          cx="26.023"
          cy="25.776"
          rx="26.023"
          ry="25.776"
          transform="translate(275.689 4603.253)"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="5"
        />
      </g>
    </g>
  </svg>
);

Crop43.defaultProps = {
  title: 'oilcrops',
};

Crop43.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
};
