import {
  GET_PRODUCT_SELECT_LOADING,
  GET_PRODUCT_SELECT_SUCCESS,
  GET_PRODUCT_SELECT_FAILED,
  STATUS_GETTING,
  STATUS_GET_SUCCESS,
  STATUS_GET_FAILED,
} from 'Redux/actions/types';

const initialState = {
  status: STATUS_GETTING,
  statusCode: null,
  errorCode: null,
  data: null,
};

export default (state = initialState, action) => {
  const { payload, type } = action;

  switch (type) {
    case GET_PRODUCT_SELECT_LOADING:
      return {
        status: STATUS_GETTING,
        errorCode: null,
      };

    case GET_PRODUCT_SELECT_SUCCESS:
      return {
        data: payload,
        status: STATUS_GET_SUCCESS,
        errorCode: null,
      };

    case GET_PRODUCT_SELECT_FAILED:
      return {
        status: STATUS_GET_FAILED,
        statusCode: payload.statusCode,
        errorCode: payload.errorCode,
      };

    default:
      return state;
  }
};
