/* eslint-disable import/prefer-default-export */
import React from 'react';

export const CopyIcon = ({ ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="19" viewBox="0 0 15 19" {...props}>
    <g id="CC_NG_copy_icon" transform="translate(-1639.5 -647.5)">
      <path
        id="Path_1628"
        data-name="Path 1628"
        fill="none"
        strokeLinejoin="round"
        stroke="#c8d200"
        d="M0,0H12V16H10V2.079H0Z"
        transform="translate(1642 648)"
      />
      <path
        id="Path_1629"
        data-name="Path 1629"
        fill="none"
        strokeLinejoin="round"
        stroke="#71bf44"
        d="M0,0H12V16H0Z"
        transform="translate(1640 650)"
      />
    </g>
  </svg>
);

export default CopyIcon;
