import { supportedCountries } from 'constants/index.js';
import { getUrlParams } from 'Utils/GlobalUtils';
import { isLive } from 'data/Config';

class LocalizationService {
  static allLocales = () => {
    let allLocales = [];
    supportedCountries.forEach(({ code, languages }) => {
      languages.forEach((languageCode) => {
        allLocales.push(`${languageCode.toLowerCase()}_${code}`);
      });
    });
    if (isLive()) {
      allLocales = allLocales.filter((locale) => {
        const [lang] = locale.split('_');
        return lang !== 'en';
      });
    }
    return allLocales;
  };

  static allLanguages = () => {
    let allLanguages = [];
    supportedCountries.forEach(({ languages }) => {
      languages.forEach((languageCode) => {
        allLanguages.push(languageCode);
      });
    });
    if (isLive()) {
      allLanguages = allLanguages.filter((languageCode) => languageCode !== 'EN');
    }
    return allLanguages;
  };

  static allCountries = () => {
    const allCountries = supportedCountries.map(({ code }) => code);
    return allCountries;
  };

  static getStringWithReplacedLocale(string) {
    let replacedString = string;
    this.allLocales().forEach((supportedLocale) => {
      const languageCode = supportedLocale.split('_')[0].toUpperCase();
      if (string.includes('languageCode=')) {
        replacedString = replacedString.replace(
          `languageCode=${supportedLocale}`,
          `languageCode=${languageCode}`,
        );
      }
      if (string.includes('languageCode:')) {
        replacedString = replacedString.replace(
          `languageCode:${supportedLocale}`,
          `languageCode:${languageCode}`,
        );
      }
    });
    return replacedString;
  }

  static getUrlWithConvertedLanguageCode = (url) => this.getStringWithReplacedLocale(url);

  static getBodyWithConvertedLangaugeCode = (body) =>
    JSON.parse(this.getStringWithReplacedLocale(JSON.stringify(body)));

  static getCountryForLocale = (locale) => {
    const [languageCode, countryCode] = locale.split('_');
    const countriesWithLanguageCode = supportedCountries.filter(({ code, languages }) => {
      if (countryCode) {
        return countryCode === code;
      }
      return languages.includes(languageCode);
    });
    if (countriesWithLanguageCode.length === 0) {
      return languageCode;
    }
    return countriesWithLanguageCode[0].code;
  };

  static getMainLanguageForCountry = (countryCode) => {
    const countriesWithCountryCode = supportedCountries.filter(({ code }) => code === countryCode);
    return countriesWithCountryCode[0].languages[0];
  };

  static getLangCodeFromString = (string) => string.substr(0, 2).toUpperCase();

  static getLocaleFromUrl = (query) => {
    let localeFromUrl = getUrlParams(query);
    localeFromUrl =
      localeFromUrl.l || localeFromUrl.lang || localeFromUrl.language || localeFromUrl.locale;
    return localeFromUrl;
  };

  // This is needed for backwards compatibility, in the future the user will be able to select only locales
  static getLocaleForLang = (lang) => {
    const supportedLocale = this.allLocales().filter((locale) => {
      const languageCode = locale.split('_')[0];
      const language = lang.split('_')[0];
      return language.toLowerCase() === languageCode;
    });
    return supportedLocale[0];
  };

  static getFirstAvailableLocaleForLang = (lang) => {
    const supportedLocale = this.allLocales().filter((locale) => {
      const languageCode = locale.split('_')[0];
      const language = lang.split('_')[0];
      return language.toLowerCase() === languageCode;
    });
    return supportedLocale[0];
  };

  static getLocaleFromLangAndCountry = (lang, country) => {
    return `${lang !== 'UK' ? lang.toLowerCase() : 'en'}_${country.toUpperCase()}`;
  }

  static createLocaleFromLangAndCountry = (lang, country) => {
    const usedLang = lang.split('_')[0];
    return `${lang !== 'UK' ? usedLang.toLowerCase() : 'en'}_${country.toUpperCase()}`;
  };
}

export default LocalizationService;
