/* eslint-disable import/prefer-default-export */
/* eslint-disable react/prop-types */
import React from 'react';
import { PropTypes } from 'prop-types';
import { useLocalizedFromContext } from 'data/Localization';

export const Crop16 = ({ title, fill, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="83.579"
    height="156.62"
    viewBox="0 0 83.579 156.62"
    {...props}
  >
    <title>{useLocalizedFromContext(title)}</title>
    <g id="Component_2_1" dataname="Component 2 – 1" transform="translate(2.521 2.502)">
      <path
        id="Path_1382"
        dataname="Path 1382"
        d="M2149.222,7082.168s-2.623,16.692,3.051,48.8,1.213,69.527-5.8,81.567"
        transform="translate(-2114.186 -7061.832)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_1383"
        dataname="Path 1383"
        d="M2174.031,7108.153s0,8.852-5.041,14.352c-6.451,7.038-15.085,15.533-14.629,25.679"
        transform="translate(-2114.186 -7061.832)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_1384"
        dataname="Path 1384"
        d="M2127.3,7126.087s1.456,33.859,27.043,50.485"
        transform="translate(-2114.186 -7061.832)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <g id="Group_472" dataname="Group 472" transform="translate(-2114.186 -7061.832)">
        <path
          id="Path_1385"
          dataname="Path 1385"
          d="M2139.228,7122.243c-.922,3.618-2.9,4.175-5.017,3.635s-3.587-1.974-2.665-5.593,3.883-8.052,6.005-7.511S2140.15,7118.624,2139.228,7122.243Z"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
        <path
          id="Path_1386"
          dataname="Path 1386"
          d="M2136.7,7109.718c-.327,3.72-2.185,4.588-4.366,4.4s-3.859-1.371-3.531-5.091,2.536-8.572,4.716-8.38S2137.022,7106,2136.7,7109.718Z"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
        <path
          id="Path_1387"
          dataname="Path 1387"
          d="M2126.8,7108.844c.487,3.7-1.138,4.952-3.309,5.238s-4.064-.5-4.551-4.2.617-8.918,2.787-9.2S2126.312,7105.142,2126.8,7108.844Z"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
        <path
          id="Path_1388"
          dataname="Path 1388"
          d="M2131.265,7121.326c0,3.734-1.775,4.761-3.964,4.761s-3.964-1.027-3.964-4.761,1.775-8.762,3.964-8.762S2131.265,7117.592,2131.265,7121.326Z"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
        <path
          id="Path_1389"
          dataname="Path 1389"
          d="M2131.265,7098.981c0,3.734-1.775,4.761-3.964,4.761s-3.964-1.027-3.964-4.761,1.775-8.761,3.964-8.761S2131.265,7095.247,2131.265,7098.981Z"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
        <path
          id="Path_1390"
          dataname="Path 1390"
          d="M2122.241,7120.481c.757,3.657-.773,5.022-2.917,5.466s-4.09-.2-4.847-3.859-.037-8.939,2.106-9.383S2121.484,7116.824,2122.241,7120.481Z"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
      </g>
      <g id="Group_473" dataname="Group 473" transform="translate(-2114.186 -7061.832)">
        <path
          id="Path_1391"
          dataname="Path 1391"
          d="M2186.2,7104.917c-1.217,3.53-3.229,3.923-5.3,3.21s-3.413-2.263-2.2-5.793,4.532-7.705,6.6-6.992S2187.413,7101.387,2186.2,7104.917Z"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
        <path
          id="Path_1392"
          dataname="Path 1392"
          d="M2184.7,7092.226c-.632,3.681-2.556,4.392-4.713,4.022s-3.733-1.684-3.1-5.364,3.233-8.334,5.391-7.964S2185.334,7088.546,2184.7,7092.226Z"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
        <path
          id="Path_1393"
          dataname="Path 1393"
          d="M2174.911,7090.541c.181,3.73-1.542,4.842-3.728,4.948s-4.009-.834-4.19-4.564,1.348-8.837,3.535-8.943S2174.731,7086.811,2174.911,7090.541Z"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
        <path
          id="Path_1394"
          dataname="Path 1394"
          d="M2178.335,7103.348c-.307,3.721-2.161,4.6-4.342,4.419s-3.866-1.35-3.559-5.071,2.489-8.586,4.671-8.406S2178.642,7099.626,2178.335,7103.348Z"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
        <path
          id="Path_1395"
          dataname="Path 1395"
          d="M2180.173,7081.079c-.307,3.721-2.16,4.6-4.342,4.419s-3.866-1.35-3.559-5.071,2.49-8.586,4.672-8.406S2180.481,7077.357,2180.173,7081.079Z"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
        <path
          id="Path_1396"
          dataname="Path 1396"
          d="M2169.411,7101.763c.453,3.707-1.183,4.942-3.356,5.208s-4.06-.539-4.513-4.245.7-8.912,2.871-9.178S2168.958,7098.057,2169.411,7101.763Z"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
      </g>
      <g id="Group_474" dataname="Group 474" transform="translate(-2114.186 -7061.832)">
        <path
          id="Path_1397"
          dataname="Path 1397"
          d="M2191.963,7140.031s-6.049-2.585-9.572-.014-3.689,8.833-3.689,8.833"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
        <path
          id="Path_1398"
          dataname="Path 1398"
          d="M2191.963,7140.031s.045,6.578-3.689,8.833-9.572-.014-9.572-.014-14.515,1.018-23.846,12.785"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
      </g>
      <g id="Group_475" dataname="Group 475" transform="translate(-2114.186 -7061.832)">
        <path
          id="Path_1399"
          dataname="Path 1399"
          d="M2189.9,7165.937s.009-6.578-3.737-8.813-9.571.067-9.571.067"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
        <path
          id="Path_1400"
          dataname="Path 1400"
          d="M2189.9,7165.937s-6.034,2.618-9.571.067-3.737-8.813-3.737-8.813-5.346-4.2-9.863-4.882"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
      </g>
      <path
        id="Path_1401"
        dataname="Path 1401"
        d="M2155.772,7075.5c-.463,5.614-3.259,6.937-6.55,6.666s-5.832-2.036-5.368-7.65,3.755-12.951,7.046-12.679S2156.235,7069.888,2155.772,7075.5Z"
        transform="translate(-2114.186 -7061.832)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
    </g>
  </svg>
);

Crop16.defaultProps = {
  title: 'lucerne',
};

Crop16.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
};
