import { unionBy } from 'lodash';
import {
  STATUS_DEFAULT,
  MY_CROP_ROTATIONS_LOADING,
  MY_CROP_ROTATIONS_SUCCESS,
  MY_CROP_ROTATIONS_FAILED,
  MY_CROP_ROTATIONS_SUCCESS_NG4,
  DELETE_MY_CROP_ROTATION_LOADING,
  DELETE_MY_CROP_ROTATION_SUCCESS,
  DELETE_MY_CROP_ROTATION_FAILED,
  POST_MY_CROP_ROTATION_LOADING,
  POST_MY_CROP_ROTATION_SUCCESS,
  POST_MY_CROP_ROTATION_FAILED,
  POST_MY_CROP_ROTATION_SUCCESS_NG4,
  RESET_MY_CROP_ROTATION_STATUS,
  RESET_MY_CROP_ROTATIONS,
  DELETE_CROP_ROTATION_FOR_PLOT_ID,
  SET_CROP_ROTATIONS,
} from 'Redux/actions/types';
import CropRotationService from 'services/CropRotationService';

export const initialMyCropRotationsState = {
  status: STATUS_DEFAULT,
  data: [],
  errorCode: null,
};

export default (state = initialMyCropRotationsState, action) => {
  const { type, payload } = action;
  switch (type) {
    case MY_CROP_ROTATIONS_LOADING:
      return {
        ...state,
        status: MY_CROP_ROTATIONS_LOADING,
      };
    case MY_CROP_ROTATIONS_SUCCESS:
      return {
        status: MY_CROP_ROTATIONS_SUCCESS,
        data: new CropRotationService().convertCropRotationsToFrontEnd(payload),
        errorCode: null,
      };
    case MY_CROP_ROTATIONS_SUCCESS_NG4:
      return {
        status: MY_CROP_ROTATIONS_SUCCESS,
        data: new CropRotationService().convertCropRotationsToFrontEndNG4(payload),
        errorCode: null,
      };
    case MY_CROP_ROTATIONS_FAILED:
      return {
        ...state,
        status: MY_CROP_ROTATIONS_FAILED,
        errorCode: payload.errorCode,
      };
    case DELETE_MY_CROP_ROTATION_LOADING:
      return {
        ...state,
        status: DELETE_MY_CROP_ROTATION_LOADING,
      };
    case DELETE_MY_CROP_ROTATION_SUCCESS:
      return {
        status: DELETE_MY_CROP_ROTATION_SUCCESS,
        data: state.data.filter((plot) => plot.id !== payload),
        errorCode: null,
      };
    case DELETE_MY_CROP_ROTATION_FAILED:
      return {
        ...state,
        status: DELETE_MY_CROP_ROTATION_FAILED,
        errorCode: payload.errorCode,
      };
    case POST_MY_CROP_ROTATION_LOADING:
      return {
        ...state,
        status: POST_MY_CROP_ROTATION_LOADING,
      };
    case POST_MY_CROP_ROTATION_SUCCESS:
      return {
        ...state,
        status: POST_MY_CROP_ROTATION_SUCCESS,
        data: unionBy(
          new CropRotationService().convertCropRotationsToFrontEnd([payload]),
          state.data,
          'id',
        ),
        errorCode: null,
      };
    case POST_MY_CROP_ROTATION_SUCCESS_NG4:
      return {
        ...state,
        status: POST_MY_CROP_ROTATION_SUCCESS,
        data: unionBy(
          new CropRotationService().convertCropRotationsToFrontEndNG4([payload]),
          state.data,
          'id',
        ),
        errorCode: null,
      };
    case POST_MY_CROP_ROTATION_FAILED:
      return {
        ...state,
        status: POST_MY_CROP_ROTATION_FAILED,
        errorCode: payload.errorCode,
      };
    case RESET_MY_CROP_ROTATIONS:
      return {
        ...initialMyCropRotationsState,
      };
    case DELETE_CROP_ROTATION_FOR_PLOT_ID:
      return {
        ...state,
        data: state.data.filter(({ plot: { id } }) => id !== payload),
      };
    case SET_CROP_ROTATIONS:
      return {
        ...state,
        data: payload,
      };
    case RESET_MY_CROP_ROTATION_STATUS:
      return {
        ...state,
        status: STATUS_DEFAULT,
      };
    default:
      return state;
  }
};
