/* eslint-disable react/prop-types */
/* eslint-disable import/prefer-default-export */
import React from 'react';

const MyApplicationsIcon = ({ clipPathId, ...props }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 70 70"
      enableBackground="new 0 0 70 70"
      {...props}
      width="70"
      height="70"
    >
      <path fill="none" d="M36,43.4C36.1,43.3,36,43.4,36,43.4L36,43.4z" />
      <path fill="none" d="M28.5,32.7l0.3-0.1c0-0.1,0-0.2,0-0.2C28.7,32.5,28.6,32.6,28.5,32.7z" />
      <path
        fill="none"
        d="M24.5,31.8c0.4-0.1,0.7-0.7,0.7-2c0-1.1-0.3-2.3-0.7-3.2c-0.3,1-0.7,2.1-0.7,3.3c0,0.9,0.2,1.5,0.5,1.8C24.4,31.7,24.5,31.8,24.5,31.8z"
      />
      <path
        fill="none"
        d="M32.8,22.4c0,0.6,0.5,1.1,1.1,1.1c0.6,0,1.1-0.5,1.1-1.1c0-0.6-0.5-1.1-1.1-1.1l-0.5,0.1C33.1,21.6,32.8,22,32.8,22.4z"
      />
      <path fill="none" d="M36.2,46.6v-0.2c0,0-0.1,0-0.2,0C36.1,46.4,36.1,46.5,36.2,46.6z" />
      <path
        fill="none"
        d="M53.3,21.4c0,0.6,0.5,1.1,1.1,1.1c0.6,0,1.1-0.5,1.1-1.1c0-0.6-0.5-1.1-1.1-1.1C53.8,20.3,53.3,20.8,53.3,21.4z"
      />
      <path fill="none" d="M44.9,18.7L44,19c0.1,0,0.1,0,0.2,0C44.5,19,44.7,18.9,44.9,18.7z" />
      <path
        fill="none"
        d="M40.5,46.1c-0.4,0.1-0.7,0.1-1,0.1c-0.1,0-0.2,0-0.3,0v2.5c0.6,0.4,1.2,0.9,1.7,1.4l6.9-1.6c-1.8-1.3-3.6-2.5-5.4-3.6C41.8,45.5,41.2,45.9,40.5,46.1z"
      />
      <path fill="none" d="M17.6,52.8h8.5c-2.9-2-6.5-2.6-8.5-2.8V52.8z" />
      <path
        fill="none"
        d="M50.5,46.7c1.5-1.2,3.3-1.7,4.6-1.9v-5.2c-2.1,0.4-6,1.3-9.6,3.7C47.2,44.3,48.8,45.5,50.5,46.7z"
      />
      <g>
        <path
          fill="#79B61D"
          d="M38,22.4c0-0.7-0.2-1.4-0.5-1.9l-3.6,0.8c0.6,0,1.1,0.5,1.1,1.1c0,0.6-0.5,1.1-1.1,1.1c-0.6,0-1.1-0.5-1.1-1.1c0-0.4,0.3-0.8,0.6-1l-3.6,0.8c0,0,0,0.1,0,0.1c0,2.3,1.8,4.1,4.1,4.1C36.2,26.5,38,24.7,38,22.4z"
        />
        <path
          fill="#79B61D"
          d="M37.6,28.2c-0.5,0-0.9,0.3-1.2,0.7c-0.1,0.1-0.6,0.9-1.2,2.2l4.4-1c-0.4-0.8-0.8-1.2-0.8-1.3C38.5,28.4,38,28.2,37.6,28.2z"
        />
        <path
          fill="#79B61D"
          d="M44.2,19c-0.1,0-0.2,0-0.2,0l-3.4,0.8c0.7,1.3,2,2.2,3.6,2.2c2.3,0,4.1-1.8,4.1-4.1l-3.4,0.8C44.7,18.9,44.5,19,44.2,19z"
        />
        <path
          fill="#79B61D"
          d="M54.4,17.3c-2.3,0-4.1,1.8-4.1,4.1c0,2.2,1.8,4.1,4.1,4.1c2.3,0,4.1-1.8,4.1-4.1S56.7,17.3,54.4,17.3zM54.4,22.5c-0.6,0-1.1-0.5-1.1-1.1c0-0.6,0.5-1.1,1.1-1.1c0.6,0,1.1,0.5,1.1,1.1C55.5,22,55,22.5,54.4,22.5z"
        />
        <path
          fill="#79B61D"
          d="M31.1,28.2c-0.4-0.2-0.9-0.2-1.4,0c-0.1,0-0.6,0.3-1.4,0.8c-0.3-2.6-1.5-4.8-2.2-5.9L22.5,24c-0.6,1.2-1.4,3.2-1.5,5.2c-0.7-0.5-1.3-0.8-1.3-0.8c-0.4-0.2-0.9-0.2-1.4,0c-0.4,0.2-0.7,0.6-0.8,1.1c0,0.1-0.4,2.7,0.5,5.5c-1.3,0-2,0-2.1,0c-0.6,0.1-1.1,0.4-1.3,0.9l6.2-1.4c-0.2-0.7-0.4-1.4-0.5-2c0.6,0.5,1.2,1.1,1.7,1.7l6.5-1.5c0.1-0.1,0.2-0.2,0.3-0.3c0,0.1,0,0.2,0,0.2l3.2-0.8c0.1-1.4-0.1-2.4-0.1-2.6C31.8,28.8,31.5,28.4,31.1,28.2z M24.5,31.8c0,0-0.1-0.1-0.2-0.1c-0.3-0.3-0.5-0.9-0.5-1.8c0-1.2,0.4-2.3,0.7-3.3c0.4,0.9,0.7,2.1,0.7,3.2C25.2,31.1,24.9,31.7,24.5,31.8z"
        />
      </g>
      <g>
        <path
          fill="#EB6909"
          d="M17.5,47v-1.6l-3,0.7v8.2c0,0.8,0.7,1.5,1.5,1.5h0.8l13.4-3.1C26.5,48.4,20.3,47.3,17.5,47z M17.6,52.8V50c2,0.2,5.6,0.8,8.5,2.8H17.6z"
        />
        <path
          fill="#EB6909"
          d="M49.8,37.9c-1.6,0.5-3.4,1.3-5.1,2.3c0.1-0.4,0.1-0.8,0.2-1.2l-3.1,0.7c-0.2,0.7-0.4,1.4-0.7,2.1c-0.6,1.1-1.2,1.5-1.6,1.4c0,0-0.1-0.1-0.1-0.2c-0.1-0.4,0-1.1,0.5-1.8c0.2-0.4,0.5-0.8,0.8-1.2l-5,1.2c0,0,0.1,0.1,0.1,0.1c0.6,1.1,0.6,1.8,0.3,2.1c0,0,0,0-0.1,0c-0.4,0.1-1-0.3-1.6-1.4c-0.1-0.1-0.2-0.3-0.2-0.5l-2.7,0.6c0.2,0.4,0.3,0.9,0.6,1.3c0.2,0.4,0.5,0.8,0.7,1.1c-1.5-0.7-2.9-1.3-4.2-1.7l-6.2,1.4c4.1,0.7,9.7,2.4,15.1,6.6l3.6-0.8c-0.6-0.5-1.1-1-1.7-1.4v-2.5c0.1,0,0.2,0,0.3,0c0.3,0,0.6,0,1-0.1c0.7-0.2,1.3-0.6,1.9-1.2c1.9,1.1,3.7,2.3,5.4,3.6l10-2.3V36L49.8,37.9z M55.1,44.8c-1.3,0.2-3.1,0.7-4.6,1.9c-1.7-1.2-3.3-2.4-5-3.4c3.6-2.4,7.5-3.3,9.6-3.7V44.8z"
        />
      </g>
      <path
        fill="#B95700"
        d="M57,55.7c0.2-0.1,0.3-0.2,0.4-0.3c0.1-0.1,0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.3,0.1-0.5v-8.2l-10,2.3c1.8,1.4,3.5,2.8,5.2,4.4h-9.2c-0.9-1-1.9-1.9-2.9-2.8L37.3,51c0.7,0.6,1.5,1.2,2.2,1.8h-9.1v0.1c-0.1-0.1-0.1-0.2-0.2-0.2l-13.4,3.1h39.7C56.7,55.8,56.8,55.8,57,55.7z M55.1,50.7c-0.7-0.7-1.5-1.4-2.3-2c0.7-0.5,1.6-0.7,2.3-0.9V50.7z"
      />
      <path
        fill="#5F9501"
        d="M17.5,43.9c1.1,0,2.7,0.1,4.7,0.4l6.2-1.4c-0.8-0.3-1.5-0.5-2.3-0.7v-2.3c0.1,0,0.2,0,0.3,0c0.3,0,0.6,0,1-0.1c0.3-0.1,0.7-0.3,1-0.4c0.8,0.2,1.6,0.4,2.3,0.6c0.1,0.7,0.3,1.4,0.6,2.2l2.7-0.6c-0.4-0.9-0.6-1.9-0.8-2.7c1,0.7,1.8,1.4,2.3,2.4l5-1.2c0.4-0.5,0.9-0.9,1.4-1.3c-0.1,0.3-0.2,0.7-0.3,1l3.1-0.7c0.2-1.9,0-3.3,0-3.4c-0.1-0.5-0.4-0.9-0.8-1.1c-0.4-0.2-0.9-0.2-1.4,0c-0.1,0-0.6,0.3-1.4,0.8c0-0.2-0.1-0.5-0.1-0.7c5.6-2.7,11.3-3.3,13.9-3.4v5.4c-1.2,0.2-3.1,0.5-5.3,1.3l8-1.9v-6.3c0.3-0.9-0.3-1.5-1.2-1.5c-0.4,0-8.2-0.1-16.2,3.5c-0.3-0.6-0.5-1.2-0.8-1.6l-4.4,1c-0.5,1.2-1,2.8-1.1,4.4c-0.7-0.5-1.3-0.8-1.3-0.8c-0.4-0.2-0.9-0.2-1.3,0c0.3-1,0.4-2,0.5-2.8l-3.2,0.8c-0.2,0.9-0.4,2-1,2.9c-0.6,1.1-1.2,1.5-1.6,1.4c0,0-0.1-0.1-0.1-0.2c-0.1-0.4,0-1.1,0.5-1.8c0.4-0.9,1.2-1.6,1.9-2.2L22,34.2c0.2,0.3,0.4,0.5,0.6,0.8c0.6,1.1,0.6,1.8,0.3,2.1c0,0-0.2,0-0.3,0c-0.4-0.1-0.9-0.6-1.3-1.4c-0.2-0.4-0.4-0.8-0.5-1.2l-6.2,1.4c-0.1,0.2-0.1,0.4-0.1,0.6v9.6l3-0.7V43.9z M37.7,32.9c0.2,0.4,0.3,0.8,0.4,1.2v0.1c0.2,0.6,0.3,1.3,0.3,1.9c0,1.3-0.3,1.9-0.7,2c0,0-0.1-0.1-0.2-0.1c-0.3-0.3-0.5-1-0.5-1.8C37,35,37.3,33.9,37.7,32.9z M17.6,37.9c0.4,0.1,1,0.1,1.7,0.1c0.7,1,1.6,1.7,2.5,1.9c0.4,0.1,0.7,0.1,1,0.1c0.1,0,0.2,0,0.3,0v1.4c-2.3-0.4-4.2-0.5-5.5-0.5V37.9z"
      />
      <path
        fill="#C8D200"
        d="M33.9,21.3c-0.2,0-0.3,0.1-0.5,0.1L33.9,21.3C33.9,21.3,33.9,21.3,33.9,21.3z"
      />
      <path
        fill="#C8D200"
        d="M25.7,22.5c-0.3-0.4-0.8-0.6-1.2-0.6c-0.5,0-0.9,0.3-1.2,0.7c0,0-0.4,0.6-0.8,1.4l3.6-0.9C25.9,22.8,25.7,22.5,25.7,22.5z"
      />
      <g>
        <path
          fill="#C8D200"
          d="M16.7,15.4c-2.3,0-4.1,1.8-4.1,4.1s1.8,4.1,4.1,4.1s4.1-1.8,4.1-4.1S19,15.4,16.7,15.4z M16.7,20.6c-0.6,0-1.1-0.4-1.1-1.1c0-0.6,0.5-1.1,1.1-1.1c0.6,0,1.1,0.5,1.1,1.1C17.8,20.1,17.3,20.6,16.7,20.6z"
        />
        <path
          fill="#C8D200"
          d="M44.2,13.8c-2.3,0-4.1,1.8-4.1,4.1c0,0.7,0.2,1.3,0.5,1.9L44,19c-0.5-0.1-0.9-0.5-0.9-1.1c0-0.6,0.5-1.1,1.1-1.1c0.6,0,1.1,0.5,1.1,1.1c0,0.3-0.2,0.6-0.4,0.8l3.4-0.8c0,0,0,0,0,0C48.3,15.6,46.5,13.8,44.2,13.8z"
        />
        <path
          fill="#C8D200"
          d="M33.9,18.3c-2.3,0-4,1.7-4.1,4l3.6-0.8c0.1-0.1,0.3-0.1,0.5-0.1c0,0,0,0,0,0l3.6-0.8C36.8,19.2,35.5,18.3,33.9,18.3z"
        />
        <path
          fill="#C8D200"
          d="M31.9,13.5c0-2.3-1.9-4-4.1-4s-4.1,1.8-4.1,4.1c0,2.3,1.8,4,4.1,4S31.9,15.8,31.9,13.5z M27.8,14.7c-0.6,0-1.1-0.5-1.1-1.1c0-0.6,0.5-1.1,1.1-1.1c0.6,0,1.1,0.5,1.1,1.1C28.9,14.2,28.4,14.7,27.8,14.7z"
        />
      </g>
    </svg>
  );
};

export default MyApplicationsIcon;
