/* eslint-disable import/prefer-default-export */
/* eslint-disable react/prop-types */
import React from 'react';
import { PropTypes } from 'prop-types';
import { useLocalizedFromContext } from 'data/Localization';

export const Crop26 = ({ title, fill, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="96.995"
    height="79.984"
    viewBox="0 0 96.995 79.984"
    {...props}
  >
    <title>{useLocalizedFromContext(title)}</title>
    <g id="Component_3_1" dataname="Component 3 – 1" transform="translate(2.519 2.515)">
      <path
        id="Path_4726"
        dataname="Path 4726"
        d="M2803.84,5289.326c-.866-2.206-2.667-5.206-6.117-5.478a9.258,9.258,0,0,0-8.267,3.916,10.006,10.006,0,0,0,2.546,4.922"
        transform="translate(-2753.534 -5258.019)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_4727"
        dataname="Path 4727"
        d="M2801.142,5301.08a7.825,7.825,0,0,0,7.611-.375,8.457,8.457,0,0,0,3.734-4.911"
        transform="translate(-2753.534 -5258.019)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_4728"
        dataname="Path 4728"
        d="M2810.934,5291.3c-2.149-1-5.577-1.966-8.284.255a8.915,8.915,0,0,0-2.9,4.248"
        transform="translate(-2753.534 -5258.019)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_4729"
        dataname="Path 4729"
        d="M2796.759,5276.679s-6.069-4.427-10.343-.92a9.26,9.26,0,0,0-3.244,8.553s4.775,3.581,9.347.593A9.693,9.693,0,0,0,2796.759,5276.679Z"
        transform="translate(-2753.534 -5258.019)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_4730"
        dataname="Path 4730"
        d="M2805.667,5281.42a7.275,7.275,0,0,0-.987,5.469,9.061,9.061,0,0,0,1.43,3.318"
        transform="translate(-2753.534 -5258.019)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_4731"
        dataname="Path 4731"
        d="M2815.711,5285.929a3.911,3.911,0,0,0-.3-1.439,8.952,8.952,0,0,0-4.01-5"
        transform="translate(-2753.534 -5258.019)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_4732"
        dataname="Path 4732"
        d="M2790.665,5293.327a9.4,9.4,0,0,0-2.457-6.466s-5.959-.327-8.1,4.7a9.7,9.7,0,0,0,1.041,9.2s4.928.205,7.653-2.532"
        transform="translate(-2753.534 -5258.019)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_4733"
        dataname="Path 4733"
        d="M2804.538,5281.354s7.5.313,9.112-4.977a9.259,9.259,0,0,0-2.051-8.915s-5.96-.327-8.1,4.7A9.693,9.693,0,0,0,2804.538,5281.354Z"
        transform="translate(-2753.534 -5258.019)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_4734"
        dataname="Path 4734"
        d="M2818.7,5287.148c2.1-1.009,4.645-2.887,4.779-6.1a9.26,9.26,0,0,0-4.219-8.117s-5.851,1.177-6.667,6.577"
        transform="translate(-2753.534 -5258.019)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_4735"
        dataname="Path 4735"
        d="M2774.294,5281.021a9.26,9.26,0,0,0-8.7-2.823s-3.344,4.944-.136,9.364a9.7,9.7,0,0,0,8.424,3.834"
        transform="translate(-2753.534 -5258.019)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_4736"
        dataname="Path 4736"
        d="M2790.03,5266.567a9.4,9.4,0,0,0-2.671-6.329s-5.967-.128-7.941,4.965a9.128,9.128,0,0,0-.209,6.053"
        transform="translate(-2753.534 -5258.019)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_4737"
        dataname="Path 4737"
        d="M2805.344,5269.19a5.821,5.821,0,0,0-.292-2.171,9.258,9.258,0,0,0-6.811-6.107,9.5,9.5,0,0,0-3.97,5.677"
        transform="translate(-2753.534 -5258.019)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_4738"
        dataname="Path 4738"
        d="M2795.544,5275.619a9.29,9.29,0,0,0,.522-8.428s-5.527-2.252-9.191,1.8a9.186,9.186,0,0,0-2.379,6.036"
        transform="translate(-2753.534 -5258.019)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_4739"
        dataname="Path 4739"
        d="M2785.205,5276.75a6.091,6.091,0,0,0-2.556-3.875,9.259,9.259,0,0,0-9.125-.641s-2.053,5.6,2.126,9.121"
        transform="translate(-2753.534 -5258.019)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_4740"
        dataname="Path 4740"
        d="M2784.308,5286.646a9.258,9.258,0,0,0-6.811-6.106s-5.06,3.164-3.919,8.506a9.234,9.234,0,0,0,.809,2.285"
        transform="translate(-2753.534 -5258.019)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_4741"
        dataname="Path 4741"
        d="M2779.01,5294.772a9.459,9.459,0,0,0-4.8-3.35s-5.29,2.765-4.564,8.178a9.7,9.7,0,0,0,5.6,7.372,13.97,13.97,0,0,0,2.257-1.612"
        transform="translate(-2753.534 -5258.019)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_4742"
        dataname="Path 4742"
        d="M2780.265,5299.2a9.447,9.447,0,0,0-3.752,2.6s.332,5.96,5.561,7.536a9.1,9.1,0,0,0,5.716-.134"
        transform="translate(-2753.534 -5258.019)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_4743"
        dataname="Path 4743"
        d="M2790.949,5303.531a7.117,7.117,0,0,0-2.276,2.769,9.7,9.7,0,0,0,.692,9.229s7.488.6,9.295-4.63"
        transform="translate(-2753.534 -5258.019)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_4744"
        dataname="Path 4744"
        d="M2798.129,5306.164a9.694,9.694,0,0,0,3.583,8.533s7.291-1.809,7.347-7.337"
        transform="translate(-2753.534 -5258.019)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_4745"
        dataname="Path 4745"
        d="M2799.286,5306.075s3.7-6.538-.281-10.379a9.258,9.258,0,0,0-8.87-2.235s-3.006,5.156.49,9.353A9.7,9.7,0,0,0,2799.286,5306.075Z"
        transform="translate(-2753.534 -5258.019)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_4746"
        dataname="Path 4746"
        d="M2820.319,5300.16s4.784-5.79,1.541-10.268a9.26,9.26,0,0,0-8.342-3.753s-3.863,4.55-1.155,9.294A9.7,9.7,0,0,0,2820.319,5300.16Z"
        transform="translate(-2753.534 -5258.019)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_4747"
        dataname="Path 4747"
        d="M2771.268,5316.816a8.734,8.734,0,0,0-2.049,1.324s-.542,5.946,4.4,8.27a9.693,9.693,0,0,0,9.228-.711,20.974,20.974,0,0,0-.9-4.983"
        transform="translate(-2753.534 -5258.019)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_4748"
        dataname="Path 4748"
        d="M2840.839,5328.17s.583-7.489-4.646-9.286a9.259,9.259,0,0,0-8.983,1.728s-.542,5.944,4.4,8.267A9.7,9.7,0,0,0,2840.839,5328.17Z"
        transform="translate(-2753.534 -5258.019)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_4749"
        dataname="Path 4749"
        d="M2839.745,5322.1a6.783,6.783,0,0,0,5.124-4,9.259,9.259,0,0,0-1.391-9.042s-5.919-.764-8.426,4.089a9.044,9.044,0,0,0-.986,5.058"
        transform="translate(-2753.534 -5258.019)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_4750"
        dataname="Path 4750"
        d="M2797.012,5327.1a7.467,7.467,0,0,0,4.054,5,9.7,9.7,0,0,0,9.228-.708s.583-7.489-4.646-9.287"
        transform="translate(-2753.534 -5258.019)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_4751"
        dataname="Path 4751"
        d="M2785.2,5323.023a7.122,7.122,0,0,0,.394,3.563,9.694,9.694,0,0,0,7.094,5.944s6.282-5.847,3.8-10.787"
        transform="translate(-2753.534 -5258.019)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_4752"
        dataname="Path 4752"
        d="M2806.045,5319.263a7.468,7.468,0,0,0,4.965,4.1,9.7,9.7,0,0,0,8.905-2.523,13.548,13.548,0,0,0-1.962-5.274"
        transform="translate(-2753.534 -5258.019)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_4753"
        dataname="Path 4753"
        d="M2828.869,5304.173a5.9,5.9,0,0,0,3.635-.511,9.26,9.26,0,0,0,5.13-7.574,9.878,9.878,0,0,0-5.079-3.033"
        transform="translate(-2753.534 -5258.019)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_4754"
        dataname="Path 4754"
        d="M2829.641,5285.5a6.156,6.156,0,0,0,3.263-3.585,9.26,9.26,0,0,0-1.778-8.973,9.491,9.491,0,0,0-6.6,2.17"
        transform="translate(-2753.534 -5258.019)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_4755"
        dataname="Path 4755"
        d="M2823.176,5277.023s4.785-5.79,1.542-10.268a9.261,9.261,0,0,0-8.343-3.754"
        transform="translate(-2753.534 -5258.019)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_4756"
        dataname="Path 4756"
        d="M2813.737,5270.658c1.193-1.839,2.995-5.484.563-8.843a9.26,9.26,0,0,0-8.342-3.753,9.569,9.569,0,0,0-2.084,6.416"
        transform="translate(-2753.534 -5258.019)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_4757"
        dataname="Path 4757"
        d="M2777.908,5268.743c-1.216-2.012-3.39-4.465-6.685-4.24a9.259,9.259,0,0,0-7.6,5.084s1.811,5.687,7.268,5.906"
        transform="translate(-2753.534 -5258.019)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_4758"
        dataname="Path 4758"
        d="M2764,5283.912a9.262,9.262,0,0,0-8.343-3.753s-3.862,4.55-1.155,9.294a9.091,9.091,0,0,0,4.168,3.807"
        transform="translate(-2753.534 -5258.019)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_4759"
        dataname="Path 4759"
        d="M2768.912,5290.49s-5.458-2.416-9.241,1.523a9.7,9.7,0,0,0-2.283,8.969,14.2,14.2,0,0,0,3.292.862"
        transform="translate(-2753.534 -5258.019)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_4760"
        dataname="Path 4760"
        d="M2769.579,5300.5a9.386,9.386,0,0,0-6.8-2.082s-3.863,4.55-1.155,9.294a9.465,9.465,0,0,0,6.667,4.588"
        transform="translate(-2753.534 -5258.019)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_4761"
        dataname="Path 4761"
        d="M2772.58,5306.562a9.261,9.261,0,0,0-3.794,8.324s4.532,3.884,9.288,1.2"
        transform="translate(-2753.534 -5258.019)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_4762"
        dataname="Path 4762"
        d="M2781.34,5309.2s-3.862,4.55-1.155,9.294a9.694,9.694,0,0,0,7.956,4.727s3.158-3.822,2.6-7.657"
        transform="translate(-2753.534 -5258.019)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_4763"
        dataname="Path 4763"
        d="M2793.633,5315.1c-.282,1.495.333,2.893,1.348,4.672a9.7,9.7,0,0,0,7.956,4.728s5.469-5.543,1.9-11.085"
        transform="translate(-2753.534 -5258.019)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_4764"
        dataname="Path 4764"
        d="M2810.435,5307.929a10.051,10.051,0,0,0-1.258,6.015s6.437,3.871,10.383,0a9.044,9.044,0,0,0,1.935-2.742"
        transform="translate(-2753.534 -5258.019)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_4765"
        dataname="Path 4765"
        d="M2817.522,5307.492a9.694,9.694,0,0,0,7.956,4.728s4.784-5.791,1.541-10.268a8.916,8.916,0,0,0-4.666-3.364"
        transform="translate(-2753.534 -5258.019)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_4766"
        dataname="Path 4766"
        d="M2823.217,5295.057a9.694,9.694,0,0,0,9.121-1.57s-.122-7.51-5.495-8.81a8.95,8.95,0,0,0-4.49.037"
        transform="translate(-2753.534 -5258.019)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_4767"
        dataname="Path 4767"
        d="M2804.512,5302.073s3.632,6.575,8.987,5.2a9.259,9.259,0,0,0,6.552-6.383"
        transform="translate(-2753.534 -5258.019)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_4768"
        dataname="Path 4768"
        d="M2802.755,5273.008a6.056,6.056,0,0,0-4.5.3,9.035,9.035,0,0,0-2.9,2.2"
        transform="translate(-2753.534 -5258.019)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_4769"
        dataname="Path 4769"
        d="M2795,5282.521a7.665,7.665,0,0,0,7.054,1.1,9.157,9.157,0,0,0,2.463-1.325"
        transform="translate(-2753.534 -5258.019)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
    </g>
  </svg>
);

Crop26.defaultProps = {
  title: 'millet',
};

Crop26.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
};
