/* eslint-disable import/prefer-default-export */
/* eslint-disable react/prop-types */
import React from 'react';
import { PropTypes } from 'prop-types';
import { useLocalizedFromContext } from 'data/Localization';

export const Crop32 = ({ title, fill, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="74.281"
    height="157.225"
    viewBox="0 0 74.281 157.225"
    {...props}
  >
    <title>{useLocalizedFromContext(title)}</title>
    <g id="Component_8_1" dataname="Component 8 – 1" transform="translate(2.504 2.789)">
      <g id="Group_1005" dataname="Group 1005" transform="translate(-2399.23 -3957.678)">
        <line
          id="Line_390"
          dataname="Line 390"
          y1="18.337"
          transform="translate(2450.61 3966.16)"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
        <line
          id="Line_391"
          dataname="Line 391"
          x2="18.337"
          transform="translate(2441.399 3975.286)"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
        <g id="Group_1001" dataname="Group 1001">
          <line
            id="Line_392"
            dataname="Line 392"
            x1="7.735"
            y1="7.735"
            transform="translate(2433.664 3967.551)"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
          <line
            id="Line_393"
            dataname="Line 393"
            y1="7.735"
            x2="7.735"
            transform="translate(2433.664 3975.286)"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
        <g id="Group_1002" dataname="Group 1002">
          <line
            id="Line_394"
            dataname="Line 394"
            x2="7.735"
            y2="7.735"
            transform="translate(2459.736 3975.286)"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
          <line
            id="Line_395"
            dataname="Line 395"
            x1="7.735"
            y2="7.735"
            transform="translate(2459.736 3967.551)"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
        <g id="Group_1003" dataname="Group 1003">
          <line
            id="Line_396"
            dataname="Line 396"
            x1="7.735"
            y2="7.735"
            transform="translate(2442.874 3984.497)"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
          <line
            id="Line_397"
            dataname="Line 397"
            x1="7.735"
            y1="7.735"
            transform="translate(2450.61 3984.497)"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
        <g id="Group_1004" dataname="Group 1004">
          <line
            id="Line_398"
            dataname="Line 398"
            y1="7.735"
            x2="7.735"
            transform="translate(2450.61 3958.424)"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
          <line
            id="Line_399"
            dataname="Line 399"
            x2="7.735"
            y2="7.735"
            transform="translate(2442.874 3958.424)"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
      </g>
      <g id="Group_1007" dataname="Group 1007" transform="translate(-2399.23 -3957.678)">
        <line
          id="Line_400"
          dataname="Line 400"
          y2="130.95"
          transform="translate(2412.492 3978.663)"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
        <g id="Group_1006" dataname="Group 1006">
          <path
            id="Path_4678"
            dataname="Path 4678"
            d="M2412.26,3980.935s4.337-5.755,4.252-11.929-4.48-11.328-4.48-11.328-4.245,5.761-4.167,11.447c.085,6.174,4.4,11.81,4.4,11.81"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
        <path
          id="Path_4679"
          dataname="Path 4679"
          d="M2412.186,3982.985s.2-4.354-5.28-8.329-6.062-16.468-6.062-16.468-3.891,15.168.411,22.172,7.022,3.727,10.932,8.983Z"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
        <path
          id="Path_4680"
          dataname="Path 4680"
          d="M2412.188,3982.985s-.2-4.354,5.28-8.329,6.063-16.468,6.063-16.468,3.891,15.168-.411,22.172-7.023,3.727-10.933,8.983Z"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
        <path
          id="Path_4681"
          dataname="Path 4681"
          d="M2412.186,4005.732s.2-4.353-5.28-8.328-6.062-16.469-6.062-16.469-3.891,15.169.411,22.172,7.022,3.727,10.932,8.983Z"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
        <path
          id="Path_4682"
          dataname="Path 4682"
          d="M2412.188,4005.732s-.2-4.353,5.28-8.328,6.063-16.469,6.063-16.469,3.891,15.169-.411,22.172-7.023,3.727-10.933,8.983Z"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
        <path
          id="Path_4683"
          dataname="Path 4683"
          d="M2412.186,4026.939s.2-4.353-5.28-8.328-6.062-16.468-6.062-16.468-3.891,15.168.411,22.171,7.022,3.727,10.932,8.983Z"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
        <path
          id="Path_4684"
          dataname="Path 4684"
          d="M2412.188,4026.939s-.2-4.353,5.28-8.328,6.063-16.468,6.063-16.468,3.891,15.168-.411,22.171-7.023,3.727-10.933,8.983Z"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
        <path
          id="Path_4685"
          dataname="Path 4685"
          d="M2412.186,4048.146s.2-4.353-5.28-8.328-6.062-16.468-6.062-16.468-3.891,15.168.411,22.171,7.022,3.727,10.932,8.983Z"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
        <path
          id="Path_4686"
          dataname="Path 4686"
          d="M2412.188,4048.146s-.2-4.353,5.28-8.328,6.063-16.468,6.063-16.468,3.891,15.168-.411,22.171-7.023,3.727-10.933,8.983Z"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
        <path
          id="Path_4687"
          dataname="Path 4687"
          d="M2412.186,4069.809s.2-4.354-5.28-8.329-6.062-16.468-6.062-16.468-3.891,15.168.411,22.172,7.022,3.727,10.932,8.983Z"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
        <path
          id="Path_4688"
          dataname="Path 4688"
          d="M2412.188,4069.809s-.2-4.354,5.28-8.329,6.063-16.468,6.063-16.468,3.891,15.168-.411,22.172-7.023,3.727-10.933,8.983Z"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
      </g>
    </g>
  </svg>
);

Crop32.defaultProps = {
  title: 'winter_rye',
};

Crop32.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
};
