import React from 'react';

const CropRotationIcon = (props) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 70 70"
      enableBackground="new 0 0 70 70"
      width="70"
      height="70"
      {...props}
    >
      <g>
        <g>
          <path
            fill="#79B61D"
            d="M47.2,16.9l-4.2,1c3.6,1.7,6.7,4.6,8.7,8.2l3-0.7C52.9,21.9,50.3,19,47.2,16.9z"
          />
          <path
            fill="#79B61D"
            d="M16,24.2c-1.6,2.8-2.6,6-2.9,9.4h-1.6c-0.6,0-1.2,0.4-1.4,0.9c-0.2,0.4-0.1,0.9,0.1,1.3l8.3-1.9c-0.2-0.1-0.5-0.2-0.8-0.2h-1.5c0.3-3.8,1.8-7.5,4.2-10.4L16,24.2z"
          />
        </g>
        <g>
          <path
            fill="#EB6909"
            d="M59.7,34.3l-8.1,1.9c0.2,0.1,0.4,0.2,0.6,0.2h1.5c-0.3,3.8-1.8,7.5-4.2,10.4l4.5-1c1.6-2.8,2.6-6,2.9-9.3h1.6c0.6,0,1.2-0.4,1.4-0.9C59.9,35.2,59.9,34.7,59.7,34.3z"
          />
          <path
            fill="#EB6909"
            d="M18.5,44l-3,0.7c1.7,3.4,4.2,6.3,7.3,8.4l4.4-1C23.5,50.4,20.4,47.6,18.5,44z"
          />
        </g>
        <g>
          <path
            fill="#B95700"
            d="M36,51.1c-0.4-0.4-1-0.5-1.6-0.3c-0.5,0.2-0.9,0.8-0.9,1.4v1.5c-2.2-0.2-4.4-0.7-6.3-1.6l-4.4,1c3.1,2.1,6.8,3.5,10.7,3.7v1.6c0,0.6,0.3,1.2,0.9,1.4c0.2,0.1,0.4,0.1,0.6,0.1c0.4,0,0.8-0.1,1.1-0.4l3.1-3.1c0.6-0.6,0.6-1.5,0-2.1L36,51.1z"
          />
          <path
            fill="#B95700"
            d="M49.5,46.8c-1.7,2.1-3.9,3.9-6.4,5.1c-0.7,0.4-1.1,1.3-0.7,2c0.3,0.5,0.8,0.9,1.4,0.9c0.2,0,0.4,0,0.6-0.1c4.1-2,7.4-5.1,9.5-8.9L49.5,46.8z"
          />
        </g>
        <g>
          <path
            fill="#5F9501"
            d="M59.4,33.9l-3.1-3.1c-0.6-0.6-1.5-0.6-2.1,0l-3.1,3.1c-0.4,0.4-0.6,1.1-0.3,1.6c0.2,0.3,0.5,0.6,0.8,0.7l8.1-1.9C59.6,34.2,59.5,34,59.4,33.9z"
          />
          <path
            fill="#5F9501"
            d="M18.5,33.8l-8.3,1.9c0.1,0.1,0.1,0.2,0.2,0.3l3.1,3.1c0.3,0.3,0.7,0.4,1.1,0.4c0.4,0,0.8-0.2,1.1-0.4l3.1-3.1c0.4-0.4,0.6-1.1,0.3-1.6C18.9,34.2,18.7,34,18.5,33.8z"
          />
          <path
            fill="#5F9501"
            d="M53.4,27.7c0.2,0,0.4,0,0.6-0.1c0.7-0.4,1.1-1.3,0.7-2c0-0.1-0.1-0.1-0.1-0.2l-3,0.7c0.1,0.2,0.3,0.5,0.4,0.7C52.3,27.3,52.8,27.7,53.4,27.7z"
          />
          <path
            fill="#5F9501"
            d="M16,42.4c-0.7,0.4-1.1,1.3-0.7,2c0.1,0.1,0.1,0.2,0.2,0.3l3-0.7c-0.2-0.3-0.3-0.6-0.5-0.9C17.6,42.4,16.7,42,16,42.4z"
          />
        </g>
        <g>
          <path
            fill="#C8D200"
            d="M36.5,13.2v-1.6c0-0.6-0.4-1.2-0.9-1.4C35,10,34.4,10.1,34,10.5l-3.1,3.1c-0.3,0.3-0.4,0.7-0.4,1.1s0.2,0.8,0.4,1.1l3,3c0.3,0.3,0.7,0.4,1.1,0.4c0.2,0,0.4,0,0.6-0.1c0.6-0.2,0.9-0.8,0.9-1.4v-1.5c2.3,0.2,4.4,0.8,6.4,1.7l4.2-1C44,14.8,40.4,13.5,36.5,13.2z"
          />
          <path
            fill="#C8D200"
            d="M25.5,15.4c-4.1,1.9-7.3,5.1-9.5,8.8l4.4-1c1.7-2.1,3.9-3.9,6.4-5.1c0.7-0.4,1.1-1.3,0.7-2C27.1,15.4,26.2,15,25.5,15.4z"
          />
        </g>
      </g>
    </svg>
  );
};

export default CropRotationIcon;
