import React, { lazy, Suspense } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { detect as detectBrowser } from 'detect-browser';
import NutriguideLogo from 'components/Icons/NutriguideLogo';

const TractorBodyMovin = lazy(() => import('components/Icons/TractorBodyMovin'));

function initiateOnBackdropClick(onBackdropClick) {
  if (onBackdropClick) {
    onBackdropClick();
  }
}

const Dialogue = ({
  title,
  text1,
  text2,
  displaySpinner = false,
  buttons,
  children,
  onBackdropClick,
  ...props
}) => {
  const actionButtons = buttons
    ? buttons.map((button) =>
        button ? (
          <Button
            disabled={button.disabled}
            key={button.id}
            id={button.id}
            onClick={button.handler}
            color={button.color}
          >
            {button.title}
          </Button>
        ) : null,
      )
    : null;

  let spinner = null;
  if (displaySpinner) {
    const { name: browserName } = detectBrowser();
    const circularProgress = (
      <CircularProgress className="img-center margin-top--sixteen-important overflow-hidden" />
    );
    if (browserName !== 'edge' && browserName !== 'ie') {
      spinner = (
        <Suspense fallback={circularProgress}>
          <TractorBodyMovin />
        </Suspense>
      );
    } else {
      spinner = circularProgress;
    }
  }

  return (
    <Dialog open onBackdropClick={() => initiateOnBackdropClick(onBackdropClick)} {...props}>
      <DialogTitle id="alert_dialog_title">
        <NutriguideLogo tagline={false} className="logo__img img-center" />
      </DialogTitle>
      {title && <h3 style={{ textAlign: 'center' }}>{title}</h3>}
      <DialogContent classes={{ root: 'dialog--content' }}>
        <DialogContentText
          className="text-align--center margin-top--zero"
          id="alert_dialog_description"
        >
          {text1}
          <br />
          {text2}
        </DialogContentText>
        {spinner}
        {children}
      </DialogContent>
      <DialogActions>{actionButtons}</DialogActions>
    </Dialog>
  );
};

Dialogue.propTypes = {
  onBackdropClick: PropTypes.func,
  title: PropTypes.string,
  text1: PropTypes.any,
  text2: PropTypes.any,
  displaySpinner: PropTypes.bool,
  buttons: PropTypes.array,
  children: PropTypes.any,
};

export default Dialogue;
