/* eslint-disable import/prefer-default-export */
/* eslint-disable react/prop-types */
import React from 'react';
import { PropTypes } from 'prop-types';
import { useLocalizedFromContext } from 'data/Localization';

export const GradientCrop36 = ({ title, ...props }) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 70 70"
    enableBackground="new 0 0 70 70"
    {...props}
  >
    <title>{useLocalizedFromContext(title)}</title>
    <g>
      <clipPath id="SVGID_2_">
        <path
          id="SVGID_59_"
          d="M42.9,15.1l-0.8,0c0.4-1.5,0.6-3.2,0.7-4.2l0.1-1.7l-1.7,0.1c-0.7,0-1.7,0.1-2.8,0.4c-0.9-1.4-2-2.8-2.2-3
          L35,5.5l-1.1,1.3c-0.2,0.2-1.3,1.5-2.2,3c-1.1-0.2-2.2-0.3-2.8-0.4l-1.7-0.1l0.1,1.7c0.1,0.9,0.2,2.6,0.7,4.2l-0.8,0l0.1,1.7
          c0.1,0.9,0.2,2.6,0.7,4.2l-0.8,0l0.1,1.7c0.1,0.9,0.2,2.7,0.7,4.2l-0.8,0l0.1,1.7c0.1,0.9,0.2,2.7,0.7,4.2l-0.8,0l0.1,1.7
          c0.1,0.9,0.2,2.7,0.7,4.2l-0.8,0l0.1,1.7c0.1,0.9,0.2,2.7,0.7,4.2l-0.8,0l0.1,1.7c0.1,1.5,0.4,5,1.8,6.4c0.8,0.8,2,1.3,3.1,1.3
          c0.4,0,0.9-0.1,1.3-0.2v9c0,0.8,0.7,1.5,1.5,1.5s1.5-0.7,1.5-1.5v-9c0.5,0.2,0.9,0.3,1.4,0.3c1.1,0,2.2-0.4,3.1-1.3
          c1.4-1.4,1.7-4.9,1.8-6.4l0.1-1.7l-0.8,0c0.5-1.5,0.6-3.3,0.7-4.2l0.1-1.7l-0.8,0c0.5-1.5,0.6-3.3,0.7-4.2l0.1-1.7l-0.8,0
          c0.5-1.5,0.6-3.3,0.7-4.2l0.1-1.7l-0.8,0c0.5-1.5,0.6-3.3,0.7-4.2l0.1-1.7l-0.8,0c0.4-1.5,0.6-3.2,0.7-4.2L42.9,15.1z M39.1,24.2
          C39.2,24.2,39.2,24.2,39.1,24.2c0.1,0,0.3-0.1,0.4-0.1c-0.2,1.3-0.5,2.4-0.7,2.7c-0.1,0.1-0.3,0.2-0.5,0.3
          c-0.5-0.8-1.1-1.6-1.5-2.1c0,0,0-0.1,0.1-0.1C37.2,24.7,38.1,24.4,39.1,24.2L39.1,24.2z M30.9,24.2c0.2,0,0.3,0.1,0.5,0.1l0,0
          c0.8,0.2,1.5,0.4,1.7,0.6c0,0,0.1,0.1,0.1,0.1c-0.4,0.6-1,1.3-1.5,2.1c-0.2-0.1-0.4-0.2-0.5-0.3c-0.3-0.3-0.6-1.4-0.8-2.7
          C30.6,24.2,30.7,24.2,30.9,24.2z M31.2,21c-0.3-0.3-0.6-1.4-0.8-2.7c0.2,0,0.3,0,0.4,0.1c0.2,0.1,0.3,0.1,0.5,0.1l0,0
          c0.8,0.2,1.5,0.4,1.7,0.6c0,0,0,0.1,0.1,0.1c-0.4,0.6-1,1.3-1.5,2.1c0,0,0,0,0,0C31.5,21.2,31.3,21.1,31.2,21z M35,45
          c0.1,0.2,0.3,0.4,0.4,0.6c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1-0.1-0.2-0.2-0.4-0.3C34.7,45.4,34.9,45.2,35,45z M35,40.1
          c-0.1-0.1-0.2-0.2-0.4-0.3c0.1-0.2,0.3-0.4,0.4-0.6c0.1,0.2,0.3,0.4,0.4,0.6C35.2,39.9,35.1,40,35,40.1z M35,34.3
          c-0.1-0.1-0.2-0.2-0.4-0.3c0.1-0.2,0.3-0.4,0.4-0.6c0.1,0.2,0.3,0.4,0.4,0.6C35.2,34,35.1,34.1,35,34.3z M35,28.4
          c-0.1-0.1-0.2-0.2-0.4-0.3c0.1-0.2,0.3-0.4,0.4-0.6c0.1,0.2,0.3,0.4,0.4,0.6C35.2,28.2,35.1,28.3,35,28.4z M35,22.6
          c-0.1-0.1-0.2-0.2-0.4-0.3c0.1-0.2,0.3-0.4,0.4-0.6c0.1,0.2,0.3,0.4,0.4,0.6C35.2,22.4,35.1,22.5,35,22.6z M35,15.9
          c0.1,0.2,0.3,0.4,0.4,0.6c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1-0.1-0.2-0.2-0.4-0.3C34.7,16.3,34.9,16.1,35,15.9z M33.1,30.7
          c0,0,0.1,0.1,0.1,0.1c-0.4,0.6-1,1.3-1.5,2.1c-0.2-0.1-0.4-0.2-0.5-0.3c-0.3-0.3-0.6-1.4-0.8-2.7C31.7,30.2,32.8,30.5,33.1,30.7z
          M33.1,36.5c0,0,0.1,0.1,0.1,0.1c-0.4,0.6-1,1.3-1.5,2.1c-0.2-0.1-0.4-0.2-0.5-0.3c-0.3-0.3-0.6-1.4-0.8-2.7
          C31.7,36,32.8,36.3,33.1,36.5z M31.4,41.8C31.4,41.8,31.4,41.8,31.4,41.8L31.4,41.8c0.8,0.2,1.5,0.4,1.7,0.6c0,0,0.1,0.1,0.1,0.1
          c-0.4,0.6-1,1.3-1.5,2.1c-0.2-0.1-0.4-0.2-0.5-0.3c-0.3-0.3-0.6-1.4-0.8-2.7C30.8,41.7,31.1,41.7,31.4,41.8z M38.8,44.3
          c-0.1,0.1-0.3,0.2-0.5,0.3c-0.5-0.8-1.1-1.6-1.5-2.1c0,0,0-0.1,0.1-0.1c0.3-0.3,1.4-0.6,2.7-0.8C39.4,42.9,39.1,44,38.8,44.3z
          M38.8,38.5c-0.1,0.1-0.3,0.2-0.5,0.3c-0.5-0.8-1.1-1.6-1.5-2.1c0,0,0-0.1,0.1-0.1c0.3-0.3,1.4-0.6,2.7-0.8
          C39.4,37.1,39.1,38.2,38.8,38.5z M38.8,32.6c-0.1,0.1-0.3,0.2-0.5,0.3c-0.5-0.8-1.1-1.6-1.5-2.1c0,0,0-0.1,0.1-0.1
          c0.3-0.3,1.4-0.6,2.7-0.8C39.4,31.2,39.1,32.3,38.8,32.6z M38.3,21.3c-0.5-0.8-1.1-1.6-1.5-2.1c0,0,0-0.1,0.1-0.1
          c0.3-0.2,1.2-0.5,2.3-0.7l0,0c0,0,0,0,0,0c0.1,0,0.3,0,0.4-0.1c-0.2,1.3-0.5,2.4-0.7,2.7C38.7,21.1,38.5,21.2,38.3,21.3
          C38.4,21.3,38.3,21.3,38.3,21.3z M38.8,15.2c-0.1,0.1-0.3,0.2-0.4,0.3c0,0,0,0,0,0c-0.5-0.8-1.1-1.6-1.5-2.1c0,0,0-0.1,0.1-0.1
          c0.3-0.3,1.4-0.6,2.7-0.8C39.4,13.8,39.1,14.9,38.8,15.2z M35,10.1c0.1,0.2,0.3,0.4,0.4,0.6c-0.2,0.1-0.3,0.2-0.4,0.3
          c-0.1-0.1-0.2-0.2-0.4-0.3C34.7,10.5,34.9,10.3,35,10.1z M33.1,13.3C33.1,13.3,33.1,13.4,33.1,13.3c-0.4,0.6-0.9,1.4-1.4,2.2
          c0,0,0,0,0,0c-0.2-0.1-0.3-0.2-0.5-0.3c-0.3-0.3-0.6-1.4-0.8-2.7C31.7,12.7,32.8,13,33.1,13.3z M33.1,50.1c-0.5,0.5-1.4,0.5-1.9,0
          c-0.3-0.3-0.6-1.4-0.7-2.7c1.3,0.2,2.4,0.5,2.7,0.7c0.2,0.2,0.3,0.4,0.3,0.7c0,0,0,0,0,0v0.5C33.4,49.7,33.3,49.9,33.1,50.1z
          M38.8,50.1c-0.5,0.5-1.4,0.5-1.9,0c-0.3-0.3-0.4-0.6-0.4-1c0-0.4,0.1-0.7,0.4-1c0.3-0.3,1.4-0.6,2.7-0.8
          C39.4,48.7,39.1,49.8,38.8,50.1z"
        />
      </clipPath>
      <g clipPath="url(#SVGID_2_)">
        <rect
          x="4.5"
          y="49.2"
          transform="matrix(0.9736 -0.2284 0.2284 0.9736 -12.47 10.8225)"
          fill="#B95700"
          width="72.1"
          height="20.2"
        />
        <rect
          x="1"
          y="39.7"
          transform="matrix(0.9736 -0.2284 0.2284 0.9736 -9.2199 9.6522)"
          fill="#EB6909"
          width="72.1"
          height="9.9"
        />
        <rect
          x="-1.2"
          y="30.1"
          transform="matrix(0.9736 -0.2284 0.2284 0.9736 -7.0821 8.8823)"
          fill="#5F9501"
          width="72.1"
          height="9.9"
        />
        <rect
          x="-3.5"
          y="20.5"
          transform="matrix(0.9736 -0.2284 0.2284 0.9736 -4.9442 8.1125)"
          fill="#79B61D"
          width="72.1"
          height="9.9"
        />
        <rect
          x="-6.9"
          y="0.7"
          transform="matrix(0.9736 -0.2284 0.2284 0.9736 -1.6929 6.9416)"
          fill="#C8D200"
          width="72.1"
          height="20.2"
        />
      </g>
    </g>
  </svg>
);

GradientCrop36.defaultProps = {
  title: 'cereal',
};

GradientCrop36.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
};
