/* eslint-disable import/prefer-default-export */
/* eslint-disable react/prop-types */
import React from 'react';
import { PropTypes } from 'prop-types';
import { useLocalizedFromContext } from 'data/Localization';

export const Crop41 = ({ title, fill, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="76.368"
    height="162.36"
    viewBox="0 0 76.368 162.36"
    {...props}
  >
    <title>{useLocalizedFromContext(title)}</title>
    <g id="Component_3_1" data-name="Component 3 – 1" transform="translate(2.5 3.409)">
      <path
        id="Path_792"
        data-name="Path 792"
        d="M980.158,5280.215a28.646,28.646,0,0,0-.741,4.341c-1.363,14.439,2.14,14.508,2.251,21.154.132,7.886-2.873,8.69-3.187,17.032-.309,8.22,6.489,18.067,15.173,22.529s13.375,4.277,17.983,12.86c0,0,15.569-12.938,15.71-52.986a87.259,87.259,0,0,0-1.928-20.028"
        transform="translate(-964.831 -5201.68)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_793"
        data-name="Path 793"
        d="M998.49,5276.521a13.255,13.255,0,0,0-3.646,22.114"
        transform="translate(-964.831 -5201.68)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_794"
        data-name="Path 794"
        d="M988.228,5320.851a15.334,15.334,0,0,0,13.825,16.709"
        transform="translate(-964.831 -5201.68)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_795"
        data-name="Path 795"
        d="M1000.416,5255.45s2.086,8.723-12.86,18.669c-13.246,8.814-22.725,26.559-22.725,26.559s4.681-26.292,15.732-36.505c10.493-9.695,9.834-16.5,9.834-16.5"
        transform="translate(-964.831 -5201.68)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_796"
        data-name="Path 796"
        d="M1000.614,5255.45s-2.086,8.723,12.86,18.669c13.246,8.814,22.725,26.559,22.725,26.559s-4.658-26.317-15.732-36.505c-10.3-9.473-9.834-16.5-9.834-16.5"
        transform="translate(-964.831 -5201.68)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_797"
        data-name="Path 797"
        d="M990.4,5247.8a10.118,10.118,0,0,1,20.236,0"
        transform="translate(-964.831 -5201.68)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
      <path
        id="Path_798"
        data-name="Path 798"
        d="M1000.515,5237.684s.82-26.337-16.182-36"
        transform="translate(-964.831 -5201.68)"
        fill="none"
        stroke={fill || '#839ca9'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
    </g>
  </svg>
);

Crop41.defaultProps = {
  title: 'lentil',
};

Crop41.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
};
