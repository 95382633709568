/* eslint-disable react/prop-types */
import React from 'react';

const NotifyIcon = (props) => (
  <svg
    {...props}
    id="Layer_1"
    dataname="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
  >
    <circle fill="#eb6909" cx="12" cy="12" r="12" />
    <circle fill="#fff" cx="12" cy="18" r="2" />
    <path
      fill="#fff"
      d="M12,3h0a1.79,1.79,0,0,0-1.7791,1.9878L11,13a1,1,0,0,0,1,1h0a1,1,0,0,0,1-1l.7791-8.0122A1.79,1.79,0,0,0,12,3Z"
    />
  </svg>
);

export default NotifyIcon;
