/* eslint-disable import/prefer-default-export */
/* eslint-disable react/prop-types */
import React from 'react';
import { PropTypes } from 'prop-types';
import { useLocalizedFromContext } from 'data/Localization';

export const Crop31 = ({ title, fill, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="83.24"
    height="161.123"
    viewBox="0 0 83.24 161.123"
    {...props}
  >
    <title>{useLocalizedFromContext(title)}</title>
    <g id="Component_7_1" dataname="Component 7 – 1" transform="translate(2.512 2.5)">
      <g id="Group_230" dataname="Group 230" transform="translate(-2578.463 -3953.491)">
        <line
          id="Line_72"
          dataname="Line 72"
          y2="127.662"
          transform="translate(2594.484 3981.951)"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="5"
        />
        <g id="Group_219" dataname="Group 219">
          <path
            id="Path_926"
            dataname="Path 926"
            d="M2591.845,4057.147s-5.319,2.994-9.964-5.524-3.208-18.632-3.208-18.632,9.648,4.945,13.925,12.788c4.645,8.518-.753,11.368-.753,11.368"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
        <g id="Group_220" dataname="Group 220">
          <path
            id="Path_927"
            dataname="Path 927"
            d="M2597.123,4056.615s5.319,2.994,9.964-5.524,3.208-18.632,3.208-18.632-9.648,4.945-13.925,12.788c-4.645,8.518.753,11.368.753,11.368"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
        <g id="Group_221" dataname="Group 221">
          <path
            id="Path_928"
            dataname="Path 928"
            d="M2591.845,4075.733s-5.319,2.994-9.964-5.523-3.208-18.633-3.208-18.633,9.648,4.945,13.925,12.789c4.645,8.517-.753,11.367-.753,11.367"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
        <g id="Group_222" dataname="Group 222">
          <path
            id="Path_929"
            dataname="Path 929"
            d="M2597.123,4075.2s5.319,3,9.964-5.523,3.208-18.632,3.208-18.632-9.648,4.944-13.925,12.788c-4.645,8.517.753,11.367.753,11.367"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
        <g id="Group_223" dataname="Group 223">
          <path
            id="Path_930"
            dataname="Path 930"
            d="M2591.845,4037.821s-5.319,2.994-9.964-5.523-3.208-18.633-3.208-18.633,9.648,4.945,13.925,12.788c4.645,8.518-.753,11.368-.753,11.368"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
        <g id="Group_224" dataname="Group 224">
          <path
            id="Path_931"
            dataname="Path 931"
            d="M2597.123,4037.289s5.319,2.995,9.964-5.523,3.208-18.632,3.208-18.632-9.648,4.944-13.925,12.788c-4.645,8.518.753,11.367.753,11.367"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
        <g id="Group_225" dataname="Group 225">
          <path
            id="Path_932"
            dataname="Path 932"
            d="M2591.845,4018.394s-5.319,2.995-9.964-5.523-3.208-18.632-3.208-18.632,9.648,4.944,13.925,12.788c4.645,8.518-.753,11.367-.753,11.367"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
        <g id="Group_226" dataname="Group 226">
          <path
            id="Path_933"
            dataname="Path 933"
            d="M2597.123,4017.863s5.319,2.994,9.964-5.524,3.208-18.632,3.208-18.632-9.648,4.945-13.925,12.788c-4.645,8.518.753,11.368.753,11.368"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
        <g id="Group_227" dataname="Group 227">
          <path
            id="Path_934"
            dataname="Path 934"
            d="M2591.845,3998.854s-5.319,3-9.964-5.523-3.208-18.632-3.208-18.632,9.648,4.944,13.925,12.788c4.645,8.518-.753,11.367-.753,11.367"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
        <g id="Group_228" dataname="Group 228">
          <path
            id="Path_935"
            dataname="Path 935"
            d="M2597.123,3998.323s5.319,2.994,9.964-5.524,3.208-18.632,3.208-18.632-9.648,4.945-13.925,12.788c-4.645,8.518.753,11.368.753,11.368"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
        <g id="Group_229" dataname="Group 229">
          <path
            id="Path_936"
            dataname="Path 936"
            d="M2594.68,3981s6.1-.043,5.906-9.743-6.468-17.765-6.468-17.765-5.919,9.083-5.736,18.015c.2,9.7,6.3,9.493,6.3,9.493"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
      </g>
      <g id="Group_1013" dataname="Group 1013" transform="translate(-2578.463 -3953.491)">
        <line
          id="Line_401"
          dataname="Line 401"
          y1="18.337"
          transform="translate(2638.794 3966.16)"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
        <line
          id="Line_402"
          dataname="Line 402"
          x2="18.337"
          transform="translate(2629.583 3975.286)"
          fill="none"
          stroke={fill || '#839ca9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
        />
        <g id="Group_1009" dataname="Group 1009">
          <line
            id="Line_403"
            dataname="Line 403"
            x1="7.735"
            y1="7.735"
            transform="translate(2621.848 3967.551)"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
          <line
            id="Line_404"
            dataname="Line 404"
            y1="7.735"
            x2="7.735"
            transform="translate(2621.848 3975.286)"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
        <g id="Group_1010" dataname="Group 1010">
          <line
            id="Line_405"
            dataname="Line 405"
            x2="7.735"
            y2="7.735"
            transform="translate(2647.92 3975.286)"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
          <line
            id="Line_406"
            dataname="Line 406"
            x1="7.735"
            y2="7.735"
            transform="translate(2647.92 3967.551)"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
        <g id="Group_1011" dataname="Group 1011">
          <line
            id="Line_407"
            dataname="Line 407"
            x1="7.735"
            y2="7.735"
            transform="translate(2631.059 3984.497)"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
          <line
            id="Line_408"
            dataname="Line 408"
            x1="7.735"
            y1="7.735"
            transform="translate(2638.794 3984.497)"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
        <g id="Group_1012" dataname="Group 1012">
          <line
            id="Line_409"
            dataname="Line 409"
            y1="7.735"
            x2="7.735"
            transform="translate(2638.794 3958.424)"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
          <line
            id="Line_410"
            dataname="Line 410"
            x2="7.735"
            y2="7.735"
            transform="translate(2631.059 3958.424)"
            fill="none"
            stroke={fill || '#839ca9'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
          />
        </g>
      </g>
    </g>
  </svg>
);

Crop31.defaultProps = {
  title: 'winter_spelt',
};

Crop31.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
};
