/* eslint-disable react/prop-types */
import React from 'react';

const LightBulb = (props) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 42 42"
      enableBackground="new 0 0 42 42"
      {...props}
    >
      <g>
        <path
          fill="#79B61D"
          d="M32.1,22c0-6.1-4.9-11-11-11C15,11,10,16,10,22c0,2.4,0.8,4.8,2.3,6.7c-0.4,0.7-0.7,1.5-0.7,2.3
          c0,0.9,0,1.4-0.1,1.8C11.2,33.4,11,34.2,11,35c0,0.4,0,0.8,0.1,1.2c0.3,1.3,1.1,2.3,2.1,2.6c0.2,0.1,0.3,0.1,0.5,0.1c0,0,0,0,0,0
          c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0.1,0.4,0.3,0.7,0.6,1.1c0,0.1,0.1,0.1,0.1,0.2l0,0c0,0,0,0,0,0.1c0.9,1.1,2.2,1.7,3.7,1.7h6
          c1.3,0,2.4-0.4,3.3-1.3c0.9-0.9,1.3-2,1.3-3.1c0-0.7,0-1.2,0-1.5c0.9-0.4,1.7-1.4,2-2.6c0.1-0.4,0.1-0.8,0.1-1.2
          c0-0.6-0.1-1.2-0.4-1.7c-0.1-0.7-0.4-1.3-0.8-1.9C31.3,26.7,32.1,24.4,32.1,22z M16.3,29.6H25l-10.3,1.8c0-0.2,0-0.3,0-0.4
          c0-0.4,0.2-0.7,0.5-1C15.4,29.8,15.7,29.6,16.3,29.6z M14.2,35.8c0-0.1-0.1-0.2-0.1-0.4c0-0.1-0.1-0.3-0.1-0.5c0-0.1,0-0.3,0.1-0.4
          l14-2.5c0,0,0,0.1,0,0.1c0,0.2,0,0.3-0.1,0.5c-0.1,0.3-0.2,0.5-0.2,0.5L14.2,35.8z M21.1,14c4.4,0,8,3.6,8,8c0,1.7-0.6,3.4-1.6,4.8
          c-0.4-0.1-0.9-0.2-1.4-0.2h-9.8c-0.6,0-1.1,0.1-1.6,0.3c-1-1.4-1.6-3.1-1.6-4.8C13,17.6,16.6,14,21.1,14z M25.5,38.5
          c-0.3,0.3-0.7,0.4-1.2,0.4h-6c-0.5,0-1-0.2-1.3-0.6l9-1.7c0,0.4,0,0.8,0,0.9C25.9,37.8,25.8,38.2,25.5,38.5z"
        />
        <path
          fill="#79B61D"
          d="M20.8,8c0.8,0,1.5-0.7,1.5-1.5V1.6c0-0.8-0.7-1.5-1.5-1.5s-1.5,0.7-1.5,1.5v4.9C19.3,7.3,20,8,20.8,8z"
        />
        <path
          fill="#79B61D"
          d="M30.2,11.6c0.4,0,0.8-0.2,1.1-0.5l3.5-3.7c0.6-0.6,0.5-1.6-0.1-2.1s-1.6-0.5-2.1,0.1l-3.5,3.7
          c-0.6,0.6-0.5,1.6,0.1,2.1C29.4,11.5,29.8,11.6,30.2,11.6z"
        />
        <path
          fill="#79B61D"
          d="M39.5,17.3l-5.1,0.3c-0.8,0-1.5,0.8-1.4,1.6c0,0.8,0.7,1.4,1.5,1.4c0,0,0.1,0,0.1,0l5.1-0.3
          c0.8,0,1.5-0.8,1.4-1.6C41.1,17.8,40.4,17.2,39.5,17.3z"
        />
        <path
          fill="#79B61D"
          d="M10.7,11.1c0.3,0.3,0.7,0.5,1.1,0.5c0.4,0,0.7-0.1,1-0.4c0.6-0.6,0.6-1.5,0.1-2.1L9.4,5.3
          C8.8,4.7,7.9,4.7,7.3,5.3S6.6,6.8,7.2,7.4L10.7,11.1z"
        />
        <path
          fill="#79B61D"
          d="M7.6,17.5l-5.1-0.3c-0.8-0.1-1.5,0.6-1.6,1.4c0,0.8,0.6,1.5,1.4,1.6l5.1,0.3c0,0,0.1,0,0.1,0
          c0.8,0,1.5-0.6,1.5-1.4C9.1,18.3,8.4,17.6,7.6,17.5z"
        />
      </g>
    </svg>
  );
};

export default LightBulb;
