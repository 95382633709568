import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import userUtils from 'Utils/UserUtils';

const PlanNowRoute = ({ user, ...rest }) => {
  if (!userUtils.isLoggedIn(user) && !user.startedPlanningWithoutRegistration) {
    return <Redirect to="/plan-builder/flow-select" />; // The flow select route will handle the redirect to the correct get-started page if needed
  }
  return <Route {...rest} />;
};

PlanNowRoute.propTypes = {
  user: PropTypes.object,
};

export default PlanNowRoute;
