/* eslint-disable import/prefer-default-export */
/* eslint-disable react/prop-types */
import React from 'react';
import { PropTypes } from 'prop-types';
import { useLocalizedFromContext } from 'data/Localization';

export const GradientCrop44 = ({ title, ...props }) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 70 70"
    enableBackground="new 0 0 70 70"
    {...props}
  >
    <title>{useLocalizedFromContext(title)}</title>
    <g>
      <clipPath id="SVGID_2_">
        <path
          id="SVGID_73_"
          d="M46.1,11.5c-0.3-0.4-0.7-0.6-1.2-0.7c-0.5,0-0.9,0.2-1.2,0.6c-3,3.7-7.1,5.3-11,6.9c-4.9,2-10,4.1-12.8,10
          c-4.7,9.7-1.5,16.7,0.8,20c-2.3,4.2-3,7.3-3,7.5c-0.1,0.3,0,0.5,0,0.8c0.7,2.2,2,3.1,3,3.4c0.4,0.1,0.9,0.2,1.3,0.2
          c1.6,0,2.9-0.9,3.1-1c0.4-0.3,0.6-0.7,0.6-1.2c0,0,0.1-2.2,1.5-5.6c1,0.2,2,0.4,3.1,0.4c6,0,13.1-4,17.6-12.9
          C55.2,25.1,46.5,12,46.1,11.5z M21.6,57.1c-0.4-0.1-0.7-0.6-0.9-1.1c0.5-1.6,2.5-8.1,8.6-14.6c0.5-0.5,0.9-1,1.4-1.5
          c-0.8,1.4-1.8,3-2.9,4.8C24,50.6,23,55.1,22.7,57C22.4,57.1,21.9,57.2,21.6,57.1z M45.1,38.5C40.2,48.1,33,50.4,28.4,49.6
          c0.5-1,1.1-2.2,1.9-3.3c7.5-11.9,8.4-16.3,8.5-16.8c0.1-0.7-0.3-1.4-1-1.7c-0.7-0.2-1.4,0-1.8,0.7c0,0-2,3.3-8.9,10.9
          c-1.9,2-3.4,4.1-4.6,6c-1.7-3.1-3.3-8.5,0.2-15.8c2.3-4.9,6.5-6.5,11.2-8.5c3.7-1.5,7.5-3.1,10.7-6.2
          C46.5,18.6,50.3,28.2,45.1,38.5z"
        />
      </clipPath>
      <g clipPath="url(#SVGID_2_)">
        <rect
          x="4.5"
          y="49.2"
          transform="matrix(0.9736 -0.2284 0.2284 0.9736 -12.47 10.8225)"
          fill="#B95700"
          width="72.1"
          height="20.2"
        />
        <rect
          x="1"
          y="39.7"
          transform="matrix(0.9736 -0.2284 0.2284 0.9736 -9.2199 9.6522)"
          fill="#EB6909"
          width="72.1"
          height="9.9"
        />
        <rect
          x="-1.2"
          y="30.1"
          transform="matrix(0.9736 -0.2284 0.2284 0.9736 -7.0821 8.8823)"
          fill="#5F9501"
          width="72.1"
          height="9.9"
        />
        <rect
          x="-3.5"
          y="20.5"
          transform="matrix(0.9736 -0.2284 0.2284 0.9736 -4.9442 8.1125)"
          fill="#79B61D"
          width="72.1"
          height="9.9"
        />
        <rect
          x="-6.9"
          y="0.7"
          transform="matrix(0.9736 -0.2284 0.2284 0.9736 -1.6929 6.9416)"
          fill="#C8D200"
          width="72.1"
          height="20.2"
        />
      </g>
    </g>
  </svg>
);

GradientCrop44.defaultProps = {
  title: 'others',
};

GradientCrop44.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
};
