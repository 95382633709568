/* eslint-disable import/prefer-default-export */
/* eslint-disable react/prop-types */
import React from 'react';

export const Crop = ({ fill, ...props }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 71 71"
      enableBackground="new 0 0 71 71"
      {...props}
    >
      <g>
        <path
          fill={fill || '#839ca9'}
          d="M43,43.7l-0.9,1c-1.2,0-3.6,0.3-5.5,2c-0.4,0.4-0.8,0.8-1.1,1.2c-0.3-0.4-0.7-0.9-1.1-1.2
          c-1.9-1.7-4.3-2-5.5-2l-0.9-1c-0.5-0.6-1.5-0.7-2.1-0.2c-0.6,0.5-0.7,1.5-0.2,2.1l1,1.2c0.1,2.9,1.1,5.2,2.8,6.7
          c1.5,1.3,3.2,1.7,4.4,1.8v7.6c0,0.8,0.7,1.5,1.5,1.5s1.5-0.7,1.5-1.5v-7.6c1.2-0.1,3-0.5,4.4-1.8c1.7-1.5,2.6-3.7,2.8-6.7l1-1.2
          c0.5-0.6,0.5-1.6-0.2-2.1C44.5,43,43.5,43.1,43,43.7z M31.5,51.2c-0.9-0.8-1.4-1.9-1.7-3.4c0.8,0.1,1.8,0.4,2.6,1.1
          c0.8,0.8,1.3,1.9,1.5,3.4C33.2,52.2,32.3,51.9,31.5,51.2z M39.5,51.2c-0.8,0.7-1.7,1-2.4,1.1c0.2-1.5,0.7-2.6,1.5-3.3
          c0.8-0.7,1.8-1,2.6-1.1C40.9,49.3,40.4,50.5,39.5,51.2z"
        />
        <path
          fill={fill || '#839ca9'}
          d="M43,32.5l-0.9,1c-1.2,0-3.6,0.3-5.5,2c-0.4,0.4-0.8,0.8-1.1,1.2c-0.3-0.4-0.7-0.9-1.1-1.2
          c-1.9-1.7-4.3-2-5.5-2l-0.9-1c-0.5-0.6-1.5-0.7-2.1-0.2c-0.6,0.5-0.7,1.5-0.2,2.1l1,1.2c0.1,2.9,1.1,5.2,2.8,6.7
          c1.9,1.6,4.2,1.9,5.3,1.9c0.3,0,0.5,0,0.6,0c0.1,0,0.3,0,0.6,0c1.2,0,3.5-0.2,5.3-1.9c1.7-1.5,2.6-3.7,2.8-6.7l1-1.2
          c0.5-0.6,0.5-1.6-0.2-2.1S43.5,31.9,43,32.5z M31.5,40.1c-0.9-0.8-1.4-1.9-1.7-3.4c0.8,0.1,1.8,0.4,2.6,1.1
          c0.8,0.8,1.3,1.9,1.5,3.4C33.2,41,32.3,40.7,31.5,40.1z M39.5,40.1c-0.8,0.7-1.7,1-2.4,1.1c0.2-1.5,0.7-2.6,1.5-3.3
          c0.8-0.7,1.8-1,2.6-1.1C40.9,38.2,40.4,39.3,39.5,40.1z"
        />
        <path
          fill={fill || '#839ca9'}
          d="M44.1,7.6c-0.8,0-1.5,0.7-1.5,1.5V22l-0.5,0.6c-1.2,0-3.6,0.3-5.5,2c-0.4,0.4-0.8,0.8-1.1,1.2
          c-0.3-0.4-0.7-0.9-1.1-1.2c-1.9-1.7-4.3-2-5.5-2L28.4,22V9.1c0-0.8-0.7-1.5-1.5-1.5s-1.5,0.7-1.5,1.5v13.5c0,0.4,0.1,0.7,0.4,1
          l1,1.2c0.1,2.9,1.1,5.2,2.8,6.7c1.9,1.6,4.2,1.9,5.3,1.9c0.3,0,0.5,0,0.6,0c0.1,0,0.3,0,0.6,0c1.2,0,3.5-0.2,5.3-1.9
          c1.7-1.5,2.6-3.7,2.8-6.7l1-1.2c0.2-0.3,0.4-0.6,0.4-1V9.1C45.6,8.2,45,7.6,44.1,7.6z M31.5,29.1c-0.9-0.8-1.4-1.9-1.7-3.4
          c0.8,0.1,1.8,0.4,2.6,1.1c0.8,0.8,1.3,1.9,1.5,3.3C33.2,30,32.3,29.8,31.5,29.1z M39.5,29.1c-0.8,0.7-1.7,1-2.4,1.1
          c0.2-1.5,0.7-2.6,1.5-3.3c0.8-0.7,1.8-1,2.6-1.1C40.9,27.2,40.4,28.3,39.5,29.1z"
        />
        <path
          fill={fill || '#839ca9'}
          d="M34.9,22.8c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0.1,0,0.2,0.1,0.4,0.1c0,0,0,0,0,0c0.1,0,0.3,0,0.4-0.1
          c0,0,0,0,0,0c0.1,0,0.1,0,0.2-0.1c0.1-0.1,3.5-1.6,3.5-6.4c0-3.3-1.5-5.1-2.6-5.9V9.1c0-0.8-0.7-1.5-1.5-1.5S34,8.2,34,9.1v1.4
          c-1,0.8-2.6,2.6-2.6,5.9C31.4,21.2,34.7,22.7,34.9,22.8z M36.6,16.3c0,1.7-0.6,2.7-1.1,3.2C35,19,34.4,18,34.4,16.3
          c0-1.7,0.6-2.7,1.1-3.2C36,13.7,36.6,14.7,36.6,16.3z"
        />
      </g>
    </svg>
  );
};
