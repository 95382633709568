import React from 'react';
import { PropTypes } from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import userUtils from 'Utils/UserUtils';

const AnonymousRouteComponent = ({ component: Component, user, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      userUtils.isLoggedIn(user) === false ? (
        <Component {...props} />
      ) : (
        <Redirect to="/myoverview" />
      )
    }
  />
);

AnonymousRouteComponent.propTypes = {
  location: PropTypes.object,
  component: PropTypes.any,
  user: PropTypes.object,
};

export default AnonymousRouteComponent;
