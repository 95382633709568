import React from 'react';
import { PropTypes } from 'prop-types';
import { Route } from 'react-router-dom';

const GenericRoute = ({ component: Component, ...rest }) => (
  <Route render={(props) => <Component {...props} />} {...rest} />
);

GenericRoute.propTypes = {
  component: PropTypes.any,
};

export default GenericRoute;
