/* eslint-disable react/prop-types */
import React from 'react';

const GradientPlot = (props) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 171.5 111.8">
    <title>Plot</title>
    <polygon
      points="97.2 62.9 126.5 85.3 153.1 52 165.5 36.4 138 26.7 124.8 22.1 116.2 31 90.4 57.7 97.2 62.9"
      fill="none"
    />
    <polygon
      points="58.1 70.5 93.1 35.5 112.1 16.5 87.7 8.3 76.8 4.7 65 12.7 6.7 52.3 6.6 52.4 45.6 72.9 52.2 76.4 58.1 70.5"
      fill="none"
    />
    <polygon
      points="64.3 83.9 83.2 93.8 107.8 106.8 118.2 95.4 109.3 88.7 80.7 67.5 64.3 83.9"
      fill="none"
    />
    <path d="M125.5,90.2a2,2,0,0,0,1.3.4,2.3,2.3,0,0,0,1.8-.8l4.5-5.7-12.4,2.4Z" fill="#b95700" />
    <path
      d="M108.3,111.8a2,2,0,0,0,1.6-.7l13.3-14.6a2.5,2.5,0,0,0,.6-1.7,2.6,2.6,0,0,0-.9-1.6l-7.6-5.6-6,1.1,8.9,6.7-10.4,11.4-24.6-13-7,1.4,31,16.4A2.2,2.2,0,0,0,108.3,111.8Z"
      fill="#b95700"
    />
    <path
      d="M64.3,83.9,80.7,67.5l28.6,21.2,6-1.1L85.1,65.2,74.5,67.3,59,82.8a2.1,2.1,0,0,0-.6,2,2.1,2.1,0,0,0,1.1,1.6l16.7,8.8,7-1.4Z"
      fill="#eb6909"
    />
    <path
      d="M52.2,76.4l-6.6-3.5-7,1.4,13,6.8a1.7,1.7,0,0,0,1,.3,2.4,2.4,0,0,0,1.6-.7L65.9,69l-7.8,1.5Z"
      fill="#eb6909"
    />
    <polygon
      points="126.5 85.3 97.2 62.9 91.4 64 120.7 86.5 133.1 84.1 159.9 50.7 153.1 52 126.5 85.3"
      fill="#eb6909"
    />
    <path d="M81.8,62.7a2.3,2.3,0,0,0-2.9.3l-4.4,4.3,10.6-2.1Z" fill="#5f9501" />
    <path
      d="M90.4,57.7,116.2,31l-7.7,1.5-23,23.9a2.1,2.1,0,0,0-.7,1.7,2.6,2.6,0,0,0,.9,1.6L91.4,64l5.8-1.1Z"
      fill="#5f9501"
    />
    <path
      d="M165.5,36.4,153.1,52l6.8-1.3L171,36.8a2.1,2.1,0,0,0,.4-2,2.1,2.1,0,0,0-1.4-1.5L146.7,25,138,26.7Z"
      fill="#5f9501"
    />
    <polygon points="58.1 70.5 65.9 69 100.9 33.9 93.1 35.5 58.1 70.5" fill="#5f9501" />
    <path d="M45.6,72.9,6.6,52.4h.1L.2,53.6a2.4,2.4,0,0,0,1,1L38.6,74.3Z" fill="#5f9501" />
    <path
      d="M65,12.7,53.8,14.9,1,50.8a2.2,2.2,0,0,0-1,1.9,2.9,2.9,0,0,0,.2.9l6.5-1.3Z"
      fill="#79b61d"
    />
    <path
      d="M112.1,16.5l-19,19,7.8-1.6,16.9-16.8a2.1,2.1,0,0,0,.6-2.1,2,2,0,0,0-1.5-1.6L96.6,6.6,87.7,8.3Z"
      fill="#79b61d"
    />
    <path
      d="M122.6,17.9,108.5,32.5l7.7-1.5,8.6-8.9L138,26.7l8.7-1.7-21.8-7.6A2.2,2.2,0,0,0,122.6,17.9Z"
      fill="#79b61d"
    />
    <path
      d="M76.8,4.7,87.7,8.3l8.9-1.7L77.2.1a2.4,2.4,0,0,0-2,.3L53.8,14.9,65,12.7Z"
      fill="#c8d200"
    />
  </svg>
);

export default GradientPlot;
