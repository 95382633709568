import { AT, de_AT, en_AT } from './ATBehaviours';
import { BG, bg_BG, en_BG } from './BGBehaviours';
import { DE, de_DE } from './DEBehaviours';
import { FR, fr_FR, en_FR } from './FRBehaviours';
import { HU, hu_HU, en_HU } from './HUBehaviours';
import { RO, ro_RO, en_RO } from './ROBehaviours';
import { UK, en_UK } from './UKBehaviours';
import { CZ, cz_CZ } from './CZBehaviours';
import { SK, sk_SK } from './SKBehaviours';

export const behaviours = { AT, BG, DE, FR, HU, RO, UK, CZ, SK };
export const localeDependentBehaviours = { de_AT, en_AT, bg_BG, en_BG, de_DE, fr_FR, en_FR, hu_HU, en_HU, ro_RO, en_RO, en_UK, cz_CZ, sk_SK };
